// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ExUaLF17DYO0O6Y7eGxL {\n    display: flex;\n    gap: 28px;\n    margin-top: 32px;\n    margin-bottom: 48px;\n}\n\n._1yGEjmu0jCGQ0cr0yElix1 {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n._34U9XS4tyKuM4GuWVGfVs_ {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-600);\n    margin: 0 0 4px 0;\n}\n\n._3ufAwi21WG-ogy_t30pCcs {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n._1PxvRYkuubcviUexPp4jec {\n    display: flex;\n    flex-direction: column;\n    width: 45%;\n}\n\n._1PxvRYkuubcviUexPp4jec._3VELhbE0bRIqf7p2fhNNGR {\n    width: 50%;\n}\n", ""]);
// Exports
exports.locals = {
	"skeleton": "_1ExUaLF17DYO0O6Y7eGxL",
	"column-title": "_1yGEjmu0jCGQ0cr0yElix1",
	"row-item": "_34U9XS4tyKuM4GuWVGfVs_",
	"row-container": "_3ufAwi21WG-ogy_t30pCcs",
	"column": "_1PxvRYkuubcviUexPp4jec",
	"old": "_3VELhbE0bRIqf7p2fhNNGR"
};
module.exports = exports;
