// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rimFQ_xMR_qyUeXSDXbiH {\n    font-size: 14px;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin-bottom: 40px;\n}", ""]);
// Exports
exports.locals = {
	"shipment-label": "rimFQ_xMR_qyUeXSDXbiH"
};
module.exports = exports;
