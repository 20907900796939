import type { FC, ChangeEvent, FormEvent } from 'react'
import React, { useEffect, useState } from 'react'
import type {
  CarouselPromptOption,
  MultiSelectPromptOption,
  Reply,
  SelectPromptOption,
  Slot,
} from '@helloextend/extend-api-client'
import { Footer } from './footer'
import type { MerchantContract } from '../../../../types/merchant-contract'
import styles from './answer-selection.module.css'
import { getFormattedIncredibotMessage } from '../../../../utils/get-formatted-incredibot-message'

export interface AnswerSelectionProps {
  onSubmit: (slot: Slot, slotValue: string | number) => void
  isLoading: boolean
  reply: Reply
  contractId: MerchantContract['id']
  autoselectHideSingleOption?: boolean
}

export const AnswerSelection: FC<AnswerSelectionProps> = ({
  isLoading,
  onSubmit,
  reply,
  contractId,
  autoselectHideSingleOption,
}) => {
  const shouldAutoselectAndHideSingleOption = Boolean(
    autoselectHideSingleOption && getOptions(reply).length === 1,
  )
  // due to some selections on claims-assistant registered as an empty string, we are
  // using "null" to represent an untouched state. This allows us to disable the form
  // up until the site user selects an option
  const [selectedValueIndex, setSelectedValueIndex] = useState<number | null>(
    shouldAutoselectAndHideSingleOption ? 0 : null,
  )
  useEffect(() => {
    setSelectedValueIndex(autoselectHideSingleOption ? 0 : null)
  }, [reply, autoselectHideSingleOption])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedIndex = +e.target.value
    setSelectedValueIndex(selectedIndex)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (reply.prompt?.slot && selectedValueIndex !== null && 'options' in reply.prompt) {
      const { options } = reply.prompt
      const selectedOption = options[selectedValueIndex]

      if (typeof selectedOption.value === 'string' || typeof selectedOption.value === 'number') {
        onSubmit(reply.prompt.slot, selectedOption.value)
      }
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.title}>{getFormattedIncredibotMessage(reply.messages)}</h2>
      <div className={styles['form-group']}>
        {!shouldAutoselectAndHideSingleOption &&
          getOptions(reply).map((option, index) => (
            <label
              className={styles.container}
              htmlFor={`answer-selection-${option.value}-${option.outputText}`}
              key={`${option.value.toString()}-${option.outputText}`}
            >
              <input
                checked={selectedValueIndex === index}
                aria-checked={selectedValueIndex === index}
                data-value={option.value}
                aria-labelledby={`answer-selection-${option.value}-${option.outputText}`}
                id={`answer-selection-${option.value}-${option.outputText}`}
                disabled={isLoading}
                name="answer-selection"
                onChange={handleChange}
                tabIndex={0}
                type="radio"
                value={index}
              />
              <div className={styles['select-container-box']}>
                {option.outputText ?? option.title}
                <span className={styles['check-circle']} />
              </div>
            </label>
          ))}
      </div>
      <Footer
        disabled={selectedValueIndex === null}
        isLoading={isLoading}
        contractId={contractId}
      />
    </form>
  )
}

function getOptions(
  reply: Reply,
): Array<SelectPromptOption | CarouselPromptOption | MultiSelectPromptOption> {
  if (reply.prompt && 'options' in reply.prompt) {
    return reply.prompt.options || []
  }

  return []
}
