import React, { FC, useState } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import {
  AccordionSection,
  AdvancedSelect,
  AdvancedSelectChangeEvent,
  AdvancedSelectOption,
  Badge,
  Button,
  DataProperty,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useGetServicerQuery } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { formatAddress } from '../../../utils/form-utils'
import { useUser } from '../../../hooks/use-user'
import { useUpdateAssignmentLocationMutation } from '../../../queries/service-orders'

interface ServicerSectionProps {
  serviceOrder: ServiceOrder
}

const NON_EDITABLE_STATUSES = ['created', 'closed', 'payment_approved', 'paid']

export const ServicerSection: FC<ServicerSectionProps> = ({ serviceOrder }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { accountId } = useUser()
  const { toast } = useToaster()
  const [assignedLocation, setAssignedLocation] = useState(
    serviceOrder.assignedServicer?.servicingLocation.id ?? '',
  )
  const { assignedServicer } = serviceOrder
  const { data: servicer } = useGetServicerQuery(serviceOrder.assignedServicer?.servicerId ?? '', {
    skip: !serviceOrder.assignedServicer,
  })

  const isAssignedServicer = assignedServicer && assignedServicer.authOrganizationId === accountId

  const { mutateAsync: updateAssignmentLocation } = useUpdateAssignmentLocationMutation()

  const handleToggle = (): void => {
    setIsExpanded(!isExpanded)
  }

  if (!assignedServicer || !servicer) {
    return null
  }

  const handleLocationChange = (e: AdvancedSelectChangeEvent): void => {
    setAssignedLocation(e.target.value)
  }

  const handleSaveClick = async (): Promise<void> => {
    try {
      await updateAssignmentLocation({
        serviceOrderId: serviceOrder.id,
        locationId: assignedLocation,
      })

      toast({
        message: 'Servicer location updated!',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    } catch (err) {
      toast({
        message: 'Error updating servicer location',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const options: AdvancedSelectOption[] = (servicer.locations ?? []).map((location) => ({
    value: location.id,
    display: `${location.businessName} ${formatAddress(location.address)}`,
    badgeColor: 'neutral',
    badgeText: location.id === servicer.settings.defaultServicingLocation ? 'Default' : undefined,
  }))

  return (
    <AccordionSection
      heading="Servicer"
      headingSize="sub"
      onToggleRequest={handleToggle}
      isExpanded={isExpanded}
      data-cy={`${serviceOrder.id}-servicer-section`}
    >
      <Stack spacing={5}>
        <Stack isRow spacing={5}>
          <DataProperty
            label="Servicer"
            data-cy="servicer-data-property"
            value={servicer.businessName}
          />
          {!isAssignedServicer ||
          NON_EDITABLE_STATUSES.includes(serviceOrder.status) ||
          (servicer.locations ?? []).length < 2 ? (
            <Stack isRow spacing={1}>
              <DataProperty
                label="Servicer Location"
                data-cy="servicer-location-data-property"
                value={`${assignedServicer.servicingLocation.businessName} ${formatAddress(
                  assignedServicer.servicingLocation.address,
                )}`}
              />
              {assignedServicer.servicingLocation.id ===
                servicer.settings.defaultServicingLocation && (
                <BadgeWrapper data-cy="default-location-badge">
                  <Badge color="neutral" text="Default" />
                </BadgeWrapper>
              )}
            </Stack>
          ) : (
            <AdvancedSelect
              label="Servicer Location"
              id="servicer-location-select"
              data-cy="servicer-location-advanced-select"
              value={assignedLocation}
              options={options}
              onChange={handleLocationChange}
              multiple={false}
              isNotClearable
            />
          )}
        </Stack>
        {isAssignedServicer &&
          !NON_EDITABLE_STATUSES.includes(serviceOrder.status) &&
          (servicer.locations ?? []).length > 1 && (
            <div>
              <Button
                onClick={handleSaveClick}
                text="Save"
                fillContainer={false}
                data-cy="servicer-location-save-button"
              />
            </div>
          )}
      </Stack>
    </AccordionSection>
  )
}

const BadgeWrapper = styled.div({
  paddingTop: 25,
})
