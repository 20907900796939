import { date } from '@extend/client-helpers'
import type { Contract } from '@helloextend/extend-api-client'
import { startCase } from 'lodash/fp'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { formatAddress } from './form-utils'
import { claimServiceTypeMap } from './get-service-order-status-copy'
import type { MerchantClaim } from '../types/claims'

enum SectionKey {
  customer = 'customer',
  claimDetails = 'claimDetails',
}

export const getShippingClaimFields = (
  contract: Contract,
  claim: MerchantClaim,
): Record<SectionKey, DataPropertyDetails[]> => {
  const isKaley = `${claim.filedBy?.firstName} ${claim.filedBy?.lastName}` === 'Kaley Chatbot'
  return {
    [SectionKey.customer]: [
      {
        key: 'name',
        label: 'Name',
        dataCy: 'name',
        value: claim.customer.name,
      },
      { key: 'email', label: 'Email', value: claim.customer.email, dataCy: 'email' },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
        value: contract.customer.phone,
        dataCy: 'phoneNumber',
      },
      {
        key: 'shippingAddress',
        label: 'Customer Shipping Address',
        value: claim.customer?.shippingAddress
          ? formatAddress(claim.customer?.shippingAddress)
          : '',
        dataCy: 'shippingAddress',
      },
    ],
    [SectionKey.claimDetails]: [
      {
        key: 'claimType',
        value: 'Shipping Protection',
        label: 'Type',
        dataCy: 'claimType',
      },
      {
        key: 'reportedAt',
        value: date.format(claim.reportedAt),
        label: 'Reported At Date',
        dataCy: 'reportedAt',
      },
      {
        key: 'incidentDate',
        value: date.format(claim.incident.occurredAt),
        label: 'Incident Date',
        dataCy: 'incidentDate',
      },
      {
        key: 'transactionDate',
        value: date.format(Number(contract.purchaseDate)),
        label: 'Transaction Date',
        dataCy: 'transactionDate',
      },
      {
        key: 'transactionId',
        value: contract.transactionId,
        label: 'Transaction ID',
        dataCy: 'transactionId',
      },
      {
        key: 'claimStatus',
        value: claim.status,
        label: 'Status',
        dataCy: 'claimStatus',
      },
      {
        key: 'description',
        value: claim.incident.description,
        label: 'Description',
        dataCy: 'description',
      },
      {
        key: 'contractId',
        value: contract.id,
        label: 'Contract ID',
        dataCy: 'contractId',
        includeCopyAction: true,
      },
      {
        key: 'fraudulentActivity',
        value: claim.fraudulentActivity?.description,
        label: 'Fraudulent Activity',
        dataCy: 'fraudulentActivity',
      },
      {
        key: 'purchaseOrderNumber',
        value: contract.poNumber,
        label: 'PO Number',
        dataCy: 'purchaseOrderNumber',
      },
      {
        key: 'storeName',
        value: contract.sellerName,
        label: 'Store Name',
        dataCy: 'storeName',
      },
      {
        key: 'storeId',
        value: contract.sellerId,
        label: 'Store ID',
        dataCy: 'storeId',
        includeCopyAction: true,
      },
      {
        key: 'failureType',
        value: startCase(claim.incident.failureType),
        label: 'Failure Type',
        dataCy: 'failureType',
      },
      {
        key: 'failureCause',
        value: startCase(claim.incident.failureCause ?? ''),
        label: 'Failure Cause',
        dataCy: 'failure Cause',
      },
      {
        key: 'serviceType',
        value: claimServiceTypeMap[claim.serviceType],
        label: 'Service Type',
        dataCy: 'serviceType',
      },
      {
        key: 'createdBy',
        value: isKaley ? claim.customer.email : claim.filedBy?.email,
        label: 'Created By',
        dataCy: 'createdBy',
      },
    ],
  }
}
