// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2fMovl0YW6QyrxXo_XqK7_ {\n    margin-top: 40px;\n    max-width: 1107px;\n}\n\n._2fMovl0YW6QyrxXo_XqK7_ > * + * {\n    margin-top: 32px;\n}", ""]);
// Exports
exports.locals = {
	"cards": "_2fMovl0YW6QyrxXo_XqK7_"
};
module.exports = exports;
