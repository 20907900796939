import type { FC } from 'react'
import React from 'react'
import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useRequestForPaymentMutation } from '../../../queries/service-orders'

export type RequestPaymentModalProps = {
  isVisible: boolean
  serviceOrder: ServiceOrder
  closeModal: () => void
}

export const RequestPaymentModal: FC<RequestPaymentModalProps> = ({
  isVisible,
  closeModal,
  serviceOrder,
}) => {
  const { mutateAsync: requestPayment, isLoading } = useRequestForPaymentMutation()
  const { toast } = useToaster()
  const handleOnClickRequest = async (): Promise<void> => {
    try {
      await requestPayment({ serviceOrderId: serviceOrder?.id || '' })
      toast({
        message: 'Your payment was successfully requested',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      closeModal()
    } catch {
      toast({
        message: 'There was an error requesting payment. Please try again',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Requesting Payment"
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: closeModal,
        }}
        primaryButtonProps={{
          isProcessing: isLoading,
          text: 'Request',
          onClick: handleOnClickRequest,
        }}
      >
        <p>
          Once payment is requested, no further action can be taken on this claim and its service
          order.
        </p>
        <p>Are you sure you want to request payment?</p>
      </Modal>
    </ModalController>
  )
}
