import { useMemo } from 'react'
import { useUser } from './use-user'
import type { Grant } from '../types/okta'
import { isValidRoleV3 } from '../utils/get-user-roles'

export const useAccountFilteredGrants = (grants: Grant[] = []): Grant[] => {
  const { accountId } = useUser()

  const validFilteredGrants = useMemo(() => {
    return grants?.filter(
      (grant: Grant) => isValidRoleV3(grant.role) && grant.ern === `ERN:ACC:${accountId}`,
    )
  }, [grants, accountId])

  return validFilteredGrants
}
