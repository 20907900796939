// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._262b6pSooJS37r72zJCqFK {\n    color: var(--neutral-600)\n}\n\n._2s5hrooUUijSSngnW9ldVf {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n._2DJ-wixoyENmZcVsq7gXD5 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.uxiSFn7dIdA9FfP1mFpQW {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.tSMsZ9Ha-sqeNu5lJW_WG {\n    color: var(--neutral-600);\n    font-weight: bold;\n}", ""]);
// Exports
exports.locals = {
	"remaining-amount": "_262b6pSooJS37r72zJCqFK",
	"product-line": "_2s5hrooUUijSSngnW9ldVf",
	"type-line": "_2DJ-wixoyENmZcVsq7gXD5",
	"contract-line": "uxiSFn7dIdA9FfP1mFpQW",
	"info-label": "tSMsZ9Ha-sqeNu5lJW_WG"
};
module.exports = exports;
