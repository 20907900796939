// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2or-SHf0zonktxCG0jJMZJ {\n    padding-bottom: 16px;\n}\n\n._1dAr7Y3hyvYHGO0Mhwptqh {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n._4jDb2XGvU00VALV-AoujK {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    width: 100%;\n}\n\n._4jDb2XGvU00VALV-AoujK:first-child {\n    flex: 1;\n}\n\n._4jDb2XGvU00VALV-AoujK:nth-child(2) {\n    flex: 2;\n}\n\n._1FgFmppQQquHJegmotzNoh {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    gap: 4px;\n}\n\n.nZCbcKj7Bav6BZR-BCNn8 {\n    flex: 1;\n    padding: 8px 0 0;\n    width: 174px;\n}\n\n.-axamphFpySHAuOxJYYn_ {\n    flex: 2;\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n.-axamphFpySHAuOxJYYn_ > div {\n    flex-grow: 1;\n    width: 100%;\n}\n\n@media (max-width: 980px) {\n    ._1dAr7Y3hyvYHGO0Mhwptqh {\n        grid-template-columns: 1fr;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "_2or-SHf0zonktxCG0jJMZJ",
	"roles-section": "_1dAr7Y3hyvYHGO0Mhwptqh",
	"label-row": "_4jDb2XGvU00VALV-AoujK",
	"data-row": "_1FgFmppQQquHJegmotzNoh",
	"role-selector": "nZCbcKj7Bav6BZR-BCNn8",
	"org-selector": "-axamphFpySHAuOxJYYn_"
};
module.exports = exports;
