import type { FC, SyntheticEvent } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  Checkbox,
  COLOR,
  ColorInput,
  Information,
  InformationSize,
  Input,
  Radio,
  Spinner,
} from '@extend/zen'
import { InputType } from '@extend/zen'
import { useFormik } from 'formik'
import { Select, SelectItem } from '@helloextend/merchants-ui/src/components/select'
import { useAtomValue } from 'jotai/react'
import { SubHeader } from '../../../components/sub-header'
import { SaveBanner } from '../../../components/save-banner'
import { LeadEmailPreview } from './lead-email-preview'
import { LeadsEmailsSchema } from '../../../schemas/leads-email-schema'
import { SendTestEmails } from './send-test-emails'
import { useSanitizedBlur } from '../../../hooks/use-sanitized-blur'
import { StoreLogoUpload } from './store-logo-upload'
import { LeadsEmailTypeInfo } from './leads-section-info'
import { useGetConfigurationQuery, useSaveConfigurationMutation } from '../../../queries/messaging'
import { getActiveStoreAtom } from '../../../atoms/stores'
import styles from './leads-email-managment.module.css'

interface LeadsEmailManagementProps {
  storeId: string
}

const emailFonts = [
  'Helvetica',
  'Arial',
  'Times New Roman',
  'Courier New',
  'Palatino',
  'Garamond',
  'Bookman',
  'Avant Garde',
  'Verdana',
  'Trebuchet MS',
  'Georgia',
  'Impact',
]

const [defaultEmailFont] = emailFonts

export const LeadsEmailManagement: FC<LeadsEmailManagementProps> = ({ storeId }) => {
  const activeStore = useAtomValue(getActiveStoreAtom)
  const { data, isLoading } = useGetConfigurationQuery({
    messageName: 'leads-created',
    storeId,
  })

  const {
    mutate: saveConfiguration,
    isLoading: isSaving,
    isSuccess: isSaveSuccess,
    isError: isSaveError,
  } = useSaveConfigurationMutation()

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: {
      enabled: data?.enabled || false,
      emailType: data?.emailType || 'marketing',
      sendFromEmail: data?.sendFromEmail || '',
      replyToEmail: data?.replyToEmail || '',
      callToActionUri: data?.callToActionUri || '',
      store: {
        id: storeId,
        name: data?.storeName || activeStore?.name || '',
        merchantAddress: data?.merchantAddress || activeStore?.address?.address1 || '',
        primaryColor: data?.primaryColor || '',
        heroButtonTextColor: data?.heroButtonTextColor || 'FFFFFF',
        lightBackgroundColor: data?.lightBackgroundColor || 'F3F6F9',
        merchantPrivacyPolicyLink: data?.merchantPrivacyPolicyLink || '',
        merchantPhoneNumber: data?.merchantPhoneNumber || '',
        googleFont: data?.googleFont || defaultEmailFont,
        headerFont: data?.headerFont || defaultEmailFont,
        merchantLogo: data?.merchantLogo || activeStore?.logoUrl || '',
      },
      buildFirstCommunication: data?.buildFirstCommunication || true,
      buildSecondCommunication: data?.buildSecondCommunication || true,
      buildThirdCommunication: data?.buildThirdCommunication || true,
      buildFourthCommunication: data?.buildFourthCommunication || true,
    },
    validationSchema: LeadsEmailsSchema,
    enableReinitialize: true,

    onSubmit: (configuration): void => {
      saveConfiguration({
        message: {
          name: 'leads-created',
        },
        configuration,
      })
    },
  })

  const handlePrimaryColorChange = (color: string): void => {
    setFieldValue('store.primaryColor', color)
  }

  const handleButtonColorChange = (color: string): void => {
    setFieldValue('store.heroButtonTextColor', color)
  }

  const handleBackgroundColorChange = (color: string): void => {
    setFieldValue('store.lightBackgroundColor', color)
  }

  const handleBodyFontChange = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLButtonElement
    setFieldValue('store.googleFont', target.value)
  }

  const handleHeaderFontChange = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLButtonElement
    setFieldValue('store.headerFont', target.value)
  }
  const handleMerchantLogoChange = useCallback(
    (logoUrl: string): void => {
      setFieldValue('store.merchantLogo', logoUrl)
    },
    [setFieldValue],
  )

  const LeadEmailConfigDescription =
    'Turn lead emails on or off, and change the type and details of emails'

  const customizeLeadEmailsDescription = 'Change the way your lead emails will appear to customers'

  const handleSaveChanges = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const handleDiscardChanges = useCallback(() => {
    resetForm()
  }, [resetForm])

  const { toastError, toastCompleted } = useStandardToast()

  const { onHandleBlur } = useSanitizedBlur(handleBlur, setFieldValue)

  useEffect(() => {
    if (isSaveError) {
      toastError('Changes could not be saved. Please try again later.')
    }
  }, [toastError, isSaveError])

  useEffect(() => {
    if (isSaveSuccess && !dirty) {
      toastCompleted('Changes Saved')
    }
  }, [isSaveSuccess, toastCompleted, dirty])

  if (isLoading) {
    return (
      <div className="flex align-items-center justify-content-center width-100 height-100">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  return (
    <>
      <SaveBanner
        onSaveChanges={handleSaveChanges}
        onDiscardChanges={handleDiscardChanges}
        isProcessing={isSaving}
        isVisible={dirty}
      />
      <form>
        <div className={styles['leads-emails']} data-cy="lead-email-config">
          <SubHeader labelText="Lead Email Configuration" />
          <div className={styles.description} data-cy="lead-email-config:description">
            {LeadEmailConfigDescription}
          </div>

          <div className={styles['info-header']} data-cy="lead-email-config:input:header">
            Send Leads Emails
          </div>
          <Checkbox
            data-cy="send-email:checkbox"
            checked={values.enabled}
            onChange={handleChange}
            name="enabled"
            label="Send emails to customers"
          />
          {values.enabled && (
            <>
              <div className={styles.info}>
                Lead Email Type
                <LeadsEmailTypeInfo />
              </div>
              <Radio
                data-cy="email-type:marketing"
                onChange={handleChange}
                label="Marketing"
                value="marketing"
                name="emailType"
                checked={values.emailType === 'marketing'}
              />
              <Radio
                data-cy="email-type:transactional"
                onChange={handleChange}
                label="Transactional"
                value="transactional"
                name="emailType"
                checked={values.emailType === 'transactional'}
              />
              <div className={styles.row}>
                <div className={styles.text}>
                  <Input
                    data-cy="sendFromEmail"
                    onChange={handleChange}
                    onBlur={onHandleBlur}
                    type={InputType.text}
                    label="Sending Email Address"
                    id="sendFromEmail"
                    value={values.sendFromEmail}
                    errorFeedback={touched.sendFromEmail ? errors.sendFromEmail : ''}
                    isError={touched.sendFromEmail && Boolean(errors.sendFromEmail)}
                  />
                </div>
                <div className={styles.text}>
                  <Input
                    data-cy="replyToEmail"
                    onChange={handleChange}
                    onBlur={onHandleBlur}
                    type={InputType.text}
                    label="Reply-to Email Address"
                    id="replyToEmail"
                    value={values.replyToEmail}
                    errorFeedback={touched.replyToEmail ? errors.replyToEmail : ''}
                    isError={touched.replyToEmail && Boolean(errors.replyToEmail)}
                  />
                </div>
                <div className={styles.text}>
                  <Input
                    data-cy="callToActionUri"
                    onChange={handleChange}
                    onBlur={onHandleBlur}
                    type={InputType.text}
                    label="Call-to-Action URL"
                    id="callToActionUri"
                    value={values.callToActionUri}
                    errorFeedback={touched.callToActionUri ? errors.callToActionUri : ''}
                    isError={touched.callToActionUri && Boolean(errors.callToActionUri)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {values.enabled && (
          <div className={styles.customization} data-cy="lead-email-customize">
            <div>
              <SubHeader labelText="Customize Lead Emails" />
              <div className={styles.description} data-cy="lead-email-customize:description">
                {customizeLeadEmailsDescription}
              </div>
            </div>
            <div className="flex flex-row">
              <div className={styles['email-fields']}>
                <div className={styles['top-text']}>
                  <div className={styles.text}>
                    <Input
                      data-cy="storeName"
                      onChange={handleChange}
                      onBlur={onHandleBlur}
                      type={InputType.text}
                      label="Store Name"
                      id="store.name"
                      value={values.store.name}
                      errorFeedback={touched.store?.name ? errors.store?.name : ''}
                      isError={touched.store?.name && Boolean(errors.store?.name)}
                    />
                  </div>
                </div>
                <div className={styles.input}>
                  <StoreLogoUpload
                    storeId={storeId}
                    onChange={handleMerchantLogoChange}
                    currentImage={values.store?.merchantLogo}
                  />
                </div>
                <div className={styles.input}>
                  <ColorInput
                    data-cy="primaryColor"
                    onChange={handlePrimaryColorChange}
                    label="Primary Color (Button Color)"
                    id="store.primaryColor"
                    value={values.store?.primaryColor}
                  />
                </div>
                <div className={styles.input}>
                  <ColorInput
                    data-cy="heroButtonTextColor"
                    onChange={handleButtonColorChange}
                    label="Button Text Color"
                    id="store.heroButtonTextColor"
                    value={values.store?.heroButtonTextColor}
                  />
                </div>
                <div className={styles.input}>
                  <ColorInput
                    data-cy="lightBackgroundColor"
                    onChange={handleBackgroundColorChange}
                    label="Light Background Color"
                    id="store.lightBackgroundColor"
                    value={values.store?.lightBackgroundColor}
                  />
                </div>
                <div className={styles.input}>
                  <Select
                    data-cy="headerFont"
                    onChange={handleHeaderFontChange}
                    value={values.store?.headerFont}
                    label="Header Font"
                  >
                    {emailFonts.map((font) => (
                      <SelectItem value={font} label={font} key={font} />
                    ))}
                  </Select>
                </div>
                <div className={styles.input}>
                  <Select
                    data-cy="googleFont"
                    onChange={handleBodyFontChange}
                    value={values.store?.googleFont}
                    label="Body Font"
                  >
                    {emailFonts.map((font) => (
                      <SelectItem value={font} label={font} key={font} />
                    ))}
                  </Select>
                </div>
                {values.emailType === 'transactional' && (
                  <>
                    <div className={styles.input}>
                      <Input
                        data-cy="merchantAddress"
                        onChange={handleChange}
                        onBlur={onHandleBlur}
                        type={InputType.text}
                        label="Merchant Address"
                        id="store.merchantAddress"
                        value={values.store?.merchantAddress}
                        errorFeedback={
                          touched.store?.merchantAddress ? errors.store?.merchantAddress : ''
                        }
                        isError={
                          touched.store?.merchantAddress && Boolean(errors.store?.merchantAddress)
                        }
                      />
                    </div>
                    <div className={styles.input}>
                      <Input
                        data-cy="merchantPrivacyPolicyLink"
                        onChange={handleChange}
                        onBlur={onHandleBlur}
                        type={InputType.text}
                        label="Privacy Policy Link"
                        id="store.merchantPrivacyPolicyLink"
                        value={values.store?.merchantPrivacyPolicyLink}
                        errorFeedback={
                          touched.store?.merchantPrivacyPolicyLink
                            ? errors.store?.merchantPrivacyPolicyLink
                            : ''
                        }
                        isError={
                          touched.store?.merchantPrivacyPolicyLink &&
                          Boolean(errors.store?.merchantPrivacyPolicyLink)
                        }
                      />
                    </div>
                    <div className={styles.input}>
                      <Input
                        data-cy="merchantPhoneNumber"
                        onChange={handleChange}
                        onBlur={onHandleBlur}
                        type={InputType.text}
                        label="Merchant Phone Number (Optional)"
                        id="store.merchantPhoneNumber"
                        value={values.store?.merchantPhoneNumber}
                        errorFeedback={
                          touched.store?.merchantPhoneNumber
                            ? errors.store?.merchantPhoneNumber
                            : ''
                        }
                        isError={
                          touched.store?.merchantPhoneNumber &&
                          Boolean(errors.store?.merchantPhoneNumber)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex-grow z-0" data-cy="leadEmailPreview">
                <div className={styles.preview}>
                  Preview
                  <Information size={InformationSize.small}>
                    Stylized for reference only (for an accurate proof, please send test email
                    below)
                  </Information>
                </div>
                <LeadEmailPreview
                  storeName={values.store?.name || ''}
                  buttonColor={values.store?.primaryColor}
                  buttonTextColor={values.store?.heroButtonTextColor}
                  lightBackgroundColor={values.store?.lightBackgroundColor}
                  merchantAddress={values.store?.merchantAddress}
                  privacyPolicyUrl={values.store?.merchantPrivacyPolicyLink}
                  merchantPhone={values.store?.merchantPhoneNumber}
                  bodyFont={values.store?.googleFont}
                  headlineFont={values.store?.headerFont}
                  logoSrc={values.store?.merchantLogo}
                  emailType={values.emailType}
                />
              </div>
            </div>
          </div>
        )}
      </form>
      {values.enabled && (
        <div className={styles['leads-emails']}>
          <SendTestEmails storeId={storeId} />
        </div>
      )}
    </>
  )
}
