import type { Grant } from '../types/okta'
import type { V3User } from '../types/users'

export const mapUsersWithGrants = (users: V3User[] = [], grants: Grant[] = []): V3User[] => {
  for (const grant of grants) {
    const user = users.find((u) => u.email === grant.userId)
    if (user) {
      user.grants = user.grants ? [...user.grants, grant] : [grant]
    }
  }
  return users
}
