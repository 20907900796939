import { validate } from '@extend/client-helpers'
import * as Yup from 'yup'

const PHONE_NUMBER_MIN_LENGTH_USA = 10

const LeadsEmailsSchema = Yup.object()
  .shape({
    enabled: Yup.boolean().required(),
    emailType: Yup.string().required(),
    sendFromEmail: Yup.string()
      .when('emailType', {
        is: (value) => value === 'marketing',
        then: Yup.string()
          .email('Must be a valid email address')
          .test('@extend.com domain', 'Address must end in @extend.com', (value): boolean => {
            return Boolean(value?.endsWith('@extend.com'))
          })
          .required('Sending Email Address is a required field'),
        otherwise: Yup.string()
          .email('Must be a valid email address')
          .required('Sending Email Address is a required field'),
      })
      .when('emailType', {
        is: (value) => value === 'transactional',
        then: Yup.string()
          .email('Must be a valid email address')
          .test('not @extend.com domain', 'Cannot be an @extend.com domain', (value): boolean => {
            return Boolean(!value?.endsWith('@extend.com'))
          })
          .required('Sending Email Address is a required field'),
        otherwise: Yup.string()
          .email('Must be a valid email address')
          .required('Sending Email Address is a required field'),
      }),
    replyToEmail: Yup.string()
      .email('Must be a valid email address')
      .required('Reply to Email Address is a required field'),
    callToActionUri: Yup.string()
      .url('Call to Action Url must be a valid URL')
      .required('Call to Action URL is a required field'),
    store: Yup.object().shape({
      name: Yup.string().required('Store Name is a required field'),
      heroButtonTextColor: Yup.string().notRequired(),
      merchantAddress: Yup.string().notRequired(),
      primaryColor: Yup.string().notRequired(),
      lightBackgroundColor: Yup.string().notRequired(),
      merchantPrivacyPolicyLink: Yup.string()
        .url('Privacy Policy Link must be a valid URL')
        .notRequired(),
      phoneNumber: Yup.string().test(
        'phone-number-validation',
        'Please enter a valid phone number',
        (value): boolean => {
          return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
        },
      ),
      googleFont: Yup.string().notRequired(),
      headerFont: Yup.string().notRequired(),
    }),
  })
  .defined()

export { LeadsEmailsSchema }
