import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { UseQueryResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { useUser } from '../hooks/use-user'

const OAUTH_CLIENTS_CACHE_KEY = 'oauth-clients'

const BASE_URL = `https://${EXTEND_API_HOST}/auth/oauth/clients`

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export function useGetOAuthClientQuery({
  clientId: integrationId,
}: GetOAuthClientRequest): UseQueryResult<GetOAuthClientResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${integrationId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
        accept: 'application/json',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch OAuth client')
    }

    return response.json()
  }, [accessToken, integrationId])

  return useQuery({
    queryFn,
  })
}

export function useGetAPIIntegrationsListQuery(): UseQueryResult<GetOAuthClientsResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { accountId } = useUser()
  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}?ownerErn=ERN:ACC:${accountId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
        accept: 'application/json;',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch api integrations')
    }

    return response.json()
  }, [accessToken, accountId])

  return useQuery({
    queryKey: [OAUTH_CLIENTS_CACHE_KEY],
    queryFn,
  })
}

export function useDeleteOAuthClientMutation(): UseBaseMutationResult<
  void,
  Error,
  DeleteOAuthClientRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ id }) => {
      const response = await fetch(`${BASE_URL}/${id}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
          accept: 'application/json',
        },
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Unable to delete integration')
      }

      await client.invalidateQueries([OAUTH_CLIENTS_CACHE_KEY])
    },
  })
}

export function useCreateOAuthClientMutation(): UseBaseMutationResult<
  CreateOAuthClientResponse,
  Error,
  CreateOAuthClientRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { accountId } = useUser()

  return useMutation({
    mutationFn: async ({ name, validUntil }) => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
          accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          name,
          validUntil,
          ern: `ERN:ACC:${accountId}`,
          actorType: 'merchant',
          scopes: ['collection:oauth-client:full'],
        }),
      })

      if (!response.ok) {
        throw new Error('Unable to create oauth client')
      }

      await client.invalidateQueries([OAUTH_CLIENTS_CACHE_KEY])

      const data = await response.json()

      return { clientId: data.clientId, clientSecret: data.clientSecret }
    },
  })
}

type GetOAuthClientRequest = {
  clientId: string
}

type GetOAuthClientResponse = {
  clientId: string
  ownerErn: string
  validUntil?: number
  config: ClientConfig
  effectiveAt?: number
}

export type GetOAuthClientsResponse = {
  clients: OAuthClient[]
}

type ClientConfig = {
  name: string
  actorType: ActorType
  scopes: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claims?: Record<string, any>
  ern?: string
  ttl?: number // how long a token will be valid for in seconds, must be less than 1 hour
}

export type ActorType =
  | 'merchant'
  | 'internal'
  | 'consumer'
  | 'servicer'
  | 'integrator'
  | 'exendsvc'

export type OAuthClient = GetOAuthClientResponse

type DeleteOAuthClientRequest = {
  id: string
}

type CreateOAuthClientRequest = {
  name: string
  validUntil: number | null
}

export type CreateOAuthClientResponse = {
  clientId: string
  clientSecret: string
}
