import type { UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { MAGENTO_HOST } from '@helloextend/client-constants'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=2022-02-01',
}

export function useMagentoRegistrationMutation(): UseBaseMutationResult<
  void | Response,
  Error,
  { accessToken: string; oauth_consumer_key: string },
  void
> {
  return useMutation({
    mutationFn: async ({ accessToken, oauth_consumer_key }) => {
      const response = await fetch(
        `${MAGENTO_HOST}/auth/finish?oauth_consumer_key=${oauth_consumer_key}`,
        {
          headers: {
            ...COMMON_HEADERS,
            'X-Extend-Access-Token': accessToken,
          },
          method: 'GET',
        },
      )
      return response.json()
    },
  })
}
