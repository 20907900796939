// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._IRwjmsk78XzE67hjcMxn {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n._3z0qO57qodehGMHmZyKiOw {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._1GtI8NvEy5ovQdNCKoh5LH {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._3EY7KvSPVFQsYmq6hOFcED {\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n}\n\n._2z7hfolJM-ibuv12a09-7C {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_IRwjmsk78XzE67hjcMxn",
	"row": "_3z0qO57qodehGMHmZyKiOw",
	"details": "_1GtI8NvEy5ovQdNCKoh5LH",
	"detail": "_3EY7KvSPVFQsYmq6hOFcED",
	"label": "_2z7hfolJM-ibuv12a09-7C"
};
module.exports = exports;
