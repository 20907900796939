// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ei1nx5GPlIQz1H7w5d2cu {\n    max-width: 1008px;\n    margin: 108px auto 0;\n    text-align: center;\n}\n\n._1vKbpv0wyXXMaRnyeROXM0 {\n    color: var(--blue-1000);\n    font-size: 22px;\n    font-weight: 700;\n}\n\n._3odSyNUxE8Tt5x_QkFx-55 {\n    color: var(--blue-1000);\n    font-size: 20px;\n    line-height: 27px;\n    white-space: pre-line;\n}\n\n._3odSyNUxE8Tt5x_QkFx-55 p {\n    margin: 0;\n    padding: 0;\n}\n\n._31kO9xo4raHsWMPlNeTk1R {\n    margin-top: 16px;\n}\n\n._2Sm7mbiVJi3KS4eujvXlHJ {\n    display: inline-flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 56px;\n}\n\n._3H2Pp066lpE2sD3kVqzxpR {\n    width: 400px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1ei1nx5GPlIQz1H7w5d2cu",
	"header": "_1vKbpv0wyXXMaRnyeROXM0",
	"caption": "_3odSyNUxE8Tt5x_QkFx-55",
	"help": "_31kO9xo4raHsWMPlNeTk1R",
	"button": "_2Sm7mbiVJi3KS4eujvXlHJ",
	"icons": "_3H2Pp066lpE2sD3kVqzxpR"
};
module.exports = exports;
