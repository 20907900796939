import { useCallback } from 'react'
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type {
  Expense,
  ServiceOrder,
  ServiceOrderShipment,
  ServiceOrderExpensesCreateRequest,
} from '@helloextend/extend-api-client'
import type { ServiceOrdersFulfillRequest } from '@helloextend/extend-api-client/src/models/service-order'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { isServiceOrderFulfillingAtom } from '../atoms/service-orders'

export const SERVICE_ORDERS_CACHE_KEY = 'ServiceOrders'
export const SERVICE_ORDER_EXPENSES_CACHE_KEY = 'ServiceOrderExpenses'
const SERVICE_ORDER_SHIPMENTS_CACHE_KEY = 'ServiceOrderShipments'

const BASE_URL = `https://${EXTEND_API_HOST}/service-orders`
const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=2021-07-01',
}

export function useUpdateAssignmentLocationMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string; locationId: string },
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, locationId }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/update-assignment-location`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({ locationId }),
      })

      if (!response.ok) {
        throw new Error('Unable to update assignment location')
      }
      client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

export function useGetServiceOrdersByClaimQuery({
  claimId,
}: {
  claimId: string
}): UseQueryResult<ServiceOrder[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ claimId })
    const response = await fetch(`${BASE_URL}/search?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get service orders by claim')
    }

    return response.json()
  }, [accessToken, claimId])

  return useQuery({
    queryKey: [SERVICE_ORDERS_CACHE_KEY],
    queryFn,
  })
}

export function useListServiceOrderExpensesQuery({
  serviceOrderId,
  enabled = true,
  refetchInterval,
}: {
  serviceOrderId: string
  enabled?: boolean
  refetchInterval?: number
}): UseQueryResult<Expense[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${serviceOrderId}/expenses`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get service orders by claim')
    }

    return response.json()
  }, [accessToken, serviceOrderId])

  return useQuery({
    queryKey: [SERVICE_ORDER_EXPENSES_CACHE_KEY],
    queryFn,
    enabled,
    refetchInterval,
  })
}

export function useFulfillServiceOrderMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string; fulfillRequest: ServiceOrdersFulfillRequest },
  { previousServiceOrders: ServiceOrder[] }
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const setIsServiceOrderFulfilling = useSetAtom(isServiceOrderFulfillingAtom)

  return useMutation({
    mutationFn: async ({ serviceOrderId, fulfillRequest }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/fulfill`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(fulfillRequest),
      })

      if (!response.ok) {
        throw new Error('Unable to fulfill service order')
      }
    },
    /**
     * This portion is for optimistic updates due to the lag of queued updates
     * We temporarily patch the service order in the cache and then invalidate
     * the query, prompting a refetch in the future.
     */
    onMutate: async ({ serviceOrderId }) => {
      setIsServiceOrderFulfilling(true)
      await client.cancelQueries({ queryKey: [SERVICE_ORDERS_CACHE_KEY] })
      const previousServiceOrders: ServiceOrder[] =
        client.getQueryData([SERVICE_ORDERS_CACHE_KEY]) || []

      client.setQueryData<ServiceOrder[]>([SERVICE_ORDERS_CACHE_KEY], (prev) => {
        if (!prev) {
          return []
        }
        return prev.map((serviceOrder) =>
          serviceOrder.id === serviceOrderId
            ? { ...serviceOrder, status: 'accepted' }
            : serviceOrder,
        )
      })

      return { previousServiceOrders }
    },
    /**
     * If there is an error, we need to rollback to the previous values
     */
    onError: async (_err, _args, context) => {
      client.setQueryData([SERVICE_ORDERS_CACHE_KEY], context?.previousServiceOrders)
    },
    /**
     * No matter what, we need to invalidate the service orders cache to
     * fetch the actually correct data
     */
    onSettled: async () => {
      setIsServiceOrderFulfilling(false)
      await client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

export function useGetServiceOrderShipmentsByServiceOrderId({
  serviceOrderId,
  enabled = true,
}: {
  serviceOrderId: string
  enabled?: boolean
}): UseQueryResult<ServiceOrderShipment[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${serviceOrderId}/service-order-shipments`, {
      headers: {
        'Content-Type': 'application/json',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get service order shipments')
    }

    return response.json()
  }, [accessToken, serviceOrderId])

  return useQuery({
    queryKey: [SERVICE_ORDER_SHIPMENTS_CACHE_KEY, { serviceOrderId }],
    queryFn,
    enabled,
  })
}

interface CreateServiceOrderMutation extends ServiceOrderExpensesCreateRequest {
  metaData: Record<string, string>
}
export function useCreateServiceOrderExpenseMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string; body: CreateServiceOrderMutation },
  { previousServiceOrderExpenses: Expense[] }
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, body }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/expenses`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        throw new Error('Unable to create expense')
      }
      return response.json()
    },
    onMutate: async () => {
      await client.cancelQueries({ queryKey: [SERVICE_ORDER_EXPENSES_CACHE_KEY] })
      const previousServiceOrderExpenses: Expense[] =
        client.getQueryData([SERVICE_ORDER_EXPENSES_CACHE_KEY]) || []
      return { previousServiceOrderExpenses }
    },
    onError: async (_err, _args, context) => {
      client.setQueryData([SERVICE_ORDER_EXPENSES_CACHE_KEY], context?.previousServiceOrderExpenses)
    },
    onSettled: async () => {
      await client.invalidateQueries([SERVICE_ORDER_EXPENSES_CACHE_KEY])
    },
  })
}

export function useDeleteServiceOrderExpenseMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string; expenseId: string; body?: Record<string, unknown> },
  { previousServiceOrderExpenses: Expense[] }
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, expenseId, body = {} }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/expenses/${expenseId}/void`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        throw new Error('Unable to delete expense')
      }
    },
    onMutate: async () => {
      await client.cancelQueries({ queryKey: [SERVICE_ORDER_EXPENSES_CACHE_KEY] })
      const previousServiceOrderExpenses: Expense[] =
        client.getQueryData([SERVICE_ORDER_EXPENSES_CACHE_KEY]) || []
      return { previousServiceOrderExpenses }
    },
    onError: async (_err, _args, context) => {
      client.setQueryData([SERVICE_ORDER_EXPENSES_CACHE_KEY], context?.previousServiceOrderExpenses)
    },
    onSettled: async () => {
      await client.invalidateQueries([SERVICE_ORDER_EXPENSES_CACHE_KEY])
    },
  })
}

export function useRequestForPaymentMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string },
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/request-payment`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
      })
      if (!response.ok) {
        throw new Error('Unable to request payment')
      }
      setTimeout(() => {
        client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
      }, 3000)
    },
  })
}

export function useFulfillRepairDepotServiceOrder(
  fulfillmentMethod: 'repair_depot' | 'repair_onsite' | 'cleaning_kit' | 'self_repair' | 'carry_in',
): UseBaseMutationResult<void, Error, FulfillRepairDepotServiceOrderRequest, void> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, repairCompleteDate, repairExplanation }) => {
      const fulfilledResponse = await fetch(`${BASE_URL}/${serviceOrderId}/fulfill`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({
          method: fulfillmentMethod,
          repairCompletedAt: repairCompleteDate.getTime(),
          repairExplanation,
        }),
      })

      if (!fulfilledResponse.ok) {
        throw new Error('Unable to fulfill order')
      }

      await client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

export function useCompleteServiceOrderRepair(): UseBaseMutationResult<
  void,
  Error,
  {
    serviceOrderId: string
    repairCompleteDate: Date
    repairExplanation: string
    method: string
  },
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, repairCompleteDate, repairExplanation, method }) => {
      const fulfilledResponse = await fetch(`${BASE_URL}/${serviceOrderId}/fulfill`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({
          method,
          repairCompletedAt: repairCompleteDate.getTime(),
          repairExplanation,
        }),
      })

      if (!fulfilledResponse.ok) {
        throw new Error('Unable to complete repair')
      }

      await client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

export function useAcceptServiceOrderMutation(): UseBaseMutationResult<
  void,
  Error,
  { serviceOrderId: string; body: { rmaNumber?: string; instructions?: string } },
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, body }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/accept`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        throw new Error('Unable to request payment')
      }
      client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

type FulfillRepairDepotServiceOrderRequest = {
  serviceOrderId: string
  repairCompleteDate: Date
  repairExplanation: string
}

export function useStartRepairMutation(): UseBaseMutationResult<
  void,
  Error,
  ServiceOrderStartRepairRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ serviceOrderId, repairStartedAt }) => {
      const response = await fetch(`${BASE_URL}/${serviceOrderId}/start-repair`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({ repairStartedAt }),
      })

      if (!response.ok) {
        throw new Error('Unable to start repair')
      }
      client.invalidateQueries([SERVICE_ORDERS_CACHE_KEY])
    },
  })
}

type ServiceOrderStartRepairRequest = {
  serviceOrderId: string
  repairStartedAt: number
}
