import type {
  ServiceOrdersCloseResponse,
  ServiceOrderCreateRequest,
  ServiceOrdersFulfillRequest,
  VirtualCardUserAgreement,
  VirtualCardUserAgreementCreateResponse,
} from '@helloextend/extend-api-client/src/models/service-order'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  CreateExpenseRequest,
  Expense,
  ServiceOrder,
  ServiceOrdersGetRequest,
  ServiceOrderShipment,
  VoidExpenseRequest,
  AssignServicerRequest,
  ServiceOrdersSearchRequest,
  ServiceOrdersSearchResponse,
  ServiceOrdersStartTrackingResponse,
  CheckInServiceOrderRequest,
  ServiceOrderStartRepairRequest,
  AcceptServiceOrderRequest,
  StartLabelTrackingRequest,
  ServiceOrderCloseRequest,
  ServiceOrdersGetByClaimRequest,
  ReplacementFulfillmentMethod,
  VoidLabelRequest,
  RepairFulfillmentMethod,
} from './types'

export const serviceOrdersApi = createApi({
  baseQuery,
  reducerPath: 'ServiceOrder',
  tagTypes: [
    'ServiceOrder',
    'ServiceOrderShipments',
    'serviceOrderExpenses',
    'Entitlements',
    'VirtualCardUserAgreement',
  ],
  endpoints: (build) => ({
    // service orders
    getServiceOrder: build.query<ServiceOrder, ServiceOrdersGetRequest>({
      query: ({
        serviceOrderId,
        includeShippingLabels = false,
        version = 'default',
        accessToken,
      }) => ({
        url: `/service-orders/${serviceOrderId}`,
        params: { include_shipping_labels: includeShippingLabels },
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { serviceOrderId }) => [{ type: 'ServiceOrder', id: serviceOrderId }],
    }),
    getServiceOrdersByClaim: build.query<ServiceOrder[], ServiceOrdersGetByClaimRequest>({
      query: ({ claimId, accessToken }) => ({
        url: `/service-orders/search`,
        params: { claimId, includeShippingLabels: false },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2021-07-01;',
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id: serviceOrderId }) => ({ type: 'ServiceOrder', id: serviceOrderId } as const),
              ),
              { type: 'ServiceOrder', id: 'LIST' },
            ]
          : [{ type: 'ServiceOrder', id: 'LIST' }],
    }),
    searchServiceOrders: build.query<ServiceOrdersSearchResponse, ServiceOrdersSearchRequest>({
      query: (params) => ({
        url: `/service-orders/search`,
        params,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      transformResponse: (response: ServiceOrdersSearchResponse) => ({
        ...response,
        items: response.items?.length
          ? response.items.map((serviceOrder: ServiceOrder) => ({
              ...serviceOrder,
              ...(serviceOrder.status === 'assigned' && serviceOrder.configurations?.autoAccept
                ? { status: 'accepted' }
                : {}),
            }))
          : [],
      }),
      providesTags: (result) => {
        if (result?.items.length) {
          return [
            ...result.items.map(
              ({ id: serviceOrderId }: ServiceOrder) =>
                ({
                  type: 'ServiceOrder',
                  id: serviceOrderId,
                } as const),
            ),
            { type: 'ServiceOrder', id: 'LIST' },
          ]
        }
        return [{ type: 'ServiceOrder', id: 'LIST' }]
      },
    }),
    searchServiceOrdersV1: build.query<ServiceOrder[], ServiceOrdersSearchRequest>({
      query: (params) => ({
        url: `/service-orders/search`,
        params,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (result) => {
        if (result?.length) {
          return [
            ...result.map(
              ({ id: serviceOrderId }: ServiceOrder) =>
                ({
                  type: 'ServiceOrder',
                  id: serviceOrderId,
                } as const),
            ),
            { type: 'ServiceOrder', id: 'LIST' },
          ]
        }
        return [{ type: 'ServiceOrder', id: 'LIST' }]
      },
    }),
    serviceOrderAssign: build.mutation<{ id: string }, AssignServicerRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/assign`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderApproveReplacement: build.mutation<
      { id: string },
      { serviceOrderId: string; version?: string }
    >({
      query: ({ serviceOrderId, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/approve-replacement`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
      }),
      async onQueryStarted({ serviceOrderId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'getServiceOrder',
            { serviceOrderId },
            (serviceOrder: ServiceOrder) => {
              return {
                ...serviceOrder,
                status: 'replacement_approved',
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['ServiceOrder'],
    }),
    acceptServiceOrder: build.mutation<{ id: string }, AcceptServiceOrderRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/accept`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      async onQueryStarted({ serviceOrderId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'getServiceOrder',
            { serviceOrderId },
            (serviceOrder: ServiceOrder) => {
              return {
                ...serviceOrder,
                ...body,
                status: 'accepted',
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['ServiceOrder'],
    }),
    checkInServiceOrder: build.mutation<{ id: string }, CheckInServiceOrderRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/check-in`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderStartRepair: build.mutation<{ id: string }, ServiceOrderStartRepairRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/start-repair`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderRequestPayment: build.mutation<{ id: string; version?: string }, string>({
      query: (serviceOrderId: string, version = 'default') => ({
        url: `/service-orders/${serviceOrderId}/request-payment`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderApprovePayment: build.mutation<{ id: string; version?: string }, string>({
      query: (serviceOrderId: string, version = 'default') => ({
        url: `/service-orders/${serviceOrderId}/approve-payment`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
      }),
      invalidatesTags: ['ServiceOrder', 'Entitlements'],
    }),
    serviceOrderStartLabelTracking: build.mutation<
      ServiceOrdersStartTrackingResponse,
      StartLabelTrackingRequest
    >({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/start-label-tracking`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, _err, { serviceOrderId }) => [
        { type: 'ServiceOrderShipments', id: serviceOrderId },
      ],
    }),
    serviceOrderClose: build.mutation<ServiceOrdersCloseResponse, ServiceOrderCloseRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/close`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderCreate: build.mutation<ServiceOrder, ServiceOrderCreateRequest>({
      query: (body, version = 'default') => ({
        url: `/service-orders`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    // expenses
    listServiceOrderExpenses: build.query<Expense[], string>({
      query: (serviceOrderId: string, version = 'default') => ({
        url: `/service-orders/${serviceOrderId}/expenses`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, expenseId) => [{ type: 'serviceOrderExpenses', id: expenseId }],
    }),
    createServiceOrderExpense: build.mutation<{ id: string }, CreateExpenseRequest>({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/expenses`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      async onQueryStarted({ serviceOrderId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'listServiceOrderExpenses',
            serviceOrderId,
            (draftExpenses: Expense[]) => {
              const expense = { ...body }
              const {
                quantity,
                cost: { amount: cost },
              } = body
              if (!quantity || quantity === 1 || cost === 0) {
                expense.totalCost = cost
              } else {
                const multipliedQuantity = quantity * 100
                const multipliedCost = cost * 100
                expense.totalCost = Math.round((multipliedQuantity * multipliedCost) / 10000)
              }
              draftExpenses.push(expense as Expense)
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['serviceOrderExpenses'],
    }),
    voidExpense: build.mutation<{ id: string }, VoidExpenseRequest>({
      query: ({ serviceOrderId, expenseId, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/expenses/${expenseId}/void`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
      }),
      async onQueryStarted({ serviceOrderId, expenseId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'listServiceOrderExpenses',
            serviceOrderId,
            (draftExpenses: Expense[]) => {
              const expense = draftExpenses.find((ex) => ex.id === expenseId)
              if (expense) {
                expense.status = 'voided'
              }
              return draftExpenses
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['serviceOrderExpenses'],
    }),
    // shipments
    listServiceOrderShipments: build.query<ServiceOrderShipment[], string>({
      query: (serviceOrderId: string, version = 'default') => ({
        url: `/service-orders/${serviceOrderId}/service-order-shipments`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, serviceOrderId) => [
        { type: 'ServiceOrderShipments', id: serviceOrderId },
      ],
    }),
    fulfillServiceOrder: build.mutation<
      { id: string },
      {
        serviceOrderId: string
        body: ServiceOrdersFulfillRequest
        version?: string
      }
    >({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/fulfill`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      async onQueryStarted({ serviceOrderId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'getServiceOrder',
            { serviceOrderId },
            (serviceOrder: ServiceOrder) => {
              return {
                ...serviceOrder,
                status: 'replacement_approved',
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderSetRepairShipped: build.mutation<
      { id: string },
      { serviceOrderId: string; version?: string }
    >({
      query: ({ serviceOrderId, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/set-repair-shipped`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    selectFulfillmentMethod: build.mutation<
      undefined,
      {
        serviceOrderId: string
        fulfillmentSelection: ReplacementFulfillmentMethod
        version?: string
      }
    >({
      query: ({ serviceOrderId, fulfillmentSelection, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/select-fulfillment`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: { fulfillmentSelection },
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    selectRepairFulfillmentMethod: build.mutation<
      undefined,
      {
        serviceOrderId: string
        repairFulfillmentMethod: RepairFulfillmentMethod
        version?: string
      }
    >({
      query: ({ serviceOrderId, repairFulfillmentMethod, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/select-repair-fulfillment`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: { repairFulfillmentMethod },
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    serviceOrderSetAsPaid: build.mutation<
      { id: string },
      { serviceOrderId: string; body: { paymentId: string }; version?: string }
    >({
      query: ({ serviceOrderId, body, version = 'default' }) => ({
        url: `/service-orders/${serviceOrderId}/set-as-paid`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    voidShippingLabel: build.mutation<{ id: string }, VoidLabelRequest>({
      query: ({ serviceOrderId, body, version = 'latest' }) => ({
        url: `/service-orders/${serviceOrderId}/shipping-labels/void`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    submitVirtualCardUserAgreement: build.mutation<
      VirtualCardUserAgreementCreateResponse,
      { accessToken?: string; version?: string }
    >({
      query: ({ accessToken, version = 'default' }) => ({
        url: `/service-orders/virtual-card-user-agreement`,
        method: 'POST',
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        setTimeout(() => {
          dispatch(serviceOrdersApi.util.invalidateTags(['VirtualCardUserAgreement']))
        }, 200)
      },
    }),
    getVirtualCardUserAgreement: build.query<
      VirtualCardUserAgreement,
      { accessToken?: string; version?: string }
    >({
      query: ({ accessToken, version = 'default' }) => ({
        url: `/service-orders/virtual-card-user-agreement`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'VirtualCardUserAgreement' }],
    }),
  }),
})

export const {
  useGetServiceOrderQuery,
  useLazyGetServiceOrderQuery,
  useGetServiceOrdersByClaimQuery,
  useSearchServiceOrdersQuery,
  useSearchServiceOrdersV1Query,
  useAcceptServiceOrderMutation,
  useLazySearchServiceOrdersQuery,
  useServiceOrderAssignMutation,
  useCheckInServiceOrderMutation,
  useServiceOrderStartRepairMutation,
  useServiceOrderCloseMutation,
  useServiceOrderRequestPaymentMutation,
  useServiceOrderApprovePaymentMutation,
  useListServiceOrderExpensesQuery,
  useCreateServiceOrderExpenseMutation,
  useVoidExpenseMutation,
  useListServiceOrderShipmentsQuery,
  useLazyListServiceOrderShipmentsQuery,
  useServiceOrderStartLabelTrackingMutation,
  useServiceOrderApproveReplacementMutation,
  useServiceOrderSetRepairShippedMutation,
  useServiceOrderSetAsPaidMutation,
  useFulfillServiceOrderMutation,
  useSubmitVirtualCardUserAgreementMutation,
  useGetVirtualCardUserAgreementQuery,
  useServiceOrderCreateMutation,
  useSelectFulfillmentMethodMutation,
  useVoidShippingLabelMutation,
  useSelectRepairFulfillmentMethodMutation,
} = serviceOrdersApi
