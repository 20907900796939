import { validate } from '@extend/client-helpers'

type Validator = Record<string, RegExp>

const RegExpValidator: Validator = {
  // check for non-empty fields for now
  username: RegExp(/^(?=[a-zA-Z0-9]).{1,}$/),
  // check for at 1 special character #?!@$%^&*=+_)(-~`{}[]<>|:;',./, 1 number, and at least 8 characters
  password: RegExp(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-])[a-zA-Z0-9#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]{8,}$/,
  ),
}

const PasswordIncludesValidator: Validator = {
  valid: RegExp(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-])[a-zA-Z0-9#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]{8,}$/,
  ),
  length: RegExp(/^(.){8,}$/),
  letter: RegExp(/[a-zA-Z]+/),
  number: RegExp(/[0-9]+/),
  symbol: RegExp(/[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]+/),
  invalid: RegExp(/["\\]/),
}

export const isValidShopifyLoginUrl = (url: string): boolean => {
  const urlExp = new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/g,
  )

  // Checks to make sure the submitted domain fits the pattern *.myshopify.com
  const domainExp = new RegExp(/([a-zA-Z\d-]+\.)myshopify\.com\/?$/)

  return urlExp.test(url) && domainExp.test(url)
}

export const validateSignupForm = (formValues: Record<string, any>): boolean => {
  // check for mismatch passwords
  if (!doPasswordsMatch(formValues)) return false

  // check for blanks
  return Object.values(formValues).every((value) => !!value || value.trim())
}

export const validateCreateAccountForm = (formFields: Record<string, string>): boolean => {
  if (!validate.validateUrl(formFields.storeUrl) || !doPasswordsMatch(formFields)) {
    return false
  }
  if (
    !validatePassword(formFields.password, 'STRENGTH') ||
    !validatePassword(formFields.password, 'LENGTH')
  ) {
    return false
  }

  return Object.values(formFields).every((value) => !!value || value.trim())
}

export const doPasswordsMatch = (formFields: Record<string, string>): boolean => {
  // pass if form fields are undefined
  if (!formFields) return true

  const { password, passwordConfirm } = formFields
  return Boolean(password && passwordConfirm && password === passwordConfirm)
}

export const validatePassword = (password: string, criteria: string): boolean => {
  const combinatorTest = (word: string): boolean => {
    return (
      ['LETTER', 'SYMBOL', 'NUMBER'].reduce((total, test) => {
        if (validatePassword(word, test)) {
          return total + 1
        }
        return total
      }, 0) === 3
    )
  }

  const { length, letter, number, symbol, valid, invalid } = PasswordIncludesValidator

  if (!password) return false

  switch (criteria) {
    case 'LENGTH':
      return length.test(password)
    case 'STRENGTH':
      return combinatorTest(password)
    case 'LETTER':
      return letter.test(password)
    case 'NUMBER':
      return number.test(password)
    case 'SYMBOL':
      return symbol.test(password)
    case 'VALID':
      return valid.test(password)
    case 'INVALID':
      return invalid.test(password)
    default:
      return false
  }
}

export const validateField = (name: string, value = ''): boolean => {
  const validator = RegExpValidator[name] || RegExp(/^.+$/)
  return validator.test(value)
}

export function isExtendEmail(inputText: string): boolean {
  return new RegExp(/@extend.com$/).test(inputText)
}

export const alphaOnlyRegex = /^[A-Za-z]+$/
export function isAlphaOnly(inputText: string): boolean {
  return new RegExp(alphaOnlyRegex).test(inputText)
}

export const alphanumericRegex = /^[A-Za-z0-9]+$/
export function isAlphanumeric(inputText: string): boolean {
  return new RegExp(alphanumericRegex).test(inputText)
}

export const alphanumericWithSpacesRegex = /^[\s]*[A-Za-z0-9][A-Za-z0-9\s]*$/
export function isAlphanumericWithSpaces(inputText: string): boolean {
  return new RegExp(alphanumericWithSpacesRegex).test(inputText)
}
