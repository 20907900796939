import type { Address } from '@helloextend/extend-api-client'
import ISOAlpha2 from 'iso-3166-1-alpha-2'
import provinces from 'provinces'
import { postcodeValidator } from 'postcode-validator'

/* Extend customers are globally distributed now, so we need to support all countries. */

/**
 * An array of province codes for a given country, or all countries if no country code is provided.
 * @param countryCode the ISO 3166 Alpha-2 country code
 * @returns an array of 2-letter non-US province codes
 */
const getProvinceCodes = (countryCode?: string): string[] => {
  return countryCode
    ? provinces
        .filter((provinceCode) => provinceCode.country === countryCode)
        .map((provinceCode) => provinceCode.short ?? provinceCode.name)
    : provinces.map((provinceCode) => provinceCode.short ?? provinceCode.name) // cast here is safe because we've filter out nulls
}

const countries = ISOAlpha2.getData()

/* internationally-compatible e.164 phone number regex - up to 15 digits */
const phoneRegEx = /^\+?[1-9]\d{1,14}$/

/**
 * We use the postcode-validator package to validate US zip + intl postal codes. This is
 * simply a wrapper around the postcode-validator function.
 * @see https://www.npmjs.com/package/postcode-validator
 * @param code the postal code to validate
 * @param country the ISO 3166 Alpha-2 country code to validate against
 * @returns true if the postal code is valid for the given country code
 */
const isValidPostalCode = (code?: string, country?: string): boolean => {
  // passing an invalid ISO 3166 Alpha-2 country code will throw an error, so we need to try + catch
  try {
    if (!code || !country) return false
    return postcodeValidator(code, country)
  } catch (e) {
    return false
  }
}

const cityRegex = /^[a-zA-Z.-]+(?:[\s-][a-zA-Z.]+)*$/

/**
 * This function takes a phone number string and returns a valid E.164 phone number string.
 * It removes all non-numeric characters and trims off the leading '+' if present. It also
 * ensures that the phone number is no longer than 15 digits, per the E.164 standard.
 * @param value the phone number to convert
 * @returns an E.164 phone number string
 */
const toValidPhoneNumber = (value: string): string => {
  let val = value

  // Remove everything not a number, except for the leading '+'
  val = val.replace(/[^0-9+]/g, '')

  // e.164 phone numbers are up to 15 digits or 16 chars counting the '+'
  if (val.length > 16) val = val.substring(0, val.length - 1)

  return val
}

const formatAddress = ({
  address1,
  address2,
  city,
  provinceCode,
  postalCode,
  countryCode,
}: Partial<Address>): string => {
  const address = `${address1}${address2 ? `, ${address2}` : ''}`
  return [address, city, provinceCode, postalCode, countryCode].join(', ')
}

export {
  cityRegex,
  phoneRegEx,
  toValidPhoneNumber,
  isValidPostalCode,
  formatAddress,
  getProvinceCodes,
  countries,
}
