import React, { useState } from 'react'
import { Modal, ModalController, Checkbox, Input, ContentCopy, Button } from '@extend/zen'
import { useAtom } from 'jotai/react'
import styles from './create-api-integration-details-modal.module.css'
import { createIntegrationDetailsModalAtom } from '../../atoms/api-integration-details-modal'
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard'

export const CreateApiIntegrationDetailsModal = () => {
  const copyToClipboard = useCopyToClipboard()
  const [apiIntegrationDetails, setCreateIntegrationDetails] = useAtom(
    createIntegrationDetailsModalAtom,
  )
  const isModalVisible = Boolean(apiIntegrationDetails)
  const [isConfirmationCheckboxChecked, toggleIsConfirmationCheckboxChecked] = useState(false)
  const handleClickClose = (): void => {
    setCreateIntegrationDetails(null)
    toggleIsConfirmationCheckboxChecked(false)
  }

  if (!apiIntegrationDetails) return null

  return (
    <ModalController isOpen={isModalVisible}>
      <Modal
        heading="Copy your new API Key"
        primaryButtonProps={{
          onClick: handleClickClose,
          text: 'Close',
          isDisabled: !isConfirmationCheckboxChecked,
          isProcessing: false,
          type: 'button',
        }}
      >
        <div className={styles.wrapper}>
          <p>
            Copy and store your API credentials and client secret in a secure location. After you
            close this window, <strong>Extend can’t show the secret again</strong>— if you lose it,
            you’ll have to create a new integration.
          </p>
          <div className={styles['input-wrapper']}>
            <Input
              id="clientId"
              label="Client ID"
              isDisabled
              value={apiIntegrationDetails.clientId}
            />
            <Button
              text="Copy"
              data-cy="copy-client-id-button"
              onClick={() => copyToClipboard('Client ID', apiIntegrationDetails.clientId)}
              icon={ContentCopy}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <Input
              id="clientSecret"
              label="Client Secret"
              isDisabled
              value={apiIntegrationDetails.clientSecret}
            />
            <Button
              data-cy="copy-client-secret-button"
              text="Copy"
              onClick={() => copyToClipboard('Client Secret', apiIntegrationDetails.clientSecret)}
              icon={ContentCopy}
            />
          </div>
          <Checkbox
            checked={isConfirmationCheckboxChecked}
            disabled={false}
            onChange={() => toggleIsConfirmationCheckboxChecked(!isConfirmationCheckboxChecked)}
            label="I've copied my secret and stored it in a secure location"
          />
        </div>
      </Modal>
    </ModalController>
  )
}
