import type { FulfillmentMethod } from '@helloextend/extend-api-client'
import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    method: Yup.string<FulfillmentMethod>().default('product_replacement').required(),
    product: Yup.object()
      .shape({
        referenceId: Yup.string().default('').required('Please enter the product ref ID'),
        title: Yup.string()
          .default('')
          .required('Please enter the product name for the replacement product'),
        value: Yup.object()
          .shape({
            amount: Yup.mixed<undefined | number>()
              .test({
                name: 'isPositiveNumber',
                message: 'Please enter the replacement value',
                test: (value) => value != null && value > 0,
              })
              .default(undefined),
            currencyCode: Yup.string().default('USD'),
          })
          .defined(),
        reimbursementAmount: Yup.object()
          .shape({
            amount: Yup.mixed<undefined | number>()
              .test({
                name: 'isPositiveNumber',
                message: 'Please enter the reimbursement amount',
                test: (value) => value != null && value > 0,
              })
              .default(undefined),
            currencyCode: Yup.string().default('USD'),
          })
          .defined(),
      })
      .defined(),
    wholesaleCost: Yup.object()
      .shape({
        amount: Yup.number(),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .optional(),
    orderNumber: Yup.string().default('').required('Please enter an order number'),
    expenseDescription: Yup.string().default('').required('Please enter a description'),
    carrier: Yup.string().default('').required('Please enter a carrier'),
    trackingNumber: Yup.string().default('').required('Please enter a tracking number'),
  })
  .defined()

const giftCardFormSchema = Yup.object()
  .shape({
    method: Yup.string().default('gift_card').required(),
    provider: Yup.string().default('').required('Please select a gift card provider.'),
    amount: Yup.object()
      .shape({
        amount: Yup.number().required('Please enter a gift card value.'),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .required('Please enter a gift card value.'),
    miscFees: Yup.object().shape({
      amount: Yup.number(),
      currencyCode: Yup.string().default('USD').optional(),
    }),
    wholesaleCost: Yup.object()
      .shape({
        amount: Yup.number(),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .optional(),
    expenseDescription: Yup.string().default(''),
  })
  .defined()

type Values = Yup.InferType<typeof schema>
type GiftCardValues = Yup.InferType<typeof giftCardFormSchema>

export { schema, giftCardFormSchema }
export type { Values, GiftCardValues }
