// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uiUJE0FCHE8c2O7N9lpTL {\n    list-style-position: inside;\n    padding-left: 8px;\n}\n\n.bEdZiDJ1IZnAajJThYTJB {\n    padding-top: 4px;\n    font-weight: normal;\n}\n\n._2gu-4OfOqHjamoj6CVhM-Y {\n    font-weight: normal;\n}\n\n._3HOlmxHc4d0COI8zXQuRbn {\n    padding-left: 16px;\n    list-style-type: none;\n    font-weight: normal;\n}\n\n._1Xquwvt5WXjcGBMhI0gJA9 {\n    font-weight: 700;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "uiUJE0FCHE8c2O7N9lpTL",
	"item": "bEdZiDJ1IZnAajJThYTJB",
	"first": "_2gu-4OfOqHjamoj6CVhM-Y",
	"plain": "_3HOlmxHc4d0COI8zXQuRbn",
	"info": "_1Xquwvt5WXjcGBMhI0gJA9"
};
module.exports = exports;
