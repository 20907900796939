import React from 'react'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { Badge } from '@extend/zen'
import type { APIIntegrationStatus } from '../../utils/integration-status-utils'
import { getApiIntegrationStatusBadgeItem } from '../../utils/integration-status-utils'
import type { APIIntegrationMapping } from '../../hooks/use-all-api-clients'

export const TABLE_COLUMNS: ColumnDefs<APIIntegrationMapping> = [
  {
    id: 'name',
    label: 'Client Name',
    renderCell: (cellData: CellContext<APIIntegrationMapping, string>) => cellData.getValue(),
  },
  {
    id: 'validUntil',
    label: 'Expiration Date',
    renderCell: (cellData: CellContext<APIIntegrationMapping, string>) => cellData.getValue(),
  },
  {
    id: 'orgName',
    label: 'Organization',
    renderCell: (cellData: CellContext<APIIntegrationMapping, string>) => cellData.getValue(),
  },
  {
    id: 'status',
    label: 'Status',
    renderCell: (cellData: CellContext<APIIntegrationMapping, APIIntegrationStatus>) => {
      const { text, color } = getApiIntegrationStatusBadgeItem(cellData.getValue())
      return <Badge text={text} color={color} />
    },
  },
]
