import type { Contract } from '@helloextend/extend-api-client'
import { currency, date } from '@extend/client-helpers'
import { capitalize } from 'lodash/fp'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { getNumberAmount } from './money'
import { getISOAlpha2CountryCodeForCountryInput } from './format-country-name-to-country-code'

export enum SectionKey {
  transactionInformation = 'transactionInformation',
  contractInformation = 'contractInformation',
  customer = 'customer',
  storeInformation = 'storeInformation',
  billingInformation = 'billingInformation',
  shippingInformation = 'shippingInformation',
}

export const getInitialValues = (
  fields: ReturnType<typeof getContractDetailsFieldsFromContract>,
): { [key: string]: any } => {
  const customerFields = fields.customer?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)
  const billingFields = fields.billingInformation?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)
  const shippingFields = fields.shippingInformation?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)

  return { ...customerFields, ...billingFields, ...shippingFields }
}

export const getContractId = (
  fields: ReturnType<typeof getContractDetailsFieldsFromContract>,
): string => fields.contractInformation.find((v) => v.key === 'contractId')?.value || ''

export const getContractDetailsFieldsFromContract = (
  contract: Contract,
): Record<SectionKey, DataPropertyDetails[]> => {
  const planPurchasePrice = getNumberAmount(
    contract?.purchasePrice || contract?.plan?.purchasePrice || 0,
  )

  const purchasePrice = planPurchasePrice
    ? currency.formatWithoutCurrencySymbol(planPurchasePrice, contract.currency)
    : '0.00'

  return {
    [SectionKey.customer]: [
      {
        key: 'fullName',
        label: 'Full Name',
        dataCy: 'fullName',
        value: contract.customer?.name,
      },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
        value: contract.customer?.phone,
        dataCy: 'phoneNumber',
      },
      {
        key: 'email',
        label: 'Email',
        value: contract.customer?.email,
        dataCy: 'email',
      },
    ],
    [SectionKey.billingInformation]: [
      {
        key: 'billingAddress1',
        value: contract.customer?.billingAddress?.address1,
        label: 'Address',
        dataCy: 'billingAddress1',
      },
      {
        key: 'billingAddress2',
        value: contract.customer?.billingAddress?.address2,
        label: 'Address 2',
        dataCy: 'billingAddress2',
      },
      {
        key: 'billingCity',
        value: contract.customer?.billingAddress?.city,
        label: 'City',
        dataCy: 'billingCity',
      },
      {
        key: 'billingProvinceCode',
        value: contract.customer?.billingAddress?.provinceCode,
        label: 'Province Code',
        dataCy: 'billingProvinceCode',
      },
      {
        key: 'billingPostalCode',
        value: contract.customer?.billingAddress?.postalCode,
        label: 'Postal Code',
        dataCy: 'billingPostalCode',
      },
      {
        key: 'billingCountryCode',
        value: getISOAlpha2CountryCodeForCountryInput(
          contract.customer?.billingAddress?.countryCode || '',
        ),
        label: 'Country Code',
        dataCy: 'billingCountryCode',
      },
    ],
    [SectionKey.shippingInformation]: [
      {
        key: 'shippingAddress1',
        value: contract.customer?.shippingAddress?.address1,
        label: 'Address',
        dataCy: 'shippingAddress1',
      },
      {
        key: 'shippingAddress2',
        value: contract.customer?.shippingAddress?.address2,
        label: 'Address 2',
        dataCy: 'shippingAddress2',
      },
      {
        key: 'shippingCity',
        value: contract.customer?.shippingAddress?.city,
        label: 'City',
        dataCy: 'shippingCity',
      },
      {
        key: 'shippingProvinceCode',
        value: contract.customer?.shippingAddress?.provinceCode,
        label: 'Province Code',
        dataCy: 'shippingProvinceCode',
      },
      {
        key: 'shippingPostalCode',
        value: contract.customer?.shippingAddress?.postalCode,
        label: 'Postal Code',
        dataCy: 'shippingPostalCode',
      },
      {
        key: 'shippingCountryCode',
        value: getISOAlpha2CountryCodeForCountryInput(
          contract.customer?.shippingAddress?.countryCode || '',
        ),
        label: 'Country Code',
        dataCy: 'shippingCountryCode',
      },
    ],
    [SectionKey.transactionInformation]: [
      {
        key: 'orderId',
        value: contract.orderId,
        label: 'Order ID',
        dataCy: 'orderId',
      },
      {
        key: 'currencyCode',
        value: contract.currency,
        label: 'Currency Code',
        dataCy: 'currencyCode',
      },
      {
        key: 'transactionId',
        value: contract.transactionId,
        label: 'Transaction ID',
        dataCy: 'transactionId',
        includeCopyAction: true,
      },
      {
        key: 'poNumber',
        value: contract.poNumber,
        label: 'PO Number',
        dataCy: 'poNumber',
      },
    ],
    [SectionKey.storeInformation]: [
      {
        key: 'sellerName',
        value: contract.sellerName,
        label: 'Store Name',
        dataCy: 'sellerName',
      },
      {
        key: 'sellerId',
        value: contract.sellerId,
        label: 'Store ID',
        dataCy: 'sellerId',
      },
    ],
    [SectionKey.contractInformation]: [
      {
        key: 'contractId',
        value: contract.id,
        label: 'Contract ID',
        dataCy: 'contractId',
        includeCopyAction: true,
      },
      {
        key: 'isTest',
        value: capitalize(String(contract.isTest)),
        label: 'Is Test',
        dataCy: 'isTest',
      },
      {
        key: 'contractStatus',
        value: capitalize(contract.status),
        label: 'Contract Status',
        dataCy: 'contractStatus',
      },
      {
        key: 'planId',
        value: contract.plan.id,
        label: 'Plan ID',
        dataCy: 'planId',
      },
      {
        ...(contract.type === 'pcrs'
          ? {
              key: 'coverageStartDate',
              value: date.format(Number(contract.coverage?.starts), date.standardDateFormat),
              label: 'Coverage Start Date',
              dataCy: 'coverageStartDate',
            }
          : null),
      },
      {
        ...(contract.type === 'pcrs'
          ? {
              key: 'coverageEndDate',
              value: date.format(Number(contract.coverage?.ends), date.standardDateFormat),
              label: 'Coverage End Date',
              dataCy: 'coverageEndDate',
            }
          : null),
      },
      {
        key: 'contractPurchasePrice',
        value: purchasePrice,
        label: 'Purchase Price',
        dataCy: 'contractPurchasePrice',
      },
      {
        key: 'contractListPrice',
        value: currency.formatWithoutCurrencySymbol(contract.listPrice, contract.currency),
        label: 'List Price',
        dataCy: 'contractListPrice',
      },
      {
        key: 'dateCreated',
        value: date.format(Number(contract.createdAt), date.standardDateFormat),
        label: 'Date Created',
        dataCy: 'dateCreated',
      },
      {
        key: 'dateUpdated',
        value: date.format(Number(contract.updatedAt), date.standardDateFormat),
        label: 'Date Updated',
        dataCy: 'dateUpdated',
      },
      {
        key: 'transactionDate',
        value: date.format(Number(contract.purchaseDate), date.standardDateFormat),
        label: 'Transaction Date',
        dataCy: 'transactionDate',
      },
      {
        key: 'dateCancelled',
        value: contract.cancelledAt
          ? date.format(Number(contract.cancelledAt), date.standardDateFormat)
          : '',
        label: 'Date Cancelled',
        dataCy: 'dateCancelled',
      },
      {
        key: 'dateRefunded',
        value: contract.refundedAt
          ? date.format(Number(contract.refundedAt), date.standardDateFormat)
          : '',
        label: 'Date Refunded',
        dataCy: 'dateRefunded',
      },
      {
        key: 'planTransactionId',
        value: contract.transactionId,
        label: 'Plan Transaction ID',
        dataCy: 'planTransactionId',
      },
      {
        key: 'planCategory',
        value: contract.plan.category,
        label: 'Plan Category',
        dataCy: 'planCategory',
      },
    ].filter(Boolean) as DataPropertyDetails[],
  }
}
