import React from 'react'
import { startCase } from 'lodash/fp'
import type { Column } from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import { currency } from '@extend/client-helpers'
import type { Expense, PartsExpense, ReplacementExpense } from '@helloextend/extend-api-client'
import styles from './expenses.module.css'

export const formatExpenseType = (type: Expense['type']): string => {
  switch (type) {
    case 'replacement': {
      return 'Product Replacement'
    }
    case 'parts': {
      return 'Part(s)'
    }
    default: {
      return startCase(type)
    }
  }
}

export const checkMetaData = (expense: Expense): string => {
  switch (expense.type) {
    case 'replacement':
    case 'refurbished_replacement':
      return `Product Name: ${(expense as ReplacementExpense).metaData.productName}`
    case 'parts':
      return `Part Number: ${(expense as PartsExpense).metaData.partNumber}`
    default:
      // Not all expense types have metadata
      return ''
  }
}

export const expenseColumns: Array<Column<Expense>> = [
  {
    Header: 'Type',
    accessor: 'type',
    columnWidth: 25,
    Cell: (data) => <CellText title={data.type}>{formatExpenseType(data.type)}</CellText>,
  },
  {
    Header: 'Description',
    accessor: 'description',
    columnWidth: 50,
    Cell: (data: Expense): JSX.Element => {
      const metaData = checkMetaData(data)
      return (
        <>
          {Boolean(metaData) && (
            <p className={styles['double-cell-text']} title="metaData">
              {metaData}
            </p>
          )}
          <p className={styles['double-cell-text']}>{data.description}</p>
        </>
      )
    },
  },
  {
    Header: 'Incurred By',
    accessor: 'incurredBy',
    columnWidth: 20,
    Cell: (data) => (
      <CellText title={data.id}>
        {data.incurredBy ? startCase(data.incurredBy as string) : ''}
      </CellText>
    ),
  },
  {
    Header: 'Rate',
    accessor: 'cost',
    columnWidth: 25,
    textAlign: 'right',
    Cell: (data) => {
      const { amount, currencyCode } = data.cost
      return (
        <CellText align="right">
          <span className={styles.amount}>
            {currency.formatWithoutCurrencySymbol(amount, currencyCode)}
          </span>
        </CellText>
      )
    },
  },
  {
    Header: 'Wholesale Cost',
    accessor: 'wholesaleCost',
    columnWidth: 25,
    textAlign: 'right',
    Cell: (data) => {
      if (!data.wholesaleCost) return <CellText />
      const { amount, currencyCode } = data.wholesaleCost
      return (
        <CellText align="right">
          <span className={styles.amount}>
            {currency.formatWithoutCurrencySymbol(amount, currencyCode ?? 'USD')}
          </span>
        </CellText>
      )
    },
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    columnWidth: 25,
    textAlign: 'right',
    Cell: (data) => {
      return <CellText align="right">{data.quantity || 1}</CellText>
    },
  },
  {
    Header: 'Total',
    accessor: 'totalCost',
    columnWidth: 25,
    textAlign: 'right',
    Cell: (data) => {
      const { amount, currencyCode } = data.cost
      const calculatedTotal = amount * (data.quantity || 1)

      return (
        <CellText align="right">
          <span className={styles.amount}>
            {currency.formatWithoutCurrencySymbol(data.totalCost || calculatedTotal, currencyCode)}
          </span>
        </CellText>
      )
    },
  },
]

export const hasExpenses = (expenses: Expense[]): boolean => {
  return expenses.some((e) => e.status !== 'voided')
}
