import type { FC } from 'react'
import React, { useMemo } from 'react'
import { usePopover, Button, Popover, PopoverAlignment, MenuButtonItem } from '@extend/zen'
import { More } from '@extend/zen'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import { useAtom, useSetAtom } from 'jotai/react'
import {
  isRenameThemeModalVisibleAtom,
  isPublishThemeModalVisibleAtom,
  isDeleteThemeModalVisibleAtom,
  themeToDeleteAtom,
  isCreateThemeModalVisibleAtom,
  createThemeNameAtom,
  currentlySelectedThemeAtom,
} from '../../../atoms/customize-theme'
import styles from './customize-action-menu.module.css'

export const CustomizeActionMenu: FC = () => {
  const [isRenameThemeModalVisible, setIsRenameThemeModalVisible] = useAtom(
    isRenameThemeModalVisibleAtom,
  )
  const [isPublishThemeModalVisible, setIsPublishThemeModalVisible] = useAtom(
    isPublishThemeModalVisibleAtom,
  )
  const [isDeleteThemeModalVisible, setIsDeleteThemeModalVisible] = useAtom(
    isDeleteThemeModalVisibleAtom,
  )
  const setThemeToDelete = useSetAtom(themeToDeleteAtom)
  const [isCreateThemeModalVisible, setIsCreateThemeModalVisible] = useAtom(
    isCreateThemeModalVisibleAtom,
  )
  const [newThemeName] = useAtom(createThemeNameAtom)
  const [selectedTheme] = useAtom(currentlySelectedThemeAtom)
  const isDraftMode = Boolean(newThemeName)

  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox, hide } =
    usePopover<HTMLButtonElement>()

  const handleRenameModalToggle = (): void => {
    setIsRenameThemeModalVisible(!isRenameThemeModalVisible)
    hide()
  }

  const handlePublishModalToggle = (): void => {
    setIsPublishThemeModalVisible(!isPublishThemeModalVisible)
    hide()
  }

  const handleDeleteModalToggle = (): void => {
    if (selectedTheme) {
      setThemeToDelete({
        themeId: selectedTheme?.themeId,
        storeId: selectedTheme?.storeId,
        status: selectedTheme?.status,
        name: selectedTheme?.name,
        createdAt: selectedTheme?.createdAt,
        updatedAt: selectedTheme?.createdAt,
      })
      setIsDeleteThemeModalVisible(!isDeleteThemeModalVisible)
      hide()
    }
  }

  const handleClickDuplicateTheme = (): void => {
    setIsCreateThemeModalVisible(!isCreateThemeModalVisible)
    hide()
  }

  const showPublishButton = useMemo(
    () => selectedTheme?.status === ThemePublishedStatus.unpublished && !isDraftMode,
    [selectedTheme, isDraftMode],
  )

  const showUnpublishedThemeEllipsisOptions = useMemo(
    () => selectedTheme?.status === ThemePublishedStatus.unpublished || isDraftMode,
    [selectedTheme, isDraftMode],
  )

  if (!selectedTheme) {
    return <div className={styles.wrapper} data-cy="customize-action-menu-wrapper" />
  }

  return (
    <div className={styles.wrapper} data-cy="customize-action-menu-wrapper">
      {showPublishButton && (
        <Button
          text="Publish"
          emphasis="medium"
          onClick={handlePublishModalToggle}
          data-cy="publish-theme"
        />
      )}
      <Button
        data-cy="customize-action-popover-trigger"
        emphasis="medium"
        ref={triggerRef}
        onClick={toggle}
        icon={More}
        isToggled={isPresent}
      />

      <Popover
        ref={popoverRef}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        alignment={PopoverAlignment.start}
      >
        {showUnpublishedThemeEllipsisOptions ? (
          <>
            <MenuButtonItem data-cy="delete-theme" onClick={handleDeleteModalToggle}>
              Delete Theme
            </MenuButtonItem>
            <MenuButtonItem data-cy="rename-theme" onClick={handleRenameModalToggle}>
              Rename Theme
            </MenuButtonItem>
          </>
        ) : (
          <MenuButtonItem data-cy="duplicate-theme" onClick={handleClickDuplicateTheme}>
            Duplicate Theme
          </MenuButtonItem>
        )}
      </Popover>
    </div>
  )
}
