import type { FC } from 'react'
import React from 'react'
import { Subheading } from '@extend/zen'
import { currency, date } from '@extend/client-helpers'
import {
  Header,
  HeaderContainer,
  HeaderCoverageDate,
  HeaderTransactionId,
  HeaderListPrice,
  HeaderManufacturerWarrantyLengthLabor,
  HeaderManufacturerWarrantyLengthParts,
  HeaderTransactionDate,
  HeaderSerialNumber,
  HeaderProductCategory,
  HeaderLoLAmount,
  HeaderPurchasePrice,
  Item,
  BundleTableContainer,
  BundleTableRow,
  StyledPurchasePrice,
  StyledCoverage,
  StyledItem,
  StyledTransactionId,
  StyledTitle,
  StyledLengthParts,
  StyledLengthLabor,
  StyledTransactionDate,
} from './styles'
import { ContractsTableMapping } from './contract-products-data-table-mapping'

interface BundleProductsProps {
  bundleProducts: ContractsTableMapping[]
  maxLength?: number
}

export const BundleProducts: FC<BundleProductsProps> = ({
  bundleProducts,
  maxLength,
}) => {
  const gap = (maxLength || 6.5) * 0.45

  return (
    <BundleTableContainer>
      {bundleProducts?.length > 0 && (
        <>
          <Subheading>Component Products</Subheading>
          <br />

          <HeaderContainer>
            <Header>Title</Header>
            <Header
             style={{
              marginLeft: `${gap}rem`,
             }}
            >
              Product Reference ID
            </Header>
            <HeaderProductCategory>Product Category</HeaderProductCategory>
            <HeaderTransactionId>Transaction Id</HeaderTransactionId>
            <HeaderPurchasePrice>Purchase Price</HeaderPurchasePrice>
            <HeaderCoverageDate>Coverage Date</HeaderCoverageDate>
            <HeaderManufacturerWarrantyLengthParts>
              Manufacturer Warranty Length Parts
            </HeaderManufacturerWarrantyLengthParts>
            <HeaderManufacturerWarrantyLengthLabor>
              Manufacturer Warranty Length Labor
            </HeaderManufacturerWarrantyLengthLabor>
            <HeaderTransactionDate>Transaction Date</HeaderTransactionDate>
            <HeaderSerialNumber>Serial Number</HeaderSerialNumber>
            <HeaderListPrice>List Price</HeaderListPrice>
            <HeaderLoLAmount>LoL Amount</HeaderLoLAmount>
          </HeaderContainer>

          <br />
        </>
      )}

      {bundleProducts.map((product, index) => {
        const title = product?.title || 'Bundle Product'

        return (
          <BundleTableRow key={index}>
            <StyledTitle>
              {title}
            </StyledTitle>
            <StyledItem
              style={{
                marginLeft: `${gap + 2.5}rem`,
              }}
            >
              {product.referenceId}
            </StyledItem>
            <Item>{product.category}</Item>
            <StyledTransactionId>{product.transactionId}</StyledTransactionId>
            <StyledPurchasePrice>{currency.format(product.purchasePrice)}</StyledPurchasePrice>
            <StyledCoverage>{product.coverage}</StyledCoverage>         
            <StyledLengthParts>{product.manufacturerWarrantyLengthParts || 0}</StyledLengthParts>
            <StyledLengthLabor>{product.manufacturerWarrantyLengthLabor || 0}</StyledLengthLabor>
            <StyledTransactionDate>{date.format(Number(product.transactionDate), 'MMM DD YYYY')}</StyledTransactionDate>
            <Item>{product.serialNumber}</Item>
            <Item>{currency.format(product.listPrice)}</Item>
            <Item>{currency.format(product.limitOfLiability)}</Item>
          </BundleTableRow>
        )
      })}
    </BundleTableContainer>
  )
}
