import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const THEMES_CACHE_KEY = 'Themes'

const BASE_URL = `https://${EXTEND_API_HOST}/offers/themes`

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

export function useFetchAllThemesQuery({
  storeId,
  refetchOnMount,
}: {
  storeId: string
  refetchOnMount?: boolean
}): QueryObserverResult<ThemesGetAllResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}?storeId=${storeId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch all themes')
    }

    return response.json()
  }, [accessToken, storeId])

  return useQuery({
    queryKey: [THEMES_CACHE_KEY],
    queryFn,
    refetchOnMount,
    enabled: Boolean(storeId),
  })
}

export function useFetchThemeQuery({
  storeId,
  themeId,
  enabled = true,
  onSuccess = () => {},
}: {
  storeId: string
  themeId: string
  enabled?: boolean
  onSuccess?: (data: Theme) => void
}): QueryObserverResult<Theme, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${themeId}?storeId=${storeId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get theme')
    }

    return response.json()
  }, [accessToken, storeId, themeId])

  return useQuery({
    queryKey: [THEMES_CACHE_KEY, { id: `${storeId}:${themeId}` }],
    queryFn,
    enabled: Boolean(enabled && storeId && themeId),
    onSuccess,
    refetchOnMount: false,
  })
}

export function useCreateThemeMutation(): UseBaseMutationResult<Theme, Error, ThemeBase, void> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (request) => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify(request),
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to create theme')
      }

      await client.invalidateQueries({ queryKey: [THEMES_CACHE_KEY] })

      return response.json()
    },
  })
}

export function useUpdateThemeMutation(): UseBaseMutationResult<
  Theme,
  Error,
  ThemeUpdateRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (request) => {
      const response = await fetch(`${BASE_URL}/${request.themeId}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify(request),
        method: 'PUT',
      })

      if (!response.ok) {
        throw new Error('Unable to create theme')
      }

      await client.invalidateQueries({ queryKey: [THEMES_CACHE_KEY] })

      return response.json()
    },
  })
}

export function usePublishThemeMutation(): UseBaseMutationResult<void, Error, ThemeByIdArgs, void> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, themeId }) => {
      const response = await fetch(`${BASE_URL}/${themeId}/publish?storeId=${storeId}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify({ storeId }),
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to publish theme')
      }

      await client.invalidateQueries({ queryKey: [THEMES_CACHE_KEY] })
    },
  })
}

export function useDeleteThemeMutation(): UseBaseMutationResult<void, Error, ThemeByIdArgs, void> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, themeId }) => {
      const response = await fetch(`${BASE_URL}/${themeId}?storeId=${storeId}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Unable to delete theme')
      }

      await client.invalidateQueries({ queryKey: [THEMES_CACHE_KEY] })
    },
  })
}

export function useUploadThemeLogoMutation(): UseBaseMutationResult<
  string,
  Error,
  UploadThemeLogoRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ base64image }) => {
      const response = await fetch(`${BASE_URL}/logo`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify(base64image),
        method: 'PUT',
      })

      if (!response.ok) {
        throw new Error('Unable to delete theme')
      }

      return response.json()
    },
  })
}

type ButtonRadiusMeasurements = 'px' | 'rem'

interface ThemeBase {
  name: string
  storeId: string
  contents: {
    global: {
      storeLogoUrl: string
      backgroundColor: string
      fontFamily: string
      color: string
      buttonBackgroundColor: string
      buttonFontColor: string
      buttonRadius: {
        size: number
        measurement: ButtonRadiusMeasurements
      }
    }
  }
  cssConfig?: {
    css?: string
    enabled?: boolean
  }
}

export enum ThemePublishedStatus {
  published = 'PUBLISHED',
  unpublished = 'UNPUBLISHED',
}

export interface Theme extends ThemeBase {
  themeId: string
  updatedAt: number
  createdAt: number
  status: ThemePublishedStatus
}

export type ThemeMinimal = Pick<
  Theme,
  'storeId' | 'status' | 'themeId' | 'name' | 'createdAt' | 'updatedAt'
>
type ThemesGetAllResponse = {
  themes: ThemeMinimal[]
}
export type ThemeByIdArgs = {
  storeId: string
  themeId: string
}

type RequiredThemeUpdateFields = Pick<Theme, 'storeId' | 'themeId' | 'name'>
type OptionalThemeUpdateFields = Partial<Pick<Theme, 'cssConfig' | 'contents'>>
export type ThemeUpdateRequest = RequiredThemeUpdateFields & OptionalThemeUpdateFields

export interface UseLazyGetThemeQueryState {
  themeQueryData: Theme | undefined
  isFetching?: boolean
  isLoading?: boolean
  isError?: boolean
}

export interface UploadThemeLogoRequest {
  base64image: string
}
