import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { Box, CurrencyInput, DataProperty, Grid, GridItem } from '@extend/zen'
import { CreateContractsModal } from './create-contracts-modal'
import {
  productAtom,
  orderAtom,
  modalStepAtom,
  offersAtom,
  productDetailsAtom,
  planDetailsAtom,
} from '../../../../atoms/create-contracts-modal'
import { DatePickerInput } from '../../../../components/date-picker-input'
import { currency } from '@extend/client-helpers'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import { useLazyGetOffersQuery } from '../../../../queries/offers'
import { getActiveStoreIdAtom } from '../../../../atoms/stores'

export const CreateContractsModalProductDetails = () => {
  const product = useAtomValue(productAtom)
  const order = useAtomValue(orderAtom)
  const customer = order?.customer
  const setModalStep = useSetAtom(modalStepAtom)
  const [productDetails, setProductDetails] = useAtom(productDetailsAtom)
  const setOffers = useSetAtom(offersAtom)
  const setPlanDetails = useSetAtom(planDetailsAtom)
  const { mutateAsync: getOffer, isLoading: isOffersLoading } = useLazyGetOffersQuery()
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  // Restrict the earliest date a contract can be created to 90 days before the current date
  const minDate = useMemo(() => {
    const date = new Date()
    date.setDate(date.getDate() - 90)
    return date
  }, [])

  const maxDate = useMemo(() => {
    return new Date()
  }, [])

  const initialPurchaseDate = useMemo(() => {
    return order && 'createdAt' in order && order.createdAt > minDate.getTime()
      ? new Date(order?.createdAt).toDateString()
      : ''
  }, [order, minDate])
  if (!productDetails?.purchaseDate && initialPurchaseDate) {
    setProductDetails({
      ...productDetails,
      purchaseDate: new Date(initialPurchaseDate).toDateString(),
    })
  }

  const initialPurchasePrice = `${product?.price?.amount || ''}`
  if (!productDetails?.purchasePrice && initialPurchasePrice) {
    setProductDetails({ ...productDetails, purchasePrice: initialPurchasePrice })
  }

  const handleOnClick = useCallback(async () => {
    try {
      const offers = await getOffer({
        storeId,
        productId: product?.referenceId,
        price: productDetails?.purchasePrice,
        category: product?.category,
        userLocation:
          customer?.shippingAddress?.countryCode && customer?.shippingAddress?.province
            ? `${customer?.shippingAddress?.countryCode}-${customer?.shippingAddress?.province}`
            : '',
      })

      if (offers.length > 0) {
        setOffers(offers)
        setModalStep(CreateContractsModalStep.PlanSelection)
      } else {
        setErrorMsg('No valid plans found. Please check your input details or try again later.')
      }
    } catch (e: unknown) {
      setErrorMsg('An unexpected error occurred. Please try again later.')
    }
  }, [product, productDetails])

  const handleDateChange = (newDate: string) => {
    setProductDetails({ ...productDetails, purchaseDate: newDate })
  }

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductDetails({ ...productDetails, purchasePrice: e.target.value })

    // Clear the error message if the user updates the price
    setErrorMsg(null)

    // In case the user navigated back to this step and changed the price,
    // clear the plan details since those might not be valid anymore
    setPlanDetails({ plan: undefined, numContracts: 1 })
  }

  return (
    <CreateContractsModal
      primaryButton={{
        text: 'Search Available Plans',
        onClick: handleOnClick,
        isDisabled: !productDetails?.purchasePrice || !productDetails?.purchaseDate,
        isProcessing: isOffersLoading,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.ProductSearch)

          // Clear the error message if the user navigates back
          setErrorMsg(null)
        },
      }}
      errorMsg={errorMsg}
    >
      <Box>
        <Grid spacing={4}>
          <GridItem>
            <Grid columns={4}>
              <DataProperty label="Reference ID" value={product?.referenceId} />
              <DataProperty label="Product Name" value={product?.title} />
              <DataProperty label="Category" value={product?.category} />
              <DataProperty
                label="Product List Price"
                value={currency.format(product?.price?.amount, order?.currency)}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <Grid columns={2} spacing={4}>
              <DatePickerInput
                label="Purchase Date"
                helperText="Contract start date, must be within 90 days from today."
                value={productDetails?.purchaseDate || ''}
                onChange={handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
              />
              <CurrencyInput
                label="Purchase Price"
                id="purchasePrice"
                data-cy="purchase-price-input"
                onChange={handlePriceChange}
                value={productDetails?.purchasePrice || ''}
                autoFocus
                currency={order?.currency || 'USD'}
                currencyDisplay="symbol"
              />
            </Grid>
          </GridItem>
        </Grid>
      </Box>
    </CreateContractsModal>
  )
}
