import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Badge } from '@extend/zen'

interface StoreBadgeProps {
  approved: boolean
  enabled: boolean
}
const DashboardSidebarStoreBadge: FC<StoreBadgeProps> = ({ approved, enabled }) => {
  const isStoreLive = approved && enabled

  const displayText = useMemo(() => {
    return isStoreLive ? 'Live' : 'Not Live'
  }, [isStoreLive])

  const badeColor = useMemo(() => {
    return isStoreLive ? 'green' : 'neutral'
  }, [isStoreLive])

  return (
    <Badge
      data-cy="store-dropdown:store-status"
      text={displayText}
      color={badeColor}
      emphasis="low"
      size="small"
    />
  )
}

export { DashboardSidebarStoreBadge }
