import type { FC } from 'react'
import React from 'react'
import styles from './color-preivew.module.css'

type ColorPreviewProps = {
  color: string
}

export const ColorPreview: FC<ColorPreviewProps> = ({ color }) => {
  const colorPreview = color.includes('#') ? color : '#'.concat(color)

  return (
    <div className={styles.container}>
      <div className={styles.preview} style={{ color: colorPreview }} />
      <p className={styles.text}>{colorPreview}</p>
    </div>
  )
}
