import type { FC } from 'react'
import React from 'react'
import { Card } from '@helloextend/merchants-ui'
import { Button } from '@extend/zen'
import { Small } from '@helloextend/merchants-ui/src/components/typography'
import { currency, date } from '@extend/client-helpers'
import { Link } from 'react-router-dom'
import type { MerchantContract } from '../../../types/merchant-contract'
import {
  getContractPlanCoverageType,
  getContractServiceType,
} from '../../../utils/contract-mappers'
import styles from './contract-details-card.module.css'
import { extractPricingInfo } from '../../../utils/map-contract-response-per-api-version'

export const ContractDetailsCard: FC<{ contract: MerchantContract }> = ({ contract }) => {
  const { transactionDate, coverage, coverageAmount, planDetails } = contract
  const contractPurchaseDate = transactionDate
    ? date.format(transactionDate, date.standardDateFormat)
    : '--'

  const contractCoverageStartDate = coverage?.starts
    ? date.format(coverage.starts, date.standardDateFormat)
    : '--'
  const contractCoverageEndDate = coverage?.ends
    ? date.format(coverage.ends, date.standardDateFormat)
    : '--'

  const contractCoverageTerms = `${contractCoverageStartDate} - ${contractCoverageEndDate}`
  const planCoverageType = planDetails
    ? getContractPlanCoverageType({
        coverageIncludes: planDetails.coverage_includes,
        pcmiSubcategory: planDetails.pcmi_subcategory,
        termLength: planDetails.term_length,
      })
    : null

  const coverageAmountFormatted = currency.formatWithoutCurrencySymbol(
    extractPricingInfo(coverageAmount ?? 0),
    contract.currency,
    true,
  )

  const serviceType = planDetails ? getContractServiceType(planDetails.service_type) : null

  return (
    <Card dataQa={`contract-search-item-${contract.id}`}>
      <div className={styles['flex-wrapper']}>
        <div className={styles.figure}>
          <div className={styles['image-wrapper']}>
            <img
              className={styles.image}
              src={contract.product.imageUrl}
              alt={contract.product.title}
            />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles['flex-wrapper']}>
            {/*
             * Product/store details and contract/plan details
             * are designed to be in separated columns for large screens
             */}
            <div className={styles['details-column']}>
              {[
                ['Product Name', 'productTitle', contract.product.title],
                ['Product Serial #', 'productSerialNumber', contract.product.serialNumber],
                ['Transaction ID', 'transactionId', contract.product.transactionId],
                ['Store Name', 'storeName', contract.sellerName],
                ['Purchase Date', 'purchaseDate', contractPurchaseDate],
              ].map(([label, dataQaAttribute, value]) => {
                if (!value) return null
                return (
                  <div data-qa={`contract.${dataQaAttribute}`} key={value}>
                    <Small>{label}</Small>
                    <div>{value}</div>
                  </div>
                )
              })}
            </div>
            <div className={styles['details-column']}>
              {[
                ['Contract ID', 'id', contract.id],
                ['Plan Coverage Type', 'planCoverage', planCoverageType],
                ['Coverage Term', 'coverageTerms', contractCoverageTerms],
                ['Coverage Amount', 'coverageAmount', coverageAmountFormatted],
                ['Service Type', 'planServiceType', serviceType],
              ].map(([label, dataQaAttribute, value]) => {
                if (!value) return null
                return (
                  <div data-qa={`contract.${dataQaAttribute}`} key={value}>
                    <Small>{label}</Small>
                    <div>{value}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.buttons}>
            <Link to={`/store/claims/new/contracts/${contract.id}/create-claim`}>
              <Button text="Start a Claim" />
            </Link>
          </div>
        </div>
      </div>
    </Card>
  )
}
