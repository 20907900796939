// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1XCwq7HylzLWWTGZegPtgW {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    color: var(--neutral-1000);\n    font-weight: 800;\n}\n\n._3mxQDSZkNWm3RpcA5QVT5e {\n  font-size: 14px;\n  line-height: 20px;\n  padding: 13px 16px;\n}\n\n._18mp5_djqSuVnxjF28n7O {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"store-name-wrapper": "_1XCwq7HylzLWWTGZegPtgW",
	"active-store-wrapper": "_3mxQDSZkNWm3RpcA5QVT5e",
	"store-dropdown-list": "_18mp5_djqSuVnxjF28n7O"
};
module.exports = exports;
