import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import { isArray } from 'lodash'
import { orderAtom, modalStepAtom } from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import { Box, Checkbox, Input, Select } from '@extend/zen'
import { Money } from '@helloextend/currency'
import { DataPropertyDetails } from '../../../../components/data-property-grid'
import {
  getCustomerInfoFieldsFromOrder,
  getInitialValues,
  getNewOrderFromFormValues,
} from '../../../../utils/customer-info-fields'
import { SubHeader } from '../../../../components/sub-header'
import { useFormik } from 'formik'
import { customerInfoSchema } from './customer-info.schema'
import styles from './create-contracts-modal-order-details.module.css'
import { CreateContractsModal } from './create-contracts-modal'
import { CustomerInformationGrid } from './customer-information-grid'
import { countries, getProvinceCodes } from '../../../../utils/form-utils'

export const CreateContractsModalOrderDetails: FC = () => {
  const [isSameAsShipping, setisSameAsShipping] = useState(false)
  const setModalStep = useSetAtom(modalStepAtom)

  // fetch the order details based on the transaction ID atom's value
  const [order, setOrder] = useAtom(orderAtom)

  // determine if the user is in view-only mode
  // (i.e., the order already exists and has already been set by the previous step)
  // or if the user is creating a new order.
  const isViewOnly = order && 'createdAt' in order && order.createdAt

  // extract the customer information fields from the order data, if it exists
  const fields: ReturnType<typeof getCustomerInfoFieldsFromOrder> =
    getCustomerInfoFieldsFromOrder(order)

  // formik form state and handlers, with validation schema
  const { values, setValues, errors, handleChange, submitForm, validateForm } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: customerInfoSchema,
    onSubmit: (formValues): void => {
      setOrder(getNewOrderFromFormValues(formValues))
    },
    initialValues: getInitialValues(fields),
  })

  // state + handler for manual checkbox used by the user to set shipping address same as billing address.
  // this is only used when creating a new order.
  const handleClickSameAsShipping = async (): Promise<void> => {
    if (isSameAsShipping) {
      setisSameAsShipping(false)
    } else {
      await setValues({
        ...values,
        billingAddress1: values.shippingAddress1,
        billingAddress2: values.shippingAddress2,
        billingCity: values.shippingCity,
        billingCountryCode: values.shippingCountryCode,
        billingPostalCode: values.shippingPostalCode,
        billingProvinceCode: values.shippingProvinceCode,
      })
      setisSameAsShipping(true)
    }
  }

  const shippingProvinceLabel = useMemo(() => {
    return values.shippingCountryCode === 'US' ? 'State' : 'Province'
  }, [values.shippingCountryCode])
  const shippingProvinceValues = useMemo(() => {
    return values.shippingCountryCode ? getProvinceCodes(values.shippingCountryCode) : []
  }, [values.shippingCountryCode])
  const billingProvinceLabel = useMemo(() => {
    return values.billingCountryCode === 'US' ? 'State' : 'Province'
  }, [values.billingCountryCode])
  const billingProvinceValues = useMemo(() => {
    return values.billingCountryCode ? getProvinceCodes(values.billingCountryCode) : []
  }, [values.billingCountryCode])

  if (isViewOnly) {
    return (
      <CreateContractsModal
        // the modal's secondary button & onDismiss default behavior is fine, no need to override it here
        subHeading={"Here's the associated customer information we found. Please confirm."}
        primaryButton={{
          text: 'Next',
          // if the user is in view-only mode, the primary button should simply move the user to the next step.
          onClick: () => {
            setModalStep(CreateContractsModalStep.ProductSearch)
          },
          isDisabled: false,
          isProcessing: false,
        }}
        secondaryButton={{
          text: 'Back',
          onClick: () => {
            setModalStep(CreateContractsModalStep.OrderSearch)
            setOrder(undefined)
          },
        }}
      >
        {/* if the user is in view-only mode, display the customer information fields as read-only. */}
        <Box data-cy="customer-information">
          <SubHeader labelText="Customer Information" />
          <CustomerInformationGrid order={order} />
        </Box>
      </CreateContractsModal>
    )
  }

  return (
    <CreateContractsModal
      subHeading={'A new order will be created upon saving.'}
      primaryButton={{
        // the modal's secondary button & onDismiss default behavior is fine, no need to override it here
        text: 'Next',
        // if the user is creating a new order, the primary button should validate the form and then submit it.
        onClick: () => {
          validateForm().then((errors) => {
            if (Object.keys(errors).length) {
              console.error('form is invalid!! 🫠', errors)
            } else
              submitForm().then(() => {
                setModalStep(CreateContractsModalStep.ProductSearch)
              })
          })
        },
        isDisabled: false,
        isProcessing: false,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.OrderSearch)
          setOrder(undefined)
        },
      }}
    >
      {/* if the user is creating a new order, display the customer information fields as editable. */}
      <form className={styles['form-container']}>
        <SubHeader labelText="Customer Information" />
        <section className={styles['four-input-grid']}>
          {fields.customerInformation.map((v: DataPropertyDetails) => {
            const err = errors[v.key]
            const hasErrors = Boolean(err)
            const errorMessage = isArray(err) ? err.join(' ') : (err as string)
            return v.key === 'currencyCode' ? (
              <Select
                key={v.key}
                onChange={handleChange}
                id={v.key}
                label={v.label}
                value={values[v.key]}
                placeholder="—Select—"
                children={Object.keys(Money.currencies).map((currencyCode) => {
                  return (
                    <option key={currencyCode} value={currencyCode}>
                      {currencyCode}
                    </option>
                  )
                })}
                isError={hasErrors}
                errorFeedback={errorMessage}
              />
            ) : (
              <Input
                key={v.key}
                onChange={handleChange}
                id={v.key}
                label={v.label}
                value={values[v.key]}
                isError={hasErrors}
                errorFeedback={errorMessage}
              />
            )
          })}
        </section>
        <h4 className={styles['h4-styles']}>SHIPPING INFORMATION</h4>
        <section className={styles['six-input-grid']}>
          {fields.shippingInformation.map((v: DataPropertyDetails) => {
            const err = errors[v.key]
            const hasErrors = Boolean(err)
            const errorMessage = isArray(err) ? err.join(' ') : (err as string)

            if (v.key === 'shippingProvinceCode') {
              return (
                <Select
                  key={v.key}
                  isError={hasErrors}
                  id={v.key}
                  label={shippingProvinceLabel}
                  onChange={handleChange}
                  value={values[v.key]}
                  errorFeedback={errorMessage}
                  placeholder="—Select—"
                  isDisabled={!values.shippingCountryCode}
                  helperText={'Determines the available offers for a product.'}
                >
                  {shippingProvinceValues.map((state: string) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              )
            } else if (v.key === 'shippingCountryCode') {
              return (
                <Select
                  key={v.key}
                  isError={hasErrors}
                  id={v.key}
                  label={v.label}
                  onChange={handleChange}
                  value={values[v.key]}
                  errorFeedback={errorMessage}
                  placeholder="—Select—"
                  helperText={'Determines the available offers for a product.'}
                >
                  {Object.entries(countries).map(([countryCode, country]) => (
                    <option key={countryCode} value={countryCode}>
                      {country}
                    </option>
                  ))}
                </Select>
              )
            } else {
              return (
                <Input
                  key={v.key}
                  onChange={handleChange}
                  id={v.key}
                  label={v.label}
                  value={values[v.key]}
                  isError={hasErrors}
                  errorFeedback={errorMessage}
                />
              )
            }
          })}
        </section>
        <h4 className={styles['h4-styles']}>BILLING INFORMATION</h4>
        <Checkbox
          label="Billing Information is the same as Shipping Address"
          checked={isSameAsShipping}
          onChange={handleClickSameAsShipping}
          data-cy="same-as-shipping-checkbox"
        />
        <section className={styles['six-input-grid']}>
          {fields.billingInformation.map((v: DataPropertyDetails, i) => {
            const err = errors[v.key]
            const hasErrors = Boolean(err)
            const errorMessage = isArray(err) ? err.join(' ') : (err as string)
            const value = isSameAsShipping
              ? values[fields.shippingInformation[i].key]
              : values[v.key]
            if (v.key === 'billingProvinceCode') {
              return (
                <Select
                  key={v.key}
                  isError={hasErrors}
                  id={v.key}
                  label={billingProvinceLabel}
                  onChange={handleChange}
                  value={values[v.key]}
                  errorFeedback={errorMessage}
                  placeholder="—Select—"
                  isDisabled={!values.billingCountryCode || isSameAsShipping}
                >
                  {billingProvinceValues.map((state: string) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              )
            } else if (v.key === 'billingCountryCode') {
              return (
                <Select
                  key={v.key}
                  isError={hasErrors}
                  id={v.key}
                  label={v.label}
                  onChange={handleChange}
                  value={values[v.key]}
                  errorFeedback={errorMessage}
                  placeholder="—Select—"
                  isDisabled={isSameAsShipping}
                >
                  {Object.entries(countries).map(([countryCode, country]) => (
                    <option key={countryCode} value={countryCode}>
                      {country}
                    </option>
                  ))}
                </Select>
              )
            } else {
              return (
                <Input
                  isDisabled={isSameAsShipping}
                  onChange={handleChange}
                  id={v.key}
                  label={v.label}
                  value={value}
                  key={v.key}
                  isError={hasErrors}
                  errorFeedback={errorMessage}
                />
              )
            }
          })}
        </section>
      </form>
    </CreateContractsModal>
  )
}
