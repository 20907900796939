import type { FC, ReactNode } from 'react'
import React from 'react'
import { AccordionSection, Box } from '@extend/zen'
import type { InformationSize } from '@extend/zen'
import type { BadgeProps } from '@extend/zen'
import styles from './collapsible-section.module.css'

export const CollapsibleSection: FC<{
  heading?: string
  isExpanded?: boolean
  'data-cy'?: string
  toolBar?: ReactNode
  onToggleRequest?: () => void
  badgeProps?: Pick<BadgeProps, 'color' | 'text'>
  information?: React.ReactNode
  informationPopoverSize?: InformationSize
}> = ({
  heading,
  children,
  isExpanded = false,
  'data-cy': dataCy,
  toolBar,
  onToggleRequest,
  badgeProps,
  information,
  informationPopoverSize,
}) => {
  return (
    <Box>
      <div className={styles['additional-padding']}>
        <AccordionSection
          heading={heading || ''}
          isExpanded={isExpanded}
          data-cy={dataCy}
          toolbar={toolBar}
          onToggleRequest={onToggleRequest}
          badgeProps={badgeProps}
          information={information}
          informationPopoverSize={informationPopoverSize}
        >
          {children}
        </AccordionSection>
      </div>
    </Box>
  )
}
