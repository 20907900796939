import type { FC } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSetAtom } from 'jotai/react'
import { LDFlag } from '../constants/ld-flags'
import { Customize } from '../features/store/customize/customize'
import { CustomizeManageThemes } from '../features/store/customize/customize-manage-themes'
import { Products } from '../features/store/products'
import { Product } from '../features/store/product/product'
import { Settings } from '../features/store/settings/settings'
import { PaymentAuthorization } from '../features/store/settings/payment-authorization'
import { ClaimsContractSearchResults } from '../features/store/claims-contract-search-results/claims-contract-search-results'
import { ClaimsContractDetails } from '../features/store/claims-contract-details/claims-contract-details'
import { ClaimsManagement } from '../features/store/claims-management'
import { ContractsCreateClaim as ContractsCreateClaimNew } from '../features/store/contract/contracts-create-claim/contracts-create-claim'
import { ClaimDetails } from '../features/store/contract/claim-details'
import { Claims } from '../features/store/claims/claims'
import { ClaimsDownload } from '../features/store/claims/claims-download'
import { Contracts } from '../features/store/contracts/contracts'
import { ContractsDownload } from '../features/store/contracts-download'
import { ProductsDownload } from '../features/store/products-download'
import { DashboardLayout } from '../components/dashboard-layout'
import { DashboardSection } from '../constants/dashboard-sections'
import { ProtectedRoute } from '../components/protected-route'
import { Analytics } from '../features/store/analytics/analytics'
import { AddNewStore } from '../features/store/add-new-store'
import { Permission } from '../lib/permissions'
import { useQueryStore } from '../hooks/use-query-store'
import { usePermissions } from '../hooks/use-permissions'
import { Claim } from '../features/store/claim/claim'
import { ProductVariantsRdb } from '../features/store/product-variants-rdb'
import { useSplashScreen } from '../hooks/use-splash-screen'
import { Contract } from '../features/store/contract/contract'
import { Lead } from '../features/store/lead/lead'
import { FileClaim } from '../features/store/file-claim/file-claim'
import { Leads } from '../features/store/leads/leads'
import { LeadsDownload } from '../features/store/leads-download'
import { getClaimsUrl, getContractsUrl } from '../utils/route-helper'
import {
  shouldShowSupportPopoverAtom,
  shouldShowSupportPopoverContactFormAtom,
} from '../atoms/support'

export const Store: FC = () => {
  const { afterLoading } = useSplashScreen('pages:store')
  const { store, isLoaded } = useQueryStore()
  const { hasPermission } = usePermissions()
  const STORE_PATH = '/store'
  const location = useLocation()
  /**
   * Using useRef to avoid overwriting shouldShowSupportPopupByDefault
   * when re-rendering occurs from redirects below
   * */
  const supportRef = useRef({
    shouldShowSupportPopupByDefault: location.pathname.includes('submit-support-ticket'),
  })
  const setShouldShowSupportPopoverContactForm = useSetAtom(shouldShowSupportPopoverContactFormAtom)
  const setShouldShowSupportPopover = useSetAtom(shouldShowSupportPopoverAtom)

  const { [LDFlag.EnterpriseInitiative]: FF_ENTERPRISE_INITIATIVE } = useFlags()

  const rootRedirectTo = useMemo(() => {
    const isStoreApproved = store?.approved || store?.shippingProtection?.approved
    const shouldShowUnapprovedLandingPage = FF_ENTERPRISE_INITIATIVE && !isStoreApproved
    if (supportRef.current.shouldShowSupportPopupByDefault) {
      setShouldShowSupportPopoverContactForm(true)
      setShouldShowSupportPopover(true)
    }
    if (!store && hasPermission(Permission.ManageStores)) {
      return '/store/add-new-store'
    }
    if (store && shouldShowUnapprovedLandingPage) {
      return '/store/account/welcome'
    }
    if (store && hasPermission(Permission.StoreContractsView)) {
      return getContractsUrl()
    }
    if (store?.claimsManagementEnabled && hasPermission(Permission.StoreClaimsView)) {
      return getClaimsUrl()
    }
    return '/profile'
  }, [
    store,
    FF_ENTERPRISE_INITIATIVE,
    hasPermission,
    setShouldShowSupportPopoverContactForm,
    setShouldShowSupportPopover,
  ])

  useEffect(() => {
    afterLoading()
  }, [afterLoading])

  if (!isLoaded) {
    return null
  }
  return (
    <DashboardLayout section={DashboardSection.Stores}>
      <Switch>
        <Redirect from={STORE_PATH} to={rootRedirectTo} exact />
        <Redirect from={`${STORE_PATH}/submit-support-ticket`} to={rootRedirectTo} exact />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.ProductsView]}
          path={`${STORE_PATH}/products`}
          component={Products}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.ProductsView]}
          exact
          path={`${STORE_PATH}/products/csv-download`}
          component={ProductsDownload}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.ProductsView]}
          path={`${STORE_PATH}/products/:referenceId`}
          component={Product}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.ProductsView]}
          path={`${STORE_PATH}/products/:referenceId/variants/:variantId`}
          component={Product}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.ProductsView]}
          path={`${STORE_PATH}/products/:referenceId/variants`}
          component={ProductVariantsRdb}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.ManageStores]}
          path={`${STORE_PATH}/add-new-store`}
          component={AddNewStore}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.CustomizeView]}
          path={`${STORE_PATH}/customize`}
          component={Customize}
        />

        <ProtectedRoute
          exact
          requiredPermissions={[Permission.CustomizeFullAccess]}
          path={`${STORE_PATH}/customize/themes`}
          component={CustomizeManageThemes}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreSettingsView]}
          exact
          path={`${STORE_PATH}/settings`}
          component={Settings}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreSettingsView]}
          exact
          path={`${STORE_PATH}/settings/payment-authorization`}
          component={PaymentAuthorization}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims/new`}
          component={ClaimsManagement}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims/file`}
          component={FileClaim}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims/new/search`}
          component={ClaimsContractSearchResults}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims/new/contracts/:contractId`}
          component={ClaimsContractDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims`}
          component={Claims}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/claims/csv-download`}
          component={ClaimsDownload}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreContractsView]}
          exact
          path={`${STORE_PATH}/contracts/csv-download`}
          component={ContractsDownload}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreContractsView]}
          exact
          path={`${STORE_PATH}/contracts`}
          component={Contracts}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/contracts/:contractId/claims/new`}
          component={ContractsCreateClaimNew}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/contracts/:contractId/products/:lineItemId/claims/new`}
          component={ContractsCreateClaimNew}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          exact
          path={`${STORE_PATH}/contracts/:contractId/claims/new/:claimId`}
          component={ClaimDetails}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.StoreContractsView]}
          path={`${STORE_PATH}/contracts/:contractId`}
          component={Contract}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.StoreClaimsView]}
          path={`${STORE_PATH}/claims/:claimId`}
          component={Claim}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewStoreAnalytics]}
          path={`${STORE_PATH}/analytics`}
          component={Analytics}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.LeadsView]}
          exact
          path={`${STORE_PATH}/leads/download`}
          component={LeadsDownload}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.LeadsView]}
          path={[`${STORE_PATH}/leads`, `${STORE_PATH}/leads/leads-email-management`]}
          exact
          component={Leads}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.LeadsView]}
          exact
          path={`${STORE_PATH}/leads/:leadToken`}
          component={Lead}
        />
        <Redirect to="/404" />
      </Switch>
    </DashboardLayout>
  )
}
