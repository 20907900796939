import type { FC } from 'react'
import React from 'react'
import styles from './empty-results-message.module.css'

interface EmptyResultsMessageProps {
  searchValue: string
}

export const EmptyResultsMessage: FC<EmptyResultsMessageProps> = ({ searchValue }) => {
  return (
    <div className={styles.message}>
      <h2 className={styles.heading}>{`We can't find a match for “${searchValue}”`}</h2>
      <div>
        Double check that the email or ID is correct&#44; or try finding the customer&apos;s
        contract with another search criteria.
      </div>
    </div>
  )
}
