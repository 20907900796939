import type { FC, MouseEvent } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { DateValue } from '@extend/zen'
import { DatePicker as DatePickerComponent, Input } from '@extend/zen'
import styles from './date-picker-input.module.css'

type DatePickerInputProps = {
  value: string | null
  onChange: (dateString: string) => void
  label: string
  helperText: string
  maxDate?: Date
  minDate?: Date
  isDisabled?: boolean
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
  value,
  onChange,
  label,
  helperText,
  maxDate,
  minDate,
  isDisabled = false,
}) => {
  const datePickerRef = useRef<HTMLDivElement>(null)
  const [openCalendar, setOpenCalendar] = useState(false)

  const noop = useCallback(() => {}, [])
  const onDateChange = useCallback(
    (date: DateValue) => {
      const newDate = date || new Date()
      onChange(
        newDate.toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      )
      setOpenCalendar(false)
    },
    [onChange],
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent<HTMLDivElement> | TouchEvent): void => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as HTMLDivElement)
      ) {
        setOpenCalendar(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside as EventListener)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside as EventListener)
    }
  }, [datePickerRef])

  return (
    <div ref={datePickerRef}>
      <Input
        ariaLabel="Date Picker Input"
        data-cy="date-picker-input-item"
        id="date-picker-input"
        label={label}
        helperText={helperText}
        value={value || ''}
        onChange={noop}
        onFocus={() => setOpenCalendar(true)}
        isDisabled={isDisabled}
      />
      {openCalendar && (
        <div className={styles.datepicker}>
          <DatePickerComponent
            onChange={onDateChange}
            value={new Date(value || Date.now())}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      )}
    </div>
  )
}
