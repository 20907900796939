import React from 'react'
import type { CellProps, Column } from 'react-table'
import { currency } from '@extend/client-helpers'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'
import { CellText } from '@helloextend/merchants-ui'
import { Badge } from '@extend/zen'
import { ProductImageCell } from '../product-image-cell'
import { ProductOfferStatusVariants } from '../product-offer-status-variants'

export interface ProductRowData extends ProductsSearchItem {
  [key: string]: unknown
}

export const productColumns: Array<Column<ProductRowData>> = [
  {
    Header: 'Image',
    headerTooltip: 'The image of the product sold in the webstore',
    accessor: 'imageUrl',
    disableSortBy: true,
    width: 5,
    Cell: ({ value }: CellProps<ProductsSearchItem, string>): JSX.Element => {
      return <ProductImageCell title={value} imageUrl={value} size="small" />
    },
  },
  {
    Header: 'Name',
    headerTooltip: 'The name of the product displayed to customers in the webstore',
    accessor: 'name',
    disableSortBy: false,
    width: 30,
    Cell: ({ row }: CellProps<ProductsSearchItem, string>): JSX.Element => {
      const { variantCount, name } = row.original
      const hasVariants = variantCount !== 0
      return (
        <CellText data-cy="name">
          {hasVariants && (
            <span style={{ marginRight: 5 }}>
              <Badge data-cy="variant-count-badge" text={variantCount.toString()} size="small" />
            </span>
          )}
          {name}
        </CellText>
      )
    },
  },
  {
    Header: 'Reference Id',
    headerTooltip: 'The ID of the product in your system, typically the SKU number',
    accessor: 'referenceId',
    disableSortBy: false,
    width: 10,
    Cell: ({ value }: CellProps<ProductsSearchItem, string>): JSX.Element => (
      <div
        className="overflow-ellipsis whitespace-nowrap overflow-hidden"
        data-cy="product-referenceId"
      >
        {value}
      </div>
    ),
  },
  {
    Header: 'Sku #',
    headerTooltip: 'The SKU # of the product in your system',
    accessor: 'sku',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: CellProps<ProductsSearchItem, string>): JSX.Element => (
      <span data-cy="product-skuId">{value}</span>
    ),
  },
  {
    Header: 'Price',
    headerTooltip: 'The price and currency code of the product as listed in the webstore',
    accessor: 'price',
    disableSortBy: true,
    width: 10,
    Cell: (cell: CellProps<ProductsSearchItem, number>): JSX.Element => {
      const data = cell.row.original
      return (
        <CellText data-cy="product-price">
          {data.variantCount !== 0 ? '—' : currency.format(data.price)}
        </CellText>
      )
    },
  },
  {
    Header: 'Display Offer',
    headerTooltip: 'Controls the display of the Product Protection Offer in the webstore',
    accessor: 'enabled',
    disableSortBy: true,
    width: 10,

    Cell: (cell: CellProps<ProductsSearchItem, boolean>): JSX.Element => {
      const data = cell.row.original
      return <ProductOfferStatusVariants enabled={data.enabled || false} product={data} />
    },
  },
]

export const searchOptions = [
  { label: 'Reference ID', value: 'referenceId' },
  { label: 'SKU #', value: 'sku' },
  { label: 'Name', value: 'name' },
]
