import type { FC } from 'react'
import React from 'react'

export const ShippingCarrierSelectOptions: FC = () => (
  <>
    <option value="dhl_express">DHL Express</option>
    <option value="fedex_ground">FedEx Ground</option>
    <option value="fedex_standard_overnight">FedEx Overnight</option>
    <option value="newgistics">Newgistics</option>
    <option value="ontrac">OnTrac</option>
    <option value="usps">USPS</option>
    <option value="ups">UPS</option>
  </>
)
