import type { DataTableAction, VisibilityState } from '@extend/zen'
import { DataTable } from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { ContractsTableMapping } from './contract-data-table-config'
import { TABLE_COLUMNS, TABLE_FILTERS } from './contract-data-table-config'
import { useContractsTableSearch } from '../../../hooks/use-contracts-table-search'
import { useContractsColumnFiltersWithQueryString } from '../../../hooks/use-contracts-column-filters-with-qs'

const ContractsDataTable: FC = () => {
  const { push } = useHistory()
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    planId: false,
  })

  const { columnFilters, setColumnFilters, searchParams } =
    useContractsColumnFiltersWithQueryString()

  const { isLoading, tableItems } = useContractsTableSearch(searchParams)

  const getRowActions = (row: ContractsTableMapping): DataTableAction[] => [
    {
      onClick: () => push(`/store/contracts/${row.contractId}`),
      text: 'View',
      emphasis: 'low',
    },
  ]

  return (
    <DataTable
      columnFilters={columnFilters}
      columns={TABLE_COLUMNS}
      data-cy="contracts-table"
      data={tableItems}
      filterDefs={TABLE_FILTERS}
      getRowActions={getRowActions}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      hasManualFiltering
      hasRequiredFilter
      searchInputMaxWidth={660}
      isLoading={isLoading}
      itemName="contract"
      onColumnFiltersChange={setColumnFilters}
      sorting={[{ id: 'purchaseDate', desc: true }]}
      stateViews={{
        preFilter: {
          description: 'Select your search criteria before submitting.',
        },
        noData: {
          description: 'Select your search criteria before submitting.',
        },
      }}
    />
  )
}

export { ContractsDataTable }
