import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { NameThemeModal } from './name-theme-modal'
import { DeleteModal } from './delete-modal'
import { WarningModal } from './warning-modal'
import { PublishModal } from './publish-modal'
import {
  createThemeNameAtom,
  currentlySelectedThemeIdAtom,
  draftThemeGlobalPropertiesAtom,
  hasChangesToPublishedThemeAtom,
  isCreateThemeModalVisibleAtom,
  isDeleteThemeModalVisibleAtom,
  isPublishThemeModalVisibleAtom,
  isRenameThemeModalVisibleAtom,
  isWarningModalVisibleAtom,
  renameThemeDisplayAtom,
  requestedSelectedThemeAtom,
  themeToDeleteAtom,
} from '../../../atoms/customize-theme'
import { useSelectedTheme } from '../../../hooks/use-get-selected-theme'
import { useGetPublishedTheme } from '../../../hooks/use-get-published-theme'
import { mapGlobalOverrideToTheme } from '../../../schemas/customize-schema'
import type { ThemeUpdateRequest } from '../../../queries/themes'
import {
  useCreateThemeMutation,
  useDeleteThemeMutation,
  usePublishThemeMutation,
  useUpdateThemeMutation,
} from '../../../queries/themes'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

type ModalContainerProps = {
  resetForm?: () => void
}

const ModalsContainer: FC<ModalContainerProps> = ({ resetForm }) => {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const storeId = useAtomValue(getActiveStoreIdAtom)
  const { theme: currentlySelectedTheme } = useSelectedTheme()
  const publishedTheme = useGetPublishedTheme()

  const { mutateAsync: updateTheme, isLoading, isSuccess, error, reset } = useUpdateThemeMutation()

  const [createThemeName, setCreateThemeName] = useAtom(createThemeNameAtom)
  const [isCreateThemeModalVisible, setIsCreateThemeModalVisible] = useAtom(
    isCreateThemeModalVisibleAtom,
  )
  const [isRenameThemeModalVisible, setIsRenameThemeModalVisible] = useAtom(
    isRenameThemeModalVisibleAtom,
  )
  const [isWarningModalVisible, setIsWarningModalVisible] = useAtom(isWarningModalVisibleAtom)
  const [isDeleteThemeModalVisible, setIsDeleteThemeModalVisible] = useAtom(
    isDeleteThemeModalVisibleAtom,
  )
  const [isPublishThemeModalVisible, setIsPublishThemeModalVisible] = useAtom(
    isPublishThemeModalVisibleAtom,
  )
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const [requestedSelectedTheme, setRequestedSelectedTheme] = useAtom(requestedSelectedThemeAtom)
  const [hasChangesToPublishedTheme, setHasChangesToPublishedTheme] = useAtom(
    hasChangesToPublishedThemeAtom,
  )

  const [draftThemeGlobalProperties] = useAtom(draftThemeGlobalPropertiesAtom)
  const [themeToDelete] = useAtom(themeToDeleteAtom)
  const [renameThemeDisplay] = useAtom(renameThemeDisplayAtom)

  const {
    mutateAsync: deleteTheme,
    isSuccess: isDeletedSuccess,
    isLoading: isDeleteProcessing,
    error: deleteError,
    reset: resetDeleteHook,
  } = useDeleteThemeMutation()
  const {
    mutateAsync: createTheme,
    isLoading: isCreateProcessing,
    isSuccess: isCreateSuccess,
    error: createError,
    reset: resetCreateHook,
    data: createThemeResponse,
  } = useCreateThemeMutation()
  const {
    mutateAsync: publishTheme,
    isSuccess: isPublishSuccess,
    isLoading: isPublishProcessing,
    error: publishError,
    reset: resetPublishHook,
  } = usePublishThemeMutation()

  const isUpdateOrCreateProcessing = isLoading || isCreateProcessing
  const isUpdateOrCreateSuccess = isSuccess || isCreateSuccess
  const updateOrCreateError = error ?? createError ?? undefined
  const resetWarningModalSaveHooks = (): void => {
    reset()
    resetCreateHook()
  }

  // only reset the form on an update mutation
  if (resetForm && isSuccess) {
    resetForm()
  }

  const isDraftTheme = Boolean(createThemeName)

  // ------ Modal Toggles ------
  const handleToggleCreateTheme = (): void => {
    setIsCreateThemeModalVisible(!isCreateThemeModalVisible)
  }

  const handleToggleRenameTheme = (): void => {
    setIsRenameThemeModalVisible(!isRenameThemeModalVisible)
    setHasChangesToPublishedTheme(false)
  }

  const handleToggleWarningModal = (): void => {
    setIsWarningModalVisible(!isWarningModalVisible)
  }

  const handleToggleDeleteTheme = (): void => {
    setIsDeleteThemeModalVisible(!isDeleteThemeModalVisible)
  }

  const handleTogglePublishTheme = (): void => {
    setIsPublishThemeModalVisible(!isPublishThemeModalVisible)
  }

  // ------ Modal Handlers ------
  const handleCreateThemeConfirm = (themeName: string): void => {
    setCreateThemeName(themeName)

    if (pathname.includes('/customize/themes')) {
      push('/store/customize')
    }
    handleToggleCreateTheme()
  }

  const handleRenameThemeConfirm = (themeName: string): void => {
    if (hasChangesToPublishedTheme && draftThemeGlobalProperties) {
      createTheme({
        name: themeName,
        storeId,
        contents: {
          global: { ...draftThemeGlobalProperties },
        },
      })
    } else if (isDraftTheme) {
      setCreateThemeName(themeName)
      handleToggleRenameTheme()
    } else if (currentlySelectedTheme?.themeId) {
      const updatedTheme: ThemeUpdateRequest = {
        ...currentlySelectedTheme,
        name: themeName,
        storeId,
      }
      updateTheme(updatedTheme)
    }
  }

  const handleDeleteThemeConfirm = (): void => {
    if (isDraftTheme && resetForm) {
      resetForm()
      handleToggleDeleteTheme()
    } else {
      deleteTheme({ storeId, themeId: themeToDelete?.themeId ?? '' })
    }
  }

  const handleWarningModalSave = (): void => {
    if (createThemeName && draftThemeGlobalProperties) {
      createTheme({
        name: createThemeName,
        storeId,
        contents: {
          global: { ...draftThemeGlobalProperties },
        },
      })
    }

    if (!createThemeName && currentlySelectedTheme && draftThemeGlobalProperties) {
      const payload = mapGlobalOverrideToTheme(currentlySelectedTheme, draftThemeGlobalProperties)
      updateTheme(payload)
    }
  }

  const handleWarningModalSkip = (): void => {
    setCurrentThemeId(requestedSelectedTheme?.themeId ?? '')

    if (isDraftTheme) {
      setCreateThemeName(null)
    }

    setIsWarningModalVisible(!isWarningModalVisible)
    setRequestedSelectedTheme(null)

    if (resetForm) {
      resetForm()
    }
  }

  const handlePublishTheme = (): void => {
    if (currentlySelectedTheme && storeId) {
      publishTheme({ storeId, themeId: currentlySelectedTheme.themeId })
    }
  }

  // ------ Logic Functions ------

  const getRenameThemeName = useCallback((draftThemeName, displayThemeName, selectedThemeName) => {
    return draftThemeName || displayThemeName || selectedThemeName
  }, [])

  return (
    <>
      {isCreateThemeModalVisible && (
        <NameThemeModal
          isVisible={isCreateThemeModalVisible}
          onConfirm={handleCreateThemeConfirm}
          onCancel={handleToggleCreateTheme}
          resetForm={reset}
        />
      )}
      {isRenameThemeModalVisible && (
        <NameThemeModal
          isVisible={isRenameThemeModalVisible}
          onConfirm={handleRenameThemeConfirm}
          onCancel={handleToggleRenameTheme}
          title={hasChangesToPublishedTheme ? 'New Theme Name' : 'Rename Theme'}
          themeName={getRenameThemeName(
            createThemeName,
            renameThemeDisplay,
            currentlySelectedTheme?.name,
          )}
          isProcessing={isUpdateOrCreateProcessing}
          isSuccess={isUpdateOrCreateSuccess}
          error={updateOrCreateError}
          resetForm={reset}
          createdThemeId={createThemeResponse?.themeId}
        />
      )}
      {isWarningModalVisible && (
        <WarningModal
          isVisible={isWarningModalVisible}
          handleCloseModal={handleToggleWarningModal}
          handleSave={handleWarningModalSave}
          handleSkip={handleWarningModalSkip}
          isProcessing={isUpdateOrCreateProcessing}
          isSuccess={isUpdateOrCreateSuccess}
          error={updateOrCreateError}
          reset={resetWarningModalSaveHooks}
        />
      )}
      {isDeleteThemeModalVisible && (
        <DeleteModal
          isVisible={isDeleteThemeModalVisible}
          handleCloseModal={handleToggleDeleteTheme}
          handleDelete={handleDeleteThemeConfirm}
          isProcessing={isDeleteProcessing}
          isSuccess={isDeletedSuccess}
          error={deleteError}
          reset={resetDeleteHook}
          currentlySelectedTheme={currentlySelectedTheme}
        />
      )}
      {isPublishThemeModalVisible && (
        <PublishModal
          isVisible={isPublishThemeModalVisible}
          publishedTheme={publishedTheme}
          handleToggleModal={handleTogglePublishTheme}
          handlePublish={handlePublishTheme}
          isProcessing={isPublishProcessing}
          isSuccess={isPublishSuccess}
          error={publishError}
          reset={resetPublishHook}
        />
      )}
    </>
  )
}

export { ModalsContainer }
