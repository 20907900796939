import type { FC } from 'react'
import React from 'react'
import { Radio, Icon } from '@extend/zen'
import { Mobile, Desktop } from '@extend/zen'
import styles from './breakpoint.module.css'
import { SelectedBreakpoint } from './preview-container'

type PreviewContainerProps = {
  setSelectedBreakpoint: (breakpoint: SelectedBreakpoint) => void
  selectedBreakpoint: SelectedBreakpoint
}

export const Breakpoint: FC<PreviewContainerProps> = ({ selectedBreakpoint, setSelectedBreakpoint }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon icon={Desktop} />
        <Radio
          name="desktop"
          label=""
          value="desktop"
          checked={selectedBreakpoint === 'desktop'}
          onChange={() => setSelectedBreakpoint('desktop')}
        />
      </div>
      <div className={styles.icon}>
        <div className={styles.center}>
          <Icon icon={Mobile} />
        </div>
        <Radio
          name="mobile"
          label=""
          value="mobile"
          checked={selectedBreakpoint === 'mobile'}
          onChange={() => setSelectedBreakpoint('mobile')}
        />
      </div>
    </div>
  )
}
