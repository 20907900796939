import type { FC, SyntheticEvent } from 'react'
import { startCase } from 'lodash/fp'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Select, Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useUpdateClaimMutation } from '../queries/claims'
import type { MerchantClaim } from '../types/claims'
import { ClaimSelectStatus, FailureType } from '@helloextend/extend-api-client'

export const ApproveClaimModal: FC<{
  isOpen: boolean
  handleConfirm: () => void
  handleLeave: () => void
  claim: MerchantClaim
}> = ({ isOpen, handleConfirm, handleLeave, claim }) => {
  const { toast } = useToaster()
  const [failureType, setFailureType] = useState<FailureType | null>(null)
  const { mutateAsync: updateClaim, isLoading: isUpdatingClaim } = useUpdateClaimMutation()

  const failureTypes = Object.keys(FailureType)

  const onConfirmClick = async (): Promise<void> => {
    if (!failureType) return
    try {
      await updateClaim({
        claimId: claim.id,
        body: {
          status: ClaimSelectStatus.approved,
          customer: claim.customer,
          incident: { ...claim.incident, failureType },
        },
      })
      handleConfirm()
    } catch (error) {
      toast({
        message: 'Failed to approve claim!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isOpen}>
      <Modal
        heading="Approve Claim"
        size="lg"
        primaryButtonProps={{
          'data-cy': 'approve-claim-modal-confirm',
          text: 'Confirm',
          color: 'blue',
          isProcessing: isUpdatingClaim,
          isDisabled: !failureType,
          onClick: onConfirmClick,
        }}
        secondaryButtonProps={{
          'data-cy': 'approve-claim-modal-cancel',
          text: 'Cancel',
          isProcessing: isUpdatingClaim,
          onClick: handleLeave,
        }}
      >
        <DataRow>
          <ItemWrapper>
            <Select
              label="Failure Type"
              id="failure-type-select"
              onChange={(e: SyntheticEvent<Element>): void => {
                const { value } = e.currentTarget as HTMLInputElement
                setFailureType(value as FailureType)
              }}
              value={failureType ?? ''}
              placeholder="—SELECT—"
              data-cy="approve-failure-type-select"
            >
              {failureTypes.map((type) => (
                <option
                  key={type}
                  value={type}
                  label={startCase(type)}
                  data-cy={`select-item-${type}`}
                />
              ))}
            </Select>
          </ItemWrapper>
        </DataRow>
      </Modal>
    </ModalController>
  )
}

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})
