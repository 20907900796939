// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Nd6P9ztXT93CamTytXlp- a {\n    text-decoration: underline;\n}\n\n._1QQpf325C-lrJGl0G2hdH3 {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 16px;\n}\n\n._1QQpf325C-lrJGl0G2hdH3 > div {\n    flex: 1;\n}", ""]);
// Exports
exports.locals = {
	"success": "_1Nd6P9ztXT93CamTytXlp-",
	"input-row": "_1QQpf325C-lrJGl0G2hdH3"
};
module.exports = exports;
