import React from 'react'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText, DateRange } from '@helloextend/merchants-ui'

import type { Cell } from 'react-table'
import { date } from '@extend/client-helpers'
import type { ClaimStatus } from '@helloextend/extend-api-client'
import { ClaimSelectStatus, ClaimType } from '@helloextend/extend-api-client'
import { startCase } from 'lodash'
import type { Claim } from '@extend-services/claims-management'
import { BadgeDescriptionsModal } from '../../../components/badge-descriptions-modal'
import { CellStatusBadge } from '../../../components/cell-status-badge'
import { claimStatusDetails } from '../contract/contract-claim-status-details'

const CLAIM_TYPE_MAP = {
  [ClaimType.EXTENDED_WARRANTY]: 'Product',
  [ClaimType.SHIPPING_PROTECTION]: 'Shipping',
  [ClaimType.CATEGORY]: 'Category',
}

const columns = [
  {
    Header: 'Type',
    headerTooltip:
      'The type of protection your customer purchased: Product Protection or Shipping Protection',
    accessor: 'type',
    width: 8,
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => {
      return <CellText>{CLAIM_TYPE_MAP[value as ClaimType]}</CellText>
    },
  },
  {
    Header: 'Contract Id',
    headerTooltip: 'ID of the contract in Extend’s system',
    accessor: 'contractId',
    disableSortBy: true,
    width: 22,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="contractId">{value}</CellText>,
  },
  {
    Header: 'Claim Id',
    headerTooltip: 'ID of the claim in Extend’s system',
    accessor: 'id',
    disableSortBy: true,
    width: 22,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="id">{value}</CellText>,
  },
  {
    Header: 'Date Reported',
    headerTooltip: 'Date claim was filed',
    accessor: 'reportedAt',
    disableSortBy: false,
    width: 15,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-incedentDate">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Customer Name',
    headerTooltip: 'Customer’s name',
    accessor: 'customer.name',
    disableSortBy: true,
    width: 13,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-customerName">{value}</CellText>
    ),
  },
  {
    Header: 'Customer Email',
    headerTooltip: 'Customer’s email address',
    accessor: 'customer.email',
    disableSortBy: true,
    width: 18,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-customerEmail">{value}</CellText>
    ),
  },
  {
    Header: 'Failure Type',
    headerTooltip: 'Type of product failure reported by customer',
    accessor: 'incident.failureType',
    disableSortBy: true,
    width: 13,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-failureType">{startCase(value)}</CellText>
    ),
  },
  {
    Header: 'Failure Cause',
    headerTooltip: 'Cause of product failure reported by customer',
    accessor: 'incident.failureCause',
    disableSortBy: true,
    width: 13,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-failureCause">{startCase(value)}</CellText>
    ),
  },
  {
    Header: 'Incident Date',
    headerTooltip: 'Date product failure occurred',
    accessor: 'incident.occurredAt',
    disableSortBy: true,
    width: 12,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-incedentDate">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Status',
    headerTooltip: 'Current status of the claim',
    HeaderAdornment: (): JSX.Element => (
      <BadgeDescriptionsModal descriptions={Object.values(claimStatusDetails)} />
    ),
    accessor: 'status',
    disableSortBy: true,
    width: 10,
    Cell: ({ row }: Cell): JSX.Element => {
      const claim = row.original as Claim
      const badgeData = claimStatusDetails[claim.status as ClaimStatus]
      if (
        claim.status === ClaimSelectStatus.pending_adjudication &&
        claim.photoRequirements?.length
      ) {
        badgeData.details = 'Photos Required'
      }
      return <CellStatusBadge badgeData={badgeData} />
    },
  },
]

const searchOptions = [
  { label: 'Customer Email', value: 'containsCustomerEmail' },
  {
    label: 'Contract ID',
    value: 'containsContractId',
  },
  { label: 'Claim ID', value: 'containsClaimId' },
]

const filterOptions: Record<string, FilterOptions> = {
  status: {
    label: 'Claim Status',
    type: 'checkbox',
    options: {
      [ClaimSelectStatus.approved]: claimStatusDetails.approved.text,
      [ClaimSelectStatus.denied]: claimStatusDetails.denied.text,
      [ClaimSelectStatus.fulfilled]: claimStatusDetails.fulfilled.text,
      [ClaimSelectStatus.review]: claimStatusDetails.review.text,
      [ClaimSelectStatus.pending_adjudication]: claimStatusDetails.pending_adjudication.text,
      [ClaimSelectStatus.merchant_review]: claimStatusDetails.merchant_review.text,
    },
  },
  reportedAtDate: {
    type: 'dateRange',
    label: 'Claim Reported Date',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  updatedAtDate: {
    type: 'dateRange',
    label: 'Claim Updated Date',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  typeFilter: {
    label: 'Claim Types',
    type: 'checkbox',
    options: {
      [ClaimType.EXTENDED_WARRANTY]: 'Product',
      [ClaimType.SHIPPING_PROTECTION]: 'Shipping',
      [ClaimType.CATEGORY]: 'Category',
    },
  },
}

export { columns, searchOptions, filterOptions }
