// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3n9Bk1FknJANcT3Irvs-Ew {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.ngWtHWGY0FeF3u7MZt8t_ {\n    border: 1px solid var(--neutral-300);\n    border-radius: 4px;\n    padding: 40px;\n}\n\n._1iz6MMiyM0hwq2EcTiXue {\n    width: 100%;\n    font-size: 12px;\n    border-collapse: collapse;\n    table-layout: fixed;\n}\n\n._1iz6MMiyM0hwq2EcTiXue tbody {\n    border: none;\n}\n\n._3brRoJxaoHp6qf6jwtsjYS {\n    border-top: 1px solid var(--neutral-300);\n    border-bottom: 1px solid var(--neutral-300);\n    background-color: transparent;\n    transition: background-color .2s ease-in;\n}\n\n._3brRoJxaoHp6qf6jwtsjYS:hover {\n    background-color: var(--neutral-100);\n}\n\n.fWjmIfBbF8g5USGZaZy9s {\n    text-align: left;\n    padding-left: 24px;\n    text-transform: uppercase;\n    font-weight: bold;\n    line-height: 4px;\n    width: 100%;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    color: var(--neutral-1000);\n}\n\n.A15Lb7QH6hMNwcvyZEhUK {\n    color: var(--neutral-700)\n}\n\n._2mTxHF_4IY9jTFLY_EVKyE {\n    padding-right: 12px;\n}\n\n._1esvGas9z2hWT267LWreJQ {\n    font-size: 14px;\n}\n\n._1esvGas9z2hWT267LWreJQ p {\n    font-size: 14px;\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"container": "_3n9Bk1FknJANcT3Irvs-Ew",
	"expenses-table-wrapper": "ngWtHWGY0FeF3u7MZt8t_",
	"table": "_1iz6MMiyM0hwq2EcTiXue",
	"table-row": "_3brRoJxaoHp6qf6jwtsjYS",
	"table-foot": "fWjmIfBbF8g5USGZaZy9s",
	"total-label-cell": "A15Lb7QH6hMNwcvyZEhUK",
	"total-amount-cell": "_2mTxHF_4IY9jTFLY_EVKyE",
	"alert-wrapper": "_1esvGas9z2hWT267LWreJQ"
};
module.exports = exports;
