// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "strong {\n    font-weight: 700;\n}\n\n.pZCcUQYfY8kzusxhephC_ {\n    display: flex;\n    gap: 10px;\n    align-items: end;\n}\n\n.pZCcUQYfY8kzusxhephC_ > div:first-child {\n    width: 100%;\n}\n\n.pZCcUQYfY8kzusxhephC_ > button {\n    align-self: unset;\n}\n\np {\n    font-weight: 100;\n    line-height: 20px;\n}\n\n.OgbCLz_Zj_Dy6mhvPcBmj {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}", ""]);
// Exports
exports.locals = {
	"input-wrapper": "pZCcUQYfY8kzusxhephC_",
	"wrapper": "OgbCLz_Zj_Dy6mhvPcBmj"
};
module.exports = exports;
