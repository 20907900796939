import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useAtom } from 'jotai/react'
import { Link } from 'react-router-dom'
import {
  Input,
  Modal,
  ModalController,
  Spinner,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { isCreateLeadModalVisibleAtom } from '../../atoms/create-lead-modal'
import { DatePickerInput } from '../../components/date-picker-input'
import { useGetProductForLead } from '../../hooks/use-get-product-for-lead'
import { CreateLeadErrorWithMessage, useCreateLeadMutation } from '../../queries/leads'
import styles from './create-lead-modal.module.css'

export const DEFAULT_CREATE_LEAD_ERROR_MESSAGE =
  'The lead couldn’t be created. Please try again later'

const initialLead = {
  transactionId: '',
  email: '',
  transactionDate: new Date().toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }),
  quantity: '',
  price: '',
  productReferenceId: '',
}
export const CreateLeadModal: FC = () => {
  const { toast } = useToaster()
  const { mutateAsync: createLead, isLoading } = useCreateLeadMutation()
  const {
    product,
    error,
    getProductForLead,
    isLoading: isProductLoading,
    clearError,
  } = useGetProductForLead()
  const [isModalVisible, setIsModalVisible] = useAtom(isCreateLeadModalVisibleAtom)
  const [lead, setLead] = useState<{
    transactionId: string
    email: string
    transactionDate: string
    quantity: string
    price: string
    productReferenceId: string
  }>(initialLead)

  const handleSave = useCallback(async (): Promise<void> => {
    if (product) {
      try {
        const createdLead = await createLead({
          transactionId: lead.transactionId,
          product,
          lineItemPrice: parseInt(lead.price, 10),
          quantity: parseInt(lead.quantity, 10),
          email: lead.email,
          transactionDate: new Date(lead.transactionDate).getTime(),
        })
        toast({
          message: (
            <div className={styles.success}>
              Lead created <Link to={`/store/leads/${createdLead.leadToken}`}>View Details</Link>
            </div>
          ),
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
        setIsModalVisible(false)
        setLead(initialLead)
      } catch (err) {
        let message = DEFAULT_CREATE_LEAD_ERROR_MESSAGE
        if (err instanceof CreateLeadErrorWithMessage) {
          message = err.message
        }
        toast({
          message,
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    }
  }, [lead, createLead, product, toast, setIsModalVisible])
  const isValid = !!(
    lead.transactionId &&
    lead.transactionDate &&
    lead.quantity &&
    lead.price &&
    lead.productReferenceId
  )
  const handleCancel = (): void => {
    setIsModalVisible(false)
    setLead(initialLead)
    clearError()
  }

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLead({ ...lead, [e.target.id]: e.target.value })
    },
    [lead],
  )

  const handleDateChange = useCallback(
    (newDate: string) => {
      setLead({ ...lead, transactionDate: newDate })
    },
    [lead],
  )

  return (
    <ModalController isOpen={isModalVisible}>
      <Modal
        heading="Create a new lead"
        primaryButtonProps={{
          text: 'Save',
          onClick: handleSave,
          isDisabled: !product || !isValid,
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: handleCancel,
        }}
        onDismissRequest={handleCancel}
        data-cy="create-lead-modal"
      >
        <div className={styles['input-row']}>
          <Input
            id="transactionId"
            label="Transaction ID"
            helperText="The transaction ID from your system of record"
            value={lead.transactionId}
            autoFocus
            onChange={handleChange}
          />
          <Input
            id="email"
            label="Email (optional)"
            helperText="The email of the customer who purchased the product. This email will be used in the Contract if the Lead is converted using the lead token."
            value={lead.email}
            onChange={handleChange}
          />
        </div>
        <div className={styles['input-row']}>
          <DatePickerInput
            label="Transaction Date"
            helperText="Date of the transaction."
            value={lead.transactionDate}
            onChange={handleDateChange}
          />
          <Input
            id="quantity"
            label="Line Item Qty"
            helperText="The number of units of the product that were purchased in the order. This sets the quantity of the lead token."
            value={lead.quantity}
            onChange={handleChange}
          />
          <Input
            id="price"
            label="Line Item Price"
            helperText="The actual transaction price of the line item as paid by the customer. This should be the same as it appears in your system of record, all quantities included, and after all discounts applied. Enter the amount in the transaction currency."
            value={lead.price}
            onChange={handleChange}
          />
        </div>
        <div className={styles['input-row']}>
          <Input
            id="productReferenceId"
            label="Extend Product Reference ID"
            helperText="This is the product identifier stored by Extend, a.k.a. the Product Reference ID. Usually the same as the SKU ID, but not always. If applicable, make sure it’s the ID of a specific variant, not a generic base product."
            value={lead.productReferenceId}
            onChange={handleChange}
            onBlur={() => getProductForLead(lead.productReferenceId)}
            subtext={product?.title}
            isError={Boolean(error)}
            errorFeedback={error}
          />
        </div>
        {isProductLoading && <Spinner />}
      </Modal>
    </ModalController>
  )
}
