import { useEffect, useState } from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { useFetchAllStoresQuery } from '../queries/stores'
import { getActiveStoreAtom, getActiveStoreIdAtom, setActiveStoreAtom } from '../atoms/stores'
import { decodeToken } from '../lib/jwt'

const useQueryStore = (): {
  store: Store | null
  isLoaded: boolean
  stores: Store[]
} => {
  const accessToken = useAtomValue(v3AccessTokenAtom)
  const activeStore = useAtomValue(getActiveStoreAtom)
  const setActiveStore = useSetAtom(setActiveStoreAtom)
  const activeStoreId = useAtomValue(getActiveStoreIdAtom)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const { data: stores, isInitialLoading } = useFetchAllStoresQuery()

  // Choose the initial active store if one is not set based on user role
  useEffect(() => {
    const decodedToken = decodeToken(accessToken)
    if (!decodedToken) {
      setIsLoaded(true)
      return
    }

    if (!isInitialLoading && stores) {
      const previousActiveStore = stores.find((s) => s.id === activeStoreId)

      if (previousActiveStore) {
        setActiveStore(previousActiveStore)
      } else {
        setActiveStore(stores[0])
      }

      setIsLoaded(true)
    }
  }, [accessToken, isInitialLoading, activeStore, stores, setActiveStore, activeStoreId])

  return {
    store: activeStore as Store,
    isLoaded,
    stores: stores || [],
  }
}

export { useQueryStore }
