import type { ClaimStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import type { MerchantClaim } from '../../../types/claims'

export const claimStatusDetails: Record<ClaimStatus, BadgeDescriptionItem> = {
  approved: {
    text: 'Approved',
    description:
      "The customer's reported issue is covered. The claim will be processed. Customer will receive reimbursement/compensation, or their product will be repaired/replaced.",
    color: 'green',
  },
  review: {
    text: 'In Review',
    description:
      "A coverage determination has not yet been made. Extend is evaluating if the customer's reported issue will be covered.",
    color: 'yellow',
  },
  denied: {
    text: 'Denied',
    description:
      "The customer's reported issue is not eligible for coverage based on the contract's limit of liability. ",
    color: 'red',
  },
  pending_adjudication: {
    text: 'Pending Adjudication',
    description: 'The claim is pending an adjudication decision. ',
    color: 'yellow',
  },
  fulfilled: {
    text: 'Fulfilled',
    description:
      'Customer has received reimbursement/compensation, or their product has been repaired/replaced.',
    color: 'neutral',
  },
  closed: {
    text: 'Closed',
    description: 'The claim has been closed. Fulfillment or further action is not required.',
    color: 'neutral',
  },
  merchant_review: {
    text: 'Merchant Review',
    description:
      'A coverage determination has not yet been made. Extend is waiting for the merchant to review before proceeding.',
    color: 'yellow',
  },
}

export const CLAIMS_MANUAL_DENIAL_REASONS: Record<ClaimManualDenialReason, string> = {
  occurred_outside_coverage: 'Occurred outside coverage',
  failure_not_covered: 'Failure not covered',
  police_report_not_provided: 'Police report not provided',
  images_not_provided: 'Images not provided',
  under_manufacturer_warranty: 'Under manufacturer warranty',
}

export const CLAIMS_AUTOMATED_DENIAL_REASONS: Record<ClaimAutomatedDenialReason, string> = {
  invalid_contract_status: 'Contract has an invalid status',
  occurred_before_purchase: 'Incident occurred before the purchase date',
  occurred_before_coverage: 'Incident occurred before the coverage period',
  occurred_future_date: 'Incident occurred on a future date',
  occurred_after_coverage: 'Incident occurred after the coverage period',
  reported_after_coverage: 'Claim reported after the coverage period',
  service_type_invalid: 'Service type is invalid',
  fraud_risk: 'Fraud risk',
  // the following indexes are for legacy claims, and we will not display any message for them
  no_adh_coverage: '',
  no_explicit_failure_type: '',
  active_claim_found: '',
  is_tire_or_wheel: '',
  images_required: '',
}

export type ClaimManualDenialReason =
  | 'occurred_outside_coverage'
  | 'failure_not_covered'
  | 'police_report_not_provided'
  | 'images_not_provided'
  | 'under_manufacturer_warranty'

export type ClaimAutomatedDenialReason =
  | 'invalid_contract_status'
  | 'occurred_before_purchase'
  | 'occurred_before_coverage'
  | 'occurred_future_date'
  | 'occurred_after_coverage'
  | 'reported_after_coverage'
  | 'service_type_invalid'
  | 'no_adh_coverage'
  | 'no_explicit_failure_type'
  | 'active_claim_found'
  | 'is_tire_or_wheel'
  | 'images_required'
  | 'fraud_risk'

/*
 * should only return a string if a valid reason is found
 * Valid Case #1: if manual reason is found, return manual reason
 * Valid Case #2: if a valid manual reason does not exist, move on to automated reason. if automated reason is found, return automated reason
 * Valid Case #3: if neither is found, return empty string
 */
export const getClaimDenialReason = (claim: MerchantClaim): string => {
  const { denialReason, validationError } = claim

  if (denialReason) {
    const manualReason = CLAIMS_MANUAL_DENIAL_REASONS[denialReason]
    if (manualReason) return manualReason
  }

  if (validationError) {
    const automatedReason = CLAIMS_AUTOMATED_DENIAL_REASONS[validationError]
    if (automatedReason) return automatedReason
  }

  return ''
}
