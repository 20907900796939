// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3eosFtfIAiVG2sZr4Bhp3A {\n    font-size: 18px;\n    line-height: 24px;\n    font-weight: 700;\n}\n\n._3Ukkp2opFkj91dW1IIOeeu {\n    display: flex;\n    flex: 1;\n    justify-content: center;\n}\n\n._1k3MMtOnHovTePSfWcWmlb {\n    margin-right: auto;\n}\n\n.TqvVLVfjOUgWBusWCKIr7 {\n    display: flex;\n    margin-left: auto;\n    gap: 8px;\n}\n\n._8eukVAOLh7vU4mzRt3seu {\n    z-index: 1000000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    overflow: auto;\n    display: flex;\n    flex-direction: row-reverse;\n    gap: 16px;\n    height: 59px;\n    padding: 0 16px;\n    background-color: var(--blue-800);\n    align-items: center;\n    color: var(--white);\n    white-space: nowrap;\n    transition-property: top;\n    transition-duration: 200ms;\n}\n\n._8eukVAOLh7vU4mzRt3seu.VALSLz4kqiAZ4dSIpd6_u {\n    top: -59px;\n}", ""]);
// Exports
exports.locals = {
	"header": "_3eosFtfIAiVG2sZr4Bhp3A",
	"section": "_3Ukkp2opFkj91dW1IIOeeu",
	"start": "_1k3MMtOnHovTePSfWcWmlb",
	"end": "TqvVLVfjOUgWBusWCKIr7",
	"save-banner": "_8eukVAOLh7vU4mzRt3seu",
	"invisible": "VALSLz4kqiAZ4dSIpd6_u"
};
module.exports = exports;
