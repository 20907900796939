import React, { useCallback } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { CreateContractsModal } from './create-contracts-modal'
import {
  productAtom,
  modalStepAtom,
  offersAtom,
  productDetailsAtom,
  planDetailsAtom,
  orderAtom,
} from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import {
  Box,
  CurrencyInput,
  DataProperty,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Select,
} from '@extend/zen'
import { DatePickerInput } from '../../../../components/date-picker-input'
import { currency } from '@extend/client-helpers'
import { getContractTermLengthDisplayText } from '../../../../utils/contract-mappers'

export const CreateContractsModalPlanSelection = () => {
  const setModalStep = useSetAtom(modalStepAtom)
  const order = useAtomValue(orderAtom)
  const product = useAtomValue(productAtom)
  const productDetails = useAtomValue(productDetailsAtom)
  const offers = useAtomValue(offersAtom)
  const [planDetails, setPlanDetails] = useAtom(planDetailsAtom)

  const initialPlan = offers[0]
  if (!planDetails?.plan && initialPlan) {
    setPlanDetails({ ...planDetails, plan: initialPlan })
  }

  if (!planDetails?.numContracts) {
    setPlanDetails({ ...planDetails, numContracts: 1 })
  }

  const handleOnClick = useCallback(async () => {
    setModalStep(CreateContractsModalStep.Confirmation)
  }, [])

  const handlePlanOnChange = useCallback(
    (event) => {
      const offer = offers.find((offer) => offer.id === event.target.value)
      setPlanDetails({ ...planDetails, plan: offer })
    },
    [offers, planDetails, setPlanDetails],
  )

  return (
    <CreateContractsModal
      primaryButton={{
        text: 'Next',
        onClick: handleOnClick,
        isDisabled: !planDetails?.plan || !planDetails.numContracts,
        isProcessing: false,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.ProductDetails)
        },
      }}
    >
      <Box>
        <Grid spacing={4}>
          <GridItem>
            <Grid columns={4} spacing={4}>
              <DataProperty label="Reference ID" value={product?.referenceId} />
              <DataProperty label="Product Name" value={product?.title} />
              <DataProperty label="Category" value={product?.category} />
              <DataProperty
                label="Product List Price"
                value={currency.format(product?.price?.amount, order?.currency)}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <Grid columns={2} spacing={4}>
              <DatePickerInput
                label="Purchase Date"
                helperText="Start date of the contract."
                value={productDetails?.purchaseDate || ''}
                onChange={() => {}}
                isDisabled={true}
              />
              <CurrencyInput
                label="Purchase Price"
                id="purchasePrice"
                data-cy="purchase-price-input"
                onChange={() => {}}
                value={productDetails?.purchasePrice || ''}
                autoFocus
                currency={order?.currency || 'USD'}
                currencyDisplay="symbol"
                isDisabled={true}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <RadioGroup
              name="plan-selection"
              label="Plans"
              value={planDetails?.plan?.id || offers[0]?.id}
              onChange={handlePlanOnChange}
              layout="vertical"
            >
              {offers.map((offer) => {
                let label = offer.title
                if (offer.contract.termLength) {
                  label += ` - ${getContractTermLengthDisplayText(offer.contract.termLength)}`
                }

                label += ` - ${currency.format(offer.price, offer.currencyCode)}`

                return (
                  <Radio
                    key={offer.id}
                    label={label}
                    value={offer.id}
                    checked={planDetails?.plan?.id === offer.id}
                    onChange={() => setPlanDetails({ ...planDetails, plan: offer })}
                  />
                )
              })}
            </RadioGroup>
          </GridItem>
          <GridItem>
            <Select
              id="num-contracts"
              label="Number of Contracts Needed"
              value={planDetails?.numContracts?.toString() || '1'}
              onChange={(e) =>
                setPlanDetails({ ...planDetails, numContracts: Number(e.target.value) })
              }
            >
              {new Array(4).fill(null).map((_, index) => {
                const num = index + 1
                return (
                  <option key={num} value={num.toString()}>
                    {num}
                  </option>
                )
              })}
            </Select>
          </GridItem>
        </Grid>
      </Box>
    </CreateContractsModal>
  )
}
