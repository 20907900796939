import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  ButtonGroup,
  COLOR,
  Input,
  InputType,
  Spinner,
  Visibility,
  VisibilityOff,
} from '@extend/zen'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  useGetMerchantServicingSettingsQuery,
  useUpsertMerchantServicingSettingsMutation,
} from '../../../queries/servicers'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import styles from './mid-setting.module.css'

const schema = Yup.object()
  .shape({
    mid: Yup.string(),
  })
  .defined()

type MidSettingProps = {
  sellerId: string
  setHasChangesMade: (value: boolean) => void
}

export const MidSetting: FC<MidSettingProps> = ({ sellerId, setHasChangesMade }) => {
  const { toastCompleted, toastError } = useStandardToast()
  const [isMasked, setIsMasked] = useState(true)
  const { data, isLoading: isGetLoading } = useGetMerchantServicingSettingsQuery({ sellerId })
  const { mutateAsync: updateSettings, isLoading } = useUpsertMerchantServicingSettingsMutation()
  const [updatedMid, setUpdatedMid] = useState('')
  const { hasPermission } = usePermissions()

  const { values, dirty, touched, handleChange, handleBlur, handleSubmit, handleReset } = useFormik(
    {
      enableReinitialize: true,
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: true,
      initialValues: {
        mid: (updatedMid || (data?.mids && data.mids[0])) ?? '',
      },
      onSubmit: async ({ mid }): Promise<void> => {
        try {
          await updateSettings({ ...data, sellerId, mids: [mid] })
          toastCompleted('Merchant Identification Number updated')
          setHasChangesMade(false)
          setIsMasked(false)
          setUpdatedMid(mid)
        } catch (e) {
          toastError(
            'There was an error saving the Merchant Identification Number. Please try again later.',
          )
        }
      },
    },
  )

  const onReset = (): void => {
    handleReset(null)
    setHasChangesMade(false)
  }

  useEffect(() => {
    setHasChangesMade(Boolean(Object.values(touched).find(Boolean) && dirty))
  }, [touched, dirty, setHasChangesMade])

  const handleActionButtonClick = useCallback(() => {
    setIsMasked(!isMasked)
  }, [isMasked])

  const handleOnFocus = useCallback(() => {
    setIsMasked(false)
  }, [])

  if (isGetLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  const shouldViewOnly = !hasPermission(Permission.StoreSettingsFullAccess)

  return (
    <form className="flex flex-col" onSubmit={handleSubmit} data-cy="mid-form">
      <div className={styles.input}>
        <Input
          label="Merchant Identification Number (MID)"
          value={values.mid}
          id="mid"
          isDisabled={shouldViewOnly}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleOnFocus}
          data-cy="mid"
          type={isMasked ? InputType.password : InputType.text}
          actionButtonProps={{
            emphasis: 'low',
            color: 'neutral',
            icon: isMasked ? VisibilityOff : Visibility,
            onClick: handleActionButtonClick,
          }}
        />
      </div>
      {!shouldViewOnly && (
        <ButtonGroup>
          <Button
            type="submit"
            color="blue"
            isProcessing={isLoading}
            isDisabled={!dirty}
            data-cy="save-button"
            text="Save"
          />
          <Button
            type="button"
            emphasis="medium"
            onClick={onReset}
            color="neutral"
            isDisabled={!dirty}
            text="Cancel"
          />
        </ButtonGroup>
      )}
    </form>
  )
}
