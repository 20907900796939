import type { QueryObserverResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { useCallback } from 'react'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { Plan } from '@helloextend/extend-api-client'
import { v3AccessTokenAtom } from '../atoms/auth'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

const BASE_URL = `https://${EXTEND_API_HOST}`
const PLANS_CACHE_KEY = 'plans'
export function useGetPlanByIdQuery({
  id,
  enabled = true,
}: {
  id: string
  enabled?: boolean
}): QueryObserverResult<Plan, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/plans/${id}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get plan by id')
    }
    return response.json()
  }, [accessToken, id])

  return useQuery({
    queryKey: [PLANS_CACHE_KEY, id],
    queryFn,
    enabled,
  })
}
