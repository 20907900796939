import { useCallback } from 'react'
import type { UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import type {
  CoverageType,
  OfferMarketing,
  OfferPlan,
  OfferProduct,
} from '@helloextend/extend-api-client/src/models/offer'
import type { ApiVersion } from '../constants/api-version'
import { v3AccessTokenAtom } from '../atoms/auth'

const DEFAULT_VERSION: ApiVersion = '2022-02-01'

const OFFERS_CACHE_KEY = 'Offers'

const BASE_QUERY = `https://${EXTEND_API_HOST}/offers`

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

/**
 * Fetches offers based on the given parameters.
 *
 * @param storeId - The store ID to filter by.
 * @param productId - The product ID that the offers are for.
 * @param price - The price of the product.
 * @param category - The category of the product.
 * @param version - The API version to use.
 * @param enabled - Whether the query should be enabled.
 * @param userLocation - The user's location. e.g. US-WA for United States - Washington State
 * @returns The query result.
 */
export function useGetOffersQuery({
  storeId = '',
  productId = '',
  price = '',
  category = '',
  version = DEFAULT_VERSION,
  enabled = true,
  userLocation = '',
}: {
  storeId?: string
  productId?: string
  price?: string
  category?: string
  version?: string
  enabled?: boolean
  userLocation?: string
}): UseQueryResult<OfferPlan[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({
      storeId,
      productId,
      ...(userLocation && { userLocation }),
      ...(price && { price }),
      // Only include category if price is set
      ...(price && category && { category }),
    })

    const response = await fetch(`${BASE_QUERY}?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        accept: `application/json; version=${version};`,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch offers')
    }

    const data = await response.json()
    return [...data.plans.adh, ...data.plans.base, ...data.plans.roadhazard]
  }, [accessToken, productId, storeId, version, price, category])

  return useQuery({
    queryKey: [OFFERS_CACHE_KEY],
    queryFn,
    enabled,
  })
}

/**
 * Fetches offers based on the given parameters.
 */
export function useLazyGetOffersQuery() {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({
      storeId = '',
      productId = '',
      price = '',
      category = '',
      version = DEFAULT_VERSION,
      userLocation = '',
    }: {
      storeId?: string
      productId?: string
      price?: string
      category?: string
      version?: string
      userLocation?: string
    }) => {
      const params = new URLSearchParams({
        storeId,
        productId,
        ...(userLocation && { userLocation }),
        ...(price && { price }),
        // Only include category if price is set
        ...(price && category && { category }),
      })

      const response = await fetch(`${BASE_QUERY}?${params}`, {
        headers: {
          ...COMMON_HEADERS,
          accept: `application/json; version=${version};`,
          'x-extend-access-token': accessToken,
        },
        method: 'GET',
      })

      if (!response.ok) {
        throw new Error('Unable to fetch offers')
      }

      const data = await response.json()
      return [...data.plans.adh, ...data.plans.base, ...data.plans.roadhazard]
    },
  })
}

export interface Offer {
  marketing?: OfferMarketing
  product: OfferProduct
  plans: PlansByCoverageType
}

export interface PlansByCoverageType extends Record<CoverageType, OfferPlan[]> {
  recommended: CoverageType
}
