import type { FC } from 'react'
import React, { useMemo } from 'react'
import { StandardHeader } from '../../../components/standard-header'
import { getSettingsUrl } from '../../../utils/route-helper'
import { PaymentAuthorizationForm } from './payment-authorization-form'

const PaymentAuthorization: FC = () => {
  const pageTitle = 'Recurring Payment ACH Authorization'
  const crumbs = useMemo(() => {
    return [
      { to: getSettingsUrl(), text: 'Settings' },
      {
        text: pageTitle,
      },
    ]
  }, [pageTitle])

  return (
    <>
      <StandardHeader
        pageTitle={pageTitle}
        data-cy="payment-authorization-header"
        crumbs={crumbs}
      />
      <PaymentAuthorizationForm />
    </>
  )
}

export { PaymentAuthorization }
