// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2B3yiG5LCwXOJgfRfRFA2e {\n    display: flex;\n    flex-direction: column;\n    padding: 20px 24px 24px 24px;\n}\n\n.Hc9TiluwtwJceqZWFFzdF {\n    align-self: center;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 28px;\n}\n\n.HLTcUNIfLHOTgePDVwZ_g {\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 700;\n    color: var(--neutral-1000);\n    padding: 1.6em 0 0;\n    margin: 0;\n}\n\n._22G462tJyPSCAlIjL19QNg {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 8px 0 16px;\n}\n\n._1IxRBA5o16pKCEeHBCrxBe {\n    font-size: 16px;\n    padding: 8px 0 16px;\n    margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_2B3yiG5LCwXOJgfRfRFA2e",
	"header": "Hc9TiluwtwJceqZWFFzdF",
	"subheader": "HLTcUNIfLHOTgePDVwZ_g",
	"help-button-section": "_22G462tJyPSCAlIjL19QNg",
	"text": "_1IxRBA5o16pKCEeHBCrxBe"
};
module.exports = exports;
