import { crypto as securityUtil } from '@helloextend/client-security-utils'
import {
  OKTA_AUTHORIZATION_URL,
  OKTA_BASE_URL,
  OKTA_CLIENT_ID_MERCHANTS,
  MERCHANTS_APP_REDIRECT_URI,
} from '@helloextend/client-constants'

const codeChallengeMethod = 'S256'

const PKCE_STATE = 'pkce_state'
const PKCE_CODE_VERIFIER = 'pkce_code_verifier'

type PkceCodeVerifier = string | null
type PkceState = string | null

type PkceCodeChallenge = string

type AuthorizeUrlParams = {
  offlineAccess?: boolean
  codeChallenge: string
  state: string
}

const getOktaRedirectUrl = async (params: { offlineAccess?: boolean }): Promise<string> => {
  const { offlineAccess = false } = params
  const state = generateRandomString(16)
  localStorage.setItem(PKCE_STATE, state)

  const codeChallenge = await getCodeChallenge()
  const url = generateAuthorizeUrl({ codeChallenge, state, offlineAccess })

  return url
}

const getCodeVerifier = (): PkceCodeVerifier => {
  return localStorage.getItem(PKCE_CODE_VERIFIER)
}
const getPkceState = (): PkceState => {
  return localStorage.getItem(PKCE_STATE)
}

const getCodeChallenge = async (): Promise<PkceCodeChallenge> => {
  const pair = await securityUtil.generateKeyPair()
  localStorage.setItem(PKCE_CODE_VERIFIER, pair.codeVerifier)
  return pair.codeChallenge
}

export const generateAuthorizeUrl = ({
  codeChallenge,
  state,
  offlineAccess = false,
}: AuthorizeUrlParams): string => {
  const url = new URL(OKTA_AUTHORIZATION_URL, OKTA_BASE_URL)

  let scope = 'openid profile email'
  if (offlineAccess) {
    scope = `${scope} offline_access`
  }

  url.searchParams.append('response_type', 'code')
  url.searchParams.append('client_id', `${OKTA_CLIENT_ID_MERCHANTS}`)
  url.searchParams.append('redirect_uri', `${MERCHANTS_APP_REDIRECT_URI}`)
  url.searchParams.append('scope', `${scope}`)
  url.searchParams.append('state', `${state}`)

  url.searchParams.append('code_challenge_method', `${codeChallengeMethod}`)
  url.searchParams.append('code_challenge', `${codeChallenge}`)

  return url.toString()
}

const generateRandomString = (size: number): string => {
  const array = new Uint32Array(size)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join('')
}

const dec2hex = (dec: number): string => {
  return dec.toString(16).padStart(2, '0')
}

export { getOktaRedirectUrl, getCodeVerifier, getPkceState }
