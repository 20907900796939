import React from 'react'
import type { Cell, Column } from 'react-table'
import { CellText } from '@helloextend/merchants-ui'
import { date } from '@extend/client-helpers'
import { BadgeDescriptions } from '../../../components/badge-descriptions'
import { CellStatusBadge } from '../../../components/cell-status-badge'
import type { TableTheme } from '../../../types/merchant-theme'
import { getThemeStatusCopy, statusDescriptions } from '../../../utils/theme-status'

const columns: Array<Column<TableTheme>> = [
  {
    Header: 'Nickname',
    headerTooltip: 'Theme name',
    accessor: 'name',
    disableSortBy: false,
    width: 15,
    Cell: ({ value }: Cell) => <CellText data-cy="theme-name">{value}</CellText>,
  },
  {
    Header: 'Date Created',
    headerTooltip: 'Date theme was created',
    accessor: 'createdAt',
    disableSortBy: false,
    width: 15,
    filter: 'dateRange',
    Cell: ({ value }: Cell) => (
      <CellText data-cy="theme-created-date">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Date Updated',
    headerTooltip: 'Date theme was updated',
    accessor: 'updatedAt',
    disableSortBy: false,
    width: 15,
    filter: 'dateRange',
    Cell: ({ value }: Cell) => (
      <CellText data-cy="theme-updated-date">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Status',
    headerTooltip: 'Current status of the theme',
    HeaderAdornment: (): JSX.Element => <BadgeDescriptions descriptions={statusDescriptions} />,
    accessor: 'status',
    disableSortBy: false,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => {
      const badgeData = getThemeStatusCopy(value)
      return <CellStatusBadge badgeData={badgeData} />
    },
  },
]

export { columns }
