// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".jcn0PtRzPMFR4cCtc5d_g {\n    max-width: 1004px;\n    margin: 64px auto 0;\n}\n\n._1UASFRp-daMVWtQsj13AQM {\n    color: var(--neutral-800);\n    font-size: 22px;\n    font-weight: 700;\n}\n\n._3aKxmEZLTfv-j7UETqR5_1 {\n    color: var(--neutral-800);\n    font-size: 20px;\n    line-height: 27px;\n    white-space: pre-line;\n}\n\n._3IHXcugxmP-VS8k94NTjx6 {\n    margin-top: 64px;\n}\n\n._1mU4msddNfy6bqyYdaehL5 {\n    width: 397px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "jcn0PtRzPMFR4cCtc5d_g",
	"header": "_1UASFRp-daMVWtQsj13AQM",
	"caption": "_3aKxmEZLTfv-j7UETqR5_1",
	"button-wrapper": "_3IHXcugxmP-VS8k94NTjx6",
	"product-icons": "_1mU4msddNfy6bqyYdaehL5"
};
module.exports = exports;
