import { Button, ButtonGroup } from '@extend/zen'
import { ArrowBack } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { shouldShowSupportPopoverAtom } from '../atoms/support'
import { Error404 } from './error-404'
import { Error500 } from './error-500'
import styles from './error-page.module.css'

interface ErrorPageProps {
  error?: Error | null
}

export const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const history = useHistory()
  const setShouldShowSupportPopover = useSetAtom(shouldShowSupportPopoverAtom)
  const isServerError = error?.message === '500 server error'

  const handleClickGoBack = (): void => {
    history.goBack()
  }

  const showSupportPopover = (): void => {
    setShouldShowSupportPopover(true)
  }

  return (
    <div className={styles.container}>
      {isServerError ? <Error500 /> : <Error404 />}
      <div className={styles['button-group-container']}>
        <ButtonGroup>
          <Button
            icon={ArrowBack}
            emphasis="medium"
            text="Back"
            onClick={handleClickGoBack}
            data-cy="go-back-button"
          />
          <Button
            emphasis="medium"
            text="Contact Us"
            onClick={showSupportPopover}
            data-cy="back-to-products-button"
          />
        </ButtonGroup>
      </div>
    </div>
  )
}
