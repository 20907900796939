import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import type { BadgeDescriptionItem } from '../components/badge-descriptions'

const getThemeStatusCopy = (themeStatus: ThemePublishedStatus): BadgeDescriptionItem => {
  switch (themeStatus) {
    case ThemePublishedStatus.published:
      return {
        text: 'Published',
        description: 'The theme has been published',
        color: 'green',
      }
    case ThemePublishedStatus.unpublished:
      return {
        text: 'Unpublished',
        description: 'The theme is in a draft state',
        color: 'neutral',
      }
    default:
      return {
        text: 'Unknown',
        description: '',
        color: 'neutral',
      }
  }
}

const statusDescriptions = [
  getThemeStatusCopy(ThemePublishedStatus.published),
  getThemeStatusCopy(ThemePublishedStatus.unpublished),
]

export { getThemeStatusCopy, statusDescriptions }
