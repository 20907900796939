// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1FaSuuUiNJaADDPXGRFUYd {\n    display: grid;\n    grid-template-rows: repeat(7, 1fr);\n    gap: 8px\n}\n\n._3jb3isW036FIMLKnu9f5wA {\n    display: grid;\n}\n\n._3ztZ3hZ_nMEu8jbdrSWhhj {\n    display: grid;\n    grid: 1fr / 1fr 1fr;\n    gap: 16px;\n}\n\n._1n4-6qtqUW-0mlYrtqjJzy {\n    display: grid;\n    grid: 1fr / 3fr 1fr;\n    gap: 16px;\n}\n\n._3U5gdGXMBCIR3SjTEVVO5O {\n    display: grid;\n    grid: 1fr / 3fr 1fr 2fr;\n    gap: 16px;\n}\n\n._3r1jh6EpWKE9u53yh1PF5l {\n    display: grid;\n    grid-template: 1fr / 1fr 1fr;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1FaSuuUiNJaADDPXGRFUYd",
	"heading": "_3jb3isW036FIMLKnu9f5wA",
	"email-phone-row": "_3ztZ3hZ_nMEu8jbdrSWhhj",
	"addressline1-row": "_1n4-6qtqUW-0mlYrtqjJzy",
	"addressline2-row": "_3U5gdGXMBCIR3SjTEVVO5O",
	"country-picker": "_3r1jh6EpWKE9u53yh1PF5l"
};
module.exports = exports;
