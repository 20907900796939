import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import styles from './full-loader.module.css'

export const FullLoader: FC = () => {
  return (
    <div className={styles.container} data-cy="loading-container">
      <Spinner color={COLOR.BLUE[800]} />
    </div>
  )
}
