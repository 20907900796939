import { useCallback } from 'react'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const STORE_CATEGORIES_CACHE_KEY = 'StoreCategories'

const STORE_CATEGORIES_LIMIT = 50000

export function useGetStoreCategoriesQuery({
  storeId,
}: {
  storeId: string
}): UseQueryResult<ProductCategoriesGetResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(
      `https://${EXTEND_API_HOST}/merchants-portal/stores/${storeId}/product-categories?limit=${STORE_CATEGORIES_LIMIT}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
          'x-extend-access-token': accessToken,
        },
        method: 'GET',
      },
    )

    if (!response.ok) {
      throw new Error('Unable to get store categories')
    }

    return response.json()
  }, [accessToken, storeId])

  return useQuery({
    queryKey: [STORE_CATEGORIES_CACHE_KEY],
    queryFn,
  })
}

interface ProductCategoriesGetResponse {
  storeId: string
  categories: string[]
}
