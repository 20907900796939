import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Search } from '@extend/zen'
import { COLOR, Input, MenuCheckbox } from '@extend/zen'
import { debounce } from 'lodash'
import type { NestedCheckboxFilterOptions, NestedCheckboxFilterValues } from './types'
import { searchNestedCheckboxFilters } from '../../utils/nested-checkbox-filter'

export type NestedCheckboxFilterProps = {
  onFilterChange: (property: string, values: NestedCheckboxFilterValues | null) => void
  property: string
  values?: NestedCheckboxFilterValues
  filters: NestedCheckboxFilterOptions['filters']
  includeSearchBar?: boolean
  keepCheckedOnSearch?: boolean // even if search text does not match, keep checked filters in list
  bulkSearchCSV?: boolean // allow comma separated filter values on search
}

const NestedCheckboxFilter: FC<NestedCheckboxFilterProps> = ({
  property,
  filters,
  values = {
    type: 'nestedCheckbox',
    values: {},
  },
  onFilterChange,
  includeSearchBar = false,
  keepCheckedOnSearch = false,
  bulkSearchCSV = false,
}) => {
  const [selectedValues] = useState<Record<string, string[]>>(
    values.values as Record<string, string[]>,
  )
  const [search, setSearch] = useState('')
  const [filteredFilters, setFilteredFilters] =
    useState<NestedCheckboxFilterOptions['filters']>(filters)

  useEffect(
    () =>
      debounce(() => {
        if (search === '') {
          setFilteredFilters(filters)
        } else if (includeSearchBar) {
          setFilteredFilters(
            searchNestedCheckboxFilters(
              filters,
              search,
              selectedValues,
              keepCheckedOnSearch,
              bulkSearchCSV,
            ),
          )
        } else setFilteredFilters({})
      }, 100)(),
    [
      setFilteredFilters,
      search,
      filters,
      keepCheckedOnSearch,
      selectedValues,
      includeSearchBar,
      bulkSearchCSV,
    ],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, parent?: string): void => {
      const { value, checked } = e.target

      if (!parent) {
        if (!checked) {
          delete selectedValues[value as string]
        } else if (!selectedValues[value]) {
          const filterValue = filters[value]
          selectedValues[value] =
            (filterValue.options && Object.keys(filterValue.options as Record<string, string>)) ??
            []
        }
      } else if (!checked) {
        selectedValues[parent] = selectedValues[parent].filter((v) => v !== value)
        if (selectedValues[parent].length === 0) {
          delete selectedValues[parent]
        }
      } else if (selectedValues[parent]) {
        selectedValues[parent] = [...selectedValues[parent], value]
      } else {
        selectedValues[parent] = [value]
      }

      onFilterChange(
        property,
        Object.keys(selectedValues).length
          ? {
              type: 'nestedCheckbox',
              values: selectedValues,
            }
          : null,
      )
    },
    [onFilterChange, property, filters, selectedValues],
  )

  if (!Object.keys(filters).length) {
    return <NoOptionsWrapper>No options</NoOptionsWrapper>
  }

  const isIndeterminate = (key: string): boolean => {
    if (!selectedValues[key] || !selectedValues[key].length) return false
    return (
      selectedValues[key].length !==
      Object.keys(filters[key].options as Record<string, string>).length
    )
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.currentTarget.value.toLowerCase())
  }

  return (
    <>
      {includeSearchBar && (
        <InputWrapper>
          <Input
            id="checkbox-search"
            value={search}
            icon={Search}
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </InputWrapper>
      )}
      {Object.entries(filteredFilters).map(([key, { label, options }]) => (
        <NestedCheckboxContainer key={key}>
          <MenuCheckbox
            checked={Object.keys(values.values).includes(key, 0) ?? false}
            indeterminate={isIndeterminate(key)}
            onChange={handleChange}
            value={key}
            key={key}
          >
            {label}
          </MenuCheckbox>
          {options &&
            Object.entries(options).map(([option, value]) => (
              <MenuCheckbox
                checked={(selectedValues[key] && selectedValues[key].includes(option, 0)) ?? false}
                onChange={(e) => handleChange(e, key)}
                value={option}
                indentLevel={1}
                key={option}
              >
                {value}
              </MenuCheckbox>
            ))}
        </NestedCheckboxContainer>
      ))}
    </>
  )
}

const NestedCheckboxContainer = styled.div({
  span: {
    paddingTop: 2,
    paddingBottom: 2,
  },
})

const NoOptionsWrapper = styled.div({
  padding: '8px 16px',
  color: COLOR.NEUTRAL[600],
})

const InputWrapper = styled.div({
  width: 'calc(100% - 20px)',
  borderBottom: `solid 1px ${COLOR.NEUTRAL[400]}`,
  padding: 10,
})

export { NestedCheckboxFilter }
