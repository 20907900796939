import { atom } from 'jotai/vanilla'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { atomWithStorage } from 'jotai/vanilla/utils'

export const ID_TOKEN_KEY = `${EXTEND_ENV}_ID_TOKEN_KEY`
export const REFRESH_TOKEN_KEY = `${EXTEND_ENV}_OKTA_REFRESH_TOKEN`

export const storageIdTokenAtom = atomWithStorage<string>(ID_TOKEN_KEY, '')
export const storageOktaRefreshTokenAtom = atomWithStorage<string>(REFRESH_TOKEN_KEY, '')

export const idTokenAtom = atom(
  (get) => {
    const token = get(storageIdTokenAtom) || JSON.parse(localStorage.getItem(ID_TOKEN_KEY) || '""')

    return token || ''
  },
  (_get, set, token: string) => {
    localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token))
    set(storageIdTokenAtom, token)
  },
)

export const oktaRefreshTokenAtom = atom(
  (get) => {
    const token =
      get(storageOktaRefreshTokenAtom) ||
      JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY) || '""')

    return token || ''
  },
  (_get, set, token: string) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(token))
    set(storageOktaRefreshTokenAtom, token)
  },
)
