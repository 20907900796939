import type { EntitlementsResponse } from '@helloextend/extend-api-client'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'

export const entitlementsApi = createApi({
  baseQuery,
  reducerPath: 'Entitlements',
  tagTypes: ['Entitlements'],
  endpoints: (build) => ({
    getEntitlements: build.query<EntitlementsResponse, { contractId: string }>({
      query: ({ contractId }) => ({
        url: `/entitlements/contract/${contractId}`,
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'Entitlements', id: contractId }],
    }),
    getLineItemEntitlements: build.query<
      EntitlementsResponse,
      { contractId: string; lineItemId: string }
    >({
      query: ({ contractId, lineItemId }) => ({
        url: `/entitlements/contract/${contractId}/lineItem/${lineItemId}`,
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'Entitlements', id: contractId }],
    }),
  }),
})

export const { useGetEntitlementsQuery, useGetLineItemEntitlementsQuery } = entitlementsApi
