// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vwflR-ndS7p2cMb7XT4F7 {\n    width: 100%;\n    table-layout: fixed;\n    border-collapse: collapse;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 thead {\n    color: var(--neutral-600);\n    font-size: 14px;\n    text-align: left;\n    margin-bottom: 8px;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 th {\n    text-align: left;\n    padding-bottom: 8px;\n    width: initial;\n\tdisplay: initial;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 td {\n    font-weight: normal;\n    padding: 16px 0;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 tr {\n    border: none;\n    border-top: 1px solid var(--neutral-300);\n    white-space: normal;\n\tdisplay: grid;\n\tgrid-template-columns: 3fr 2fr 1fr;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 p {\n  margin: 0.3rem 0;\n}\n\n._3vwflR-ndS7p2cMb7XT4F7 tr:first-of-type {\n    border: none;\n    white-space: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "_3vwflR-ndS7p2cMb7XT4F7"
};
module.exports = exports;
