import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import { ErrorPage } from '../../../components/error-page'
import styles from './tableau-embed.module.css'

import { getIsJsonWebTokenExpired } from '../../../utils/get-is-json-web-token-expired'

interface TableauEmbedProps {
  storeId: string | undefined
  reportUrl: string | undefined
  token: string | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const TableauEmbed: FC<TableauEmbedProps> = ({
  storeId = '',
  reportUrl = '',
  token = '',
  isLoading = true,
  isError = false,
  isSuccess = false,
}) => {
  const shouldLoadReport = isSuccess && !isLoading && !isError && !getIsJsonWebTokenExpired(token)

  if (isLoading) {
    return (
      <div className={styles.loading} data-cy="report-loader">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError) {
    return <ErrorPage />
  }

  return (
    <>
      {shouldLoadReport && (
        <div className="flex">
          <tableau-viz
            data-cy="tableau-viz"
            src={reportUrl}
            token={token}
            toolbar="hidden"
            hide-tabs
          >
            <viz-filter field="store_id" value={storeId} />
          </tableau-viz>
        </div>
      )}
    </>
  )
}
