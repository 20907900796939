import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import type { Store } from '@helloextend/extend-api-client'
import { COLOR, Icon, MenuButtonItem } from '@extend/zen'
import { Check } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import { setActiveStoreAtom } from '../atoms/stores'
import { DashboardSidebarStoreBadge } from './dashboard-sidebar-store-badge'

interface StoreMenuItemProps {
  store: Store
  isSelectedStore: boolean
  toggle: () => void
}

export const DashboardSidebarStoreMenuItem: FC<StoreMenuItemProps> = ({
  store,
  isSelectedStore,
  toggle,
}) => {
  const { push } = useHistory()
  const setActiveStore = useSetAtom(setActiveStoreAtom)

  const handleSelectStore = useCallback(() => {
    toggle()
    setActiveStore(store)
    push('/')
  }, [setActiveStore, toggle, store, push])

  return (
    <MenuButtonItem
      rightAlignAdornment
      data-cy="store-list-item"
      key={store.id}
      isToggled={isSelectedStore}
      endAdornment={isSelectedStore ? <Icon icon={Check} color={COLOR.BLUE[700]} /> : undefined}
      onClick={handleSelectStore}
    >
      <div data-cy="store-list-item:store-name">{store.name}</div>
      <DashboardSidebarStoreBadge
        approved={store.approved || Boolean(store.shippingProtection?.approved)}
        enabled={store.enabled || Boolean(store.shippingProtection?.enabled)}
      />
    </MenuButtonItem>
  )
}
