import type { FC } from 'react'
import React from 'react'
import images from '../../../images'
import styles from './header.module.css'

export const Header: FC = () => {
  return (
    <div className={styles.container} data-cy="onboarding-header">
      <img className={styles.img} data-cy="header-logo" src={images.extendLogoWhite} alt="extend" />
    </div>
  )
}
