// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3hkpyfCrom-di8In99XlYf {\n    margin: 0;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n._1XBqExI_WUAJT_kj72rIgT {\n    display: flex;\n    gap: 28px;\n    margin-top: 32px;\n    margin-bottom: 48px;\n}\n\n._1Z8AKcWxjUcH3_T2lNfoJx {\n    width: 50%;\n}\n\n._2s0pgrGKe3BzZtRQEQJmyF {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n._3Q6qwAgjawQ0EY9e8LaYuW {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-600);\n    margin: 0 0 4px 0;\n}\n\n._1_eWGNxCE3TEdhuEPsacn7 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n._3p48yxdD_9iaXV8gaPbyu1 {\n    width: 184px;\n    height: 64px;\n    background-size: cover;\n}\n\n.IAHEko-jiJr7NA_O_BUyk {\n    display: flex;\n    width: 255px;\n    justify-content: space-between;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "_3hkpyfCrom-di8In99XlYf",
	"content": "_1XBqExI_WUAJT_kj72rIgT",
	"half-width": "_1Z8AKcWxjUcH3_T2lNfoJx",
	"column-title": "_2s0pgrGKe3BzZtRQEQJmyF",
	"row-title": "_3Q6qwAgjawQ0EY9e8LaYuW",
	"row-item": "_1_eWGNxCE3TEdhuEPsacn7",
	"logo-img": "_3p48yxdD_9iaXV8gaPbyu1",
	"logo": "IAHEko-jiJr7NA_O_BUyk"
};
module.exports = exports;
