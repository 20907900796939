// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._17JCObbKz3sjEd6QAT2mJY {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n._3rMd-1vsbHhurrdCXnkW7J {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width:100%;\n}\n\n.-SuqZFngPbqEYTwJKa8Q6 {\n    margin-bottom: 32px;\n}\n\n.uC4w6_go1mJkzt6yY-mWr {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n._1mKzcMGYsichI0cVO0kRjG {\n    color: var(--neutral-1000);\n    font-family: \"Nunito Sans\", sans-serif;\n    font-size: 26px;\n    font-weight: 700;\n    line-height: 45px;\n    text-align: center;\n    margin-bottom: 22px;\n}\n\n._1bVU6H8rncRkKvgIqKxnrb {\n    max-width: 430px;\n    margin-bottom: 32px;\n}\n\n._1oqK8wzwJEBZeyeyJCC0AS {\n    font-size: 20px;\n    line-height: 27px;\n    margin-bottom: 32px;\n    text-align: center;\n}\n\n._1_nIeX9E63WyYhvjXJd5tI {\n    margin-bottom: 22px;\n}\n", ""]);
// Exports
exports.locals = {
	"page-container": "_17JCObbKz3sjEd6QAT2mJY",
	"container": "_3rMd-1vsbHhurrdCXnkW7J",
	"figure": "-SuqZFngPbqEYTwJKa8Q6",
	"button-group": "uC4w6_go1mJkzt6yY-mWr",
	"heading": "_1mKzcMGYsichI0cVO0kRjG",
	"body-container": "_1bVU6H8rncRkKvgIqKxnrb",
	"body": "_1oqK8wzwJEBZeyeyJCC0AS",
	"alert": "_1_nIeX9E63WyYhvjXJd5tI"
};
module.exports = exports;
