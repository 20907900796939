// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1fuILH8jRa_8b7RAv18rfN {\n    max-width: 816px;\n    margin: auto;\n}\n\n._1PTPUwTiE5RaQW_J7q4bpy {\n    align-content: flex-start;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 56px;\n}\n\n._3-w_CIzIVEYSt_U8VtZgsX {\n    text-align: center;\n    margin: 0 0 32px;\n}\n\n.JI-H1QSWa43M6IRKcXAG9 input {\n    display: none;\n}\n\n.JI-H1QSWa43M6IRKcXAG9 input[type=radio]:checked + ._1RHgur25OsdfnLkDHf13PP {\n    border: 1px solid var(--blue-800);\n    background-color: var(--neutral-100);\n}\n\n.JI-H1QSWa43M6IRKcXAG9 input[type=radio]:checked + ._1RHgur25OsdfnLkDHf13PP > ._2kCZkZryQrkIXBalYce6V6 {\n    opacity: 1;\n}\n\n.JI-H1QSWa43M6IRKcXAG9 ._1RHgur25OsdfnLkDHf13PP {\n    align-items: center;\n    background-color: var(--white);\n    border: 1px solid var(--neutral-200);\n    border-radius: 4px;\n    box-sizing: border-box;\n    cursor: pointer;\n    display: flex;\n    height: 80px;\n    justify-content: space-between;\n    margin-bottom: 32px;\n    padding: 0 24px 0 24px;\n    transition: all .25s ease-in-out;\n    width: 392px;\n}\n\n.JI-H1QSWa43M6IRKcXAG9 ._1RHgur25OsdfnLkDHf13PP:hover {\n    border: 1px solid var(--blue-800);\n    background-color: var(--neutral-100);\n}\n\n.JI-H1QSWa43M6IRKcXAG9 ._2kCZkZryQrkIXBalYce6V6 {\n    opacity: 0;\n    transition: opacity .25s ease-in-out;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 24px;\n    width: 24px;\n    height: 24px;\n    background-color: var(--blue-800);\n    border-radius: 30px;\n}\n\n.JI-H1QSWa43M6IRKcXAG9 ._2kCZkZryQrkIXBalYce6V6::after {\n    content: \" \";\n    width: 6px;\n    height: 12px;\n    border-color: var(--white);\n    border-style: solid;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    box-sizing: border-box;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_1fuILH8jRa_8b7RAv18rfN",
	"form-group": "_1PTPUwTiE5RaQW_J7q4bpy",
	"title": "_3-w_CIzIVEYSt_U8VtZgsX",
	"container": "JI-H1QSWa43M6IRKcXAG9",
	"select-container-box": "_1RHgur25OsdfnLkDHf13PP",
	"check-circle": "_2kCZkZryQrkIXBalYce6V6"
};
module.exports = exports;
