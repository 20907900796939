import type { ServiceOrder } from '@helloextend/extend-api-client'

export const getFulfillmentEmailSentDateFromServiceOrder = (serviceOrder: ServiceOrder): string => {
  const getVirtualCardEmailSentDate = (): string => {
    /**
     * @popaEmailDates: Gather any lastSent values that exist in the serviceOrder.popaEmailsMetaData
     * object and return them in an array
     */
    const popaEmailDates = Object.entries(serviceOrder?.popaEmailsMetaData || {}).map(
      ([_key, value]) => value?.lastSentAt || 0,
    )
    /**
     * @lastSentDate: Return the largest number in popaEmailDates array by spreading it into Math.max()
     */
    const lastSentDate = Math.max(...popaEmailDates)

    return lastSentDate
      ? `Sent ${new Date(lastSentDate).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`
      : ''
  }

  if (serviceOrder?.serviceType === 'replace') {
    if (serviceOrder?.fulfillmentMetaData?.method === 'virtual_card') {
      return getVirtualCardEmailSentDate()
    }
  }

  if (serviceOrder.fulfillmentMetaData?.method === 'direct_payment') {
    const emailSentDate = serviceOrder?.fulfillmentMetaData?.rewardEmailLastSentAt
    return emailSentDate
      ? `Sent ${new Date(emailSentDate).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`
      : ''
  }

  return ''
}
