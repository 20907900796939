import { useCallback } from 'react'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  LeadsCreatedConfigurationGetResponse,
  LeadsCreatedConfigurationPostRequest,
  PostSendTestEmailRequest,
} from '@helloextend/extend-api-client'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const BASE_URL = `https://${EXTEND_API_HOST}/messaging/messages`

const MESSAGING_CACHE_KEY = 'Messaging'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=default',
}

export function useGetConfigurationQuery({
  messageName,
  storeId,
}: {
  messageName: string
  storeId: string
}): QueryObserverResult<LeadsCreatedConfigurationGetResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ messageName, storeId })
    const response = await fetch(`${BASE_URL}/configurations?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
    })
    if (!response.ok) {
      throw new Error('Unable to get configuration')
    }
    return response.json()
  }, [accessToken, messageName, storeId])

  return useQuery({
    queryKey: [MESSAGING_CACHE_KEY],
    queryFn,
  })
}

export function useSaveConfigurationMutation(): UseBaseMutationResult<
  void,
  Error,
  LeadsCreatedConfigurationPostRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  // using POST instead of PUT b/c:
  // 1. We have access to all fields for use case.
  // 2. If the configuration doesn't exist, doing a PUT will fail Tenancy Boundary check. The data hasn't been tagged with merchantERN which is done through POST.
  return useMutation({
    mutationFn: async (body) => {
      const response = await fetch(`${BASE_URL}/configurations`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        throw new Error('Unable to save configuration')
      }

      await client.invalidateQueries([MESSAGING_CACHE_KEY])
    },
  })
}

export function useSendTestEmailMutation(): UseBaseMutationResult<
  void,
  Error,
  PostSendTestEmailRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (body) => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        throw new Error('Unable to send test email')
      }
    },
  })
}
