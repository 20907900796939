import { useMemo } from 'react'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { Permission } from '../lib/permissions'
import { usePermissions } from './use-permissions'

export const SETTINGS_VIEW_ONLY_SUBHEADER = 'Copy your Store ID here'
export const SETTINGS_FULL_ACCESS_PROD_SUBHEADER =
  'Your API key carries many privileges, so be sure to keep it secure!'
export const SETTINGS_FULL_ACCESS_SANDBOX_SUBHEADER =
  'Use your sandbox keys for testing and development'
export function useGetSettingsCredentialsSubheaderText(): string {
  const { hasPermission } = usePermissions()
  return useMemo(() => {
    if (!hasPermission(Permission.StoreSettingsFullAccess)) return SETTINGS_VIEW_ONLY_SUBHEADER

    return EXTEND_ENV === 'production'
      ? SETTINGS_FULL_ACCESS_PROD_SUBHEADER
      : SETTINGS_FULL_ACCESS_SANDBOX_SUBHEADER
  }, [hasPermission])
}
