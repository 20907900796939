import { useMemo } from 'react'
import type { Contract } from '@helloextend/extend-api-client'
import {
  ContractStatus,
  ContractType,
  LimitOfLiabilityAmountType,
} from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSearchEntitlementsQuery } from '@helloextend/extend-api-rtk-query'
import { useGetEntitlementsQuery } from '../queries/entitlements'
import { LDFlag } from '../constants/ld-flags'

type LimitOfLiabilityType = {
  remainingAmount: string
  coverageAmount: string
  isLoading: boolean
  isEMSLoading: boolean
  productPriceType: string
}

const INVALID_CONTRACT_STATUSES = [
  ContractStatus.CANCELLED,
  ContractStatus.EXPIRED,
  ContractStatus.FULFILLED,
]

export const useLimitOfLiability = (contract: Contract): LimitOfLiabilityType => {
  const { [LDFlag.UseEMSApi]: FF_USE_EMS_API } = useFlags()
  const isProductProtectionContract = contract.type === ContractType.PCRS

  const { data: entitlements, isLoading } = useGetEntitlementsQuery({
    contractId: contract.id,
    enabled: isProductProtectionContract,
  })
  const { data: emsData, isLoading: isEMSLoading } = useSearchEntitlementsQuery(
    { queryString: `contractId=${contract.id}`, version: 'latest' },
    { skip: !contract.id || !FF_USE_EMS_API },
  )

  const remainingAmount = useMemo(() => {
    if (
      !(
        entitlements?.coverageAmountRemaining.amount ||
        emsData?.products?.[0]?.entitlements?.[0]?.contract?.remainingLoL
      ) ||
      INVALID_CONTRACT_STATUSES.includes(contract.status)
    ) {
      return '0.00'
    }
    return `${currency.formatWithoutCurrencySymbol(
      FF_USE_EMS_API
        ? emsData?.products?.[0]?.entitlements?.[0]?.contract?.remainingLoL
        : entitlements?.coverageAmountRemaining.amount,
    )}`
  }, [entitlements, contract, emsData?.products, FF_USE_EMS_API])

  const coverageAmount = useMemo(() => {
    const amount = contract.limitOfLiabilityAmount
      ? currency.formatWithoutCurrencySymbol(contract.limitOfLiabilityAmount)
      : '0.00'
    const emsTotalLoL = emsData?.products?.[0]?.entitlements?.[0]?.contract?.limitOfLiabilityAmount
      ? currency.formatWithoutCurrencySymbol(
          emsData?.products?.[0]?.entitlements?.[0]?.contract?.limitOfLiabilityAmount,
        )
      : '0.00'

    return `/ ${FF_USE_EMS_API ? emsTotalLoL : amount} ${contract.currency}`
  }, [contract, emsData?.products, FF_USE_EMS_API])

  const productPriceType =
    contract.limitOfLiabilityAmountType === LimitOfLiabilityAmountType.PRODUCT_LIST_PRICE
      ? ' List'
      : ' Purchase'

  return {
    remainingAmount,
    coverageAmount,
    isLoading,
    isEMSLoading,
    productPriceType,
  }
}
