import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import {
  AdvancedSelect,
  Button,
  DataProperty,
  LinkButton,
  Modal,
  ModalController,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useRoleOptions } from '../../../hooks/use-role-options'
import { FullLoader } from '../../../components/full-loader'
import { useV3UserInfo } from '../../user/use-v3-user-info'
import { useUpdateUserGrantsMutation } from '../../../queries/users-v3'
import styles from './v3-user-edit-form.module.css'

export const V3UserEditForm: FC = () => {
  const { uuid } = useParams<{ uuid: string }>()

  const { toast } = useToaster()
  const { push } = useHistory()

  const { data, isLoading, userRoles } = useV3UserInfo(uuid)
  const { mutateAsync: updateRoles, isLoading: isUpdatingRoles } = useUpdateUserGrantsMutation()

  const [isRoleDeleteModalOpen, setIsRoleDeleteModalOpen] = useState(false)
  const [editRoles, setEditRoles] = useState(userRoles)
  const [hasChanges, setHasChanges] = useState(false)

  const options = useRoleOptions()

  if (isLoading) {
    return <FullLoader />
  }

  const handleCancel = (): void => {
    push(`/account/users/${uuid}`)
  }

  const handleChange = (event: AdvancedSelectChangeEvent<string[] | string>): void => {
    // First make sure we have the latest from the server
    if (!editRoles && userRoles.length > 0) {
      setEditRoles(userRoles)
    }

    const newValue = Array.isArray(event.target.value) ? event.target.value : [event.target.value]
    setEditRoles(newValue)
    setHasChanges(true)
  }

  const saveChanges = async (): Promise<void> => {
    if (!data?.email) {
      return
    }

    try {
      await updateRoles({ userId: data.email, newRoles: editRoles, previousRoles: userRoles, uuid })
      toast({
        message: 'Successfully updated user roles',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      handleCancel()
    } catch (error) {
      setEditRoles(userRoles)
      setIsRoleDeleteModalOpen(false)
      toast({
        message: 'Unable to update user roles',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const handleSaveChanges = async (hasConfirmed = false): Promise<void> => {
    const shouldOpenConfirmModal = !hasConfirmed && editRoles.length < userRoles.length
    if (shouldOpenConfirmModal) {
      setIsRoleDeleteModalOpen(true)
    } else {
      saveChanges()
    }
  }

  const hasRolesError = editRoles.length < 1

  return data ? (
    <>
      <section className={styles['title-row']} data-cy="v3-user-edit-form">
        <h1 className={styles.title}>User Details</h1>
      </section>
      <div className="flex flex-wrap width-100">
        <div className={styles.property} style={{ width: 'calc(25% - 16px)' }}>
          <DataProperty data-cy="firstName" label="First Name" value={data.firstName} />
        </div>
        <div className={styles.property} style={{ width: 'calc(25% - 16px)' }}>
          <DataProperty data-cy="lastName" label="Last Name" value={data.lastName} />
        </div>
        <div className={styles.property} style={{ width: 'calc(50% - 16px)' }}>
          <DataProperty label="Email" value={data.email} />
        </div>
        <div className={styles.property} style={{ width: 'calc(100% - 16px)' }}>
          <AdvancedSelect
            data-cy="roles"
            label="Role(s)"
            id="roles"
            options={options}
            multiple
            value={editRoles}
            onChange={handleChange}
            isError={hasRolesError}
            errorFeedback="A user must have at least one role"
          />
        </div>
        <section className={styles['button-row']}>
          <LinkButton
            text="Cancel"
            emphasis="medium"
            to={`/account/users/${uuid}`}
            isDisabled={isUpdatingRoles}
          />
          <Button
            text="Save Changes"
            onClick={() => handleSaveChanges(false)}
            isProcessing={isUpdatingRoles}
            isDisabled={!hasChanges || hasRolesError}
          />
        </section>
        <ModalController isOpen={isRoleDeleteModalOpen}>
          <Modal
            heading="Are you sure you want to delete one or more role assignments?"
            primaryButtonProps={{
              text: 'Delete Assignments',
              color: 'red',
              isDisabled: !hasChanges || isUpdatingRoles,
              isProcessing: isUpdatingRoles,
              onClick: () => handleSaveChanges(true),
            }}
            secondaryButtonProps={{
              text: 'Cancel',
              onClick: handleCancel,
              isDisabled: isUpdatingRoles,
            }}
            onDismissRequest={handleCancel}
          >
            <p>The user will lose the associated access and capabilities.</p>
          </Modal>
        </ModalController>
      </div>
    </>
  ) : null
}
