/**
 * Note: This component is intended to be used with a service CSV Export API which
 * generates a download link and emails it to the user, building the data ad-hoc.
 *
 * If you're looking to use the AIS Report Download API, please use `download-csv-report.tsx`.
 * @link https://helloextend.atlassian.net/wiki/spaces/ENG/pages/1925840966/AIS+Report+Download+Endpoint#API-Interface
 */
import type { FC } from 'react'
import React from 'react'
import {
  InlineAlert,
  InlineAlertColor,
  Error as ErrorIcon,
  Button,
  ButtonGroup,
  Download as DownloadIcon,
} from '@extend/zen'
import styles from './download-csv-data.module.css'

export const DownloadCSVData: FC<{
  isExpired: boolean
  alertMessage?: string
  downloadDataType: string
  downloadLink?: string
  handleDismissClick: () => void
  isProcessing: boolean
  onDownloadClick: () => void
}> = ({
  isExpired,
  alertMessage = 'The link to download this data has expired. Export your data again to receive a new link.',
  downloadDataType,
  downloadLink = 'https://docs.extend.com/docs',
  handleDismissClick,
  isProcessing,
  onDownloadClick,
}) => {
  return (
    <div className={styles.container}>
      {isExpired ? (
        <InlineAlert color={InlineAlertColor.red} icon={ErrorIcon}>
          {alertMessage}
        </InlineAlert>
      ) : (
        <p className={styles['success-message']}>
          Your {downloadDataType} data is ready for download. Visit the{' '}
          <a href={downloadLink} target="_blank" rel="noreferrer">
            Extend Help Center
          </a>{' '}
          to learn more about working with {downloadDataType} data.
        </p>
      )}
      <ButtonGroup>
        <Button onClick={handleDismissClick} text="Dismiss" emphasis="medium" />
        {!isExpired && (
          <Button
            onClick={onDownloadClick}
            icon={DownloadIcon}
            text="Download CSV"
            emphasis="high"
            isProcessing={isProcessing}
          />
        )}
      </ButtonGroup>
    </div>
  )
}
