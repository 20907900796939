// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2bQrh5aukVAGxPmMkw6FTK {\n    padding-bottom: 12px;\n    width: 542px;\n}\n\n._1qyVqfH1bWEoo-q1uTte3Z {\n    break-before: left;\n    display: flex;\n}\n\n.O9XCzOw0nY5buc0KOCI73 {\n    padding-right: 24px;\n    padding-top: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"input-wrapper": "_2bQrh5aukVAGxPmMkw6FTK",
	"button-group": "_1qyVqfH1bWEoo-q1uTte3Z",
	"button-wrapper": "O9XCzOw0nY5buc0KOCI73"
};
module.exports = exports;
