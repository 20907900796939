import { useCallback } from 'react'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  Claim,
  ClaimsSearchResponse,
  ClaimType,
  ClaimUpdateRequest,
} from '@helloextend/extend-api-client'
import type { InsuranceClaim } from '@helloextend/extend-api-client/src/models/claim'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { getActiveStoreIdAtom } from '../atoms/stores'
import { v3AccessTokenAtom } from '../atoms/auth'
import type { MerchantClaim } from '../types/claims'

const BASE_URL = `https://${EXTEND_API_HOST}`
const CLAIMS_CACHE_KEY = 'claims'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

export function useGetClaimQuery({
  claimId,
  enabled = true,
}: {
  claimId: string
  enabled?: boolean
}): QueryObserverResult<Claim, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ claimId })
    const response = await fetch(`${BASE_URL}/contracts/claims/search?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get claim')
    }
    const jsonData: ClaimsSearchResponse = await response.json()
    const { claims } = jsonData

    return (claims as Claim[]).length > 0 ? (claims as Claim[])[0] : null
  }, [accessToken, claimId])

  return useQuery({
    queryKey: [CLAIMS_CACHE_KEY, claimId],
    queryFn,
    enabled,
  })
}

export function useGetClaimsByContractIdQuery({
  contractId,
  enabled = true,
}: {
  contractId: string
  enabled?: boolean
}): QueryObserverResult<{ claims: ClaimsSearchResponse[] }, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ contractId })
    const response = await fetch(`${BASE_URL}/contracts/claims/search?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get claim by contractId')
    }

    return response.json()
  }, [accessToken, contractId])

  return useQuery({
    queryKey: [CLAIMS_CACHE_KEY, contractId],
    queryFn,
    enabled,
  })
}

export function useGetInsuranceClaimQuery({
  claimId,
  enabled = true,
  refetchOnMount = false,
}: {
  claimId: string
  enabled?: boolean
  refetchOnMount?: boolean
}): QueryObserverResult<MerchantClaim, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(
      `${BASE_URL}/claims-management/claims/${claimId}?claimId=${claimId}`,
      {
        headers: {
          ...COMMON_HEADERS,
          accept: 'application/json; version=2022-02-01;',
          ...(accessToken && { 'x-extend-access-token': accessToken }),
        },
        method: 'GET',
      },
    )

    if (!response.ok) {
      throw new Error('Unable to get insurance claim')
    }

    return response.json()
  }, [accessToken, claimId])

  return useQuery({
    queryKey: [CLAIMS_CACHE_KEY, claimId],
    queryFn,
    enabled,
    refetchOnMount,
  })
}

export function useUpdateClaimMutation(): UseBaseMutationResult<
  void,
  Error,
  InsuranceClaimsUpdateRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  return useMutation({
    mutationFn: async ({ claimId, body }) => {
      const response = await fetch(`${BASE_URL}/claims-management/claims/${claimId}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
          'x-extend-access-token': accessToken,
        },
        method: 'PUT',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        throw new Error('Unable to update claim')
      }

      await response.json()

      await client.invalidateQueries([CLAIMS_CACHE_KEY])
    },
  })
}

export function useExportClaimsMutation(): UseBaseMutationResult<
  ExportClaimsResult,
  Error,
  ExportClaimsRequest,
  void
> {
  const storeId = useAtomValue(getActiveStoreIdAtom) || ''
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/claims-management/csv-export`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({ storeId }),
      })

      if (!response.ok) {
        throw new Error('Unable to export claims')
      }

      const data = await response.json()
      return data === 'Job Started' ? { message: 'Job Started' } : { url: data }
    },
  })
}

export function useExportCarrierClaimsMutation(): UseBaseMutationResult<
  ExportCarrierClaimsResult,
  Error,
  ExportCarrierClaimsRequest,
  void
> {
  const storeId = useAtomValue(getActiveStoreIdAtom) || ''
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async () => {
      // This should request the most recent report for the given store
      const filePath = encodeURIComponent(`spg/carrier_claims/storeId=${storeId}`)

      const url = `${BASE_URL}/async-integrations/reports?filePath=${filePath}`

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-extend-access-token': accessToken,
          accept: 'application/json; version=2022-02-01;',
        },
      })

      if (!response.ok) {
        if (response.status === 404) throw new Error('no_claims_found')
        throw new Error('Unable to export carrier claims')
      }

      const data = await response.json()

      if (data.error) {
        return { error: data.error }
      }

      return { url: data.downloadUrl }
    },
  })
}

/**
 * Types
 */

export interface ClaimsGetRequest {
  contractId: string
  claimId: string
}

export type ClaimStatus = 'approved' | 'denied' | 'review' | 'fulfilled' | 'closed'
export type ShippingStatus = 'notScanned' | 'scanned' | 'received' | 'inspected' | 'expired'
type AdminSyncStatus = 'complete' | 'failed' | 'pending'

export interface ClaimSearchOptionsV1 {
  status?: ClaimStatus
  shippingStatus?: ShippingStatus
  customerEmail?: string
  contractId?: string
  adminSyncStatus?: AdminSyncStatus
  claimId?: string
  sellerId?: string
  storeIds?: string // only for service requests
  searchVersion?: '1'
}

export interface ClaimsSearchResponseV1 {
  claims: Claim[]
}

export type ClaimsSearchResponseV2 = {
  items: ClaimRecord[]
  limit?: number
  nextPageCursor?: string
}

export type InsuranceClaimsListResponse = {
  items: InsuranceClaimRecord[]
  limit?: number
  nextPageCursor?: string
}

export type ClaimRecord = Claim & {
  [key: string]: unknown
}

export type InsuranceClaimRecord = InsuranceClaim & {
  [key: string]: unknown
}

export type ClaimsSearchQueryStringOptions = {
  sellerId?: string
  claimId?: string
  searchVersion?: string
  minLimit?: number
  cursor?: string
  sortKey?: string
  sortAsc?: boolean
  containsContractId?: string
  containsClaimId?: string
  containsCustomerEmail?: string
  matchesClaimStatus?: string[]
  matchesClosedResolution?: string[]
  matchesPendingDisposition?: string[]
  createdAtBegin?: number
  createdAtEnd?: number
  reportedAtBegin?: number
  reportedAtEnd?: number
  updatedAtBegin?: number
  updatedAtEnd?: number
  typeFilter?: ClaimType[]
}

export interface InsuranceClaimsListQueryStringOptions extends ClaimsSearchQueryStringOptions {
  matchesAssignedUser?: string
  matchesClaimType?: string
  matchesClaimServiceType?: string
}

export type ClaimsUpdateRequest = {
  claimId: string
  contractId: string
  body: ClaimUpdateRequest
}

export type InsuranceClaimsUpdateRequest = {
  claimId: string
  body: ClaimUpdateRequest
}

export interface ExportClaimsRequest {
  storeId: string
}

export interface ExportCarrierClaimsRequest {
  storeId: string
}

export type ExportClaimsResult = { message: string } | { url: string }

export type ExportCarrierClaimsResult = { url: string } | { error: string }
