import { useCallback, useLayoutEffect, useState } from 'react'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import {
  createThemeNameAtom,
  currentlySelectedThemeIdAtom,
  draftThemeGlobalPropertiesAtom,
} from '../atoms/customize-theme'
import { useCreateThemeMutation, useFetchAllThemesQuery } from '../queries/themes'
import { getActiveStoreIdAtom } from '../atoms/stores'

type UseCreateTheme = {
  createTheme: (x?: string) => void
  isCreateProcessing: boolean
  isCreateSuccess: boolean
}

const useCreateTheme = (): UseCreateTheme => {
  const [createdThemeId, setCreatedThemeId] = useState<string | null>(null)

  const storeId = useAtomValue(getActiveStoreIdAtom)
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const [createThemeName, setCreateThemeName] = useAtom(createThemeNameAtom)
  const [draftGlobalProperties] = useAtom(draftThemeGlobalPropertiesAtom)
  const { isInitialLoading: isFetchingThemeList } = useFetchAllThemesQuery({ storeId })

  const { toastCompleted, toastError } = useStandardToast()

  const {
    mutateAsync: createThemeMutation,
    isLoading: isCreateProcessing,
    isSuccess: isCreateSuccess,
    reset: resetCreateThemeMutation,
  } = useCreateThemeMutation()

  const createTheme = useCallback(
    async (themeName?: string): Promise<void> => {
      const name = themeName ?? createThemeName
      if (draftGlobalProperties && name) {
        try {
          const response = await createThemeMutation({
            name,
            storeId,
            contents: {
              global: { ...draftGlobalProperties },
            },
          })
          toastCompleted('Theme successfully created')
          setCreatedThemeId(response.themeId)
          setCreateThemeName(null)
          resetCreateThemeMutation()
        } catch (err) {
          toastError('Uh-Oh, something went wrong. Please try again.')
          resetCreateThemeMutation()
        }
      }
    },
    [
      createThemeMutation,
      createThemeName,
      draftGlobalProperties,
      resetCreateThemeMutation,
      setCreateThemeName,
      storeId,
      toastCompleted,
      toastError,
    ],
  )

  useLayoutEffect(() => {
    if (createdThemeId && !isFetchingThemeList) {
      setCurrentThemeId(createdThemeId)
      setCreatedThemeId(null)
    }
  }, [isFetchingThemeList, setCurrentThemeId, createdThemeId])

  return { createTheme, isCreateProcessing, isCreateSuccess }
}

export { useCreateTheme }
