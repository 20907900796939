import type { FC } from 'react'
import React from 'react'
import type { Product } from '@helloextend/extend-api-client'
import { ProductDetailsInfo } from './product-details-info'
import { ProductDetailsManufacturingInfo } from './product-details-manufacturer-info'
import { ProductDetailsIdentifiers } from './product-details-identifiers'
import { ProductDetailsOfferInfo } from './product-details-offer-info'
import { PlansRdb } from './plans-rdb'

type ProductDetailsProps = {
  product: Product
}

const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
  return (
    <>
      <ProductDetailsInfo product={product} />
      <ProductDetailsManufacturingInfo product={product} />
      <ProductDetailsIdentifiers product={product} />
      <ProductDetailsOfferInfo product={product} />
      <PlansRdb product={product} />
    </>
  )
}

export { ProductDetails }
