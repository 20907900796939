/**
 * Note: This component is intended to be used with AIS Report Download API which
 * generates a download link for a pre-built report.
 *
 * If you're looking to use a service CSV Export API, please use `download-csv-data.tsx`.
 */
import type { FC } from 'react'
import React from 'react'
import {
  InlineAlert,
  InlineAlertColor,
  Error as ErrorIcon,
  Button,
  ButtonGroup,
  Download as DownloadIcon,
  LinkButton,
} from '@extend/zen'
import { date } from '@extend/client-helpers'
import styles from './download-csv-data.module.css'

export const DownloadCSVReport: FC<{
  alertMessage?: string
  downloadDataType: string
  supportLink?: string
  handleDismissClick: () => void
  isErrorState?: boolean
  isProcessing: boolean
  onDownloadClick: () => void
}> = ({
  alertMessage,
  downloadDataType,
  supportLink = 'https://docs.extend.com/docs',
  handleDismissClick,
  isErrorState,
  isProcessing,
  onDownloadClick,
}) => {
  // Reports only contain data up to midnight CT of the current day.
  const dateStr = date.format(
    date.getStartOfDay(new Date()).getTime(),
    'h a z, MMMM D, YYYY',
    'America/Chicago',
  )

  return (
    <div className={styles.container}>
      {isErrorState && (
        <InlineAlert color={InlineAlertColor.red} icon={ErrorIcon}>
          {alertMessage ?? (
            <>
              An error occurred while downloading the data. If the problem persists, please contact
              Extend for support.
              <br />
              <br />
              <LinkButton text="Contact Support" emphasis="medium" color="red" to={supportLink} />
            </>
          )}
        </InlineAlert>
      )}
      <p className={styles['success-message']}>
        Your {downloadDataType} data is ready for download. This file includes all carrier claims up
        until <strong>{dateStr}</strong> and will be updated every 24 hours.
      </p>
      <p>
        Please check back for the most recent data. Visit the{' '}
        <a href={supportLink} target="_blank" rel="noreferrer">
          Extend Help Center
        </a>{' '}
        to learn more.
      </p>

      <ButtonGroup>
        <Button onClick={handleDismissClick} text="Dismiss" emphasis="medium" />

        <Button
          onClick={onDownloadClick}
          icon={DownloadIcon}
          text="Download CSV"
          emphasis="high"
          isProcessing={isProcessing}
          isDisabled={isProcessing}
        />
      </ButtonGroup>
    </div>
  )
}
