// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KFmIBWf4N8W3V5H-PVJHk {\n    padding-bottom: 16px;\n}\n\n._2TjKGDUQenfDNuOLSMU6ge {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n._3n0gowVx8aofMdJfKyQ6ET {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n._3HVT4Mj-WF4dBTZUFhXJQF {\n    display: flex;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n@media (max-width: 980px) {\n    ._3HVT4Mj-WF4dBTZUFhXJQF {\n        grid-template-columns: 1fr;\n    }\n\n    ._1ZJIPU948r5NHvB26TavCS {\n        display: flex;\n        flex-wrap: wrap;\n    }\n}\n\n._1ZJIPU948r5NHvB26TavCS {\n    width: 100%;\n    gap: 4px;\n    display: grid;\n}\n\n._1w56f3TrnAvRdbjCyAJqNH {\n    padding: 8px 0 0;\n}\n\n@media (min-width: 979px) {\n    ._1w56f3TrnAvRdbjCyAJqNH {\n        width: 90%;\n    }\n}\n\n._25b0iKzv8K1uDno_aghx5Q {\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n._25b0iKzv8K1uDno_aghx5Q > div {\n    flex-grow: 1;\n    width: 100%;\n}\n\n._2yasrmsoW2eFeVk8K8eK74 {\n    padding-right: 16px;\n    padding-bottom: 40px;\n}\n\n.NcwBv0MCIRXYPp75otteF {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n._2skcse5W5vwT5vBl1MgIg7 {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "_2KFmIBWf4N8W3V5H-PVJHk",
	"user-name-section": "_2TjKGDUQenfDNuOLSMU6ge",
	"subtitle": "_3n0gowVx8aofMdJfKyQ6ET",
	"roles-section": "_3HVT4Mj-WF4dBTZUFhXJQF",
	"container": "_1ZJIPU948r5NHvB26TavCS",
	"role-selector": "_1w56f3TrnAvRdbjCyAJqNH",
	"org-selector": "_25b0iKzv8K1uDno_aghx5Q",
	"property": "_2yasrmsoW2eFeVk8K8eK74",
	"title": "NcwBv0MCIRXYPp75otteF",
	"title-row": "_2skcse5W5vwT5vBl1MgIg7"
};
module.exports = exports;
