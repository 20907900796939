import type { Order } from '@helloextend/extend-api-client'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { NewOrder } from '../atoms/create-contracts-modal'

enum SectionKey {
  customerInformation = 'customerInformation',
  shippingInformation = 'shippingInformation',
  billingInformation = 'billingInformation',
}

export const getCustomerInfoFieldsFromOrder = (
  order?: Order | NewOrder,
): Record<SectionKey, DataPropertyDetails[]> => {
  return {
    [SectionKey.customerInformation]: [
      {
        key: 'fullName',
        label: 'Full Name',
        dataCy: 'fullName',
        value: order?.customer.name ?? '',
      },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
        value: order?.customer.phone ?? '',
        dataCy: 'phoneNumber',
      },
      { key: 'email', label: 'Email', value: order?.customer.email ?? '', dataCy: 'email' },
      {
        key: 'currencyCode',
        label: 'Currency Code',
        value: order?.currency,
        dataCy: 'currencyCode',
      },
    ],
    [SectionKey.shippingInformation]: [
      {
        key: 'shippingAddress1',
        label: 'Address',
        value: order?.customer.shippingAddress?.address1 ?? '',
        dataCy: 'shippingAddress1',
      },
      {
        key: 'shippingAddress2',
        label: 'Address 2',
        value: order?.customer.shippingAddress?.address2 ?? '',
        dataCy: 'shippingAddress2',
      },
      {
        key: 'shippingCity',
        label: 'City',
        value: order?.customer.shippingAddress?.city ?? '',
        dataCy: 'city',
      },
      {
        key: 'shippingPostalCode',
        label: 'Postal Code',
        value: order?.customer.shippingAddress?.postalCode ?? '',
        dataCy: 'postalCode',
      },
      {
        key: 'shippingProvinceCode',
        label: order?.customer.shippingAddress?.countryCode === 'US' ? 'State' : 'Province',
        value: order?.customer.shippingAddress?.province ?? '',
        dataCy: 'provinceCode',
      },
      {
        key: 'shippingCountryCode',
        label: 'Country',
        value: order?.customer.shippingAddress?.countryCode ?? '',
        dataCy: 'countryCode',
      },
    ],
    [SectionKey.billingInformation]: [
      {
        key: 'billingAddress1',
        label: 'Address',
        value: order?.customer.billingAddress?.address1 ?? '',
        dataCy: 'billingAddress1',
      },
      {
        key: 'billingAddress2',
        label: 'Address 2',
        value: order?.customer.billingAddress?.address2 ?? '',
        dataCy: 'billingAddress2',
      },
      {
        key: 'billingCity',
        label: 'City',
        value: order?.customer.billingAddress?.city ?? '',
        dataCy: 'city',
      },
      {
        key: 'billingPostalCode',
        label: 'Postal Code',
        value: order?.customer.billingAddress?.postalCode ?? '',
        dataCy: 'postalCode',
      },
      {
        key: 'billingProvinceCode',
        label: order?.customer.billingAddress?.countryCode === 'US' ? 'State' : 'Province',
        value: order?.customer.billingAddress?.province ?? '',
        dataCy: 'provinceCode',
      },
      {
        key: 'billingCountryCode',
        label: 'Country',
        value: order?.customer.billingAddress?.countryCode ?? '',
        dataCy: 'countryCode',
      },
    ],
  }
}

export const getInitialValues = (
  fields: ReturnType<typeof getCustomerInfoFieldsFromOrder>,
): { [key: string]: any } => {
  const customerFields = fields.customerInformation?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)
  const billingFields = fields.billingInformation?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)
  const shippingFields = fields.shippingInformation?.reduce((acc, f) => {
    acc[f.key] = f.value
    return acc
  }, {} as any)
  return { ...customerFields, ...billingFields, ...shippingFields }
}

export const getNewOrderFromFormValues = (formValues: { [key: string]: any }): NewOrder => {
  return {
    currency: formValues.currencyCode,
    customer: {
      name: formValues.fullName,
      email: formValues.email,
      phone: formValues.phoneNumber,
      billingAddress: {
        address1: formValues.billingAddress1,
        address2: formValues.billingAddress2,
        city: formValues.billingCity,
        province: formValues.billingProvinceCode,
        postalCode: formValues.billingPostalCode,
        countryCode: formValues.billingCountryCode,
      },
      shippingAddress: {
        address1: formValues.shippingAddress1,
        address2: formValues.shippingAddress2,
        city: formValues.shippingCity,
        province: formValues.shippingProvinceCode,
        postalCode: formValues.shippingPostalCode,
        countryCode: formValues.shippingCountryCode,
      },
    },
  }
}
