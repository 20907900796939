// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rFV_znJhu_JLm2C8d8WZq {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"loading-container": "_2rFV_znJhu_JLm2C8d8WZq"
};
module.exports = exports;
