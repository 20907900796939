import type { FC } from 'react'
import React from 'react'
import { LinkTab } from './link-tab'
import styles from './tab-bar.module.css'

type TabBarLinkDefinition = {
  text: string
  to: string
}

type TabBarProps = {
  tabBarLinks: TabBarLinkDefinition[]
  children?: JSX.Element
  isAnalytics?: boolean
  'data-cy'?: string
}

const TabBar: FC<TabBarProps> = ({
  tabBarLinks,
  children,
  isAnalytics = false,
  'data-cy': dataCy,
}) => {
  return tabBarLinks && tabBarLinks.length ? (
    <>
      <div className={styles['tab-bar-wrapper']} data-cy={dataCy}>
        <ul className={styles.list}>
          {tabBarLinks.map((tabBarLink) => (
            <li className={styles['list-item']} key={tabBarLink.text}>
              <LinkTab key={tabBarLink.text} to={tabBarLink.to} isAnalytics={isAnalytics}>
                {tabBarLink.text}
              </LinkTab>
            </li>
          ))}
        </ul>
      </div>
      {children}
    </>
  ) : null
}

export type { TabBarLinkDefinition, TabBarProps }
export { TabBar }
