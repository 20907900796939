import { useCallback } from 'react'
import { useSetAtom } from 'jotai/react'
import { afterLoadingAtom, onLoadingAtom } from '../atoms/splash-screen'

const useSplashScreen = (key: string): { onLoading: () => void; afterLoading: () => void } => {
  const afterLoading = useSetAtom(afterLoadingAtom)
  const onLoading = useSetAtom(onLoadingAtom)

  const dispatchOnLoading = useCallback(() => {
    onLoading(key)
  }, [onLoading, key])

  const dispatchAfterLoading = useCallback(() => {
    afterLoading(key)
  }, [afterLoading, key])

  return {
    onLoading: dispatchOnLoading,
    afterLoading: dispatchAfterLoading,
  }
}

export { useSplashScreen }
