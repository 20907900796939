import type { FC } from 'react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAtomValue } from 'jotai/react'
import { customLogger } from '@extend/client-helpers'
import { getContractsUrl } from '../../utils/route-helper'
import { DownloadCSVData } from '../../components/download-csv-data'
import { getActiveStoreAtom } from '../../atoms/stores'
import { useExportContractsMutation } from '../../queries/contract'

const ContractsDownload: FC = () => {
  const store = useAtomValue(getActiveStoreAtom)
  const storeId = store?.id || ''
  const { search } = useLocation()
  const { push } = useHistory()
  const urlSearchParams = new URLSearchParams(search)
  const expiry = parseInt(urlSearchParams.get('expires') || '', 10) || null
  const { mutateAsync: downloadContracts, isLoading } = useExportContractsMutation()

  const isExpired = expiry === null || Date.now() > expiry

  const onDownloadClick = async (): Promise<void> => {
    try {
      const downloadResponse = await downloadContracts({ storeId })
      if (downloadResponse && 'url' in downloadResponse) {
        window.open(downloadResponse.url, '_blank')
      }
    } catch (err) {
      if (err instanceof Error) {
        customLogger.error(err.message, {
          stack: err.stack,
        })
      }
    }
  }

  const handleDismissClick = (): void => {
    push(getContractsUrl())
  }

  return (
    <div>
      <h2>Download Contracts Data</h2>
      <DownloadCSVData
        downloadDataType="Contracts"
        downloadLink="https://docs.extend.com/docs/manage-your-contracts"
        isExpired={isExpired}
        handleDismissClick={handleDismissClick}
        isProcessing={isLoading}
        onDownloadClick={onDownloadClick}
      />
    </div>
  )
}

export { ContractsDownload }
