import type { FC } from 'react'
import React from 'react'
import styles from './products-and-shipping-section.module.css'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import { DataPropertyGrid } from '../../../../components/data-property-grid'
import { ProductShipmentTable } from './product-shipment-table'
import { useProductsShipment } from '../../../../hooks/use-products-shipment'

export const ProductsAndShippingSection: FC = () => {
  const { shipmentInfos, unshipped } = useProductsShipment()
  return (
    <CollapsibleSection heading="Products &amp; Shipping" isExpanded>
      {shipmentInfos.map((info) => {
        return (
          <div key={info.label} data-cy={`${info.trackingId}:shipment`}>
            <h3 className={styles['shipment-label']}>{info.label}</h3>
            <DataPropertyGrid values={info.sectionValues} />
            <ProductShipmentTable products={info.products} id={info.trackingId} />
          </div>
        )
      })}
      {unshipped.length > 0 && (
        <>
          <h3 className={styles['shipment-label']}>No Shipment Assigned</h3>
          <ProductShipmentTable products={unshipped} id="unshipped" />
        </>
      )}
    </CollapsibleSection>
  )
}
