import type { Message } from '@helloextend/extend-api-client'

/**
 * Ticket for context : https://helloextend.atlassian.net/browse/MEXP-2406
 * This function removes any message with a type of textSelect as these are only to be used by kaley.
 * It also concatenates multiple message's content together into one string so it can be displayed by the client.
 * */
export const getFormattedIncredibotMessage = (messages: Message[]): string | undefined =>
  messages
    .reduce(
      (output: string, message: Message) =>
        message.type === 'text' ? `${output} ${message.content.trim()}` : output,
      ``,
    )
    .trim()
