// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3IrDDtddjnlr7QViUjV-rr {\n    color: var(--blue-1000);\n    font-size: 24px;\n    margin-bottom: 24px;\n}\n\n._13ZYZB0HlqbK9HJtbAERoN {\n    font-size: 20px;\n    line-height: 34px;\n    margin: 0 auto;\n    text-align: center;\n    width: 678px;\n}", ""]);
// Exports
exports.locals = {
	"heading": "_3IrDDtddjnlr7QViUjV-rr",
	"message": "_13ZYZB0HlqbK9HJtbAERoN"
};
module.exports = exports;
