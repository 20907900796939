// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2iCjvFl2sr3TQuY6bbVJPQ {\n    margin-top: 32px;\n}\n\n.Us0CdyqqwAgYxzezqgkEq {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n.PpALVhAKSEzH8GLAzTomq {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n\n._2kQIPO-4siqwoH9B_aCA5a {\n    margin-right: 16px;\n}\n\n._3SMPV5Bc6KBbVWcTD16CVN {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n.XdRD9Z1IODJsKwwqlLPCV {\n    display: flex;\n    margin-bottom: 16px;\n    width: 100%;\n    border-bottom: 1px solid var(--neutral-300);\n}\n\n.XdRD9Z1IODJsKwwqlLPCV:last-child {\n    border-bottom: none;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_2iCjvFl2sr3TQuY6bbVJPQ",
	"title": "Us0CdyqqwAgYxzezqgkEq",
	"title-row": "PpALVhAKSEzH8GLAzTomq",
	"property": "_2kQIPO-4siqwoH9B_aCA5a",
	"subtitle": "_3SMPV5Bc6KBbVWcTD16CVN",
	"row": "XdRD9Z1IODJsKwwqlLPCV"
};
module.exports = exports;
