import type { FC } from 'react'
import type { MerchantClaim } from '../../../../../../types/claims'
import React from 'react'
import { COLOR, InlineAlert, InlineAlertColor, Info as InfoIcon } from '@extend/zen'
import styled from '@emotion/styled'

interface FraudReportProps {
  claim: MerchantClaim
}

const FraudReport: FC<FraudReportProps> = ({ claim }) => {
  return (
    <Container>
      <Title data-cy="fraud-report-tab-title">Fraud Report</Title>
      {claim.validationError === 'fraud_risk' && (
        <InlineAlert
          data-cy="fraud-report-inline-alert"
          color={InlineAlertColor.yellow}
          icon={InfoIcon}
        >
          This claim must be reviewed manually due to a Fraud Risk Score of{' '}
          <strong>{claim.fraudRiskDetails?.riskScore}</strong> on this consumer&apos;s profile.
        </InlineAlert>
      )}
      <TabContents>
        <DataContainer data-cy="risk-reason-data">
          <DataHeader>Risk Reason</DataHeader>
          <ul>
            {claim.fraudRiskDetails?.riskReasoning.map((el) => (
              <li>{el}</li>
            ))}
          </ul>
        </DataContainer>
        <DataContainer data-cy="review-notes-data">
          <DataHeader>Fraud Ops Review Notes</DataHeader>
          <p>{claim.fraudRiskDetails?.reviewNotes}</p>
        </DataContainer>
      </TabContents>
    </Container>
  )
}

const TabContents = styled.div({
  display: 'flex',
  width: '100%',
})

const DataContainer = styled.div({
  marginTop: 24,
  flex: 1,
})

const DataHeader = styled.span({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Title = styled.h2({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '28px',
})

export type { FraudReportProps }
export { FraudReport }
