import type { SyntheticEvent, FC, FormEvent } from 'react'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, Input } from '@extend/zen'
import { Close } from '@extend/zen'
import { Select, SelectItem } from '../select'

export type OptionValue = {
  label: string
  value: string
}

export type SearchBarProps = {
  'data-cy'?: string
  id: string
  placeholder?: string
  searchLabel?: string
  resetSearch?: boolean
  isSearchOnly?: boolean
  onSubmit: (key: string, value: string) => void
  onReset?: () => void
  options?: OptionValue[]
  isLoading?: boolean
  isDisabled?: boolean
  initialKey?: string
  initialValue?: string
}

/**
 * @deprecated Instead of using this separate `<SearchBar />` component, use the search features that exist within the
 * Zen DataTable.
 */
const SearchBar: FC<SearchBarProps> = ({
  'data-cy': dataCy,
  id,
  placeholder,
  searchLabel = 'Search',
  resetSearch,
  onSubmit,
  options = [],
  isLoading,
  isDisabled,
  initialKey = '',
  initialValue = '',
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchKey, setSearchKey] = useState(initialKey || options[0]?.value)
  const [searchValue, setSearchValue] = useState(initialValue)

  const handleClear = useCallback(() => {
    setSearchValue('')
    inputRef.current?.focus()
  }, [])

  const onChangeText = (e: SyntheticEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    inputRef.current?.blur()
    onSubmit(searchKey, searchValue)
  }

  const handleChangeDropdown = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLInputElement
    const optionsValue = options.find((option) => option.value === target.value)
    setSearchKey(optionsValue?.value || '')
  }

  useEffect(() => {
    if (resetSearch) {
      setSearchValue('')
    }
  }, [resetSearch])

  return (
    <SearchForm onSubmit={handleSubmit} data-cy="search-bar">
      {Boolean(options.length) && (
        <SelectWrapper>
          <Select
            onChange={handleChangeDropdown}
            placeholder=""
            value={searchKey}
            isDisabled={isDisabled}
            inline
          >
            {options.map((option) => (
              <SelectItem value={option.value} label={option.label} key={option.value} />
            ))}
          </Select>
        </SelectWrapper>
      )}
      <InputWrapper isInline={Boolean(options.length)}>
        <Input
          data-cy={dataCy}
          id={id}
          label=""
          placeholder={placeholder}
          onChange={onChangeText}
          value={searchValue}
          ref={inputRef}
          isDisabled={isDisabled}
          actionButtonProps={
            searchValue
              ? {
                  emphasis: 'low',
                  icon: Close,
                  color: 'neutral',
                  onClick: handleClear,
                }
              : undefined
          }
        />
      </InputWrapper>
      <Button
        text={searchLabel}
        type="submit"
        size="regular"
        color="blue"
        emphasis="high"
        isProcessing={isLoading}
        isDisabled={isDisabled}
        data-cy="table-search-button"
      />
    </SearchForm>
  )
}

const SelectWrapper = styled.div({
  width: 184,
})

const SearchForm = styled.form({
  display: 'flex',
  alignItems: 'center',
})

const InputWrapper = styled.div<{ isInline: boolean }>(({ isInline }) => ({
  width: 440,
  marginRight: 4,
  overflow: 'hidden',
  '& > div': {
    // These styles are a way to hide the 4px border radius of the zen input, which we cannot
    // readily change. If isInline, then there are elements on both sides, otherwise, there is
    // only the search button to the right
    marginLeft: isInline ? -4 : 0,
  },
}))

export { SearchBar }
