// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ZRziIjpxJ6ff33u7WcHob {\n    padding-bottom: 40px;\n    width: 542px;\n}\n", ""]);
// Exports
exports.locals = {
	"input": "_1ZRziIjpxJ6ff33u7WcHob"
};
module.exports = exports;
