import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import type { Column, Cell } from 'react-table'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText, DateRange } from '@helloextend/merchants-ui'

import { date, currency } from '@extend/client-helpers'
import type { Contract, ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { ContractStatus, ContractType } from '@helloextend/extend-api-client'
import type { TextAlign } from '@helloextend/merchants-ui/src/components/table/table-styles'
import type { CellContext, ColumnDefs, FilterDef } from '@extend/zen'
import { BadgeDescriptions, Descriptions } from '../../../components/badge-descriptions'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import { getContractStatusCopy, statusDescriptions } from '../../../utils/contract-status'
import { CellStatusBadge } from '../../../components/cell-status-badge'
import { getContractProductName } from '../../../utils/get-contract-product-name'
import { getContractType } from '../../../utils/contract-mappers'

export interface ContractsTableMapping {
  contractId: string
  currency?: string
  customerEmail: string
  customerName: string
  merchantCustomerId?: string
  planPrice?: number
  productName: string
  productSerialNumber?: string
  purchaseDate?: number
  status: ContractStatus
  transactionId: string
  type: ContractType
  planId?: string
}

export const TABLE_COLUMNS: ColumnDefs<ContractsTableMapping> = [
  {
    label: 'Type',
    id: 'type',
    renderCell: (cellData: CellContext<ContractsTableMapping, ContractType>) =>
      getContractType(cellData.getValue()),
  },
  {
    label: 'Transaction ID',
    id: 'transactionId',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) =>
      cellData.getValue() || null,
    search: 'explicit',
  },
  {
    label: 'Trans Date',
    id: 'purchaseDate',
    renderCell: (cellData: CellContext<ContractsTableMapping, number>) =>
      date.format(cellData.getValue(), 'DD MMM YYYY'),
    isSortable: true,
  },
  {
    label: 'Customer Name',
    id: 'customerName',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() || '',
  },
  {
    label: 'Customer Email',
    id: 'customerEmail',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() || '',
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      const emailRegex = /\S+@\S+\.\S+/
      if (emailRegex.test(value)) {
        return null
      }
      return 'Must be a valid email address'
    },
  },
  {
    label: 'Prod Name',
    id: 'productName',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue(),
  },
  {
    label: 'Contract ID',
    id: 'contractId',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue(),
    search: 'explicit',
  },
  {
    label: 'Price',
    id: 'planPrice',
    align: 'right',
    renderCell: (cellData: CellContext<ContractsTableMapping, number>) =>
      `${currency.formatWithoutCurrencySymbol(cellData.getValue())} ${
        cellData.row.original.currency
      }`,
  },
  {
    label: 'Status',
    id: 'status',
    information: <Descriptions descriptions={statusDescriptions} />,
    renderCell: (cellData: CellContext<ContractsTableMapping, ContractStatus>) => {
      const statusBadgeDescription = getContractStatusCopy(cellData.getValue())
      return <CellStatusBadge badgeData={statusBadgeDescription} />
    },
  },
  {
    label: 'Plan ID',
    id: 'planId',
    search: 'explicit',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => `${cellData.getValue()}`,
  },
  {
    label: 'Serial Number',
    id: 'productSerialNumber',
    search: 'explicit',
    isSearchOnly: true,
  },
  {
    label: 'Customer ID',
    id: 'merchantCustomerId',
    search: 'explicit',
    isSearchOnly: true,
  },
]

export const TABLE_FILTERS: FilterDef[] = [
  {
    type: 'group',
    filterDefs: [
      {
        id: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            display: getContractStatusCopy(ContractStatus.LIVE).text,
            value: ContractStatus.LIVE,
          },
          {
            display: getContractStatusCopy(ContractStatus.CANCELLED).text,
            value: ContractStatus.CANCELLED,
          },
          {
            display: getContractStatusCopy(ContractStatus.FULFILLED).text,
            value: ContractStatus.FULFILLED,
          },
          {
            display: getContractStatusCopy(ContractStatus.DELIVERED).text,
            value: ContractStatus.DELIVERED,
          },
          {
            display: getContractStatusCopy(ContractStatus.EXPIRED).text,
            value: ContractStatus.EXPIRED,
          },
          {
            display: getContractStatusCopy(ContractStatus.VOIDED).text,
            value: ContractStatus.VOIDED,
          },
          {
            display: getContractStatusCopy(ContractStatus.TERMINATED).text,
            value: ContractStatus.TERMINATED,
          },
        ],
      },
      {
        id: 'typeFilter',
        label: 'Type',
        type: 'select',
        isMultiSelect: true,
        options: [
          {
            display: getContractType(ContractType.PCRS),
            value: ContractType.PCRS,
          },
          {
            display: getContractType(ContractType.SHIPPING_PROTECTION),
            value: ContractType.SHIPPING_PROTECTION,
          },
          {
            display: getContractType(ContractType.CATEGORY),
            value: ContractType.CATEGORY,
          },
          {
            display: getContractType(ContractType.PRODUCT_PROTECTION_BUNDLE),
            value: ContractType.PRODUCT_PROTECTION_BUNDLE,
          },
        ],
      },
    ],
  },
]

/**
 * @deprecated DO NOT USE - ContractsDataTable manages this with ContractsTableMapping
 */
type ContractsColumn = Column<ContractsV2GetResponse> & {
  disableSortBy?: boolean
  Header: string
  HeaderAdornment?: ReactNode
  headerTooltip?: string
  textAlign?: TextAlign
}

/**
 * @deprecated DO NOT USE - ContractsDataTable manages this with TABLE_COLUMNS
 */
const columns: ContractsColumn[] = [
  {
    Header: 'Type',
    headerTooltip:
      'The type of protection your customer purchased: product protection or shipping protection',
    accessor: 'type',
    width: 100,
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => {
      return <CellText>{getContractType(value)}</CellText>
    },
  },
  {
    Header: 'Transaction ID',
    headerTooltip: 'Transaction ID in your system, often same as order ID',
    accessor: 'transactionId',
    disableSortBy: true,
    width: 165,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-transactionId">{value}</CellText>
    ),
  },
  {
    Header: 'Trans Date',
    headerTooltip: 'Date order was created',
    accessor: 'purchaseDate',
    disableSortBy: false,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-transactionDate">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Customer Name',
    headerTooltip: 'Customer’s name',
    accessor: 'customer.name',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-customerName">{value}</CellText>
    ),
  },
  {
    Header: 'Customer Email',
    headerTooltip: 'Customer’s email address',
    accessor: 'customer.email',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-customerEmail">{value}</CellText>
    ),
  },
  {
    Header: 'Prod Name',
    headerTooltip: 'Name of the product as displayed in your store',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell): JSX.Element => (
      <CellText data-cy="contract-productName">
        {getContractProductName(original as Contract)}
      </CellText>
    ),
  },
  {
    Header: 'Contract Id',
    headerTooltip: 'ID of the contract in Extend’s system',
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="contract-id">{value}</CellText>,
  },
  {
    Header: 'Plan Price',
    headerTooltip: 'Price customer paid for plan after discounts applied',
    accessor: 'purchasePrice',
    disableSortBy: true,
    textAlign: 'right',
    Cell: ({ value, row: { original } }: Cell): JSX.Element => {
      return (
        <CellText align="right" data-cy="contract-planPurchasePrice">
          {currency.formatWithoutCurrencySymbol(value)}{' '}
          {(original as ContractsV2GetResponse).purchaseCurrency}
        </CellText>
      )
    },
  },
  {
    Header: 'Status',
    HeaderAdornment: <BadgeDescriptions descriptions={statusDescriptions} buttonSize="xsmall" />,
    headerTooltip: 'Current status of the contract',
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => {
      const statusBadgeDescription = useMemo((): BadgeDescriptionItem => {
        return getContractStatusCopy(value)
      }, [value])
      return <CellStatusBadge badgeData={statusBadgeDescription} />
    },
  },
]

/**
 * @deprecated DO NOT USE - ContractsDataTable manages this with TABLE_FILTERS
 */
const filterOptions: Record<string, FilterOptions> = {
  status: {
    label: 'Contract Status',
    type: 'checkbox',
    options: {
      [ContractStatus.LIVE]: getContractStatusCopy(ContractStatus.LIVE).text,
      [ContractStatus.CANCELLED]: getContractStatusCopy(ContractStatus.CANCELLED).text,
      [ContractStatus.FULFILLED]: getContractStatusCopy(ContractStatus.FULFILLED).text,
      [ContractStatus.DELIVERED]: getContractStatusCopy(ContractStatus.DELIVERED).text,
      [ContractStatus.EXPIRED]: getContractStatusCopy(ContractStatus.EXPIRED).text,
      [ContractStatus.VOIDED]: getContractStatusCopy(ContractStatus.VOIDED).text,
      [ContractStatus.TERMINATED]: getContractStatusCopy(ContractStatus.TERMINATED).text,
    },
  },
  transactionDate: {
    label: 'Contract Transaction Date',
    type: 'dateRange',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  canceledDate: {
    label: 'Contract Canceled Date',
    type: 'dateRange',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  refundedDate: {
    label: 'Contract Refunded Date',
    type: 'dateRange',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  updatedDate: {
    label: 'Contract Updated Date',
    type: 'dateRange',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
  typeFilter: {
    label: 'Contract Type',
    type: 'checkbox',
    options: {
      [ContractType.PCRS]: 'Product',
      [ContractType.SHIPPING_PROTECTION]: 'Shipping',
      [ContractType.CATEGORY]: 'Category',
    },
  },
}

/**
 * @deprecated DO NOT USE - ContractsDataTable manages this with TABLE_COLUMNS
 */
const searchOptions = [
  {
    label: 'Transaction ID',
    value: 'containsTransactionId',
  },
  {
    label: 'Contract ID',
    value: 'containsId',
  },
  { label: 'Reference ID', value: 'containsProductReferenceId' },
  { label: 'Serial Number', value: 'containsProductSerialNumber' },
  { label: 'Customer Email', value: 'containsCustomerEmail' },
  { label: 'Customer ID', value: 'containsMerchantCustomerId' },
]

export { columns, filterOptions, searchOptions }
