import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const BASE_URL = `https://${EXTEND_API_HOST}/auth`

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export const GET_ME_CACHE_KEY = 'GetMe'

export function useGetApiKeyMutation(): UseBaseMutationResult<string, Error, void, void> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/oauth/token`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({
          grant_type: 'password',
        }),
      })

      if (!response.ok) {
        throw new Error('Unable to fetch api key')
      }

      const data = await response.json()

      return data.access_token
    },
  })
}
