import type { FC } from 'react'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Checkbox, Input, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import { isArray } from 'lodash/fp'
import { useHistory } from 'react-router-dom'
import type { getContractDetailsFieldsFromContract } from '../../../../utils/contract-details-utils'
import { getContractId, getInitialValues } from '../../../../utils/contract-details-utils'
import type { DataPropertyDetails } from '../../../../components/data-property-grid'
import { DataPropertyGrid } from '../../../../components/data-property-grid'
import { contractDetailsSchema } from './contract-details.schema'
import { useUpdateContractMutation } from '../../../../queries/contract'
import { Permission } from '../../../../lib/permissions'
import { usePermissions } from '../../../../hooks/use-permissions'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import styles from './customer-information-form-grid.module.css'
import { LeavePageGuard } from '../../../../components/leave-page-guard'

export const CustomerInformationFormGrid: FC<{
  fields: ReturnType<typeof getContractDetailsFieldsFromContract>
}> = ({ fields }) => {
  const [isSameAsBilling, setIsSameAsBilling] = useState(false)
  const { mutateAsync: updateContract, isLoading } = useUpdateContractMutation()
  const { toast } = useToaster()
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const { values, setValues, dirty, errors, isValid, handleChange, resetForm, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: contractDetailsSchema,
      onSubmit: async (formValues): Promise<void> => {
        try {
          await updateContract({
            contract: {
              id: getContractId(fields),
              customer: {
                email: formValues.email,
                name: formValues.fullName,
                phone: formValues.phoneNumber,
                billingAddress: {
                  address1: formValues.billingAddress1,
                  address2: formValues.billingAddress2,
                  city: formValues.billingCity,
                  countryCode: formValues.billingCountryCode,
                  postalCode: formValues.billingPostalCode,
                  provinceCode: formValues.billingProvinceCode,
                },
                shippingAddress: {
                  address1: isSameAsBilling
                    ? formValues.billingAddress1
                    : formValues.shippingAddress1,
                  address2: isSameAsBilling
                    ? formValues.billingAddress2
                    : formValues.shippingAddress2,
                  city: isSameAsBilling ? formValues.billingCity : formValues.shippingCity,
                  countryCode: isSameAsBilling
                    ? formValues.billingCountryCode
                    : formValues.shippingCountryCode,
                  postalCode: isSameAsBilling
                    ? formValues.billingPostalCode
                    : formValues.shippingPostalCode,
                  provinceCode: isSameAsBilling
                    ? formValues.billingProvinceCode
                    : formValues.shippingProvinceCode,
                },
              },
            },
          })
          toast({
            message: 'Contract updated',
            toastColor: ToastColor.green,
            toastDuration: ToastDuration.short,
          })
          setIsEditingCustomer(false)
        } catch {
          toast({
            message: 'Contract update failed, please try again later.',
            toastColor: ToastColor.red,
            toastDuration: ToastDuration.short,
          })
        }
      },
      initialValues: getInitialValues(fields),
    })
  const [isEditingCustomer, setIsEditingCustomer] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (): void => {
    if (isExpanded && isEditingCustomer) {
      setIsExpanded(false)
      setIsEditingCustomer(false)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const handleEdit = (): void => {
    setIsEditingCustomer(true)
    setIsExpanded(true)
  }

  const handleCancel = (): void => {
    setIsEditingCustomer(false)
    resetForm()
  }

  const handleClickSameAsBilling = async (): Promise<void> => {
    if (isSameAsBilling) {
      setIsSameAsBilling(false)
    } else {
      await setValues({
        ...values,
        shippingAddress1: values.billingAddress1,
        shippingAddress2: values.billingAddress2,
        shippingCity: values.billingCity,
        shippingCountryCode: values.billingCountryCode,
        shippingPostalCode: values.billingPostalCode,
        shippingProvinceCode: values.billingProvinceCode,
      })
      setIsSameAsBilling(true)
    }
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  return (
    <CollapsibleSection
      heading="Customer Information"
      isExpanded={isExpanded}
      onToggleRequest={handleToggle}
      toolBar={
        hasPermission(Permission.StoreContractsFullAccess) &&
        (isEditingCustomer ? (
          <Button
            color="red"
            emphasis="low"
            type="button"
            size="small"
            onClick={handleCancel}
            text="Cancel"
          />
        ) : (
          <Button
            color="blue"
            emphasis="low"
            size="small"
            type="button"
            onClick={handleEdit}
            text="Edit"
            data-cy="edit-customer-information"
          />
        ))
      }
      data-cy="customerInformation"
    >
      <LeavePageGuard isNavBlocked={isValid && dirty} handleLeavePage={handleLeavePage} />
      {isEditingCustomer ? (
        <form onSubmit={handleSubmit} className={styles['form-container']}>
          <section className={styles['three-input-grid']}>
            {fields.customer.map((v: DataPropertyDetails) => {
              const err = errors[v.key]
              const hasErrors = Boolean(err)
              const errorMessage = isArray(err) ? err.join(' ') : (err as string)
              return (
                <Input
                  key={v.key}
                  onChange={handleChange}
                  id={v.key}
                  label={v.label}
                  value={values[v.key]}
                  isError={hasErrors}
                  errorFeedback={errorMessage}
                />
              )
            })}
          </section>
          <h4 className={styles['h4-styles']}>Billing Information</h4>
          <section className={styles['six-input-grid']}>
            {fields.billingInformation.map((v: DataPropertyDetails) => {
              const err = errors[v.key]
              const hasErrors = Boolean(err)
              const errorMessage = isArray(err) ? err.join(' ') : (err as string)
              return (
                <Input
                  key={v.key}
                  onChange={handleChange}
                  id={v.key}
                  label={v.label}
                  value={values[v.key]}
                  isError={hasErrors}
                  errorFeedback={errorMessage}
                />
              )
            })}
          </section>
          <h4 className={styles['h4-styles']}>Shipping Information</h4>
          <Checkbox
            label="Same as Billing Address"
            checked={isSameAsBilling}
            onChange={handleClickSameAsBilling}
            data-cy="same-as-billing-checkbox"
          />
          <section className={styles['six-input-grid']}>
            {fields.shippingInformation.map((v: DataPropertyDetails, i) => {
              const err = errors[v.key]
              const hasErrors = Boolean(err)
              const errorMessage = isArray(err) ? err.join(' ') : (err as string)
              const value = isSameAsBilling
                ? values[fields.billingInformation[i].key]
                : values[v.key]
              return (
                <Input
                  isDisabled={isSameAsBilling}
                  onChange={handleChange}
                  id={v.key}
                  label={v.label}
                  value={value}
                  key={v.key}
                  isError={hasErrors}
                  errorFeedback={errorMessage}
                />
              )
            })}
          </section>
          <span className={styles['button-group']}>
            <Button emphasis="medium" text="Cancel" onClick={handleCancel} />
            <Button
              type="submit"
              text="Save Changes"
              isDisabled={!isValid || !dirty}
              isProcessing={isLoading}
            />
          </span>
        </form>
      ) : (
        <>
          <DataPropertyGrid values={fields.customer} className={styles['three-property-grid']} />
          <h4 className={styles['h4-styles']}>Billing Information</h4>
          <DataPropertyGrid
            values={fields.billingInformation}
            className={styles['six-property-grid']}
          />
          <h4 className={styles['h4-styles']}>Shipping Information</h4>
          <DataPropertyGrid
            values={fields.shippingInformation}
            className={styles['six-property-grid']}
          />
        </>
      )}
    </CollapsibleSection>
  )
}
