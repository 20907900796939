import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  SPPlanCategoryMapping,
  SPPlanCategoryMappingDeleteRequest,
  SPPlanCategoryMappingsGetResponse,
  SPPlanPriceBandMappingsGetResponse,
  SPPlanPriceBandMappingsRequest,
} from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import type {
  StoreIdAndName,
  StoreIdAndNameFetchAllResponse,
  StoresContractsCreateRequest,
} from '@helloextend/extend-api-client/src/models/store'
import type {
  Store,
  StoresUpdateRequest,
  StoresGetRequest,
  StoreConsumerGetRequest,
  StoreConsumerReadableProperties,
  StoreFetchAllResponseNonSensitive,
  StoresGetLatestResponse,
  StoreIntegrationSettings,
  StoreNonSensitive,
  StorePlanMapping,
  StoresPlanMappingsResponse,
  StorePlanPricingListResponse,
  StorePlanPricingRequest,
  StoreSearchResponse,
  StoreSearchCount,
  StorePlanPricingUpdateRequest,
  StorePlanPricingUpdateResponse,
  GetPlanSetMappingRequest,
  GetPlanSetMappingsResponse,
  GetPlanSetCategoriesListResponse,
  CreateStorePlanSetMappingRequest,
  CreateStorePlanSetMappingResponse,
  UpdateStorePlanSetMappingRequest,
  UpdateStorePlanSetMappingResponse,
  DeleteStorePlanSetMappingRequest,
  CategoryMappingListRequest,
  CategoryMappingListResponse,
  CategoryMapping,
  CategoryMappingCreateBody,
  ContractsGetResponse,
  CategoryMappingBatchCreateBody,
  CategoryMappingDownloadResponse,
  CategoryMappingDownloadRequest,
  CategoryMappingImportUrlRequest,
  CategoryMappingImportUrlResponse,
  CategoryMappingImportRequest,
} from '@helloextend/extend-api-client'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  CreateStoreRequest,
  StorePlanMappingProductsCountRequest,
  StorePlanMappingProductsCountResponse,
  StorePlanMappingRequest,
  StorePlanMappingResponse,
  StoreSearchParams,
  StoresQueAsyncJobRequest,
  StoresQueueAsyncJobResponse,
} from './types'
import { buildMultiValue } from '../utils/querystring'

export const storesApi = createApi({
  baseQuery,
  reducerPath: 'Stores',
  tagTypes: [
    'stores',
    'integrationConfig',
    'planMappings',
    'planSetCategories',
    'planSetMappings',
    'storesRDS',
    'storePlanPricing',
    'CategoryMappings',
    'CategoryMappingsDownload',
    'CategoryMappingsImport',
    'ShippingProtectionPriceBandMappings',
    'ShippingProtectionCategoryMappings',
    'WarrantyMappings',
  ],
  endpoints: (build) => ({
    getStore: build.query<Store, StoresGetRequest>({
      query: ({ storeId, version = 'default' }) => ({
        url: `/stores/${storeId}`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
    getStoreConsumer: build.query<StoreConsumerReadableProperties, StoreConsumerGetRequest>({
      query: ({ storeId, includeDeleted, accessToken, version = 'latest' }) => ({
        url: `/stores/store-consumer/${storeId}`,
        params: { includeDeleted },
        headers: {
          accept: `application/json; version=${version};`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
    getStores: build.query<
      StoresGetLatestResponse,
      { accountId?: string; version?: string; pageCursor?: string; limit?: number }
    >({
      query: ({ accountId, version = 'default', pageCursor, limit }) => ({
        url: '/stores',
        params: { accountId, pageCursor, limit },
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      transformResponse: (stores: Store[] | StoresGetLatestResponse, _meta, { version }) => {
        if (version === 'latest') {
          return stores as StoresGetLatestResponse
        }

        return {
          items: stores as Store[],
        }
      },
      providesTags: (result) => {
        if (result?.items.length) {
          return [
            ...result.items.map(
              ({ id }) =>
                ({
                  type: 'stores',
                  id,
                } as const),
            ),
            { type: 'stores', id: 'LIST' },
          ]
        }
        return [{ type: 'stores', id: 'LIST' }]
      },
    }),
    updateStore: build.mutation<Store, StoresUpdateRequest>({
      query: ({ storeId, data, version = 'default' }) => ({
        url: `/stores/${storeId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
    getIntegrationConfig: build.query<StoreIntegrationSettings, StoresGetRequest>({
      query: ({ storeId, version = 'default' }) => ({
        url: `/stores/${storeId}/integration-config`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'integrationConfig', id: storeId }],
    }),
    updateIntegrationConfig: build.mutation<StoreIntegrationSettings, StoresUpdateRequest>({
      query: ({ storeId, data, version = 'default' }) => ({
        url: `/stores/${storeId}/integration-config`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'integrationConfig', id: storeId }],
    }),
    getPlanMappings: build.query<StorePlanMapping[], { storeId: string }>({
      async queryFn({ storeId }, _queryApi, _extraOptions, fetchWithBQ) {
        const getPlanMappingsPage = async (cursor?: string): Promise<StorePlanMapping[]> => {
          const planMappingListResult: QueryReturnValue = await fetchWithBQ({
            url: `/stores/${storeId}/plan-mappings${cursor ? `?cursor=${cursor}` : ''}`,
            headers: {
              accept: `application/json; version=latest;`,
            },
          })

          if (planMappingListResult.error) throw planMappingListResult.error

          const { items, nextPageCursor } = planMappingListResult.data as StoresPlanMappingsResponse

          return [...items, ...(nextPageCursor ? await getPlanMappingsPage(nextPageCursor) : [])]
        }

        const planMappingsList = await getPlanMappingsPage()

        return { data: planMappingsList }
      },
      providesTags: (_, _err, { storeId }) => [{ type: 'planMappings', id: storeId }],
    }),
    storesSearch: build.query<StoreSearchResponse, StoreSearchParams>({
      query: (query) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/merchants-portal/stores/search?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
      providesTags: (_, _err, { storeId }) => {
        return [{ type: 'storesRDS', id: storeId }]
      },
    }),
    getStoresSearchCount: build.query<StoreSearchCount, StoreSearchParams>({
      query: (query) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/merchants-portal/stores/search-count?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
    }),
    addPlanMappings: build.mutation<StorePlanMappingResponse, StorePlanMappingRequest>({
      query: ({ storeId, data, version = 'default' }) => ({
        url: `/stores/${storeId}/plan-mappings`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'planMappings', id: storeId }],
    }),
    getPlanSetMappings: build.query<GetPlanSetMappingsResponse, GetPlanSetMappingRequest>({
      query: ({ storeId }) => ({
        url: `/stores/${storeId}/plan-set-mappings`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
      }),
      providesTags: [{ type: 'planSetMappings' }],
    }),
    getPlanSetCategories: build.query<GetPlanSetCategoriesListResponse, void>({
      query: () => ({
        url: `/taxonomy/plancategories`,
        headers: {
          accept: `application/json; version=latest;`,
        },
      }),
      providesTags: [{ type: 'planSetCategories' }],
    }),
    createPlanSetMappings: build.mutation<
      CreateStorePlanSetMappingResponse,
      CreateStorePlanSetMappingRequest
    >({
      query: ({ storeId, ...body }) => ({
        url: `/stores/${storeId}/plan-set-mappings`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: ['planSetMappings'],
    }),
    updatePlanSetMappings: build.mutation<
      UpdateStorePlanSetMappingResponse,
      UpdateStorePlanSetMappingRequest
    >({
      query: ({ storePlanSetMappingId: planCategoryId, planSetId, storeId }) => ({
        url: `/stores/${storeId}/plan-set-mappings/${planCategoryId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
        method: 'PUT',
        body: { planSetId },
      }),
      invalidatesTags: ['planSetMappings'],
    }),
    deletePlanSetMappings: build.mutation<void, DeleteStorePlanSetMappingRequest>({
      query: ({ storeId, storePlanSetMappingId }) => ({
        url: `/stores/${storeId}/plan-set-mappings/${storePlanSetMappingId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
        method: 'DELETE',
      }),
      invalidatesTags: ['planSetMappings'],
    }),
    listAllStoreIds: build.query<string[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: string[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue = await fetchWithBQ({
            url: nextPageCursor ? `/stores?limit=500&cursor=${nextPageCursor}` : '/stores',
            headers: {
              accept: 'application/json; version=latest;',
            },
          })

          if (res.error) throw res.error

          const payload = res.data as StoreFetchAllResponseNonSensitive

          if (payload.items) {
            payload.items.forEach((store: StoreNonSensitive) => {
              if (!data.includes(store.id)) {
                data.push(store.id)
              }
            })
            nextPageCursor = payload.nextPageCursor
          }
        } while (nextPageCursor)

        return { data }
      },
    }),
    listAllStoreIdsWithName: build.query<StoreIdAndName[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: StoreIdAndName[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue = await fetchWithBQ({
            url: nextPageCursor ? `/stores?limit=500&cursor=${nextPageCursor}` : '/stores',
            headers: {
              accept: 'application/json; version=latest;',
            },
          })

          if (res.error) throw res.error

          const payload = res.data as StoreIdAndNameFetchAllResponse

          if (payload.items) {
            data.push(
              ...payload.items.map((store: StoreIdAndName) => ({ id: store.id, name: store.name })),
            )
            nextPageCursor = payload.nextPageCursor
          }
        } while (nextPageCursor)

        return { data }
      },
    }),
    getStorePlanPricingList: build.query<StorePlanPricingListResponse, StorePlanPricingRequest>({
      // if planId is not provided, it will return all planPricing mappings for the store
      query: ({ storeId, planId, version = 'default' }) => ({
        url: `/stores/${storeId}/store-pricing${planId ? `?planId=${planId}` : ''}`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { storeId, planId }) => [
        { type: 'storePlanPricing', id: storeId, planId },
      ],
    }),
    updateStorePlanPricing: build.mutation<
      StorePlanPricingUpdateResponse,
      StorePlanPricingUpdateRequest
    >({
      query: ({ storeId, data, version = 'default', isBatchUpdate }) => ({
        url: `/stores/${storeId}/store-pricing${isBatchUpdate ? '?batch=true' : ''}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId, planId }) => [
        { type: 'storePlanPricing', id: storeId, planId },
      ],
    }),
    createStoresContract: build.mutation<ContractsGetResponse, StoresContractsCreateRequest>({
      query: ({ storeId, ...body }) => ({
        url: `stores/${storeId}/contracts`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=2021-04-01;`,
        },
        body,
      }),
    }),
    bulkStoreUpdateJob: build.mutation<StoresQueueAsyncJobResponse, StoresQueAsyncJobRequest>({
      query: ({ storeId, jobType, filters, data, planIds }) => {
        let queryString
        if (jobType === 'delete-spm') {
          queryString = planIds ? planIds.map((planId) => `planId=${planId}`).join('&') : ''
        } else {
          queryString = filters ? buildMultiValue(filters) : ''
        }
        return {
          url: `stores/${storeId}/jobs/${jobType}?${queryString}`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: `application/json; version=latest;`,
          },
          body: data,
        }
      },
      async onQueryStarted({ storeId, planIds, jobType }, { dispatch, queryFulfilled }) {
        if (jobType !== 'delete-spm') return
        const patchResult = dispatch(
          storesApi.util.updateQueryData('getPlanMappings', { storeId }, (response) => ({
            ...response,
            items: response?.filter(
              (storePlanMapping) => !planIds?.includes(storePlanMapping.planId),
            ),
          })),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'stores', id: storeId }],
    }),
    getStorePlanMappingProductCounts: build.query<
      StorePlanMappingProductsCountResponse,
      StorePlanMappingProductsCountRequest
    >({
      query: ({ storeId, planId }) => ({
        url: `/merchants-portal/stores/${storeId}/plan/${planId}/product-counts`,
        headers: { accept: 'application/json; version=latest;' },
      }),
    }),
    createStore: build.mutation<Store, CreateStoreRequest>({
      query: ({ body, version = '2022-02-01' }) => ({
        url: `stores`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'stores', id: 'LIST' }],
    }),
    // Category Mappings
    listCategoryMappings: build.query<CategoryMappingListResponse, CategoryMappingListRequest>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const baseUrl = _arg.filter
          ? `/stores/${_arg.storeId}/category-mappings?limit=500&filter=${_arg}`
          : `/stores/${_arg.storeId}/category-mappings?limit=500`
        const getCategoryMappings = async (cursor = ''): Promise<CategoryMapping[]> => {
          const queryUrl = cursor ? `${baseUrl}&cursor=${cursor}` : baseUrl
          const response: QueryReturnValue = await fetchWithBQ(queryUrl)
          if (response.error) throw response.error
          const { items, nextPageCursor } = response.data as CategoryMappingListResponse

          return [
            ...items,
            ...(nextPageCursor
              ? await getCategoryMappings(encodeURIComponent(nextPageCursor))
              : []),
          ]
        }

        const allMappings = await getCategoryMappings()
        return {
          data: {
            items: allMappings,
            limit: 500,
          },
        }
      },
      providesTags: [{ type: 'CategoryMappings' }],
    }),
    createCategoryMapping: build.mutation<CategoryMapping, CategoryMappingCreateBody>({
      query: (data) => {
        return {
          url: `/stores/${data.storeId}/category-mappings`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['CategoryMappings'],
    }),
    createBatchCategoryMappings: build.mutation<CategoryMapping[], CategoryMappingBatchCreateBody>({
      query: (data) => {
        return {
          url: `/stores/${data[0].storeId}/category-mappings/batch`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['CategoryMappings'],
    }),
    createCategoryMappingDownload: build.mutation<
      CategoryMappingDownloadResponse,
      CategoryMappingDownloadRequest
    >({
      query: ({ storeId, email }) => ({
        url: `/stores/${storeId}/category-mappings/download`,
        method: 'POST',
        headers: {
          'X-Idempotency-Key': `${storeId}-${email}`,
        },
      }),
      invalidatesTags: (_, _err, { storeId }) => [
        { type: 'CategoryMappingsDownload', id: storeId },
      ],
    }),
    getSPPriceBandMappings: build.query<SPPlanPriceBandMappingsGetResponse, string>({
      query: (storeId) => ({
        url: `/stores/${storeId}/sp-plan-price-band-mappings`,
        headers: { accept: 'application/json; version=latest;' },
      }),
      providesTags: ['ShippingProtectionPriceBandMappings'],
    }),
    updateSPPriceBandMappings: build.mutation<
      SPPlanPriceBandMappingsGetResponse,
      SPPlanPriceBandMappingsRequest
    >({
      query: ({ storeId, priceBands }) => ({
        url: `/stores/${storeId}/sp-plan-price-band-mappings`,
        method: 'PUT',
        body: {
          priceBands,
        },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
      }),
      invalidatesTags: ['ShippingProtectionPriceBandMappings'],
    }),
    getListSPPlanCategoryMappings: build.query<SPPlanCategoryMappingsGetResponse, string>({
      query: (storeId) => ({
        url: `/stores/${storeId}/sp-plan-category-mapping`,
        headers: { accept: 'application/json; version=latest;' },
      }),
      providesTags: ['ShippingProtectionCategoryMappings'],
    }),
    putSPPlanCategoryMapping: build.mutation<SPPlanCategoryMapping, SPPlanCategoryMapping>({
      query: ({
        storeId,
        planCategoryId,
        spPlanId,
        revenueShare,
        partialReimbursement,
        partialReimbursementFixed,
      }) => ({
        url: `/stores/${storeId}/sp-plan-category-mapping/${planCategoryId}`,
        body: {
          spPlanId,
          revenueShare,
          partialReimbursement,
          partialReimbursementFixed,
        },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        method: 'PUT',
      }),
      invalidatesTags: ['ShippingProtectionCategoryMappings'],
    }),
    deleteSPPlanCategoryMapping: build.mutation<void, SPPlanCategoryMappingDeleteRequest>({
      query: ({ storeId, planCategoryId }) => ({
        url: `/stores/${storeId}/sp-plan-category-mapping/${planCategoryId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        method: 'DELETE',
      }),
      invalidatesTags: ['ShippingProtectionCategoryMappings'],
    }),
    getCategoryMappingsImportUrl: build.mutation<
      CategoryMappingImportUrlResponse,
      CategoryMappingImportUrlRequest
    >({
      query: ({ storeId }) => ({
        url: `/stores/${storeId}/category-mappings/upload`,
        headers: {
          accept: 'application/json; version=2022-02-01;',
        },
        method: 'POST',
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'CategoryMappingsImport', id: storeId }],
    }),
    importCategoryMappings: build.mutation<string, CategoryMappingImportRequest>({
      query: ({ url, fileBlob }) => {
        return {
          url,
          method: 'PUT',
          body: fileBlob,
          headers: { 'content-type': 'application/gzip' },
        }
      },
      invalidatesTags: ['CategoryMappings'],
    }),
  }),
})

export const {
  useGetStoreQuery,
  useLazyGetStoreQuery,
  useGetStoresQuery,
  useLazyGetStoresQuery,
  useUpdateStoreMutation,
  useGetIntegrationConfigQuery,
  useUpdateIntegrationConfigMutation,
  useGetStoreConsumerQuery,
  useLazyGetStoreConsumerQuery,
  useGetPlanMappingsQuery,
  useLazyGetPlanMappingsQuery,
  useStoresSearchQuery,
  useGetStoresSearchCountQuery,
  useAddPlanMappingsMutation,
  useListAllStoreIdsQuery,
  useListAllStoreIdsWithNameQuery,
  useGetStorePlanPricingListQuery,
  useUpdateStorePlanPricingMutation,
  useCreateStoresContractMutation,
  useBulkStoreUpdateJobMutation,
  useGetStorePlanMappingProductCountsQuery,
  useCreateStoreMutation,
  useListCategoryMappingsQuery,
  useLazyListCategoryMappingsQuery,
  useCreateCategoryMappingMutation,
  useCreateBatchCategoryMappingsMutation,
  useCreateCategoryMappingDownloadMutation,
  useGetPlanSetCategoriesQuery,
  useGetPlanSetMappingsQuery,
  useCreatePlanSetMappingsMutation,
  useUpdatePlanSetMappingsMutation,
  useDeletePlanSetMappingsMutation,
  useGetSPPriceBandMappingsQuery,
  useUpdateSPPriceBandMappingsMutation,
  useGetListSPPlanCategoryMappingsQuery,
  usePutSPPlanCategoryMappingMutation,
  useDeleteSPPlanCategoryMappingMutation,
  useGetCategoryMappingsImportUrlMutation,
  useImportCategoryMappingsMutation,
} = storesApi
