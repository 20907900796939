import { useCallback, useEffect } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import jwt from '@helloextend/jwt'
import { useHistory } from 'react-router-dom'
import { isRefreshingTokensAtom, v3AccessTokenAtom } from '../atoms/auth'
import { useRefreshAndExchangeTokens } from '../queries/okta'
import { oktaRefreshTokenAtom } from '../atoms/okta'

type DecodedAccessToken = {
  firstName: string
  lastName: string
  ern: string
  sub: string
  email: string
  scope: string
  exp: number
  iss: string
}

export function isTokenRefreshNeeded(token: string): boolean {
  const decoded = jwt.decode(token) as DecodedAccessToken

  if (!decoded || isExpired(decoded.exp)) {
    return true
  }

  const expiryTime = new Date(decoded.exp * 1000)
  const thirtyMinutesToExpiry = new Date(expiryTime.getTime() - 30 * 60000)
  const currentTime = new Date()

  return currentTime > thirtyMinutesToExpiry
}

export function isTokenExpired(token: string): boolean {
  const decoded = jwt.decode(token) as DecodedAccessToken

  return isExpired(decoded?.exp)
}

function isExpired(expiration: number): boolean {
  return Date.now() / 1000 > expiration
}

const useTokenRefresh = (): void => {
  const history = useHistory()
  const { mutateAsync: refreshAndExchangeTokens } = useRefreshAndExchangeTokens()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom)
  const oktaRefreshToken = useAtomValue(oktaRefreshTokenAtom)
  const isRefreshingTokens = useAtomValue(isRefreshingTokensAtom)
  const setIsRefreshingTokens = useSetAtom(isRefreshingTokensAtom)

  const handleTokenRefresh = useCallback(async () => {
    if (
      document.visibilityState === 'visible' &&
      v3AccessToken &&
      oktaRefreshToken &&
      isTokenRefreshNeeded(v3AccessToken) &&
      !isRefreshingTokens // prevent multiple refreshes - i.e. multiple clicks
    ) {
      setIsRefreshingTokens(true)
      try {
        await refreshAndExchangeTokens({ v3AccessToken, oktaRefreshToken })
      } catch (e) {
        history.push('/logout')
        return
      }

      setIsRefreshingTokens(false)
    }
  }, [refreshAndExchangeTokens, history, v3AccessToken, oktaRefreshToken, isRefreshingTokens])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleTokenRefresh)
    document.addEventListener('click', handleTokenRefresh)

    return () => {
      document.removeEventListener('visibilitychange', handleTokenRefresh)
      document.removeEventListener('click', handleTokenRefresh)
    }
  }, [handleTokenRefresh])
}

export { useTokenRefresh }
