import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Button, ButtonGroup } from '@extend/zen'
import { usePrevious } from '@helloextend/client-hooks'
import { useDeleteClaimPhotoMutation } from '@helloextend/extend-api-rtk-query'
import { BasicModal } from '@helloextend/merchants-ui'

interface ConfirmPhotoDeleteModalProps {
  isVisible: boolean
  onClose: () => void
  claimId?: string
  photoId?: string
  contractId?: string
}

const ConfirmPhotoDeleteModal: FC<ConfirmPhotoDeleteModalProps> = ({
  isVisible,
  onClose,
  claimId,
  photoId,
  contractId,
}) => {
  const [deleteClaimPhoto, { isSuccess, isLoading }] = useDeleteClaimPhotoMutation()
  const prevIsSuccess = usePrevious(isSuccess)

  useEffect(() => {
    if (isSuccess && !prevIsSuccess) {
      onClose()
    }
  }, [isSuccess, onClose, prevIsSuccess])

  const handleSubmit = (): void => {
    if (!claimId || !photoId || !contractId) return
    deleteClaimPhoto({ claimId, photoId, contractId })
  }

  return (
    <BasicModal
      isVisible={isVisible}
      onClickClose={onClose}
      width="778px"
      data-cy="confirm-photo-delete-modal"
    >
      <Title>Delete this claim photo?</Title>
      <ContentWrapper>
        <Text>Are you sure you want to delete the photo attached to this claim?</Text>
      </ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => onClose()}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Confirm"
            data-cy="confirm-delete-button"
            isDisabled={isLoading}
            onClick={handleSubmit}
            isProcessing={isLoading}
            color="red"
          />
        </ButtonGroup>
      </ButtonWrapper>
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
  marginBottom: 16,
  marginTop: 0,
})

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  maxHeight: 650,
  overflow: 'scroll',
  scrollbarWidth: 'none',
})

const Text = styled.p({
  marginBottom: 0,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  paddingTop: 20,
  marginLeft: -32,
  padding: '20px 32px 0 32px',
  width: '100%',
  justifyContent: 'flex-end',
})

export type { ConfirmPhotoDeleteModalProps }
export { ConfirmPhotoDeleteModal }
