import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '@helloextend/merchants-ui'
import type { BadgeColor } from '@extend/zen'
import { Information, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { Product } from '@helloextend/extend-api-client'
import { useAtom } from 'jotai/react'
import { ProductOfferStatus } from '../../product/product-offer-status'
import {
  WARRANTY_STATUS_MAP_DESCRIPTION,
  WARRANTY_STATUS_MAP_DISPLAY,
} from '../../../constants/product-warranty-status'
import { HeaderLabel } from '../../../components/header-label'
import { StandardHeader } from '../../../components/standard-header'
import { getProductsUrl, getProductUrl, getProductVariantsUrl } from '../../../utils/route-helper'
import { productsUrlAtom, variantsUrlAtom } from '../../../atoms/product-breadcrumbs'
import { ProductImageCell } from '../../product/product-image-cell'
import { useGetCountsQuery } from '../../../queries/products'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import styles from './product-info-rdb.module.css'

export const statusDescriptions: BadgeDescriptionItem[] = [
  {
    color: 'green',
    text: WARRANTY_STATUS_MAP_DISPLAY.warrantable,
    description: WARRANTY_STATUS_MAP_DESCRIPTION.warrantable,
  },
  {
    color: 'yellow',
    text: WARRANTY_STATUS_MAP_DISPLAY.pending,
    description: WARRANTY_STATUS_MAP_DESCRIPTION.pending,
  },
  {
    color: 'neutral',
    text: WARRANTY_STATUS_MAP_DISPLAY['non-warrantable'],
    description: WARRANTY_STATUS_MAP_DESCRIPTION['non-warrantable'],
  },
]

type ProductInfoRdbProps = {
  product: Product
  baseProduct?: Product
}

export const ProductInfoRdb: FC<ProductInfoRdbProps> = ({ product, baseProduct }) => {
  const { toast } = useToaster()
  const { storeId, referenceId, parentReferenceId } = product

  const badgeColor = useMemo((): BadgeColor => {
    switch (product.warrantyStatus) {
      case 'warrantable':
        return 'green'
      case 'pending':
      case 'review_required':
        return 'yellow'
      default:
        return 'neutral'
    }
  }, [product.warrantyStatus])

  const {
    data: variantCounts,
    isError,
    isInitialLoading: isLoadingVariantCounts,
  } = useGetCountsQuery({ storeId, referenceId })

  const {
    data: parentVariantCounts,
    isError: parentIsError,
    isInitialLoading: isLoadingParentVariantCounts,
  } = useGetCountsQuery({ storeId, referenceId: parentReferenceId || '' })

  useEffect(() => {
    if (isError) {
      toast({
        message: 'There was a problem retrieving variant counts',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isError, toast])

  useEffect(() => {
    if (parentIsError) {
      toast({
        message: 'There was a problem retrieving base variant counts',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [parentIsError, toast])

  const isBase = !!variantCounts && variantCounts.childCount > 0
  const isVariant = !!parentVariantCounts && parentVariantCounts.childCount > 0
  const isSoloVariant = !!parentVariantCounts && parentVariantCounts.childCount < 2
  const othersQuantity = !!parentVariantCounts && parentVariantCounts.childCount - 1

  const othersText = othersQuantity > 1 ? 'others' : 'other'
  const variantsText = !!variantCounts && variantCounts?.childCount > 1 ? 'variants' : 'variant'

  const [productsUrl] = useAtom(productsUrlAtom)
  const [variantsUrl] = useAtom(variantsUrlAtom)

  const crumbs = useMemo(
    () => [
      {
        text: 'Products',
        to: productsUrl || getProductsUrl(),
      },
      ...(!isBase && baseProduct
        ? [
            {
              text: baseProduct.title,
              to: getProductUrl(baseProduct.referenceId),
            },
            {
              text: 'Variants',
              to: variantsUrl || getProductVariantsUrl(baseProduct.referenceId),
            },
          ]
        : []),
      {
        text: product.title,
      },
    ],
    [product, baseProduct, productsUrl, variantsUrl, isBase],
  )

  const isLoading = isLoadingVariantCounts || isLoadingParentVariantCounts

  return (
    <StandardHeader
      data-cy="product-info"
      pageTitle={product.title}
      pageTitleBadge={
        !isLoading && (!isBase || isVariant)
          ? {
              badgeText: WARRANTY_STATUS_MAP_DISPLAY[product.warrantyStatus],
              badgeColor,
              badgeDescriptions: statusDescriptions,
            }
          : undefined
      }
      pageTitleAside={<ProductImageCell title={product.title} imageUrl={product.imageUrl} />}
      crumbs={crumbs}
    >
      <div className={styles.info}>
        <HeaderLabel labelText="Reference ID:" />
        <span className={styles.value} data-cy="product-info-referenceId">
          {product.referenceId}
        </span>{' '}
        <HeaderLabel labelText="SKU:" />
        <span className={styles.value} data-cy="product-info-sku">
          {product.identifiers?.sku ?? 'N/A'}
        </span>
      </div>

      {isLoading ? (
        <Loader width="150px" height="30px" margin="10px 0" />
      ) : (
        <>
          {isBase && !isVariant && (
            <div className={styles.info}>
              <HeaderLabel labelText="Base product" />
              <Information>
                A base product is the parent of one or more “variant” products. Extend Warranty
                Plans are mapped to the variants of the base product. Find a list of the variants
                for this product by clicking the Variants link below.
              </Information>
            </div>
          )}
          {(!isBase || isVariant) && (
            <div className={styles.info}>
              <HeaderLabel labelText="Display Offer:" />
              <ProductOfferStatus
                title={product.title || ''}
                enabled={product.enabled ?? false}
                referenceId={product.referenceId}
                isDisabled={!product.enabled && product.warrantyStatus !== 'warrantable'}
              />
            </div>
          )}
          {(isBase || (isVariant && !isSoloVariant)) && (
            <div className={styles.info}>
              <HeaderLabel labelText="Variants:" />
              <span className={styles.value} data-cy="product-info-variants">
                <Link
                  className={styles.link}
                  to={getProductVariantsUrl(product.parentReferenceId ?? product.referenceId)}
                >
                  {isBase
                    ? `${variantCounts?.childCount} ${variantsText}`
                    : `${othersQuantity} ${othersText}`}
                </Link>
              </span>
            </div>
          )}
        </>
      )}
    </StandardHeader>
  )
}
