// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NIsc6NFpuujkoGjCQvGIX {\n    text-decoration: none;\n    color: var(--neutral-800);\n    font-size: 16px;\n    line-height: 24px;\n    padding: 16px 0;\n    z-index: 1;\n    max-width: none;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n._1NIsc6NFpuujkoGjCQvGIX._1FTFBbEBNxHBcYcoeuTtVJ {\n    color: var(--neutral-1000);\n    border-bottom: 1px solid var(--blue-800);\n}\n\n._1NIsc6NFpuujkoGjCQvGIX:visited {\n    color: var(--neutral-800)\n}\n\n._1NIsc6NFpuujkoGjCQvGIX.p99bMSd6iX3Poo-TLBzKE {\n    max-width: 20ch;\n}", ""]);
// Exports
exports.locals = {
	"link": "_1NIsc6NFpuujkoGjCQvGIX",
	"active": "_1FTFBbEBNxHBcYcoeuTtVJ",
	"is-analytics": "p99bMSd6iX3Poo-TLBzKE"
};
module.exports = exports;
