import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@extend/zen'
import { getContractDetailUrl } from '../../../../utils/route-helper'
import styles from './footer.module.css'

interface FooterProps {
  disabled: boolean
  isLoading: boolean
  contractId: string
}

export const Footer: FC<FooterProps> = ({ disabled, isLoading, contractId }) => {
  const history = useHistory()
  const handleCancel = (): void => {
    history.push(getContractDetailUrl(contractId))
  }
  return (
    <div className={styles.wrapper}>
      <Button
        data-cy="cancel-button"
        text="Cancel"
        emphasis="medium"
        color="red"
        type="button"
        onClick={handleCancel}
      />
      <Button
        data-cy="continue-button"
        text="Continue"
        isDisabled={disabled || isLoading}
        isProcessing={isLoading}
        type="submit"
      />
    </div>
  )
}
