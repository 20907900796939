// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Csh8VFLA8PiXpTKDlPQ3K {\n    font-size: 15px;\n    line-height: 20px;\n    font-weight: 400;\n    margin: 0;\n}\n\n._1o39-j6zEt8Nj-2m5FnDY5 {\n    font-size: 20px;\n    line-height: 28px;\n    width: 100%;\n}\n\n._2zzWviPFHL-rMdCVieOwko {\n    margin-right: 16px;\n    margin-bottom: 25px;\n}\n\n._2zzWviPFHL-rMdCVieOwko ._14dAk0pvYYRmY3HMyqw1cV {\n    width: 100% !important;\n}\n\n._2zzWviPFHL-rMdCVieOwko ._14dAk0pvYYRmY3HMyqw1cV input#_3vBywAxSzar0hsdoU2Tj-a {\n    width: calc(100% - 32px);\n}\n\n._3AS0NW2-qbpi7kCVzPu3ma {\n    display: inline-block;\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 800;\n    margin: 0 0 8px 0;\n}\n\n._1uyr3Y1ZnWJEiqgkxQehTn {\n    display: flex;\n    flex-direction: row;\n    flex-basis: 100%;\n    margin-top: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"inline-alert-text": "_2Csh8VFLA8PiXpTKDlPQ3K",
	"heading": "_1o39-j6zEt8Nj-2m5FnDY5",
	"input-wrapper": "_2zzWviPFHL-rMdCVieOwko",
	"react-datepicker-wrapper": "_14dAk0pvYYRmY3HMyqw1cV",
	"date": "_3vBywAxSzar0hsdoU2Tj-a",
	"form-label": "_3AS0NW2-qbpi7kCVzPu3ma",
	"form-buttons": "_1uyr3Y1ZnWJEiqgkxQehTn"
};
module.exports = exports;
