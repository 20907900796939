import type { Expense } from '@helloextend/extend-api-client'
import { CellText, TableCell } from '@helloextend/merchants-ui'
import type { FC } from 'react'
import React from 'react'
import { expenseColumns } from '../../../utils/expenses'

type ExpenseColumnsProps = {
  row: Expense
}

const ExpenseColumns: FC<ExpenseColumnsProps> = ({ row }) => {
  return (
    <>
      {expenseColumns.map((column) => {
        const cell = row[column.accessor as keyof typeof row]
        const element = column.Cell?.(row) ?? <CellText>{`${cell ?? '--'}`}</CellText>

        return (
          <TableCell columnWidth={column.columnWidth ?? 100} key={`${column.accessor}`}>
            {element}
          </TableCell>
        )
      })}
    </>
  )
}

export type { ExpenseColumnsProps }
export { ExpenseColumns }
