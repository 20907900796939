import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { Lead } from '@helloextend/extend-api-client'
import { DataProperty, DataPropertyType } from '@extend/zen'
import { currency } from '@extend/client-helpers'
import { capitalize } from 'lodash/fp'
import { SubHeader } from '../../../components/sub-header'
import styles from './lead-details.module.css'

interface LeadDetailsProps {
  lead: Lead
}

export const LeadDetails: FC<LeadDetailsProps> = ({ lead }) => {
  const getValue = (value: string | undefined): string => {
    return `${value ?? 'N/A'}`
  }

  const leadStatus = useMemo(() => capitalize(getValue(lead.status)), [lead.status])

  return (
    <div className={styles.lead} data-cy="lead-details-cell">
      <SubHeader labelText="Lead Details" />
      <div className={styles.row}>
        <div className={`${styles.column} width-50`}>
          <DataProperty
            data-cy="product-details-title"
            label="Product Name"
            value={getValue(lead.product.title)}
          />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty data-cy="lead-status" label="Status" value={leadStatus} />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="product-transaction-id"
            label="Transaction Id"
            value={getValue(lead.product.transactionId)}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="customer-email"
            label="Customer Email"
            value={getValue(lead.customer?.email)}
          />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="product-reference-id"
            label="Product Ref Id"
            value={getValue(lead.product.referenceId)}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="product-purchase-price"
            label="Product Purchase Price"
            value={currency.format(
              lead.product.purchasePrice?.amount,
              lead.product.purchasePrice?.currencyCode,
            )}
          />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="lead-token"
            label="Lead Token"
            value={lead.leadToken}
            includeCopyAction
          />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="lead-quantity"
            label="Quantity"
            value={lead.quantity}
            type={DataPropertyType.number}
          />
        </div>
        <div className={`${styles.column} width-25`}>
          <DataProperty
            data-cy="lead-quantity-consumed"
            label="Quantity Consumed"
            value={lead.quantityConsumed}
            type={DataPropertyType.number}
          />
        </div>
      </div>
    </div>
  )
}
