import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import {
  Label,
  Modal,
  ModalController,
  TextArea,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { DatePicker } from '@helloextend/merchants-ui'
import { useAtom, useAtomValue } from 'jotai/react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { ClaimType } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { isCompleteRepairOnsiteModalVisibleAtom } from '../../../atoms/complete-repair-onsite-modal'
import { useCompleteServiceOrderRepair } from '../../../queries/service-orders'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { contractDetailsAtom } from '../../../atoms/contract-details'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import { useCategoryLOLRemaining } from '../../../hooks/use-category-limit-of-liability-remaining'
import styles from './complete-repair-onsite-modal.module.css'

export const CompleteRepairOnsiteModal: FC<{
  serviceOrder: ServiceOrder
}> = ({ serviceOrder }) => {
  const contract = useAtomValue(contractDetailsAtom)
  const claim = useAtomValue(claimDetailsAtom)
  const [isVisible, setIsVisible] = useAtom(isCompleteRepairOnsiteModalVisibleAtom)
  const [date, setDate] = useState(null as Date | null)
  const [hasDateError, setHasDateError] = useState(false)
  const [repairExplanation, setRepairExplanation] = useState('')
  const { toast } = useToaster()
  const { mutateAsync, isLoading } = useCompleteServiceOrderRepair()
  const closeModal = (): void => setIsVisible(false)
  const isCategoryClaim = claim.type === ClaimType.CATEGORY

  const claimProduct = useMemo(() => {
    return isCategoryClaim
      ? contract.categoryProductsList?.find(
          (p) => p.referenceId === claim.products?.[0]?.referenceId,
        )
      : contract.product
  }, [claim, contract, isCategoryClaim])

  const { remainingAmount = '', isLoading: isLoLLoading } = useCategoryLOLRemaining(
    contract,
    claim.products?.[0]?.lineItemId ?? '',
  )

  const handleChangeDatePicker = (newDate: Date | null): void => {
    if (newDate) {
      setHasDateError(false)
      setDate(newDate)
      return
    }
    setHasDateError(true)
  }

  const handleSubmit = async (): Promise<void> => {
    if (!date) {
      return setHasDateError(true)
    }

    try {
      await mutateAsync({
        serviceOrderId: serviceOrder.id,
        repairCompleteDate: date,
        repairExplanation,
        method: 'repair_onsite',
      })
      closeModal()
      toast({
        message: 'The repair has been successfully marked as complete',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    } catch {
      toast({
        message: 'There was an error marking the repair as complete. Please try again',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }

    return Promise.resolve()
  }

  const dataProperties = [
    {
      value: 'Repair Onsite',
      label: 'Service Type',
      key: 'Service Type',
    },
    {
      value: isCategoryClaim
        ? remainingAmount
        : currency.formatWithoutCurrencySymbol(
            contract?.limitOfLiabilityAmount,
            contract?.currency,
            true,
          ),
      label: 'Remaining LOL',
      key: 'Remaining LOL',
      isLoading: isCategoryClaim && isLoLLoading,
    },
    {
      value: claimProduct?.title,
      label: 'Item Requiring Fulfillment',
      key: 'Item Requiring Fulfillment',
    },
    {
      value: contract?.sellerName,
      label: 'Purchased From',
      key: 'Purchased From',
    },
    {
      value: claimProduct?.serialNumber,
      label: 'Serial Number',
      key: 'Serial Number',
    },
    {
      value: claim?.incident.failureType,
      label: 'Failure Type',
      key: 'Failure Type',
    },
    {
      value: claim?.incident.description,
      label: 'Incident Description',
      key: 'Incident Description',
    },
  ]

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Complete Repair"
        onDismissRequest={closeModal}
        primaryButtonProps={{
          text: 'Complete Repair',
          isDisabled: false,
          onClick: handleSubmit,
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: closeModal }}
      >
        <div className={styles.container}>
          <DataPropertyGrid values={dataProperties} className={styles.grid} />
          <div className={styles['date-container']}>
            <Label isMuted htmlFor="repair-date">
              Repair Date
            </Label>
            <DatePicker
              id="repair-date"
              maxDate={new Date()}
              onChange={(e) => handleChangeDatePicker(e)}
              selected={date}
              errorFeedback={hasDateError ? 'Required' : ''}
              placeHolder=""
            />
          </div>
          <TextArea
            id="repair-explanation"
            label="Repair Explanation (optional)"
            maxLength={3000}
            value={repairExplanation}
            onChange={(e) => setRepairExplanation(e.target.value)}
          />
        </div>
      </Modal>
    </ModalController>
  )
}
