import type { FC } from 'react'
import React from 'react'
import type { BadgeColor, ButtonSize } from '@extend/zen'
import { Badge, Information } from '@extend/zen'
import styles from './badge-descriptions.module.css'

export interface BadgeDescriptionItem {
  text: string
  color: BadgeColor
  description: string
  details?: string
}

export interface BadgeDescriptionsProps {
  descriptions: BadgeDescriptionItem[]
  buttonSize?: Exclude<ButtonSize, 'regular' | 'large'>
  'data-cy'?: string
  shouldWrapInfoIcon?: boolean
  badgeCellStyle?: string
}

export const BadgeDescriptions: FC<BadgeDescriptionsProps> = ({
  descriptions,
  buttonSize,
  'data-cy': dataCy,
  shouldWrapInfoIcon = true,
}) => {
  if (shouldWrapInfoIcon) {
    return (
      <Information buttonSize={buttonSize} data-cy={dataCy} padding="0">
        <Descriptions data-cy={dataCy} descriptions={descriptions} />
      </Information>
    )
  }

  return <Descriptions data-cy={dataCy} descriptions={descriptions} />
}

export const Descriptions: FC<Omit<BadgeDescriptionsProps, 'buttonSize'>> = ({
  descriptions,
  'data-cy': dataCy,
  badgeCellStyle,
}) => {
  return (
    <table className={styles.table}>
      <tbody>
        {descriptions.map((d) => (
          <tr key={d.text}>
            <td className={`${styles['badge-cell']} ${badgeCellStyle && badgeCellStyle}`}>
              <Badge
                text={d.text}
                color={d.color}
                data-cy={`${dataCy}:badge`}
                details={d.details}
              />
            </td>
            <td data-cy={`${dataCy}:description`}>{d.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
