import type { FC } from 'react'
import React from 'react'
import { Button } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import { shouldShowSupportPopoverAtom } from '../../atoms/support'
import images from '../../images'
import styles from './add-new-store.module.css'

export const AddNewStore: FC = () => {
  const setShouldShowSupportPopover = useSetAtom(shouldShowSupportPopoverAtom)
  const showSupportPopover = (): void => setShouldShowSupportPopover(true)

  return (
    <div className={styles.wrapper}>
      <img className={styles.icons} src={images.products} alt="product icons" width="400px" />
      <h2 className={styles.header}>Add a store to start using Extend</h2>
      <div className={styles.caption}>
        <p>
          Connect your store to sync your products to warranties, file claims, and more. Need
          support? We’re happy to help! Send us a message using the form below.
        </p>
      </div>
      <div className={styles.button}>
        <div className={styles.help}>
          <Button emphasis="medium" text="Get help" onClick={showSupportPopover} />
        </div>
      </div>
    </div>
  )
}
