import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

export const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'left',
  textAlign: 'left',
  width: '100%',
  whiteSpace: 'nowrap',
  marginRight: '7rem',
})

export const Header = styled.div({
  marginRight: '10rem',
  fontWeight: 'bold',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  fontSize: '13px',
  color: COLOR.NEUTRAL[800],
})

export const BundleTableContainer = styled.div({
  flexDirection: 'column',
  overflowX: 'scroll',
})

export const BundleTableRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'left',
})

export const Item = styled.div({
  alignItems: 'left',
  textAlign: 'left',
  paddingBottom: '1rem',
  paddingTop: '1rem',
  minWidth: '10rem',
  maxWidth: '10rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const StyledTitle = styled(Item)({
  minWidth: '9rem',
  maxWidth: '10rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const StyledItem = styled(Item)({
  marginLeft: '2rem',
  marginRight: '1rem',
})

export const StyledTransactionId = styled(Item)({
  marginRight: '1rem',
  minWidth: '18rem',
  maxWidth: '18rem',
})

export const StyledPurchasePrice = styled(Item)({
  minWidth: '8rem',
})

export const StyledCoverage = styled(Item)({
  minWidth: '12rem',
  maxWidth: '12rem',
})

export const HeaderProductCategory = styled(Header)({
  marginLeft: '-7.2rem',
})

export const HeaderPurchasePrice = styled(Header)({
  marginLeft: '3.5rem',
})

export const HeaderListPrice = styled(Header)({
  marginLeft: '-5.5rem',
})

export const HeaderLineItem = styled(Header)({
  marginLeft: '-2.3rem',
})

export const HeaderFulfillmentDate = styled(Header)({
  marginLeft: '-6.3rem',
})

export const HeaderTransactionId = styled(Header)({
  marginLeft: '-6.5rem'
})

export const HeaderCoverageDate = styled(Header)({
  marginLeft: '-7.2rem',
})

export const HeaderManufacturerWarrantyLengthLabor = styled(Header)({
  marginLeft: '-8.3rem',
})

export const HeaderManufacturerWarrantyLengthParts = styled(Header)({
  marginLeft: '-2.3rem',
})

export const HeaderTransactionDate = styled(Header)({
  marginLeft: '-7.3rem',
})

export const HeaderSerialNumber = styled(Header)({
  marginLeft: '-6.3rem',
})

export const HeaderLoLAmount = styled(Header)({
  marginLeft: '-3.3rem',
})

export const StyledLengthParts = styled(Item)({
  marginLeft: '1.5rem',
})

export const StyledLengthLabor = styled(Item)({
  marginLeft: '5.8rem',
})

export const StyledTransactionDate = styled(Item)({
  marginLeft: '6.8rem',
})
