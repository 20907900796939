import type { FC } from 'react'
import React, { useContext } from 'react'
import {
  ExtendContext,
  OfferModalSurface,
  LearnMoreModalSurface,
  CartPageSurface,
  ProductPageSurface,
  AftermarketModalSurface,
  setLeadProductIdContractStatus,
} from '@extend/extend-sdk-react'
import { COLOR, Spinner } from '@extend/zen'
import type { SelectedBreakpoint } from '../../../atoms/customize-theme'
import styles from './active-offer-surface.module.css'

type ActiveOfferSurfaceProps = {
  surface: string
  breakpoint?: SelectedBreakpoint
  isLoading?: boolean
}

export const ActiveOfferSurface: FC<ActiveOfferSurfaceProps> = ({
  surface,
  breakpoint = 'desktop',
  isLoading = false,
}) => {
  const config = useContext(ExtendContext)
  const referenceId = config?.referenceId ?? ''

  // Generate a preview leadToken
  const leadToken = setLeadProductIdContractStatus(referenceId, 'consumed') ?? ''

  if (isLoading)
    return (
      <div className={styles.spinner} data-testid="loading-container">
        <Spinner size={40} color={COLOR.NEUTRAL[300]} />
      </div>
    )

  switch (surface) {
    case 'learnMoreModal': {
      const className = `${styles['learn-more']} ${
        breakpoint === 'desktop' ? styles.desktop : ''
      }`.trim()

      return (
        <div className={className} data-testid="learn-more-container">
          <LearnMoreModalSurface containModal />
        </div>
      )
    }
    case 'offerButtons':
      return (
        <div data-testid="offer-buttons-container">
          <ProductPageSurface />
        </div>
      )
    case 'cartOffer':
      return (
        <div className={styles.cart} data-testid="cart-container">
          <CartPageSurface />
        </div>
      )
    case 'postPurchaseModal': {
      const className = `${styles.aftermarket} ${
        breakpoint === 'desktop' ? styles.desktop : ''
      }`.trim()

      return (
        <div className={className} data-testid="offer-modal-container">
          <AftermarketModalSurface leadToken={leadToken} containModal />
        </div>
      )
    }
    case 'offerTypeModal': {
      const className = `${styles.offer} ${breakpoint === 'desktop' ? styles.desktop : ''}`.trim()

      return (
        <div className={className} data-testid="offer-modal-container">
          <OfferModalSurface containModal />
        </div>
      )
    }
    default:
      return null
  }
}
