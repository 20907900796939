export function safeBtoa(s: string): string {
  try {
    return btoa(s)
  } catch (e) {
    console.error(e)
    return ''
  }
}

export function safeAtob(s: string): string {
  try {
    return atob(s)
  } catch (e) {
    console.error(e)
    return ''
  }
}
