import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Add,
  AdvancedSelect,
  Button,
  ButtonGroup,
  DataProperty,
  Label,
  LinkButton,
  Modal,
  ModalController,
  Trash,
} from '@extend/zen'
import { useRoleOptions } from '../../../hooks/use-role-options'
import { FullLoader } from '../../../components/full-loader'
import { useV3UserInfo } from '../../user/use-v3-user-info'
import { useV3UserEditForm } from '../../user/use-v3-user-edit-form'
import styles from './v3-user-edit-form-r3.module.css'

export const V3UserEditFormR3: FC = () => {
  const { uuid } = useParams<{ uuid: string }>()

  const { push } = useHistory()

  const handleCancel = (): void => {
    push(`/account/users/${uuid}`)
  }

  const {
    organizations,
    roleOrgMappings,
    hasChanges,
    isRoleDeleteModalOpen,
    updateRoles,
    isUpdatingRoles,
    addRow,
    removeRow,
    changeRowRole,
    changeRowOrganizations,
    isLoading,
    errors,
    closeDeleteModal,
  } = useV3UserEditForm({ uuid, onCancel: handleCancel })

  const numRows = roleOrgMappings.length

  const { data } = useV3UserInfo(uuid)

  const options = useRoleOptions()

  const canAddRow = numRows < options.length

  const mappedOrgs = useMemo(() => {
    return organizations.map((organization) => ({
      display: organization.name,
      value: organization.id,
    }))
  }, [organizations])

  if (isLoading) {
    return <FullLoader />
  }

  if (!data) {
    return null
  }

  return (
    <>
      <section className={styles['title-row']}>
        <h1 className={styles.title}>User Details</h1>
      </section>
      <div className={styles.container}>
        <section className={styles['user-name-section']}>
          <div
            className={styles.property}
            style={{
              flex: 3,
              minWidth: 188,
            }}
          >
            <DataProperty data-cy="firstName" label="First Name" value={data.firstName} />
          </div>
          <div
            className={styles.property}
            style={{
              flex: 3,
            }}
          >
            <DataProperty data-cy="lastName" label="Last Name" value={data.lastName} />
          </div>
          <div
            className={styles.property}
            style={{
              flex: 6,
            }}
          >
            <DataProperty label="Email" value={data.email} />
          </div>
        </section>
        <h4 className={styles.subtitle}>Role Assignments</h4>
        <section className={styles['roles-section']}>
          <div
            style={{
              flex: 3,
            }}
          >
            <Label
              helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
              htmlFor="roles"
            >
              Role
            </Label>
            {roleOrgMappings.map((roleOrg, index) => {
              return (
                <div className={styles['role-selector']} key={`Row-${roleOrg.role}`}>
                  <AdvancedSelect
                    id="roles"
                    placeholder="Role"
                    options={options}
                    value={roleOrg.role}
                    onChange={(e) => changeRowRole(e.target.value, index)}
                    multiple={false}
                  />
                </div>
              )
            })}
          </div>
          <div
            style={{
              flex: 9,
            }}
          >
            <Label
              helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
              htmlFor="roles"
            >
              Organization
            </Label>
            {roleOrgMappings.map((roleOrg, index) => {
              return (
                <div className={styles['org-selector']}>
                  <AdvancedSelect
                    id="organizations"
                    placeholder="Organizations"
                    options={mappedOrgs}
                    value={roleOrg.orgIds}
                    errorFeedback={errors}
                    isError={Boolean(errors)}
                    onChange={(e) => {
                      const newOrgs = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value]
                      changeRowOrganizations(newOrgs, index)
                    }}
                    multiple
                  />
                  {numRows !== 1 ? (
                    <Button
                      emphasis="low"
                      size="small"
                      color="neutral"
                      icon={Trash}
                      onClick={() => removeRow(index)}
                    />
                  ) : null}
                </div>
              )
            })}
          </div>
        </section>
        {canAddRow ? (
          <section className={styles['add-button-row']}>
            <Button
              text="Add a new assignment"
              icon={Add}
              onClick={addRow}
              emphasis="low"
              size="small"
              fillContainer
            />
          </section>
        ) : null}
        <ButtonGroup isReversed>
          <Button
            text="Save Changes"
            onClick={updateRoles}
            isProcessing={isUpdatingRoles}
            isDisabled={!hasChanges}
          />
          <LinkButton
            text="Cancel"
            emphasis="medium"
            to={`/account/users/${uuid}`}
            isDisabled={isUpdatingRoles}
          />
        </ButtonGroup>
        <ModalController isOpen={isRoleDeleteModalOpen}>
          <Modal
            heading="Are you sure you want to delete one or more role assignments?"
            primaryButtonProps={{
              text: 'Delete Assignments',
              color: 'red',
              isDisabled: !hasChanges || isUpdatingRoles,
              isProcessing: isUpdatingRoles,
              onClick: updateRoles,
            }}
            secondaryButtonProps={{
              text: 'Cancel',
              onClick: closeDeleteModal,
              isDisabled: isUpdatingRoles,
            }}
            onDismissRequest={closeDeleteModal}
          >
            <p>The user will lose the associated access and capabilities.</p>
          </Modal>
        </ModalController>
      </div>
    </>
  )
}
