// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1W_TLN4fmgesSYgspne23z {\n  display: flex;\n  flex-direction: column;\n}\n\n._3CRHX8hLXN4FLy--_Vmth4 {\n  padding: 16px 0;\n}\n\n.DumfnXm321p-ca01P_u1_ {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-column-gap: 20px;\n  grid-row-gap: 16px;\n}\n\n.wgmWzqjKGe7Aocn16lInF {\n  grid-column: span 3 / span 3;\n}\n\n._2RQ7uZPfMZop5GCX27FXKN {\n  grid-column: span 2 / span 2;\n}\n\n._1oGRYe9Y6FTZVrhTH6eluq {\n  margin-bottom: 16px;\n}\n\n.UJ7R8shPFiGTDDYDumL7h {\n  margin: 16px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_1W_TLN4fmgesSYgspne23z",
	"checkbox-wrapper": "_3CRHX8hLXN4FLy--_Vmth4",
	"grid": "DumfnXm321p-ca01P_u1_",
	"grid-full-row": "wgmWzqjKGe7Aocn16lInF",
	"grid-two-columns": "_2RQ7uZPfMZop5GCX27FXKN",
	"warning-message-wrapper": "_1oGRYe9Y6FTZVrhTH6eluq",
	"button-group-wrapper": "UJ7R8shPFiGTDDYDumL7h"
};
module.exports = exports;
