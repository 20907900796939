// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".esttatVyfpMaPL-ZD-HV1:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n.esttatVyfpMaPL-ZD-HV1 a {\n    color: inherit;\n}\n\n.esttatVyfpMaPL-ZD-HV1 a:visited {\n    color: inherit;\n}\n\n._20mww8zwwXLP4LhflGjS05 {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n._2aFOPWMBlIUpP8d6XpFO-9 {\n    margin: 0;\n}\n\n._3W55vzWtaTUQTqqvb19IhU {\n    margin-top: 1rem;\n}\n\n._3W55vzWtaTUQTqqvb19IhU:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n.UGyy1y4jc9B3_6FcfmPhm {\n    font-weight: bold;\n}\n\n.W9a_37UYQvxGTYFrLY72n  {\n    margin: 0;\n}\n\n.W9a_37UYQvxGTYFrLY72n svg {\n    margin: 0.125rem;\n    vertical-align: bottom;\n}\n", ""]);
// Exports
exports.locals = {
	"inline-alert-wrapper": "esttatVyfpMaPL-ZD-HV1",
	"container": "_20mww8zwwXLP4LhflGjS05",
	"text": "_2aFOPWMBlIUpP8d6XpFO-9",
	"contract-id-container": "_3W55vzWtaTUQTqqvb19IhU",
	"contract-id-header": "UGyy1y4jc9B3_6FcfmPhm",
	"contract-id-wrapper": "W9a_37UYQvxGTYFrLY72n"
};
module.exports = exports;
