import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'
import { EXTEND_ENV } from '@helloextend/client-constants'
import type { AccountWithGrants, Grant } from '../types/okta'

export const V3_ACCESS_TOKEN_KEY = `${EXTEND_ENV}_MERCHANTS_V3_ACCESS_TOKEN`
export const storageV3AccessTokenAtom = atomWithStorage<string>(V3_ACCESS_TOKEN_KEY, '')

export const v3AccessTokenAtom = atom(
  (get) => {
    const token =
      get(storageV3AccessTokenAtom) || JSON.parse(localStorage.getItem(V3_ACCESS_TOKEN_KEY) || '""')
    return token || ''
  },
  (_get, set, token: string) => {
    localStorage.setItem(V3_ACCESS_TOKEN_KEY, JSON.stringify(token))
    set(storageV3AccessTokenAtom, token)
  },
)

/**
 * Use this to determine if jotai has tried to load the accessToken from local storage
 */
export const hasLoadedV3TokenAtom = atom((get) => {
  const atomValue = get(storageV3AccessTokenAtom)
  const storageValue = localStorage.getItem(V3_ACCESS_TOKEN_KEY)
  return atomValue !== undefined || storageValue === null
})

export const oktaAccessTokenAtom = atom('')
export const accountWithGrantsAtom = atom<AccountWithGrants[]>([])

export const CACHED_ROLES_KEY = `${EXTEND_ENV}_MERCHANTS_CACHED_ROLES`
export const storageCachedRolesAtom = atomWithStorage<Record<string, Grant>>(CACHED_ROLES_KEY, {})

export const cachedRolesAtom = atom(
  (get) => {
    const roles = get(storageCachedRolesAtom)
    return Object.keys(roles).length
      ? roles
      : JSON.parse(localStorage.getItem(CACHED_ROLES_KEY) || '{}')
  },
  (_get, set, roles: Record<string, Grant>) => {
    localStorage.setItem(CACHED_ROLES_KEY, JSON.stringify(roles))
    set(storageCachedRolesAtom, roles)
  },
)

export const CACHED_ACCOUNT_NAME_KEY = `${EXTEND_ENV}_MERCHANTS_CACHED_ACCOUNT_NAME`
export const cachedAccountNameAtom = atomWithStorage<string>(CACHED_ACCOUNT_NAME_KEY, '')

export const isRefreshingTokensAtom = atom(false)
