import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useToggle } from '@helloextend/client-hooks'
import { COLOR, Icon, IconSize } from '@extend/zen'
import { Check, ChevronRight } from '@extend/zen'
import type { DateRangeFilterValues } from './types'
import { DateRange } from './types'
import { Menu } from '../menu'
import { DateRangeFilterCustomRange } from './date-range-filter-custom-range'
import { dateRangeToString } from '../../utils/date-range-filter'
import { Chevron, ItemWrapper, MenuButton, MenuWrapper } from './filter-styles'

export type DateRangeFilterProps = {
  property: string
  ranges?: DateRange[]
  values?: DateRangeFilterValues
  onFilterChange: (property: string, values: DateRangeFilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

const buildDateRangeValues = (
  range: DateRange,
  start: Date | null,
  end: Date | null,
): DateRangeFilterValues | null => {
  if (range === DateRange.custom && !start && !end) {
    return null
  }

  return {
    type: 'dateRange',
    range,
    start,
    end,
  }
}

const DateRangeFilter: FC<DateRangeFilterProps> = ({
  property,
  ranges = [],
  values = {
    type: 'dateRange',
    range: DateRange.custom,
    start: null,
    end: null,
  },
  onFilterChange,
  onMenuLock,
}) => {
  const [isCustomOpen, { toggle, off }] = useToggle(false)

  const handleRangeClick = useCallback(
    (range: DateRange): void => {
      off()
      onFilterChange(property, buildDateRangeValues(range, null, null))
    },
    [onFilterChange, off, property],
  )

  const handleCustomClick = useCallback((): void => {
    toggle()
  }, [toggle])

  const handleCustomDateRangeChange = useCallback(
    (start: Date | null, end: Date | null): void => {
      onFilterChange(property, buildDateRangeValues(DateRange.custom, start, end))
    },
    [onFilterChange, property],
  )

  if (!ranges.length) {
    return (
      <DateRangeFilterCustomRange
        start={values.start}
        end={values.end}
        onDateRangeChange={handleCustomDateRangeChange}
        onMenuLock={onMenuLock}
      />
    )
  }

  return (
    <>
      {ranges.map((range) => (
        <ItemWrapper key={range}>
          <MenuButton
            data-cy="filterSelectRange"
            isActive={false}
            hoverBackground={COLOR.NEUTRAL[100]}
            onClick={() => handleRangeClick(range)}
          >
            {dateRangeToString(range)}
            {values.range === range && (
              <Icon icon={Check} color={COLOR.BLUE[800]} size={IconSize.xsmall} />
            )}
          </MenuButton>
        </ItemWrapper>
      ))}
      <ItemWrapper>
        <MenuButton
          data-cy="filterSelectCustomRange"
          isActive={isCustomOpen}
          hoverBackground={COLOR.NEUTRAL[100]}
          onClick={() => handleCustomClick()}
        >
          Custom
          <Chevron>
            <Icon icon={ChevronRight} color={isCustomOpen ? COLOR.WHITE : COLOR.NEUTRAL[800]} />
          </Chevron>
        </MenuButton>
        <MenuWrapper>
          <Menu isOpen={isCustomOpen} position="right" width={216}>
            <DateRangeFilterCustomRange
              start={values.start}
              end={values.end}
              onDateRangeChange={handleCustomDateRangeChange}
              onMenuLock={onMenuLock}
            />
          </Menu>
        </MenuWrapper>
      </ItemWrapper>
    </>
  )
}

export { DateRangeFilter }
