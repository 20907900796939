import React from 'react'
import type { Cell, Column } from 'react-table'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText, DateRange } from '@helloextend/merchants-ui'
import { currency } from '@extend/client-helpers'
import { date } from '@extend/client-helpers'
import { BadgeDescriptions } from '../../../components/badge-descriptions'
import type { TableLead } from '../../../types/merchant-lead'
import { getLeadStatusCopy, statusDescriptions } from '../../../utils/get-lead-status-copy'
import { CellStatusBadge } from '../../../components/cell-status-badge'

const columns: Array<Column<TableLead>> = [
  {
    Header: 'Transaction ID',
    headerTooltip: 'Transaction ID in your system, often same as order ID',
    accessor: 'productTransactionId',
    disableSortBy: true,
    width: 20,
    Cell: ({ value }: Cell) => <CellText data-cy="lead-transaction-id">{value}</CellText>,
  },
  {
    Header: 'Trans Date',
    headerTooltip: 'Date order was created',
    accessor: 'productTransactionDate',
    disableSortBy: true,
    width: 10,
    filter: 'dateRange',
    Cell: ({ value }: Cell) => (
      <CellText data-cy="lead-transcation-date">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Customer Email',
    headerTooltip: 'Customer’s email address',
    accessor: 'customerEmail',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: Cell) => <CellText data-cy="lead-customer-email">{value}</CellText>,
  },
  {
    Header: 'Product Ref ID',
    headerTooltip: 'ID of the product in your system, often same as SKU #',
    accessor: 'productReferenceId',
    disableSortBy: true,
    width: 15,
    Cell: ({ value }: Cell) => <CellText data-cy="lead-product-reference-id">{value}</CellText>,
  },
  {
    Header: 'Product Name',
    headerTooltip: 'Name of the product as displayed in your store',
    accessor: 'productName',
    disableSortBy: true,
    width: 15,
    Cell: ({ value }: Cell) => <CellText data-cy="lead-product-name">{value}</CellText>,
  },
  {
    Header: 'Prod Purchase Price',
    headerTooltip: 'Price customer paid for product after discounts applied',
    accessor: 'productPurchasePrice',
    disableSortBy: true,
    width: 15,
    textAlign: 'right',
    Cell: ({ value }: Cell) => (
      <CellText align="right" data-cy="lead-product-purchase-price">
        {currency.format(value.amount, value.currencyCode)}
      </CellText>
    ),
  },
  {
    Header: 'Status',
    headerTooltip: 'Current status of the lead',
    HeaderAdornment: (): JSX.Element => <BadgeDescriptions descriptions={statusDescriptions} />,
    accessor: 'status',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => {
      const badgeData = getLeadStatusCopy(value)
      return <CellStatusBadge badgeData={badgeData} />
    },
  },
]

const searchOptions = [
  { label: 'Customer Email', value: 'customerEmail' },
  { label: 'Transaction ID', value: 'productTransactionId' },
  { label: 'Reference ID', value: 'productRefId' },
]

const filterOptions: Record<string, FilterOptions> = {
  productTransactionDate: {
    label: 'Lead Transaction Date',
    type: 'dateRange',
    ranges: [DateRange.today, DateRange.last7days, DateRange.last30days],
  },
}

export { columns, searchOptions, filterOptions }
