// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uWxt1ak_PUvuMOid1gbBL {\n    display: flex !important;\n    justify-content: space-between;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "uWxt1ak_PUvuMOid1gbBL"
};
module.exports = exports;
