// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3xBPZnGlButaGqCeJWKEKa {\n    font-size: 16px;\n    padding: 8px 0 16px;\n}\n\n._3BO9yvqaG4KH8f_SpDkd68 {\n    align-self: center;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 28px;\n}\n\n.VHOrAhrq_MeGpyOG-W-Pm {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 154px;\n}\n\n._1PsLnP8t-McKlULEd5sWTf {\n    font-size: 22px;\n    font-weight: 800;\n    line-height: 30px;\n    margin-top: 32px;\n}\n\n._29bnNz-SXvg8LvP8HXQOrF {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 22px;\n    margin-top: 16px;\n}\n\n._52gzFLIsWBpkMzMmsgyta {\n    margin-top: 224px;\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "_3xBPZnGlButaGqCeJWKEKa",
	"header": "_3BO9yvqaG4KH8f_SpDkd68",
	"thanks": "VHOrAhrq_MeGpyOG-W-Pm",
	"thanks-header": "_1PsLnP8t-McKlULEd5sWTf",
	"thanks-text": "_29bnNz-SXvg8LvP8HXQOrF",
	"thanks-button": "_52gzFLIsWBpkMzMmsgyta"
};
module.exports = exports;
