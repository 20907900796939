import type { FC } from 'react'
import React from 'react'
import { ContractDataTable } from './contract-data-table'
import { FileClaimHeader } from './file-claim-header'

const FileClaim: FC = () => {
  return (
    <>
      <FileClaimHeader />
      <ContractDataTable />
    </>
  )
}

export { FileClaim }
