import { useCallback, useState } from 'react'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { Refund } from '../queries/refunds'
import { useGetRefundsByContractId } from '../queries/refunds'

type RefundModalState = {
  data: Refund | undefined
  isFetching: boolean
  isOpen: boolean
  closeModal: () => void
  openModal: () => void
}
export function useRefundModal({ contractId }: { contractId: string }): RefundModalState {
  const [shouldFetchRefunds, setShouldFetchRefunds] = useState(false)
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)

  const { toast } = useToaster()

  const { data, isFetching } = useGetRefundsByContractId({
    contractId,
    enabled: Boolean(contractId && shouldFetchRefunds),
    onSuccess: () => setIsRefundModalOpen(true),
    onError: () => {
      toast({
        message: 'There was a problem fetching refund info, please try again later',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    },
    onSettled: () => setShouldFetchRefunds(false),
  })

  const closeModal = useCallback((): void => {
    setIsRefundModalOpen(false)
  }, [setIsRefundModalOpen])

  const openModal = useCallback(() => setShouldFetchRefunds(true), [setShouldFetchRefunds])

  return {
    data,
    isFetching,
    isOpen: isRefundModalOpen,
    closeModal,
    openModal,
  }
}
