import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const BASE_URL = `https://${EXTEND_API_HOST}/support`
const SUPPORT_CACHE_KEY = 'support'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

export function useSlackSupportNotificationMutation(): UseBaseMutationResult<
  void,
  Error,
  SlackSupportNotificationMutation,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  return useMutation({
    mutationFn: async ({ body }: SlackSupportNotificationMutation) => {
      await fetch(`${BASE_URL}/slack/merchants-support`, {
        headers: { ...COMMON_HEADERS, 'x-extend-access-token': accessToken },
        body: JSON.stringify(body),
        method: 'POST',
      })
    },
  })
}

export function useCreateSupportMutation(): UseBaseMutationResult<
  void,
  Error,
  MerchantSupportRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  return useMutation({
    mutationFn: async ({ body }: MerchantSupportRequest) => {
      await fetch(BASE_URL, {
        headers: { ...COMMON_HEADERS, 'x-extend-access-token': accessToken },
        body: JSON.stringify(body),
        method: 'POST',
      })
    },
  })
}

export function useGetNetsuiteACHQuery(
  storeId: string,
): QueryObserverResult<NetsuiteACHInformation, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/netsuite/${storeId}`, {
      method: 'GET',
      headers: { ...COMMON_HEADERS, 'x-extend-access-token': accessToken },
    })

    if (!response.ok) {
      throw new Error('Error getting ACH Inormation')
    }

    return response.json()
  }, [accessToken, storeId])

  return useQuery({
    queryKey: [SUPPORT_CACHE_KEY, storeId],
    queryFn,
  })
}

export function useUpdateNetsuiteACHMutation(): UseBaseMutationResult<
  void,
  Error,
  Partial<NetsuiteACHInformation>,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationKey: ['support'],
    mutationFn: async (body: Partial<NetsuiteACHInformation>) => {
      const respose = await fetch(`${BASE_URL}/netsuite/${body.storeId}`, {
        method: 'POST',
        headers: { ...COMMON_HEADERS, 'x-extend-access-token': accessToken },
        body: JSON.stringify(body),
      })

      if (!respose.ok) {
        throw new Error('Error updating ACH information')
      }
    },
  })
}

export interface SlackSupportNotificationMutation {
  body: {
    subject: string
    message: string
  }
}

export interface MerchantSupportRequest {
  body: {
    name: string
    phoneNumber?: string
    email: string
    message: string
    storeDomainName: string
    requestType: string
    marchantPlatform?: string
    merchantPlatformOther?: string
    createdAt: number
    storeId?: string
    merchantName?: string
  }
}

export interface NetsuiteACHInformation {
  bankName: string
  accountNumber: string
  routingNumber: string
  accountType: string
  legalBusinessName: string
  accountsPayableEmail: string
  storeId: string
  authorizedSignatoryName: string
  date: string
  title: string
}
