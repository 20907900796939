import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Button, Box, Edit } from '@extend/zen'
import { OKTA_RESET_PASSWORD_URL } from '@helloextend/client-constants'
import { useAtom } from 'jotai'
import { DashboardLayout } from '../components/dashboard-layout'
import { DashboardSection } from '../constants/dashboard-sections'
import { RolesDisplay } from '../features/profile/roles-display'
import { useUser } from '../hooks/use-user'
import { StandardHeader } from '../components/standard-header'
import { UserProfileEditForm } from '../components/user-profile-edit-form'
import { UserProfileLogoutReminder } from '../components/user-profile-logout-reminder'
import { isUserNameUpdatedAtom, isUserProfileEditFormOpenAtom } from '../atoms/user'
import styles from './user-profile.module.css'

export const UserProfile: FC = () => {
  const { firstName, lastName, email } = useUser()
  const [isUserNameUpdated, setIsUserNameUpdated] = useAtom(isUserNameUpdatedAtom)

  const [isUserProfileEditFormOpen, setUserProfileEditFormOpen] = useAtom(
    isUserProfileEditFormOpenAtom,
  )

  const toggleEdit = (): void => {
    setUserProfileEditFormOpen(!isUserProfileEditFormOpen)
  }

  const handleCancel = (): void => {
    setUserProfileEditFormOpen(false)
  }

  useEffect(() => {
    return () => {
      setIsUserNameUpdated(false)
    }
  }, [setIsUserNameUpdated])

  return (
    <DashboardLayout section={DashboardSection.None}>
      <StandardHeader
        marginBottom={40}
        pageTitle={`${firstName} ${lastName}'s Profile`}
        buttons={[
          {
            text: 'Reset password',
            emphasis: 'medium',
            to: OKTA_RESET_PASSWORD_URL,
          },
        ]}
      />
      {isUserNameUpdated && <UserProfileLogoutReminder />}
      <Box>
        <div className={styles.container}>
          <h2 className={styles.header}>User Details</h2>
          <Button icon={Edit} emphasis="low" text="Edit" onClick={toggleEdit} />
        </div>

        {isUserProfileEditFormOpen ? (
          <UserProfileEditForm onCancel={handleCancel} />
        ) : (
          <>
            <div className={styles.row}>
              <span>
                <div className={styles.label}>First Name</div>
                <p className={styles.detail}>{firstName}</p>
              </span>
              <span>
                <div className={styles.label}>Last Name</div>
                <p className={styles.detail}>{lastName}</p>
              </span>
              <span>
                <div className={styles.label}>Email</div>
                <p className={styles.detail}>{email}</p>
              </span>
            </div>
            <RolesDisplay />
          </>
        )}
      </Box>
    </DashboardLayout>
  )
}
