import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, DataProperty, Loading, Stack } from '@extend/zen'
import type { MerchantClaim } from '../../../../../../types/claims'
import { getSessionLogsList } from '../../../../../../queries/incredibot'

interface IncidentReportProps {
  claim: MerchantClaim
}

export const IncidentReport: FC<IncidentReportProps> = ({ claim }) => {
  const { data: sessionLogs, isLoading } = getSessionLogsList(claim.sessionId ?? '')

  if (isLoading) return <Loading />

  return (
    <Container>
      <Header>
        <Title>Incident Report</Title>
      </Header>
      <Stack spacing={3}>
        {sessionLogs?.map((log) => (
          <DataProperty
            data-cy={log.id}
            key={log.id}
            label={log.question}
            value={log.answer.toString()}
          />
        ))}
      </Stack>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: 16,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})
