import { createApi } from '@reduxjs/toolkit/query/react'
import type { Order } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type { OrdersSearchOptions, OrdersSearchResponse } from './types'
import { ordersAPIVersion } from './types'

// Default limit of 2000 should ensure that we're always hitting the single page limit (1000)
// in Dynamo, which there's not really any reason not to do.
const DEFAULT_SEARCH_LIMIT = 2000

export const ordersApi = createApi({
  baseQuery,
  reducerPath: 'Orders',
  tagTypes: ['Orders'],
  endpoints: (build) => ({
    getOrder: build.query<Order, string>({
      query: (orderId: string) => ({
        url: `orders/${orderId}`,
        headers: {
          accept: `application/json; version=${ordersAPIVersion};`,
        },
      }),
      providesTags: (_, _err, orderId) => [{ type: 'Orders', id: orderId }],
    }),
    searchOrders: build.query<OrdersSearchResponse, OrdersSearchOptions>({
      query: ({ searchKey, searchValue, limit = DEFAULT_SEARCH_LIMIT, cursor }) => {
        const qsParams = new URLSearchParams({
          [searchKey]: searchValue,
          limit: limit.toString(),
          ...(cursor && { cursor }),
        })
        return {
          url: `orders/search?${qsParams.toString()}`,
          headers: {
            accept: `application/json; version=${ordersAPIVersion};`,
          },
        }
      },
      providesTags: (_, _err, { searchKey, searchValue, cursor }) => [{ type: 'Orders', searchKey, searchValue, cursor }],
    }),
  }),
})

export const { useGetOrderQuery, useSearchOrdersQuery, useLazySearchOrdersQuery } = ordersApi
