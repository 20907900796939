// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3OG_TXw2bzX3N64wyBh5CN {\n    display: grid;\n}\n\n/* Form Input Grid Styles */\n._3gGu-AykA7glzNDM7kgNnn {\n    display: grid;\n    column-gap: 16px;\n    grid-template-columns: calc(25% - 16px) calc(25% - 16px) calc(12.5% - 16px) calc(12.5% - 16px) calc(12.5% - 16px) 12.5%;\n}\n\n._1ajhtj-U1tjxNnVNfDkdIB {\n    display: grid;\n    column-gap: 16px;\n    grid-template-columns: calc(25% - 16px) calc(25% - 16px) 50%;\n}\n\n/*===============================*/\n\n.rb6z7r9M9C74s6yzoDQ42 {\n    font-size: 20px;\n}\n\n._2zphMBi4gNTiONwTskO-Kx {\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n    gap: 0.6em;\n    padding-top: 30px;\n}\n\n._1SZzLz9Y4WKdiEMHVnqO8W {\n    align-items: center;\n    background-color: var(--red-200);\n    border-radius: 6px;\n    display: flex;\n    height: 52px;\n    gap: 8px;\n    line-height: 20px;\n    padding: 0 16px;\n}\n\n._142sm7sbRzLfiWpYY3vKJr {\n    color: var(--red-600);\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 20px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n._1gWXTv4mDlYWyqphIIiJLS {\n    display: grid;\n    grid-template-columns: 25% 25% 50%;\n}\n\n._3qrHsDJWsqH6hu82zUXOXQ {\n    display: grid;\n    grid-template-columns: 25% 25% 12.5% 12.5% 12.5% 12.5%;\n}", ""]);
// Exports
exports.locals = {
	"form-container": "_3OG_TXw2bzX3N64wyBh5CN",
	"six-input-grid": "_3gGu-AykA7glzNDM7kgNnn",
	"three-input-grid": "_1ajhtj-U1tjxNnVNfDkdIB",
	"h4-styles": "rb6z7r9M9C74s6yzoDQ42",
	"button-group": "_2zphMBi4gNTiONwTskO-Kx",
	"error-message-container": "_1SZzLz9Y4WKdiEMHVnqO8W",
	"error-message": "_142sm7sbRzLfiWpYY3vKJr",
	"three-property-grid": "_1gWXTv4mDlYWyqphIIiJLS",
	"six-property-grid": "_3qrHsDJWsqH6hu82zUXOXQ"
};
module.exports = exports;
