import type { ReactElement } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { AuthLayout } from '../components/auth-layout'
import { HeadTag } from '../components/head-tag'
import { OktaLoginButton } from '../features/login/okta-login-button'
import { useLoginRedirect } from '../hooks/use-login-redirect'

export function OktaLogin(): ReactElement {
  const { initializeAnonymous } = useUserAnalytics()
  const { search } = useLocation()

  useEffect(() => {
    initializeAnonymous()
  }, [initializeAnonymous])

  useLoginRedirect()

  const accountFollowUp = useMemo(() => {
    return Boolean(new URLSearchParams(search).get('accountFollowUp'))
  }, [search])

  return (
    <AuthLayout showFooter={!accountFollowUp}>
      <HeadTag siteTitle="Extend Merchants | Login" />
      <OktaLoginButton accountFollowUp={accountFollowUp} />
    </AuthLayout>
  )
}
