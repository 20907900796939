import { atom } from 'jotai/vanilla'

type CategoryProductModalState = {
  isVisible: boolean
  productId: string
}
export const categoryProductModalAtom = atom<CategoryProductModalState>({
  isVisible: false,
  productId: '',
})
