import { createApi } from '@reduxjs/toolkit/query/react'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery } from '../base-query'
import type {
  DonationsMerchantConfigGetResponse,
  DonationsCharitiesListResponse,
  DonationsMerchantConfigGetRequest,
  DonationsMerchantConfigCreateRequest,
  DonationsMerchantConfigUpdateResponse,
  DonationsMerchantConfigUpdateRequest,
  DonationsCharityCreateRequest,
  DonationsCharityCreateResponse,
  DonationsCharityUpdateRequest,
  DonationsCharityUpdateResponse,
  Charity,
} from './types'

export const donationsApi = createApi({
  baseQuery,
  reducerPath: 'Donations',
  tagTypes: ['Donations', 'Charities'],
  endpoints: (build) => ({
    getMerchantCharityConfig: build.query<
      DonationsMerchantConfigGetResponse,
      DonationsMerchantConfigGetRequest
    >({
      query: ({ storeId, version = 'latest' }) => ({
        url: `/donations/merchant-config/${storeId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'Donations', id: storeId }],
    }),
    createMerchantCharityConfig: build.mutation<
      DonationsMerchantConfigGetResponse,
      DonationsMerchantConfigCreateRequest
    >({
      query: ({ data, version = 'latest' }) => ({
        url: `/donations/merchant-config`,
        headers: {
          'content-type': `application/json`,
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_, _err, { data }) => [{ type: 'Donations', id: data.referenceId }],
    }),
    updateMerchantCharityConfig: build.mutation<
      DonationsMerchantConfigUpdateResponse,
      DonationsMerchantConfigUpdateRequest
    >({
      query: ({ storeId, data, version = 'latest' }) => ({
        url: `/donations/merchant-config/${storeId}`,
        headers: {
          'content-type': `application/json`,
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'Donations', id: storeId }],
    }),
    createCharity: build.mutation<DonationsCharityCreateResponse, DonationsCharityCreateRequest>({
      query: ({ data, version = 'latest' }) => ({
        url: `/donations/charities`,
        headers: {
          'content-type': `application/json`,
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Charities'],
    }),
    updateCharity: build.mutation<DonationsCharityUpdateResponse, DonationsCharityUpdateRequest>({
      query: ({ charityId, data, version = 'latest' }) => ({
        url: `/donations/charities/${charityId}`,
        headers: {
          'content-type': `application/json`,
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Charities'],
    }),
    getAllCharitiesList: build.query<Charity[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: Charity[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue = await fetchWithBQ({
            url: nextPageCursor
              ? `/donations/charities?limit=500&cursor=${nextPageCursor}`
              : '/donations/charities',
            headers: {
              accept: 'application/json; version=latest;',
            },
          })

          if (res.error) throw res.error

          const payload = res.data as DonationsCharitiesListResponse

          if (payload.items) {
            data.push(...payload.items)
            nextPageCursor = payload.nextPageCursor
          }
        } while (nextPageCursor)

        return { data }
      },
      providesTags: ['Charities'],
    }),
  }),
})

export const {
  useGetMerchantCharityConfigQuery,
  useGetAllCharitiesListQuery,
  useCreateMerchantCharityConfigMutation,
  useUpdateMerchantCharityConfigMutation,
  useCreateCharityMutation,
  useUpdateCharityMutation,
} = donationsApi
