import type { FC } from 'react'
import React from 'react'
import { Card } from '@helloextend/merchants-ui'
import { H2, Small } from '@helloextend/merchants-ui/src/components/typography'
import type { MerchantContract } from '../../../types/merchant-contract'
import styles from './customer-details-card.module.css'

interface CustomerDetailsCardProps {
  contract: MerchantContract
}

export const CustomerDetailsCard: FC<CustomerDetailsCardProps> = ({ contract }) => {
  const { customer } = contract

  return (
    <Card dataQa={`contract-search-item-${contract.id}`}>
      <div className={styles['flex-wrapper']}>
        <div className={styles.header}>
          <H2>Customer Info</H2>
          <div className={styles['header-text']}>Details for the owner of this contract</div>
        </div>
        <div className={styles.details}>
          <div className={styles['flex-wrapper']}>
            <div className={styles['details-columh']}>
              {[
                ['Name', 'name', customer.name],
                ['Phone', 'phone', customer.phone],
              ].map(([label, dataQaAttribute, value]) => {
                if (!value) return null
                return (
                  <div data-qa={`contract.customer.${dataQaAttribute}`} key={value}>
                    <Small>{label}</Small>
                    <div>{value}</div>
                  </div>
                )
              })}
            </div>
            <div className={styles['details-column']}>
              {customer?.email && (
                <div data-qa="contract.customer.email">
                  <Small>Email</Small>
                  <div>{customer.email}</div>
                </div>
              )}
              {customer.shippingAddress && (
                <div data-qa="contract.customer.shippingAddress">
                  {/*
                   * Formats the address fields in separate lines:
                   * {address1}
                   * {address2}
                   * {city} {provinceCode/state} {postalCode}
                   */}
                  <Small>Shipping Address</Small>
                  {customer.shippingAddress.address1 && (
                    <div>{customer.shippingAddress.address1}</div>
                  )}
                  {customer.shippingAddress?.address2 && (
                    <div>{customer.shippingAddress.address2}</div>
                  )}
                  <div>
                    {[
                      customer.shippingAddress.city,
                      customer.shippingAddress.provinceCode,
                      customer.shippingAddress.postalCode,
                    ]
                      .filter(Boolean)
                      .join(' ')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
