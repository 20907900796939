import type { FC, ReactElement } from 'react'
import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Icon, IconSize, COLOR, Modal, ModalController } from '@extend/zen'
import { ArrowForward } from '@extend/zen'
import type { Theme } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { usePrevious } from '@helloextend/client-hooks'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import type { ThemeGlobalProperties } from '../../../types/customize'
import { ColorPreview } from './color-preview'
import { getChangedGlobalStyles } from '../../../utils/get-changed-global-styles'
import { PublishModalSkeleton } from './publish-modal-skeleton'
import { useSelectedTheme } from '../../../hooks/use-get-selected-theme'
import styles from './publish-modal.module.css'

export type PublishModalProps = {
  isVisible: boolean
  publishedTheme: Theme | undefined,
  handleToggleModal: () => void
  handlePublish: () => void
  isProcessing: boolean
  isSuccess: boolean
  error?: FetchBaseQueryError | SerializedError | null | string
  reset: () => void
}

const PROPERTY_TITLE_MAP = {
  storeLogoUrl: 'Store Logo',
  backgroundColor: 'Background Color',
  buttonBackgroundColor: 'Button Color',
  buttonRadius: 'Button Corner Radius',
  buttonFontColor: 'Button Font Color',
  fontFamily: 'Font Family',
  color: 'Primary Font Color',
}

export const PublishModal: FC<PublishModalProps> = ({
  isVisible,
  publishedTheme,
  handleToggleModal,
  handlePublish,
  isProcessing,
  isSuccess,
  error,
  reset,
}) => {
  const { theme: selectedTheme, isLoading: isSelectedThemeLoading } = useSelectedTheme()
  const { toastCompleted, toastError } = useStandardToast()
  const prevProcessing = usePrevious<boolean>(isProcessing)
  const [requiresCssConfirmation, setRequiresCssConfirmation] = useState(publishedTheme?.cssConfig?.css ? true : false)

  const isLoadingThemeDetails = useMemo(() => {
    const hasSelectedThemeLoaded = Boolean(selectedTheme)
    const hasPublishedThemeLoaded = Boolean(publishedTheme)
    return !(hasSelectedThemeLoaded && hasPublishedThemeLoaded && !isSelectedThemeLoading)
  }, [publishedTheme, selectedTheme, isSelectedThemeLoading])

  useEffect(() => {
    if (prevProcessing && isSuccess && !error) {
      toastCompleted('Theme Successfully Published!')
      reset()
      handleToggleModal()
    }
  }, [prevProcessing, isSuccess, error, handleToggleModal, toastCompleted, reset])

  useEffect(() => {
    if (error) {
      toastError('Uh-Oh, something went wrong. Please try again.')
      reset()
    }
  }, [error, toastError, reset])

  const changedGlobalStyleProperties = useMemo(
    () => getChangedGlobalStyles(publishedTheme, selectedTheme),
    [publishedTheme, selectedTheme],
  )

  const ArrowIcon = useMemo(
    () => (
      <Icon
        icon={ArrowForward}
        size={IconSize.xsmall}
        color={COLOR.NEUTRAL[800]}
        data-cy="publish-modal:arrow-icon"
      />
    ),
    [],
  )

  const displayStyleChanges = useCallback(
    (
      key: keyof ThemeGlobalProperties,
      themeGlobalStyles: ThemeGlobalProperties,
      isOld: boolean,
    ): ReactElement => {
      const isColorField = key.toLowerCase().includes('color')
      const isButtonRadius = key === 'buttonRadius'
      const isStoreLogo = key === 'storeLogoUrl'
      const isText = !(isColorField || isButtonRadius || isStoreLogo)

      return (
        <div
          className="flex flex-col"
          key={`${isOld ? 'old' : 'changed'}:${key}`}
          data-cy={`publish-modal:${key}`}
        >
          <p className={styles['row-title']}>{PROPERTY_TITLE_MAP[key]}</p>
          <div className={styles['row-item']}>
            {isColorField && <ColorPreview color={themeGlobalStyles[key] as string} />}
            {isButtonRadius && (
              <p
                className={styles.text}
              >{`${themeGlobalStyles.buttonRadius.size}${themeGlobalStyles.buttonRadius.measurement}`}</p>
            )}
            {isStoreLogo && (
              <div className="flex flex-col">
                <div className={styles.logo}>
                  <img
                    alt="logo"
                    className={styles['logo-img']}
                    src={themeGlobalStyles[key] as string}
                  />
                  {isOld && ArrowIcon}
                </div>
              </div>
            )}
            {isText && <p className={styles.text}>{themeGlobalStyles[key]}</p>}
            {isOld && !isStoreLogo && ArrowIcon}
          </div>
        </div>
      )
    },
    [ArrowIcon],
  )

  const displayThemeName = useCallback(
    (theme: Theme, isOld: boolean): ReactElement => (
      <div className="flex flex-col" data-cy="publish-modal:theme-names">
        <p className={styles['row-title']}>Theme</p>
        <div className={styles['row-item']}>
          <p className={styles.text}>{theme.name}</p>
          {isOld && ArrowIcon}
        </div>
      </div>
    ),
    [ArrowIcon],
  )

  const confirmCss = useCallback(() => {
    setRequiresCssConfirmation(false)
  }, [])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading={requiresCssConfirmation ? "Hang on! Your published theme has a CSS override." : "Publish These Changes?"}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: handleToggleModal,
          'data-cy': 'publish-modal-cancel',
        }}
        primaryButtonProps={{
          text: requiresCssConfirmation ? 'Proceed & Disabled Override' : 'Publish',
          onClick: requiresCssConfirmation ? confirmCss : handlePublish,
          color: requiresCssConfirmation ? 'red' : 'blue',
          isProcessing,
          'data-cy': 'publish-modal-publish',
        }}
      >
        <p className={styles.text}>
          {requiresCssConfirmation && (
            <span>
              Publishing this new theme will disable the existing CSS override. You will need to contact the Extend team for updates.<br/><br/>Do you wish to proceed?
            </span>
          )}
          {!requiresCssConfirmation && (
            <span>
               All the Extend offers associated with this store will be affected. The changes are
               summarized below:
            </span>
          )}
        </p>
        {isLoadingThemeDetails && !requiresCssConfirmation && <PublishModalSkeleton />}
        {!isLoadingThemeDetails && !requiresCssConfirmation && (
          <section className={styles.content}>
          {publishedTheme && (
              <div className={`flex flex-col ${styles['half-width']}`}>
                <p className={styles['column-title']}>OLD</p>
                {displayThemeName(publishedTheme, true)}
                {changedGlobalStyleProperties.map((property) => {
                  return displayStyleChanges(property, publishedTheme.contents.global, true)
                })}
              </div>
            )}
            {selectedTheme && (
              <div className="flex flex-col">
                <p className={styles['column-title']}>NEW</p>
                {displayThemeName(selectedTheme, false)}
                {changedGlobalStyleProperties.map((property) => {
                  return displayStyleChanges(property, selectedTheme.contents.global, false)
                })}
              </div>
            )}
          </section>
        )}
      </Modal>
    </ModalController>
  )
}
