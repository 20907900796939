// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mFB34L6S6lBBVJXvUoPmX {\n    padding-bottom: 16px;\n}\n\n._3AUAhQaeNJS2ukoevsZTTl {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: baseline;\n    gap: 4px;\n    padding-bottom: 8px;\n}\n\n._3fWJOLw02xRuSPkmW4ibW1 {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n@media (max-width: 980px) {\n    ._3fWJOLw02xRuSPkmW4ibW1 {\n        grid-template-columns: 1fr;\n    }\n}\n\n._3J9CVY56M8nYTkRBeP5N7F {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    width: 100%;\n}\n\n._1U-hrSMWuGpPFwggQEakZs {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    gap: 4px;\n}\n\n._2JFma6aZXDqkm7NUiZuk7A {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    gap: 8px;\n}\n\n._2OvA0fMDrjWbLu-Zx4r2FC {\n    flex: 1;\n    padding: 8px 0 0;\n    width: 174px;\n}\n\n._1Ga8ylYz2LE9b5zNx87laM {\n    flex: 2;\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n._1Ga8ylYz2LE9b5zNx87laM > div {\n    flex-grow: 1;\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "mFB34L6S6lBBVJXvUoPmX",
	"user-name-section": "_3AUAhQaeNJS2ukoevsZTTl",
	"roles-section": "_3fWJOLw02xRuSPkmW4ibW1",
	"label-row": "_3J9CVY56M8nYTkRBeP5N7F",
	"data-row": "_1U-hrSMWuGpPFwggQEakZs",
	"container": "_2JFma6aZXDqkm7NUiZuk7A",
	"role-selector": "_2OvA0fMDrjWbLu-Zx4r2FC",
	"org-selector": "_1Ga8ylYz2LE9b5zNx87laM"
};
module.exports = exports;
