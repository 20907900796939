import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { isEnv } from '@extend/client-helpers'
import { useUser } from './use-user'

export function useSetRumUser(): void {
  const { isLoggedIn, userId, email, accountId, tokenRole } = useUser()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (!isInitialized && isLoggedIn && isEnv(['production', 'demo'])) {
      datadogRum.setUser({
        id: userId,
        email,
        role: tokenRole || '',
        accountId,
      })
      setIsInitialized(true)
    }
  }, [isLoggedIn, userId, email, accountId, tokenRole, isInitialized])
}
