// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._15v_SwpEhBNYb8dieHCNjS {\n    display: flex;\n    align-items: center;\n    padding-top: 8px;\n}\n\n._1d0LMmQkuKLIzV1ombaHMd {\n    margin-right: 12px;\n}\n\n.UDQgM9ChK9XCuqlXQdUtu:hover {\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"info": "_15v_SwpEhBNYb8dieHCNjS",
	"value": "_1d0LMmQkuKLIzV1ombaHMd",
	"link": "UDQgM9ChK9XCuqlXQdUtu"
};
module.exports = exports;
