import { useCallback } from 'react'
import {
  modalStepAtom,
  isModalVisibleAtom,
  transactionIdAtom,
  orderAtom,
  productAtom,
  productDetailsAtom,
  offersAtom,
  planDetailsAtom,
  isConfirmationModalVisibleAtom,
} from '../atoms/create-contracts-modal'
import { useSetAtom } from 'jotai'
import { CreateContractsModalStep } from '../constants/create-contracts-modal-step'

export const useCreateContractsModalClose = () => {
  const setModalStep = useSetAtom(modalStepAtom)
  const setTransactionId = useSetAtom(transactionIdAtom)
  const setOrderAtom = useSetAtom(orderAtom)
  const setProduct = useSetAtom(productAtom)
  const setProductDetails = useSetAtom(productDetailsAtom)
  const setOffers = useSetAtom(offersAtom)
  const setPlanDetails = useSetAtom(planDetailsAtom)
  const setIsModalVisible = useSetAtom(isModalVisibleAtom)
  const setIsConfirmationModalVisible = useSetAtom(isConfirmationModalVisibleAtom)

  const handleModalClose = useCallback(() => {
    window.setTimeout(() => {
      setModalStep(CreateContractsModalStep.OrderSearch)
      setTransactionId('')
      setOrderAtom(undefined)
      setProduct(undefined)
      setProductDetails(undefined)
      setOffers([])
      setPlanDetails(undefined)
    }, 500)

    setIsModalVisible(false)
    setIsConfirmationModalVisible(false)
  }, [])

  return {
    handleModalClose,
  }
}
