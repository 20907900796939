import React, { useState } from 'react'
import type { FC } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { AccordionSection, Button } from '@extend/zen'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import styles from './fulfilled-product-shipment.module.css'
import { useFulfilledProductShipmentData } from '../../../hooks/use-fulfilled-product-shipment-data'
import { useServiceOrderShippingLabel } from '../../../hooks/use-service-order-shipping-label'

interface FulfilledProductShipmentSectionProps {
  serviceOrder: ServiceOrder
}
export const FulfilledProductShipmentSection: FC<FulfilledProductShipmentSectionProps> = ({
  serviceOrder,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { id: serviceOrderId } = serviceOrder
  const { gridData } = useFulfilledProductShipmentData(serviceOrderId)
  const { handleDownloadShippingLabel, hasDownloadLabelUrl } = useServiceOrderShippingLabel(
    serviceOrder,
    'customer',
  )

  return (
    <AccordionSection
      heading="Fulfilled Product Shipment"
      headingSize="sub"
      data-cy={`${serviceOrder.id}-fulfilled-product-shipment`}
      onToggleRequest={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
    >
      <DataPropertyGrid values={gridData} className={styles.grid} />
      {hasDownloadLabelUrl && (
        <Button text="Download Label" onClick={handleDownloadShippingLabel} />
      )}
    </AccordionSection>
  )
}
