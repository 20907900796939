import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAtomValue } from 'jotai/react'
import type { EnterpriseUserRole } from '../lib/permissions'
import {
  cachedRolesAtom,
  hasLoadedV3TokenAtom,
  v3AccessTokenAtom,
} from '../atoms/auth'
import {
  decodeToken,
  getAccountIdFromV3Token,
  getRoleFromCachedRoles,
  isValidToken,
} from '../lib/jwt'

/*
 * IF MAKING CHANGES TO AUTH TOKENS, BE SURE TO TEST THE ENTIRE FLOW.
 * THIS INCLUDES LOGGING IN, LOGGING OUT, APP REDIRECTS TO CONTRACTS PAGE IF YOU ARE LOGGED IN AND TRY TO GO TO LOGIN PAGE, WHEN TOKEN EXPIRES THE APP REDIRECTS TO LOGIN PAGE
 */
export const useUser = (): {
  isLoggedIn: boolean
  tokenRole: EnterpriseUserRole | undefined
  accountId: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  userId: string | undefined
  initials: string
  hasLoadedToken: boolean
} => {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom)
  const hasLoadedV3Token = useAtomValue(hasLoadedV3TokenAtom)

  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const decodedV3Token = useMemo(() => {
    return v3AccessToken ? decodeToken(v3AccessToken) : null
  }, [v3AccessToken])

  const v3AccountId = useMemo(() => {
    return decodedV3Token ? getAccountIdFromV3Token(v3AccessToken) : undefined
  }, [decodedV3Token, v3AccessToken])

  const initials = useMemo(() => {
    const firstName = decodedV3Token?.firstName
    const lastName = decodedV3Token?.lastName

    const firstLetter = firstName?.substring(0, 1)
    const lastLetter = lastName?.substring(0, 1)
    return `${firstLetter}${lastLetter}`.toUpperCase()
  }, [decodedV3Token])

  const isLoggedIn = Boolean(v3AccessToken) && isValidToken(v3AccessToken, undefined, true)

  useEffect(() => {
    if (!isLoggedIn) {
      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [history, isLoggedIn, searchParams])

  const cachedRoles = useAtomValue(cachedRolesAtom)

  let tokenRole: EnterpriseUserRole | undefined

  if (v3AccountId) {
    tokenRole = getRoleFromCachedRoles(cachedRoles, v3AccountId) || undefined
  }

  return {
    tokenRole,
    isLoggedIn,
    accountId: v3AccountId ?? undefined,
    firstName: decodedV3Token?.firstName,
    lastName: decodedV3Token?.lastName,
    email: decodedV3Token?.email,
    userId: decodedV3Token?.sub,
    initials,
    hasLoadedToken: hasLoadedV3Token,
  }
}
