import type { FC } from 'react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { customLogger } from '@extend/client-helpers'
import { getProductsUrl } from '../../utils/route-helper'
import { DownloadCSVData } from '../../components/download-csv-data'
import { useExportProductsMutation } from '../../queries/products'

export const ProductsDownload: FC = () => {
  const { search } = useLocation()
  const { push } = useHistory()
  const urlSearchParams = new URLSearchParams(search)
  const expiry = parseInt(urlSearchParams.get('expires') || '', 10) || null
  const isExpired = expiry === null || Date.now() > expiry
  const { mutateAsync: downloadProducts, isLoading } = useExportProductsMutation()

  const handleDismissClick = (): void => {
    push(getProductsUrl())
  }

  const onDownloadClick = async (): Promise<void> => {
    try {
      const downloadResponse = await downloadProducts()
      if (downloadResponse && 'url' in downloadResponse) {
        window.open(downloadResponse.url, '_blank')
      }
    } catch (err) {
      if (err instanceof Error) {
        customLogger.error(err.message, { stack: err.stack })
      }
    }
  }

  return (
    <div>
      <h2>Download Products Data</h2>
      <DownloadCSVData
        downloadDataType="Products"
        downloadLink="https://docs.extend.com/docs/manage-your-products"
        isExpired={isExpired}
        handleDismissClick={handleDismissClick}
        isProcessing={isLoading}
        onDownloadClick={onDownloadClick} // will be updated with functionality in a follow-up ticket
      />
    </div>
  )
}
