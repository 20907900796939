import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { getIn, useFormik } from 'formik'
import * as yup from 'yup'
import { Input, InputType, Select } from '@extend/zen'
import { NormalizedPhoneNumber } from '@extend/client-helpers'
import { InsuranceClaim } from '@helloextend/extend-api-client'
import { Footer } from './footer'
import type { MerchantContract } from '../../../../types/merchant-contract'
import {
  cityRegex,
  countries,
  phoneRegEx,
  getProvinceCodes,
  toValidPhoneNumber,
  isValidPostalCode,
} from '../../../../utils/form-utils'
import styles from './contact-form.module.css'

interface ContactFormProps {
  contract: MerchantContract
  onSubmit: (updates: InsuranceClaim['customer']) => void
  toggleNavBlocked: (blocked: boolean) => void
  isLoading: boolean
}

export const ContactForm: FC<ContactFormProps> = ({
  contract: { customer, id },
  onSubmit,
  toggleNavBlocked,
  isLoading,
}) => {
  const shippingAddress = customer?.shippingAddress || customer?.billingAddress
  const customerPhoneNumber = customer?.phone && toValidPhoneNumber(customer.phone)

  const formik = useFormik({
    initialValues: {
      name: customer?.name || '',
      email: customer?.email || '',
      phone: customerPhoneNumber || '',
      shippingAddress: {
        address1: shippingAddress?.address1 || '',
        address2: shippingAddress?.address2 || '',
        city: shippingAddress?.city || '',
        provinceCode: shippingAddress?.provinceCode || '',
        postalCode: shippingAddress?.postalCode || '',
        countryCode: shippingAddress?.countryCode || 'US',
      },
    },
    initialErrors: {
      name: '',
      email: '',
      phone: '',
      shippingAddress: {
        address1: '',
        address2: '',
        city: '',
        provinceCode: '',
        postalCode: '',
      },
    },
    initialTouched: {
      name: false,
      email: false,
      phone: false,
      shippingAddress: {
        address1: false,
        address2: false,
        city: false,
        provinceCode: false,
        postalCode: false,
      },
    },
    onSubmit: (formValues) => {
      onSubmit({ ...formValues })
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
      phone: yup
        .string()
        .matches(phoneRegEx, 'Please enter a valid phone number')
        .required('Phone number is required')
        .test('is-valid-e.164 number', 'Please enter a valid phone number', (value) => {
          // parsePhoneNumber throws an error on empty values and values with length 1
          if (!value || value.length <= 1) return false
          const phoneNumber = new NormalizedPhoneNumber(value, 'US')
          // confirm that the number is a valid E.164 number so it complies with Twilio's API downstream
          return phoneNumber.valid
        }),
      shippingAddress: yup
        .object()
        .shape({
          address1: yup.string().required('Shipping address is required'),
          address2: yup.string(),
          city: yup
            .string()
            .matches(cityRegex, 'Please enter a valid city')
            .required('City is required'),
          provinceCode: yup.string().when('countryCode', {
            is: (value) => value === 'US',
            then: yup
              .string()
              .required('Select a state')
              .test('is-valid-US-state', 'Select a state', function testState(value) {
                return Boolean(value && getProvinceCodes('US').includes(value))
              }),
            otherwise: yup
              .string()
              // when there are provinces, we want to make the province required and validate it
              .when('countryCode', {
                is: (value) => getProvinceCodes(value).length > 0,
                then: yup
                  .string()
                  .required('Select a province')
                  .test('is-valid-non-US-state', 'Select a province', function testState(value) {
                    const { countryCode } = this.parent
                    return Boolean(value && getProvinceCodes(countryCode).includes(value))
                  }),
                otherwise: yup.string(),
              }),
          }),
          countryCode: yup.string().default('US').required(),
          postalCode: yup.string().when('countryCode', {
            is: 'US',
            then: yup
              .string()
              .required('Zip code is required')
              .test(
                'is-valid-US-zip-code',
                'Please enter a valid zip code',
                function testZipCode(value) {
                  const { countryCode } = this.parent
                  if (!countryCode) return true
                  return value ? isValidPostalCode(value, countryCode) : false
                },
              ),
            otherwise: yup
              .string()
              .required('Postal code is required')
              .test(
                'is-valid-non-US-postal-code',
                'Please enter a valid postal code',
                function testZipCode(value) {
                  const { countryCode } = this.parent
                  if (!countryCode) return true
                  return value ? isValidPostalCode(value, countryCode) : false
                },
              ),
          }),
        })
        .required(),
    }),
    validateOnBlur: true,
  })

  const { setFieldValue, handleSubmit, handleChange, handleBlur, values, errors, touched, dirty } =
    formik

  const onHandleBlur = (e: SyntheticEvent): void => {
    const { name, value } = e.target as HTMLInputElement
    setFieldValue(name, value.trim())
    handleBlur(e)
  }

  useEffect(() => {
    toggleNavBlocked(dirty)
  }, [dirty, toggleNavBlocked])

  const error = (field: string): string => {
    return getIn(touched, field) && getIn(errors, field)
  }

  const isUS = values.shippingAddress.countryCode === 'US'

  const provinceValues = getProvinceCodes(values.shippingAddress.countryCode)
  const stateLabel = isUS ? 'State' : 'Province'
  const postalCodeLabel = isUS ? 'Zip Code' : 'Postal Code'

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <h2 className={styles.heading}>
        Please confirm the correct contact information for this customer&lsquo;s claim.
      </h2>
      <Input
        isError={!!error('name')}
        id="name"
        label="Name"
        onBlur={onHandleBlur}
        onChange={handleChange}
        type={InputType.text}
        value={values.name}
        errorFeedback={error('name')}
      />
      <div className={styles['email-phone-row']}>
        <Input
          isError={!!error('email')}
          id="email"
          label="Email Address"
          onBlur={onHandleBlur}
          onChange={handleChange}
          type={InputType.email}
          value={values.email}
          errorFeedback={error('email')}
        />
        <Input
          isError={!!error('phone')}
          id="phone"
          label="Phone"
          onBlur={onHandleBlur}
          onChange={(e) => {
            e.target.value = toValidPhoneNumber(e.target.value)
            handleChange(e)
          }}
          type={InputType.tel}
          value={values.phone}
          errorFeedback={error('phone')}
        />
      </div>
      <div className={styles['addressline1-row']}>
        <Input
          isError={!!error('shippingAddress.address1')}
          id="shippingAddress.address1"
          label="Shipping Address"
          onBlur={onHandleBlur}
          onChange={handleChange}
          type={InputType.text}
          value={values.shippingAddress.address1}
          errorFeedback={error('shippingAddress.address1')}
        />
        <Input
          isError={!!error('shippingAddress.address2')}
          id="shippingAddress.address2"
          label="Apt, Suite #"
          onBlur={onHandleBlur}
          onChange={handleChange}
          type={InputType.text}
          value={values.shippingAddress.address2}
          errorFeedback={error('shippingAddress.address2')}
        />
      </div>
      <div className={styles['addressline2-row']}>
        <Input
          isError={!!error('shippingAddress.city')}
          id="shippingAddress.city"
          label="City"
          onBlur={onHandleBlur}
          onChange={handleChange}
          type={InputType.text}
          value={values.shippingAddress.city}
          errorFeedback={error('shippingAddress.city')}
        />

        <Select
          isError={Boolean(error('shippingAddress.provinceCode'))}
          id="shippingAddress.provinceCode"
          label={stateLabel}
          onBlur={handleBlur('shippingAddress.provinceCode')}
          onChange={handleChange}
          value={values.shippingAddress.provinceCode}
          errorFeedback={error('shippingAddress.provinceCode')}
        >
          <option value="">-Select-</option>
          {provinceValues.map((state: string) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>

        <Input
          isError={!!error('shippingAddress.postalCode')}
          id="shippingAddress.postalCode"
          label={postalCodeLabel}
          onBlur={onHandleBlur}
          onChange={handleChange}
          type={InputType.text}
          value={values.shippingAddress.postalCode}
          data-cy="shippingAddress.postalCode:input"
          errorFeedback={error('shippingAddress.postalCode')}
        />
      </div>
      <div className={styles['country-picker']}>
        <Select
          isError={Boolean(error('shippingAddress.countryCode'))}
          id="shippingAddress.countryCode"
          label="Country"
          onBlur={handleBlur('shippingAddress.countryCode')}
          onChange={handleChange}
          value={values.shippingAddress.countryCode}
          errorFeedback={error('shippingAddress.countryCode')}
        >
          {Object.keys(countries).map((countryCode: string) => (
            <option key={countries[countryCode]} value={countryCode}>
              {countries[countryCode]}
            </option>
          ))}
        </Select>
      </div>
      <Footer disabled={false} isLoading={isLoading} contractId={id} />
    </form>
  )
}
