// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2poQAKi_EWIBZd4_ZsHrHH {\n    font-size: 16px;\n    line-height: 24px;  \n}\n\n._2poQAKi_EWIBZd4_ZsHrHH p { \n    margin-top: 0; \n}\n\n.MUKNuN-fLSSB0TR52BQ7i {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 16px;\n    flex: 1;\n}\n\n.MUKNuN-fLSSB0TR52BQ7i > div {\n    flex: 1;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2poQAKi_EWIBZd4_ZsHrHH",
	"input-row": "MUKNuN-fLSSB0TR52BQ7i"
};
module.exports = exports;
