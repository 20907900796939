// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._64uh6sEXA3qRf79BwI7SJ {\n    width: 280px;\n}\n\n._1cAeTqXhGYNNx8qJ_-njPr {\n    border-bottom: 1px solid var(--neutral-300);\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_64uh6sEXA3qRf79BwI7SJ",
	"menu-item-divider": "_1cAeTqXhGYNNx8qJ_-njPr"
};
module.exports = exports;
