import type { Contract } from '@helloextend/extend-api-client'
import type { ColumnFiltersState } from '@extend/zen'
import type { ContractsTableMapping } from './contract-data-table-config'
import { getContractProductName } from '../../../utils/get-contract-product-name'

export const toContractsTableMapping = (contract: Contract): ContractsTableMapping => {
  let planPrice = contract.purchasePrice
  if (!planPrice) {
    if (typeof contract.plan.purchasePrice === 'number') {
      planPrice = contract.plan.purchasePrice
    } else {
      planPrice = contract.plan.purchasePrice?.amount
    }
  }

  let currency = contract.currency || contract.purchaseCurrency
  if (!currency && typeof contract.plan.purchasePrice !== 'number') {
    currency = contract.plan.purchasePrice?.currencyCode
  }

  return {
    contractId: contract.id,
    currency,
    customerEmail: contract.customer.email,
    customerName: contract.customer.name,
    merchantCustomerId: contract.merchantCustomerId,
    planPrice,
    productName: getContractProductName(contract),
    productSerialNumber: contract.product?.serialNumber,
    purchaseDate: contract.purchaseDate,
    status: contract.status,
    transactionId: contract.transactionId,
    type: contract.type,
    planId: contract.plan.planId,
  }
}

export const getColumnFiltersFromQueryParams = ({
  searchKey,
  searchValue,
  searchFilters,
}: {
  searchKey: string
  searchValue: string
  searchFilters: Record<string, ColumnFiltersState[number]>
}): ColumnFiltersState => {
  const columnFilters = Object.keys(searchFilters).reduce<ColumnFiltersState>((acc, key) => {
    const filters: ColumnFiltersState = []

    const record = searchFilters[key]

    if (['status', 'typeFilter'].includes(key) && Array.isArray(record.value)) {
      filters.push({
        id: key,
        value: record.value,
      })
    }

    return [...acc, ...filters]
  }, [])

  if (searchKey && searchValue) {
    columnFilters.push({
      id: searchKey,
      value: searchValue,
    })
  }

  return columnFilters
}
