import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { BrowserRouter } from 'react-router-dom'
import { LD_CLIENT_ID_MERCHANTS } from '@helloextend/client-constants'
import { ErrorBoundary, SystemError } from '@helloextend/component-commons'
import { merchants, Tooltip, Toaster, ToasterPosition } from '@extend/zen'
import './colors.css'
import { configureDataDogRum } from '@extend/client-helpers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { defaultLDFlagValues } from './constants/ld-flags'
import { Router } from './router'
import configureStore from './store'
import { StoreControlledSplashScreen } from './features/store/store-controlled-splash-screen'
import './global-styles.css'
import '@extend/zen/dist/style.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const store = configureStore()
;(async () => {
  configureDataDogRum(15, 100)
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID_MERCHANTS,
    flags: defaultLDFlagValues,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })

  ReactDOM.render(
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={merchants}>
            <BrowserRouter>
              <Toaster data-cy="toaster" toasterPosition={ToasterPosition.left}>
                <ErrorBoundary page={SystemError}>
                  <Router />
                  <Tooltip />
                </ErrorBoundary>
              </Toaster>
            </BrowserRouter>
            <StoreControlledSplashScreen />
          </ThemeProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </LDProvider>,
    document.querySelector('#root'),
  )
})()
