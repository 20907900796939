import { Button } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { Permission } from '../../../lib/permissions'
import { usePermissions } from '../../../hooks/use-permissions'
import { isCompleteRepairDepotModalVisibleAtom } from '../../../atoms/complete-repair-depot-modal'
import { isDepotRepair } from '../../../lib/service-order-helper'

export const CompleteRepairDepotButton: FC<{ serviceOrder: ServiceOrder }> = ({ serviceOrder }) => {
  const { hasPermission } = usePermissions()
  const setIsCompleteRepairModalVisible = useSetAtom(isCompleteRepairDepotModalVisibleAtom)

  const shouldShowCompleteRepairDepotButton = useMemo(() => {
    return (
      hasPermission(Permission.ServiceOrderFullAccess) &&
      serviceOrder &&
      isDepotRepair(serviceOrder) &&
      serviceOrder?.status === 'repair_started'
    )
  }, [hasPermission, serviceOrder?.serviceType, serviceOrder?.status])

  if (shouldShowCompleteRepairDepotButton) {
    return (
      <>
        <hr />
        <Button text="Complete Repair" onClick={() => setIsCompleteRepairModalVisible(true)} />
      </>
    )
  }

  return null
}
