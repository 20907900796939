import React from 'react'
import type { FC, VFC } from 'react'
import styled from '@emotion/styled'
import {
  ArrowDropDown,
  Button,
  Menu,
  MenuButtonItem,
  MenuContextProvider,
  PopoverAlignment,
  PopoverDirection,
  useMenuContext,
  usePopover,
  useThemeSafe,
} from '@extend/zen'
import type { MenuButtonItemProps } from '@extend/zen'
import type { SplitButtonProps, Theme } from './types'

export const SplitButton: FC<SplitButtonProps> = ({
  mainButton,
  items,
  menuButton = {},
  isDisabled,
}) => {
  const theme = useThemeSafe() as Theme
  const { hideAll } = usePopover<HTMLElement>()

  const { borderRadius, ...restMainButton } = mainButton
  const { ...restMenuButton } = menuButton

  const bRadius = borderRadius || theme.button.borderRadius

  const mainButtonBorderRadius = `${bRadius} 0px 0px ${bRadius}`
  const dropdownButtonBorderRadius = `0px ${bRadius} ${bRadius} 0px`

  // If the top level isDisabled prop is true, all buttons should be disabled
  const allDisabled = isDisabled || false

  return (
    <MenuContextProvider hideAll={hideAll}>
      <StyledContainer>
        <Button
          borderRadius={mainButtonBorderRadius}
          isDisabled={allDisabled}
          {...restMainButton}
        />
        <Menu
          alignment={PopoverAlignment.end}
          direction={PopoverDirection.down}
          triggerRenderer={() => (
            <Button
              icon={ArrowDropDown}
              borderRadius={dropdownButtonBorderRadius}
              isDisabled={allDisabled}
              {...restMenuButton}
            />
          )}
        >
          <MenuItems items={items} isDisabled={allDisabled} />
        </Menu>
      </StyledContainer>
    </MenuContextProvider>
  )
}

const MenuItems: VFC<{ items: MenuButtonItemProps[]; isDisabled?: boolean }> = ({
  items,
  isDisabled,
}) => {
  const { hideAll } = useMenuContext()

  return (
    <>
      {items.map((itemProps, idx) => {
        const key = `item-${idx}`
        return (
          <MenuButtonItem
            key={key}
            disabled={isDisabled}
            {...itemProps}
            onClick={(e) => {
              if (itemProps.onClick) {
                itemProps.onClick(e)
              }

              hideAll()
            }}
          />
        )
      })}
    </>
  )
}

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  // Second button pseudo before element has no left border width to avoid double border thickness
  '& button:last-of-type::before': {
    borderLeftWidth: 0,
  },
})
