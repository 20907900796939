import type { FC } from 'react'
import React from 'react'
import { Button, HeadingLarge } from '@extend/zen'
import { customLogger } from '@extend/client-helpers'
import { ErrorBrokenLinks, ErrorCrossCircle } from '../../../components/icons'
import styles from './claim-details-error.module.css'

interface ClaimDetailsErrorProps {
  handleOnClickContractDetails: () => void
  contractId: string
  claimId: string
  error: string
}

export const ClaimDetailsError: FC<ClaimDetailsErrorProps> = ({
  handleOnClickContractDetails,
  contractId,
  claimId,
  error,
}) => {
  customLogger.error('User encountered an error while attempting to fetch claim details', {
    claimId,
    contractId,
    error,
  })

  return (
    <div className={styles.messages}>
      <div className={styles.icons}>
        <ErrorCrossCircle />
        <ErrorBrokenLinks />
      </div>
      <HeadingLarge>A problem occurred while submitting this claim...</HeadingLarge>
      <p className={styles.error}>
        There was a system error while submitting this claim. Extend has been notified of this
        failure, and will reach out to the customer directly.
      </p>
      <p className={styles.support}>
        If you need immediate help, contact our support team at{' '}
        <a className={styles.link} href="mailto:support@extend.com">
          support@extend.com
        </a>
        , and provide this contract ID: {contractId}
      </p>
      <Button size="small" text="Exit" onClick={handleOnClickContractDetails} />
    </div>
  )
}
