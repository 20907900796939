import type { BadgeColor } from '@extend/zen'
import type { BadgeDescriptionItem } from '../components/badge-descriptions'
import type { Grant } from '../types/okta'

export type ExtendUserAccountStatus = 'PENDING' | 'DEACTIVATED' | 'ACTIVE' | 'NOSTATUS'

const USER_STATUS_COLOR: Record<string, BadgeColor> = {
  deactivated: 'neutral',
  active: 'green',
  pending: 'yellow',
  noStatus: 'neutral',
}

export const ACTIVE_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Active',
  description: 'User has accepted their invitation and successfully logged in to Merchant Portal.',
  color: USER_STATUS_COLOR.active,
}

export const DEACTIVATED_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Deactivated',
  description:
    'All access and Roles have been revoked and user can no longer log in to Merchant Portal.',
  color: USER_STATUS_COLOR.deactivated,
}

export const PENDING_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Pending',
  description: 'User has been invited but has not yet logged in successfully to Merchant Portal. ',
  color: USER_STATUS_COLOR.pending,
}

export const FILTER_OPTIONS = [
  {
    display: 'Active',
    value: 'ACTIVE',
  },
  { display: 'Pending', value: 'PENDING' },

  {
    display: 'Deactivated',
    value: 'DEACTIVATED',
  },
]

export const userStatusBadgeDescriptionItems: Record<string, BadgeDescriptionItem> = {
  ACTIVE: ACTIVE_STATUS_OPTIONS,
  PENDING: PENDING_STATUS_OPTIONS,
  DEACTIVATED: DEACTIVATED_STATUS_OPTIONS,
}

export const STATUS_MAP: BadgeDescriptionItem[] = [
  ACTIVE_STATUS_OPTIONS,
  PENDING_STATUS_OPTIONS,
  DEACTIVATED_STATUS_OPTIONS,
]

const DEACTIVATED_OKTA_STATUS = ['STAGED', 'SUSPENDED', 'DEPROVISIONED']
const PENDING_OKTA_STATUS = ['PROVISIONED', 'RECOVERY', 'PASSWORD_EXPIRED', 'LOCKED_OUT']
const ACTIVE_OKTA_STATUS = 'ACTIVE'

export const getExtendUserStatusBadgeItem = (
  status: ExtendUserAccountStatus,
): BadgeDescriptionItem => {
  return userStatusBadgeDescriptionItems[status]
}

export const mapOktaToExtendStatus = (
  value: string,
  grants: Grant[] = [],
): ExtendUserAccountStatus => {
  if (grants.length > 0) {
    if (value === ACTIVE_OKTA_STATUS) {
      return 'ACTIVE'
    }

    if (PENDING_OKTA_STATUS.includes(value)) {
      return 'PENDING'
    }
  }

  if (DEACTIVATED_OKTA_STATUS.includes(value) || grants.length === 0) {
    /**
     * @Return 'DEACTIVATED' if value is included in DEACTIVATED_OKTA_STATUS
     * or if value is included in PENDING_OKTA_STATUS/ACTIVE_OKTA_STATUS and 0 grants are present
     * */
    return 'DEACTIVATED'
  }

  return 'NOSTATUS'
}
