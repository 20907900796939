import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import type { ButtonProps } from '@extend/zen'
import { ToastColor, ToastDuration, useToaster, Add } from '@extend/zen'
import { Redirect, Route, Switch } from 'react-router'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { TabBar } from '../../../components/tab-bar'
import { getLeadsEmailManagementUrl, getLeadsSearchUrl } from '../../../utils/route-helper'
import { LeadsEmailManagement } from './leads-email-management'
import { LeadsDataTable } from './leads-data-table'
import { StandardHeader } from '../../../components/standard-header'
import type { LeadSearchQueryStringOptions } from '../../../queries/leads'
import { useExportLeadsMutation } from '../../../queries/leads'
import { getActiveStoreAtom, getActiveStoreIdAtom } from '../../../atoms/stores'
import { isCreateLeadModalVisibleAtom } from '../../../atoms/create-lead-modal'
import { CreateLeadModal } from '../../leads/create-lead-modal'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

const Leads: FC = () => {
  const { toast } = useToaster()
  const { hasPermission } = usePermissions()
  const store = useAtomValue(getActiveStoreAtom)
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const setIsCreateLeadModalOpen = useSetAtom(isCreateLeadModalVisibleAtom)
  const { mutateAsync: exportLeads, isLoading } = useExportLeadsMutation()
  const [isLeadsExportDisabled, setLeadsExportDisabled] = useState(true)

  const [searchQs, setSearchQs] = useState<LeadSearchQueryStringOptions>({ storeId })

  const hasCreateLeadPermission = hasPermission(Permission.LeadsFullAccess)

  const handleClick = useCallback(async (): Promise<void> => {
    if (storeId) {
      try {
        await exportLeads(searchQs)
        toast({
          message:
            'Your data is being prepared. You will receive an email with a download link shortly. ',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.blue,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }, [storeId, exportLeads, searchQs, toast])

  const onLeadsQueryChange = useCallback(
    (params: Omit<LeadSearchQueryStringOptions, 'storeId'>): void => {
      setSearchQs({ ...params, storeId })
    },
    [setSearchQs, storeId],
  )

  const onLeadsDataChange = (hasLeads: boolean): void => {
    setLeadsExportDisabled(!hasLeads)
  }

  const headerButtons = useMemo(() => {
    const buttons: ButtonProps[] = [
      {
        onClick: handleClick,
        text: 'Export Leads',
        emphasis: 'medium',
        isProcessing: isLoading,
        isDisabled: isLeadsExportDisabled,
      },
    ]

    if (hasCreateLeadPermission) {
      buttons.unshift({
        onClick: () => setIsCreateLeadModalOpen(true),
        text: 'Create Lead',
        emphasis: 'medium',
        icon: Add,
      })
    }
    return buttons
  }, [
    handleClick,
    isLoading,
    isLeadsExportDisabled,
    setIsCreateLeadModalOpen,
    hasCreateLeadPermission,
  ])

  return (
    <>
      <StandardHeader pageTitle="Leads" data-cy="customize-header" buttons={headerButtons} />
      {store?.enableMerchantPortalLeadEmailsConfiguration ? (
        <>
          <TabBar
            tabBarLinks={[
              { to: getLeadsSearchUrl(), text: 'Leads Search' },
              { to: getLeadsEmailManagementUrl(), text: 'Leads Email Management' },
            ]}
          />
          <Switch>
            <Route exact path={getLeadsSearchUrl()}>
              <LeadsDataTable
                onLeadsDataChange={onLeadsDataChange}
                onLeadsQueryChange={onLeadsQueryChange}
              />
            </Route>
            <Route exact path={getLeadsEmailManagementUrl()}>
              <LeadsEmailManagement storeId={storeId} />
            </Route>

            <Redirect to="/404" />
          </Switch>
        </>
      ) : (
        <LeadsDataTable
          onLeadsDataChange={onLeadsDataChange}
          onLeadsQueryChange={onLeadsQueryChange}
        />
      )}
      <CreateLeadModal />
    </>
  )
}

export { Leads }
