import type { FC } from 'react'
import React from 'react'
import images from '../images'
import styles from './auth-layout.module.css'

type AuthLayoutProps = {
  showFooter?: boolean
  showLogo?: boolean
}

export const AuthLayout: FC<AuthLayoutProps> = ({ showFooter, children }) => (
  <section className={styles.wrapper} data-cy="login-wrapper">
    <div className={styles.card} data-cy="login-card">
      <img className={styles.logo} data-cy="login-logo" src={images.extendLogo} alt="Extend" />
      {children}
    </div>
    {showFooter && (
      <div className={styles.footer} data-cy="login-footer">
        Don&apos;t have an account yet?{' '}
        <a
          className={styles['button-text']}
          data-cy="login-contact"
          href="https://www.extend.com/contact"
        >
          Contact us
        </a>
      </div>
    )}
  </section>
)
