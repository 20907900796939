// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fMMSGYjkhHOT1_c8-tKKd {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._2opGcq59-Dg2kwYX3toOpR {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._3KnikD0hpwes9G691sxBJc {\n    height: 800px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n.fMMSGYjkhHOT1_c8-tKKd.-AQeYBvg1ieIRTCKMGJsj {\n    height: 570px;\n    width: 600px;\n}\n\n._2opGcq59-Dg2kwYX3toOpR.-AQeYBvg1ieIRTCKMGJsj {\n    height: 700px;\n    width: 700px;\n}\n\n._3KnikD0hpwes9G691sxBJc.-AQeYBvg1ieIRTCKMGJsj {\n    height: 600px;\n    width: 600px;\n}\n\n._12JGGLlr132BkGJZFdKL5C {\n    transform: translateX(20%);\n}\n\n._3PzZ2IpJEustVc8RLleCiO {\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"learn-more": "fMMSGYjkhHOT1_c8-tKKd",
	"offer": "_2opGcq59-Dg2kwYX3toOpR",
	"aftermarket": "_3KnikD0hpwes9G691sxBJc",
	"desktop": "-AQeYBvg1ieIRTCKMGJsj",
	"cart": "_12JGGLlr132BkGJZFdKL5C",
	"spinner": "_3PzZ2IpJEustVc8RLleCiO"
};
module.exports = exports;
