import type { FC } from 'react'
import React from 'react'
import { date } from '@extend/client-helpers'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import styles from './expense-info-banner.module.css'
import { DataPropertyGrid } from '../../../components/data-property-grid'

export const ExpenseInfoBanner: FC<{
  serviceOrder: ServiceOrder
  expenseTotal: string
  currency?: string
}> = ({ serviceOrder, expenseTotal, currency }) => {
  const data = [
    {
      label: 'Amount Requested',
      value: expenseTotal,
      key: 'Amount Requested',
    },
    {
      label: 'Amount Approved',
      value: serviceOrder.status === 'payment_approved' ? expenseTotal : `0.00 ${currency}`,
      key: 'Amount Approved',
    },
    {
      label: 'Last Modified',
      value: date.format(serviceOrder.updatedAt, 'MM/DD/YYYY'),
      key: 'Last Modified',
    },
  ]

  return <DataPropertyGrid values={data} className={styles['expense-info-banner']} />
}
