import React, { useCallback, useMemo } from 'react'
import { useSetAtom } from 'jotai/react'
import { Button } from '@extend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { isStartRepairModalVisibleAtom } from '../../../atoms/start-repair-modal'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import { isDepotRepair } from '../../../lib/service-order-helper'

export function StartRepairButton({
  serviceOrder,
}: {
  serviceOrder: ServiceOrder
}): JSX.Element | null {
  const { hasPermission } = usePermissions()

  const shouldShowStartRepairButton = useMemo(() => {
    return (
      hasPermission(Permission.ServiceOrderFullAccess) &&
      isDepotRepair(serviceOrder) &&
      serviceOrder.status === 'repair_shipped'
    )
  }, [serviceOrder.serviceType, serviceOrder.status, hasPermission])
  const setIsStartRepairModalVisible = useSetAtom(isStartRepairModalVisibleAtom)

  const handleOnClick = useCallback(() => {
    setIsStartRepairModalVisible(true)
  }, [setIsStartRepairModalVisible])

  if (shouldShowStartRepairButton) {
    return (
      <>
        <hr />
        <Button text="Start Repair" onClick={handleOnClick} isProcessing={false} />
      </>
    )
  }

  return null
}
