import type { FC } from 'react'
import React from 'react'
import { COLOR, Input, Spinner } from '@extend/zen'
import { useGetStoreQuery } from '../../../queries/stores'
import styles from './store-details.module.css'

type StoreDetailsFormProps = {
  storeId: string
}

export const StoreDetails: FC<StoreDetailsFormProps> = ({ storeId }) => {
  const { data: store, isLoading } = useGetStoreQuery({
    storeId,
    version: 'latest',
  })

  if (isLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!store) return null

  const { platform, name, domain } = store

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Input label="Store Name" id="settingsStoreName" value={name ?? ''} isDisabled />
      </div>
      <div className={styles.wrapper}>
        <Input label="Website" id="settingsDomainName" value={domain} isDisabled />
      </div>
      <div className={styles.wrapper}>
        <Input label="E-Commerce Platform" id="settingsStorePlatform" value={platform} isDisabled />
      </div>
    </div>
  )
}
