// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2XvGOBrBsUbvfZ9VHICNqX {\n  display: flex;\n  flex-direction: column;\n  margin: 16px 0;\n}\n\n._2ow-YVmnCQB0Ymz6RvlCdO {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 20px;\n  row-gap: 16px;\n  margin-bottom: 16px;\n}\n\n._1_jnoqSRY7s867bGa68pF_ {\n  margin-top: 16px;\n}\n\n._3txdJcL-FRHGDAR2N9IAPy {\n  margin-top: 16px;\n}", ""]);
// Exports
exports.locals = {
	"form": "_2XvGOBrBsUbvfZ9VHICNqX",
	"grid": "_2ow-YVmnCQB0Ymz6RvlCdO",
	"alert-wrapper": "_1_jnoqSRY7s867bGa68pF_",
	"button-group-wrapper": "_3txdJcL-FRHGDAR2N9IAPy"
};
module.exports = exports;
