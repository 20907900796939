// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PgHslPjeUc2yTgtoTUTX7 {\n    margin-top: 32px;\n    font-size: 20px;\n}\n\n._2C5_Tx72VPJiEf56lmgjpL {\n    border: none;\n    background-color: inherit;\n    font-size: inherit;\n    text-decoration: inherit;\n    color: var(--blue-800);\n}\n\n._2C5_Tx72VPJiEf56lmgjpL:visited {\n    color: var(--blue-800);\n}\n\n._2C5_Tx72VPJiEf56lmgjpL:hover {\n    text-decoration: underline;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "PgHslPjeUc2yTgtoTUTX7",
	"contact": "_2C5_Tx72VPJiEf56lmgjpL"
};
module.exports = exports;
