import type { FC } from 'react'
import React from 'react'
import { TabSections, TabSection } from '@extend/zen'
import { StandardHeader } from '../../components/standard-header'
import { ApiIntegrationsTable } from './api-integrations-table'
// import { PlatformIntegrationsTable } from './platform-integrations-table'

export const Integrations: FC = () => {
  return (
    <>
      <StandardHeader marginBottom={40} pageTitle="Integrations" />
      <TabSections id="integrations" isQSControlled>
        <TabSection tabLabel="API Integrations" qs="api">
          <ApiIntegrationsTable />
        </TabSection>
        {/*
        TODO:[MEXP-2589] uncomment when platform integrations are ready
        <TabSection tabLabel="Platform Integrations" qs="platform">
          <PlatformIntegrationsTable />
        </TabSection>
         */}
      </TabSections>
    </>
  )
}
