import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import type { Location } from 'history'
import { Prompt } from 'react-router-dom'
import { LeavePageModal } from './leave-page-modal'

interface LeavePageGuardProps {
  isNavBlocked: boolean
  handleLeavePage: (path: string) => void
}

const LeavePageGuard: FC<LeavePageGuardProps> = ({ isNavBlocked, handleLeavePage }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleCloseModal = (): void => {
    setIsVisible(false)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setIsVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = (): void => {
    setIsVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      handleLeavePage(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, handleLeavePage])

  const onBeforeUnloadWindow = useCallback(
    (event: Event) => {
      if (!isNavBlocked) {
        return
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
      // Because of deprecations and browser inconsistencies, both of the following lines are
      // intended to cause the native browser prompt to guard again accidental navigations.
      event.preventDefault()

      // eslint-disable-next-line no-param-reassign
      event.returnValue = true
    },
    [isNavBlocked],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnloadWindow)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadWindow)
    }
  }, [onBeforeUnloadWindow])

  return (
    <>
      <Prompt when={isNavBlocked} message={handleBlockedNavigation} />
      <LeavePageModal
        isVisible={isVisible}
        handleCloseModal={handleCloseModal}
        handleLeavePage={handleConfirmNavigationClick}
      />
    </>
  )
}

export type { LeavePageGuardProps }
export { LeavePageGuard }
