import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useAtom } from 'jotai/react'
import { ModalController } from '@extend/zen'
import { isModalVisibleAtom, modalStepAtom } from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import { CreateContractsModalOrderSearch } from './create-contracts-modal-order-search'
import { CreateContractsModalOrderDetails } from './create-contracts-modal-order-details'
import { CreateContractsModalProductsSearch } from './create-contracts-modal-products-search'
import { CreateContractsModalProductDetails } from './create-contracts-modal-product-details'
import { CreateContractsModalPlanSelection } from './create-contracts-modal-plan-selection'
import { CreateContractsModalConfirmation } from './create-contracts-modal-confirmation'

/**
 * Manual contract creation is a multi-step process.
 *
 * When open, the Create Contracts modal has several distinct states that display sequentially.
 * 1. The user searches for an order by transaction ID.
 * 2. If the order is found, the user is shown the order details and asked to confirm.
 *   alt: If the order is not found, the user is asked to enter the order details, for order creation.
 * 3. The user searches for a product within the order (or to add to the new order), by reference ID. The user is
 * then prompted to enter additional purchase details.
 * 4. Select a list of relevant protection plans for the selected product below the product purchase details.
 * 5. The Contract Confirmation component is displayed, showing order, customer, and product information. The user clicks the
 * disclaimer checkbox and then clicks the "Confirm" CTA.
 * The "Confirm" CTA closes the modal.
 */
export const CreateContractsModalController: FC = () => {
  const [isModalVisible] = useAtom(isModalVisibleAtom)
  const [modalStep] = useAtom(modalStepAtom)

  const modalContent: JSX.Element = useMemo(() => {
    // Render the appropriate modal content based on the current step
    switch (modalStep) {
      case CreateContractsModalStep.OrderSearch:
        return <CreateContractsModalOrderSearch />
      case CreateContractsModalStep.OrderDetails:
        return <CreateContractsModalOrderDetails />
      case CreateContractsModalStep.ProductSearch:
        return <CreateContractsModalProductsSearch />
      case CreateContractsModalStep.ProductDetails:
        return <CreateContractsModalProductDetails />
      case CreateContractsModalStep.PlanSelection:
        return <CreateContractsModalPlanSelection />
      case CreateContractsModalStep.Confirmation:
        return <CreateContractsModalConfirmation />
      default:
        return <CreateContractsModalOrderSearch />
    }
  }, [modalStep])

  return <ModalController isOpen={isModalVisible}>{modalContent}</ModalController>
}
