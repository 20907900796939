import type { ReactElement, FC } from 'react'
import React, { useMemo, useCallback } from 'react'
import { Icon, IconSize, COLOR } from '@extend/zen'
import { ArrowForward } from '@extend/zen'
import { Loader } from '@helloextend/merchants-ui'
import styles from './publish-modal-skeleton.module.css'

const FIELDS = [
  'Theme',
  'Store Logo',
  'Background Color',
  'Font Family',
  'Primary Font Color',
  'Button Color',
  'Button Font Color',
  'Button Corner Radius',
]

export const PublishModalSkeleton: FC = () => {
  const ArrowIcon = useMemo(
    () => (
      <Icon
        icon={ArrowForward}
        size={IconSize.xsmall}
        color={COLOR.NEUTRAL[800]}
        data-cy="publish-modal:arrow-icon"
      />
    ),
    [],
  )

  const generateFieldLoader = useCallback(
    (fieldName: string, isOld?: boolean): ReactElement => {
      return (
        <>
          <p className={styles['row-item']}>{fieldName}</p>
          <div className={styles['row-item']}>
            <Loader width="85%" />
            {isOld && ArrowIcon}
          </div>
        </>
      )
    },
    [ArrowIcon],
  )

  return (
    <section className={styles.skeleton}>
      <div className={`${styles.column} ${styles.old}`}>
        <p className={styles['column-title']}>Old</p>
        {FIELDS.map((fieldName: string) => {
          return generateFieldLoader(fieldName, true)
        })}
      </div>
      <div className={styles.column}>
        <p className={styles['column-title']}>New</p>
        {FIELDS.map((fieldName: string) => {
          return generateFieldLoader(fieldName)
        })}
      </div>
    </section>
  )
}
