import { ZeroState } from '@helloextend/merchants-ui'
import { useSetAtom } from 'jotai/react'
import type { FC } from 'react'
import React from 'react'
import {
  shouldShowSupportPopoverAtom,
  supportPopoverContactFormDefaultsAtom,
} from '../../../atoms/support'
import { SupportRequestType } from '../../../utils/support'
import styles from './leads-zero-state.module.css'

interface LeadsZeroStateProps {
  isError?: boolean
}

export const LeadsZeroState: FC<LeadsZeroStateProps> = ({ isError }) => {
  const setSupportPopoverContactFormDefaults = useSetAtom(supportPopoverContactFormDefaultsAtom)
  const setShouldShowSupportPopover = useSetAtom(shouldShowSupportPopoverAtom)
  const handleSupport = (): void => {
    setSupportPopoverContactFormDefaults({
      requestType: SupportRequestType.GENERAL_OTHER,
      message: `I'd like to learn more about generating leads for my store. Please have an Extend agent contact me.`,
    })
    setShouldShowSupportPopover(true)
  }

  if (isError) {
    return (
      <ZeroState
        altIconName="cloudsError"
        title="Sorry, something went wrong."
        text={`The data couldn't be retrieved right now.\nPlease try again later.`}
        data-cy="leads-zero-state:error-container"
      />
    )
  }

  return (
    <ZeroState
      title="No leads yet"
      text="When a customer purchases a product but does not purchase the associated offer, you can view those leads here."
      data-cy="leads-zero-state:message-container"
    >
      <div className={styles.container}>
        Visit the{' '}
        <a href="https://docs.extend.com/" target="_blank" rel="noreferrer">
          knowledge base
        </a>{' '}
        or
        <button type="button" className={styles.contact} onClick={handleSupport}>
          contact us
        </button>{' '}
        to learn more about generating leads.
      </div>
    </ZeroState>
  )
}
