// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WAVAqqG4m_j3zf4j4oqcI {\n  width: 50%;\n}\n", ""]);
// Exports
exports.locals = {
	"input": "WAVAqqG4m_j3zf4j4oqcI"
};
module.exports = exports;
