// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KJPr9kgWHfQGxCQFlEKTO {\n    display: flex;\n    border: 1px solid var(--neutral-300);\n    box-sizing: border-box;\n    margin-top: 32px;\n    padding: 40px 18px 16px 50px;\n    width: 100%;\n}\n\n._3IJjmEETSWKGf8VUmG29ZA {\n    font-family: \"Nunito Sans\", sans-serif;\n}\n\n.WHcO1XjPDgRv_jiTWca8n {\n    color: var(--blue-1000);\n    font-size: 20px;\n    width: 316px;\n    font-weight: 700;\n    line-height: 28px;\n    margin: 0 80px 0 0;\n}\n\n._3ZbpawFYLYS5gdzzUb34zl {\n    color: var(--neutral-1000);\n    font-size: 18px;\n    font-weight: normal;\n    display: inline-block;\n    margin-top: 8px;\n    width: 316px;\n}\n\n._2uombeGrHhAdwHOrsViThk {\n    display: flex;\n    align-items: flex-start;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "KJPr9kgWHfQGxCQFlEKTO",
	"heading-wrapper": "_3IJjmEETSWKGf8VUmG29ZA",
	"header": "WHcO1XjPDgRv_jiTWca8n",
	"subheader": "_3ZbpawFYLYS5gdzzUb34zl",
	"content-wrapper": "_2uombeGrHhAdwHOrsViThk"
};
module.exports = exports;
