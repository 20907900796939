// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Ko0wrW6McGGLZPEFbRmzC {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    align-items: center;\n    margin-bottom: 40px;\n}\n\n._1L9h9Epja36RZHyFS0q_2X {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n}\n\n._2RKgdyXedNq_LjLZmgqWtv {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n._2EgheknXPKZ-uwue2pCQ6k {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n._2j40hHsrFMIPuJwfOHr-h0 {\n    display: flex;\n    padding: 0.5em 0.1em;\n    width: 100%;\n    border-bottom: 1px solid var(--neutral-300);\n}\n\n._2j40hHsrFMIPuJwfOHr-h0:last-child {\n    border-bottom: none;\n}\n\n._3QdHwVcByB83-BRb5lR6Mj {\n    width: calc(25% - 16px);\n}\n\n._2pQdWLX06nXvAbMkvE1F7R {\n    width: calc(75% - 16px);\n}\n\n._24AL9KGe8XegchnbFzJfhA {\n    margin-bottom: 0;\n}\n\n._5sQnFw87ClktA6r5tNAqd {\n    margin-bottom: 4px;\n}", ""]);
// Exports
exports.locals = {
	"row": "_2Ko0wrW6McGGLZPEFbRmzC",
	"user-detail-label": "_1L9h9Epja36RZHyFS0q_2X",
	"container": "_2RKgdyXedNq_LjLZmgqWtv",
	"subtitle": "_2EgheknXPKZ-uwue2pCQ6k",
	"org-row": "_2j40hHsrFMIPuJwfOHr-h0",
	"quarter-width": "_3QdHwVcByB83-BRb5lR6Mj",
	"three-quarter-width": "_2pQdWLX06nXvAbMkvE1F7R",
	"no-bottom-margin": "_24AL9KGe8XegchnbFzJfhA",
	"margin-bottom-4": "_5sQnFw87ClktA6r5tNAqd"
};
module.exports = exports;
