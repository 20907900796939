import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetAtom } from 'jotai/react'
import type {
  ColumnFiltersState,
  DataTableAction,
  DataTableMenuItem,
  PaginationState,
} from '@extend/zen'
import { DataTable } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StandardHeader } from '../../../components/standard-header'
import { useResendInvite } from '../../../hooks/use-resend-invite'
import { isAddUserModalVisibleAtom } from '../../../atoms/add-user-modal'
import { CreateUserModal } from '../../user/create-user-modal'
import { ReactivateUserModal } from '../../user/reactivate-user-modal'
import { openReactivateUserModal } from '../../../atoms/reactivate-user-modal'
import { openDeactivateUserModal } from '../../../atoms/deactivate-user-modal'
import { DeactivateUserModal } from '../../user/deactivate-user-modal'
import type { UserTableMapping } from './v3-users-table-config'
import { TABLE_COLUMNS, FILTER_DEFS } from './v3-users-table-config'
import { useAllTableUsers } from '../../../hooks/use-all-table-users'
import { LDFlag } from '../../../constants/ld-flags'
import { ReactivateUserModalR3 } from '../../user/reactivate-user-modal-r3'

const V3Users: FC = () => {
  const { [LDFlag.EnterpriseTenancyR3]: isEnterpriseR3Enabled } = useFlags()
  const { push } = useHistory()

  const { resendInvite } = useResendInvite()
  const setIsAddUserModalVisible = useSetAtom(isAddUserModalVisibleAtom)
  const openActivateModal = useSetAtom(openReactivateUserModal)
  const openDeactivateModal = useSetAtom(openDeactivateUserModal)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const queryParams = useMemo(() => {
    const params: Record<string, string | number> = {}

    columnFilters.forEach((filter) => {
      if (Array.isArray(filter.value) && filter.value.length > 0) {
        params[filter.id] = filter.value[0] as string
      } else {
        params[filter.id] = filter.value as string
      }
    })
    return { ...params }
  }, [columnFilters])

  const { tableItems, isLoading, hasNextPage } = useAllTableUsers(pagination, queryParams)

  const getRowActions = (row: UserTableMapping): DataTableAction[] => [
    {
      onClick: () => push(`/account/users/${row.uuid}`),
      text: 'View',
      emphasis: 'low',
      'data-cy': `user-table:view-${row.uuid}:btn`,
    },
  ]

  const getRowMenuItems = (row: UserTableMapping): DataTableMenuItem[] => {
    const items: DataTableMenuItem[] = []

    if (row.status === 'PENDING') {
      items.push({
        onClick: async () => {
          await resendInvite(row.email)
        },
        children: 'Resend Email Invite',
        'data-cy': 'resend-email-invite',
      })
    }

    if (row.status === 'DEACTIVATED') {
      items.push({
        onClick: () => {
          openActivateModal(row.email)
        },
        children: 'Activate',
        'data-cy': 'activate-user',
      })
    }

    if (row.status !== 'DEACTIVATED') {
      items.push({
        onClick: () => {
          openDeactivateModal(row.email, row.grants)
        },
        children: 'Deactivate',
        'data-cy': 'deactivate-user',
      })
    }

    return items
  }

  return (
    <>
      <StandardHeader
        pageTitle="Users"
        buttons={[
          {
            text: 'Add New User',
            emphasis: 'medium',
            onClick: () => setIsAddUserModalVisible(true),
          },
        ]}
      />
      <CreateUserModal />
      <DataTable
        isLoading={isLoading}
        data={tableItems}
        columns={TABLE_COLUMNS}
        getRowActions={getRowActions}
        getRowMenuItems={getRowMenuItems}
        filterDefs={FILTER_DEFS}
        data-cy="users-table"
        hasManualPagination
        pagination={pagination}
        onPaginationChange={setPagination}
        hasMoreRows={hasNextPage}
        hasManualFiltering
        onColumnFiltersChange={setColumnFilters}
        columnFilters={columnFilters}
      />
      {isEnterpriseR3Enabled ? <ReactivateUserModalR3 /> : <ReactivateUserModal />}
      <DeactivateUserModal />
    </>
  )
}

export { V3Users }
