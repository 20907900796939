import { useCallback, useMemo } from 'react'
import { useAtom, useAtomValue } from 'jotai/react'
import {
  createThemeNameAtom,
  currentlySelectedThemeAtom,
  currentlySelectedThemeIdAtom,
} from '../atoms/customize-theme'
import type { Theme } from '../queries/themes'
import { ThemePublishedStatus, useFetchAllThemesQuery, useFetchThemeQuery } from '../queries/themes'
import { getActiveStoreIdAtom } from '../atoms/stores'

type SelectedThemeResult = {
  theme: Theme | null
  isLoading: boolean
}

export function useSelectedTheme(): SelectedThemeResult {
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const { data: themesData } = useFetchAllThemesQuery({ storeId, refetchOnMount: true })

  const [createThemeName] = useAtom(createThemeNameAtom)
  const [currentTheme, setCurrentTheme] = useAtom(currentlySelectedThemeAtom)
  const [currentlySelectedThemeId] = useAtom(currentlySelectedThemeIdAtom)

  const themeToFetch = useMemo(() => {
    if (!themesData) {
      return null
    }
    const publishedTheme =
      themesData?.themes?.find((theme) => theme.status === ThemePublishedStatus.published) ?? null
    const sortedThemes =
      [...(themesData?.themes || [])].sort((a, b) => b.updatedAt - a.updatedAt) ?? []

    return currentlySelectedThemeId
      ? themesData?.themes?.find((theme) => theme.themeId === currentlySelectedThemeId)
      : publishedTheme || sortedThemes[0]
  }, [themesData, currentlySelectedThemeId])

  const handleSetCurrentTheme = useCallback(
    (theme: Theme) => {
      if (theme !== currentTheme && createThemeName === null) {
        setCurrentTheme(theme ?? null)
      }
    },
    [setCurrentTheme, currentTheme, createThemeName],
  )

  const { isInitialLoading: isLoading } = useFetchThemeQuery({
    storeId,
    themeId: themeToFetch?.themeId || '',
    enabled: Boolean(themeToFetch),
    onSuccess: handleSetCurrentTheme,
  })

  return {
    theme: currentTheme,
    isLoading,
  }
}
