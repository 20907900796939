// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".y1bcEOT7js7_09u_hnpG0 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n._2EvJePJFvAs--FJKlmeXVC {\n    display: flex;\n    align-items: center;\n    margin-bottom: 40px;\n}", ""]);
// Exports
exports.locals = {
	"line": "y1bcEOT7js7_09u_hnpG0",
	"type-line": "_2EvJePJFvAs--FJKlmeXVC"
};
module.exports = exports;
