import React, { useMemo } from 'react'
import type { FC } from 'react'
import type {
  Condition,
  ConditionTypes,
  NumericConditionWithValue,
  NumericCondition,
  NumericInvokableFunction,
  AdjudicationRuleResponse,
  ScriptItem,
  AdjudicationRuleResponseExpanded,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect, Input, Paragraph } from '@extend/zen'
import type { DefaultPrompt, DefaultReply } from '@extend-conversations/types'
import { identifyCondition } from './utils'
import { getRuleKeySelectorValue, getRuleValue } from './utils-ui'
import {
  keySelectorOptions,
  numericValueConditionComparandOptions,
} from './key-selector-options-base'

type AdjudicationRowProps = {
  rule: AdjudicationRuleResponse
  condition: Condition
  index: number
}

export const getOperatorOptions = (conditionType: ConditionTypes): AdvancedSelectOption[] => {
  const isNumericScript = conditionType === 'numericScript'
  const greaterThanText = isNumericScript ? 'Later' : 'Greater'
  const lessThanText = isNumericScript ? 'Earlier' : 'Less'
  return [
    {
      display: `${greaterThanText} than`,
      value: '>',
    },
    {
      display: `${greaterThanText} than or equal to`,
      value: '>=',
    },
    {
      display: 'Equal to',
      value: '=',
    },
    {
      display: `${lessThanText} than`,
      value: '<',
    },
    {
      display: `${lessThanText} than or equal to`,
      value: '<=',
    },
  ]
}

export const comparandOptions: (AdvancedSelectOption & { value: NumericInvokableFunction })[] = [
  {
    display: 'Claim filing date',
    value: 'claimFilingDate',
  },
  {
    display: 'Contract transaction date',
    value: 'contractTransactionDate',
  },
  {
    display: 'Contract coverage started',
    value: 'contractStartDate',
  },
  {
    display: 'Contract coverage ended',
    value: 'contractEndDate',
  },
  {
    display: 'Product is delivered',
    value: 'productDeliveryDate',
  },
]

const AdjudicationRow: FC<AdjudicationRowProps> = ({ rule, condition, index }) => {
  const conditionType = condition ? identifyCondition(condition) : null
  const activeConditionScriptItem = (rule as AdjudicationRuleResponseExpanded).conditions[index]
    .script as ScriptItem

  const keyItemOptions: AdvancedSelectOption[] = useMemo(() => {
    if (!conditionType) return []
    if (conditionType === 'numericScript') {
      return getRuleKeySelectorValue(activeConditionScriptItem).options
    }
    if (conditionType === 'numericValue') {
      return numericValueConditionComparandOptions
    }

    if (conditionType === 'nonNumericScript') {
      const { slot } = (activeConditionScriptItem.reply as DefaultReply).prompt as DefaultPrompt
      return [{ display: slot, value: slot }]
    }

    if (conditionType.includes('nonNumeric')) {
      return keySelectorOptions
    }

    return []
  }, [activeConditionScriptItem, conditionType])

  const keyItemValue = useMemo(() => {
    if (conditionType === 'numericValue') return condition.comparand as string
    return keyItemOptions?.[0]?.value || ''
  }, [conditionType, condition.comparand, keyItemOptions])

  if (!conditionType) return null

  const isNumericCondition = conditionType === 'numericScript' || conditionType === 'numericValue'
  const isLastCondition = Boolean(rule && index === rule?.conditions.length - 1)
  const isMonetaryValue = isNumericCondition && condition?.comparand === 'totalOfPurchasePrices'

  const comparandValue =
    conditionType === 'numericScript' || conditionType === 'numericValue' ? condition.comparand : ''
  const conditionOffset =
    conditionType === 'numericScript' ? (condition as NumericCondition)?.offset : null
  const conditionOffsetValue = (conditionOffset as number) > -1 ? 'after' : 'before'
  const operatorValue = (condition as NumericCondition)?.operator || ''
  const offsetValue = conditionOffset ? Math.abs(conditionOffset).toString() : ''

  const { options, value: selectorValue } = getRuleValue(condition, activeConditionScriptItem)

  return (
    <div data-cy={`adjudication-rule-item:${index}`}>
      <ConditionLine>
        <SelectorWrapper>
          <Paragraph data-cy="claim-validation-row-prefix">
            {conditionType === 'numericValue' ? 'The value of' : 'The answer of'}
          </Paragraph>
          <DropdownWrapper>
            <AdvancedSelect
              id="claim-validation-key-item"
              onChange={() => {}}
              options={keyItemOptions}
              value={keyItemValue}
              multiple={false}
              badgePosition="start"
              data-cy="claim-validation-key-item"
              isDisabled
              isNotClearable
              hideSelectedMenuItems
            />
          </DropdownWrapper>
          <Paragraph>is</Paragraph>
          {conditionType.includes('nonNumeric') && (
            <DropdownWrapper>
              <AdvancedSelect
                id="claim-validation-value-item"
                onChange={() => {}}
                options={options}
                value={selectorValue}
                multiple
                badgePosition="start"
                data-cy="claim-validation-value-item"
                isDisabled
                isNotClearable
                hideSelectedMenuItems
                willTruncateChips
              />
            </DropdownWrapper>
          )}
          {isNumericCondition && (
            <DropdownWrapper>
              <AdvancedSelect
                id="claim-validation-operator-item"
                onChange={() => {}}
                options={getOperatorOptions(conditionType)}
                value={operatorValue}
                multiple={false}
                badgePosition="start"
                data-cy="claim-validation-operator-item"
                isDisabled
                isNotClearable
                hideSelectedMenuItems
              />
            </DropdownWrapper>
          )}
        </SelectorWrapper>
      </ConditionLine>
      {isNumericCondition && (
        <ConditionLine>
          <SelectorWrapper>
            {conditionType === 'numericScript' ? (
              <>
                <Input
                  data-cy="claim-validation-offset-input"
                  id="claim-validation-offset-input"
                  onChange={() => {}}
                  value={offsetValue}
                  isDisabled
                />
                <Paragraph>days</Paragraph>
                <DropdownWrapper>
                  <AdvancedSelect
                    id="claim-validation-offset-item"
                    onChange={() => {}}
                    options={[
                      { display: 'After', value: 'after' },
                      { display: 'Before', value: 'before' },
                    ]}
                    value={conditionOffsetValue}
                    multiple={false}
                    badgePosition="start"
                    data-cy="claim-validation-offset-item"
                    isDisabled
                    isNotClearable
                    hideSelectedMenuItems
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <AdvancedSelect
                    id="adjudication-comparand-item"
                    onChange={() => {}}
                    options={comparandOptions}
                    value={comparandValue ?? ''}
                    multiple={false}
                    badgePosition="start"
                    data-cy="claim-validation-comparand-item"
                    isDisabled
                    isNotClearable
                    hideSelectedMenuItems
                  />
                </DropdownWrapper>
              </>
            ) : (
              <DropdownWrapper>
                <Input
                  data-cy="claim-validation-numeric-input"
                  id="claim-validation-numeric-input"
                  onChange={() => {}}
                  value={(condition as NumericConditionWithValue).value.toString()}
                  isDisabled
                />
                {isMonetaryValue && <Paragraph>USD</Paragraph>}
              </DropdownWrapper>
            )}
          </SelectorWrapper>
        </ConditionLine>
      )}
      {!isLastCondition && <Paragraph data-cy="adjudication-rule-separator-text">and</Paragraph>}
    </div>
  )
}

const ConditionLine = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0px',
})

const SelectorWrapper = styled.div({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  width: '100%',
})

const DropdownWrapper = styled.div({
  flex: 1,
})

export { AdjudicationRow }
