import { customLogger } from '@extend/client-helpers'

export function safeDecodeURIComponent(input: string, fallback = ''): string {
  try {
    return decodeURIComponent(input)
  } catch (err) {
    customLogger.warn(`Invalid UTF-8 Decoding for input: ${input}`)
    return fallback
  }
}

export function safeEncodeURIComponent(input: string, fallback = ''): string {
  try {
    return encodeURIComponent(input)
  } catch (err) {
    customLogger.warn(`Invalid UTF-8 Encoding for input: ${input}`)
    return fallback
  }
}
