import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import {
  Input,
  Select,
  ButtonGroup,
  Button,
  InlineAlert,
  InlineAlertColor,
  Info as InfoIcon,
} from '@extend/zen'
import { CurrencyInputGroup } from '@helloextend/merchants-ui'
import type { GiftCardServiceOrdersFulfillRequest } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useSetAtom } from 'jotai/react'
import type { GiftCardValues } from './reserve-service-order-modal-schema'
import { giftCardFormSchema } from './reserve-service-order-modal-schema'
import { useFulfillServiceOrderMutation } from '../../../queries/service-orders'
import styles from './resolve-service-order-gift-card-form.module.css'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'
import { useGetContractQuery } from '../../../queries/contract'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'

export type ResolveServiceOrderGiftCardFormProps = {
  contractId: string
  serviceOrderId: string
}

export const ResolveServiceOrderGiftCardForm: FC<ResolveServiceOrderGiftCardFormProps> = ({
  contractId,
  serviceOrderId,
}) => {
  const { data: contract } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })

  const currencyCode = contract?.currency ?? 'USD'

  const { mutateAsync: fulfillReplacement, isLoading } = useFulfillServiceOrderMutation()
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)
  const { values, errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur } =
    useFormik({
      enableReinitialize: true,
      validationSchema: giftCardFormSchema,
      initialValues: giftCardFormSchema.default(),
      onSubmit: async (formValues: GiftCardValues) => {
        const payload = { ...formValues }
        if (!formValues.miscFees?.amount) {
          delete payload.miscFees
        }

        if (!formValues.wholesaleCost?.amount) {
          delete payload.wholesaleCost
        } else if (payload.wholesaleCost) {
          payload.wholesaleCost.currencyCode = currencyCode
        }

        await fulfillReplacement({
          serviceOrderId,
          fulfillRequest: {
            ...payload,
            method: 'gift_card',
          } as GiftCardServiceOrdersFulfillRequest,
        })
        setIsResolveServiceOrderModalVisible(false)
      },
    })

  const handleSelectChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>,
  ): void => {
    const { value, id } = event.currentTarget
    setFieldValue(id, value)
  }

  return (
    <form
      className={styles.form}
      name="gift-card-form"
      aria-label="gift-card-form"
      onSubmit={handleSubmit}
    >
      <div className={styles.grid}>
        <Select
          id="provider"
          placeholder="SELECT"
          label="Gift Card Provider"
          value={values.provider || ''}
          onChange={handleSelectChange}
          isError={touched.provider && Boolean(errors.provider)}
          errorFeedback={(touched.provider && errors.provider) || ''}
        >
          <option value="merchant">Merchant</option>
          <option value="mastercard">Mastercard</option>
          <option value="visa">Visa</option>
          <option value="amex">Amex</option>
        </Select>

        <CurrencyInputGroup
          data-cy="gift-card-value"
          label="Gift Card Value"
          name="amount.amount"
          errorMessage={errors.amount?.amount}
          invalid={Boolean(errors.amount?.amount)}
          onChange={setFieldValue}
          value={values.amount?.amount}
          currencyProps={{
            codeFieldName: 'amount.currencyCode',
            codeValue: currencyCode,
          }}
          showSymbol={false}
          isCurrencyCodeDisabled
        />

        <CurrencyInputGroup
          data-cy="wholesale-cost"
          name="wholesaleCost.amount"
          label="Wholesale Cost (Optional)"
          isCurrencyCodeDisabled
          currencyProps={{
            codeFieldName: 'wholesaleCost',
            codeValue: contract?.currency ?? 'USD',
          }}
          showSymbol={false}
          onChange={setFieldValue}
          onBlur={handleBlur}
          value={values.wholesaleCost?.amount}
          invalid={touched.wholesaleCost && Boolean(errors.wholesaleCost)}
          errorMessage={touched.wholesaleCost && errors.wholesaleCost}
        />

        <CurrencyInputGroup
          data-cy="gift-card-misc-fees"
          label="Misc Fees (Optional)"
          name="miscFees.amount"
          onChange={setFieldValue}
          value={values.miscFees?.amount}
          currencyProps={{
            codeFieldName: 'miscFees.currencyCode',
            codeValue: contract?.currency ?? 'USD',
          }}
          isCurrencyCodeDisabled
        />

        <Input
          data-cy="total-expenses"
          label="Total Expense"
          onChange={() => {}}
          value={currency.formatWithoutCurrencySymbol(
            (values.amount?.amount ?? 0) + (values.miscFees?.amount ?? 0),
            currencyCode,
            true,
          )}
          id="total-expenses"
          isDisabled
        />

        <Input
          id="expenseDescription"
          label="Description (Optional)"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.expenseDescription || ''}
          maxLength={30000}
        />
      </div>

      <div className={styles['alert-wrapper']}>
        <InlineAlert
          color={InlineAlertColor.blue}
          icon={InfoIcon}
          data-cy="service-order-resolve-gift-card-alert"
          isDismissable={false}
        >
          Resolution cannot be edited once submitted. Please review before clicking
          &quot;Resolve&quot;.
        </InlineAlert>
      </div>

      <div className={styles['button-group-wrapper']}>
        <ButtonGroup isReversed>
          <Button
            type="submit"
            text="Resolve"
            data-cy="resolve-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => setIsResolveServiceOrderModalVisible(false)}
            isDisabled={isLoading}
          />
        </ButtonGroup>
      </div>
    </form>
  )
}
