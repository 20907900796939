// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".svSzlhlqHMswmiQyXV3lz {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.svSzlhlqHMswmiQyXV3lz div {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"container": "svSzlhlqHMswmiQyXV3lz"
};
module.exports = exports;
