// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._27s0e4Du35OVHeexnx2Hfl {\n    width: 208px;\n    height: 64px;\n    object-fit: contain;\n}\n\n._3njSbQhS3yiqNRlAE3Ti1Z {\n    line-height: 32px;\n    font-size: 24px;\n}\n\n._1MoAU8Gjhj3STQYeRsTNZw {\n    padding-left: 20px;\n    margin-bottom: 30px;\n    text-align: left;\n}\n\n.TsWq5cIppnRh01-JCPPG9 {\n    font-size: 20px;\n}\n\n._6MV3Ou5MwzwYVYSqOOY_v {\n    font-size: 12px;\n    padding: 0 32px;\n    margin: 0;\n}\n\n._3Mjq-0B5Ap4xW9AC1wFP4V {\n    margin: 16px 0 18px 0;\n    padding: 5px 21px;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 24px;\n    border-radius: 4px;\n}\n\n._3S5qw883aZ1z9ihi-vmDfg {\n    margin: 0;\n    font-size: 8px;\n}\n\n.zZFcL1NzMqNR13ZSvEYku {\n    height: 12px;\n}\n\n.VWv6XcdEc9tsWdWm9CwAZ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    box-sizing: border-box;\n    padding: 14px 50px 32px 50px;\n    text-align: center;\n    width: 426px;\n}\n\n._2OdPx61an957nYQUZeKlFt {\n    padding: 0 55px 32px 24px;\n    text-align: left;\n    font-size: 11px;\n}\n\n._2qOcXyUgWJD4c5Rq8ah2yJ {\n    margin-bottom: 32px;\n    font-size: 11px;\n    width: 100%;\n}\n\n._1475Qxu1BEN17XcbuTRk9z {\n    color: var(--neutral-800);\n    text-decoration: underline;\n}\n\n._3fQqraFFM9MIw98pxt5MUC {\n    margin: 0;\n    font-size: 11px;\n    line-height: 27px;\n    color: var(--neutral-800);\n}\n\n.XJYRnxaW9kdBOCAR7F1kC {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    box-sizing: border-box;\n    width: 100%;\n    margin-bottom: 8px;\n}\n\n._1GhyYfC6OvYjmnV2lDm5a0 {\n    font-size: 12px;\n}\n\n._2PbeCHaxtEg_O7hk1cBx2G {\n    box-sizing: border-box;\n    padding: 61px;\n    background-color: var(--neutral-100);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n._2PbeCHaxtEg_O7hk1cBx2G *:not(._3njSbQhS3yiqNRlAE3Ti1Z):not(.TsWq5cIppnRh01-JCPPG9) {\n    font-family: attr(data-font-family);\n}\n", ""]);
// Exports
exports.locals = {
	"logo": "_27s0e4Du35OVHeexnx2Hfl",
	"header": "_3njSbQhS3yiqNRlAE3Ti1Z",
	"mock-data": "_1MoAU8Gjhj3STQYeRsTNZw",
	"subheader": "TsWq5cIppnRh01-JCPPG9",
	"primary-copy": "_6MV3Ou5MwzwYVYSqOOY_v",
	"mock-button": "_3Mjq-0B5Ap4xW9AC1wFP4V",
	"extra-small": "_3S5qw883aZ1z9ihi-vmDfg",
	"extend": "zZFcL1NzMqNR13ZSvEYku",
	"inner": "VWv6XcdEc9tsWdWm9CwAZ",
	"whats-included": "_2OdPx61an957nYQUZeKlFt",
	"links": "_2qOcXyUgWJD4c5Rq8ah2yJ",
	"link": "_1475Qxu1BEN17XcbuTRk9z",
	"footer": "_3fQqraFFM9MIw98pxt5MUC",
	"row": "XJYRnxaW9kdBOCAR7F1kC",
	"col": "_1GhyYfC6OvYjmnV2lDm5a0",
	"container": "_2PbeCHaxtEg_O7hk1cBx2G"
};
module.exports = exports;
