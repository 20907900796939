import { useMemo } from 'react'
import type { ServiceOrderShipment } from '@helloextend/extend-api-client'
import { startCase } from 'lodash/fp'
import { date } from '@extend/client-helpers'
import { useGetServiceOrderShipmentsByServiceOrderId } from '../queries/service-orders'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { getShipmentDeliveredDate } from '../utils/get-shipment-delivered-date'

export const useFulfilledProductShipmentData = (
  serviceOrderId: string,
): { gridData: DataPropertyDetails[] } => {
  const { data: shipments } = useGetServiceOrderShipmentsByServiceOrderId({
    serviceOrderId,
  })

  const shipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'customer') ||
      ({} as ServiceOrderShipment)
    )
  }, [shipments])

  const shipmentDeliveredDate = useMemo(() => getShipmentDeliveredDate(shipment), [shipment])

  const gridData = [
    {
      label: 'Status',
      key: 'Status',
      value: shipment.status === 'received_by_carrier' ? 'In Transit' : startCase(shipment.status),
    },
    {
      label: 'Return Tracking Number',
      key: 'Return Tracking Number',
      value: shipment.trackingNumber,
    },
    {
      label: 'Return Shipment Date',
      key: 'Return Shipment Date',
      value: shipment.shippedAt ? date.format(shipment.shippedAt, date.standardDateFormat) : '',
    },
    {
      'data-cy': `${serviceOrderId}-delivered-date`,
      label: shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date',
      key: shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date',
      value: shipmentDeliveredDate,
    },
  ]

  return { gridData }
}
