import { createApi } from '@reduxjs/toolkit/query/react'
import type { Refund } from '@helloextend/extend-api-client'
import { ContractStatus } from '@helloextend/extend-api-client'
import { customLogger } from '@extend/client-helpers'
import { baseQuery } from '../base-query'
import { contractsApi } from '../contracts'

export const refundsApi = createApi({
  baseQuery,
  reducerPath: 'Refunds',
  tagTypes: ['refunds'],
  endpoints: (build) => ({
    getRefundsByContractId: build.query<Refund, string>({
      query: (contractId: string) => ({
        url: `/refunds`,
        params: { contractId },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2021-07-01;',
        },
      }),
      providesTags: (_, __err, contractId) => [{ type: 'refunds', id: contractId }],
    }),
    getRefundsByLineItemId: build.query<Refund, string>({
      query: (lineItemId: string) => ({
        url: `/refunds`,
        params: { lineItemId },
      }),
    }),
    reportRefundById: build.mutation<Refund, { contractId: string; contractsApiVersion?: string }>({
      query: ({ contractId, contractsApiVersion }) => ({
        url: `/refunds`,
        params: { contractId },
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${contractsApiVersion || '2021-07-01'};`,
        },
        body: {
          contractId,
        },
      }),
      async onQueryStarted({ contractId, contractsApiVersion }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          // once the response completes, separate backend workflows pickup
          // the created refund record and update the contract. this small delay prevents
          // an immediate query to fetch and display new details. in order to display this
          // change to the user immediately, we update the cached result of the
          // current contract details
          dispatch(
            contractsApi.util.updateQueryData(
              'getContract',
              { contractId, apiVersion: contractsApiVersion },
              (draft) => {
                const patch = {
                  ...draft,
                  status: ContractStatus.CANCELLED,
                  refundedAt: Date.now(),
                  cancelledAt: Date.now(),
                }
                Object.assign(draft, patch)
              },
            ),
          )
        } catch (ex) {
          customLogger.error('Refund failed for contract', {
            error: JSON.stringify(ex, null, '\t'),
            contractId,
          })
        }
      },
    }),
  }),
})

export const {
  useGetRefundsByContractIdQuery,
  useLazyGetRefundsByContractIdQuery,
  useGetRefundsByLineItemIdQuery,
  useReportRefundByIdMutation,
} = refundsApi

export const useGetRefundsByContractIdQueryState =
  refundsApi.endpoints.getRefundsByContractId.useQueryState
