// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zijT0E11Y-PZrvuQk3LEW {\n    width: 100%;\n    table-layout: fixed;\n    border-collapse: collapse;\n}\n\n.zijT0E11Y-PZrvuQk3LEW thead {\n    color: var(--neutral-600);\n    font-size: 14px;\n    text-align: left;\n    margin-bottom: 8px;\n}\n\n.zijT0E11Y-PZrvuQk3LEW th {\n    text-align: left;\n    padding-bottom: 8px;\n    width: initial;\n}\n\n.zijT0E11Y-PZrvuQk3LEW td {\n    font-weight: normal;\n    padding: 16px 0;\n}\n\n.zijT0E11Y-PZrvuQk3LEW tr {\n    border: none;\n    border-top: 1px solid var(--neutral-300);\n    white-space: normal;\n}\n\n.zijT0E11Y-PZrvuQk3LEW tr:first-of-type {\n    border: none;\n    white-space: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "zijT0E11Y-PZrvuQk3LEW"
};
module.exports = exports;
