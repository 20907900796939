import type { FC, SyntheticEvent } from 'react'
import React, { useState, useMemo } from 'react'
import { Button, COLOR } from '@extend/zen'
import { ChevronLeft, ChevronRight } from '@extend/zen'
import styled from '@emotion/styled'

type DotPaginationProps = {
  onChange: (x: number) => void
  numOfDots: number
  startingIndex?: number
  'data-cy'?: string
}

const DotPagination: FC<DotPaginationProps> = ({
  onChange,
  numOfDots,
  startingIndex = 0,
  'data-cy': dataCy,
}) => {
  const startIndex = useMemo(() => {
    if (startingIndex < 0 || startingIndex >= numOfDots) {
      return 0
    }
    return startingIndex
  }, [startingIndex, numOfDots])

  const [selectedIndex, setSelectedIndex] = useState(startIndex)

  const handleClickPrevious = (): void => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1
      setSelectedIndex(prevIndex)
      onChange(prevIndex)
    }
  }

  const handleClickNext = (): void => {
    if (numOfDots - 1 > selectedIndex) {
      const nextIndex = selectedIndex + 1
      setSelectedIndex(nextIndex)
      onChange(nextIndex)
    }
  }

  const handleClickDot = (e: SyntheticEvent): void => {
    const target = e.target as HTMLButtonElement
    const index = Number(target.value)
    setSelectedIndex(index)
    onChange(index)
  }

  return (
    <Container>
      <Button
        icon={ChevronLeft}
        emphasis="medium"
        color="neutral"
        onClick={handleClickPrevious}
        data-cy={`${dataCy ? dataCy.concat(':') : ''}dot-pagination:previous`}
      />
      <DotContainer>
        {Array.from({ length: numOfDots }, (_, i) => ({
          id: i,
        })).map((item, i) => {
          const isActive = i === selectedIndex
          return (
            <Dot
              key={item.id}
              value={i}
              onClick={handleClickDot}
              isActive={isActive}
              data-cy={`${dataCy ? dataCy.concat(':') : ''}dot-pagination:dot`}
            />
          )
        })}
      </DotContainer>
      <Button
        icon={ChevronRight}
        emphasis="medium"
        color="neutral"
        onClick={handleClickNext}
        data-cy={`${dataCy ? dataCy.concat(':') : ''}dot-pagination:next`}
      />
    </Container>
  )
}

const DotContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
})

const Dot = styled.button<{ isActive: boolean }>(({ isActive }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  border: 'none',
  padding: '0px 0px',
  backgroundColor: isActive ? COLOR.NEUTRAL[700] : COLOR.NEUTRAL[300],
  '&:hover': {
    cursor: 'pointer',
  },
}))

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
})

export type { DotPaginationProps }
export { DotPagination }
