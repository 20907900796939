import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { ZeroState } from '@helloextend/merchants-ui'
import { Redirect, Route, Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ANALYTICS_PRODUCTS_REPORT, ANALYTICS_SHIPPING_REPORT } from '@helloextend/client-constants'
import type { CustomTableauReport } from '@helloextend/extend-api-client'
import { useAtomValue } from 'jotai/react'
import { MerchantReport } from './merchant-report'
import { TabBar } from '../../../components/tab-bar'
import {
  getAnalyticsShippingProtectionUrl,
  getAnalyticsUrl,
  getCustomReportPath,
} from '../../../utils/route-helper'
import { tableBarLinks } from './tab-links'
import { useTableauAnalyticsScript } from '../../../hooks/use-tableau-analytics-script'
import { getActiveStoreAtom } from '../../../atoms/stores'

const TableauAnalytics: FC = () => {
  const history = useHistory()

  const activeStore = useAtomValue(getActiveStoreAtom)
  const storeId = activeStore?.id || ''
  const isProductProtectionApproved = activeStore?.approved || false
  const isShippingProtectionApproved = activeStore?.shippingProtection?.approved || false
  const shouldDisplayTabBar = isProductProtectionApproved || isShippingProtectionApproved
  const onlyShippingProtectionApproved =
    !isProductProtectionApproved && isShippingProtectionApproved
  const isScriptLoaded = useTableauAnalyticsScript()

  useEffect(() => {
    if (onlyShippingProtectionApproved) {
      history.push(getAnalyticsShippingProtectionUrl())
    }
  }, [onlyShippingProtectionApproved, history])

  const customReportsData = activeStore?.customReports || []

  const customReports = customReportsData.filter(
    (report) => report.title && report.url,
  ) as CustomTableauReport[]

  const reportRoutes = useMemo(
    () =>
      customReports.map((report) => (
        <Route key={report.title} exact path={getCustomReportPath(report.title)}>
          <MerchantReport storeId={storeId} reportId={encodeURIComponent(report.url)} />
        </Route>
      )),
    [customReports, storeId],
  )

  if (!shouldDisplayTabBar) {
    return (
      <>
        <ZeroState
          title="No analytics yet"
          text="View your product protection and shipping protection business performance here once your store has been approved by Extend."
          data-cy="analytics-zero-state:message-container"
          data-testid="analytics-zero-state:message-container"
        />
      </>
    )
  }

  return isScriptLoaded ? (
    <>
      <TabBar
        tabBarLinks={tableBarLinks({
          isProductProtectionApproved,
          isShippingProtectionApproved,
          customTableauReports: customReports,
        })}
        isAnalytics
      />
      <Switch>
        <Route exact path={getAnalyticsUrl()}>
          <MerchantReport
            storeId={storeId}
            reportId={encodeURIComponent(ANALYTICS_PRODUCTS_REPORT)}
          />
        </Route>
        <Route exact path={getAnalyticsShippingProtectionUrl()}>
          <MerchantReport
            storeId={storeId}
            reportId={encodeURIComponent(ANALYTICS_SHIPPING_REPORT)}
          />
        </Route>
        {reportRoutes}

        <Redirect to="/404" />
      </Switch>
    </>
  ) : null
}

export { TableauAnalytics }
