import React from 'react'
import type { Claim } from '@helloextend/extend-api-client'
import { CopyToClipboardButton } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'

export function getTitleText(claim: Claim): string {
  if (claim?.photoRequirements?.length) {
    return 'Thank you! This claim requires photos in order to complete the submission.'
  }

  if (claim.status === 'approved') {
    return 'This claim is approved! 🎉'
  }

  if (claim.status === 'denied') {
    return 'This claim is denied.'
  }
  return 'Thank you! This claim is in review.'
}

export function getFirstListItemText(claim: Claim, storeName: string): JSX.Element {
  const { email } = claim.customer
  const isShippingProtectionClaim = claim.type === 'shipping_protection'

  if (claim?.photoRequirements?.length) {
    return (
      <p>
        You must upload photos in the next 30 days in order to submit your claim and receive a
        decision. You will receive email reminders at <b>{email}</b> with a link to MyExtend to
        upload.
      </p>
    )
  }

  if (claim.status === 'approved') {
    return (
      <p>
        In a few minutes, the customer will receive an email with next steps from Extend at{' '}
        <b>{email}</b>.
      </p>
    )
  }

  if (claim.status === 'denied') {
    return isShippingProtectionClaim ? (
      <p>
        Extend will send a notification to the customer at <b>{email}</b> in the next 1-2 business
        days.
      </p>
    ) : (
      <p>
        In a few minutes, the customer will receive an email with next steps from Extend at{' '}
        <b>{email}</b>.
      </p>
    )
  }

  return isShippingProtectionClaim ? (
    <p>
      Extend will send a notification to the customer at <b>{email}</b> in the next 1-2 business
      days.
    </p>
  ) : (
    <p>
      If Extend owns claim fulfillment, Extend will review this claim in the next 1-2 business days
      and send next steps to the customer at {email}. If {storeName} owns claim fulfillment,{' '}
      {storeName} will review this claim and connect with the customer on the next steps in the next
      1-2 business days.
    </p>
  )
}

export function getSecondListItemText(claim: Claim, storeName: string): JSX.Element {
  const isApprovedShippingProtectionClaim =
    claim.type === 'shipping_protection' && claim.status === 'approved'

  if (claim?.photoRequirements?.length) {
    return (
      <StyledDiv>
        <p>
          If you do not upload in 30 days, the claim will automatically be closed. Your Claim ID is:
        </p>
        <p>
          <b>Claim ID:</b> {claim.id}{' '}
        </p>
      </StyledDiv>
    )
  }

  if (claim?.status === 'review') {
    return (
      <StyledDiv>
        <p>
          If Extend owns claim fulfillment, the email will include details on the next steps for
          this claim. If {storeName} owns claim fulfillment, instruct the customer to submit photos
          to help move forward with this claim.{' '}
        </p>
        <p>
          <b>Claim ID:</b> {claim.id}{' '}
        </p>
      </StyledDiv>
    )
  }

  return (
    <div>
      <p>
        {isApprovedShippingProtectionClaim
          ? 'This email will include details on how to fulfill this claim, along with a confirmation and their claim ID for tracking.'
          : 'This email will include details on the next steps for this claim.'}
      </p>
      <p>
        <b>Claim ID:</b> {claim.id}{' '}
        <CopyToClipboardButton label="Claim ID" value={claim.id} data-cy="claim-id" />
      </p>
    </div>
  )
}

export function getThirdListItemText(claim: Claim, storeName: string): JSX.Element {
  const isDeniedShippingProtectionClaim =
    claim.type === 'shipping_protection' && claim.status === 'denied'

  if (claim?.photoRequirements?.length) {
    return (
      <p>
        You can also access MyExtend to upload your photos by going to{' '}
        <a href="https://customers.extend.com">customers.extend.com</a> and clicking on the Claims
        tab.
      </p>
    )
  }

  if (claim?.status === 'review') {
    return (
      <p>
        If Extend owns claim fulfillment, the customer can contact Extend online if they need
        additional support at{' '}
        <a href="https://customers.extend.com/contact" target="_blank" rel="noreferrer">
          customers.extend.com/contact
        </a>
        . Otherwise, if {storeName} owns claim fulfillment, the customer may reach out directly for
        additional support.
      </p>
    )
  }

  return (
    <p>
      If the customer does not get an email, or needs additional support, they can contact Extend
      online at{' '}
      <a href="https://customers.extend.com/contact" target="_blank" rel="noreferrer">
        customers.extend.com/contact
      </a>
      .
      {isDeniedShippingProtectionClaim &&
        ' For more information, refer to the terms of the protection plan.'}
    </p>
  )
}

export function getClaimResultMessaging(
  claim: Claim,
  storeName = '',
): {
  headerText: string
  titleText: string
  firstListItemText: JSX.Element
  secondListItemText: JSX.Element
  thirdListItemText: JSX.Element
} {
  return {
    headerText: 'Claim Submitted',
    titleText: getTitleText(claim),
    firstListItemText: getFirstListItemText(claim, storeName),
    secondListItemText: getSecondListItemText(claim, storeName),
    thirdListItemText: getThirdListItemText(claim, storeName),
  }
}

const StyledDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})
