import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import { validate } from '@extend/client-helpers'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect, Button, Input, TextArea } from '@extend/zen'
import { customLogger } from '@extend/client-helpers'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { getActiveStoreAtom } from '../../atoms/stores'
import {
  shouldShowSupportPopoverContactFormAtom,
  supportPopoverContactFormDefaultsAtom,
} from '../../atoms/support'
import { useCreateSupportMutation } from '../../queries/support'
import type { StorePlatform } from '../../utils/store-options'
import {
  getZendeskStoreValue,
  MERCHANT_CONTACT_METHOD,
  SUPPORT_REQUEST_OPTIONS_APPROVED_MERCHANT,
  SUPPORT_REQUEST_OPTIONS_UNAPPROVED_MERCHANT,
} from '../../utils/support'
import type { SupportSchemaValues } from './schema'
import { supportSchema } from './schema'
import styles from './support-contact-form.module.css'

interface SupportFormProps {
  name: string
  email: string
  onSubmit: (supportFlag: boolean) => void
}

export const SupportContactForm: FC<SupportFormProps> = ({ name, email, onSubmit }) => {
  const { mutateAsync: createSupport, isLoading: isSupportLoading } = useCreateSupportMutation()
  const activeStore = useAtomValue(getActiveStoreAtom)
  const domain = activeStore?.domain || null
  const platform = activeStore?.platform || null
  const { message, requestType } = useAtomValue(supportPopoverContactFormDefaultsAtom)
  const setShouldShowContactForm = useSetAtom(shouldShowSupportPopoverContactFormAtom)

  const handleBack = useCallback(() => {
    setShouldShowContactForm(false)
  }, [setShouldShowContactForm])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: supportSchema,
    initialValues: {
      name,
      email,
      phoneNumber: '',
      storeDomainName: domain || '',
      message: message || '',
      requestType: requestType || '',
    },
    onSubmit: async (vals: SupportSchemaValues): Promise<void> => {
      const body = {
        name: vals.name,
        email: vals.email,
        phoneNumber: vals.phoneNumber,
        storeDomainName: vals.storeDomainName,
        requestType: vals.requestType,
        merchantPlatform: getZendeskStoreValue(platform as StorePlatform),
        merchantContactMethod: MERCHANT_CONTACT_METHOD,
        message: vals.message,
        createdAt: new Date().getTime(),
        storeId: activeStore?.id || '',
        merchantName: activeStore?.name || '',
      }

      try {
        await createSupport({ body })
        onSubmit(true)
      } catch (err) {
        if (err instanceof Error) {
          customLogger.error(err.message, { stack: err.stack })
        }
        onSubmit(false)
      }
    },
  })

  const formattedNumber =
    formik.touched.phoneNumber && formik.errors.phoneNumber
      ? validate.removeNonNumsFromPhone(formik.values?.phoneNumber ?? '')
      : validate.formatPhoneNumber(formik.values?.phoneNumber ?? '')

  const handleChangeDropdown = (e: AdvancedSelectChangeEvent<string | string[]>): void => {
    const { value } = e.target
    const requestTypeOptions = [
      ...SUPPORT_REQUEST_OPTIONS_APPROVED_MERCHANT,
      ...SUPPORT_REQUEST_OPTIONS_UNAPPROVED_MERCHANT,
    ].find((option) => option.value === value)
    formik.setFieldValue('requestType', requestTypeOptions?.value)
  }

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <Input
        label="Name"
        id="name"
        value={formik.values.name}
        isError={Boolean(formik.touched.name && formik.errors.name)}
        errorFeedback={formik.errors.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Input
        label="Email"
        id="email"
        value={formik.values.email}
        errorFeedback={formik.errors.email}
        isError={Boolean(formik.touched.email && formik.errors.email)}
        isDisabled={false}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="ContactInfo@gmail.com"
      />
      <Input
        label="Phone Number (Optional)"
        id="phoneNumber"
        errorFeedback={formik.errors.phoneNumber}
        value={formattedNumber}
        isDisabled={false}
        isError={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="(408) 123-4567"
      />
      <Input
        label="Store Domain Name"
        errorFeedback={formik.errors.storeDomainName}
        id="storeDomainName"
        value={formik.values.storeDomainName}
        isError={Boolean(formik.touched.storeDomainName && formik.errors.storeDomainName)}
        isDisabled={false}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <AdvancedSelect
        label="Type of Request"
        onChange={handleChangeDropdown}
        id="requestType"
        options={
          activeStore?.approved || Boolean(activeStore?.shippingProtection?.approved)
            ? SUPPORT_REQUEST_OPTIONS_APPROVED_MERCHANT
            : SUPPORT_REQUEST_OPTIONS_UNAPPROVED_MERCHANT
        }
        placeholder=" Select "
        value={formik.values.requestType}
        isError={Boolean(formik.errors.requestType && formik.errors.requestType)}
        errorFeedback={formik.errors.requestType ?? ''}
        multiple={false}
      />
      <TextArea
        label="How can we help you?"
        id="message"
        value={formik.values.message}
        errorFeedback={formik.errors.message}
        isError={Boolean(formik.touched.message && formik.errors.message)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div className={styles.buttons}>
        <Button fillContainer onClick={handleBack} text="Back" emphasis="medium" size="small" />
        <Button
          fillContainer
          type="submit"
          isProcessing={isSupportLoading}
          text="Send"
          size="small"
        />
      </div>
    </form>
  )
}
