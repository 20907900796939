import { StoreUserRole } from '@helloextend/extend-api-client'
import { faker } from '@faker-js/faker/locale/en'
import * as jwt from '@helloextend/jwt'
import type { ExpiresIn } from '@helloextend/jwt'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export interface ExtendApiPrivateTokenClaims {
  email?: string
  firstName?: string
  lastName?: string
  accountId?: string
  activatedAt?: number | null
  domain?: string
  contractId?: string
  role?: string
  storeIds?: string[]
}

type AccessTokenOverrides = {
  user: {
    aud?: string
    iss?: string
    scope?: string
    email?: string
    firstName?: string
    lastName?: string
    role?: string
    accountId?: string
  }
  secret?: string
  expiresIn?: string
  sub?: string
}

const defaults = {
  user: {
    email: faker.internet.exampleEmail(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    accountId: uuid(),
  },
  secret: 'TEST',
  expiresIn: '3m' as ExpiresIn, // sets default token expiry to 3 minutes, which should be enough for most tests.
  sub: uuid(),
}

export const generateAccessToken = (overrides: Partial<AccessTokenOverrides> = {}): string => {
  const { user, secret, expiresIn, sub } = _.merge(defaults, overrides)
  return jwt.sign(
    {
      ...user,
      jti: uuid(),
    },
    secret,
    {
      expiresIn,
      subject: sub,
    },
  )
}

export const generateUserAccessToken = (overrides: Partial<AccessTokenOverrides> = {}): string =>
  generateAccessToken(_.merge({ user: { role: StoreUserRole.user } }, overrides))

export const generateMerchantAgentAccessToken = (
  overrides: Partial<AccessTokenOverrides> = {},
): string =>
  generateAccessToken(_.merge({ user: { role: StoreUserRole.merchantagent } }, overrides))
