import { COLOR } from '@extend/zen'
import type { FC } from 'react'
import React, { memo } from 'react'
import images from '../../../images'
import { getFontFamilyString } from '../../../utils/get-font-family-string'
import { BulletIcon } from './bullet-icon'
import { MockData } from './mock-data'
import { MockProductImage } from './mock-product-image'
import {
  EXTEND_ADDRESS,
  EXTEND_PHONE_NUMBER,
  EXTEND_PRIVACY_POLICY_URL,
  EXTEND_TERMS_AND_CONDITIONS_URL,
} from '../../../constants/const'
import styles from './lead-email-preview.module.css'

export interface LeadEmailPreviewProps {
  storeName: string
  logoSrc?: string
  buttonColor?: string
  buttonTextColor?: string
  lightBackgroundColor?: string
  headlineFont?: string
  bodyFont?: string
  merchantAddress?: string
  merchantPhone?: string
  privacyPolicyUrl?: string
  emailType: string
}

export const LeadEmailPreview: FC<LeadEmailPreviewProps> = memo(
  ({
    storeName,
    logoSrc = images.extendLogo,
    buttonColor = COLOR.BLUE[800],
    buttonTextColor = COLOR.WHITE,
    lightBackgroundColor = COLOR.NEUTRAL[100],
    headlineFont = 'Nunito Sans',
    bodyFont = 'Nunito Sans',
    merchantAddress,
    merchantPhone,
    privacyPolicyUrl,
    emailType,
  }) => {
    const headlineFontFamily = getFontFamilyString(headlineFont)
    const bodyFontFamily = getFontFamilyString(bodyFont)
    const year = new Date().getFullYear()
    const merchantContact = [merchantAddress, merchantPhone].filter((t) => !!t?.trim()).join(' | ')
    const isTransactionalEmail = emailType === 'transactional'

    return (
      <div className={styles.container} data-font-family={bodyFontFamily}>
        <div className={styles.inner} style={{ backgroundColor: COLOR.WHITE }}>
          <img className={styles.logo} alt="Logo" src={logoSrc} />
          <h1 data-font-family={headlineFontFamily}>
            Your recent purchase with
            <br />
            {storeName}
          </h1>
          <div className={styles['mock-data']}>
            <div className={styles.row} style={{ gap: 30 }}>
              <div className={styles.col}>
                <MockProductImage />
              </div>
              <div className={styles.col}>
                <div className={styles.row} style={{ gap: 8 }}>
                  <div className={styles.col}>
                    <MockData />
                    <br />
                    Product Name
                  </div>
                </div>
                <div className={styles.row} style={{ gap: 8 }}>
                  <div className={styles.col} style={{ width: 78 }}>
                    <MockData />
                    <br />
                    Quantity
                  </div>
                  <div className={styles.col}>
                    <MockData />
                    <br />
                    Purchase Date
                  </div>
                </div>
                <div className={styles.row} style={{ gap: 8 }}>
                  <div className={styles.col} style={{ width: 78 }}>
                    <MockData />
                    <br />
                    Price
                  </div>
                  <div className={styles.col}>
                    <MockData />
                    <br />
                    Warranty Expiration
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['primary-copy']}>
            Extend your manufacturer&apos;s warranty by adding additional protection.
          </div>
          <div
            className={styles['mock-button']}
            data-cy="preview-add-plan-btn"
            style={{
              backgroundColor: buttonColor,
              color: buttonTextColor,
            }}
          >
            Add plan for $99.99
          </div>
          <p className={styles['extra-small']}>
            powered by <img className={styles.extend} alt="Extend" src={images.extendLogo} />
          </p>
        </div>
        <div
          className={styles.inner}
          data-cy="preview-bottom-section"
          style={{ backgroundColor: lightBackgroundColor }}
        >
          <h2 className={styles.subheader} data-font-family={headlineFontFamily}>
            What&apos;s Included
          </h2>
          <div className={styles['whats-included']}>
            <div className={styles.row} style={{ gap: 18 }}>
              <div className={styles.col}>
                <BulletIcon />
              </div>
              <div className={styles.col}>
                Online claims (most are processed in 90 seconds or less!)
              </div>
            </div>
            <div className={styles.row} style={{ gap: 18 }}>
              <div className={styles.col}>
                <BulletIcon />
              </div>
              <div className={styles.col}>Simple replacements and/or repairs</div>
            </div>
            <div className={styles.row} style={{ gap: 18 }}>
              <div className={styles.col}>
                <BulletIcon />
              </div>
              <div className={styles.col}>Zero deductible</div>
            </div>
          </div>
          <div className={styles.links}>
            <div className={styles.row} data-cy="privacy-and-terms-links">
              <div className={styles.col} style={{ width: privacyPolicyUrl ? '50%' : '100%' }}>
                <a className={styles.link} href={EXTEND_TERMS_AND_CONDITIONS_URL}>
                  Terms &amp; Conditions
                </a>
              </div>
              {privacyPolicyUrl && isTransactionalEmail && (
                <div className={styles.col} style={{ width: '50%' }}>
                  <a className={styles.link} href={privacyPolicyUrl}>
                    Privacy Policy
                  </a>
                </div>
              )}
              {!isTransactionalEmail && (
                <div className={styles.col} style={{ width: '50%' }}>
                  <a className={styles.link} href={EXTEND_PRIVACY_POLICY_URL}>
                    Privacy Policy
                  </a>
                </div>
              )}
            </div>
          </div>
          <p className={styles.footer}>
            {isTransactionalEmail ? `©${storeName} ${year}` : `©Extend ${year}`}
            {merchantContact && isTransactionalEmail && (
              <>
                <br />
                {merchantContact}
              </>
            )}
            {!isTransactionalEmail && (
              <>
                <br />
                {`${EXTEND_ADDRESS} | ${EXTEND_PHONE_NUMBER}`}
              </>
            )}
          </p>
        </div>
      </div>
    )
  },
)
