import type { WarrantyStatus } from '@helloextend/extend-api-client'

const WARRANTY_STATUS_MAP_DISPLAY: Record<WarrantyStatus, string> = {
  pending: 'Pending',
  warrantable: 'Matched',
  'non-warrantable': 'Non-warrantable',
  review_required: 'Pending',
}

const WARRANTY_STATUS_MAP_DESCRIPTION: Record<WarrantyStatus, string> = {
  pending:
    'The product does not yet have an associated Extend Warranty Plan that can be sold in the webstore.',
  warrantable: 'An Extend Warranty Plan can be sold for this product.',
  'non-warrantable': 'An Extend Warranty Plan cannot be sold for this product.',
  review_required:
    'The product does not yet have an associated Extend Warranty Plan that can be sold in the webstore.',
}

export { WARRANTY_STATUS_MAP_DISPLAY, WARRANTY_STATUS_MAP_DESCRIPTION }
