// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1srpRmlSN9_9lzT4KKxk6h {\n    color: var(--neutral-1000);\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--neutral-100);\n}\n\n._1srpRmlSN9_9lzT4KKxk6h * {\n    box-sizing: border-box;\n}\n\n._2HFzzREw6jGkgbs_pnZcRK {\n    display: inline-block;\n    font-size: 14px;\n    background-color: transparent;\n    border-bottom: 1px solid transparent;\n    color: var(--blue-800);\n    cursor: pointer;\n    transition: border .25s ease-in;\n}\n\n._2HFzzREw6jGkgbs_pnZcRK:hover {\n    border-bottom: 1px solid var(--blue-800);\n}\n\n._2HFzzREw6jGkgbs_pnZcRK:visited {\n    color: var(--blue-800);\n}\n\n._2NHVklBfF6FqFNEe-cbCwZ {\n    width: 436px;\n    border-radius: 12px;\n    max-width: 100%;\n    padding: 40px;\n    background-color: var(--white);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    box-shadow: 0 0 40px 0 rgb(163 163 163 / 8%);\n}\n\n._37OHp9AuAA_ZXM1hZdYQUQ {\n    width: 104px;\n    height: 24px;\n}\n\n._1uvxlsU3-jiAMF3UW1QpJG {\n    font-family: \"Nunito Sans\", Helvetica, sans-serif;\n    font-size: 14px;\n    padding: 24px 0 0;\n    text-align: center;\n    margin: 0 auto;\n    color: var(--neutral-1000);\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_1srpRmlSN9_9lzT4KKxk6h",
	"button-text": "_2HFzzREw6jGkgbs_pnZcRK",
	"card": "_2NHVklBfF6FqFNEe-cbCwZ",
	"logo": "_37OHp9AuAA_ZXM1hZdYQUQ",
	"footer": "_1uvxlsU3-jiAMF3UW1QpJG"
};
module.exports = exports;
