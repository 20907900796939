import type { FC } from 'react'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getLeadsSearchUrl } from '../../utils/route-helper'
import { DownloadCSVData } from '../../components/download-csv-data'
import { useLazyDownloadLeadsQuery } from '../../queries/leads'

const LeadsDownload: FC = () => {
  const { search } = useLocation()
  const { push } = useHistory()
  const urlSearchParams = new URLSearchParams(search)
  const expiry = parseInt(urlSearchParams.get('expires') || '', 10) || null
  const { isRefetching, refetch } = useLazyDownloadLeadsQuery(urlSearchParams.get('keyName') || '')

  const isExpired = expiry === null || Date.now() > expiry

  const handleDownload = async (): Promise<void> => {
    try {
      const response = await refetch()
      if (response.data) {
        window.open(response.data, '_blank')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleDismissClick = (): void => {
    push(getLeadsSearchUrl())
  }

  return (
    <div>
      <h2>Download Leads Data</h2>
      <DownloadCSVData
        downloadDataType="Leads"
        isExpired={isExpired}
        handleDismissClick={handleDismissClick}
        isProcessing={isRefetching}
        onDownloadClick={handleDownload}
      />
    </div>
  )
}

export { LeadsDownload }
