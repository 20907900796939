import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSetAtom } from 'jotai/react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import type { ButtonOrLinkButtonProps } from '@extend/zen'
import { Badge, Box, DataProperty, Edit, Label, LinkButton } from '@extend/zen'
import { LDFlag } from '../../../constants/ld-flags'
import { StandardHeader } from '../../../components/standard-header'
import { STATUS_MAP } from '../../../utils/okta-to-extend-status-utils'
import { FullLoader } from '../../../components/full-loader'
import { useResendInvite } from '../../../hooks/use-resend-invite'
import { openReactivateUserModal } from '../../../atoms/reactivate-user-modal'
import { openDeactivateUserModal } from '../../../atoms/deactivate-user-modal'
import { ReactivateUserModal } from '../../user/reactivate-user-modal'
import { DeactivateUserModal } from '../../user/deactivate-user-modal'
import type { UserRoleV3 } from '../../../utils/get-user-roles'
import { UserRoleMappingV3 } from '../../../utils/get-user-roles'
import { useV3UserInfo } from '../../user/use-v3-user-info'
import { V3UserEditForm } from './v3-user-edit-form'
import { V3UserEditFormR3 } from './v3-user-edit-form-r3'
import { ReactivateUserModalR3 } from '../../user/reactivate-user-modal-r3'
import styles from './v3-user.module.css'

export const V3User: FC = () => {
  const { [LDFlag.EnterpriseTenancyR3]: isEnterpriseR3Enabled } = useFlags()
  const { uuid } = useParams<{ uuid: string }>()
  const { path, url } = useRouteMatch()

  const { [LDFlag.EnterpriseTenancyR3]: FF_R3_ORGANIZATIONS } = useFlags()

  const {
    data,
    isLoading,
    status: mappedStatus,
    accountFilteredGrantsForDeactivate,
    userRoleLabels,
    badgeText,
    badgeColor,
    roleAssignments,
  } = useV3UserInfo(uuid)
  const { resendInvite, isLoading: isResendLoading } = useResendInvite()

  const openActivateModal = useSetAtom(openReactivateUserModal)
  const openDeactivateModal = useSetAtom(openDeactivateUserModal)

  const displayRoleAssignments = FF_R3_ORGANIZATIONS && mappedStatus !== 'DEACTIVATED'

  const headerButtons = useMemo(() => {
    const buttons: ButtonOrLinkButtonProps[] = []
    if (mappedStatus === 'PENDING') {
      buttons.push({
        text: 'Resend email invite',
        emphasis: 'medium',
        onClick: async () => {
          await resendInvite(data?.email || '')
        },
        isProcessing: isResendLoading,
      })
    }

    if (mappedStatus === 'DEACTIVATED') {
      buttons.push({
        text: 'Activate User',
        emphasis: 'medium',
        onClick: () => {
          openActivateModal(data?.email || '')
        },
        isProcessing: isResendLoading,
      })
    }

    if (mappedStatus !== 'DEACTIVATED') {
      buttons.push({
        text: 'Deactivate User',
        emphasis: 'medium',
        color: 'red',
        onClick: () => {
          openDeactivateModal(data?.email || '', accountFilteredGrantsForDeactivate)
        },
      })
    }

    return buttons
  }, [
    data?.email,
    isResendLoading,
    resendInvite,
    openActivateModal,
    openDeactivateModal,
    mappedStatus,
    accountFilteredGrantsForDeactivate,
  ])

  if (isLoading) {
    return <FullLoader />
  }

  const renderRoleAssignments = (): React.ReactNode => {
    if (!roleAssignments) return null
    const hasRoleAssignments = Object.entries(roleAssignments).length

    if (!hasRoleAssignments) {
      return (
        <div className={styles.row}>
          <div className={styles.property} style={{ width: 'calc(25% - 16px)', marginBottom: '0' }}>
            <DataProperty value="" />
          </div>
          <div className={styles.property} style={{ width: 'calc(75% - 16px)', marginBottom: '0' }}>
            <DataProperty value="" />
          </div>
        </div>
      )
    }

    return Object.entries(roleAssignments).map(([key, value]) => {
      return (
        <div className={styles.row} key={key}>
          <div className={styles.property} style={{ width: 'calc(25% - 16px)', marginBottom: '0' }}>
            <DataProperty value={UserRoleMappingV3[key as UserRoleV3]} />
          </div>
          <div className={styles.property} style={{ width: 'calc(75% - 16px)', marginBottom: '0' }}>
            <DataProperty value={value.join(', ') ?? '-'} />
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <StandardHeader
        pageTitle={`${data?.firstName} ${data?.lastName}`}
        crumbs={[
          {
            text: 'Users',
            to: '/account/users',
          },
          {
            text: `${data?.firstName} ${data?.lastName}`,
          },
        ]}
        pageTitleBadge={{
          badgeText,
          badgeColor,
          badgeDescriptions: STATUS_MAP,
        }}
        buttons={headerButtons}
      />
      <div className={styles.wrapper}>
        <Box padding={5}>
          <Switch>
            <Route exact path={path}>
              <section className={styles['title-row']}>
                <div
                  className="flex"
                  style={{
                    gap: 8,
                  }}
                >
                  <h1 className={styles.title}>User Details</h1>
                  {data?.federated && <Badge text="Federated" color="neutral" />}
                </div>
                <LinkButton
                  text="Edit"
                  size="small"
                  emphasis="low"
                  icon={Edit}
                  to={`${url}/edit`}
                />
              </section>
              {data ? (
                <div className="flex flex-wrap width-100">
                  <div className={styles.property} style={{ width: 'calc(25% - 16px)' }}>
                    <DataProperty data-cy="firstName" label="First Name" value={data.firstName} />
                  </div>
                  <div className={styles.property} style={{ width: 'calc(25% - 16px)' }}>
                    <DataProperty data-cy="lastName" label="Last Name" value={data.lastName} />
                  </div>
                  <div className={styles.property} style={{ width: 'calc(50% - 16px)' }}>
                    <DataProperty label="Email" value={data.email} />
                  </div>
                  {displayRoleAssignments ? (
                    <>
                      <h4 className={styles.subtitle}>Role Assignments</h4>
                      <div className="flex flex-wrap width-100">
                        <div
                          className={styles.property}
                          style={{ width: 'calc(25% - 16px)', marginBottom: '4px' }}
                        >
                          <Label
                            isMuted
                            helperText="The role this user is assigned in one or more organizations"
                          >
                            Role
                          </Label>
                        </div>
                        <div
                          className={styles.property}
                          style={{ width: 'calc(75% - 16px)', marginBottom: '4px' }}
                        >
                          <Label
                            isMuted
                            helperText="The organization(s) in which this user performs their role"
                          >
                            Organization(s)
                          </Label>
                        </div>
                        {renderRoleAssignments()}
                      </div>
                    </>
                  ) : (
                    <div className={styles.property} style={{ width: 'calc(100% - 16px)' }}>
                      <DataProperty label="Role(s)" value={userRoleLabels.join(', ')} />
                    </div>
                  )}
                </div>
              ) : null}
            </Route>
            <Route path={`${path}/edit`}>
              {FF_R3_ORGANIZATIONS ? <V3UserEditFormR3 /> : <V3UserEditForm />}
            </Route>
          </Switch>
        </Box>
      </div>
      {isEnterpriseR3Enabled ? <ReactivateUserModalR3 /> : <ReactivateUserModal />}
      <DeactivateUserModal />
    </>
  )
}
