import type { PostSendTestEmailRequest } from '@helloextend/extend-api-client/src/models/messaging'

const generateTestEmailBody = (value: string, storeId: string): PostSendTestEmailRequest => {
  return {
    message: {
      name: 'leads-created-test',
    },
    to: [
      {
        address: {
          type: 'email',
          value,
        },
      },
    ],
    data: {
      storeId,
    },
  }
}

export { generateTestEmailBody }
