// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1acjigxpEsRr-8NOAveSdn {\n    z-index: -1;\n    border-bottom: 1px solid var(--neutral-300);\n    margin-bottom: 40px;\n}\n\n._2UkUtI0PpfOFkTbcTPq5Gz {\n    list-style-type: none;\n    display: flex;\n    justify-content: flex-start;\n    padding: 0;\n    margin: 0;\n    flex-wrap: wrap;\n}\n\n._1uQ86jiz7p8K5evkTSGnus {\n    display: inline-flex;\n}\n\n._1uQ86jiz7p8K5evkTSGnus a {\n    margin-left: 24px;\n    margin-right: 24px;\n}\n\n._1uQ86jiz7p8K5evkTSGnus:first-of-type > a {\n    margin-left: 0;\n}\n\n._1uQ86jiz7p8K5evkTSGnus:last-of-type > a {\n    margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"tab-bar-wrapper": "_1acjigxpEsRr-8NOAveSdn",
	"list": "_2UkUtI0PpfOFkTbcTPq5Gz",
	"list-item": "_1uQ86jiz7p8K5evkTSGnus"
};
module.exports = exports;
