import { useMemo } from 'react'
import { useAtomValue } from 'jotai/react'
import type { Theme } from '../queries/themes'
import { ThemePublishedStatus, useFetchAllThemesQuery, useFetchThemeQuery } from '../queries/themes'
import { getActiveStoreIdAtom } from '../atoms/stores'

const useGetPublishedTheme = (): Theme | undefined => {
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const { data: themesMinimal } = useFetchAllThemesQuery({ storeId })

  const publishedThemeId = useMemo(
    () =>
      themesMinimal?.themes?.find((theme) => theme.status === ThemePublishedStatus.published)
        ?.themeId,
    [themesMinimal],
  )

  const { data: publishedTheme } = useFetchThemeQuery({
    storeId,
    themeId: publishedThemeId || '',
    enabled: Boolean(publishedThemeId),
  })

  return publishedTheme
}

export { useGetPublishedTheme }
