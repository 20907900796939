import type { ChangeEvent, FC } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect, Input, ModalFooter } from '@extend/zen'
import type { V3Values } from './user-form-schema'
import { v3Schema } from './user-form-schema'
import { useRoleOptions } from '../../hooks/use-role-options'
import styles from './v3-create-user-form.module.css'

type V3CreateUserFormProps = {
  onSubmit: (formValues: V3Values) => void
  onClickCancel: () => void
  isLoading: boolean
}

export const V3CreateUserForm: FC<V3CreateUserFormProps> = ({
  onSubmit,
  onClickCancel,
  isLoading,
}) => {
  const {
    touched,
    errors,
    values,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: v3Schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
    },
    onSubmit: (formValues: V3Values): void => {
      onSubmit(formValues)
    },
  })

  const handleChangeDropdown = ({
    target: { value },
  }: AdvancedSelectChangeEvent<string | string[]>): void => {
    const roles = typeof value === 'string' ? [value] : value
    setFieldValue('roles', roles)
  }

  const handleClearChange = (e: ChangeEvent): void => {
    handleChange(e)
  }

  const hasErrors = Object.entries(errors).length > 0
  const roleOptions = useRoleOptions()

  return (
    <form className="flex flex-col" id="v3CreateUserForm" onSubmit={handleSubmit}>
      <div className={styles['name-input']}>
        <Input
          id="firstName"
          label="First Name"
          value={values.firstName}
          onChange={handleClearChange}
          onBlur={handleBlur}
          isError={touched.firstName && Boolean(errors.firstName)}
          errorFeedback={errors.firstName}
          isDisabled={isLoading}
        />
        <Input
          id="lastName"
          label="Last Name"
          value={values.lastName}
          onChange={handleClearChange}
          onBlur={handleBlur}
          isError={touched.lastName && Boolean(errors.lastName)}
          errorFeedback={errors.lastName}
          isDisabled={isLoading}
        />
      </div>
      <div className={styles.input}>
        <Input
          id="email"
          label="Email"
          value={values.email}
          onChange={handleClearChange}
          onBlur={handleBlur}
          isError={touched.email && Boolean(errors.email)}
          errorFeedback={errors.email}
          isDisabled={isLoading}
        />
      </div>
      <AdvancedSelect
        data-cy="role-selector"
        id="role"
        label="Roles"
        onChange={handleChangeDropdown}
        // onBlur={handleBlur} // TODO: uncomment once prop is added to zen component
        options={roleOptions}
        isError={touched.roles && Boolean(values.roles?.length === 0)}
        errorFeedback={errors.roles as string | undefined}
        placeholder=" Select "
        value={values.roles}
        isLoading={isLoading}
        multiple
      />
      <ModalFooter
        primaryButtonProps={{
          isProcessing: isLoading,
          isDisabled: !isValid || !dirty || hasErrors || isLoading,
          text: 'Add User',
          type: 'submit',
          formId: 'v3CreateUserForm',
        }}
        secondaryButtonProps={{
          isDisabled: isLoading,
          text: 'Cancel',
          onClick: onClickCancel,
        }}
      />
    </form>
  )
}
