// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1XXHLx5tG7uBpTYELpYu6x {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 8px;\n}\n\n._1LnAMKhALSxe1PCkCTp92a {\n    padding-left: 4px;\n}\n\n._2DKzPRUkNQGBSa7Zr8DGhO {\n    display: flex;\n    gap: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "_1XXHLx5tG7uBpTYELpYu6x",
	"center": "_1LnAMKhALSxe1PCkCTp92a",
	"wrapper": "_2DKzPRUkNQGBSa7Zr8DGhO"
};
module.exports = exports;
