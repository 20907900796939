import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Information,
  Input,
  InputType,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useFormik } from 'formik'
import { useAtom, useSetAtom } from 'jotai'
import { isUserNameUpdatedAtom, isUserProfileEditFormOpenAtom } from '../atoms/user'
import { getAllUserRoles } from '../utils/get-user-roles'
import { useUser } from '../hooks/use-user'
import { useGetUserGrantsQuery, useUpdateUserNameMutation } from '../queries/users-v3'
import type { Values } from '../schemas/user-profile-edit-form-schema'
import { userProfileEditFormSchema } from '../schemas/user-profile-edit-form-schema'
import { LeavePageGuard } from './leave-page-guard'
import type { Grant } from '../types/okta'
import styles from './user-profile-edit-form.module.css'

type UserProfileEditFormProps = {
  onCancel: () => void
}

export const UserProfileEditForm: FC<UserProfileEditFormProps> = ({ onCancel }) => {
  const { toast } = useToaster()

  const { firstName, lastName, email, accountId } = useUser()
  const { mutateAsync: updateUserName, isLoading: isUpdatingUserName } = useUpdateUserNameMutation()

  const { push } = useHistory()

  const { data: grants } = useGetUserGrantsQuery(email || '')

  const grantsForAccount =
    grants?.filter((grant: Grant) => grant.ern === `ERN:ACC:${accountId}`) || []

  const roles = getAllUserRoles(grantsForAccount || []).join(', ')

  const [isUserNameUpdated, setIsUserNameUpdated] = useAtom(isUserNameUpdatedAtom)
  const setUserProfileEditFormOpen = useSetAtom(isUserProfileEditFormOpenAtom)

  const { values, errors, dirty, handleChange, resetForm, handleSubmit } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: userProfileEditFormSchema,
    validateOnChange: true,
    initialValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    },
    onSubmit: async (): Promise<void> => {
      try {
        await updateUserName({
          email: email || '',
          firstName: values.firstName,
          lastName: values.lastName,
        })
        toast({
          message: 'Changes saved',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
        setIsUserNameUpdated(true)
        setUserProfileEditFormOpen(false)
      } catch (err) {
        toast({
          message: 'User name could not be updated. Please try again later',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    },
  })

  const onClickCancel = (): void => {
    onCancel()
    resetForm()
  }

  const handleLeavePage = (path: string): void => {
    push(path)
  }

  const hasErrors = Object.entries(errors).length > 0

  return (
    <div>
      <LeavePageGuard
        isNavBlocked={!isUserNameUpdated && dirty}
        handleLeavePage={handleLeavePage}
      />
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <span>
            <Input
              type={InputType.text}
              ariaLabel="First Name Input"
              data-cy="first-name-input"
              id="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleChange}
              isError={hasErrors}
              errorFeedback={errors.firstName}
            />
          </span>
          <span>
            <Input
              ariaLabel="Last Name Input"
              data-cy="last-name-input"
              id="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              isError={hasErrors}
              errorFeedback={errors.lastName}
            />
          </span>
          <span>
            <div className={styles['user-detail-label']}>Email</div>
            <p className={styles['no-margin']}>{email}</p>
          </span>
        </div>
        <div className={styles.row}>
          <span>
            <div className={`${styles['user-detail-label']} ${styles['flex-center']}`}>
              Role(s)
              <Information buttonSize="xsmall">
                The roles that this user has been assigned. An admin can edit these in the Users
                tab.
              </Information>
            </div>
            <p className={styles['no-margin']}>{roles}</p>
          </span>
        </div>
        <div className={styles['button-group']}>
          <Button
            isDisabled={isUpdatingUserName}
            emphasis="medium"
            text="Cancel"
            onClick={onClickCancel}
          />
          <div className={styles['submit-button-wrapper']}>
            <Button
              isProcessing={isUpdatingUserName}
              isDisabled={!dirty || hasErrors || isUpdatingUserName}
              text="Save Changes"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  )
}
