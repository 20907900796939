import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'
import styles from './sub-header.module.css'

type SubHeaderProps = {
  labelText: string
  'data-cy'?: string
  color?: string
}

export const SubHeader: FC<SubHeaderProps> = ({
  labelText,
  'data-cy': dataCy,
  color = COLOR.NEUTRAL[1000],
}) => (
  <h2 className={styles.subheading} style={{ color }} data-cy={dataCy}>
    {labelText}
  </h2>
)
