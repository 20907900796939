// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RbcN1d3DCyQnYn2duxoBC {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    align-items: center;\n    margin-bottom: 40px;\n}\n\n._3eRthaBLCaAtOszHjMBnMI {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 25px;\n}\n\n._1zsES-fX1UOrBHdmRmcVAK {\n    margin: 0;\n    color: var(--blue-1000);\n}\n\n.lUUOLSZyqvylANE_Jc_hp {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n    display: flex;\n    align-items: center;\n}\n\n._3eY5nyO2sidg8IcDzhJ1_D {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"row": "RbcN1d3DCyQnYn2duxoBC",
	"container": "_3eRthaBLCaAtOszHjMBnMI",
	"header": "_1zsES-fX1UOrBHdmRmcVAK",
	"label": "lUUOLSZyqvylANE_Jc_hp",
	"detail": "_3eY5nyO2sidg8IcDzhJ1_D"
};
module.exports = exports;
