import { safeEncodeURIComponent } from './encoding'

const getClaimsUrl = (): string => {
  return '/store/claims'
}

const getClaimDetailUrl = (claimId: string): string => {
  return `${getClaimsUrl()}/${claimId}`
}

const getClaimDetailHistoryUrl = (claimId: string): string => {
  return `${getClaimDetailUrl(claimId)}/history`
}

const getContractsUrl = (): string => {
  return `/store/contracts`
}

const getContractDetailUrl = (contractId: string): string => {
  return `${getContractsUrl()}/${contractId}`
}

const getFileClaimUrl = (): string => {
  return `${getClaimsUrl()}/file`
}

const getNewContractClaimUrl = (contractId: string): string => {
  return `${getContractDetailUrl(contractId)}/claims/new`
}

const getProductUrl = (referenceId: string): string => {
  return `/store/products/${safeEncodeURIComponent(referenceId)}`
}

const getProductsUrl = (): string => {
  return `/store/products`
}

const getProductVariantUrl = (parentReferenceId: string, referenceId: string): string => {
  return `/store/products/${safeEncodeURIComponent(
    parentReferenceId,
  )}/variants/${safeEncodeURIComponent(referenceId)}`
}

const getProductVariantsUrl = (parentReferenceId: string): string => {
  return `/store/products/${safeEncodeURIComponent(parentReferenceId)}/variants`
}

const getAddNewStoreUrl = (): string => {
  return '/store/add-new-store'
}

const getLeadsSearchUrl = (): string => {
  return `/store/leads`
}

const getLeadsEmailManagementUrl = (): string => {
  return `/store/leads/leads-email-management`
}

const getAnalyticsUrl = (): string => {
  return `/store/analytics`
}

const getAnalyticsShippingProtectionUrl = (): string => {
  return `/store/analytics/shipping-protection`
}

const getCustomReportPath = (title: string): string => {
  return `/store/analytics/${safeEncodeURIComponent(title.replace(/\s+/g, '-'))}`
}

const getCustomizeUrl = (): string => {
  return `/store/customize`
}

const getInstoreUrl = (): string => {
  return `/store/in-store`
}

const getIntegrationsUrl = (): string => {
  return `/store/integrations`
}

const getSettingsUrl = (): string => {
  return `/store/settings`
}

const getUserManagementUrl = (): string => {
  return `/account/users`
}

export {
  getClaimsUrl,
  getClaimDetailUrl,
  getClaimDetailHistoryUrl,
  getContractsUrl,
  getContractDetailUrl,
  getFileClaimUrl,
  getProductUrl,
  getProductsUrl,
  getProductVariantsUrl,
  getProductVariantUrl,
  getNewContractClaimUrl,
  getLeadsSearchUrl,
  getLeadsEmailManagementUrl,
  getAnalyticsUrl,
  getCustomizeUrl,
  getAddNewStoreUrl,
  getInstoreUrl,
  getIntegrationsUrl,
  getSettingsUrl,
  getUserManagementUrl,
  getAnalyticsShippingProtectionUrl,
  getCustomReportPath,
}
