// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1r1J_x88r5MGKYDhUnsQ-F {\n    align-items: center;\n    border-style: solid;\n    border-color: var(--neutral-300);\n    border-radius: 4px;\n    border-width: 1px;\n    background-color: var(--white);\n    display: flex;\n    font-size: 14px;\n    gap: 16px;\n    line-height: 19px;\n    padding: 14px;\n    color: var(--black);\n}\n\n._1r1J_x88r5MGKYDhUnsQ-F:hover {\n    background-color: var(--neutral-100);\n    border-color: var(--blue-800);\n    text-decoration: none;\n}\n\n.-wlXgm4bCu4bA7GshI5Yr {\n    border-radius: 4px;\n}", ""]);
// Exports
exports.locals = {
	"button": "_1r1J_x88r5MGKYDhUnsQ-F",
	"help": "-wlXgm4bCu4bA7GshI5Yr"
};
module.exports = exports;
