// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Y_0T1Hm441cFKcXZkEdZJ {\n    max-width: 530px;\n    margin: 0;\n}\n\n._2AsjXy6xn6Ht0WM_-0ZvBA {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}", ""]);
// Exports
exports.locals = {
	"success-message": "_2Y_0T1Hm441cFKcXZkEdZJ",
	"container": "_2AsjXy6xn6Ht0WM_-0ZvBA"
};
module.exports = exports;
