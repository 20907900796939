import type { FC } from 'react'
import React from 'react'
import { currency } from '@extend/client-helpers'
import type { MerchantProduct } from '../../../types/merchant-product'
import { SubHeader } from '../../../components/sub-header'
import styles from './product-details-info.module.css'

type ProductDetailsInfoProps = {
  product: MerchantProduct
}

type ValueOf<T> = T[keyof T]

export const ProductDetailsInfo: FC<ProductDetailsInfoProps> = ({ product }) => {
  const getValue = (value: ValueOf<MerchantProduct>): string => {
    return `${value ?? 'N/A'}`
  }
  return (
    <>
      <div className={styles.details}>
        <SubHeader labelText="Product Info" />
        <div className={styles.row}>
          <div className="flex flex-col width-50">
            <span className={styles.label}>Product Name</span>
            <span className={styles.value} data-cy="product-details-title">
              {getValue(product.title)}
            </span>
          </div>
          <div className="flex flex-col width-50">
            <span className={styles.label}>Category</span>
            <span className={styles.value} data-cy="product-details-category">
              {getValue(product.category)}
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className="flex flex-col width-25">
            <span className={styles.label}>Product List Price</span>
            <span className={styles.value} data-cy="product-details-price">
              {currency.format(product.price?.amount, product.price?.currencyCode)}
            </span>
          </div>
          <div className="flex flex-col width-25">
            <span className={styles.label}>Currency</span>
            <span className={styles.value} data-cy="product-details-currencyCode">
              {getValue(product.price?.currencyCode)}
            </span>
          </div>
          <div className="flex flex-col width-25">
            <span className={styles.label}>Image</span>
            <span className={styles.value}>
              {product.imageUrl ? (
                <a
                  data-cy="product-details-imageUrl"
                  href={product.imageUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {product.imageUrl}
                </a>
              ) : (
                'N/A'
              )}
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className="flex flex-col width-50">
            <span className={styles.label}>Description</span>
            <span className={styles.value} data-cy="product-details-description">
              {getValue(product.description)}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
