// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1wrho5T0-vfor7e9t4mfuA {\n    margin-bottom: 32px;\n}\n\n._1wrho5T0-vfor7e9t4mfuA > svg:not(:first-of-type) {\n    margin-left: 24px;\n}\n\n._2FNoUZNrkaSFc2ptuwznAm {\n    margin-bottom: 24px;\n    font-size: 24px;\n    font-weight: 700;\n}\n\n._2fgQxIWt4tuFXhhM_rY0KF {\n    font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"icon-container": "_1wrho5T0-vfor7e9t4mfuA",
	"title": "_2FNoUZNrkaSFc2ptuwznAm",
	"text": "_2fgQxIWt4tuFXhhM_rY0KF"
};
module.exports = exports;
