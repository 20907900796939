import type { Store } from '@helloextend/extend-api-client'

export type ProtectionType = 'product' | 'shipping'
interface ProtectionToggleDetails {
  isProtectionApproved: boolean
  isProtectionEnabled: boolean
  isProtectionLive: boolean
  capitalizedProtectionType: 'Product' | 'Shipping'
  updateStoreDataShape: { enabled: boolean } | { shippingProtection: { enabled: boolean } }
}

export const getProtectionToggleDetails = ({
  protectionType,
  store,
  isEnabling,
}: {
  protectionType: ProtectionType
  store: Store | undefined
  isEnabling: boolean
}): ProtectionToggleDetails => {
  const protectionTypeDetailsMap: Record<string, ProtectionToggleDetails> = {
    product: {
      isProtectionApproved: store?.approved || false,
      isProtectionEnabled: store?.enabled || false,
      isProtectionLive: (store?.approved && store?.enabled) || false,
      capitalizedProtectionType: 'Product',
      updateStoreDataShape: { enabled: isEnabling },
    },
    shipping: {
      isProtectionApproved: store?.shippingProtection?.approved || false,
      isProtectionEnabled: store?.shippingProtection?.enabled || false,
      isProtectionLive:
        (store?.shippingProtection?.approved && store?.shippingProtection?.enabled) || false,
      capitalizedProtectionType: 'Shipping',
      updateStoreDataShape: { shippingProtection: { enabled: isEnabling } },
    },
  }
  return protectionTypeDetailsMap[protectionType]
}
