import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Input } from '@extend/zen'
import styled from '@emotion/styled'
import { InputType } from '@extend/zen'
import type { NumberRangeFilterValues } from './types'

export type NumberRangeFilterProps = {
  property: string
  values?: NumberRangeFilterValues
  lowLabel?: string
  highLabel?: string
  isCurrency: boolean
  onFilterChange: (property: string, values: NumberRangeFilterValues | null) => void
}

const NumberRangeFilter: FC<NumberRangeFilterProps> = ({
  property,
  values = {
    type: 'numberRange',
    low: null,
    high: null,
    isCurrency: false,
  },
  lowLabel,
  highLabel,
  isCurrency,
  onFilterChange,
}) => {
  const handleChangeLow = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value

      onFilterChange(
        property,
        val.length > 0
          ? {
              type: 'numberRange',
              low: +val,
              high: values.high,
              lowLabel,
              highLabel,
              isCurrency,
            }
          : null,
      )
    },
    [property, values, lowLabel, highLabel, isCurrency, onFilterChange],
  )

  const handleChangeHigh = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value

      onFilterChange(
        property,
        val.length > 0
          ? {
              type: 'numberRange',
              low: values.low,
              high: +val,
              lowLabel,
              highLabel,
              isCurrency,
            }
          : null,
      )
    },
    [property, values, lowLabel, highLabel, isCurrency, onFilterChange],
  )

  return (
    <NumberRangeWrapper>
      <NumberPickerLabel>{lowLabel}</NumberPickerLabel>
      <NumberPickerWrapper>
        <Input
          type={InputType.number}
          id="low"
          placeholder="low"
          onChange={handleChangeLow}
          value={values.low === null ? '' : values.low.toString()}
        />
      </NumberPickerWrapper>
      <NumberPickerLabel>{highLabel}</NumberPickerLabel>
      <NumberPickerWrapper>
        <Input
          type={InputType.number}
          id="high"
          placeholder="high"
          onChange={handleChangeHigh}
          value={values.high === null ? '' : values.high.toString()}
        />
      </NumberPickerWrapper>
    </NumberRangeWrapper>
  )
}

const NumberRangeWrapper = styled.div({
  padding: '12px 12px 0px 12px',
})

const NumberPickerWrapper = styled.div({
  display: 'flex',
  margin: '0px 0px 16px 0px',
})

const NumberPickerLabel = styled.p({
  fontWeight: 'bold',
  margin: 0,
  padding: 0,
})

export { NumberRangeFilter }
