import { useMemo } from 'react'
import type { BadgeColor } from '@extend/zen'
import type { Grant } from '../../types/okta'
import type { V3User } from '../../types/users'
import { useGetUserV3Query } from '../../queries/users-v3'
import { useGetOrganizations } from '../../queries/organizations'
import { getAllUserRoles, isValidRoleV3 } from '../../utils/get-user-roles'
import {
  getExtendUserStatusBadgeItem,
  mapOktaToExtendStatus,
} from '../../utils/okta-to-extend-status-utils'
import type { Organization } from '../../queries/organizations'
import { useAccountFilteredGrants } from '../../hooks/use-account-filtered-grants'
import { useUser } from '../../hooks/use-user'
import type { BadgeDescriptionItem } from '../../components/badge-descriptions'

type UseV3UserInfoState = {
  data: V3User | undefined
  isLoading: boolean
  accountFilteredGrants: Grant[]
  accountFilteredGrantsForDeactivate: Grant[]
  status: string
  userRoleLabels: string[]
  userRoles: string[]
  badgeText: string
  badgeColor: BadgeColor
  organizations: Organization[] | undefined
  roleAssignments: Record<string, string[]> | undefined
}

export function useV3UserInfo(uuid: string): UseV3UserInfoState {
  const { data, isLoading } = useGetUserV3Query(uuid)
  const { data: organizations } = useGetOrganizations()
  const { accountId } = useUser()

  const status = useMemo(
    () => (data ? mapOktaToExtendStatus(data?.status, data?.grants) : 'NOSTATUS'),
    [data],
  )

  const badgeInfo = useMemo(
    () =>
      status !== 'NOSTATUS'
        ? getExtendUserStatusBadgeItem(status)
        : ({
            text: '',
            color: 'blue',
          } as BadgeDescriptionItem),
    [status],
  )

  const grants = data?.grants

  const accountFilteredGrants = useAccountFilteredGrants(grants)

  const accountFilteredGrantsForDeactivate = useMemo(
    () => data?.grants?.filter((grant) => grant.ern === `ERN:ACC:${accountId}`) || [],
    [data, accountId],
  )

  const userRoleLabels = useMemo(
    () => getAllUserRoles(accountFilteredGrants),
    [accountFilteredGrants],
  )

  const userRoles = useMemo(
    () => accountFilteredGrants.map((g: Grant) => g.role) || [],
    [accountFilteredGrants],
  )

  const roleAssignments = useMemo(() => {
    const grantsGroupedByRole = data?.grants?.reduce((acc, current) => {
      const { role } = current
      const orgId = current.ern.split('ORG:')[1]

      if (isValidRoleV3(role) && orgId) {
        const org = organizations?.find((o) => o.id === orgId)
        const orgs = acc[role] || []
        orgs.push(org?.name || '')

        acc[role] = orgs
        return acc
      }
      return acc
    }, {} as Record<string, string[]>)

    return grantsGroupedByRole
  }, [data, organizations])
  return {
    data,
    isLoading,
    accountFilteredGrants,
    accountFilteredGrantsForDeactivate,
    status,
    userRoleLabels,
    userRoles,
    badgeText: badgeInfo?.text,
    badgeColor: badgeInfo?.color,
    organizations,
    roleAssignments,
  }
}
