import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import {
  useListInsuranceClaimsQuery,
  useSubmitClaimMutation,
  useUpdateClaimMutation,
} from '@helloextend/extend-api-rtk-query'
import type {
  InsuranceClaim,
  ProductSelectPrompt,
  Reply,
  ShipmentSelectPrompt,
  Slot,
} from '@helloextend/extend-api-client'
import { COLOR, Spinner } from '@extend/zen'
import { ProductSelector, ShipmentSelector } from '@helloextend/kaley-ui'
import { Redirect } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ContactForm } from './contact-form'
import { AnswerTextArea } from './answer-text-area'
import { AnswerDatePicker } from './answer-date-picker'
import { AnswerSelection } from './answer-selection'
import { LeavePageGuard } from '../../../../components/leave-page-guard'
import { useIncredibotFlowState } from '../../../../hooks/use-incredibot-flow-state'
import { LDFlag } from '../../../../constants/ld-flags'
import styles from './user-input.module.css'

interface UserInputProps {
  contractId: string
}

export const UserInput: FC<UserInputProps> = ({ contractId }) => {
  const { lineItemId } = useParams<{ lineItemId: string }>()
  const { contract, connect, isLoading, currentReply, updateSession } = useIncredibotFlowState(
    contractId,
    lineItemId,
  )
  const [isNavBlocked, setIsNavBlocked] = useState(false)
  const { [LDFlag.NewClaimAttachments]: FF_CLAIM_ATTACHMENTS } = useFlags()
  const [shouldPoll, setShouldPoll] = useState(false)
  const [isAdjudicated, setIsAdjudicated] = useState(false)
  const [claimCustomerInfo, setClaimCustomerInfo] = useState<
    InsuranceClaim['customer'] | undefined
  >(undefined)

  const { prompt, poll } = currentReply

  const email = poll?.customerEmail
  const phone = poll?.customerPhone
  const pollContractId = poll?.contractId || contractId
  const pollSessionId = poll?.sessionId

  const [submitClaim, { isLoading: isSubmitLoading }] = useSubmitClaimMutation()
  const [updateClaim] = useUpdateClaimMutation()

  const { data: claims } = useListInsuranceClaimsQuery(
    {
      sellerId: contract?.sellerId,
      containsCustomerEmail: email,
      containsCustomerPhone: phone,
      containsContractId: pollContractId,
      sessionId: pollSessionId,
      minLimit: 500,
    },
    {
      skip: !shouldPoll,
      selectFromResult: (response) => ({
        data: response.data?.items ?? [],
      }),
      pollingInterval: 2000,
    },
  )

  const pendingAdjudicationClaim = claims.find(
    (claim: InsuranceClaim) => claim.status === 'pending_adjudication',
  )
  const isClaimPendingPhotoUploads =
    FF_CLAIM_ATTACHMENTS &&
    pendingAdjudicationClaim &&
    !!pendingAdjudicationClaim?.photoRequirements?.length

  useEffect(() => {
    if (poll && !pendingAdjudicationClaim) {
      if (!shouldPoll) {
        setShouldPoll(true)
      }
    } else if (shouldPoll && claimCustomerInfo) {
      updateClaim({
        claimId: pendingAdjudicationClaim?.id as string,
        body: {
          customer: {
            email: claimCustomerInfo.email,
            phone: claimCustomerInfo.phone,
            name: claimCustomerInfo.name,
            shippingAddress: claimCustomerInfo.shippingAddress,
          },
        },
      })
      setShouldPoll(false)
    }
  }, [poll, shouldPoll, pendingAdjudicationClaim, setShouldPoll])

  const history = useHistory()

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  if (isAdjudicated || isClaimPendingPhotoUploads) {
    return (
      <Redirect to={`/store/contracts/${contractId}/claims/new/${pendingAdjudicationClaim?.id}`} />
    )
  }

  const handleButtonClick = async (
    slot: Slot,
    slotValue: string | number | string[],
  ): Promise<void> => {
    if (pendingAdjudicationClaim && !isClaimPendingPhotoUploads && poll) {
      await submitClaim({ claimId: pendingAdjudicationClaim.id }).unwrap()
      setIsAdjudicated(true)
      return
    }
    updateSession(slot, slotValue)
  }

  const handleSaveClaimAddress = (updates: InsuranceClaim['customer']): void => {
    setClaimCustomerInfo(updates)
    connect()
  }

  if (contract && !claimCustomerInfo) {
    return (
      <div className={styles.wrapper}>
        <LeavePageGuard isNavBlocked={isNavBlocked} handleLeavePage={handleLeavePage} />
        <ContactForm
          contract={contract}
          onSubmit={handleSaveClaimAddress}
          toggleNavBlocked={setIsNavBlocked}
          isLoading={isLoading}
        />
      </div>
    )
  }

  switch (prompt?.type) {
    case 'input':
      return (
        <>
          <LeavePageGuard isNavBlocked handleLeavePage={handleLeavePage} />
          <AnswerTextArea
            contractId={contractId}
            onSubmit={updateSession}
            reply={currentReply as Reply}
            isLoading={isLoading}
          />
        </>
      )
    case 'datepicker':
      return (
        <>
          <LeavePageGuard isNavBlocked handleLeavePage={handleLeavePage} />
          <AnswerDatePicker
            contractId={contractId}
            onSubmit={updateSession}
            reply={currentReply as Reply}
            isLoading={isLoading}
          />
        </>
      )
    case 'multiselect':
    case 'buttons':
      return (
        <>
          <LeavePageGuard isNavBlocked handleLeavePage={handleLeavePage} />
          <AnswerSelection
            contractId={contractId}
            onSubmit={handleButtonClick}
            reply={currentReply as Reply}
            isLoading={isLoading || (poll && !pendingAdjudicationClaim) || isSubmitLoading}
            autoselectHideSingleOption={Boolean(poll)}
          />
        </>
      )
    case 'productSelect':
      return (
        <>
          <LeavePageGuard isNavBlocked handleLeavePage={handleLeavePage} />
          <ProductSelector
            prompt={currentReply.prompt as ProductSelectPrompt}
            isLoading={isLoading}
            onSelect={updateSession}
          />
        </>
      )
    case 'shipmentSelect':
      return (
        <>
          <LeavePageGuard isNavBlocked handleLeavePage={handleLeavePage} />
          <ShipmentSelector
            prompt={currentReply.prompt as ShipmentSelectPrompt}
            isLoading={isLoading}
            onSelect={updateSession}
          />
        </>
      )
    default:
      return (
        <div className={styles.loading}>
          <Spinner color={COLOR.BLUE[800]} />
        </div>
      )
  }
}
