import * as Yup from 'yup'
import ISOAlpha2 from 'iso-3166-1-alpha-2'
import { alphanumericRegex, alphanumericWithSpacesRegex } from '../../../../utils/validate'
import { cityRegex } from '../../../../utils/form-utils'

export const contractDetailsSchema = Yup.object()
  .shape({
    fullName: Yup.string()
      .required('Please enter a valid name')
      .max(30, 'Please enter a valid name')
      .matches(alphanumericWithSpacesRegex, 'Please enter a valid name'),
    phoneNumber: Yup.string().notRequired(),
    email: Yup.string()
      .notRequired()
      .max(100, 'Please enter a valid email address')
      .email('Please enter a valid email address'),
    billingAddress1: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    billingAddress2: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    billingCity: Yup.string()
      .notRequired()
      .max(30, 'Please enter a valid city')
      .matches(cityRegex, 'Please enter a valid city'),
    billingProvinceCode: Yup.string().notRequired(),
    billingPostalCode: Yup.string()
      .notRequired()
      .max(10, 'Please enter a valid code')
      .matches(alphanumericRegex, 'Please enter a valid code'),
    billingCountryCode: Yup.string()
      .notRequired()
      .oneOf(ISOAlpha2.getCodes(), 'Please enter a valid country code.'),
    shippingAddress1: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    shippingAddress2: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    shippingCity: Yup.string()
      .notRequired()
      .max(30, 'Please enter a valid city')
      .matches(cityRegex, 'Please enter a valid city'),
    shippingProvinceCode: Yup.string().notRequired(),
    shippingPostalCode: Yup.string()
      .notRequired()
      .max(10, 'Please enter a valid code')
      .matches(alphanumericRegex, 'Please enter a valid code'),
    shippingCountryCode: Yup.string()
      .notRequired()
      .oneOf(ISOAlpha2.getCodes(), 'Please enter a valid country code.'),
  })
  .defined()
