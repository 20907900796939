import type { FC } from 'react'
import React, { useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import {
  AdvancedSelect,
  Modal,
  ModalController,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import {
  closeReactivateUserModal,
  isReactivateUserModalVisible,
  reactivateUserModalEmailAtom,
} from '../../atoms/reactivate-user-modal'
import { useActivateUserMutation } from '../../queries/users-v3'
import { useRoleOptions } from '../../hooks/use-role-options'
import styles from './reactivate-user-modal.module.css'

export const ReactivateUserModal: FC = () => {
  const { toast } = useToaster()
  const roleOptions = useRoleOptions()
  const isVisible = useAtomValue(isReactivateUserModalVisible)
  const close = useSetAtom(closeReactivateUserModal)
  const email = useAtomValue(reactivateUserModalEmailAtom)
  const [roles, setRoles] = useState<string[]>([])
  const { mutateAsync: activateUser, isLoading } = useActivateUserMutation()

  const handleChangeDropdown = ({
    target: { value },
  }: AdvancedSelectChangeEvent<string | string[]>): void => {
    const selectedRoles = typeof value === 'string' ? [value] : value
    setRoles(selectedRoles)
  }

  const handleClickActivateUser = async (): Promise<void> => {
    try {
      await activateUser({ roles, email })
      toast({
        message: 'User activated',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      close()
    } catch (err) {
      toast({
        message: `The user couldn’t be activated. Try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Activate user"
        primaryButtonProps={{
          onClick: handleClickActivateUser,
          text: 'Activate User',
          isDisabled: Boolean(!roles.length),
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{
          onClick: close,
          text: 'Cancel',
        }}
      >
        <p className={styles.detail}>Select one or more roles to activate this user</p>
        <AdvancedSelect
          id="role"
          label="Role"
          onChange={handleChangeDropdown}
          options={roleOptions}
          placeholder="Select"
          value={roles}
          multiple
          data-cy="role-selector"
        />
      </Modal>
    </ModalController>
  )
}
