import React, { useState } from 'react'
import type { FC } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { AccordionSection, Button } from '@extend/zen'
import styles from './failed-product-shipment.module.css'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import { useFailedProductShipmentData } from '../../../hooks/use-failed-product-shipment-data'
import { useServiceOrderShippingLabel } from '../../../hooks/use-service-order-shipping-label'

export const FailedProductShipmentSection: FC<{
  serviceOrder: ServiceOrder
  packingSlipURL: string
}> = ({ serviceOrder, packingSlipURL = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { id: serviceOrderId } = serviceOrder
  const { gridData } = useFailedProductShipmentData({
    serviceOrderId,
    packingSlipURL,
  })
  const { handleDownloadShippingLabel, hasDownloadLabelUrl } = useServiceOrderShippingLabel(
    serviceOrder,
    'servicer',
    packingSlipURL,
  )

  return (
    <AccordionSection
      heading="Failed Product Shipment"
      headingSize="sub"
      data-cy={`${serviceOrder.id}-failed-product-shipment`}
      onToggleRequest={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
    >
      <DataPropertyGrid values={gridData} className={styles.grid} />
      {hasDownloadLabelUrl && (
        <Button text="Download Label" onClick={handleDownloadShippingLabel} />
      )}
    </AccordionSection>
  )
}
