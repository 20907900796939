// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".v3H7dZYzZ4LuF062TbHKt {\n    display: flex;\n    align-items: center;\n    margin-bottom: 40px;\n    border-radius: 6px;\n    height: 60px;\n    width: 100%;\n    border-color: var(--yellow-300);\n    border: 1px solid var(--yellow-700);\n    background-color: var(--yellow-100);\n}\n\n.yK4-Ybe_AgPSa8EYqWnQw {\n    margin-left: 10px;\n    color: var(--yellow-800);\n}\n\n._1DDDaD6KGnLvGNrBoDzdF5 {\n    margin: 5px 0 0 10px;\n}\n\n._1aQZHw3tzvh1kdLdoAndxz {\n    border: none;\n    background: none;\n    text-decoration: underline;\n    color: var(--yellow-800);\n}\n\n._1aQZHw3tzvh1kdLdoAndxz:visited {\n    color: var(--yellow-800);\n}", ""]);
// Exports
exports.locals = {
	"container": "v3H7dZYzZ4LuF062TbHKt",
	"text": "yK4-Ybe_AgPSa8EYqWnQw",
	"icon-wrapper": "_1DDDaD6KGnLvGNrBoDzdF5",
	"logout-button": "_1aQZHw3tzvh1kdLdoAndxz"
};
module.exports = exports;
