// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1a21hPcZ3IiAy2LHSoPw3b {\n    padding: 10px 12px;\n}\n\n._1g4I1OjUzHJueqUF_20WC_ {\n    font-weight: 700;\n    font-size: 12px;\n    color: var(--neutral-700);\n    letter-spacing: 1px;\n    background: var(--white);\n    margin: 0;\n    text-transform: uppercase;\n    padding: 10px 12px;\n}\n\n._2PhKBnBPe3TBZte2gBm1_M {\n    border-bottom: 1px solid var(--neutral-300);\n}", ""]);
// Exports
exports.locals = {
	"spinner-container": "_1a21hPcZ3IiAy2LHSoPw3b",
	"role-switcher-title": "_1g4I1OjUzHJueqUF_20WC_",
	"menu-item-divider": "_2PhKBnBPe3TBZte2gBm1_M"
};
module.exports = exports;
