import type { FC, ReactNode } from 'react'
import React, { useState, useMemo } from 'react'
import type { ServiceType } from '@helloextend/extend-api-client'
import { Button, Modal, ModalController } from '@extend/zen'
import { useAtom } from 'jotai/react'
import type { V2 } from '@extend-services/service-orders'
import { ResolveServiceOrderProductReplacementContent } from './resolve-service-order-product-replacement-content'
import { ResolveServiceOrderProductRepairContent } from './resolve-service-order-product-repair-content'
import styles from './resolve-service-order-modal.module.css'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'

export type ResolveServiceOrderModalProps = {
  contractId: string
  serviceOrderId: string
  serviceOrderType: ServiceType
  fulfillmentMethod?: V2.Models.RepairFulfillmentMethod
}
export const ResolveServiceOrderModal: FC<ResolveServiceOrderModalProps> = ({
  contractId,
  serviceOrderId,
  serviceOrderType,
  fulfillmentMethod,
}) => {
  const getDefaultResolutionType = (): 'replacement' | 'repair' | null => {
    switch (serviceOrderType) {
      case 'replace':
        return 'replacement'
      case 'repair':
        return 'repair'
      default:
        return null
    }
  }
  const [resolutionType, setResolutionType] = useState(getDefaultResolutionType())
  const [isResolveServiceOrderModalVisible, setIsResolveServiceOrderModalVisible] = useAtom(
    isResolveServiceOrderModalVisibleAtom,
  )

  const getResolveServiceOrderModalContent = useMemo((): ReactNode => {
    if (resolutionType === 'replacement') {
      return (
        <ResolveServiceOrderProductReplacementContent
          data-cy="replacement-modal"
          contractId={contractId}
          serviceOrderId={serviceOrderId}
        />
      )
    }

    // The modal is only displayed for `replace` and `repair`, the `repair` case should always have a fulfillment method
    if (resolutionType && fulfillmentMethod) {
      return (
        <ResolveServiceOrderProductRepairContent
          data-cy="repair-modal"
          serviceOrderId={serviceOrderId}
          fulfillmentMethod={fulfillmentMethod}
          serviceType={serviceOrderType}
        />
      )
    }

    return (
      <section className={styles.container}>
        <Button
          onClick={() => setResolutionType('replacement')}
          emphasis="medium"
          text="Product replacement"
          color="neutral"
        />
        <Button
          onClick={() => setResolutionType('repair')}
          emphasis="medium"
          text="Product repair"
          color="neutral"
        />
      </section>
    )
  }, [contractId, resolutionType, serviceOrderId, fulfillmentMethod, serviceOrderType])

  return (
    <ModalController isOpen={isResolveServiceOrderModalVisible}>
      {resolutionType === 'repair' ? (
        getResolveServiceOrderModalContent
      ) : (
        <Modal
          data-cy="select-service-order-type-modal"
          size="md"
          onDismissRequest={() => setIsResolveServiceOrderModalVisible(false)}
          heading="Resolve Service Order"
        >
          {getResolveServiceOrderModalContent}
        </Modal>
      )}
    </ModalController>
  )
}
