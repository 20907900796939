import { customLogger } from '@extend/client-helpers'
import type { FC } from 'react'
import React from 'react'
import type { Reply } from '@helloextend/extend-api-client'
import { Button, HeadingLarge } from '@extend/zen'
import { ErrorCrossCircle, ErrorBrokenLinks } from '../../../../components/icons'
import styles from './error-message.module.css'

interface ErrorMessageProps {
  contractId: string
  reply: Reply | undefined
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ contractId, reply = null }) => {
  customLogger.error('User encountered an unhandled claims-assistant session', {
    reply: JSON.stringify(reply, null, '\t'),
    contractId,
  })

  const handleClickReload = (): void => {
    window.location.reload()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.icons}>
        <ErrorCrossCircle />
        <ErrorBrokenLinks />
      </div>
      <HeadingLarge>Extend has encountered a problem...</HeadingLarge>
      <div className={styles.body}>
        <p>
          We’re sorry - a system error has occurred and we are unable to continue to the next page.
          Any progress cannot be saved at this time.
        </p>
        <p>
          If you need immediate help, contact our support team at{' '}
          <a href="mailto:support@extend.com">support@extend.com</a> and provide this contract
          ID:&nbsp;{contractId}
        </p>
      </div>
      <Button ariaLabel="refresh" onClick={handleClickReload} type="button" text="Try again" />
    </div>
  )
}
