import type { AccountWithGrants, Grant, Account } from '../types/okta'

export const mapAccountWithGrants = (accounts: Account[], grants: Grant[]): AccountWithGrants[] => {
  const result: AccountWithGrants[] = []
  for (const account of accounts) {
    const grantsForAccount = grants.filter((grant) => grant.ern.includes(account.id))
    if (grantsForAccount.length) {
      result.push({
        ...account,
        grants: grantsForAccount,
      })
    }
  }

  return result
}
