import * as Yup from 'yup'
import ISOAlpha2 from 'iso-3166-1-alpha-2'
import { Money } from '@helloextend/currency'
import { alphanumericRegex } from '../../../../utils/validate'
import { cityRegex, getProvinceCodes } from '../../../../utils/form-utils'

export const customerInfoSchema = Yup.object()
  .shape({
    fullName: Yup.string()
      .required('Please enter a valid name')
      .max(30, 'Please enter a valid name'),
    phoneNumber: Yup.string().notRequired(),
    email: Yup.string()
      .required('Please enter a valid email address')
      .max(100, 'Please enter a valid email address')
      .email('Please enter a valid email address'),
    currencyCode: Yup.string()
      .required('Please enter a currency code')
      .oneOf(Object.keys(Money.currencies)),
    shippingAddress1: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    shippingAddress2: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    shippingCity: Yup.string()
      .notRequired()
      .max(30, 'Please enter a valid city')
      .matches(cityRegex, 'Please enter a valid city'),
    shippingProvinceCode: Yup.string().when('shippingCountryCode', {
      is: (value) => value === 'US',
      then: Yup.string()
        .required('Please select a state')
        .test('is-valid-US-state', 'Select a state', function testState(value) {
          return Boolean(value && getProvinceCodes('US').includes(value))
        }),
      otherwise: Yup.string()
        // when there are provinces, we want to make the province required and validate it
        .when('shippingCountryCode', {
          is: (value) => getProvinceCodes(value).length > 0,
          then: Yup.string()
            .required('Please select a province')
            .test('is-valid-non-US-state', 'Select a province', function testState(value) {
              const { countryCode } = this.parent
              return Boolean(value && getProvinceCodes(countryCode).includes(value))
            }),
          otherwise: Yup.string(),
        }),
    }),
    shippingPostalCode: Yup.string()
      .notRequired()
      .max(10, 'Please enter a valid code')
      .matches(alphanumericRegex, 'Please enter a valid code'),
    shippingCountryCode: Yup.string()
      .required('Please select a country')
      .oneOf(ISOAlpha2.getCodes(), 'Please enter a valid country code.'),
    billingAddress1: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    billingAddress2: Yup.string().notRequired().max(255, 'Please enter a valid address'),
    billingCity: Yup.string()
      .notRequired()
      .max(30, 'Please enter a valid city')
      .matches(cityRegex, 'Please enter a valid city'),
    billingProvinceCode: Yup.string().notRequired(),
    billingPostalCode: Yup.string()
      .notRequired()
      .max(10, 'Please enter a valid code')
      .matches(alphanumericRegex, 'Please enter a valid code'),
    billingCountryCode: Yup.string()
      .notRequired()
      .oneOf(ISOAlpha2.getCodes(), 'Please enter a valid country code.'),
  })
  .defined()
