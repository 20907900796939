import type { Lead } from '@helloextend/extend-api-client'
import type { TableLead } from '../types/merchant-lead'

export const formatLeadToTableLeadItem = (lead: Lead): TableLead => {
  return {
    id: lead.leadToken,
    productTransactionId: lead.product?.transactionId,
    productTransactionDate: lead.product?.transactionDate,
    customerEmail: lead.customer?.email,
    productReferenceId: lead.product?.referenceId,
    productName: lead.product?.title,
    productPurchasePrice: lead.product?.purchasePrice,
    status: lead.status,
  }
}

export const formatLeadsToTableLeadItems = (leads: Lead[]): TableLead[] => {
  return leads.map(formatLeadToTableLeadItem)
}
