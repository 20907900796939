import React, { useState } from 'react'
import type { FC } from 'react'
import {
  Label,
  Modal,
  ModalController,
  TextArea,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { DatePicker } from '@helloextend/merchants-ui'
import { useAtom } from 'jotai/react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { isCompleteRepairDepotModalVisibleAtom } from '../../../atoms/complete-repair-depot-modal'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import { useCompleteServiceOrderRepair } from '../../../queries/service-orders'
import styles from './complete-repair-depot-modal.module.css'

const dateFormat = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

export const CompleteRepairDepotModal: FC<{
  serviceOrder: ServiceOrder
}> = ({ serviceOrder }) => {
  const [isVisible, setIsVisible] = useAtom(isCompleteRepairDepotModalVisibleAtom)
  const [date, setDate] = useState(null as Date | null)
  const [hasDateError, setHasDateError] = useState(false)
  const [repairExplanation, setRepairExplanation] = useState('')
  const { mutateAsync, isLoading } = useCompleteServiceOrderRepair()
  const closeModal = (): void => setIsVisible(false)
  const { toast } = useToaster()
  const handleSubmit = async (): Promise<void> => {
    if (!date) {
      return setHasDateError(true)
    }
    try {
      await mutateAsync({
        serviceOrderId: serviceOrder.id,
        repairCompleteDate: date,
        repairExplanation,
        method: 'repair_depot',
      })
      closeModal()
      toast({
        message: 'The repair has been successfully marked as complete',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    } catch {
      toast({
        message: 'There was an error marking the repair as complete. Please try again',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }

    return Promise.resolve()
  }

  const handleChangeDatePicker = (newDate: Date | null): void => {
    if (newDate) {
      setHasDateError(false)
      setDate(newDate)
      return
    }
    setHasDateError(true)
  }

  const dataProperties = [
    {
      key: 'SO Created Date',
      label: 'SO Created Date',
      value: serviceOrder.createdAt ? dateFormat.format(new Date(serviceOrder.createdAt)) : '',
    },
    {
      key: 'Checked In Date',
      label: 'Checked In Date',
      value: serviceOrder?.repairMetaData?.checkedInAt
        ? dateFormat.format(new Date(serviceOrder?.repairMetaData?.checkedInAt))
        : '',
    },
    {
      key: 'Repair Started Date',
      label: 'Repair Started Date',
      value: serviceOrder.repairMetaData?.repairStartedAt
        ? dateFormat.format(new Date(serviceOrder.repairMetaData?.repairStartedAt))
        : '',
    },
  ]

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Complete Repair"
        onDismissRequest={closeModal}
        primaryButtonProps={{
          text: 'Complete Repair',
          onClick: handleSubmit,
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: closeModal }}
      >
        <div className={styles.container}>
          <DataPropertyGrid values={dataProperties} className={styles.grid} />
          <div className={styles['date-container']}>
            <Label>Repair Date</Label>
            <DatePicker
              maxDate={new Date()}
              onChange={(event) => handleChangeDatePicker(event)}
              selected={date}
              fullWidth
              placeHolder=""
              errorFeedback={hasDateError ? 'Required' : ''}
            />
          </div>
          <TextArea
            value={repairExplanation}
            id="repairExplanation"
            onChange={(event) => setRepairExplanation(event.target.value)}
            label="Repair Explanation (Optional)"
          />
        </div>
      </Modal>
    </ModalController>
  )
}
