// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2fYPvfK99vQinkkALIx6TF {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    min-width: 864px;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n._1hodKgDDIef6J7AaZPWiNy {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n.f4UuHCacQjYuY7CmWLzWs {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 66%;\n}\n\n._3UZ7M8c--tf_ZoqXY5G_F5 {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n._3UZ7M8c--tf_ZoqXY5G_F5 span:last-child {\n    overflow: unset;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2fYPvfK99vQinkkALIx6TF",
	"grid-33-33-33": "_1hodKgDDIef6J7AaZPWiNy",
	"grid-33-66": "f4UuHCacQjYuY7CmWLzWs",
	"contract-information-section": "_3UZ7M8c--tf_ZoqXY5G_F5"
};
module.exports = exports;
