// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3FC1QTOlkGY8y9gkv4SKx {\n    display: grid;\n    gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n.JDh-1YWJOHnSNdFVtzEr8 {\n    display: flex;\n    gap: 8px;\n    justify-content: end;\n    margin-top: 30px;\n}\n\n._257j0uTuDGuzoEMO65VYds {\n    overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"grid-33-33-33": "_3FC1QTOlkGY8y9gkv4SKx",
	"button-group": "JDh-1YWJOHnSNdFVtzEr8",
	"box-overflow": "_257j0uTuDGuzoEMO65VYds"
};
module.exports = exports;
