import type { BadgeColor } from '@extend/zen'

export type BadgeColorItem = {
  text: string
  color: BadgeColor
}

const INTEGRATION_STATUS_COLOR: Record<string, BadgeColor> = {
  active: 'green',
  inactive: 'neutral',
  expired: 'red',
  deleted: 'red',
}

export const ACTIVE_STATUS_OPTIONS: BadgeColorItem = {
  text: 'Active',
  color: INTEGRATION_STATUS_COLOR.active,
}
export const INACTIVE_STATUS_OPTIONS: BadgeColorItem = {
  text: 'Inactive',
  color: INTEGRATION_STATUS_COLOR.inactive,
}
export const EXPIRED_STATUS_OPTIONS: BadgeColorItem = {
  text: 'Expired',
  color: INTEGRATION_STATUS_COLOR.expired,
}
export const DELETED_STATUS_OPTIONS: BadgeColorItem = {
  text: 'Deleted',
  color: INTEGRATION_STATUS_COLOR.deleted,
}

export const STATUS_MAP: BadgeColorItem[] = [
  ACTIVE_STATUS_OPTIONS,
  INACTIVE_STATUS_OPTIONS,
  EXPIRED_STATUS_OPTIONS,
  DELETED_STATUS_OPTIONS,
]

export const integrationStatusBadgeItems: Record<string, BadgeColorItem> = {
  ACTIVE: ACTIVE_STATUS_OPTIONS,
  INACTIVE: INACTIVE_STATUS_OPTIONS,
  EXPIRED: EXPIRED_STATUS_OPTIONS,
  DELETED: DELETED_STATUS_OPTIONS,
}

export type IntegrationStatus = 'ACTIVE' | 'INACTIVE' | 'EXPIRED' | 'DELETED'
export type APIIntegrationStatus = 'ACTIVE' | 'EXPIRED'

export const getApiIntegrationStatusBadgeItem = (status: APIIntegrationStatus): BadgeColorItem => {
  return integrationStatusBadgeItems[status] || INACTIVE_STATUS_OPTIONS
}

export const getIntegrationStatusBadgeItem = (status: IntegrationStatus): BadgeColorItem => {
  return integrationStatusBadgeItems[status] || INACTIVE_STATUS_OPTIONS
}
