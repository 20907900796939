import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { LDFlag } from '../constants/ld-flags'

interface SearchOption {
  label: string
  value: string
}

export const useCustomerPhoneSearch = (
  searchOptions: SearchOption[],
  flag?: LDFlag,
): SearchOption[] => {
  const flags = useFlags()
  const FF_CUSTOMER_PHONE_SEARCH = flag ? flags[flag] : true
  const flaggedSearchOptions = useMemo(() => {
    return FF_CUSTOMER_PHONE_SEARCH
      ? [...searchOptions, { label: 'Customer Phone', value: 'containsCustomerPhone' }]
      : searchOptions
  }, [FF_CUSTOMER_PHONE_SEARCH, searchOptions])

  return flaggedSearchOptions
}
