import { useCallback } from 'react'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type {
  Product,
  ProductCountsGetResponse,
  ProductsSearchItem,
  ProductsSearchPage,
  SubproductCreateRequest,
} from '@helloextend/extend-api-client'
import type { ProductsSearchArguments } from '@helloextend/extend-api-rtk-query'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { buildQueryStringFromObject } from '../utils/build-query-string-from-object'
import { v3AccessTokenAtom } from '../atoms/auth'
import { getActiveStoreIdAtom } from '../atoms/stores'
import { currentProductsSearchParams } from '../atoms/products-search'
import { safeBtoa } from '../utils/base64-encoding'

export const PRODUCTS_CACHE_KEY = 'Products'
export const SUBPRODUCTS_CACHE_KEY = 'Subproducts'
export const PRODUCTS_COUNTS_CACHE_KEY = 'ProductCounts'
const BASE_URL = `https://${EXTEND_API_HOST}`

/**
 * Non-RDB Version of the ProductsApi
 */
export function useGetProductQuery(
  params: GetProductRequest,
  enabled = true,
): QueryObserverResult<Product, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const { storeId, referenceId, version = '2020-08-01' } = params

  const queryFn = useCallback(async (): Promise<any> => {
    const response = await fetch(
      `${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(referenceId)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-extend-access-token': accessToken,
          accept: `application/json; version=${version};`,
        },
        method: 'GET',
      },
    )
    if (!response.ok) {
      throw new Error('Unable to fetch product')
    }

    if (response.status === 204) {
      return JSON.stringify('')
    }

    return response.json()
  }, [storeId, referenceId, accessToken, version])

  return useQuery<Product, Error>({
    queryKey: [PRODUCTS_CACHE_KEY, referenceId],
    queryFn,
    enabled: enabled && Boolean(referenceId),
  })
}

export function useLazyGetProductWithCountQuery(): UseBaseMutationResult<
  { product: Product; counts: ProductCountsGetResponse | null },
  Error,
  string,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const storeId = useAtomValue(getActiveStoreIdAtom)

  return useMutation({
    mutationFn: async (referenceId: string) => {
      const response = await fetch(
        `${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(referenceId)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: `application/json; version=2020-08-01;`,
          },
          method: 'GET',
        },
      )

      if (!response.ok) {
        throw new Error('Unable to fetch product')
      }

      const product = await response.json()

      let counts = null
      try {
        const countsResponse = await fetch(
          `${BASE_URL}/merchants-portal/stores/${storeId}/products/${encodeURIComponent(
            referenceId,
          )}/counts`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-extend-access-token': accessToken,
              accept: 'application/json; version=latest;',
            },
            method: 'GET',
          },
        )

        if (countsResponse.ok && countsResponse.headers.get('content-length') !== '0') {
          counts = await countsResponse.json()
        }
      } catch (err) {
        console.error('there was an error getting the counts')
      }

      return { product, counts }
    },
  })
}

export function useLazyGetProductQuery(): UseBaseMutationResult<Product, Error, string, void> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const storeId = useAtomValue(getActiveStoreIdAtom)

  return useMutation({
    mutationFn: async (referenceId: string) => {
      const response = await fetch(
        `${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(referenceId)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: `application/json; version=2020-08-01;`,
          },
          method: 'GET',
        },
      )

      if (response.status === 404) {
        return null
      } else if (!response.ok) {
        throw new Error('Unable to fetch product')
      }

      const product = await response.json()

      return product
    },
  })
}

export function useGetProductsQuery(storeId: string): QueryObserverResult<Product[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useQuery<Product[], Error>({
    queryKey: [PRODUCTS_CACHE_KEY],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/stores/${storeId}/products`, {
        headers: {
          'Content-Type': 'application/json',
          'x-extend-access-token': accessToken,
          accept: 'application/json; version=2020-08-01;',
        },
      })
      if (!response.ok) {
        throw Error('Unable to fetch products')
      }
      return response.json()
    },
  })
}

export function useUpdateProductMutation(): UseBaseMutationResult<
  void,
  Error,
  UpdateProductRequest,
  { previousProducts: Product[] }
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const currentSearchParams = useAtomValue(currentProductsSearchParams)

  const queryKey = currentSearchParams
    ? [PRODUCTS_CACHE_KEY, buildCacheKey(currentSearchParams)]
    : [PRODUCTS_CACHE_KEY]

  return useMutation({
    mutationFn: async ({ storeId, productId, data, version = 'default' }: UpdateProductRequest) => {
      await fetch(`${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(productId)}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-extend-access-token': accessToken,
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: JSON.stringify(data),
      })
    },
    onMutate: async ({ productId, data }) => {
      await client.cancelQueries({ queryKey })
      const previousProducts: Product[] = client.getQueryData(queryKey) || []

      client.setQueryData<{ products: Product[] }>(queryKey, (prev) => {
        if (!prev?.products || !prev.products.length) {
          return { products: [] }
        }

        const products = prev.products.map((product) =>
          product.referenceId === productId ? { ...product, ...data } : product,
        )

        if (currentSearchParams && currentSearchParams.active !== undefined) {
          return {
            products: products.filter((p) => p.enabled === currentSearchParams.active),
          }
        }

        return { products }
      })

      return { previousProducts }
    },
    onError: async (_err, _args, context) => {
      client.setQueryData(queryKey, context?.previousProducts)
    },
  })
}

export function useCreateSubproductMutation(): UseBaseMutationResult<
  void,
  Error,
  CreateSubproductRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, productId, subproductId, body }: CreateSubproductRequest) => {
      await fetch(
        `${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(
          productId,
        )}/product-mappings/${encodeURIComponent(subproductId)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: 'application/json; version=latest;',
          },
          method: 'POST',
          body: JSON.stringify(body),
        },
      )

      client.invalidateQueries([SUBPRODUCTS_CACHE_KEY])
    },
  })
}

export function useDeleteSubproductMutation(): UseBaseMutationResult<
  void,
  Error,
  DeleteSubproductRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, productId, subproductId }: DeleteSubproductRequest) => {
      await fetch(
        `${BASE_URL}/stores/${storeId}/products/${encodeURIComponent(
          productId,
        )}/product-mappings/${encodeURIComponent(subproductId)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: 'application/json; version=latest;',
          },
          method: 'DELETE',
        },
      )

      client.invalidateQueries([SUBPRODUCTS_CACHE_KEY])
    },
  })
}

/**
 * RDB Version of the ProductsApi
 */
export function useSearchProductsQuery(
  params: ProductsSearchQueryArguments,
): QueryObserverResult<ProductsSearchResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { storeId, enabled, ...query } = params
  const queryString = query ? buildQueryStringFromObject(query as Record<string, unknown>) : ''

  return useQuery<ProductsSearchResponse, Error>({
    queryKey: [PRODUCTS_CACHE_KEY, buildCacheKey(params)],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}/merchants-portal/stores/${storeId}/products/search?${queryString}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: 'application/json; version=latest;',
          },
          method: 'GET',
        },
      )
      if (!response.ok) {
        throw Error('Unable to fetch products')
      }
      return response.json()
    },
    enabled,
  })
}

export function useSearchProductsPaginatedQuery(
  params: ProductsSearchQueryArguments,
): QueryObserverResult<ProductsSearchPage, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { storeId, ...query } = params
  const queryString = query ? buildQueryStringFromObject(query as Record<string, unknown>) : ''
  const setCurrentSearchParams = useSetAtom(currentProductsSearchParams)

  return useQuery<ProductsSearchPage, Error>({
    queryKey: [PRODUCTS_CACHE_KEY, buildCacheKey(params)],
    queryFn: async () => {
      setCurrentSearchParams(params)
      const response = await fetch(
        `${BASE_URL}/merchants-portal/stores/${storeId}/products/search?${queryString}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: 'application/json; version=latest;',
          },
          method: 'GET',
        },
      )

      if (!response.ok) {
        throw Error('Unable to fetch paginated products search')
      }

      return response.json()
    },
  })
}

export function useGetSearchCountQuery(
  params: ProductsSearchQueryArguments,
): QueryObserverResult<ProductSearchCount, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { storeId, ...query } = params
  const queryString = query ? buildQueryStringFromObject(query as Record<string, unknown>) : ''

  return useQuery<ProductSearchCount, Error>({
    queryKey: [PRODUCTS_COUNTS_CACHE_KEY, params],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}/merchants-portal/stores/${storeId}/products/search-count?${queryString}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-extend-access-token': accessToken,
            accept: 'application/json; version=latest;',
          },
          method: 'GET',
        },
      )

      if (!response.ok) {
        throw Error('Unable to fetch products search count')
      }

      return response.json()
    },
  })
}

export function useGetCountsQuery(
  params: ProductCountsArguments,
): QueryObserverResult<ProductCountsGetResponse | null, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { storeId, referenceId } = params

  const queryFn = useCallback(async () => {
    if (!referenceId) {
      return null
    }
    const response = await fetch(
      `${BASE_URL}/merchants-portal/stores/${storeId}/products/${encodeURIComponent(
        referenceId,
      )}/counts`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-extend-access-token': accessToken,
          accept: 'application/json; version=latest;',
        },
        method: 'GET',
      },
    )

    if (!response.ok) {
      throw Error('Unable to fetch products search counts')
    }

    if (response.headers.get('content-length') === '0') {
      return null
    }

    return response.json()
  }, [referenceId, storeId, accessToken])

  return useQuery<ProductCountsGetResponse | null, Error>({
    queryKey: [PRODUCTS_COUNTS_CACHE_KEY, referenceId],
    queryFn,
    enabled: Boolean(referenceId),
  })
}

type ExportProductsResult = { message: string } | { url: string }
export function useExportProductsMutation(): UseBaseMutationResult<
  ExportProductsResult,
  Error,
  void,
  void
> {
  const storeId = useAtomValue(getActiveStoreIdAtom) || ''
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/stores/${storeId}/products/csv-export`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to export products')
      }

      const data = await response.json()
      return data === 'Job Started' ? { message: 'Job Started' } : { url: data }
    },
  })
}

/**
 * Utils
 */
const buildCacheKey = (args: ProductsSearchQueryArguments): string => {
  const { offset, limit, ...qsWithoutPagination } = args
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

/**
 * Shared Types
 */
export type GetProductRequest = {
  storeId: string
  referenceId: string
  version?: string
}

export type UpdateProductRequest = {
  storeId: string
  productId: string
  data: Partial<Product>
  version?: string
}

export interface CreateSubproductRequest {
  storeId: string
  productId: string
  subproductId: string
  body: SubproductCreateRequest
}

export interface DeleteSubproductRequest {
  storeId: string
  productId: string
  subproductId: string
}

export interface ProductCountsArguments {
  storeId: string
  referenceId: string
  enabled?: boolean
}

interface ProductSearchCount {
  totalProducts: number
}

export interface ProductsSearchQueryArguments extends ProductsSearchArguments {
  enabled?: boolean
  page?: number
}

export type ProductsSearchResponse = {
  products: ProductsSearchItem[]
}
