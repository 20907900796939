import type { FC } from 'react'
import React from 'react'
import { TableCell } from '@helloextend/merchants-ui'
import { Trash as TrashIcon, Button } from '@extend/zen'
import styles from './delete-button-column.module.css'

export type DeleteButtonColumnProps = {
  onClick: () => void
}

export const DeleteButtonColumn: FC<DeleteButtonColumnProps> = ({ onClick }) => {
  return (
    <TableCell columnWidth={7}>
      <div className={styles.cell}>
        <Button
          size="small"
          color="red"
          emphasis="low"
          onClick={onClick}
          icon={TrashIcon}
          data-cy="delete-integration"
          ariaLabel="Delete Expense"
        />
      </div>
    </TableCell>
  )
}
