import type { FC, SyntheticEvent } from 'react'
import React, { useCallback, useState } from 'react'
import {
  Badge,
  COLOR,
  Icon,
  IconSize,
  MenuButtonItem,
  Popover,
  PopoverAlignment,
  Stack,
  usePopover,
} from '@extend/zen'
import { Add, SettingsIcon } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { ThemePublishedStatus, useFetchAllThemesQuery } from '../../../queries/themes'
import { DownCaret } from '../../../components/icons'
import {
  createThemeNameAtom,
  currentlySelectedThemeIdAtom,
  isCreateThemeModalVisibleAtom,
  isWarningModalVisibleAtom,
  requestedSelectedThemeAtom,
} from '../../../atoms/customize-theme'
import { useSelectedTheme } from '../../../hooks/use-get-selected-theme'
import { ThemeNameDisplay } from './theme-name-display'
import { getActiveStoreIdAtom } from '../../../atoms/stores'
import styles from './themes-dropdown.module.css'

type ThemesDropdownProps = {
  isFormDirty: boolean
  isDisabled?: boolean
}

export const ThemesDropdown: FC<ThemesDropdownProps> = ({
  isFormDirty,
  isDisabled: hasEditPermissions,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const history = useHistory()
  const { theme: currentlySelectedTheme, isLoading } = useSelectedTheme()
  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, toggle } =
    usePopover<HTMLDivElement>()

  const storeId = useAtomValue(getActiveStoreIdAtom)
  const [newThemeName] = useAtom(createThemeNameAtom)
  const isDraftMode = Boolean(newThemeName)

  const { data } = useFetchAllThemesQuery({ storeId })
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const setIsCreateThemeModalVisible = useSetAtom(isCreateThemeModalVisibleAtom)
  const setIsWarningModalVisible = useSetAtom(isWarningModalVisibleAtom)
  const setRequestedSelectedTheme = useSetAtom(requestedSelectedThemeAtom)

  const transformStatus = (apiStatus: string): string => {
    return apiStatus === ThemePublishedStatus.published ? 'Published' : 'Unpublished'
  }

  const handleDropdownToggle = useCallback(() => {
    setIsOpen(!isOpen)
    toggle()
  }, [isOpen, toggle])

  const handleManageThemesClick = (): void => {
    handleDropdownToggle()
    history.push('/store/customize/themes')
  }

  const handleAddNewThemeClick = (): void => {
    handleDropdownToggle()
    setIsCreateThemeModalVisible(true)
  }

  const handleThemeClick = (e: SyntheticEvent): void => {
    const ele = e?.target as HTMLElement
    const themeName = ele?.innerText?.split('\n')[0]
    const clickedTheme = data?.themes.find((theme) => theme.name === themeName) ?? null

    if (newThemeName || isFormDirty) {
      setRequestedSelectedTheme(clickedTheme)
      setIsWarningModalVisible(true)
      handleDropdownToggle()
    } else if (clickedTheme && clickedTheme?.themeId !== currentlySelectedTheme?.themeId) {
      setCurrentThemeId(clickedTheme?.themeId)
      handleDropdownToggle()
    }
  }

  const caretClass = `${styles.caret} ${isOpen ? styles['is-open'] : ''}`.trim()

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Selected Theme</h3>
      <div
        className={styles['theme-selector']}
        data-cy="themeSelector"
        ref={triggerRef}
        onClick={handleDropdownToggle}
      >
        <div className={styles.item}>
          <ThemeNameDisplay
            isLoading={isLoading || !currentlySelectedTheme}
            isDraftMode={isDraftMode}
            draftThemeName={newThemeName}
            currentlySelectedTheme={currentlySelectedTheme}
          />
          <div className={caretClass}>
            <DownCaret />
          </div>
        </div>
      </div>

      <Popover
        ref={popoverRef}
        isPresent={isPresent}
        alignment={PopoverAlignment.center}
        maxWidth={440}
        triggerBoundingBox={triggerBoundingBox}
        footer={
          !hasEditPermissions && (
            <Stack padding={1}>
              <MenuButtonItem data-cy="dropdown-add-new-theme" onClick={handleAddNewThemeClick}>
                <div className={styles['menu-item']} data-cy="add-theme">
                  <Icon icon={Add} size={IconSize.medium} color={COLOR.NEUTRAL[800]} /> Add New
                  Theme
                </div>
              </MenuButtonItem>
              <MenuButtonItem data-cy="dropdown-manage-themes" onClick={handleManageThemesClick}>
                <div className={styles['menu-item']} data-cy="manage-themes">
                  <Icon icon={SettingsIcon} size={IconSize.medium} color={COLOR.NEUTRAL[800]} /> Manage
                  Themes
                </div>
              </MenuButtonItem>
            </Stack>
          )
        }
      >
        <Stack padding={1}>
          {data?.themes?.map((theme) => (
            <MenuButtonItem
              key={theme.name}
              isToggled={theme.name === currentlySelectedTheme?.name}
              onClick={handleThemeClick}
              data-cy={`${theme.name}:menu-item`}
            >
              <div className={styles['menu-item']}>
                {theme.name}
                <Badge
                  color={theme.status === ThemePublishedStatus.published ? 'green' : 'neutral'}
                  emphasis="medium"
                  size="regular"
                  text={transformStatus(theme.status)}
                  data-cy={`${theme.name}-badge`}
                />
              </div>
            </MenuButtonItem>
          ))}
        </Stack>
      </Popover>
    </div>
  )
}
