import React, { SyntheticEvent } from 'react'
import { CellContext, ColumnDef, Button } from '@extend/zen'
import type { CategoryProduct } from '@helloextend/extend-api-client'
import { currency, date } from '@extend/client-helpers'
import { startCase } from 'lodash'
import { useSetAtom } from 'jotai/react'
import Images from '../../../../images'
import { categoryProductModalAtom } from '../../../../atoms/category-product-modal'
import styles from './category-products-table.module.css'

type SelectedCategoryProduct = CategoryProduct & {
  coverageDuration?: string
}

export const getCategoryProductsColumns = (): Array<ColumnDef<SelectedCategoryProduct>> => {
  return [
    {
      label: 'Title',
      id: 'title',
      renderCell: TitleCell,
      isAlwaysPinned: true,
      isSortable: true,
    },
    {
      label: 'Product Reference ID',
      id: 'referenceId',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || '',
    },
    {
      label: 'Product Category',
      id: 'category',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) => {
        const value = cellData.getValue() || ''
        return startCase(value.replace(/_/g, ' '))
      },
    },
    {
      label: 'Purchase Price',
      id: 'purchasePrice',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        currency.format(cellData.getValue()) || '',
    },
    {
      label: 'List Price',
      id: 'listPrice',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        currency.format(cellData.getValue()) || '',
    },
    {
      label: 'Line Item ID',
      id: 'lineItemId',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || '',
    },
    {
      label: 'Transaction Date',
      id: 'transactionDate',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() ? date.format(Number(cellData.getValue()), 'MMM DD YYYY') : '',
    },
    {
      label: 'Fulfillment Date',
      id: 'fulfillmentDate',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() ? date.format(Number(cellData.getValue()), 'MMM DD YYYY') : '',
    },
    {
      label: 'Serial Number',
      id: 'serialNumber',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || '',
    },
    {
      label: 'Coverage Date',
      id: 'coverageDuration',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || '',
    },
    {
      label: 'Manufacturer Warranty Length Labor',
      id: 'manufacturerWarrantyLengthLabor',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || 0,
    },
    {
      label: 'Manufacturer Warranty Length Parts',
      id: 'manufacturerWarrantyLengthParts',
      renderCell: (cellData: CellContext<SelectedCategoryProduct, string>) =>
        cellData.getValue() || 0,
    },
  ]
}

function TitleCell(cellData: CellContext<SelectedCategoryProduct, string>): JSX.Element {
  const setModalState = useSetAtom(categoryProductModalAtom)
  const {
    getValue,
    row: { original },
  } = cellData

  return (
    <div className={styles['product-title']}>
      <img src={original?.imageUrl || Images.placeholderImage} alt={`${getValue}`} />
      <Button
        emphasis="low"
        text={getValue()}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          setModalState({
            isVisible: true,
            productId: original.referenceId,
          })
        }}
      />
    </div>
  )
}
