import type { ChangeEvent, FC, FocusEvent } from 'react'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  ButtonGroup,
  COLOR,
  Info as InfoIcon,
  InlineAlert,
  InlineAlertColor,
  Input,
  Radio,
  Spinner,
  ToastColor,
  ToastDuration,
  useShellContext,
  useToaster,
} from '@extend/zen'
import { inRange } from 'lodash'
import { useAtomValue } from 'jotai/react'
import { PaymentAuthorizationModal } from './payment-authorization-modal'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import { MaskedAchInput } from './masked-ach-input'
import { useGetNetsuiteACHQuery, useUpdateNetsuiteACHMutation } from '../../../queries/support'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import styles from './payment-authorization-form.module.css'

const schema = Yup.object()
  .shape({
    bankName: Yup.string().required('Please enter a bank name'),
    accountNumber: Yup.string().required('Please enter an account number'),
    routingNumber: Yup.number()
      .typeError('Characters must be numbers')
      .required('Please enter a routing number'),
    accountType: Yup.string().required('Please select an account type'),
    legalBusinessName: Yup.string().required('Please enter a legal business name'),
    accountsPayableEmail: Yup.string().email().required('Please enter a valid email address'),
    storeId: Yup.string().required('Please enter your store id'),
    authorizedSignatoryName: Yup.string().required('Please enter an authorized signatory name'),
    title: Yup.string().required('Pleases enter a title'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export const PaymentAuthorizationForm: FC = () => {
  const { getScrollableRegionRef } = useShellContext()
  const date = dayjs(new Date()).format('YYYY-MM-DD')
  const history = useHistory()
  const { toast } = useToaster()
  const { hasPermission } = usePermissions()
  const activeStore = useAtomValue(getActiveStoreAtom)
  const { data: achInfo, isLoading } = useGetNetsuiteACHQuery(activeStore?.id || '')
  const { mutateAsync: updateAch, isLoading: isUpdating } = useUpdateNetsuiteACHMutation()
  const [submitted, setSubmitted] = useState(false)
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isValid,
    setFieldValue,
    setFieldTouched,
    dirty,
    initialValues,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      bankName: achInfo?.bankName || '',
      accountNumber: achInfo?.accountNumber || '',
      routingNumber: achInfo?.routingNumber || '',
      accountType: achInfo?.accountType || '',
      legalBusinessName: achInfo?.legalBusinessName || '',
      accountsPayableEmail: achInfo?.accountsPayableEmail || '',
      storeId: activeStore?.id || '',
      authorizedSignatoryName: achInfo?.authorizedSignatoryName || '',
      title: achInfo?.title || '',
    },
    onSubmit: () => {
      setIsModalOpen(true)
    },
  })

  const [focusedInput, setFocusedInput] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const shouldViewOnly = !hasPermission(Permission.StoreSettingsFullAccess)

  useEffect(() => {
    const scrollToRef = getScrollableRegionRef()
    scrollToRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [getScrollableRegionRef])

  const onReset = (): void => {
    handleReset(null)
    history.push('/store/settings')
  }

  const isFormFilled = Boolean(
    values.bankName &&
      values.accountNumber &&
      values.routingNumber &&
      values.accountType &&
      values.legalBusinessName &&
      values.accountsPayableEmail &&
      values.storeId &&
      values.authorizedSignatoryName &&
      values.title,
  )

  const isValidAccountNumber =
    values.accountNumber === achInfo?.accountNumber || inRange(values.accountNumber.length, 5, 18)

  const isValidRoutingNumber =
    values.routingNumber === achInfo?.routingNumber || values.routingNumber.length === 9

  const isFormValid = isFormFilled && isValid && isValidAccountNumber && isValidRoutingNumber

  const handleAccountType = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('accountType', e.target.value)
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>): void => {
    setFieldTouched(e.target.id)
    setFocusedInput('')
  }

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>): void => {
    setFocusedInput(e.target.id)
  }

  const handleModalOnClickSubmit = async (): Promise<void> => {
    if (activeStore?.id) {
      try {
        const changedValues = Object.entries(values).reduce((acc, [key, value]) => {
          if (value !== initialValues[key as keyof Values]) {
            return { ...acc, [key]: value }
          }
          return acc
        }, {})

        await updateAch({
          ...changedValues,
          storeId: activeStore.id,
          date,
        })
        setSubmitted(true)
        setIsModalOpen(false)
        onReset()
        toast({
          message: 'Recurring payments authorized',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
        history.push('/store/settings')
      } catch (e: unknown) {
        toast({
          message: 'Recurring payment authorization update failed. Please try again later.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    }
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  if (isLoading) {
    return (
      <div className="flex align-items-center justify-content-center width-100 height-100">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  return (
    <div data-cy="settings:payment-authorization-form">
      <LeavePageGuard isNavBlocked={dirty && !submitted} handleLeavePage={handleLeavePage} />
      {!achInfo && (
        <InlineAlert color={InlineAlertColor.blue} icon={InfoIcon}>
          <p className={styles['inline-alert-text']}>
            Provide your bank details to get started with recurring payments. All fields are
            required.
          </p>
        </InlineAlert>
      )}
      <h2 className={styles.heading}>Bank Details for ACH Auto-debit</h2>
      <form
        className="flex flex-row flex-wrap"
        onSubmit={handleSubmit}
        data-cy="settings:payment-authorization-form:form"
      >
        <div className="flex flex-col flex-basis-100 flex-1">
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="bankName"
              label="Bank Name"
              id="bankName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bankName}
              isError={touched.bankName && Boolean(errors.bankName)}
              errorFeedback={errors.bankName}
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <MaskedAchInput
              label="Account Number"
              id="accountNumber"
              focusedInput={focusedInput}
              handleChange={handleChange}
              handleOnBlur={handleOnBlur}
              handleOnFocus={handleOnFocus}
              value={values.accountNumber}
              isValid={values.accountNumber ? isValidAccountNumber : true}
              invalidErrorMessage="U.S. account numbers must be between 5-17 characters"
              error={errors.accountNumber}
              touched={Boolean(touched.accountNumber)}
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <MaskedAchInput
              label="Routing Number"
              id="routingNumber"
              focusedInput={focusedInput}
              handleChange={handleChange}
              handleOnBlur={handleOnBlur}
              handleOnFocus={handleOnFocus}
              value={values.routingNumber}
              isValid={values.routingNumber ? isValidRoutingNumber : true}
              invalidErrorMessage="U.S. routing numbers must be 9 characters"
              error={errors.routingNumber}
              touched={Boolean(touched.routingNumber)}
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <div>
              <label htmlFor="account-type" className={styles['form-label']}>
                Account Type
              </label>
              <Radio
                data-cy="checking"
                label="Checking"
                name="checking"
                value="1"
                checked={values.accountType === '1'}
                onChange={handleAccountType}
                disabled={shouldViewOnly}
              />
              <Radio
                data-cy="savings"
                label="Savings"
                name="savings"
                value="2"
                checked={values.accountType === '2'}
                onChange={handleAccountType}
                disabled={shouldViewOnly}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-basis-100 flex-1">
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="legalBusinessName"
              label="Legal Business Name"
              id="legalBusinessName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.legalBusinessName}
              isError={touched.legalBusinessName && Boolean(errors.legalBusinessName)}
              errorFeedback={errors.legalBusinessName}
              helperText="The name that you use on the paperwork you file to create your business. If you own a corporation, it is the name on the corporation papers you file with your state, including the articles of incorporation."
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="accountsPayableEmail"
              label="Accounts Payable Email"
              id="accountsPayableEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.accountsPayableEmail}
              isError={touched.accountsPayableEmail && Boolean(errors.accountsPayableEmail)}
              errorFeedback="Please enter a valid email."
              helperText="The email address your invoices will be sent to"
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="storeId"
              label="Store ID"
              id="storeId"
              isDisabled
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.storeId}
            />
          </div>
        </div>
        <h2 className={styles.heading}>Merchant Authorization</h2>
        <div className="flex flex-col flex-basis-100 flex-1">
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="authorizedSignatoryName"
              label="Authorized Signatory Name"
              id="authorizedSignatoryName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.authorizedSignatoryName}
              isError={touched.authorizedSignatoryName && Boolean(errors.authorizedSignatoryName)}
              errorFeedback={errors.authorizedSignatoryName}
              isDisabled={shouldViewOnly}
            />
          </div>
          <div className={styles['input-wrapper']}>
            <Input data-cy="date" label="Date" id="date" value={date} isDisabled />
          </div>
        </div>
        <div className="flex flex-col flex-basis-100 flex-1">
          <div className={styles['input-wrapper']}>
            <Input
              data-cy="title"
              label="Title"
              id="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              isError={touched.title && Boolean(errors.title)}
              errorFeedback={errors.title}
              isDisabled={shouldViewOnly}
            />
          </div>
        </div>

        <div className={styles['form-buttons']}>
          <ButtonGroup>
            <Button
              type="button"
              emphasis="medium"
              onClick={onReset}
              color="neutral"
              data-cy="cancel-button"
              text={shouldViewOnly ? 'Close' : 'Cancel'}
            />
            {!shouldViewOnly && (
              <Button
                type="submit"
                color="blue"
                isDisabled={!isFormValid}
                data-cy="submit-button"
                text="Authorize recurring payments"
              />
            )}
          </ButtonGroup>
        </div>
      </form>

      <PaymentAuthorizationModal
        isOpen={isModalOpen}
        isProcessing={isUpdating}
        onClickDismiss={() => setIsModalOpen(false)}
        onClickCancel={() => setIsModalOpen(false)}
        onClickSubmit={handleModalOnClickSubmit}
      />
    </div>
  )
}
