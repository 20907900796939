// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tT9UAP-eM7XKqw7D-F1wP {\n    font-size: 16px;\n    margin-bottom: 16px;\n    line-height: 22px;\n}\n\n._3Omi7jfRab-_oqDSHyjeCN {\n    font-size: 16px;\n    margin-bottom: 32px;\n    line-height: 22px;\n}", ""]);
// Exports
exports.locals = {
	"refund-amount-text": "tT9UAP-eM7XKqw7D-F1wP",
	"detail-text": "_3Omi7jfRab-_oqDSHyjeCN"
};
module.exports = exports;
