import { useMemo } from 'react'
import { currency } from '@extend/client-helpers'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { LimitOfLiabilityAmountType, ContractType } from '@helloextend/extend-api-client'
import { startCase } from 'lodash/fp'
import { useAtomValue } from 'jotai/react'
import { useUser } from './use-user'
import { usePermissions } from './use-permissions'
import { Permission } from '../lib/permissions'
import { contractDetailsAtom } from '../atoms/contract-details'
import { useLimitOfLiability } from './use-limit-of-liability'
import { getFulfillmentEmailSentDateFromServiceOrder } from '../utils/get-fulfillment-email-sent-date-from-service-order'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { isRepair } from '../lib/service-order-helper'

export const useGetServiceOrderInformation = (
  serviceOrder: ServiceOrder | null,
  isReadonly: boolean,
): {
  isResolvable: boolean
  shouldShowResolveServiceOrder: boolean
  dataPropertyValues: DataPropertyDetails[]
} => {
  const contract = useAtomValue(contractDetailsAtom)
  const {
    remainingAmount,
    coverageAmount,
    isLoading: isLoadingEntitlements,
    isEMSLoading,
    productPriceType,
  } = useLimitOfLiability(contract)
  const { accountId } = useUser()
  const { hasPermission } = usePermissions()
  const hasClaimsFullAccess = hasPermission(Permission.StoreClaimsFullAccess)
  const fulfillmentMethodLabel = useMemo((): string => {
    if (serviceOrder?.configurations?.replacementFulfillmentMethod) {
      return {
        manual: 'Manual',
        virtual_card: `Virtual Card${
          serviceOrder?.fulfillmentMetaData?.status === 'cancelled' ? ' - Canceled' : ''
        }`,
        direct_payment: 'Direct Payment',
        discount_code: 'Discount Code',
        automated_replacement: 'Automated Replacement',
      }[serviceOrder?.configurations?.replacementFulfillmentMethod]
    }

    if (serviceOrder?.configurations?.repairFulfillmentMethod) {
      return {
        cleaning_kit: 'Cleaning Kit',
        self_repair: 'Self Repair',
        carry_in: 'Carry In',
        repair_onsite: 'Onsite Repair',
        repair_depot: 'Depot Repair',
      }[serviceOrder?.configurations?.repairFulfillmentMethod]
    }
    return ''
  }, [
    serviceOrder?.configurations?.replacementFulfillmentMethod,
    serviceOrder?.configurations?.repairFulfillmentMethod,
    serviceOrder?.fulfillmentMetaData?.status,
  ])

  const isResolvable = useMemo(() => {
    if (!serviceOrder) return false

    return (
      // This only handles replacement and general repair service orders currently, onsite and depot repairs are not processed
      // through the merchant portal
      ((serviceOrder.status === 'accepted' && serviceOrder.serviceType === 'repair') ||
        serviceOrder.status === 'replacement_approved') &&
      serviceOrder.assignedServicer?.servicerType === 'merchant' &&
      serviceOrder.assignedServicer?.authOrganizationId === accountId
    )
  }, [accountId, serviceOrder])

  const shouldShowResolveServiceOrder = !isReadonly && isResolvable && hasClaimsFullAccess

  const currencyCode = contract?.purchaseCurrency ?? contract?.currency ?? 'USD'

  const nonLOLproductListPrice = contract?.product?.listPrice
    ? currency.formatWithoutCurrencySymbol(contract?.product?.listPrice, currencyCode)
    : ''
  const hasLimitOfLiability = contract?.limitOfLiabilityAmount

  const productPrice = useMemo(() => {
    const price =
      contract.limitOfLiabilityAmountType === LimitOfLiabilityAmountType.PRODUCT_LIST_PRICE
        ? contract?.product?.listPrice
        : contract?.product?.purchasePrice

    if (price) {
      return currency.formatWithoutCurrencySymbol(price, currencyCode)
    }

    return ''
  }, [contract, currencyCode])

  const fulfillmentResolution =
    serviceOrder?.serviceType === 'replace' || serviceOrder?.serviceType === 'repair'
      ? {
          key: 'Fulfillment Resolution',
          label: 'Fulfillment Resolution',
          value: fulfillmentMethodLabel,
        }
      : { key: '', label: '', value: '' }

  const fulfillmentEmail = useMemo(() => {
    if (!serviceOrder) {
      return { key: '', label: '', value: '' }
    }
    const emailSentDate = getFulfillmentEmailSentDateFromServiceOrder(serviceOrder)
    return emailSentDate
      ? {
          key: 'Fulfillment Email',
          label: 'Fulfillment Email',
          value: emailSentDate,
        }
      : { key: '', label: '', value: '' }
  }, [serviceOrder])

  const dataPropertyValues = [
    {
      includeCopyAction: true,
      value: serviceOrder?.id,
      label: 'Service Order ID',
      key: 'Service Order ID',
    },
    {
      value: startCase(serviceOrder?.serviceType ?? ''),
      label: 'Service Type',
      key: 'Service Type',
    },
    {
      value: serviceOrder?.createdAt
        ? new Date(serviceOrder?.createdAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
        : '',
      label: 'Created Date',
      key: 'Created Date',
    },
    {
      isLoading: isLoadingEntitlements || isEMSLoading,
      value: `${remainingAmount} ${coverageAmount}`,
      label: "Contract's Remaining Coverage",
      key: "Contract's Remaining Coverage",
      helperText: `The amount of coverage remaining out of the coverage limit. This contract’s coverage limit is based on Product ${productPriceType} Price`,
      hide: contract.type !== ContractType.PCRS,
    },
    {
      value: hasLimitOfLiability ? productPrice : nonLOLproductListPrice,
      label: hasLimitOfLiability ? `Product ${productPriceType} Price` : 'Product List Price',
      key: hasLimitOfLiability ? `Product ${productPriceType} Price` : 'Product List Price',
    },
    fulfillmentResolution,
    fulfillmentEmail,
    serviceOrder && isRepair(serviceOrder)
      ? {
          label: 'Repair Completed Date',
          key: 'Repair Completed Date',
          value: serviceOrder?.repairMetaData?.repairCompletedAt
            ? new Date(serviceOrder?.repairMetaData?.repairCompletedAt).toLocaleDateString(
                'en-US',
                {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                },
              )
            : '—',
        }
      : { key: '', label: '', value: '' },
    { label: 'RMA Number', value: serviceOrder?.rmaNumber || '—', key: 'RMA Number' },
  ]

  return {
    isResolvable,
    shouldShowResolveServiceOrder,
    dataPropertyValues,
  }
}
