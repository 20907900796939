// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3NYn9qM7XjPPLMsCYhFegF {\n    display: flex;\n    flex-direction: column;\n    gap: 80px;\n}\n\n._3NYn9qM7XjPPLMsCYhFegF header {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n}\n\n._3NYn9qM7XjPPLMsCYhFegF header p:first-of-type {\n    margin-bottom: 8px;\n}\n\n._3NYn9qM7XjPPLMsCYhFegF h2 {\n\n    font-size: 20px;\n    margin: auto;\n}\n\n._3NYn9qM7XjPPLMsCYhFegF header strong {\n    color: var(--neutral-600);\n}\n\n._3NYn9qM7XjPPLMsCYhFegF a {\n    color: var(--blue-600);\n}\n\n._3NYn9qM7XjPPLMsCYhFegF a:visited {\n    color: var(--blue-600);\n}\n\n._3NYn9qM7XjPPLMsCYhFegF p {\n    margin: 0;\n}\n\n._1Ls6uzIp9x0lr7b4T5_7x4 {\n    width: 550px;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    row-gap: 60px;\n}\n\n._1Ls6uzIp9x0lr7b4T5_7x4 ul {\n    padding-inline-start: 0;\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    row-gap: 30px;\n}\n\n._1Ls6uzIp9x0lr7b4T5_7x4 li {\n    display: grid;\n    grid-template-columns: 13% 87%;\n    align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"section": "_3NYn9qM7XjPPLMsCYhFegF",
	"message-container": "_1Ls6uzIp9x0lr7b4T5_7x4"
};
module.exports = exports;
