// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._23ezUrTLNzkyKQYYA32c5V {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.s62on-areAJwR-hU2GvEH h2 {\n  font-size: 20px;\n  line-height: 28px;\n  margin-top: 0;\n  margin-bottom: 20px;\n  color: var(--blue-1000);\n  text-transform: capitalize;\n}\n\n.s62on-areAJwR-hU2GvEH .Rr-KahsOncMNGRLT4Z91l {\n  display: grid;\n  grid-template-columns: 1fr 1fr 2fr;\n  grid-auto-flow: row;\n  gap: 20px;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.s62on-areAJwR-hU2GvEH h3 {\n  font-size: 14px;\n  line-height: 18px;\n  margin-top: 24px;\n  margin-bottom: 24px;\n  color: var(--blue-1000);\n  text-transform: uppercase;\n}\n\n._2RM5QLiRCHWufMHJKlNlLX > div {\n  margin-bottom: 24px;\n}\n\n._2RM5QLiRCHWufMHJKlNlLX ul {\n  padding-inline-start: 0;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n  list-style: none;\n}\n\n._2RM5QLiRCHWufMHJKlNlLX li {\n  font-size: 14px;\n  color: var(--neutral-1000);\n  align-items: center;\n  display: flex;\n  gap: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"loading-container": "_23ezUrTLNzkyKQYYA32c5V",
	"container": "s62on-areAJwR-hU2GvEH",
	"grid": "Rr-KahsOncMNGRLT4Z91l",
	"wrapper": "_2RM5QLiRCHWufMHJKlNlLX"
};
module.exports = exports;
