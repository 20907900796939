export interface PlanTermsVersion {
  createdAt: number
  termsId: string
  version: string
  updatedAt: number
}

export interface PlanTermsType {
  termsId: string
  createdAt: number
  description: string
  versions: PlanTermsVersion[]
}

export interface PlanTermsListResponse {
  limit: number
  nextPageCursor?: string
  planTerms: PlanTermsType[]
}
export interface PlanTermsLanguage {
  createdAt: number
  language: string
  objectKey: string
  status: PlanTermsLanguageStatus
  termsId: string
  version: string
}

export enum PlanTermsLanguageStatus {
  UPLOADING = 'uploading',
  SAVED = 'saved',
}

export interface PlanTermsGetResponse {
  url: string
}

export type SupportedLanguages = 'en-us' | 'en-ca' | 'fr-ca' | 'es-us' | 'es-pr'

export interface PlanTermsSet {
  termsSetId: string
  termsId: string
  locale: string
  consumerText: string
  languageCode?: SupportedLanguages
  staticAssetUrl?: string
  createdAt: number
  updatedAt: number
  schemaVersion: number
  s3Url?: string
}

export type PlanTermsSetResponse = PlanTermsSet[]

export interface PlanTermsSetRequest {
  termsId: string
}
