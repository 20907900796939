import type { FC, ReactNode } from 'react'
import React from 'react'
import styles from './settings-container.module.css'

export type SettingsContainerProps = {
  headerText: string
  subHeader: ReactNode
}

export const SettingsContainer: FC<SettingsContainerProps> = ({
  headerText,
  subHeader,
  children,
}) => (
  <div className={styles.wrapper}>
    <header className={styles['heading-wrapper']}>
      <h2 className={styles.header} data-cy="header">
        {headerText}
      </h2>
      <h3 className={styles.subheader} data-cy="sub-header">
        {subHeader}
      </h3>
    </header>
    <div className={styles['content-wrapper']}>{children}</div>
  </div>
)
