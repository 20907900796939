import { decode } from '@helloextend/jwt'
import type { AccessToken } from '@helloextend/extend-api-client'
import { EXTEND_API_HOST } from '@helloextend/client-constants'

export function isValidToken(token?: string | null, checkAdmin = false): boolean {
  if (!token) {
    return false
  }

  try {
    const decoded = decode(token) as { admin: boolean; exp: number; iss: string }
    if (isExpired(decoded.exp)) return false
    if (checkAdmin && !decoded.admin) return false
    if (decoded.iss !== EXTEND_API_HOST) return false
    return true
  } catch (e) {
    return false
  }
}

function isExpired(expiration: number): boolean {
  return Date.now() / 1000 > expiration
}

export function getTokenSub(accessToken: AccessToken): string {
  const { sub } = decode(accessToken) as { sub: string }
  return sub
}
