import { atom } from 'jotai/vanilla'
import type { EnterpriseUserRole } from '../lib/permissions'

/* USER ATOM TYPES */
export type UserProfileReducerState = {
  accountId: string
  email: string
  firstName?: string
  id: string
  lastName?: string
  role?: EnterpriseUserRole | null
}

export const userProfileInitialState: UserProfileReducerState = {
  accountId: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  role: null,
}

/* USER ATOMS */
export const profileAtom = atom<UserProfileReducerState>(userProfileInitialState)

export const userFirstNameAtom = atom((get) => get<UserProfileReducerState>(profileAtom).firstName)

export const userIdAtom = atom((get) => get<UserProfileReducerState>(profileAtom).id)

export const userProfileAtom = atom((get) => get<UserProfileReducerState>(profileAtom))

export const isDeleteUserModalVisibleAtom = atom(false)
export const isUserDeletedAtom = atom(false)

export const isUserNameUpdatedAtom = atom<boolean>(false)

export const isUserProfileEditFormOpenAtom = atom<boolean>(false)
