import type { SyntheticEvent } from 'react'

const useSanitizedBlur = (
  handleBlur: (e: SyntheticEvent) => void,
  setFieldValue: (key: string, value: string) => void,
): { onHandleBlur: (e: SyntheticEvent) => void } => {
  const onHandleBlur = (e: SyntheticEvent): void => {
    const { id, value } = e.target as HTMLInputElement
    setFieldValue(id, value.trim())
    handleBlur(e)
  }
  return { onHandleBlur }
}

export { useSanitizedBlur }
