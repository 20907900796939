import { useMemo } from 'react'
import type { ServiceOrderShipment, ShippingCarrier } from '@helloextend/extend-api-client'
import { startCase } from 'lodash/fp'
import { date } from '@extend/client-helpers'
import { useGetServiceOrderShipmentsByServiceOrderId } from '../queries/service-orders'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { getShipmentDeliveredDate } from '../utils/get-shipment-delivered-date'

export const carrierMap: Record<ShippingCarrier, string> = {
  dhl_express: 'DHL Express',
  fedex_ground: 'FedEx',
  fedex_standard_overnight: 'FedEx',
  newgistics: 'Newgistics',
  ontrac: 'OnTrac',
  usps: 'USPS',
  ups: 'UPS',
}

export const useFailedProductShipmentData = ({
  serviceOrderId,
  packingSlipURL,
}: {
  serviceOrderId: string
  packingSlipURL: string
}): { gridData: DataPropertyDetails[] } => {
  const { data: shipments } = useGetServiceOrderShipmentsByServiceOrderId({
    serviceOrderId,
  })

  const shipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'servicer') ||
      ({} as ServiceOrderShipment)
    )
  }, [shipments])

  const shipmentDeliveredDate = getShipmentDeliveredDate(shipment)

  const gridData = [
    {
      label: 'Status',
      key: 'Status',
      value: shipment.status === 'received_by_carrier' ? 'In Transit' : startCase(shipment.status),
    },
    { value: carrierMap[shipment?.carrier as ShippingCarrier], label: 'Carrier', key: 'Carrier' },
    { label: 'Tracking Number', key: 'Tracking Number', value: shipment.trackingNumber },

    {
      label: 'Shipment Ship Date',
      key: 'Shipment Ship Date',
      value: shipment.shippedAt ? date.format(shipment.shippedAt, date.standardDateFormat) : '',
    },
    {
      label: shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date',
      key: shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date',
      value: shipmentDeliveredDate,
    },
    packingSlipURL
      ? {
          label: 'Packing Slip',
          key: 'Packing Slip',
          value: 'View Details',
          href: packingSlipURL,
          openInNew: true,
        }
      : { key: 'Packing Slip', label: 'Packing Slip', value: '' },
  ]

  return { gridData }
}
