import type {
  ContractsGetResponse,
  ContractsV2GetResponse,
  Contract20220201GetResponse,
  Money,
  CategoryProduct,
  Bundle,
  BundleComponent,
} from '@helloextend/extend-api-client'

import { ContractType } from '@helloextend/extend-api-client'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../constants/const'
import type { MerchantContract } from '../types/merchant-contract'

export function extractPricingInfo(value: number | Money): number {
  return typeof value === 'object' && value !== null ? value.amount : value
}

function getNumberAmount(input: number | Money): number {
  return typeof input === 'number' ? input : input.amount
}

export type CombinedContractResponseTypes =
  | ContractsGetResponse
  | ContractsV2GetResponse
  | Contract20220201GetResponse
export function mapContractsGetToContract(
  contractsAPIResponse: ContractsGetResponse | ContractsV2GetResponse,
): MerchantContract {
  if (contractsAPIResponse.contractVersion === '1.0') {
    return {
      type: ContractType.PCRS,
      ...contractsAPIResponse,
      plan: {
        ...contractsAPIResponse.plan,
        subcategory: contractsAPIResponse.planDetails?.pcmi_subcategory,
        purchasePrice: contractsAPIResponse.plan.purchasePrice?.amount,
        expectedPrice: contractsAPIResponse.plan.expectedPrice?.amount,
      },
      product: contractsAPIResponse.product && {
        ...contractsAPIResponse.product,
        listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
        purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
        reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
        manufacturerWarrantyLengthLabor:
          contractsAPIResponse.product.manufacturerWarrantyLengthLabor || 0,
        manufacturerWarrantyLengthParts:
          contractsAPIResponse.product.manufacturerWarrantyLengthParts || 0,
        // Contracts api responses for contract versions have different product objects and title or name are sometimes undefined.
        title: contractsAPIResponse.product.title || contractsAPIResponse.product.name || '',
      },
      transactionTotal: getNumberAmount(contractsAPIResponse.transactionTotal || 0) ?? undefined,
      planDetails: contractsAPIResponse.planDetails,
      coverageAmount: contractsAPIResponse?.product?.purchasePrice,
    }
  }

  return {
    type: ContractType.PCRS,
    ...contractsAPIResponse,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.plan?.expectedPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: extractPricingInfo(contractsAPIResponse.listPrice || 0),
    transactionTotal: contractsAPIResponse.transactionTotal,
    transactionDate: contractsAPIResponse.purchaseDate,
    coverageAmount: contractsAPIResponse?.product?.purchasePrice,
  }
}
export function mapContractsGet20220201ToContract(
  // TODO - this is a work around to get the productsList as a CategoryProduct[] type we unfortunately can't just change the definition
  // since it is also being used for the other contract versions. We should figure out a way to clean this up.
  contractsAPIResponse: Omit<Contract20220201GetResponse, 'productsList'> & { productsList?: any },
): MerchantContract {
  const contract = {
    ...contractsAPIResponse,
    currency: contractsAPIResponse.purchaseCurrency,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.listPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: contractsAPIResponse.product.reimbursementAmount ?? 0,
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: contractsAPIResponse.listPrice,
    transactionDate: contractsAPIResponse.purchaseDate,
    purchasePrice: contractsAPIResponse.purchasePrice,
    coverageAmount: contractsAPIResponse?.product?.purchasePrice,
  }

  if (contractsAPIResponse.type === ContractType.CATEGORY) {
    return {
      ...contract,
      categoryProductsList: contractsAPIResponse.productsList
        ? (contractsAPIResponse.productsList as CategoryProduct[])
        : [],
    }
  }

  if (contractsAPIResponse.type === ContractType.PRODUCT_PROTECTION_BUNDLE) {
    return {
      ...contract,
      bundlesList: contractsAPIResponse.productsList
        ? contractsAPIResponse.productsList.filter((product: Bundle) =>
            ['custom_bundle', 'standard_bundle'].includes(product.type),
          )
        : [],
      bundleComponents: contractsAPIResponse.productsList
        ? contractsAPIResponse.productsList.filter(
            (product: BundleComponent) => product.type === 'bundle_component',
          )
        : [],
    }
  }

  return contract
}

export function mapContractResponsePerApiVersion(
  apiVersion: string,
  contract: ContractsGetResponse | ContractsV2GetResponse | Contract20220201GetResponse,
): MerchantContract {
  if (apiVersion === CONTRACTS_API_VERSION_FEB_2022) {
    return mapContractsGet20220201ToContract(contract as Contract20220201GetResponse)
  }

  const hasOrderId = 'orderId' in contract
  const contractWithVersion = hasOrderId
    ? ({
        ...contract,
        contractVersion: '2.0',
      } as ContractsV2GetResponse)
    : ({
        ...contract,
        contractVersion: '1.0',
      } as ContractsGetResponse)

  return mapContractsGetToContract(contractWithVersion)
}
