// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/extend-placeholder-mountains.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._1DSHYk5tneQONu1hu-TCcf {\n    padding: 8px 0;\n}\n\n._1loXRjD40XrUCGgscgmL7y {\n    display: block;\n    overflow-y: hidden;\n    width: 178px;\n    height: 178px;\n}\n\n._1loXRjD40XrUCGgscgmL7y._3ln6lFAs96f58-g0C8YJnT {\n    width: 40px;\n    height: 40px;\n}\n\n._1fLqZetkXu5uyuXrSs2uPZ {\n    background-size: cover;\n    background: none;\n    height: 100%;\n}\n\n._1fLqZetkXu5uyuXrSs2uPZ._218Tcj9fhoPl2LbVEV9DL {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n    height: 40px;\n}\n\n._1fLqZetkXu5uyuXrSs2uPZ._218Tcj9fhoPl2LbVEV9DL._2GwYwsPLyM0Pr8SswvOnSN {\n    height: 178px;\n}\n\n.BMACybR2V5ya5E-TL0mp3 {\n    height: 100%;\n    width: 100%;\n    object-fit: contain;\n    word-break: break-all;\n    overflow-wrap: anywhere;\n    display: inline-block;\n}\n", ""]);
// Exports
exports.locals = {
	"product-cell": "_1DSHYk5tneQONu1hu-TCcf",
	"image-column": "_1loXRjD40XrUCGgscgmL7y",
	"small": "_3ln6lFAs96f58-g0C8YJnT",
	"product-image-wrapper": "_1fLqZetkXu5uyuXrSs2uPZ",
	"show-placeholder": "_218Tcj9fhoPl2LbVEV9DL",
	"default-size": "_2GwYwsPLyM0Pr8SswvOnSN",
	"product-image": "BMACybR2V5ya5E-TL0mp3"
};
module.exports = exports;
