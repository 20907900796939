import type { Theme } from '@helloextend/extend-api-rtk-query'
import type { ThemeGlobalProperties } from '../types/customize'

const PROPERTY_DISPLAY_ORDER: Array<keyof ThemeGlobalProperties> = [
  'storeLogoUrl',
  'backgroundColor',
  'fontFamily',
  'color',
  'buttonBackgroundColor',
  'buttonFontColor',
  'buttonRadius',
]

const getChangedGlobalStyles = (
  publishedTheme: Theme | null = null,
  selectedTheme: Theme | null = null,
): Array<keyof ThemeGlobalProperties> => {
  const publishedGlobalStyles = publishedTheme?.contents.global
  const selectedGlobalStyles = selectedTheme?.contents.global

  if (publishedGlobalStyles && selectedGlobalStyles) {
    const updatedKeys = new Set(
      (Object.keys(selectedGlobalStyles) as Array<keyof ThemeGlobalProperties>).filter((key) => {
        if (key === 'buttonRadius') {
          return !(
            selectedGlobalStyles.buttonRadius.size === publishedGlobalStyles.buttonRadius.size
          )
        }

        return !(selectedGlobalStyles[key] === publishedGlobalStyles[key])
      }),
    )
    return PROPERTY_DISPLAY_ORDER.filter((key) => updatedKeys.has(key))
  }

  if (!publishedGlobalStyles && selectedGlobalStyles) {
    return PROPERTY_DISPLAY_ORDER
  }

  return []
}

export { getChangedGlobalStyles, PROPERTY_DISPLAY_ORDER }
