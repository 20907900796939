import type {
  PlanTermsGetResponse,
  PlanTermsLanguage,
  PlanTermsListResponse,
  PlanTermsType,
} from '@helloextend/extend-api-client'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type {
  PlanTermsSetRequest,
  PlanTermsSetResponse,
} from '@helloextend/extend-api-client/src/models/plan-terms'
import type { PlanTermsGetUrlsResponse } from '@extend-plans/types'
import { baseQuery } from '../base-query'
import type {
  CoveredInfoResponse,
  CreateContractRefundInfoRequest,
  CreateCoveredInfoRequest,
  CreateNotCoveredInfoRequest,
  GetPlantermsResponse,
  NotCoveredInfoResponse,
  PlanTermContractRefundInfo,
  PlanTermVersion,
  PlanTermVersionCreateRequest,
  PlanTermsCreateRequest,
  PlanTermsDocumentGetRequest,
  PlanTermsUploadRequest,
  PlanTermsVersionLanguageUrlRequest,
  PlanTermsVersionLanguagesRequest,
  PlanTermsVersionsCreateResponse,
  UpdateContractRefundInfoRequest,
  UpdateCoveredInfoRequest,
  UpdateNotCoveredInfoRequest,
} from './types'

export const plansTermsApi = createApi({
  baseQuery,
  reducerPath: 'Terms',
  tagTypes: ['Terms', 'TermsId', 'TermsVersion'],
  endpoints: (build) => ({
    listPlanTerms: build.query<PlanTermsType[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: PlanTermsType[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue | FetchBaseQueryError = await fetchWithBQ(
            nextPageCursor ? `/plans/terms?cursor=${nextPageCursor}` : '/plans/terms',
          )
          if (res.error) throw new Error("Couldn't fetch plan terms")

          const payload = res.data as PlanTermsListResponse
          data.push(...payload.planTerms)
          nextPageCursor = payload.nextPageCursor
        } while (nextPageCursor)

        return { data }
      },
      providesTags: () => [{ type: 'Terms' }],
    }),
    getPlanTerms: build.query<GetPlantermsResponse, string>({
      query: (termsId) => ({
        url: `/plans/terms/${termsId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_result, _error, termsId) => [{ type: 'TermsId', id: termsId }],
    }),
    getPlanTermsByVersion: build.query<PlanTermVersion, { termsId: string; version: string }>({
      query: ({ termsId, version }) => ({
        url: `/plans/terms/${termsId}/versions/${version}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    createContractRefundInfo: build.mutation<
      PlanTermContractRefundInfo,
      CreateContractRefundInfoRequest
    >({
      query: ({ termsId, version, contractRefundInfo }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/contract-refund-info`,
        method: 'POST',
        body: contractRefundInfo,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    updateContractRefundInfo: build.mutation<
      PlanTermContractRefundInfo,
      UpdateContractRefundInfoRequest
    >({
      query: ({ termsId, version, contractRefundInfo }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/contract-refund-info`,
        method: 'PUT',
        body: contractRefundInfo,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    createCoveredInfo: build.mutation<CoveredInfoResponse, CreateCoveredInfoRequest>({
      query: ({ termsId, version, covered }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/covered-info`,
        method: 'POST',
        body: covered,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    updateCoveredInfoPlanCategory: build.mutation<CoveredInfoResponse, UpdateCoveredInfoRequest>({
      query: ({ termsId, version, planCategoryId, components, updateReason }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/covered-info/${planCategoryId}`,
        method: 'PUT',
        body: { components, updateReason },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    deleteCoveredInfoPlanCategory: build.mutation<
      void,
      { termsId: string; version: number; planCategoryId: string }
    >({
      query: ({ termsId, version, planCategoryId }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/covered-info/${planCategoryId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    createNotCoveredInfo: build.mutation<NotCoveredInfoResponse, CreateNotCoveredInfoRequest>({
      query: ({ termsId, version, notCovered }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/not-covered-info`,
        method: 'POST',
        body: notCovered,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    updateNotCoveredInfoPlanCategory: build.mutation<
      NotCoveredInfoResponse,
      UpdateNotCoveredInfoRequest
    >({
      query: ({ termsId, version, planCategoryId, components, updateReason }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/not-covered-info/${planCategoryId}`,
        method: 'PUT',
        body: { components, updateReason },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    deleteNotCoveredInfoPlanCategory: build.mutation<
      void,
      { termsId: string; version: number; planCategoryId: string }
    >({
      query: ({ termsId, version, planCategoryId }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/not-covered-info/${planCategoryId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: (_result, _error, { termsId, version }) => [
        { type: 'TermsVersion', id: `${termsId}-${version}` },
      ],
    }),
    createPlanTermsUploadUrl: build.mutation<PlanTermsGetResponse, PlanTermsCreateRequest>({
      query: ({ filename, language = 'en', description, termsId }) => ({
        url: `/plans/terms`,
        method: 'POST',
        body: {
          filename,
          language,
          description,
          termsId,
        },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    createPlanTermsVersionUploadUrl: build.mutation<
      PlanTermsVersionsCreateResponse,
      PlanTermVersionCreateRequest
    >({
      query: ({ filename, language = 'en', termsId }) => ({
        url: `/plans/terms/${termsId}/versions`,
        method: 'POST',
        body: {
          filename,
          language,
        },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    updatePlanTerms: build.mutation({
      query: ({ termsId, description }) => ({
        url: `/plans/terms/${termsId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: {
          description,
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    deletePlanTerms: build.mutation<void, string>({
      query: (termsId) => ({
        url: `/plans/terms/${termsId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    uploadTermsToS3Url: build.mutation<void, PlanTermsUploadRequest>({
      query: ({ url, file }) => ({
        url,
        method: 'PUT',
        body: file,
      }),
    }),
    getPlanTermsVersionLanguages: build.query<
      PlanTermsLanguage[],
      PlanTermsVersionLanguagesRequest
    >({
      query: ({ termsId, version }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsVersionLanguageUrl: build.query<
      PlanTermsGetResponse,
      PlanTermsVersionLanguageUrlRequest
    >({
      query: ({ termsId, version, language }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages/${language}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsSet: build.query<PlanTermsSetResponse, PlanTermsSetRequest>({
      query: ({ termsId }) => ({
        url: `/plans/terms-set/links/${termsId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsDocuments: build.query<PlanTermsGetUrlsResponse, PlanTermsDocumentGetRequest>({
      query: ({ termsId, version }) => ({
        url: `/plans/terms/${termsId}/documents${version ? `?termsVersion=${version}` : ''}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
  }),
})

export const {
  usePrefetch: usePrefetchPlanTerms,
  useListPlanTermsQuery,
  useGetPlanTermsQuery,
  useGetPlanTermsByVersionQuery,
  useCreateContractRefundInfoMutation,
  useUpdateContractRefundInfoMutation,
  useCreatePlanTermsUploadUrlMutation,
  useCreatePlanTermsVersionUploadUrlMutation,

  useUpdatePlanTermsMutation,
  useDeletePlanTermsMutation,
  useUploadTermsToS3UrlMutation,
  useGetPlanTermsVersionLanguagesQuery,
  useLazyGetPlanTermsVersionLanguagesQuery,
  useGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsVersionLanguageUrlQuery,
  useGetPlanTermsSetQuery,
  useLazyGetPlanTermsSetQuery,
  useGetPlanTermsDocumentsQuery,
  useCreateCoveredInfoMutation,
  useUpdateCoveredInfoPlanCategoryMutation,
  useDeleteCoveredInfoPlanCategoryMutation,
  useCreateNotCoveredInfoMutation,
  useUpdateNotCoveredInfoPlanCategoryMutation,
  useDeleteNotCoveredInfoPlanCategoryMutation,
} = plansTermsApi
