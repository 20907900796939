import { useEffect, useCallback } from 'react'

const useClosePageGuard = (disableAlert?: boolean): void => {
  const onBeforeUnloadWindow = useCallback(
    (event: Event) => {
      if (disableAlert) {
        return
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
      // Because of deprecations and browser inconsistencies, both of the following lines are
      // intended to cause the native browser prompt to guard against accidental navigations.
      event.preventDefault()

      // eslint-disable-next-line no-param-reassign
      event.returnValue = true
    },
    [disableAlert],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnloadWindow)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadWindow)
    }
  }, [onBeforeUnloadWindow])
}

export { useClosePageGuard }
