// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2mFSlpD-1urHyKh8n17eSt {\n    margin-bottom: 32px;\n}\n\n._2Ychc6fvu-Sy-b9zvA7Zl9 {\n    font-size: 32px;\n    font-weight: 700;\n    margin: 0 0 10px 0;\n}\n\n._3Fnxzl700DdyEPFDH1Jn4S {\n    font-size: 20px;\n}\n\n._2icblsBFDacASPaGABiEQG {\n    color: var(--blue-800);\n    font-weight: 700;\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_2mFSlpD-1urHyKh8n17eSt",
	"heading": "_2Ychc6fvu-Sy-b9zvA7Zl9",
	"subheading": "_3Fnxzl700DdyEPFDH1Jn4S",
	"reset": "_2icblsBFDacASPaGABiEQG"
};
module.exports = exports;
