import ISOAlpha2 from 'iso-3166-1-alpha-2'

/**
 * This function maps input to a valid ISO-3166-1 Alpha-2 country code.
 * If the input is already a valid 2-letter country code, it returns the input, uppercase.
 * This function defaults to 'US' if the input is unrecognizable.
 *
 * @param input string
 * @returns string - ISO-3166-1 Alpha-2 country code
 */
export const getISOAlpha2CountryCodeForCountryInput = (input: string): string => {
  // capitalize every word of the input because iso-3166-1-alpha-2 uses capitalized country names
  const capitalizedInput = input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

  // get the 2-letter country code from the country name
  const countryCode = ISOAlpha2.getCode(capitalizedInput)
  if (countryCode) return countryCode

  // else if the input is already a 2-letter country code, return it
  if (ISOAlpha2.getCountry(input.toUpperCase())) return input.toUpperCase()

  // default to US if the input is unrecognizable
  return 'US'
}
