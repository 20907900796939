import * as Yup from 'yup'
import type { Theme, ThemeUpdateRequest } from '../queries/themes'
import { FONT_FAMILIES } from '../constants/customize-font-families'
import type { ThemeGlobalProperties } from '../types/customize'

export const customizeCSSSchema = Yup.object()
  .shape({
    storeLogo: Yup.string().required('Store logo is required').default(''),
    backgroundColor: Yup.string()
      .uppercase()
      .required('Background color is required')
      .default('#FFFFFF'),
    fontFamily: Yup.string().required('Font family is required').default('Nunito Sans'),
    primaryFontColor: Yup.string()
      .uppercase()
      .required('Primary font color is required')
      .default('#141617'),
    buttonColor: Yup.string().uppercase().required('Button color is required').default('#141617'),
    buttonTextColor: Yup.string()
      .uppercase()
      .required('Button font color is required')
      .default('#FFFFFF'),
    buttonCornerRadius: Yup.number().required('Button corner radius is required').default(4),
  })
  .defined()

export type CustomizeCSSSchemaValues = Yup.InferType<typeof customizeCSSSchema>

export const mapSchemaToValues = (theme: Theme): CustomizeCSSSchemaValues => ({
  storeLogo: theme.contents.global.storeLogoUrl ?? '',
  backgroundColor: theme.contents.global.backgroundColor.toUpperCase() ?? '',
  fontFamily: mapFontFamily(theme.contents.global.fontFamily) ?? '',
  primaryFontColor: theme.contents.global.color.toUpperCase() ?? '',
  buttonColor: theme.contents.global.buttonBackgroundColor.toUpperCase() ?? '',
  buttonTextColor: theme.contents.global.buttonFontColor.toUpperCase() ?? '',
  buttonCornerRadius: theme.contents.global.buttonRadius.size ?? 0,
})

export const mapValuesToTheme = (
  values: CustomizeCSSSchemaValues,
  currentTheme: Theme,
  storeId: string,
): ThemeUpdateRequest => ({
  name: currentTheme.name,
  themeId: currentTheme.themeId,
  storeId,
  contents: {
    global: {
      storeLogoUrl: values.storeLogo,
      backgroundColor: values.backgroundColor,
      fontFamily: values.fontFamily,
      color: values.primaryFontColor,
      buttonBackgroundColor: values.buttonColor,
      buttonFontColor: values.buttonTextColor,
      buttonRadius: {
        size: values.buttonCornerRadius,
        measurement: 'px',
      },
    },
  },
})

export const mapFontFamily = (apiValue: string): string => {
  const fontFamilyValue = FONT_FAMILIES.find(
    (fontFamilyItem) => fontFamilyItem.value === apiValue,
  )?.value
  return fontFamilyValue ?? ''
}

export const mapValuesToSchema = (values: CustomizeCSSSchemaValues): ThemeGlobalProperties => ({
  storeLogoUrl: values.storeLogo,
  backgroundColor: values.backgroundColor,
  fontFamily: values.fontFamily,
  color: values.primaryFontColor,
  buttonBackgroundColor: values.buttonColor,
  buttonFontColor: values.buttonTextColor,
  buttonRadius: {
    size: values.buttonCornerRadius,
    measurement: 'px',
  },
})

export const mapGlobalOverrideToTheme = (
  theme: Theme,
  overrides: ThemeGlobalProperties,
): ThemeUpdateRequest => ({
  name: theme.name,
  themeId: theme.themeId,
  storeId: theme.storeId,
  contents: {
    global: {
      storeLogoUrl: overrides.storeLogoUrl,
      backgroundColor: overrides.backgroundColor,
      fontFamily: overrides.fontFamily,
      color: overrides.color,
      buttonBackgroundColor: overrides.buttonBackgroundColor,
      buttonFontColor: overrides.buttonFontColor,
      buttonRadius: {
        size: overrides?.buttonRadius?.size,
        measurement: 'px',
      },
    },
  },
})
