// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3xmaSfuVpGnODXyMJPDTi3 form {\n    display: flex;\n    flex-direction: column;\n    gap: 19px;\n}\n\n._3NVW-1fAx1F2LVwCPsAnXR {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    gap: 16px;\n    min-height: 90px;\n}\n\n._1mz1z-vi5e-LOxHf77Rt81 {\n  grid-column: span 2;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3xmaSfuVpGnODXyMJPDTi3",
	"grid": "_3NVW-1fAx1F2LVwCPsAnXR",
	"two-column": "_1mz1z-vi5e-LOxHf77Rt81"
};
module.exports = exports;
