import type { FC, SyntheticEvent } from 'react'
import React, { useMemo, useState } from 'react'
import { Add, AdvancedSelect, Button, Input, Label, ModalFooter, Trash } from '@extend/zen'
import { useRoleOptions } from '../../../hooks/use-role-options'
import { FullLoader } from '../../../components/full-loader'
import { useV3CreateUserForm } from '../../user/use-v3-create-user-form-r3'
import styles from './v3-create-user-form-r3.module.css'

export const V3CreateUserFormR3: FC<{ onClickCancel: () => void }> = ({ onClickCancel }) => {
  const {
    organizations,
    roleOrgMappings,
    hasChanges,
    inviteUser,
    addRow,
    removeRow,
    changeRowRole,
    changeRowOrganizations,
    isLoading,
    isInvitingUser,
    errors,
  } = useV3CreateUserForm({ onCancel: onClickCancel })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const numRows = roleOrgMappings.length

  const options = useRoleOptions()

  const canAddRow = numRows < options.length

  const mappedOrgs = useMemo(() => {
    return organizations.map((organization) => ({
      display: organization.name,
      value: organization.id,
    }))
  }, [organizations])

  if (isLoading) {
    return <FullLoader />
  }

  const handleInvite = (event: SyntheticEvent): void => {
    event.preventDefault()
    inviteUser(firstName, lastName, email)
  }

  return (
    <article className={styles.container}>
      <section className={styles['user-name-section']}>
        <div
          style={{
            flex: 1,
          }}
        >
          <Label htmlFor="firstName">First Name</Label>
          <Input
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            errorFeedback={errors?.firstName}
            isError={Boolean(errors?.firstName)}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            errorFeedback={errors?.lastName}
            isError={Boolean(errors?.lastName)}
          />
        </div>
      </section>
      <div
        style={{
          paddingBottom: 16,
        }}
      >
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          errorFeedback={errors?.email}
          isError={Boolean(errors?.email)}
        />
      </div>
      <section className={styles['roles-section']}>
        <div className={styles['label-row']}>
          <div
            style={{
              flex: 1,
            }}
          >
            <Label
              helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
              htmlFor="roles"
            >
              Role
            </Label>
          </div>
          <div
            style={{
              flex: 2,
            }}
          >
            <Label
              helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
              htmlFor="roles"
            >
              Organization
            </Label>
          </div>
        </div>
        {roleOrgMappings.map((roleOrg, index) => {
          return (
            <div className={styles['data-row']}>
              <div className={styles['role-selector']} key={`Row-${roleOrg.role}`}>
                <AdvancedSelect
                  id="roles"
                  placeholder="Role"
                  data-cy={`role-selector-${index + 1}`}
                  options={options}
                  value={roleOrg.role}
                  onChange={(e) => changeRowRole(e.target.value, index)}
                  multiple={false}
                />
              </div>
              <div className={styles['org-selector']}>
                <AdvancedSelect
                  id="organizations"
                  placeholder="Organizations"
                  data-cy={`org-selector-${index + 1}`}
                  options={mappedOrgs}
                  value={roleOrg.orgIds}
                  errorFeedback={errors?.role}
                  isError={Boolean(errors?.role)}
                  onChange={(e) => {
                    const newOrgs = Array.isArray(e.target.value)
                      ? e.target.value
                      : [e.target.value]
                    changeRowOrganizations(newOrgs, index)
                  }}
                  multiple
                />
                {numRows !== 1 ? (
                  <Button
                    emphasis="low"
                    size="small"
                    color="neutral"
                    icon={Trash}
                    onClick={() => removeRow(index)}
                  />
                ) : null}
              </div>
            </div>
          )
        })}
      </section>
      {canAddRow ? (
        <section className={styles['add-button-row']}>
          <Button
            text="Add row"
            icon={Add}
            iconPosition="right"
            onClick={addRow}
            emphasis="medium"
            size="small"
            fillContainer
          />
        </section>
      ) : null}
      <ModalFooter
        primaryButtonProps={{
          text: 'Add User',
          type: 'submit',
          onClick: handleInvite,
          isProcessing: isLoading || isInvitingUser,
          isDisabled: !hasChanges,
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          isDisabled: isLoading || isInvitingUser,
          onClick: onClickCancel,
        }}
      />
    </article>
  )
}
