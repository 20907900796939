import { useCallback } from 'react'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { QueryObserverResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { getAccountIdFromV3Token } from '../lib/jwt'

const BASE_URL = `https://${EXTEND_API_HOST}/accounts`
export const ORGS_CACHE_KEY = 'Organizations'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
}
export function useGetOrganizations(): QueryObserverResult<Organization[], Error> {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(v3AccessToken)

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${accountId}/organizations`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': v3AccessToken,
      },
    })
    if (!response.ok) {
      throw new Error('Unable to fetch organizations')
    }

    const data = await response.json()

    return data.items
  }, [accountId, v3AccessToken])

  return useQuery({
    queryKey: [ORGS_CACHE_KEY],
    queryFn,
  })
}

export type Organization = {
  accountId: string
  id: string
  name: string
  createdAt: number
}
