import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import { COLOR, Spinner } from '@extend/zen'
import type { Reply } from '@helloextend/extend-api-client'
import { Header } from './header'
import { UserInput } from './user-input'
import { ErrorMessage } from './error-message'
import { useIncredibotFlowState } from '../../../../hooks/use-incredibot-flow-state'
import styles from './contracts-create-claim.module.css'

export const ContractsCreateClaim: FC = () => {
  const { contractId } = useParams<{ contractId: string }>()

  const { contract, currentReply, hasError, isLoading } = useIncredibotFlowState(contractId)

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (contract && currentReply) {
    return (
      <>
        <Header contract={contract} />
        <UserInput contractId={contractId} />
      </>
    )
  }

  if (hasError) {
    return <ErrorMessage contractId={contractId} reply={currentReply as Reply} />
  }

  return null
}
