import type { UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai/react'
import {
  EXTEND_API_HOST,
  OKTA_BASE_URL,
  OKTA_TOKEN_ENDPOINT,
  OKTA_SCOPE,
  OKTA_CLIENT_ID_MERCHANTS,
  MERCHANTS_APP_REDIRECT_URI,
} from '@helloextend/client-constants'
import {
  v3AccessTokenAtom,
  oktaAccessTokenAtom,
  accountWithGrantsAtom,
  cachedRolesAtom,
} from '../atoms/auth'
import { idTokenAtom, oktaRefreshTokenAtom } from '../atoms/okta'
import { decodeToken, getAccountIdFromV3Token } from '../lib/jwt'
import type {
  ExchangeRequest,
  Grant,
  InviteUserR3Request,
  InviteUserRequest,
  RefreshRequest,
  TokenRequest,
} from '../types/okta'
import { USERS_CACHE_KEY } from './users-v3'
import { mapAccountWithGrants } from '../utils/map-account-with-grants'

const BASE_URL = `${OKTA_BASE_URL}`
const AUTH_BASE_URL = `https://${EXTEND_API_HOST}/auth`

const COMMON_HEADERS = {
  'content-type': 'application/x-www-form-urlencoded',
  accept: 'application/json; version=default',
}

export class UserNoGrantsError extends Error {}

export function useInviteOktaUser(): UseBaseMutationResult<void, Error, InviteUserRequest, void> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(accessToken)

  return useMutation({
    mutationFn: async ({ firstName, lastName, email, roles }) => {
      const headers = {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'x-extend-access-token': accessToken,
      }

      const response = await fetch(`${AUTH_BASE_URL}/v3/users`, {
        headers,
        method: 'POST',
        body: JSON.stringify({ firstName, lastName, email }),
      })

      if (!response.ok && response.status !== 409) {
        const message = 'Unable to invite user to okta'

        throw new Error(message)
      }

      const accountResponse = await fetch(`${AUTH_BASE_URL}/v3/users/${email}/accounts`, {
        headers,
        method: 'PUT',
        body: JSON.stringify({ firstName, lastName }),
      })

      if (!accountResponse.ok) {
        throw new Error('Unable to add user to account')
      }

      const grantResponses: Array<Promise<Response>> = []
      for (const role of roles) {
        grantResponses.push(
          fetch(`${AUTH_BASE_URL}/grants`, {
            headers,
            method: 'POST',
            body: JSON.stringify({
              userId: email,
              ern: `ERN:ACC:${accountId}`,
              role,
            }),
          }),
        )
      }
      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (r.status === 'rejected' || (r.status === 'fulfilled' && !r.value.ok)) {
            throw new Error('Unable to add all grants')
          }
        }
      })
    },
    onSettled: () => {
      client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}

export function useInviteOktaUserR3(): UseBaseMutationResult<
  void,
  Error,
  InviteUserR3Request,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(accessToken)

  return useMutation({
    mutationFn: async ({ firstName, lastName, email, roleOrgs }) => {
      const headers = {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'x-extend-access-token': accessToken,
      }

      const response = await fetch(`${AUTH_BASE_URL}/v3/users`, {
        headers,
        method: 'POST',
        body: JSON.stringify({ firstName, lastName, email }),
      })

      if (!response.ok && response.status !== 409) {
        const message = 'Unable to invite user to okta'

        throw new Error(message)
      }

      const accountResponse = await fetch(`${AUTH_BASE_URL}/v3/users/${email}/accounts`, {
        headers,
        method: 'PUT',
        body: JSON.stringify({ firstName, lastName }),
      })

      if (!accountResponse.ok) {
        throw new Error('Unable to add user to account')
      }

      const grantResponses: Array<Promise<Response>> = []
      for (const roleOrg of roleOrgs) {
        for (const orgId of roleOrg.orgIds) {
          grantResponses.push(
            fetch(`${AUTH_BASE_URL}/grants`, {
              headers,
              method: 'POST',
              body: JSON.stringify({
                userId: email,
                ern: `ERN:ACC:${accountId}:ORG:${orgId}`,
                role: roleOrg.role,
              }),
            }),
          )
        }
      }
      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (r.status === 'rejected' || (r.status === 'fulfilled' && !r.value.ok)) {
            throw new Error('Unable to add all grants')
          }
        }
      })
    },
    onSettled: () => {
      client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}

export function useGetOktaAccessTokenQuery({
  onSuccess,
}: {
  onSuccess: () => void
}): UseBaseMutationResult<{ oktaIdentityToken: string }, Error, TokenRequest, void> {
  const setOktaAccessToken = useSetAtom(oktaAccessTokenAtom)
  const setAccountWithGrants = useSetAtom(accountWithGrantsAtom)
  const setIdToken = useSetAtom(idTokenAtom)
  const setOktaRefreshToken = useSetAtom(oktaRefreshTokenAtom)

  return useMutation({
    mutationFn: async ({
      code,
      verifier,
    }: TokenRequest): Promise<{ oktaIdentityToken: string }> => {
      const oktaResponse = await fetch(`${BASE_URL}${OKTA_TOKEN_ENDPOINT}`, {
        headers: {
          ...COMMON_HEADERS,
        },
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          redirect_uri: MERCHANTS_APP_REDIRECT_URI,
          client_id: OKTA_CLIENT_ID_MERCHANTS,
          code,
          code_verifier: verifier,
          scope: OKTA_SCOPE,
        }),
      })

      if (!oktaResponse.ok) {
        throw new Error('Unable to get OKTA Access Token')
      }

      const oktaResponseData = await oktaResponse.json()

      const { access_token: oktaIdentityToken, id_token: idToken } = oktaResponseData
      setIdToken(idToken)
      if (oktaResponseData.refresh_token) {
        setOktaRefreshToken(oktaResponseData.refresh_token)
      }

      if (!oktaIdentityToken) {
        throw new Error('Okta token is undefined')
      }

      const decodedOktaIdentityToken = decodeToken(oktaIdentityToken)
      if (!decodedOktaIdentityToken) throw new Error('okta token not valid')

      const { email } = decodedOktaIdentityToken

      const userGrantsResponse = await fetch(`${AUTH_BASE_URL}/grants/users/${email}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=default',
          'x-extend-access-token': oktaIdentityToken,
        },
      })

      if (!userGrantsResponse.ok) {
        throw new Error('Unable to fetch user grants')
      }

      const userGrantsData = await userGrantsResponse.json()
      if (!userGrantsData.grants.length)
        throw new UserNoGrantsError('No grants found for the user.')

      const accountIds: string[] = [
        ...new Set<string>(userGrantsData.grants.map((grant: Grant) => grant.ern.split(':')[2])),
      ]

      const accountIdNamesResponse = await fetch(
        `https://${EXTEND_API_HOST}/accounts/login-list?accountIds=${accountIds.join(',')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json; version=default',
            'x-extend-access-token': oktaIdentityToken,
          },
        },
      )

      const { items: accountNames } = await accountIdNamesResponse.json()

      const accountWithGrants = mapAccountWithGrants(accountNames, userGrantsData.grants)

      setOktaAccessToken(oktaIdentityToken)
      setAccountWithGrants(accountWithGrants)

      return {
        oktaIdentityToken,
      }
    },
    onSuccess,
  })
}

export function useExchangeTokenMutation(): UseBaseMutationResult<
  string,
  Error,
  ExchangeRequest,
  void
> {
  const setV3AccessToken = useSetAtom(v3AccessTokenAtom)

  /**
   * accessToken - value can be either oktaIdentityToken or Extend Access Token
   */
  return useMutation({
    mutationFn: async ({ accessToken, grant }: ExchangeRequest): Promise<string> => {
      const baseExchangeBody = {
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        subject_token: accessToken,
        subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
        ern: grant.ern,
        role: grant.role,
      }
      const v3TokenExchangeResponse = await fetch(`${AUTH_BASE_URL}/oauth/token`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=default',
        },
        method: 'POST',
        body: JSON.stringify({
          ...baseExchangeBody,
          extend_token_ver: 'V3',
        }),
      })
      const v3AccessTokenData = await v3TokenExchangeResponse.json()
      setV3AccessToken(v3AccessTokenData.access_token || '')

      return v3AccessTokenData.access_token
    },
  })
}

export function useRefreshAndExchangeTokens(): UseBaseMutationResult<
  void,
  Error,
  RefreshRequest,
  void
> {
  const cachedRoles = useAtomValue(cachedRolesAtom)
  const setOktaAccessToken = useSetAtom(oktaAccessTokenAtom)
  const setIdToken = useSetAtom(idTokenAtom)
  const setOktaRefreshToken = useSetAtom(oktaRefreshTokenAtom)
  const { mutateAsync: exchangeToken } = useExchangeTokenMutation()

  return useMutation({
    mutationFn: async ({ v3AccessToken, oktaRefreshToken }): Promise<void> => {
      const accountId = getAccountIdFromV3Token(v3AccessToken)
      if (!accountId) {
        throw new Error('Unable to get account id from token')
      }

      // Given the account id within the user's token, attempt to retrieve the user's current grant
      // This will be used when exchanging for a new token so the user can continue to access the same resources
      const currentGrant = cachedRoles[accountId]
      if (!currentGrant) {
        throw new Error('No grant found for the user')
      }

      const oktaResponse = await fetch(`${BASE_URL}${OKTA_TOKEN_ENDPOINT}`, {
        headers: {
          ...COMMON_HEADERS,
        },
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: oktaRefreshToken,
          redirect_uri: MERCHANTS_APP_REDIRECT_URI,
          client_id: OKTA_CLIENT_ID_MERCHANTS,
          scope: 'openid profile email offline_access',
        }),
      })

      if (!oktaResponse.ok) {
        throw new Error('Unable to get OKTA Access Token')
      }
      const oktaResponseData = await oktaResponse.json()

      if (
        !oktaResponseData.access_token ||
        !oktaResponseData.id_token ||
        !oktaResponseData.refresh_token
      ) {
        throw new Error('OKTA token request did not return all required tokens')
      }

      const {
        access_token: newOktaAccessToken,
        id_token: newOktaIdToken,
        refresh_token: newOktaRefreshToken,
      } = oktaResponseData
      setIdToken(newOktaIdToken)
      setOktaAccessToken(newOktaAccessToken)
      setOktaRefreshToken(newOktaRefreshToken)

      await exchangeToken({ accessToken: newOktaAccessToken, grant: currentGrant })
    },
  })
}
