import type { FC } from 'react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { customLogger } from '@extend/client-helpers'
import { useAtomValue } from 'jotai/react'
import { Information } from '@extend/zen'
import { getActiveStoreIdAtom } from '../../../atoms/stores'
import { getClaimsUrl } from '../../../utils/route-helper'
import { DownloadCSVData } from '../../../components/download-csv-data'
import { DownloadCSVReport } from '../../../components/download-csv-report'
import { useExportCarrierClaimsMutation, useExportClaimsMutation } from '../../../queries/claims'
import styles from './claims-download.module.css'

export const ClaimsDownload: FC = () => {
  const { search } = useLocation()
  const { push } = useHistory()
  const urlSearchParams = new URLSearchParams(search)
  const expiry = parseInt(urlSearchParams.get('expires') || '', 10) || null
  const exportType =
    urlSearchParams.get('type')?.toLocaleLowerCase() === 'carrier' ? 'carrier' : 'all'
  const isExpired = expiry === null || Date.now() > expiry
  const storeId = useAtomValue(getActiveStoreIdAtom) || ''

  const { mutateAsync: downloadAllClaims, isLoading: isAllClaimsLoading } =
    useExportClaimsMutation()

  const {
    mutateAsync: downloadCarrierClaims,
    isLoading: isCarrierClaimsLoading,
    isError: isCarrierClaimsError,
    failureReason,
  } = useExportCarrierClaimsMutation()

  const handleDismissClick = (): void => {
    push(getClaimsUrl())
  }

  const onDownloadAllClaimsClick = async (): Promise<void> => {
    try {
      const downloadResponse = await downloadAllClaims({ storeId })
      if (downloadResponse && 'url' in downloadResponse) {
        window.open(downloadResponse.url, '_blank')
      }
    } catch (err) {
      customLogger.error(err as string)
    }
  }

  const onDownloadCarrierClaimsClick = async (): Promise<void> => {
    try {
      const downloadResponse = await downloadCarrierClaims({ storeId })
      if (downloadResponse && 'url' in downloadResponse) {
        window.open(downloadResponse.url, '_blank')
      }
    } catch (err) {
      customLogger.error(err as string)
    }
  }

  if (exportType === 'carrier') {
    const noClaimsError = failureReason?.message === 'no_claims_found'

    const noClaimsMessage = `We coudn't find any carrier claims associated with your store. If you would like to export all claims, please return to the claims page.`

    return (
      <>
        <div className={styles.container}>
          <h2>Download Carrier Claims Data</h2>
          <Information data-cy="download-info">
            <p>
              Carrier claims cover issues like lost packages or damaged packing, where the carrier
              is financially liable.
            </p>
          </Information>
        </div>
        <DownloadCSVReport
          downloadDataType="carrier claim"
          supportLink="https://docs.extend.com/docs/manage-your-claims"
          handleDismissClick={handleDismissClick}
          isProcessing={isCarrierClaimsLoading}
          onDownloadClick={onDownloadCarrierClaimsClick}
          isErrorState={isCarrierClaimsError}
          alertMessage={noClaimsError ? noClaimsMessage : undefined}
        />
      </>
    )
  }

  return (
    <>
      <h2>Download Claims Data</h2>
      <DownloadCSVData
        downloadDataType="Claims"
        downloadLink="https://docs.extend.com/docs/manage-your-claims"
        isExpired={isExpired}
        handleDismissClick={handleDismissClick}
        isProcessing={isAllClaimsLoading}
        onDownloadClick={onDownloadAllClaimsClick}
      />
    </>
  )
}
