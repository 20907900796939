import { SHOPINT_HOST } from '@helloextend/client-constants'

export const sanitizeUrl = (url: string): string => {
  return url
    .replace(/^(https?)?(:\/\/)?(www\.)?/, '')
    .replace(/\/$/, '')
    .toLowerCase()
}

export function shopifyLoginUrlParser(url: string): string {
  const shopUrl = sanitizeUrl(url)
  return `https://${SHOPINT_HOST}/auth/start?shop=${shopUrl}`
}
