import type { FC } from 'react'
import React from 'react'
import styles from './claims-contract-details-header.module.css'

export const ClaimsContractDetailsHeader: FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Contract Details</h1>
      <div className={styles.subheading}>
        These are the details for this customer&apos;s protection plan.
      </div>
    </div>
  )
}
