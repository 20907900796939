import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useAtom, useAtomValue } from 'jotai/react'
import { ProductDataTableRdb } from '../product/product-data-table-rdb/product-data-table-rdb'
import { StandardHeader } from '../../components/standard-header'
import { getProductsUrl, getProductUrl } from '../../utils/route-helper'
import { productsUrlAtom } from '../../atoms/product-breadcrumbs'
import { useSearchProductsQuery } from '../../queries/products'
import { getActiveStoreAtom } from '../../atoms/stores'

const ProductVariantsRdb: FC = () => {
  const store = useAtomValue(getActiveStoreAtom)
  const { referenceId: queryReferenceId } = useParams<{ referenceId: string }>()
  const referenceId = useMemo(() => decodeURIComponent(queryReferenceId), [queryReferenceId])

  const { data, isLoading } = useSearchProductsQuery({
    limit: 1,
    storeId: store?.id || '',
    referenceId,
  })

  const product = useMemo(() => {
    return data?.products?.length ? data.products[0] : null
  }, [data?.products])

  const productName = useMemo(() => {
    return product?.name || referenceId
  }, [product?.name, referenceId])

  const headerText = `Variants: ${!isLoading ? productName : ''}`

  const [productsUrl] = useAtom(productsUrlAtom)

  const crumbs = useMemo(() => {
    if (!product) {
      return undefined
    }

    return [
      {
        text: 'Products',
        to: productsUrl || getProductsUrl(),
      },
      {
        text: productName,
        to: getProductUrl(product.referenceId),
      },
      {
        text: 'Variants',
      },
    ]
  }, [product, productsUrl, productName])

  return (
    <>
      <StandardHeader data-cy="product-variants" pageTitle={headerText} crumbs={crumbs} />
      <ProductDataTableRdb parentReferenceId={referenceId} />
    </>
  )
}

export { ProductVariantsRdb }
