import { useEffect } from 'react'
import { ToastColor, useToaster, ToastDuration } from '@extend/zen'

const useDataDelayedToast = (isFetching: boolean, timer = 4000): void => {
  const { toast } = useToaster()

  useEffect(() => {
    if (isFetching) {
      const callToast = setTimeout(() => {
        toast({
          message: 'Retrieving results... This might take a few moments',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
      }, timer)
      return () => {
        clearTimeout(callToast)
      }
    }
    return () => {}
  }, [isFetching, toast, timer])
}

export { useDataDelayedToast }
