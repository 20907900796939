import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { COLOR, Spinner } from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { useGetProductQuery } from '../../../queries/products'
import { ProductInfoRdb } from './product-info-rdb'
import { ProductDetails } from './product-details'
import { ErrorPage } from '../../../components/error-page'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

export const Product: FC = () => {
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const { referenceId: queryReferenceId, variantId: queryVariantId } = useParams<{
    referenceId: string
    variantId?: string
  }>()
  const { referenceId, variantId } = useMemo(
    () => ({
      referenceId: decodeURIComponent(queryReferenceId),
      variantId: queryVariantId && decodeURIComponent(queryVariantId),
    }),
    [queryReferenceId, queryVariantId],
  )
  const targetId = variantId ?? referenceId

  const {
    data: product,
    isFetching: isFetchingProduct,
    isError,
    error,
  } = useGetProductQuery({
    storeId,
    referenceId: targetId,
  })

  const { data: baseProduct, isFetching: isFetchingBase } = useGetProductQuery({
    storeId,
    referenceId: product?.parentReferenceId || '',
  })

  if (isFetchingProduct || isFetchingBase) {
    return (
      <div className="flex align-items-center justify-content-center width-100 height-100">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError || !product) {
    return <ErrorPage error={error as Error} />
  }

  return (
    <>
      <ProductInfoRdb product={product} baseProduct={baseProduct} />
      <ProductDetails data-cy="product-details" product={product} />
    </>
  )
}
