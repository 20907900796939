import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Servicer,
  ServicerLocation,
  ServicerCreateRequest,
} from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type {
  CreateLocationRequest,
  UpdateLocationRequest,
  UpdateRequest,
  ListServicersRequest,
  ListServicersResponse,
  MerchantServicingSettingsUpsertResponse,
  MerchantServicingSettings,
} from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (qs: ListServicersRequest): string => {
  const { cursor, limit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const servicersApi = createApi({
  baseQuery,
  reducerPath: 'Servicers',
  tagTypes: ['servicers', 'merchant-servicing-settings'],
  endpoints: (build) => ({
    // servicers
    getServicer: build.query<Servicer, string>({
      query: (servicerId: string) => ({
        url: `/servicers/${servicerId}`,
      }),
      providesTags: (_, __err, servicerId) => [{ type: 'servicers', id: servicerId }],
    }),
    getServicersBySellerId: build.query<Servicer[], string>({
      query: (sellerId: string) => ({
        url: '/servicers/search',
        params: { sellerId },
      }),
      providesTags: (result, __err, __sellerId) =>
        result ? [...result.map(({ id }) => ({ type: 'servicers' as const, id }))] : ['servicers'],
    }),
    listServicers: build.query<ListServicersResponse, ListServicersRequest>({
      query: (params) => ({
        url: '/servicers',
        params,
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'servicers', id: buildCacheKey(qs) }]
      },
    }),
    updateServicer: build.mutation<Servicer, UpdateRequest>({
      query: ({ servicerId, body }) => ({
        url: `/servicers/${servicerId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ servicerId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicersApi.util.updateQueryData(
            'getServicer',
            servicerId,
            (draftServicer: Servicer) => {
              Object.assign(draftServicer, body)
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_, _err, { servicerId }) => [{ type: 'servicers', id: servicerId }],
    }),
    createServicerLocation: build.mutation<ServicerLocation, CreateLocationRequest>({
      query: ({ servicerId, body }) => ({
        url: `/servicers/${servicerId}/servicer-locations`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ servicerId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicersApi.util.updateQueryData(
            'getServicer',
            servicerId,
            (draftServicer: Servicer) => {
              Object.assign(draftServicer, { locations: [body] })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_, _err, { servicerId }) => [{ type: 'servicers', id: servicerId }],
    }),
    updateServicerLocation: build.mutation<ServicerLocation, UpdateLocationRequest>({
      query: ({ servicerId, servicerLocationId, body }) => ({
        url: `/servicers/${servicerId}/servicer-locations/${servicerLocationId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ servicerId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicersApi.util.updateQueryData(
            'getServicer',
            servicerId,
            (draftServicer: Servicer) => {
              Object.assign(draftServicer, { locations: [body] })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_, _err, { servicerId }) => [{ type: 'servicers', id: servicerId }],
    }),
    createServicer: build.mutation<Servicer, ServicerCreateRequest>({
      query: (body) => ({
        url: `/servicers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['servicers'],
    }),
    getMerchantServicingSettings: build.query<MerchantServicingSettings, { sellerId: string }>({
      query: ({ sellerId }) => ({
        url: `/servicers/merchant-servicing-settings/${sellerId}`,
        method: 'GET',
      }),
      providesTags: ['merchant-servicing-settings'],
    }),
    upsertMerchantServicingSettings: build.mutation<
      MerchantServicingSettingsUpsertResponse,
      MerchantServicingSettings
    >({
      query: (body) => ({
        url: '/servicers/merchant-servicing-settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['merchant-servicing-settings'],
    }),
  }),
})

export const {
  useGetServicerQuery,
  useGetServicersBySellerIdQuery,
  useLazyListServicersQuery,
  useListServicersQuery,
  useUpdateServicerMutation,
  useCreateServicerLocationMutation,
  useUpdateServicerLocationMutation,
  useCreateServicerMutation,
  useGetMerchantServicingSettingsQuery,
  useUpsertMerchantServicingSettingsMutation,
} = servicersApi
