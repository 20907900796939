// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ev2ubmSxp1i-MBUor_J_X {\n    cursor: not-allowed;\n}\n\n._1ev2ubmSxp1i-MBUor_J_X > div {\n    pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"read-only": "_1ev2ubmSxp1i-MBUor_J_X"
};
module.exports = exports;
