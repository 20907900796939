import { COLOR, Icon, IconSize, InlineAlert, InlineAlertColor, OpenInNew } from '@extend/zen'
import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './contract-created-alert.module.css'
import { contractCreatedAtom } from '../../../atoms/create-contracts-modal'
import { useAtom, useAtomValue } from 'jotai'
import { useCopyToClipboard } from '@helloextend/merchants-ui'
import { Link } from 'react-router-dom'
import { getActiveStoreIdAtom } from '../../../atoms/stores'
import { useSearchContractsQuery } from '../../../queries/contract'

const MAX_RETRIES = 8

export const ContractCreatedAlert: FC = () => {
  const copyToClipboard = useCopyToClipboard()
  const [contractCreatedInfo, setContractCreatedInfo] = useAtom(contractCreatedAtom)
  const [retries, setRetries] = useState(0)
  const [searchEnabled, setSearchEnabled] = useState(true)
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const { data } = useSearchContractsQuery({
    storeId: storeId,
    params: {
      transactionId: contractCreatedInfo?.transactionId,
    },
    apiVersion: '2022-02-01',
    enabled: searchEnabled && contractCreatedInfo && !contractCreatedInfo.contractsCreated,
  })

  // Reset the contract search when the contract ids change
  useEffect(() => {
    setSearchEnabled(true)
    setRetries(0)
  }, [contractCreatedInfo?.contractIds])

  // Handle retry of contract fetching if the contracts are not created yet
  useEffect(() => {
    if (!searchEnabled || !contractCreatedInfo || contractCreatedInfo.contractsCreated) {
      return
    }

    // Disable the search until the retry is triggered
    setSearchEnabled(false)

    const contractsCreated = contractCreatedInfo.contractIds.every((id) => {
      return data?.some((contract) => contract.id === id)
    })

    if (contractsCreated) {
      setContractCreatedInfo({
        ...contractCreatedInfo,
        contractsCreated: true,
      })
    } else {
      // If the contract are not created yet and we haven't reached the max retries, retry the search
      if (retries <= MAX_RETRIES) {
        setRetries(retries + 1)
        setTimeout(() => {
          // Retry the search by re-enabling it after 5 seconds
          setSearchEnabled(true)
        }, 5000)
      }
    }
  }, [
    searchEnabled,
    setSearchEnabled,
    contractCreatedInfo,
    data,
    setContractCreatedInfo,
    retries,
    setRetries,
  ])

  const { s, has, is } = useMemo(() => {
    const moreThanOneContract = Boolean(
      contractCreatedInfo?.contractIds && contractCreatedInfo.contractIds.length > 1,
    )
    return {
      s: moreThanOneContract ? 's' : '',
      has: moreThanOneContract ? 'have' : 'has',
      is: moreThanOneContract ? 'are' : 'is',
    }
  }, [contractCreatedInfo?.contractIds.length])

  if (!contractCreatedInfo) {
    return null
  }

  return (
    <div className={styles.container}>
      {contractCreatedInfo.contractsCreated && !contractCreatedInfo.successDismissed && (
        <div className={styles['inline-alert-wrapper']} data-cy="contract-success-alert">
          <InlineAlert
            color={InlineAlertColor.green}
            isDismissable
            onDismiss={() => {
              setContractCreatedInfo({
                ...contractCreatedInfo,
                successDismissed: true,
              })
            }}
          >
            <div className={styles.text}>
              The contract{s} for {contractCreatedInfo.customerName} {is} now ready!
            </div>
            <div className={styles['contract-id-container']}>
              <div className={styles['contract-id-header']}>Contract ID{s}:</div>
              {contractCreatedInfo.contractIds.map((id) => (
                <div key={id} className={styles['contract-id-wrapper']}>
                  <Link to={`/store/contracts/${id}`} target="_blank">
                    {id}
                    <Icon icon={OpenInNew} color={COLOR.GREEN[800]} size={IconSize.xsmall} />
                  </Link>
                </div>
              ))}
            </div>
          </InlineAlert>
        </div>
      )}
      {!contractCreatedInfo.pendingDismissed && (
        <div className={styles['inline-alert-wrapper']} data-cy="contract-pending-alert">
          <InlineAlert
            color={InlineAlertColor.blue}
            isDismissable
            onDismiss={() => {
              setContractCreatedInfo({
                ...contractCreatedInfo,
                pendingDismissed: true,
              })
            }}
            primaryButtonProps={{
              text: `Copy Contract ID${s}`,
              onClick: () =>
                copyToClipboard(`Contract ID${s}`, contractCreatedInfo.contractIds.join(',')),
            }}
          >
            <div className={styles.text}>
              The contract{s} for {contractCreatedInfo.customerName} {has} been created. It may take
              5-10 seconds to process. Once ready, you'll find the link{s} here.
            </div>
            <div className={styles['contract-id-container']}>
              <div className={styles['contract-id-header']}>Contract ID{s}:</div>
              {contractCreatedInfo.contractIds.map((id, index) => (
                <div
                  key={id}
                  className={styles['contract-id-wrapper']}
                  data-cy={`contract-id-${index}`}
                >
                  {id}
                </div>
              ))}
            </div>
          </InlineAlert>
        </div>
      )}
    </div>
  )
}
