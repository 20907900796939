import { ChangeEvent, FC, useCallback } from 'react'
import React, { useState } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import {
  Modal,
  ModalController,
  useToaster,
  Input,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import { useCreateOAuthClientMutation } from '../../queries/oauth-client'
import { isCreateIntegrationModalVisibleAtom } from '../../atoms/create-api-integration-modal'
import { DatePickerInput } from '../../components/date-picker-input'
import { createIntegrationDetailsModalAtom } from '../../atoms/api-integration-details-modal'
import styles from './create-api-integration-modal.module.css'

export const CreateApiIntegrationModal: FC = () => {
  const { toast } = useToaster()
  const { mutateAsync: createIntegration, isLoading } = useCreateOAuthClientMutation()
  const [name, setName] = useState('')
  const [validUntil, setValidUntil] = useState('')

  const [isModalVisible, setIsModalVisible] = useAtom(isCreateIntegrationModalVisibleAtom)
  const setApiIntegrationDetails = useSetAtom(createIntegrationDetailsModalAtom)

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleDateChange = (newDate: string) => {
    setValidUntil(newDate)
  }

  const handleCloseModal = (): void => {
    setIsModalVisible(false)
    setName('')
    setValidUntil('')
  }

  const handleSave = useCallback(async (): Promise<void> => {
    try {
      const date = validUntil ? new Date(validUntil).getTime() : null
      const createdIntegration = await createIntegration({
        name: name,
        validUntil: date,
      })
      setApiIntegrationDetails(createdIntegration)
      handleCloseModal()
    } catch (err) {
      toast({
        message: 'The API Integration could not be created. Please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [name, validUntil])

  return (
    <ModalController isOpen={isModalVisible}>
      <Modal
        data-cy="create-integration-modal"
        heading="New API Integration"
        primaryButtonProps={{
          onClick: handleSave,
          'data-cy': 'create-integration-modal-confirm-button',
          color: 'blue',
          text: 'Confirm',
          isProcessing: isLoading,
          isDisabled: !name,
        }}
        secondaryButtonProps={{
          onClick: handleCloseModal,
          text: 'Cancel',
        }}
        onDismissRequest={handleCloseModal}
      >
        <div className={styles.container}>
          <p>Add a new integration to your Extend account.</p>
          <div className={styles['input-row']}>
            <Input
              id="name"
              label="OAuth Client Name"
              value={name}
              autoFocus
              onChange={handleNameChange}
            />
            <DatePickerInput
              label="Expiration Date (optional)"
              helperText="Expiration Date of the OAuth Client Integration"
              value={validUntil}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </Modal>
    </ModalController>
  )
}
