// DEPRECATED COMPONENT. NOT WORTH CONVERTING TESTS TO RTL
import type { FC, MouseEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import type { RowContextMenuItem } from '@helloextend/merchants-ui'
import { DataReactTable } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router-dom'
import type { Row } from 'react-table'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { columns } from './themes-data-table-config'
import { formatThemeToTableThemeItems } from '../../../utils/format-theme-to-table-theme-items'
import type { TableTheme } from '../../../types/merchant-theme'
import {
  currentlySelectedThemeIdAtom,
  isCreateThemeModalVisibleAtom,
  isDeleteThemeModalVisibleAtom,
  isPublishThemeModalVisibleAtom,
  isRenameThemeModalVisibleAtom,
  renameThemeDisplayAtom,
  themeToDeleteAtom,
} from '../../../atoms/customize-theme'
import type { ThemeMinimal } from '../../../queries/themes'
import { ThemePublishedStatus, useFetchAllThemesQuery } from '../../../queries/themes'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

const ThemesDataTable: FC = () => {
  const { push } = useHistory()
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const setRenameThemeDisplay = useSetAtom(renameThemeDisplayAtom)
  const setThemeToDelete = useSetAtom(themeToDeleteAtom)
  const [isPublishThemeModalVisible, setIsPublishThemeModalVisible] = useAtom(
    isPublishThemeModalVisibleAtom,
  )
  const [isDeleteThemeModalVisible, setIsDeleteThemeModalVisible] = useAtom(
    isDeleteThemeModalVisibleAtom,
  )
  const [isRenameThemeModalVisible, setIsRenameThemeModalVisible] = useAtom(
    isRenameThemeModalVisibleAtom,
  )
  const [isCreateThemeModalVisible, setIsCreateThemeModalVisible] = useAtom(
    isCreateThemeModalVisibleAtom,
  )

  const { data, isLoading, isFetching } = useFetchAllThemesQuery({
    storeId,
  })

  const hasPublishedTheme = Boolean(
    data?.themes?.find((theme) => theme.status === ThemePublishedStatus.published),
  )

  const mappedData = useMemo(() => formatThemeToTableThemeItems(data?.themes ?? []), [data])

  const handleClickPublish = useCallback(
    (themeMin: ThemeMinimal) => {
      setCurrentThemeId(themeMin.themeId)
      setIsPublishThemeModalVisible(!isPublishThemeModalVisible)
    },
    [isPublishThemeModalVisible, setCurrentThemeId, setIsPublishThemeModalVisible],
  )

  const handleClickDelete = useCallback(
    (themeMin: ThemeMinimal) => {
      const themeToDelete = mappedData?.find((theme) => theme.themeId === themeMin.themeId)
      if (themeToDelete) {
        setThemeToDelete(themeToDelete)
        setIsDeleteThemeModalVisible(!isDeleteThemeModalVisible)
      }
    },
    [isDeleteThemeModalVisible, mappedData, setThemeToDelete, setIsDeleteThemeModalVisible],
  )

  const handleClickRename = useCallback(
    (themeMin: ThemeMinimal) => {
      setRenameThemeDisplay(themeMin.name)
      setCurrentThemeId(themeMin.themeId)
      setIsRenameThemeModalVisible(!isRenameThemeModalVisible)
    },
    [
      isRenameThemeModalVisible,
      setRenameThemeDisplay,
      setCurrentThemeId,
      setIsRenameThemeModalVisible,
    ],
  )

  const handleClickDuplicate = useCallback(
    (themeMin: ThemeMinimal) => {
      setCurrentThemeId(themeMin.themeId)
      setIsCreateThemeModalVisible(!isCreateThemeModalVisible)
    },
    [isCreateThemeModalVisible, setCurrentThemeId, setIsCreateThemeModalVisible],
  )

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: hasPublishedTheme ? 'status' : 'updatedAt', desc: !hasPublishedTheme }],
      pageSize: 50,
    }
  }, [hasPublishedTheme])

  const contextMenu: Array<RowContextMenuItem<TableTheme>> = useMemo(
    () => [
      {
        key: 'publish-theme',
        text: 'Publish Theme',
        onClick: handleClickPublish,
      },
      {
        key: 'delete-theme',
        text: 'Delete Theme',
        onClick: handleClickDelete,
      },
      {
        key: 'duplicate-theme',
        text: 'Duplicate Theme',
        onClick: handleClickDuplicate,
      },
      {
        key: 'rename-theme',
        text: 'Rename Theme',
        onClick: handleClickRename,
      },
    ],
    [handleClickPublish, handleClickDelete, handleClickRename, handleClickDuplicate],
  )

  const getAdditionalContextMenuItems = (
    row: Row<ThemeMinimal>,
    contextMenuItems: Array<RowContextMenuItem<TableTheme>>,
  ): Array<RowContextMenuItem<TableTheme>> => {
    if (row?.values?.status === 'PUBLISHED') {
      return contextMenuItems.slice(2, 3)
    }
    return contextMenuItems
  }

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: TableTheme): void => {
      setCurrentThemeId(rowData.themeId)
      push(`/store/customize`)
    },
    [push, setCurrentThemeId],
  )

  return (
    <DataReactTable
      data-cy="manage-themes"
      isLoading={isLoading || isFetching}
      initialState={initialState}
      onlyOneFilterAllowed
      type="themes"
      data={mappedData}
      columns={columns}
      contextMenuItems={contextMenu}
      getAdditionalContextMenuItems={getAdditionalContextMenuItems}
      onRowClick={handleRowClick}
      disableSortRemove
    />
  )
}

export { ThemesDataTable }
