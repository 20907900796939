import type { FC } from 'react'
import React, { useState } from 'react'
import { Modal, ModalController, Checkbox, Input, ContentCopy, Button } from '@extend/zen'
import { useAtom } from 'jotai/react'
import styles from './create-new-api-key-modal.module.css'
import { isCreateNewAPIKeyModalVisibleAtom } from '../../../atoms/create-new-api-key-modal'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard'

export const CreateNewAPIKeyModal: FC<{ APIKey: string }> = ({ APIKey }) => {
  const [isCreateNewAPIKeyModalVisible, setIsCreateNewAPIKeyModalVisible] = useAtom(
    isCreateNewAPIKeyModalVisibleAtom,
  )
  const [isConfirmationCheckboxChecked, toggleIsConfirmationCheckboxChecked] = useState(false)
  const copyToClipboard = useCopyToClipboard()
  const handleClickClose = (): void => {
    setIsCreateNewAPIKeyModalVisible(false)
    toggleIsConfirmationCheckboxChecked(!isConfirmationCheckboxChecked)
  }
  return (
    <ModalController isOpen={isCreateNewAPIKeyModalVisible}>
      <Modal
        size="sm"
        heading="Copy your new API Key"
        primaryButtonProps={{
          onClick: handleClickClose,
          text: 'Close',
          isDisabled: !isConfirmationCheckboxChecked,
          isProcessing: false,
          type: 'button',
        }}
      >
        <div className={styles.wrapper}>
          <p>
            Copy and store your API Key in a secure location. After you close this window,
            <strong className={styles.strong}> Extend can&apos;t show the secret again</strong>--if
            you lose it, you&apos;ll have to create a new integration.
          </p>
          <div className={styles['input-wrapper']}>
            <Input id="settingsStoreId" label="API Key" isDisabled value={APIKey} />
            <Button
              text="Copy"
              onClick={() => copyToClipboard('API Key', APIKey)}
              icon={ContentCopy}
            />
          </div>
          <Checkbox
            checked={isConfirmationCheckboxChecked}
            name="securedAndStoredCopyConfirmationCheckbox"
            disabled={false}
            onChange={() => toggleIsConfirmationCheckboxChecked(!isConfirmationCheckboxChecked)}
            label="I've copied my secret and stored it in a secure location"
          />
        </div>
      </Modal>
    </ModalController>
  )
}
