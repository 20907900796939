import type { FC } from 'react'
import React, { useState } from 'react'
import Images from '../../images'
import styles from './product-image-cell.module.css'

type ProductImageSize = 'default' | 'small'

type ProductMetaCellProps = {
  title?: string
  imageUrl?: string
  size?: ProductImageSize
}

export const ProductImageCell: FC<ProductMetaCellProps> = ({
  title,
  imageUrl,
  size = 'default',
}) => {
  const defaultTooltipText = imageUrl ? '' : 'This product has no image'
  const [tooltipText, setTooltipText] = useState(defaultTooltipText)
  const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(true)

  return (
    <div className={styles['product-cell']}>
      <div className={getImageColumnClass(size)}>
        <span data-cy="image:tooltip" data-tooltip={tooltipText}>
          <div className={getProductImageWrapperClass(shouldShowPlaceholder, size)}>
            <img
              className={styles['product-image']}
              data-cy={imageUrl ? 'meta-image' : 'meta-placeholder'}
              src={imageUrl || Images.placeholderImage}
              alt={imageUrl ? `shop display, ${title}` : 'This product has no image'}
              onLoad={() => setShouldShowPlaceholder(false)}
              onError={({ currentTarget }) => {
                /* eslint-disable no-param-reassign */
                currentTarget.onerror = null // prevents looping
                currentTarget.src = Images.placeholderImage
                currentTarget.alt = 'The link to this image is broken'
                currentTarget.dataset.cy = 'meta-placeholder'
                /* eslint-enable no-param-reassign */
                setTooltipText('The link to this image is broken')
                setShouldShowPlaceholder(true)
              }}
            />
          </div>
        </span>
      </div>
    </div>
  )
}

function getImageColumnClass(size: ProductImageSize): string {
  return size === 'small' ? `${styles['image-column']} ${styles.small}` : styles['image-column']
}

function getProductImageWrapperClass(showPlaceholder: boolean, size: ProductImageSize): string {
  const className = [styles['product-image-wrapper']]

  if (size === 'default') {
    className.push(styles['default-size'])
  }

  if (showPlaceholder) {
    className.push(styles['show-placeholder'])
  }

  return className.join(' ')
}
