import {
  Products,
  Contracts,
  Customize,
  Analytics,
  Claims,
  Settings,
  Users,
  InStore,
  Leads,
} from '@extend/zen'
import { Permission } from '../lib/permissions'
import {
  getLeadsSearchUrl,
  getProductsUrl,
  getClaimsUrl,
  getContractsUrl,
  getAnalyticsUrl,
  getCustomizeUrl,
  getInstoreUrl,
  getSettingsUrl,
  getUserManagementUrl,
} from '../utils/route-helper'
import type { DashboardSidebarMenuItemProp } from '../components/dashboard-sidebar-menu-items'

export enum DashboardSection {
  Stores = 'STORES',
  Account = 'ACCOUNT',
  None = 'NONE',
}

export const navMenuLinks: Record<DashboardSection, DashboardSidebarMenuItemProp[]> = {
  [DashboardSection.Stores]: [
    {
      text: 'Contracts',
      icon: Contracts,
      to: getContractsUrl(),
      permission: Permission.StoreContractsView,
    },
    {
      text: 'Claims',
      icon: Claims,
      to: getClaimsUrl(),
      permission: Permission.StoreClaimsView,
    },
    {
      text: 'Leads',
      icon: Leads,
      to: getLeadsSearchUrl(),
      permission: Permission.LeadsView,
    },
    {
      text: 'Products',
      icon: Products,
      to: getProductsUrl(),
      permission: Permission.ProductsView,
    },
    {
      text: 'Analytics',
      icon: Analytics,
      to: getAnalyticsUrl(),
      permission: Permission.ViewStoreAnalytics,
    },
    {
      text: 'Customize',
      icon: Customize,
      to: getCustomizeUrl(),
      permission: Permission.CustomizeView,
    },
    {
      text: 'In-Store',
      icon: InStore,
      to: getInstoreUrl(),
      permission: Permission.ManageInStore,
    },
    {
      text: 'Settings',
      icon: Settings,
      to: getSettingsUrl(),
      permission: Permission.StoreSettingsView,
    },
  ],
  [DashboardSection.Account]: [
    {
      text: 'Users',
      icon: Users,
      to: getUserManagementUrl(),
      permission: Permission.ManageUsers,
    },
  ],
  [DashboardSection.None]: [],
}
