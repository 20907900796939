import { Link } from 'react-router-dom'
import type { FC } from 'react'
import React from 'react'
import styles from './header.module.css'

interface HeaderProps {
  searchKey: string
  searchValue: string
}

export const Header: FC<HeaderProps> = ({ searchKey, searchValue }) => {
  const searchKeyText: Record<string, string> = {
    customerEmail: 'Customer Email',
    contractId: 'Contract ID',
    productSerialNumber: 'Product Serial Number',
    transactionId: 'Transaction ID',
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>Find a Contract</h1>
      <div className={styles.subheading}>
        {`Showing contracts that match ${searchKeyText[searchKey] || ''}: ${searchValue}`}&nbsp;
        <Link
          className={styles.reset}
          to={`/store/claims/new?key=${searchKey}&value=${searchValue}`}
        >
          Redo Search
        </Link>
      </div>
    </div>
  )
}
