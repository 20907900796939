import { FailureType, FailureLocation, ClaimProductSection } from '@helloextend/extend-api-client'
import type {
  Claim,
  ClaimBaseFields,
  ClaimFiler,
  Fulfillment,
} from '@helloextend/extend-api-client'
import type {
  ClaimCreateRequest,
  ClaimItem,
  GetClaimsReponse,
} from '@helloextend/extend-api-client/src/models/claim'
import type { FulfillmentActivity } from '@helloextend/extend-api-client/src/models/fulfillment-activity'
import { faker } from '@faker-js/faker/locale/en'

export function generateClaimBaseFields(overrides: Partial<ClaimBaseFields> = {}): ClaimBaseFields {
  return {
    customer: {
      name: faker.name.firstName(),
      phone: faker.phone.number('###-###-####'),
      email: faker.internet.exampleEmail(),
      shippingAddress: {
        address1: faker.address.streetAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      ...overrides.customer,
    },
    incident: {
      occurredAt: faker.date.past().getTime(),
      description: faker.random.words(20),
      productCondition: 'nonfunctional',
      failureType: FailureType.dropped,
      failureLocation: FailureLocation.on_site,
      productSection: ClaimProductSection.mattress,
      ...overrides.incident,
    },
    filedBy: {
      firstName: 'Kaley',
      ...overrides.filedBy,
    },
  }
}

// TODO: DeepPartial needs to be fixed on client (ENG-241)
export function generateClaim(overrides: any = {}): Claim {
  return {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
    reportedAt: faker.date.recent().getTime(),
    contractId: faker.datatype.uuid(),
    adminContractId: faker.datatype.uuid(),
    adminSyncStatus: 'complete',
    serviceType: 'repair_depot',
    status: 'approved',
    type: 'extended_warranty',
    filedBy: {
      firstName: 'Kaley',
    },
    supportTicketNumber: '12345',
    ...overrides,
    ...generateClaimBaseFields(overrides),
  }
}

export const generateClaimItem = (overrides: Partial<ClaimItem> = {}): ClaimItem => {
  return {
    id: faker.datatype.uuid(),
    referenceItemId: faker.datatype.uuid(),
    referenceItemType: 'product',
    claimId: faker.datatype.uuid(),
    quantity: 1,
    currencyCode: 'USD',
    listPrice: 100,
    purchasePrice: 80,
    title: faker.commerce.productName(),
    category: faker.commerce.department(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    sellerId: faker.datatype.uuid(),
    ...overrides,
  }
}

export function generateContractFileClaimMerchant(overrides: any = {}): Claim {
  return {
    adjudicationType: 'accident',
    status: 'approved',
    adminSyncStatus: 'complete',
    contractId: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    id: faker.datatype.uuid(),
    reportedAt: faker.date.recent().getTime(),
    sellerId: faker.datatype.uuid(),
    source: 'merch_admin',
    updatedAt: faker.date.recent().getTime(),
    adminContractId: faker.datatype.uuid(),
    serviceType: 'repair_depot',
    ...overrides,
    ...generateClaimBaseFields(overrides),
  }
}

export function generateFulfillment(overrides: any = {}): Fulfillment {
  return {
    value: {
      amount: faker.datatype.number({ min: 100, max: 1000 }),
      currencyCode: 'USD',
    },
    claimId: faker.datatype.uuid(),
    contractId: faker.datatype.uuid(),
    createdAt: 1589842213158,
    customerEmail: faker.internet.exampleEmail(),
    id: faker.datatype.uuid(),
    sellerId: faker.datatype.uuid(),
    status: 'pending',
    updatedAt: Date.now(),
    redemptionUrl: 'https://the-warrantinator.myshopify.com/discount/EXTEND-014880072',
    ...overrides,
  }
}

export function generateFulfillmentActivity(overrides: any = {}): FulfillmentActivity {
  return {
    id: faker.datatype.uuid(),
    type: 'product_purchased',
    contractId: faker.datatype.uuid(),
    claimId: faker.datatype.uuid(),
    fulfillmentId: faker.datatype.uuid(),
    updatedAt: new Date('2019-10-28').getTime(),
    createdAt: new Date('2019-10-28').getTime(),
    creditUsed: { amount: faker.datatype.number({ min: 1000, max: 100000 }) },
    transactionId: faker.datatype.uuid(),
    ...overrides,
  }
}

export function generateClaimRequest(
  overrides: Partial<ClaimCreateRequest> = {},
): ClaimCreateRequest {
  return generateClaimBaseFields(overrides)
}

export const generateClaimFiler = (overrides?: Partial<ClaimFiler>): ClaimFiler => ({
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  email: faker.internet.exampleEmail(),
  accountId: faker.datatype.uuid(),
  ...overrides,
})

export const generateClaimWithFulfillments = (
  claimOverrides: Partial<ClaimItem> = {},
): GetClaimsReponse => {
  return {
    ...generateClaim(claimOverrides),
    fulfillments: [
      {
        ...generateFulfillment(),
        fulfillmentActivities: [generateFulfillmentActivity()],
      },
    ],
  }
}
