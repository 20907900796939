import { useMemo } from 'react'
import { getIntegrationStatus } from '../utils/get-integration-status'
import { useGetOrganizations } from '../queries/organizations'
import type { OAuthClient } from '../queries/oauth-client'
import { useGetAPIIntegrationsListQuery } from '../queries/oauth-client'
import { getIntegrationOrganizationValue } from '../features/integration/integration-details'
import { getOrgIdFromErn } from '../utils/get-org-id-from-ern'

export type APIIntegrationMapping = {
  clientId: string
  name: string
  validUntil: string
  orgName: string
  status: string
}

export const useAllAPIClients = (): {
  isLoading: boolean
  tableItems: APIIntegrationMapping[]
  error: Error | null
} => {
  const { isLoading: isLoadingOrganizations, data: organizations } = useGetOrganizations()
  const {
    isLoading: isApiIntegrationsLoading,
    data: apiIntegrationListData,
    error,
  } = useGetAPIIntegrationsListQuery()

  const tableItems: APIIntegrationMapping[] = useMemo(() => {
    return (
      apiIntegrationListData?.clients?.map((client: OAuthClient): APIIntegrationMapping => {
        const status = getIntegrationStatus(client.validUntil)
        const orgId = getOrgIdFromErn(client.config?.ern || '')
        return {
          clientId: client.clientId,
          name: client?.config?.name,
          validUntil: client.validUntil
            ? new Date(client.validUntil).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : 'None',
          orgName: getIntegrationOrganizationValue(orgId, organizations || []),
          status,
        }
      }) || []
    )
  }, [apiIntegrationListData, organizations])

  return {
    isLoading: isLoadingOrganizations || isApiIntegrationsLoading,
    tableItems,
    error,
  }
}
