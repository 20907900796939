// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qMsvwS8-GtHeF2Lol5obk {\n    border: none;\n    height: 1px;\n    background: var(--neutral-300);\n    margin-top: 24px;\n}\n\n._2MVlylyCd2FqnRjW7WYYdV {\n    display: flex;\n    flex-direction: column;\n    margin-right: 48px;\n    max-width: 496px;\n}\n\n.zW6m28_5lRZntyt9SDkHD {\n    display: flex;\n    margin: 24px 0;\n    max-width: 334px;\n    gap: 8px\n}\n\n._1MxtPmiB1Xq2Di5XorfKCT {\n    max-width: 334px;\n}\n\n.G8K24fz2A6vC9iXR-PEo_ {\n    display: flex;\n    align-items: flex-end;\n    gap: 16px;\n}\n\n.bqJTMX_lE9K2keA9VwDWG {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n._1ckxPA3Ji53rtUJBzbK3yd {\n    margin: 16px 0;\n}\n\n.wNUHLmVyitQK5IhWnz8Ge {\n    margin: 8px 0;\n}\n\n.DMhO3guNanK3a7_19YeUV {\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n}\n\n.dbwIWiDccxrS7V8kOFiee {\n    margin-bottom: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"divider": "_2qMsvwS8-GtHeF2Lol5obk",
	"fields": "_2MVlylyCd2FqnRjW7WYYdV",
	"input": "zW6m28_5lRZntyt9SDkHD",
	"color": "_1MxtPmiB1Xq2Di5XorfKCT",
	"dropdown": "G8K24fz2A6vC9iXR-PEo_",
	"preview-label": "bqJTMX_lE9K2keA9VwDWG",
	"subheader": "_1ckxPA3Ji53rtUJBzbK3yd",
	"css-override-spacer": "wNUHLmVyitQK5IhWnz8Ge",
	"spinner": "DMhO3guNanK3a7_19YeUV",
	"image-input": "dbwIWiDccxrS7V8kOFiee"
};
module.exports = exports;
