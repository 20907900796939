import type { FC } from 'react'
import React from 'react'
import type { Claim } from '@helloextend/extend-api-client'
import { isEmpty, startCase } from 'lodash/fp'
import { Card } from '@helloextend/merchants-ui'
import { H2, Small } from '@helloextend/merchants-ui/src/components/typography'
import { date } from '@extend/client-helpers'
import styles from './contract-claims-cards.module.css'

interface ContractClaimsCardsProps {
  claims: Claim[]
}

export const ContractClaimsCards: FC<ContractClaimsCardsProps> = ({ claims }) => {
  if (isEmpty(claims)) return null

  // sort by date created: from most to least recent
  // shallow-cloning the claims object due to the .sort()
  // being a mutator method by nature
  const sortedClaims = [...claims].sort(
    (prevClaim, nextClaim) => nextClaim.createdAt - prevClaim.createdAt,
  )

  return (
    <>
      {sortedClaims.map((claim) => {
        const createdDate = date.format(claim.createdAt, date.standardDateFormat)
        return (
          <Card key={claim.id} dataQa={`claim.${claim.id}`}>
            <div className={styles['flex-wrapper']}>
              <div className={styles.header}>
                <H2>Claim Submitted</H2>
                <div className={styles['header-text']}>{`Submitted on ${createdDate}`}</div>
              </div>
              <div className={styles.details}>
                <div className={styles['flex-wrapper']}>
                  <div className={styles['details-column']}>
                    {[
                      ['Status', 'status', startCase(claim.status)],
                      ['Claim ID', 'id', claim.id],
                    ].map(([label, dataQaAttribute, value]) => {
                      return (
                        <div data-qa={`claimDetails.${dataQaAttribute}`} key={value}>
                          <Small>{label}</Small>
                          <div>{value}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )
      })}
    </>
  )
}
