import type { FC } from 'react'
import React from 'react'
import { Switch, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useSetAtom, useAtomValue } from 'jotai/react'
import { confirmProtectionDisableModalAtom } from '../../../atoms/confirm-protection-disable-modal'
import { useUpdateStoreMutation } from '../../../queries/stores'
import { getActiveStoreAtom } from '../../../atoms/stores'
import type { ProtectionType } from '../../../utils/get-protection-toggle-details'
import { getProtectionToggleDetails } from '../../../utils/get-protection-toggle-details'
import { useSlackSupportNotificationMutation } from '../../../queries/support'
import styles from './protection-toggle.module.css'

import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

export const ProtectionToggle: FC<{
  protectionType: ProtectionType
}> = ({ protectionType }) => {
  const { toast } = useToaster()
  const setIsConfirmProtectionDisableModalVisible = useSetAtom(confirmProtectionDisableModalAtom)
  const store = useAtomValue(getActiveStoreAtom)
  const { mutateAsync: updateStore } = useUpdateStoreMutation()
  const { mutate: sendSlackSupportNotification } = useSlackSupportNotificationMutation()
  const {
    capitalizedProtectionType,
    isProtectionApproved,
    isProtectionEnabled,
    isProtectionLive,
    updateStoreDataShape,
  } = getProtectionToggleDetails({
    protectionType,
    store,
    isEnabling: true,
  })
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission(Permission.StoreSettingsFullAccess)

  const toggleProtection = async (): Promise<void> => {
    if (isProtectionEnabled) {
      setIsConfirmProtectionDisableModalVisible({
        isVisible: true,
        protectionType,
        label: `${capitalizedProtectionType} Protection`,
      })
    } else {
      try {
        await updateStore({
          storeId: store?.id || '',
          store: updateStoreDataShape,
          version: 'latest',
        })
        await sendSlackSupportNotification({
          body: {
            subject: `${capitalizedProtectionType} Protection enabled for ${store?.name}`,
            message: `${store?.name} enabled ${protectionType} protection offers on store: ${store?.id}`,
          },
        })
        toast({
          message: `${capitalizedProtectionType} protection is now live in your store`,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.green,
        })
      } catch (err) {
        toast({
          message: `There was a ${protectionType} protection status update error. Please try again later`,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      }
    }
  }

  const noCursorAllowed = !hasEditPermission ? styles['not-allowed'] : ''

  return (
    <div className={styles['slider-wrapper']}>
      <div className={styles['slider-text']}>{`${capitalizedProtectionType} Protection`}</div>
      <div className={noCursorAllowed}>
        <Switch
          id={`${protectionType}-protection-toggle`}
          data-cy={`${protectionType}-protection-toggle`}
          isDisabled={!isProtectionApproved}
          isOn={isProtectionLive}
          onChange={toggleProtection}
          disabledTooltip="Requires approval by your Extend representative"
          label={isProtectionLive ? 'Live' : 'Not Live'}
        />
      </div>
    </div>
  )
}
