import React from 'react'
import { currency, date } from '@extend/client-helpers'
import { Badge } from '@extend/zen'
import type { CellContext, ColumnDefs } from '@extend/zen'

export interface ContractsTableMapping {
  title: string
  type?: string
  components?: ContractsTableMapping[]
  transactionId?: string
  coverage?: string
  referenceId: string
  purchasePrice: number
  manufacturerWarrantyLengthParts?: number
  manufacturerWarrantyLengthLabor?: number
  transactionDate?: number
  listPrice?: number
  limitOfLiability?: number
  serialNumber?: string
  category?: string
}

export const TABLE_COLUMNS: ColumnDefs<ContractsTableMapping> = [
  {
    label: 'Title',
    id: 'title',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue(),
    isAlwaysPinned: true,
    isSortable: true,
  },
  {
    label: 'Components',
    id: 'components',
  },
  {
    label: 'Type',
    id: 'type',
    isAlwaysPinned: true,
    isSortable: true,
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => {
      const type = cellData.getValue()
      if (type === 'standard_bundle') {
        const components = cellData.row.getValue('components') as ContractsTableMapping[]
        return <Badge text={`Bundle: ${components.length}`} color="blue" />
      }
      return cellData.getValue() ?? 'Product'
    },
  },
  {
    label: 'Product Reference Id',
    id: 'referenceId',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue(),
  },
  {
    label: 'Product Category',
    id: 'category',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'TransactionId',
    id: 'transactionId',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'Purchase Price',
    id: 'purchasePrice',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) =>
      cellData.getValue() ? currency.format(cellData.getValue()) : '',
  },
  {
    label: 'Coverage',
    id: 'coverage',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'Manufacturer Warranty Length Parts',
    id: 'manufacturerWarrantyLengthParts',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'Manufacturer Warranty Length Labor',
    id: 'manufacturerWarrantyLengthLabor',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'Transaction Date',
    id: 'transactionDate',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) =>
      cellData.getValue() ? date.format(Number(cellData.getValue()), 'MMM DD YYYY') : '',
  },
  {
    label: 'Serial Number',
    id: 'serialNumber',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) => cellData.getValue() ?? '',
  },
  {
    label: 'List Price',
    id: 'listPrice',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) =>
      cellData.getValue ? currency.format(cellData.getValue()) : '',
  },
  {
    label: 'LoL Amount',
    id: 'limitOfLiability',
    renderCell: (cellData: CellContext<ContractsTableMapping, string>) =>
      cellData.getValue() ? currency.format(cellData.getValue()) : '',
  },
]
