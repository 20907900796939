import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  Contract,
  Contract20220201GetResponse,
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractSearchOptionsWithTypeFilter,
} from '@helloextend/extend-api-client'
import { ALL_CONTRACT_TYPES } from '@helloextend/extend-api-client'
import { useCallback } from 'react'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { CONTRACTS_API_VERSION } from '../constants/const'
import { mapContractResponsePerApiVersion } from '../utils/map-contract-response-per-api-version'
import { v3AccessTokenAtom } from '../atoms/auth'

const BASE_URL = `https://${EXTEND_API_HOST}`
export const CONTRACT_CACHE_KEY = 'contract'
const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export function useGetContractQuery({
  contractId,
  apiVersion = CONTRACTS_API_VERSION,
  enabled = true,
}: {
  contractId: string
  apiVersion?: string
  enabled?: boolean
}): UseQueryResult<Contract, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/contracts/${contractId}`, {
      headers: {
        ...COMMON_HEADERS,
        accept: `application/json; version=${apiVersion}`,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('500 server error')
      }
      throw new Error('Unable to get contract')
    }
    const jsonData: ContractsGetResponse | ContractsV2GetResponse | Contract20220201GetResponse =
      await response.json()

    return mapContractResponsePerApiVersion(apiVersion, jsonData)
  }, [accessToken, apiVersion, contractId])

  return useQuery({
    queryKey: [CONTRACT_CACHE_KEY, contractId],
    queryFn,
    enabled,
  })
}

export function useSearchContractsQuery({
  storeId,
  params = {},
  apiVersion = CONTRACTS_API_VERSION,
  enabled = true,
}: {
  storeId?: string
  params?: ContractSearchOptionsWithTypeFilter
  apiVersion?: string
  enabled?: boolean
}): UseQueryResult<Contract[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    // Initial renders may not have a query or storeId yet
    if (!storeId || !Object.keys(params).length) {
      return []
    }

    const { typeFilter, ...restParams } = params

    const qs = new URLSearchParams({
      typeFilter: typeFilter?.join(',') ?? ALL_CONTRACT_TYPES.join(','),
      sellerId: storeId,
    })

    // Typecheck does not like the possible optional values in params so we have to check first
    Object.entries(restParams).forEach(([key, value]) => {
      if (value) {
        qs.append(key, value)
      }
    })

    const response = await fetch(`${BASE_URL}/contracts/search?${qs.toString()}`, {
      headers: {
        ...COMMON_HEADERS,
        accept: `application/json; version=${apiVersion}`,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('500 server error')
      }
      throw new Error('Unable to search contracts')
    }

    const jsonData: { contracts: ContractsGetResponse[] } = await response.json()

    return jsonData.contracts.map((contract) =>
      mapContractResponsePerApiVersion(apiVersion, contract),
    )
  }, [accessToken, apiVersion, storeId, params])

  return useQuery({
    queryKey: [CONTRACT_CACHE_KEY, storeId, params],
    queryFn,
    enabled,
  })
}

export function useUpdateContractMutation(): UseBaseMutationResult<
  void,
  Error,
  {
    contract: Partial<Contract> | { id: string; product: { serialNumber: string } }
    version?: string
  },
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ contract, version = 'latest' }) => {
      const response = await fetch(`${BASE_URL}/contracts/${contract.id}`, {
        headers: {
          ...COMMON_HEADERS,
          accept: `application/json; version=${version};`,
          'x-extend-access-token': accessToken,
        },
        method: 'PUT',
        body: JSON.stringify(contract),
      })

      if (!response.ok) {
        throw new Error('Unable to update contract')
      }

      await client.invalidateQueries([CONTRACT_CACHE_KEY])
    },
  })
}

type ExportContractsResult = { message: string } | { url: string }
export function useExportContractsMutation(): UseBaseMutationResult<
  ExportContractsResult,
  Error,
  { storeId: string },
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId }) => {
      const response = await fetch(`${BASE_URL}/contracts/csv-export?sellerId=${storeId}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to export contracts')
      }

      const data = await response.json()
      return data === 'Job Started' ? { message: 'Job Started' } : { url: data }
    },
  })
}
