import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner, COLOR } from '@extend/zen'
import type { BadgeColor } from '@extend/zen'
import { StandardHeader } from '../../components/standard-header'
import styles from './integration-details.module.css'
import { useGetOAuthClientQuery } from '../../queries/oauth-client'
import { ErrorPage } from '../../components/error-page'
import { IntegrationDetails } from './integration-details'

export const Integration: FC = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { data: apiIntegration, isLoading, isError, error } = useGetOAuthClientQuery({ clientId })

  const pageTile = apiIntegration?.config?.name || ''

  const crumbs = useMemo(() => {
    return [
      { to: '/account/integrations', text: 'Integrations' },
      { to: '/account/integrations', text: 'API Integrations' },
      {
        text: pageTile,
      },
    ]
  }, [pageTile])

  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError || !apiIntegration) {
    return <ErrorPage error={error} />
  }

  const integrationStatus = getIntegrationStatus(apiIntegration?.validUntil)

  return (
    <>
      <StandardHeader
        marginTop={10}
        marginBottom={40}
        pageTitle={pageTile}
        crumbs={crumbs}
        pageTitleBadge={{
          badgeText: integrationStatus.text,
          badgeColor: integrationStatus.color,
        }}
      />
      <IntegrationDetails integration={apiIntegration} />
    </>
  )
}

const API_INTEGRATION_STATUS: Record<'active' | 'expired', { text: string; color: BadgeColor }> = {
  active: {
    text: 'Active',
    color: 'green',
  },
  expired: {
    text: 'Expired',
    color: 'red',
  },
}

export const getIntegrationStatus = (
  endDate: number | undefined,
): { text: string; color: BadgeColor } => {
  if (!endDate) {
    return API_INTEGRATION_STATUS.active
  }

  const currentDate = Date.now()
  if (new Date(currentDate) < new Date(endDate)) {
    return API_INTEGRATION_STATUS.active
  }

  return API_INTEGRATION_STATUS.expired
}
