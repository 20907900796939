import { LeadStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '../components/badge-descriptions'

const getLeadStatusCopy = (leadStatus: LeadStatus): BadgeDescriptionItem => {
  switch (leadStatus) {
    case LeadStatus.LIVE:
      return {
        text: 'Live',
        description: 'The customer is eleigible for a post-purchase Protection Offer',
        color: 'green',
      }
    case LeadStatus.CONSUMED:
      return {
        text: 'Consumed',
        description: 'The customer has purchased the post-purchase Product Protection Offer',
        color: 'neutral',
      }
    case LeadStatus.EXPIRED:
      return {
        text: 'Expired',
        description: 'The customer is no longer eligible for a post-purchase  protection plan',
        color: 'red',
      }
    default:
      return {
        text: 'Unknown',
        description: '',
        color: 'neutral',
      }
  }
}

const statusDescriptions = [
  getLeadStatusCopy(LeadStatus.LIVE),
  getLeadStatusCopy(LeadStatus.CONSUMED),
  getLeadStatusCopy(LeadStatus.EXPIRED),
]

export { getLeadStatusCopy, statusDescriptions }
