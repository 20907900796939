import * as Yup from 'yup'

export const schema = Yup.object()
  .shape({
    firstName: Yup.string().trim().required('Please enter a first name'),
    lastName: Yup.string().trim().required('Please enter a last name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    role: Yup.string().required('Please select a user role'),
  })
  .defined()

export const v3Schema = Yup.object()
  .shape({
    firstName: Yup.string().trim().required('Please enter a first name'),
    lastName: Yup.string().trim().required('Please enter a last name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    roles: Yup.array().of(Yup.string()).required('Please select user roles'),
  })
  .defined()

export type Values = Yup.InferType<typeof schema>

export type V3Values = Yup.InferType<typeof v3Schema>
