// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1n0a7NdiHZbKFbNQ4AT3xF {\n    display: flex;\n    align-items: end;\n}\n\n.r1ym4sHSpnallg65BBdlp button {\n    padding: 0 10px;\n    height: 40px;\n}\n\n._1n0a7NdiHZbKFbNQ4AT3xF input[type=\"text\"] {\n    width: 542px;\n}\n\n._3l-RUNFLTuHJuro7zhzpwW {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}", ""]);
// Exports
exports.locals = {
	"input-wrapper": "_1n0a7NdiHZbKFbNQ4AT3xF",
	"copy-button-wrapper": "r1ym4sHSpnallg65BBdlp",
	"wrapper": "_3l-RUNFLTuHJuro7zhzpwW"
};
module.exports = exports;
