import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Cell } from 'react-table'
import { date } from '@extend/client-helpers'
import type { ClaimStatus } from '@helloextend/extend-api-client'
import { BadgeDescriptions } from '../../../components/badge-descriptions'
import { CellStatusBadge } from '../../../components/cell-status-badge'
import { claimStatusDetails } from './contract-claim-status-details'

const columns = [
  {
    Header: 'Date Reported',
    accessor: 'reportedAt',
    disableSortBy: false,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-reportedAt">{date.format(value, date.standardDateFormat)}</CellText>
    ),
  },
  {
    Header: 'Claim Id',
    accessor: 'id',
    disableSortBy: true,
    width: 25,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="id">{value}</CellText>,
  },
  {
    Header: 'Customer Email',
    accessor: 'customer.email',
    disableSortBy: true,
    width: 20,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-customerEmail">{value}</CellText>
    ),
  },
  {
    Header: 'Incident Date',
    accessor: 'incident.occurredAt',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-incedentDate">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Since Last Update',
    accessor: 'updatedAt',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="claim-sinceLastUpdate">
        {date.getRelativeTimeSinceTimestamp(value)}
      </CellText>
    ),
  },
  {
    Header: 'Status',
    HeaderAdornment: (): JSX.Element => (
      <BadgeDescriptions descriptions={Object.values(claimStatusDetails)} />
    ),
    accessor: 'status',
    disableSortBy: true,
    width: 10,
    Cell: ({ value }: Cell): JSX.Element => {
      const badgeData = claimStatusDetails[value as ClaimStatus]
      return <CellStatusBadge badgeData={badgeData} />
    },
  },
]

export { columns }
