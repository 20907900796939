import type { FC } from 'react'
import React from 'react'
import { Modal, ModalController, useToaster, ToastDuration, ToastColor } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAtom } from 'jotai/react'
import type { Values, V3Values } from './user-form-schema'
import { V3CreateUserForm } from './v3-create-user-form'
import { useInviteOktaUser } from '../../queries/okta'
import { LDFlag } from '../../constants/ld-flags'
import { V3CreateUserFormR3 } from '../account/users/v3-create-user-form-r3'
import styles from './create-user-modal.module.css'
import { isAddUserModalVisibleAtom } from '../../atoms/add-user-modal'

export const CreateUserModal: FC = () => {
  const { [LDFlag.EnterpriseTenancyR3]: FF_ENABLE_ORGANIZATIONS } = useFlags()
  const { toast } = useToaster()
  const { mutateAsync: inviteUser, isLoading: isInvitingUser } = useInviteOktaUser()
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useAtom(isAddUserModalVisibleAtom)
  const handleOnClickCreate = async (values: Values | V3Values): Promise<void> => {
    try {
      const v3Values = values as V3Values
      await inviteUser({
        firstName: v3Values.firstName,
        lastName: v3Values.lastName,
        email: v3Values.email,
        roles: v3Values.roles as string[],
      })

      toast({
        message: 'New user added!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      setIsAddUserModalVisible(false)
    } catch (err) {
      const message =
        err instanceof Error && err?.message
          ? err.message
          : 'Error occurred when sending invite, please try again later'
      toast({
        message,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isAddUserModalVisible}>
      <Modal heading="Invite a new user">
        <h3 className={styles.detail}>Add a new user to your Extend account.</h3>
        {FF_ENABLE_ORGANIZATIONS ? (
          <V3CreateUserFormR3 onClickCancel={() => setIsAddUserModalVisible(false)} />
        ) : (
          <V3CreateUserForm
            isLoading={isInvitingUser}
            onSubmit={handleOnClickCreate}
            onClickCancel={() => setIsAddUserModalVisible(false)}
          />
        )}
      </Modal>
    </ModalController>
  )
}
