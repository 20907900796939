// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1HEXGM0XGntVKfG_QXrTsb {\n  font-size: 14px;\n  line-height: 18px;\n}\n\n@media (min-width: 768px) {\n  ._3p1MfCgG6pnQBV6ns_YsLo {\n      display: grid;\n      row-gap: 16px;\n      grid-template-columns: repeat(2, 1fr);\n  }\n\n  ._1406Z0mFH98pDF2QlZ9iN2 {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n._3p1MfCgG6pnQBV6ns_YsLo label, ._3p1MfCgG6pnQBV6ns_YsLo div, ._1406Z0mFH98pDF2QlZ9iN2 label, ._1406Z0mFH98pDF2QlZ9iN2 div {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}", ""]);
// Exports
exports.locals = {
	"h4-styles": "_1HEXGM0XGntVKfG_QXrTsb",
	"grid": "_3p1MfCgG6pnQBV6ns_YsLo",
	"addressGrid": "_1406Z0mFH98pDF2QlZ9iN2"
};
module.exports = exports;
