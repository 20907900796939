// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bJ1E-4jbKX3mnu8iDUH4b {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n\n.bJ1E-4jbKX3mnu8iDUH4b img {\n  width: 75px;\n}\n\n.bJ1E-4jbKX3mnu8iDUH4b button {\n  align-self: center;\n}\n", ""]);
// Exports
exports.locals = {
	"product-title": "bJ1E-4jbKX3mnu8iDUH4b"
};
module.exports = exports;
