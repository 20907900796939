import { useCallback, useMemo } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'

export type ServiceOrderShippingLabelDestinationType = 'customer' | 'servicer'

export const SHIP_ENGINE_DOWNLOAD_BASE_URL = 'https://api.shipengine.com/v1/downloads'

type ServiceOrderShippingLabelType = {
  hasDownloadLabelUrl: boolean
  handleDownloadShippingLabel: () => void
}

export const useServiceOrderShippingLabel = (
  serviceOrder: ServiceOrder,
  destinationType: ServiceOrderShippingLabelDestinationType,
  packingSlipURL?: string,
): ServiceOrderShippingLabelType => {
  const { shippingLabels } = serviceOrder

  const downloadUrl = useMemo(() => {
    const downloadLabel = shippingLabels?.find((l) => l.destinationType === destinationType)
    // falls back to `imageData` if `pdfDownloadUrl` is not present, for backwards compatibility
    return downloadLabel?.pdfDownloadUrl || downloadLabel?.imageData || ''
  }, [destinationType, shippingLabels])

  const handleDownloadShippingLabel = useCallback((): void => {
    if (destinationType === 'servicer') {
      const isPackingSlipRequired = serviceOrder?.configurations?.packingSlipRequired
      const hasPackingSlipUrl = Boolean(packingSlipURL)

      if (isPackingSlipRequired && hasPackingSlipUrl) {
        return window.open(packingSlipURL, '_blank', 'noopener,noreferrer')?.focus()
      }
    }

    // eslint-disable-next-line consistent-return
    if (!downloadUrl.startsWith(SHIP_ENGINE_DOWNLOAD_BASE_URL)) return

    return window.open('http://www.google.com/', '_blank', 'noopener,noreferrer')?.focus()
  }, [serviceOrder, destinationType, packingSlipURL, downloadUrl])

  return {
    hasDownloadLabelUrl: Boolean(downloadUrl),
    handleDownloadShippingLabel,
  }
}
