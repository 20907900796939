import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Select, Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useUpdateClaimMutation } from '../queries/claims'
import type { MerchantClaim } from '../types/claims'
import { ClaimSelectStatus, ClaimDenialReason } from '@helloextend/extend-api-client'

export const DenyClaimModal: FC<{
  isOpen: boolean
  handleConfirm: () => void
  handleLeave: () => void
  claim: MerchantClaim
}> = ({ isOpen, handleConfirm, handleLeave, claim }) => {
  const { toast } = useToaster()
  const [claimDenialReason, setClaimDenialReason] = useState<ClaimDenialReason | null>(null)
  const { mutateAsync: updateClaim, isLoading: isUpdatingClaim } = useUpdateClaimMutation()

  const claimDenialReasons = [
    { value: 'occurred_outside_coverage', label: 'Occurred Outside Coverage' },
    { value: 'failure_not_covered', label: 'Failure Not Covered' },
    { value: 'police_report_not_provided', label: 'Police Report Not Provided' },
    { value: 'images_not_provided', label: 'Images Not Provided' },
    { value: 'under_manufacturer_warranty', label: 'Under Manufacturer Warranty' },
  ]

  const onConfirmClick = async (): Promise<void> => {
    if (!claimDenialReason) return
    try {
      await updateClaim({
        claimId: claim.id,
        body: {
          status: ClaimSelectStatus.denied,
          customer: claim.customer,
          incident: claim.incident,
          denialReason: claimDenialReason,
        },
      })
      handleConfirm()
    } catch (error) {
      toast({
        message: 'Failed to deny claim!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isOpen}>
      <Modal
        heading="Deny Claim"
        size="lg"
        primaryButtonProps={{
          'data-cy': 'deny-claim-modal-confirm',
          text: 'Confirm',
          color: 'blue',
          isProcessing: isUpdatingClaim,
          isDisabled: !claimDenialReason,
          onClick: onConfirmClick,
        }}
        secondaryButtonProps={{
          'data-cy': 'deny-claim-modal-cancel',
          text: 'Cancel',
          isProcessing: isUpdatingClaim,
          onClick: handleLeave,
        }}
      >
        <DataRow>
          <ItemWrapper>
            <Select
              label="Reason"
              id="deny-denialReason-select"
              onChange={(e: SyntheticEvent<Element>): void => {
                const { value } = e.currentTarget as HTMLInputElement
                setClaimDenialReason(value as ClaimDenialReason)
              }}
              value={claimDenialReason ?? ''}
              placeholder="—SELECT—"
              data-cy="deny-denialReason-select"
            >
              {claimDenialReasons.map((reason) => (
                <option
                  key={reason.value}
                  value={reason.value}
                  label={reason.label}
                  data-cy={`select-item-${reason.value}`}
                />
              ))}
            </Select>
          </ItemWrapper>
        </DataRow>
      </Modal>
    </ModalController>
  )
}

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})
