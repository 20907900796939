import type { CSSProperties, FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

interface BadgeProps {
  text: string
  kind: BadgeKind
}

export type BadgeKind = 'primary' | 'success' | 'danger'

const KINDS: BadgeKind[] = ['primary', 'success', 'danger']

/**
 * @deprecated Use Zen Badge component instead: `import { Badge } from '@extend/zen'`
 */
const Badge: FC<BadgeProps> = ({ text, kind }) => {
  return <Wrapper kind={kind}>{text}</Wrapper>
}

const Wrapper = styled.div<{ kind: BadgeKind }>(({ kind }) => ({
  borderRadius: 12.5,
  borderStyle: 'solid',
  borderWidth: 1,
  boxSizing: 'border-box',
  display: 'inline-block',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '23px',
  minWidth: 120,
  padding: '0 32px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  ...themeProps(kind),
}))

const themeProps = (kind: BadgeKind): CSSProperties => {
  switch (kind) {
    case 'danger':
      return {
        color: COLOR.RED[600],
        borderColor: COLOR.RED[600],
      }
    case 'success':
      return {
        color: COLOR.GREEN[500],
        borderColor: COLOR.GREEN[500],
      }
    case 'primary':
    default:
      return {
        color: COLOR.BLUESKY,
        borderColor: COLOR.BLUESKY,
      }
  }
}

export { Badge, KINDS }
