import type {
  Reply,
} from '@helloextend/extend-api-client'
import type { ConnectRequest, ConnectResponse } from '@extend-incredibot/types'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { useCallback } from 'react'
import { ClaimSessionLog } from '@helloextend/extend-api-rtk-query'
import { transformSessionLogs } from '../utils/claim-helpers'

export const SESSION_LOGS_CACHE_KEY = 'SessionLogs'

const BASE_URL = `https://${EXTEND_API_HOST}/claims-assistant`
const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
}

export function getSessionLogsList(sessionId: string) {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/session/${sessionId}/logs`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get session logs')
    }

    const jsonData = await response.json()
    const sortedData = jsonData
      ? (jsonData as ClaimSessionLog[]).sort((a, b) => a.createdAt - b.createdAt)
      : []
    return transformSessionLogs(sortedData)
  }, [accessToken, sessionId])
  return useQuery({
    queryKey: [SESSION_LOGS_CACHE_KEY, sessionId],
    queryFn,
  })
}

export function useConnectIncredibotMutation(): UseBaseMutationResult<
  ConnectResponse,
  Error,
  ConnectRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (data) => {
      const response = await fetch(`${BASE_URL}/connect`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({
          apiVersion: 'latest',
          ...data,
        }),
      })

      if (!response.ok) {
        throw new Error('Unable to connect to incredibot')
      }

      return response.json()
    },
    cacheTime: 0,
  })
}

export function useUpdateIncredibotMutation(): UseBaseMutationResult<
  Reply,
  Error,
  UpdateRequest,
  void
> {
  return useMutation({
    mutationFn: async ({ data, accessToken }) => {
      const response = await fetch(`${BASE_URL}/update`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        throw new Error('Unable to update incredibot')
      }

      return response.json()
    },
    cacheTime: 0,
  })
}

interface UpdateRequestData {
  slot: string | number
  slotValue: string | number
}

interface UpdateRequest {
  accessToken: string
  data: UpdateRequestData
}
