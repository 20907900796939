import type { CustomTableauReport } from '@helloextend/extend-api-client'
import { getAnalyticsShippingProtectionUrl, getCustomReportPath } from '../../../utils/route-helper'
import type { TabBarLinkDefinition } from '../../../components/tab-bar'

type AnalyticsTabsProps = {
  isProductProtectionApproved: boolean
  isShippingProtectionApproved: boolean
  customTableauReports?: CustomTableauReport[]
}
export const ProductProtectionTabLink = { to: '/store/analytics', text: 'Product Protection' }
export const ShippingProtectionTabLink = {
  to: getAnalyticsShippingProtectionUrl(),
  text: 'Shipping Protection',
}
export const mapReportToTabBarLink = (reports: CustomTableauReport[]): TabBarLinkDefinition[] =>
  reports.map((key, index) => {
    const title = key.title ?? `store-report-${index}`
    return {
      text: title,
      to: getCustomReportPath(key.title),
    }
  })
export const tableBarLinks = ({
  isProductProtectionApproved,
  isShippingProtectionApproved,
  customTableauReports,
}: AnalyticsTabsProps): TabBarLinkDefinition[] => [
  ...(isProductProtectionApproved ? [ProductProtectionTabLink] : []),
  ...(isShippingProtectionApproved ? [ShippingProtectionTabLink] : []),
  ...(customTableauReports ? mapReportToTabBarLink(customTableauReports) : []),
]
