// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2I3bbgfo1WM8Z7lWvNKaOh {\n    display: flex;\n    flex-direction: row;\n    gap: 32px;\n}\n\n._2A9O6QU1kXYBMGDm114PwE {\n    flex-grow: 1;\n}\n\n.uC5DACG3ka05-ie_boK7Y {\n    flex-grow: 0;\n}\n\n.XEae_XWjz17CKxZwYisrI {\n    margin-top: 8px;\n    width: 172px;\n    height: 172px;\n}\n\n.uHfbVQfKK02W38MxnaS8F {\n    display: inline-flex;\n    position: absolute;\n    margin-left: 16px;\n    align-items: center;\n}\n\n.tMyoj6ECw3Rrfo5VFjAHa {\n    padding-left: 4px;\n}\n\n._10wiyfR4X2orno-PWaGxSO {\n    color: var(--neutral-1000);\n    font-size: 24px;\n    line-height: 32px;\n    margin-top: 0;\n    margin-bottom: 16px;\n    padding: 0;\n}\n\n.SyFfbibsYLimSgOqz20Xb {\n    margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_2I3bbgfo1WM8Z7lWvNKaOh",
	"flex-grow": "_2A9O6QU1kXYBMGDm114PwE",
	"flex-no-grow": "uC5DACG3ka05-ie_boK7Y",
	"title-aside": "XEae_XWjz17CKxZwYisrI",
	"badge-container": "uHfbVQfKK02W38MxnaS8F",
	"descriptions-container": "tMyoj6ECw3Rrfo5VFjAHa",
	"heading": "_10wiyfR4X2orno-PWaGxSO",
	"buttons-container": "SyFfbibsYLimSgOqz20Xb"
};
module.exports = exports;
