// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1-DgGFGunSC8tN08UQmDaW {\n    display: flex;\n    border-bottom: 1px solid var(--neutral-200);\n}\n\n.O3TFg9nOwhgXuAbnK8zIT {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._2zFP6u1lrEopIMMdSFEwKw {\n    color: var(--neutral-1000);\n    font-size: 12px;\n    margin: 16px 0;\n}\n\n._2N006ibJ3u6fh77Cs9c1I7 {\n    margin: 16px 0 32px 0;\n}\n\n._27SjhzFlB3J3q8PRKPuHIh {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n\n._2YBi8ImO52l9Qh6oplaPDC:hover {\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_1-DgGFGunSC8tN08UQmDaW",
	"details": "O3TFg9nOwhgXuAbnK8zIT",
	"plan": "_2zFP6u1lrEopIMMdSFEwKw",
	"plans": "_2N006ibJ3u6fh77Cs9c1I7",
	"label": "_27SjhzFlB3J3q8PRKPuHIh",
	"link": "_2YBi8ImO52l9Qh6oplaPDC"
};
module.exports = exports;
