// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2OCr2uMgftvl7ZeHQtnEqS {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    margin-bottom: 20px;\n}\n\n._3q1nqeibm-GRtg2_UQP5aY {\n    margin-left: 8px;\n}\n\n._1W11A47RNQ8QQ5SeJ0IloL {\n    display: flex;\n    justify-content: flex-end;\n    margin: 40px 40px 40px 0;\n}\n\n._1W11A47RNQ8QQ5SeJ0IloL button:nth-of-type(2) {\n    margin-left: 16px;\n}\n\n._1-6OrOcQ0Z9Hh961hc4EPe {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n}\n\n._13JUFC-qa-QbTyQQZL0Abo {\n    display: flex;\n    align-items: center;\n}\n\n._3TBR6lXTuI7leGmnZCZ8c8 {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"row": "_2OCr2uMgftvl7ZeHQtnEqS",
	"submit-button-wrapper": "_3q1nqeibm-GRtg2_UQP5aY",
	"button-group": "_1W11A47RNQ8QQ5SeJ0IloL",
	"user-detail-label": "_1-6OrOcQ0Z9Hh961hc4EPe",
	"flex-center": "_13JUFC-qa-QbTyQQZL0Abo",
	"no-margin": "_3TBR6lXTuI7leGmnZCZ8c8"
};
module.exports = exports;
