// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Svq09zvp3sX8MdvCIO5gg {\n  padding: 32px;\n}\n\n._3V_aQq-86FJaVijU8fXScf {\n  display: flex;\n  justify-content: end;\n  gap: 10px;\n}", ""]);
// Exports
exports.locals = {
	"integrations-container": "Svq09zvp3sX8MdvCIO5gg",
	"add-integration-button-wrapper": "_3V_aQq-86FJaVijU8fXScf"
};
module.exports = exports;
