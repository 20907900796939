// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YpKm453QiWBYvJn5bFcdM {\n    margin-bottom: 32px;\n}\n\n._1YYAyYDaIK2VsjrZZuFUaQ {\n    display: inline-flex;\n    align-items: center;\n    line-height: 24px;\n}\n\n._1YYAyYDaIK2VsjrZZuFUaQ.YXoG6oKkRdRkPEA9JvKCG:hover {\n    background-color: var(--neutral-100);\n    border-radius: 4px;\n}\n\n._1YYAyYDaIK2VsjrZZuFUaQ:hover div {\n    opacity: 1;\n}\n\n._1Jhf-k_iB07fLxXX8w1pYx {\n    transition: opacity .25s ease-out;\n    display: flex;\n    opacity: 0;\n}\n\n._1Jhf-k_iB07fLxXX8w1pYx._1REkzy9TrT0gsaPG_ImNMJ {\n    opacity: 1;\n}\n\n._199mBQjQTBWbEqMCgPsqww._2exP-QL7_NMrc9XFtTvYsQ {\n    color: var(--neutral-1000);\n}\n\n._1RSjlpVdTCcL13Fu7TaGPZ {\n    gap: 4px;\n    display: flex;\n    align-items: center;\n    padding: 4px 0;\n}\n\n._1RSjlpVdTCcL13Fu7TaGPZ span[data-tooltip] {\n    display: inline-flex;\n}\n\n._1RSjlpVdTCcL13Fu7TaGPZ span[data-tooltip] button {\n    transform: translateY(-1px);\n}", ""]);
// Exports
exports.locals = {
	"search-wrapper": "YpKm453QiWBYvJn5bFcdM",
	"header-text-wrapper": "_1YYAyYDaIK2VsjrZZuFUaQ",
	"is-sortable": "YXoG6oKkRdRkPEA9JvKCG",
	"icon": "_1Jhf-k_iB07fLxXX8w1pYx",
	"visible": "_1REkzy9TrT0gsaPG_ImNMJ",
	"header-text": "_199mBQjQTBWbEqMCgPsqww",
	"sorted": "_2exP-QL7_NMrc9XFtTvYsQ",
	"header": "_1RSjlpVdTCcL13Fu7TaGPZ"
};
module.exports = exports;
