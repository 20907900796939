import type { FC } from 'react'
import React from 'react'
import { DataProperty } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAtomValue } from 'jotai/react'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { SettingsContainer } from '../../../components/settings-container'
import { ProtectionToggle } from './protection-toggle'
import { ProtectionWarningModal } from './protection-warning-modal'
import { LDFlag } from '../../../constants/ld-flags'
import styles from './store-status.module.css'

export const StoreStatus: FC = () => {
  const { [LDFlag.MpEER4Integrations]: FF_R4_INTEGRATIONS } = useFlags()
  const store = useAtomValue(getActiveStoreAtom)

  return (
    <>
      <SettingsContainer
        data-cy="store-status"
        headerText="Store status"
        subHeader="Control whether product protection and shipping protection offers can be shown in your store."
      >
        <div className="flex flex-col width-100">
          {FF_R4_INTEGRATIONS && (
            <div className={styles['data-property-wrapper']}>
              <DataProperty label="Extend Store ID" value={store?.id || ''} includeCopyAction />
            </div>
          )}
          <ProtectionToggle protectionType="product" />
          <ProtectionToggle protectionType="shipping" />
        </div>
      </SettingsContainer>
      <ProtectionWarningModal />
    </>
  )
}
