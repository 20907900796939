import type { Grant } from '../types/okta'

/**
 *  The Auth service does not accept grant objects with a `notValidAfter` value of null.
 *  This function loops through an array of user grant objects and drops notValidAfter if its value is falsy.
 *  @notValidAfter: number
 *  */
export function formatUserGrantsForDeactivation(grants: Grant[]): Grant[] {
  return grants.map((grant) => ({
    userId: grant.userId,
    role: grant.role,
    ern: grant.ern,
    notValidAfter: grant.notValidAfter || undefined,
  }))
}
