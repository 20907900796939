// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1jQQW-9aBILUcsN9RyymP {\n    width: 541px;\n}\n\n._1JB3HTIT-ene2kF0DMwR-f {\n    display: flex;\n    flex-direction: column;\n    padding-bottom: 24px;\n}\n._1JB3HTIT-ene2kF0DMwR-f >:nth-of-type(n+2) {\n    margin-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1jQQW-9aBILUcsN9RyymP",
	"container": "_1JB3HTIT-ene2kF0DMwR-f"
};
module.exports = exports;
