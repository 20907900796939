import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { LineItemShipments, Order } from '@helloextend/extend-api-client'
import { NavLink } from 'react-router-dom'
import { currency, customLogger } from '@extend/client-helpers'
import { getContractDetailUrl, getProductUrl } from '../../../utils/route-helper'
import styles from './product-shipment-table.module.css'
import { useGetOrderQuery } from '../../../queries/orders'
import type { MerchantClaim } from '../../../types/claims'

type ProductShipmentTableProps = {
  claim: MerchantClaim
  orderId: string | undefined
}

export const ProductShipmentTable: FC<ProductShipmentTableProps> = ({ claim, orderId }) => {
  const { id } = claim

  const { data: order, isLoading } = useGetOrderQuery(orderId || '')
  const currencyCode = order?.currency || 'USD'

  const products = useMemo(() => (order ? getLineItemInfo(claim, order) : []), [claim, order])
  const miscFees = useMemo(() => (order ? getMiscFeesAmount(order) : 0), [order])

  const subtotal = products.reduce((acc, p) => acc + p.purchasePrice, 0)
  const total = isLoading
    ? '...'
    : currency.formatWithoutCurrencySymbol(subtotal + miscFees, currencyCode, true)

  return (
    <table className={styles.table} data-cy={`${id}:product-shipment-table`}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
          <th style={{ textAlign: 'right' }}>Purchase Price</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, i) => {
          return (
            <tr key={`${product.id}-${product.referenceId}-${i}`}>
              <td>
                <NavLink to={getProductUrl(product.referenceId)}>{product.title}</NavLink>
                {product.hasExtendedWarranty && <p>Protected By Extended Warranty</p>}
              </td>
              <td>
                <p data-cy="product-reference-id">{product.referenceId}</p>
                {product.hasExtendedWarranty && product?.contractId && (
                  <p>
                    <span>Contract:&nbsp;</span>
                    <NavLink to={getContractDetailUrl(product.contractId)}>
                      {product.contractId}
                    </NavLink>
                  </p>
                )}
              </td>
              <td className="text-right">
                <p>
                  {currency.formatWithoutCurrencySymbol(product.purchasePrice, currencyCode, true)}
                </p>
              </td>
            </tr>
          )
        })}
        <tr>
          <td>Misc Fee</td>
          <td>Taxes & Shipping</td>
          <td className="text-right">
            {currency.formatWithoutCurrencySymbol(miscFees, currencyCode, true)}
          </td>
        </tr>
        <tr>
          <td>Total</td>
          <td>-</td>
          <td className="text-right">{total}</td>
        </tr>
      </tbody>
    </table>
  )
}

function getMiscFeesAmount(order: Order): number {
  try {
    const tax = order.taxCostTotal || 0
    const shippingCost = order.shippingCostTotal || 0

    return tax + shippingCost
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error(error.message, { stack: error.stack })
    }
    return 0
  }
}

type ProductLineInfo = {
  id: string
  referenceId: string
  title: string
  purchasePrice: number
  hasExtendedWarranty: boolean
  contractId?: string
}
function getLineItemInfo(claim: MerchantClaim, order: Order): ProductLineInfo[] {
  const lineItems = order?.lineItems || []

  const relevantIds = claim.incident.Products || []
  /**
   * Find the line items for shipments. These contain the ids of the relevant line items from the
   * order, as some of the order line items may not have had a contract for shipping protection
   */
  const shipmentLineItems = lineItems.filter((li) => li.type === 'shipments') as LineItemShipments[]

  const shippingLineItemIds = shipmentLineItems.reduce((acc, sli): string[] => {
    const newIds = sli.lineItemIds || []
    return acc.concat(newIds.filter((id) => relevantIds.includes(id)))
  }, [] as string[])
  /**
   * Build-up the relevant data for display in the table
   */
  return (
    lineItems.reduce((acc, item: any) => {
      /**
       * Only use the previously determined relevant line items
       */
      if (shippingLineItemIds.includes(item.id)) {
        acc.push({
          id: item.id,
          referenceId: item?.product?.id || '',
          title: item?.product?.name || '',
          purchasePrice: 'product' in item ? item?.product?.purchasePrice || 0 : 0,
          hasExtendedWarranty: Boolean(item.plan),
          contractId: item?.contractId,
        })
      }
      return acc
    }, [] as ProductLineInfo[]) || []
  )
}
