// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KIl2aQp2NgG97yvrcH-bb {\n    align-items: center;\n    background-color: var(--neutral-100);\n    display: flex;\n    justify-content: center;\n    line-height: 2rem;\n    min-height: 100vh;\n    text-align: center;\n    width: 100%;\n}\n\n._2KIl2aQp2NgG97yvrcH-bb h2 {\n    font-size: 24px;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n\n._2KIl2aQp2NgG97yvrcH-bb strong {\n    font-weight: 600;\n}\n\n._2KIl2aQp2NgG97yvrcH-bb a {\n    color: var(--bright-blue);\n}\n\n._2y42kl3N5xlF6V2Dt1zDn_ {\n    max-height: 50px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_2KIl2aQp2NgG97yvrcH-bb",
	"logo": "_2y42kl3N5xlF6V2Dt1zDn_"
};
module.exports = exports;
