import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { Checkbox, Modal, ModalController } from '@extend/zen'
import styles from './payment-authorization-modal.module.css'

export interface PaymentAuthorizationModalProps {
  isOpen: boolean
  isProcessing: boolean
  onClickDismiss: () => void
  onClickCancel: () => void
  onClickSubmit: () => void
}

export const PaymentAuthorizationModal: FC<PaymentAuthorizationModalProps> = ({
  isOpen,
  onClickDismiss,
  onClickCancel,
  onClickSubmit,
  isProcessing,
}) => {
  const [isAuthorized, setIsAuthorized] = useState(false)

  // need to reset checkbox state when closing modal
  useEffect(() => {
    setIsAuthorized(false)
  }, [isOpen])

  const handleIsAuthorized = (): void => {
    setIsAuthorized(!isAuthorized)
  }

  return (
    <>
      <ModalController isOpen={isOpen}>
        <Modal
          data-cy="payment-authorization-modal"
          heading="Authorize recurring payments"
          onDismissRequest={onClickDismiss}
          secondaryButtonProps={{
            'data-cy': 'modal-cancel',
            onClick: onClickCancel,
            text: 'Cancel',
          }}
          primaryButtonProps={{
            'data-cy': 'modal-submit',
            onClick: onClickSubmit,
            text: 'Authorize',
            isDisabled: !isAuthorized,
            isProcessing,
          }}
        >
          <>
            <p className={styles.disclaimer}>
              I authorize Extend, Inc. to electronically debit the business bank account identified
              below (“Account”) and, if necessary, electronically credit such Account to correct
              erroneous debits, at the named depository financial institution for the regularly
              scheduled charges described herein. The amount of each charge will be determined each
              month based on the fees due for services (“Monthly Fees”) under the Master Services
              Agreement between Extend, Inc. and the merchant named below (“MSA”). I agree that
              automatic clearing house (“ACH”) and/or pre-authorized debit (“PAD”) transactions that
              I authorize comply with all applicable law.
            </p>
            <strong>FOR U.S. MERCHANTS ONLY:</strong>
            <p className={styles.disclaimer}>
              By Selecting “Default Payment Method” I authorize Extend, Inc. to debit the amount of
              each Monthly Fee on the due date for such amounts on a monthly basis. Extend, Inc.
              will notify you of the amount of each Monthly Fee by providing you an invoice each
              month that will identify the due date for such payment in accordance with the Master
              Services Agreement. Extend, Inc. will initiate the ACH debit process three (3)
              business days before the due date without further notification.
            </p>
            <p className={styles.disclaimer}>
              By entering my name and submitting this form, I certify that I am an authorized signer
              for the depository account indicated above and that I have the authority to authorize
              these transactions on behalf of the merchant named above. I understand that because
              this is an electronic transaction, these funds may be withdrawn from my account in
              accordance with the above noted transaction dates, and that I will have limited time
              to report and dispute errors. In the case the transaction is returned for Non
              Sufficient Funds (NSF) I understand that Extend, Inc. may at its discretion attempt to
              process the charge again within 30 days, and agrees to an additional $30 charge for
              each returned NSF, which will be initiated as a separate transaction from the
              authorized payment. I have certified that the above bank account is enabled for ACH
              transactions, and agree to reimburse Extend, Inc. for all penalties and fees incurred
              as a result of my bank rejecting ACH debits or credits as a result of the account not
              being properly configured for ACH transactions. I acknowledge that the origination of
              ACH transactions to the indicated account must comply with the provisions of U.S. law.
              I agree that the ACH transactions will be governed by and that the above named
              Merchant will be bound by the NACHA Operating Rules with respect to such ACH
              transactions. I agree not to dispute this transaction with my bank provided the
              transaction corresponds to the terms indicated in this authorization form. I
              understand that this authorization will remain in effect until Extend&apos;s accounts
              receivable department receives written notice of cancellation at{' '}
              <a href="mailto:ar@extend.com">ar@extend.com</a> at least 15 days prior to the next
              billing date.
            </p>
            <strong>FOR CANADIAN MERCHANTS ONLY:</strong>
            <p className={styles.disclaimer}>
              This PAD agreement provides for the issuance of business PADs.&nbsp;
              <strong>
                I hereby agree to waive pre-notification of (a) the date and the amount of the first
                PAD to be debited from my Account, (b) each PAD (in the case of variable amount
                payments) and (c) any changes in the amount of a PAD.
              </strong>
            </p>
            <p className={styles.disclaimer}>
              By Selecting “Default Payment Method” I authorize Extend, Inc. to debit the amount of
              each Monthly Fee from the Account on the due date for such amounts on a monthly basis.
              I further authorize Extend, Inc. to debit my Account for payments in variable amounts
              and/or one-time payments from time to time for payment of the amounts arising under or
              pursuant to the MSA. Extend, Inc. will obtain my authorization for any one-time or
              sporadic debits. Extend, Inc. will notify you of the amount of each Monthly Fee by
              providing you an invoice each month that will identify the due date for such payment
              in accordance with the MSA. Extend, Inc. will initiate the PAD debit process three (3)
              business days before the due date without further notification.
            </p>
            <p className={styles.disclaimer}>
              By entering my name and submitting this form, I certify that I am an authorized signer
              for the Account and that I have the authority to authorize these transactions on
              behalf of the merchant named above. I understand that because this is an electronic
              transaction, these funds may be withdrawn from my account in accordance with the above
              noted transaction dates, and that I will have limited time to report and dispute
              errors. In the case the transaction is returned for Non Sufficient Funds (NSF) I
              understand that Extend, Inc. may at its discretion attempt to process the charge again
              within 30 days, and agrees to an additional $30 charge for each returned NSF, which
              will be initiated as a separate transaction from the authorized payment. I agree not
              to dispute this transaction with my bank provided the transaction corresponds to the
              terms indicated in this authorization form. I understand that this authorization will
              remain in effect until Extend’s accounts receivable department receives written notice
              of cancellation at <a href="mailto:ar@extend.com">ar@extend.com</a> at least 15 days
              prior to the next billing date. I may obtain a sample cancellation form, or further
              information on my right to cancel this PAD agreement, at my financial institution or
              by visiting&nbsp;
              <a href="http://www.payments.ca" target="_blank" rel="noreferrer">
                http://www.payments.ca
              </a>
              . I have certain recourse rights if any debit does not comply with this agreement. For
              example, I have the right to receive reimbursement for any debit that is not
              authorized or is not consistent with this PAD agreement. To obtain more information on
              my recourse rights, I may contact my financial institution or visit&nbsp;
              <a href="http://www.payments.ca" target="_blank" rel="noreferrer">
                http://www.payments.ca
              </a>
              .
            </p>
            <Checkbox
              data-cy="authorize:checkbox"
              label="I have read and agree to the terms"
              checked={isAuthorized}
              onChange={handleIsAuthorized}
            />
            <p className={styles.disclaimer}>
              It is recommended that you print a copy of this authorization and maintain it for your
              records.
            </p>
          </>
        </Modal>
      </ModalController>
    </>
  )
}
