import React from 'react'
import type { FC } from 'react'
import type { BadgeColor } from '@extend/zen'
import { Badge, Subheading } from '@extend/zen'
import styled from '@emotion/styled'
import type { Condition } from '@helloextend/extend-api-rtk-query'
import { useGetAdjudicationRuleQuery } from '@helloextend/extend-api-rtk-query'
import type { MerchantClaim } from '../../../../../../types/claims'
import type { ClaimValidationResponse } from '@helloextend/extend-api-client/src/models/claim'
import { AdjudicationRow } from './adjudication-row'

export type AdjudicationClaim = MerchantClaim & {
  ruleset: ClaimValidationResponse
}

type AdjudicationRuleProps = {
  claim: AdjudicationClaim
}

const AdjudicationRule: FC<AdjudicationRuleProps> = ({ claim }) => {
  const { data: rule, isLoading } = useGetAdjudicationRuleQuery({
    ruleId: claim.ruleset.ruleId,
    rulesetId: claim.ruleset.rulesetId,
    version: claim.ruleset.version,
    expand: true,
  })

  let badgeTitle: string
  let badgeColor: BadgeColor
  switch (claim.ruleset?.status) {
    case 'approved':
      badgeTitle = 'Claim Approved'
      badgeColor = 'green'
      break
    case 'denied':
      badgeTitle = 'Claim Denied'
      badgeColor = 'red'
      break
    case 'review':
      badgeTitle = 'Claim Under Review'
      badgeColor = 'yellow'
      break
    default:
      badgeTitle = 'Adjudicated Status Unknown'
      badgeColor = 'neutral'
      break
  }

  if (isLoading) return null

  return (
    <Container>
      <ClaimResult>
        <Description data-cy="adjudication-rule-header">AMP claim result:</Description>
        <Badge data-cy="claim-rule-badge" color={badgeColor} emphasis="medium" text={badgeTitle} />
      </ClaimResult>
      {rule && (
        <>
          <Subheading>Applied AMP Adjudication Rule:</Subheading>
          {rule.conditions.map((condition: Condition, conditionIndex: number) => {
            return <AdjudicationRow rule={rule} condition={condition} index={conditionIndex} />
          })}
        </>
      )}
    </Container>
  )
}

const Description = styled.p({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

const ClaimResult = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  paddingBottom: 32,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

export { AdjudicationRule }
