import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import { useParams } from 'react-router-dom'
import { LeadHeader } from './lead-header'
import { LeadDetails } from './lead-details'
import { useGetLeadQuery } from '../../../queries/leads'
import { ErrorPage } from '../../../components/error-page'

export const Lead: FC = () => {
  const { leadToken } = useParams<{ leadToken: string }>()
  const { data: lead, isLoading, isError, error } = useGetLeadQuery(leadToken)

  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center width-100 height-100">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError || !lead) {
    return <ErrorPage error={error as Error} />
  }

  return (
    <>
      <LeadHeader lead={lead} />
      <LeadDetails lead={lead} />
    </>
  )
}
