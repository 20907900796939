import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Button } from '@extend/zen'
import { useLocation } from 'react-router'
import { Location } from '../../lib/window-location'
import * as pkceOauth from '../../lib/pkce-oauth'
import styles from './okta-login-button.module.css'

interface PkceLoginFormParams {
  isLoading?: boolean
  accountFollowUp?: boolean
}

export const OktaLoginButton: FC<PkceLoginFormParams> = ({
  isLoading = false,
  accountFollowUp,
}) => {
  const { search } = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const url = searchParams.get('shopifyUrl')
    const accountId = searchParams.get('accountId')
    const role = searchParams.get('role')
    const magentoConsumerKey = searchParams.get('oauth_consumer_key')

    if (url) {
      localStorage.setItem('shopifyUrl', url)
    }

    if (accountId && role) {
      localStorage.setItem('accountId', accountId)
      localStorage.setItem('role', role)
    }

    if (magentoConsumerKey) {
      localStorage.setItem('magentoConsumerKey', magentoConsumerKey)
    }
  }, [search])

  const handleClick = async (): Promise<void> => {
    const oktaRedirect = await pkceOauth.getOktaRedirectUrl({ offlineAccess: true })
    Location.assign(oktaRedirect)
  }

  return (
    <div className={styles.wrapper}>
      {accountFollowUp && (
        <p className={styles.followup}>
          Check your email and accept the invite THEN return here to login
        </p>
      )}
      <Button
        size="regular"
        onClick={handleClick}
        isProcessing={isLoading}
        type="submit"
        fillContainer
        text="Log in to Merchant Portal"
        data-cy="okta-login-button"
      />
    </div>
  )
}
