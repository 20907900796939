import type { FC } from 'react'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@extend/zen'
import {
  ChevronStart,
  ChevronEnd,
  ChevronLeft,
  ChevronRight,
} from '@extend/zen'
import { List, ListItem } from '../list'
import { Menu } from '../menu'
import type { PaginationDirection } from './types'
import { PaginationType } from './types'

const PER_PAGE = [10, 20, 50, 100]

type EnhancedPaginationProps = {
  paginationType: PaginationType.ENHANCED | PaginationType.ENHANCED_SERVER_SIDE
  count: number
  type: string
  currPage: number
  pageSize: number
  hasNext?: boolean
  hasPrev?: boolean
  handlePagination: (type: PaginationDirection) => void
  onPageSizeChange: (pageSize: number) => void
  overrideCountDisplay?: boolean
}

const EnhancedPagination: FC<EnhancedPaginationProps> = ({
  paginationType,
  count,
  type,
  currPage,
  pageSize,
  hasNext,
  hasPrev,
  handlePagination,
  onPageSizeChange,
  overrideCountDisplay = false,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const isServerPagination = paginationType === PaginationType.ENHANCED_SERVER_SIDE
  const generateSelectDisplay = useCallback(
    (selectedCount: number): string => {
      if (overrideCountDisplay) {
        const firstUnitNumber = currPage === 1 ? 1 : pageSize + currPage - 1
        const lastUnitNumber = currPage === 1 ? pageSize : Math.min(count, currPage * pageSize)
        return `${firstUnitNumber} - ${lastUnitNumber}${
          !isServerPagination ? ` of ${count}` : ''
        }  ${type}`
      }

      const firstUnitNumber = selectedCount * currPage + 1
      let lastUnitNumber = firstUnitNumber + selectedCount - 1
      const unitType = count > 1 ? type : type.slice(0, -1)
      if (lastUnitNumber > count) {
        lastUnitNumber = count
      }

      return `${firstUnitNumber} - ${lastUnitNumber}${
        !isServerPagination ? ` of ${count}` : ''
      }  ${unitType}`
    },
    [overrideCountDisplay, currPage, count, type, isServerPagination, pageSize],
  )

  const handlePrev = useCallback((): void => {
    if (hasPrev) {
      handlePagination('previous')
    }
  }, [hasPrev, handlePagination])

  const handleNext = useCallback((): void => {
    if (hasNext) {
      handlePagination('next')
    }
  }, [hasNext, handlePagination])

  const handleFirst = useCallback((): void => {
    if (hasPrev) {
      handlePagination('first')
    }
  }, [hasPrev, handlePagination])

  const handleLast = useCallback((): void => {
    if (hasNext) {
      handlePagination('last')
    }
  }, [hasNext, handlePagination])

  const handleClickMenuButton = useCallback((): void => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  const handleSelectQuantity = useCallback(
    (quantity: number): void => {
      onPageSizeChange(quantity)
    },
    [onPageSizeChange],
  )

  const selectDisplay = useMemo(
    () => generateSelectDisplay(pageSize),
    [generateSelectDisplay, pageSize],
  )

  useEffect(() => {
    if (!isMenuVisible) {
      return () => {
        // do nothing
      }
    }

    document.addEventListener('click', handleClickMenuButton)

    return function cleanup() {
      document.removeEventListener('click', handleClickMenuButton)
    }
  }, [handleClickMenuButton, isMenuVisible])

  return (
    <Wrapper>
      {(count > 0 || currPage > 1) && (
        <>
          <Container>
            <div>
              <Button
                onClick={handleClickMenuButton}
                isToggled={isMenuVisible}
                text={selectDisplay}
                color="neutral"
                emphasis="low"
                size="small"
                data-cy="selectPageSize"
              />
              <Menu isOpen={isMenuVisible} position="right" verticalAlignment={-120} width={140}>
                <List fullWidth>
                  {PER_PAGE.map((size: number) => (
                    <ListItem
                      type="button"
                      isSelected={size === pageSize}
                      isBold={size === pageSize}
                      color={COLOR.NEUTRAL[1000]}
                      key={size}
                      id={`${size}`}
                      hoverBackground={COLOR.NEUTRAL[100]}
                      onClick={() => handleSelectQuantity(size)}
                    >
                      {size} per view
                    </ListItem>
                  ))}
                </List>
              </Menu>
            </div>
            <Button
              icon={ChevronStart}
              onClick={handleFirst}
              isDisabled={!hasPrev}
              color="neutral"
              emphasis="low"
              size="small"
              data-cy="start"
            />
            <Button
              icon={ChevronLeft}
              onClick={handlePrev}
              isDisabled={!hasPrev}
              color="neutral"
              emphasis="low"
              size="small"
              data-cy="prev"
            />
            {isServerPagination && <PageText>Page {currPage + 1}</PageText>}
            <Button
              icon={ChevronRight}
              onClick={handleNext}
              isDisabled={!hasNext}
              color="neutral"
              emphasis="low"
              size="small"
              data-cy="next"
            />
            {!isServerPagination && (
              <Button
                icon={ChevronEnd}
                onClick={handleLast}
                isDisabled={!hasNext}
                color="neutral"
                emphasis="low"
                size="small"
                data-cy="end"
              />
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
})

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  color: COLOR.NEUTRAL[600],
  paddingTop: '16px',
})

const PageText = styled.span({
  fontSize: 14,
})

export type { EnhancedPaginationProps }
export { EnhancedPagination }
