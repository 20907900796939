import type { FC } from 'react'
import React from 'react'
import type { MerchantProduct } from '../../../types/merchant-product'
import { SubHeader } from '../../../components/sub-header'
import styles from './product-details-identifiers.module.css'

type ProductDetailsIdentifiersProps = {
  product: MerchantProduct
}

type ValueOf<T> = T[keyof T]

export const ProductDetailsIdentifiers: FC<ProductDetailsIdentifiersProps> = ({ product }) => {
  const getValue = (value: ValueOf<MerchantProduct>): string => {
    return `${value ?? 'N/A'}`
  }
  return (
    <>
      <div className={styles.details}>
        <SubHeader labelText="Product Identifiers" />
        <div className={styles.row}>
          <div className={styles.detail}>
            <span className={styles.label}>Brand</span>
            <span className={styles.value} data-cy="product-details-brand">
              {getValue(product.brand)}
            </span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>Barcode</span>
            <span className={styles.value} data-cy="product-details-barcode">
              {getValue(product.identifiers?.barcode)}
            </span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>GTIN</span>
            <span className={styles.value} data-cy="product-details-gtin">
              {getValue(product.identifiers?.gtin)}
            </span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>UPC</span>
            <span className={styles.value} data-cy="product-details-upc">
              {getValue(product.identifiers?.upc)}
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.detail}>
            <span className={styles.label}>ASIN</span>
            <span className={styles.value} data-cy="product-details-asin">
              {getValue(product.identifiers?.asin)}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
