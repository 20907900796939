import type { Store } from '@helloextend/extend-api-client'
import type { LDFlagSet } from 'launchdarkly-js-sdk-common'
import type { LDFlag } from '../constants/ld-flags'
import type { Permission, RuleDefinition } from '../lib/permissions'
import { permissions, StoreRequirement } from '../lib/permissions'
import type { MerchantsEnterpriseUserRole } from '../types/users'

export const getPermissions = (
  userRole: MerchantsEnterpriseUserRole | null,
  flags: LDFlagSet,
  store: Store | null,
): Permission[] => {
  return Object.entries(permissions)
    .filter(([, ruleDefinition]) => testRule(userRole, flags, store, ruleDefinition))
    .map(([permission]) => permission as Permission)
}

const testRule = (
  userRole: MerchantsEnterpriseUserRole | null,
  flags: LDFlagSet,
  store: Store | null,
  { allowedRoles, requiredFeatureFlags, storeRequirements }: RuleDefinition,
): boolean => {
  return (
    isAllowedUserRole(userRole, allowedRoles) &&
    matchesRequiredFeatureFlags(flags, requiredFeatureFlags) &&
    matchesStoreRequirements(store, storeRequirements)
  )
}

// Determine if the current user role matches the allowed roles for the permission rule
const isAllowedUserRole = (
  userRole: MerchantsEnterpriseUserRole | null,
  allowedRoles: MerchantsEnterpriseUserRole[],
): boolean => {
  if (!userRole) return false

  return allowedRoles.some((role) => role === userRole)
}

// Determine if the feature flags required for the rule are all enabled
const matchesRequiredFeatureFlags = (
  flags: LDFlagSet,
  requiredFeatureFlags?: LDFlag[],
): boolean => {
  if (!requiredFeatureFlags?.length) return true

  return requiredFeatureFlags.every((flag) => flags[flag])
}

// Determine if the store settings required for the rule are all enabled.
const matchesStoreRequirements = (
  store: Store | null,
  requirements?: StoreRequirement[],
): boolean => {
  if (!requirements) return true

  return requirements.every((req) => {
    switch (req) {
      case StoreRequirement.ActiveStore:
        return !!store
      case StoreRequirement.Analytics:
        return !!store && !store.analyticsDisabled
      case StoreRequirement.ClaimsAgent:
        return !!store && store.claimsManagementEnabled
      case StoreRequirement.InStoreApp:
        return !!store && store.inStoreAppEnabled
      default:
        return false
    }
  })
}
