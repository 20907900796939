import { useCallback } from 'react'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { Order } from '@helloextend/extend-api-client'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { v4 as uuid } from 'uuid'

const ORDER_CACHE_KEY = 'Order'
const ORDERS_SEARCH_CACHE_KEY = 'OrderSEARCH'

const BASE_URL = `https://${EXTEND_API_HOST}/orders`

export function useGetOrderQuery(orderId: string): QueryObserverResult<Order, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom)

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${orderId}`, {
      headers: {
        accept: 'application/json; version=latest;',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch order')
    }

    return response.json()
  }, [accessToken, orderId])

  return useQuery({
    queryKey: [ORDER_CACHE_KEY, orderId],
    queryFn,
  })
}

// Default limit of 2000 should ensure that we're always hitting the single page limit (1000)
// in Dynamo, which there's not really any reason not to do.
const DEFAULT_SEARCH_LIMIT = 2000

export enum OrdersSearchKey {
  transactionId = 'transactionId',
  storeId = 'storeId',
  orderId = 'orderId',
  merchantCustomerId = 'merchantCustomerId',
  customerEmail = 'customerEmail',
  customerPhone = 'customerPhone',
}

interface SearchOrdersQueryOpts {
  searchKey: OrdersSearchKey
  searchValue: string | number
  limit?: number
  cursor?: string
  enabled?: boolean
}

/**
 * Fetches orders based on a search key and value. This is written as a mutation so that
 * the query (mutation) can be invoked on demand, rather than automatically.
 * @param opts query options
 * @returns react query result
 */
export function useSearchOrdersMutation({
  searchKey,
  searchValue,
  limit = DEFAULT_SEARCH_LIMIT,
  cursor,
}: Omit<SearchOrdersQueryOpts, 'enabled'>) {
  const accessToken = useAtomValue(v3AccessTokenAtom)

  const qsParams = new URLSearchParams({
    [searchKey]: searchValue,
    limit: limit.toString(),
    ...(cursor && { cursor }),
  })

  const mutationFn = useCallback(async () => {
    if (!searchValue) return { data: undefined }

    const response = await fetch(`${BASE_URL}/search?${qsParams.toString()}`, {
      headers: {
        accept: 'application/json; version=latest;',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch order')
    }

    return response.json()
  }, [accessToken, searchKey, searchValue, limit, cursor])

  return useMutation<{ orders: Order[] }>({
    mutationKey: [ORDERS_SEARCH_CACHE_KEY, qsParams.toString()],
    mutationFn,
  })
}

export type CreateOrderLineItemRequest = {
  orderId: string
  lineItemTransactionId: string
  plan: {
    id: string
    purchasePrice: number
  }
  product: {
    id: string
    title: string
    category: string
    listPrice: number
    purchasePrice: number
    purchaseDate: number
  }
  fulfilledQuantity: number
  quantity: number
}

export function useCreateOrderLineItemMutation(): UseBaseMutationResult<
  Order,
  Error,
  CreateOrderLineItemRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom)

  return useMutation({
    mutationFn: async (request: CreateOrderLineItemRequest) => {
      const { orderId, ...body } = request

      const response = await fetch(`${BASE_URL}/${orderId}/line-item`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
          'x-extend-access-token': accessToken,
          'X-Idempotency-Key': `${body.lineItemTransactionId}::${uuid()}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        throw new Error('Unable to create order line item')
      }

      return response.json()
    },
  })
}

export type UpsertOrderRequest = Pick<Order, 'storeId' | 'transactionId' | 'currency'> & {
  customer: Pick<Order['customer'], 'name' | 'email'> & {
    billingAddress?: Partial<Order['customer']['billingAddress']>
    shippingAddress?: Partial<Order['customer']['shippingAddress']>
  }
  lineItems: {
    lineItemTransactionId: string
    plan?: {
      id: string
      purchasePrice: number
    }
    product?: {
      id: string
      purchasePrice: number
      title?: string
      category?: string
      listPrice?: number
      purchaseDate?: number
    }
    fulfilledQuantity?: number
    quantity?: number
    status?: string
  }[]
}

export function useUpsertOrderMutation(): UseBaseMutationResult<
  Order,
  Error,
  UpsertOrderRequest,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom)

  return useMutation({
    mutationFn: async (order: UpsertOrderRequest) => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
          'x-extend-access-token': accessToken,
          'X-Idempotency-Key': `STORE::${order.storeId}::ORDER::${order.transactionId}::${uuid()}`,
        },
        method: 'PUT',
        body: JSON.stringify(order),
      })

      if (!response.ok) {
        throw new Error('Unable to update order')
      }

      return response.json()
    },
  })
}
