// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._22EYNH5bPe171fVBC1ZUjW {\n  display: grid;\n}\n\n.Iv6sRPpo1IQ3I6KiNF4O3 {\n  display: grid;\n  column-gap: 16px;\n  row-gap: 16px;\n  grid-template-columns: calc(50% - 16px) calc(50% - 16px);\n}\n\n/* Form Input Grid Styles */\n.s1d7zmRKIrRitV9wqkhqd {\n  display: grid;\n  column-gap: 16px;\n  row-gap: 16px;\n  grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);\n}\n\n@media (min-width: 768px) {\n  ._1adgLzD3SBUEEOl_BP6AgF {\n      display: grid;\n      row-gap: 16px;\n      grid-template-columns: repeat(2, 1fr);\n  }\n\n  ._1LIhyFwMfC74F5t36FfYhM {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n._1adgLzD3SBUEEOl_BP6AgF label, ._1adgLzD3SBUEEOl_BP6AgF div, ._1LIhyFwMfC74F5t36FfYhM label, ._1LIhyFwMfC74F5t36FfYhM div {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n._2mVZ9DlEDAJNLyGRNkTqeM {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 16px;\n}\n\n._2mVZ9DlEDAJNLyGRNkTqeM > div {\n  flex: 1;\n}\n\n._2wkNNZGiOIRG73pyhgL68N {\n  font-size: 14px;\n  line-height: 18px;\n}\n", ""]);
// Exports
exports.locals = {
	"form-container": "_22EYNH5bPe171fVBC1ZUjW",
	"four-input-grid": "Iv6sRPpo1IQ3I6KiNF4O3",
	"six-input-grid": "s1d7zmRKIrRitV9wqkhqd",
	"grid": "_1adgLzD3SBUEEOl_BP6AgF",
	"addressGrid": "_1LIhyFwMfC74F5t36FfYhM",
	"input-row": "_2mVZ9DlEDAJNLyGRNkTqeM",
	"h4-styles": "_2wkNNZGiOIRG73pyhgL68N"
};
module.exports = exports;
