// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1mH6wy61ss0dHCBY-v7JmC {\n    display: flex;\n    margin-top: 44px;\n    margin-bottom: 20px;\n    gap: 16px;\n    align-items: flex-start;\n    flex-wrap: wrap;\n}\n\n._1mH6wy61ss0dHCBY-v7JmC > span {\n    margin-top: 50px;\n}\n\n.mMjVxffFXjJ-EiEM2hPyq {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    width: 100%;\n}\n\n._3Ih6FZHvPumXCEikud84_d {\n    width: 262px;\n}\n\n._1lUePsGpf4ZAMjhBcZSfuh {\n    margin-top: 26px;\n}\n", ""]);
// Exports
exports.locals = {
	"combo": "_1mH6wy61ss0dHCBY-v7JmC",
	"description": "mMjVxffFXjJ-EiEM2hPyq",
	"input": "_3Ih6FZHvPumXCEikud84_d",
	"button": "_1lUePsGpf4ZAMjhBcZSfuh"
};
module.exports = exports;
