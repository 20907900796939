import type { FC } from 'react'
import React, { useMemo } from 'react'
import { querystring } from '@extend/client-helpers'
import { Redirect } from 'react-router'
import type { ContractSearchKey } from '@helloextend/extend-api-client/src/models/contract'
import { COLOR, Spinner } from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { Header } from './header'
import { ContractsList } from './contracts-list'
import { EmptyResultsMessage } from './empty-results-message'
import { useSearchContractsByStore } from '../../../queries/stores'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

export const ClaimsContractSearchResults: FC = () => {
  const { key, value } = useMemo(() => querystring.parse(window.location.search), [])
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const searchKey = useMemo(() => String(key || '') as ContractSearchKey, [key])
  const searchValue = useMemo(() => String(value || ''), [value])

  const { data: contracts, isLoading } = useSearchContractsByStore({
    storeId,
    searchKey,
    value: searchValue,
  })

  // redirects the view back to the search view
  // when the querystring params are nullish/not parsable
  const isCompleteSearchParams = useMemo(() => Boolean(key && value), [key, value])
  if (!isCompleteSearchParams) return <Redirect to="/store/claims/new" />

  const isEmptySearchResults = !isLoading && contracts && contracts?.length === 0

  return (
    <>
      <Header searchKey={searchKey} searchValue={searchValue} />
      {contracts?.length ? <ContractsList contracts={contracts} /> : null}
      {isLoading && (
        <Spinner color={COLOR.BLUE[800]} data-cy="claims-contract-search-results-spinner" />
      )}
      {isEmptySearchResults && <EmptyResultsMessage searchValue={searchValue} />}
    </>
  )
}
