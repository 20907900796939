import type { FC } from 'react'
import React, { useEffect } from 'react'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { usePrevious } from '@helloextend/client-hooks'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Modal, ModalController } from '@extend/zen'
import { useAtom, useSetAtom } from 'jotai/react'
import {
  createThemeNameAtom,
  currentlySelectedThemeAtom,
  currentlySelectedThemeIdAtom,
  isWarningModalVisibleAtom,
  requestedSelectedThemeAtom,
} from '../../../atoms/customize-theme'

export type WarningModalProps = {
  handleSave: () => void
  handleCloseModal: () => void
  handleSkip: () => void
  isVisible: boolean
  isProcessing: boolean
  isSuccess: boolean
  error?: FetchBaseQueryError | SerializedError | string | Error
  reset: () => void
}

export const WarningModal: FC<WarningModalProps> = ({
  handleSave,
  handleCloseModal,
  handleSkip,
  isVisible,
  isProcessing,
  isSuccess,
  error,
  reset,
}) => {
  const prevProcessing = usePrevious<boolean>(isProcessing)
  const { toastCompleted, toastError } = useStandardToast()
  const [createThemeName, setCreateThemeName] = useAtom(createThemeNameAtom)
  const [activeTheme] = useAtom(currentlySelectedThemeAtom)
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const setIsWarningModalVisible = useSetAtom(isWarningModalVisibleAtom)
  const [requestedSelectedTheme, setRequestedSelectedTheme] = useAtom(requestedSelectedThemeAtom)

  const isPublishedTheme = Boolean(activeTheme?.status === ThemePublishedStatus.published)

  useEffect(() => {
    if (prevProcessing && !error && isSuccess) {
      toastCompleted('Theme Successfully Saved')
      reset()
      setCurrentThemeId(requestedSelectedTheme?.themeId ?? '')
      setIsWarningModalVisible(false)
      setCreateThemeName(null)
      setRequestedSelectedTheme(null)
    }
  }, [
    prevProcessing,
    error,
    isSuccess,
    toastCompleted,
    requestedSelectedTheme,
    reset,
    setCurrentThemeId,
    setIsWarningModalVisible,
    setCreateThemeName,
    setRequestedSelectedTheme,
  ])

  useEffect(() => {
    if (error) {
      toastError('Uh-Oh, something went wrong. Please try again.')
      reset()
    }
  }, [error, toastError, reset])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading={isPublishedTheme ? 'Your unsaved changes will be lost' : 'Save these changes?'}
        primaryButtonProps={
          !isPublishedTheme
            ? {
                'data-cy': 'warning-modal-save',
                text: 'Save & Continue',
                onClick: handleSave,
                isProcessing,
              }
            : {
                'data-cy': 'warning-modal-skip',
                text: 'Continue',
                onClick: handleSkip,
              }
        }
        secondaryButtonProps={
          !isPublishedTheme
            ? {
                'data-cy': 'warning-modal-skip',
                text: isPublishedTheme ? 'Continue' : 'Skip',
                onClick: handleSkip,
              }
            : undefined
        }
        altButtonProps={{
          'data-cy': 'warning-modal-cancel',
          text: 'Cancel',
          onClick: handleCloseModal,
        }}
      >
        <p>
          {isPublishedTheme
            ? 'Do you want to continue?'
            : `Do you want to save the changes you made to "${
                createThemeName ?? activeTheme?.name
              }" before you leave?`}
        </p>
      </Modal>
    </ModalController>
  )
}
