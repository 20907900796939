import type { FC } from 'react'
import React from 'react'
import { ContractsHeader } from './contracts-header'
import { ContractsDataTable } from './contracts-data-table'

const Contracts: FC = () => {
  return (
    <>
      <ContractsHeader />
      <ContractsDataTable />
    </>
  )
}

export { Contracts }
