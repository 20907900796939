import type { FC } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { currency } from '@extend/client-helpers'
import { getProductUrl } from '../../../../utils/route-helper'
import type { ProductShipmentTableItem } from '../../../../types/contracts'
import styles from './product-shipment-table.module.css'

type ProductShipmentTableProps = {
  id: string
  products: ProductShipmentTableItem[]
}

export const ProductShipmentTable: FC<ProductShipmentTableProps> = ({ products, id }) => {
  return (
    <table className={styles.table} data-cy={`${id}:product-shipment-table`}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Product Reference ID</th>
          <th className="text-right" style={{ textAlign: 'right', width: '20%' }}>
            Purchase Price
          </th>
          <th className="text-right" style={{ textAlign: 'right', width: '10%' }}>
            Quantity
          </th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => {
          return (
            <tr key={product.referenceId}>
              <td>
                <NavLink to={getProductUrl(product.referenceId)}>{product.title}</NavLink>
              </td>
              <td>{product.referenceId}</td>
              <td className="text-right">
                {currency.format(product.purchasePrice, product.currencyCode)}
              </td>
              <td className="text-right">{product.quantity}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
