import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import { useListInsuranceClaimsQuery } from '@helloextend/extend-api-rtk-query'
import { useParams } from 'react-router'
import { useGetContractQuery } from '../../../queries/contract'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import { ContractClaimsCards } from './contract-claims-cards'
import { ContractDetailsCard } from './contract-details-card'
import { CustomerDetailsCard } from './customer-details-card'
import { ClaimsContractDetailsHeader } from './claims-contract-details-header'
import styles from './claims-contract-details.module.css'

export const ClaimsContractDetails: FC = () => {
  const { contractId } = useParams<{ contractId: string }>()
  const { data: contract, isLoading: isContractLoading } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })

  const { data: insuranceClaimData, isLoading: isInsuranceClaimLoading } =
    useListInsuranceClaimsQuery({ containsContractId: contractId })

  const claims = insuranceClaimData?.items

  const isLoading = isInsuranceClaimLoading || isContractLoading

  return (
    <>
      <ClaimsContractDetailsHeader />
      <div className={styles.content}>
        {contract && <ContractDetailsCard data-cy="contract-details-card" contract={contract} />}
        {contract && <CustomerDetailsCard data-cy="customer-details-card" contract={contract} />}
        {!isLoading && claims && (
          <ContractClaimsCards data-cy="contract-claims-cards" claims={claims} />
        )}
        {isLoading && <Spinner data-cy="spinner" color={COLOR.BLUE[800]} />}
      </div>
    </>
  )
}
