// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2OwaOOoZH_ty8_afojAr2y {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n.yBOUjS7k8Ncyeeq-BovKJ {\n    flex: 1 0 100%;\n}\n\n._2yC2fNsQkSUmp7sq_zHkZK {\n    width: 140px;\n    height: 140px;\n}\n\n._35LSfRqu02XcPjCOi3SbJj {\n    height: 100%;\n    object-fit: cover;\n    object-position: center center;\n    width: 100%;\n}\n\n.wt_lINfneK5nAjxIPp4jX {\n    flex-grow: 1;\n}\n\n._2dyu8M7gA03peK1NOMYlRx {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n._2dyu8M7gA03peK1NOMYlRx > * + * {\n    margin-top: 16px;\n}\n\n._3v40_4egd1R9P03xn21pbG {\n    display: flex;\n    flex-flow: column wrap;\n    margin-top: 32px;\n    align-items: baseline;\n}\n\n._3v40_4egd1R9P03xn21pbG > * + * {\n    margin: 16px 0 0 0;\n}\n\n@media (min-width: 1025px) {\n    .yBOUjS7k8Ncyeeq-BovKJ {\n        flex: 0 1;\n        margin-right: 16px;\n    }\n\n    ._2dyu8M7gA03peK1NOMYlRx {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n\n    ._3v40_4egd1R9P03xn21pbG {\n        flex-flow: row wrap;\n    }\n\n    ._3v40_4egd1R9P03xn21pbG > * + * {\n        margin: 0 0 0 16px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "_2OwaOOoZH_ty8_afojAr2y",
	"figure": "yBOUjS7k8Ncyeeq-BovKJ",
	"image-wrapper": "_2yC2fNsQkSUmp7sq_zHkZK",
	"image": "_35LSfRqu02XcPjCOi3SbJj",
	"details": "wt_lINfneK5nAjxIPp4jX",
	"details-column": "_2dyu8M7gA03peK1NOMYlRx",
	"buttons": "_3v40_4egd1R9P03xn21pbG"
};
module.exports = exports;
