import type { FC } from 'react'
import React, { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, InlineAlert, InlineAlertColor, Warning as WarningIcon } from '@extend/zen'
import { querystring } from '@extend/client-helpers'
import { useAtomValue } from 'jotai/react'
import images from '../images'
import { PublicSiteHeader } from '../features/onboarding/public-site-header'
import { useClosePageGuard } from '../hooks/use-close-page-guard'
import { useFetchShopifyIntegrationUrl } from '../queries/integrations'
import { isStoreUpdatingAtom, storeUpdateErrorAtom } from '../atoms/stores'
import styles from './shopify-install.module.css'

type QueryStringProps = {
  storeUrl: string
}

export const ShopifyInstall: FC = () => {
  const [wasConnectClicked, setWasConnectClicked] = useState(false)

  useClosePageGuard(wasConnectClicked)

  const [isNavigating, setIsNavigating] = useState(false)

  const { search } = useLocation()
  const { storeUrl } = querystring.parse<QueryStringProps>(search)

  const { data: shopifyUrl, error } = useFetchShopifyIntegrationUrl({
    shopUrl: storeUrl,
    enabled: Boolean(storeUrl) && wasConnectClicked,
  })

  const isUpdating = useAtomValue(isStoreUpdatingAtom)
  const storeUpdateError = useAtomValue(storeUpdateErrorAtom)

  const isConnecting = (isUpdating && !storeUpdateError) || (isUpdating && !error) || isNavigating

  const handleConnect = (): void => {
    setWasConnectClicked(true)
  }

  const storeError = error?.message || storeUpdateError

  useLayoutEffect(() => {
    if (shopifyUrl) {
      window.location.href = shopifyUrl
      // Continue to disable the Connect button
      // to prevent the user from creating another store
      // while navigating back to Shopify
      setIsNavigating(true)
    }
  }, [shopifyUrl, setIsNavigating])

  return (
    <>
      <PublicSiteHeader />
      <div className={styles['page-container']}>
        <div className={styles.container}>
          <figure className={styles.figure} data-cy="shopify-install-logo">
            <img src={images.shopifyInstall} alt="Shopify Install" />
          </figure>
          <h1 className={styles.heading} data-cy="shopify-install-header">
            Connect to Shopify
          </h1>
          {error && (
            <div className={styles.alert}>
              <InlineAlert color={InlineAlertColor.red} data-cy="shopify-install-error-alert">
                {storeError}
              </InlineAlert>
            </div>
          )}
          <div className={styles['body-container']}>
            <div className={styles.body} data-cy="shopify-install-body">
              Click “Connect” to open Shopify and install the Extend app for your store.
            </div>

            <InlineAlert
              color={InlineAlertColor.blue}
              icon={WarningIcon}
              data-cy="shopify-install-alert"
            >
              If you leave without connecting Shopify, your Extend app installation will fail. You
              may need to uninstall your Extend application in Shopify, re-install, and complete
              this installation flow again.
            </InlineAlert>
          </div>
          <div className={styles['button-group']}>
            <Button
              data-cy="shopify-install-button-connect"
              emphasis="high"
              text="Connect"
              isDisabled={!storeUrl || isConnecting}
              isProcessing={isConnecting}
              onClick={handleConnect}
            />
          </div>
        </div>
      </div>
    </>
  )
}
