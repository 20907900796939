import { faker } from '@faker-js/faker/locale/en'
import type { Sku, SkuCreateRequest } from '@helloextend/extend-api-client/src/models/sku'
import type { SkuSearchResponse } from '@helloextend/extend-api-rtk-query'

export function generateSku(overrides: any = {}): Sku {
  return {
    id: faker.datatype.uuid(),
    name: faker.random.words(3),
    activeFrom: faker.date.recent().getTime(),
    activeTo: faker.date.future().getTime(),
    program: 'extend',
    subProgram: 'extend',
    obligor: 'extend',
    insuranceProgramId: faker.datatype.uuid(),
    coverageType: 'base',
    term: faker.datatype.number(100),
    serviceType: 'replace',
    productCondition: 'new',
    purchasePriceOfProduct: 'item',
    limitOfLiabilityBasedUpon: 'product',
    paymentModel: 'monthly',
    cancellation: 'value',
    isDeductible: true,
    deductibleAmount: faker.datatype.number(5000),
    currencyCode: 'USD',
    priceBandLow: 35000,
    priceBandHigh: 49999,
    lossCost: 1000,
    targetLossRatio: 0.3,
    // same as obligorFeeRate
    underwritingProfit: 0.1,
    reserve: 3333,
    obligorFee: 333.3,
    premium: 1761.73,
    isActive: true,
    createdAt: faker.date.recent().getTime(),
    createdBy: faker.name.firstName(),
    skuParentCreatedAt: faker.date.recent().getTime(),
    version: 1,
    frequency: 0.55,
    severity: 100,
    source: 'portal.dev.extend.com',
    ...overrides,
  }
}

export const generateCreateSku = (overrides: any = {}): SkuCreateRequest => {
  return {
    id: 'sku-test-id-1y',
    name: faker.random.words(3),
    activeFrom: faker.date.recent().getTime(),
    activeTo: faker.date.future().getTime(),
    program: 'TBD',
    subProgram: 'TBD',
    coverageType: 'Base',
    term: 12,
    serviceType: 'Repair',
    lineOfBusiness: 'Service Contract',
    currencyCode: 'USD',
    productCondition: 'New',
    purchasePriceOfProduct: 'Item',
    limitOfLiabilityBasedUpon: 'Product',
    paymentModel: 'Single',
    cancellation: 'TBD',
    obligor: 'Extend',
    insuranceProgramId: 'TBD',
    priceBandLow: 1000,
    priceBandHigh: 50000,
    isDeductible: true,
    deductibleAmount: 1.25,
    lossCost: 200,
    underwritingProfit: 10,
    obligorFee: 5,
    targetLossRatio: 15,
    frequency: 0.55,
    severity: 100,
    source: 'portal.dev.extend.com',
    ...overrides,
  }
}

export const generateSearchSkusById = (
  skusAmount?: number,
  versionsAmount?: number,
  skuOverrides?: Partial<Sku>,
): SkuSearchResponse => {
  return {
    items: Array.from(
      {
        length: faker.datatype.number({
          min: skusAmount ?? 1,
          max: skusAmount ?? 10,
        }),
      },
      () => {
        const id = faker.datatype.uuid()
        return {
          id,
          createdAt: faker.date.recent().getTime(),
          versions: Array.from(
            {
              length: faker.datatype.number({
                min: versionsAmount ?? 1,
                max: versionsAmount ?? 10,
              }),
            },
            (_sku, index) => generateSku({ version: index + 1, id, ...skuOverrides }),
          ),
        }
      },
    ),
    limit: 100,
  }
}
