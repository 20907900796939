// DEPRECATED COMPONENT. NOT WORTH CONVERTING TESTS TO RTL
import type { FC, MouseEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  DateRangeFilterValues,
  FilterValues,
  RowContextMenuItem,
} from '@helloextend/merchants-ui'
import {
  DataReactTable,
  dateRangeFilterValueToDates,
  PaginationType,
} from '@helloextend/merchants-ui'
import { COLOR, IconSize, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { ContentCopy } from '@extend/zen'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { columns, filterOptions, searchOptions } from './table-config'
import type { TableLead } from '../../../types/merchant-lead'
import { formatLeadsToTableLeadItems } from '../../../utils/format-leads-to-table-lead-items'
import { useDataDelayedToast } from '../../../hooks/use-data-delay-toast'
import { leadsUrlAtom } from '../../../atoms/leads-breadcrumb'
import { LeadsZeroState } from './leads-zero-state'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard'
import { useGetLeadUrls } from '../../../hooks/use-get-lead-urls'
import type { LeadSearchQueryStringOptions } from '../../../queries/leads'
import { useSearchLeadsQuery } from '../../../queries/leads'
import { getActiveStoreAtom } from '../../../atoms/stores'

interface LeadsDataTableProps {
  onLeadsQueryChange: (params: Omit<LeadSearchQueryStringOptions, 'storeId'>) => void
  onLeadsDataChange: (hasLeads: boolean) => void
}

type Filters = Pick<
  LeadSearchQueryStringOptions,
  'searchKey' | 'searchValue' | 'productTransactionDateStart' | 'productTransactionDateEnd'
>

export const LeadsDataTable: FC<LeadsDataTableProps> = ({
  onLeadsQueryChange,
  onLeadsDataChange,
}) => {
  const [hasSearched, setHasSearched] = useState(false)
  const [hasFiltered, setHasFiltered] = useState(false)
  const [searchKey, setSearchKey] = useQueryStringState('search', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')
  const [filters, setFilters] = useQueryStringState<Record<string, FilterValues | null>>(
    'filters',
    {},
    { encode: true, transformDates: true },
  )
  const setLeadsUrl = useSetAtom(leadsUrlAtom)
  const { push } = useHistory()
  const { search: queryParams } = useLocation()
  const { trackEvent } = useUserAnalytics()
  const { toast } = useToaster()

  const initialState = useMemo(() => {
    return {
      pageSize: 50,
      filters: Object.keys(filters).map((key) => ({ id: key, value: filters[key] })),
    }
  }, [filters])
  const store = useAtomValue(getActiveStoreAtom)
  const { leadUrl, shouldDisplayCopyLeadUrlButton } = useGetLeadUrls(store || null)
  const copyToClipboard = useCopyToClipboard()
  const [nextPageCursor, setNextPageCursor] = useState('')

  useEffect(() => {
    // Sync the breadcrumb whenever the query params change
    const leadsUrl = `/store/leads${queryParams}`
    setLeadsUrl(leadsUrl)
  }, [setLeadsUrl, queryParams])

  const filtersForApi = useMemo(() => {
    const newFilters: Filters = {}

    if (filters?.productTransactionDate) {
      const { start, end } = dateRangeFilterValueToDates(
        filters.productTransactionDate as DateRangeFilterValues,
      )
      if (start) newFilters.productTransactionDateStart = `${start}`
      if (end) newFilters.productTransactionDateEnd = `${end}`
    }

    if (searchKey && searchValue) {
      newFilters.searchKey = searchKey
      newFilters.searchValue = searchValue
    }

    onLeadsQueryChange(newFilters)

    return newFilters
  }, [searchKey, searchValue, filters, onLeadsQueryChange])

  const { data, isSuccess, isInitialLoading, isFetching, isError } = useSearchLeadsQuery({
    params: { storeId: store?.id ?? '', cursor: nextPageCursor || undefined, ...filtersForApi },
    refetchOnMount: true,
  })
  const mappedData = useMemo(() => formatLeadsToTableLeadItems(data?.items ?? []), [data])
  const [autoResetPage, setAutoResetPage] = useState(true)

  useEffect(() => {
    if (!isFetching && searchValue && data?.items) {
      trackEvent('lead_search_result_count', { count: data.items.length })
    }

    if (isError) {
      toast({
        message: 'Encountered an error while trying to retrieve leads',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isFetching, data, searchValue, trackEvent, isError, toast])

  useDataDelayedToast(isFetching)
  useEffect(() => {
    onLeadsDataChange(Boolean(data?.items && data?.items.length > 0))
  }, [data, onLeadsDataChange])

  const handleCopyLeadToken = useCallback(
    (tableLead: TableLead) => {
      copyToClipboard('Lead token', tableLead.id)
    },
    [copyToClipboard],
  )

  const handleCopyLeadUrl = useCallback(
    (tableLead: TableLead) => {
      copyToClipboard('Lead URL', `${leadUrl}?leadToken=${tableLead.id}`, copyLeadUrlMessage)
    },
    [copyToClipboard, leadUrl],
  )

  // @ts-expect-error icon key does not match icon props
  const contextMenu: Array<RowContextMenuItem<TableLead>> = useMemo(() => {
    const menuItems = [
      {
        key: 'copy-lead-token',
        text: 'Copy lead token',
        icon: { icon: ContentCopy, color: COLOR.BLUE[800], size: IconSize.small },
        onClick: handleCopyLeadToken,
      },
    ]

    if (shouldDisplayCopyLeadUrlButton) {
      menuItems.push({
        key: 'copy-lead-url',
        text: 'Copy lead url',
        icon: { icon: ContentCopy, color: COLOR.BLUE[800], size: IconSize.small },
        onClick: handleCopyLeadUrl,
      })
    }

    return menuItems
  }, [handleCopyLeadToken, handleCopyLeadUrl, shouldDisplayCopyLeadUrlButton])

  const resetPagination = useCallback(() => {
    setNextPageCursor('')
    setAutoResetPage(true)
  }, [])

  const handleServerPagination = useCallback((cursor: string) => {
    const nextCursor = cursor ?? ''
    setNextPageCursor(nextCursor)
    setAutoResetPage(false)
  }, [])

  const handleSearch = useCallback(
    (key?: string, value?: string): void => {
      setHasSearched(true)
      setFilters({})
      if (key && value) {
        setSearchKey(key)
        setSearchValue(value)
        resetPagination()
      } else if (!value?.length) {
        setSearchKey('')
        setSearchValue('')
        resetPagination()
      }

      onLeadsQueryChange({ searchKey: key, searchValue: value })
    },
    [resetPagination, setSearchKey, setSearchValue, setFilters, onLeadsQueryChange],
  )

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setHasFiltered(true)
      setSearchKey('')
      setSearchValue('')
      setFilters(filtersRecord)
      resetPagination()
    },
    [resetPagination, setFilters, setSearchKey, setSearchValue],
  )

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: TableLead) => {
      push(`/store/leads/${rowData.id}`)
    },
    [push],
  )

  const shouldDisplayZeroState =
    isError ||
    (!hasSearched && !hasFiltered && !data?.items?.length && !isInitialLoading && isSuccess)

  return (
    <DataReactTable
      data-cy="leads"
      isLoading={isInitialLoading || isFetching}
      initialState={initialState}
      initialSearchValue={searchValue}
      hasSearchBar
      onlyOneFilterAllowed
      type="leads"
      data={mappedData}
      columns={columns}
      searchOptions={searchOptions}
      onServerSearch={handleSearch}
      onRowClick={handleRowClick}
      onServerFilter={handleServerFilter}
      onServerPagination={handleServerPagination}
      autoResetPage={autoResetPage}
      nextPageCursor={data?.nextPageCursor}
      paginationType={PaginationType.ENHANCED_SERVER_SIDE}
      filterOptions={!isError ? filterOptions : undefined}
      contextMenuItems={contextMenu}
      disableSortRemove
      isInitialReqFullfilled={!isInitialLoading}
      shouldDisplayZeroState={shouldDisplayZeroState}
      emptyMessage="Double check that the search term is correct, or try applying different filters to your search."
      zeroState={<LeadsZeroState isError={isError} />}
    />
  )
}

export const copyLeadUrlMessage = (
  <div>
    Lead URL copied to clipboard. You can configure this in{' '}
    <Link className="text-underline" to="/store/settings">
      settings
    </Link>
    .
  </div>
)
