type ValidParamTypes = { [key: string]: string | number | undefined }

export function getNonEmptyParams<T extends ValidParamTypes>(params: T): Record<string, string> {
  return Object.keys(params).reduce((acc, k) => {
    if (params[k as keyof T] !== undefined) {
      acc[k as string] = String(params[k as keyof T])
    }
    return acc
  }, {} as Record<string, string>)
}
