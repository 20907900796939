import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'

const PHONE_NUMBER_MIN_LENGTH_USA = 10
export const supportSchema = Yup.object()
  .shape({
    name: Yup.string().required('Please enter your name'),
    email: Yup.string().email('Please enter a valid email').required().label('Email'),
    phoneNumber: Yup.string().test(
      'phone-number-validation',
      'Please enter a valid phone number',
      (value): boolean => {
        return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      },
    ),
    requestType: Yup.string().required().label('Request Type'),
    storeDomainName: Yup.string().required().label('Store Domain Name'),
    message: Yup.string().required('Send us a message'),
  })
  .defined()

export type SupportSchemaValues = Yup.InferType<typeof supportSchema>
