// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Zm1MemqyKwdG52ZXQt1SB {\n    margin-bottom: 32px;\n}\n\n._3J0Hyq2meIUu8PHhUQMBCN {\n    font-size: 32px;\n    font-weight: 700;\n    margin: 0 0 10px 0;\n}\n\n.REgshYgdHJxOgn8ekPEU2 {\n    font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_1Zm1MemqyKwdG52ZXQt1SB",
	"heading": "_3J0Hyq2meIUu8PHhUQMBCN",
	"subheading": "REgshYgdHJxOgn8ekPEU2"
};
module.exports = exports;
