import { useCallback, useMemo, useReducer, useState } from 'react'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { MerchantsEnterpriseUserRole } from '../../types/users'
import type { Organization } from '../../queries/organizations'
import { useGetOrganizations } from '../../queries/organizations'
import type { RoleOrg } from '../../utils/user-roles'
import { diffRolesR3 } from '../../utils/user-roles'
import { useUpdateUserGrantsMutationR3 } from '../../queries/users-v3'
import { useRoleOptions } from '../../hooks/use-role-options'
import type { RoleOption } from '../../hooks/use-role-options'
import type { Grant } from '../../types/okta'
import { useV3UserInfo } from './use-v3-user-info'

type Action =
  | { type: 'ADD_ROW'; payload: { role: string } }
  | { type: 'REMOVE_ROW'; payload: { index: number } }
  | { type: 'SET_ROW_ROLE'; payload: { role: string; index: number } }
  | { type: 'SET_ROW_ORGANIZATIONS'; payload: { orgIds: string[]; index: number } }
  | { type: 'RESET'; payload: RoleOrg[] }

type V3UserEditFormState = {
  organizations: Organization[]
  roleOrgMappings: RoleOrg[]
  hasChanges: boolean
  isRoleDeleteModalOpen: boolean
  changeRowRole: (role: string, index: number) => void
  changeRowOrganizations: (organizations: string[], index: number) => void
  addRow: () => void
  removeRow: (index: number) => void
  updateRoles: () => Promise<void>
  isUpdatingRoles: boolean
  isLoading: boolean
  errors?: string
  closeDeleteModal: () => void
}

export function useV3UserEditForm({
  uuid,
  onCancel,
}: {
  uuid: string
  onCancel: () => void
}): V3UserEditFormState {
  /**
   * UI dependencies
   */
  const { toast } = useToaster()

  /**
   * Data Dependencies
   */
  const options = useRoleOptions()

  /**
   * Query Dependencies
   */
  const { data: orgData, isLoading: isLoadingOrgs } = useGetOrganizations()
  const { data, isLoading: isLoadingGrants } = useV3UserInfo(uuid)

  /**
   * Ephemeral State
   */
  const [errors, setErrors] = useState<string | undefined>(undefined)
  const [hasChanges, setHasChanges] = useState(false)
  const [isRoleDeleteModalOpen, setIsRoleDeleteModalOpen] = useState(false)

  const { mutateAsync: updateRolesMutation, isLoading: isUpdatingRoles } =
    useUpdateUserGrantsMutationR3()

  const initialState: RoleOrg[] = useMemo(() => {
    return generateInitialRoleOrgs(options, data?.grants || [])
  }, [options, data?.grants])

  const [roleOrgMappings, dispatch] = useReducer(roleOrgsReducer, initialState)

  const closeDeleteModal = useCallback(
    () => setIsRoleDeleteModalOpen(false),
    [setIsRoleDeleteModalOpen],
  )

  /**
   * Convenience 'selectors'
   */
  const nextAvailableRole = useMemo(() => {
    const usedRoles = roleOrgMappings.map((ro) => ro.role)
    return options.find((roleOpt) => !usedRoles.includes(roleOpt.value))?.value || ''
  }, [options, roleOrgMappings])

  /**
   * Reducer functions
   */
  const changeRowRole = useCallback(
    (role: string, index: number) => {
      setHasChanges(true)
      dispatch({ type: 'SET_ROW_ROLE', payload: { role, index } })
    },
    [dispatch, setHasChanges],
  )

  const changeRowOrganizations = useCallback(
    (orgIds: string[], index: number) => {
      setHasChanges(true)
      setErrors(undefined)
      dispatch({ type: 'SET_ROW_ORGANIZATIONS', payload: { orgIds, index } })
    },
    [dispatch, setHasChanges],
  )

  const addRow = useCallback(() => {
    if (roleOrgMappings.length < options.length && nextAvailableRole) {
      setHasChanges(true)
      dispatch({ type: 'ADD_ROW', payload: { role: nextAvailableRole } })
    }
  }, [dispatch, setHasChanges, options.length, roleOrgMappings.length, nextAvailableRole])

  const removeRow = useCallback(
    (index: number) => {
      if (roleOrgMappings.length > 1) {
        setHasChanges(true)
        dispatch({ type: 'REMOVE_ROW', payload: { index } })
      }
    },
    [dispatch, setHasChanges, roleOrgMappings.length],
  )

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: initialState })
  }, [dispatch, initialState])

  /**
   * Save Mutation Flow
   */
  const saveChanges = useCallback(async (): Promise<void> => {
    if (!data?.email) {
      return
    }
    const errorMessage = getRoleOrgsErrors(roleOrgMappings)
    if (errorMessage) {
      setErrors(errorMessage)
      return
    }

    const diff = diffRolesR3(roleOrgMappings, initialState)
    const isDeleting = diff.toRemove.length > 0

    if (!isRoleDeleteModalOpen && isDeleting) {
      setIsRoleDeleteModalOpen(true)
      return
    }

    try {
      await updateRolesMutation({
        userId: data.email,
        newRoleOrgs: roleOrgMappings,
        previousRoleOrgs: initialState,
        uuid,
      })
      toast({
        message: 'Successfully updated user roles',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      onCancel()
      reset()
    } catch (error) {
      dispatch({ type: 'RESET', payload: initialState })
      setIsRoleDeleteModalOpen(false)
      toast({
        message: 'Unable to update user roles',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [
    data?.email,
    initialState,
    onCancel,
    reset,
    roleOrgMappings,
    toast,
    updateRolesMutation,
    uuid,
    isRoleDeleteModalOpen,
  ])

  return {
    organizations: orgData || [],
    roleOrgMappings,
    hasChanges,
    isRoleDeleteModalOpen,
    updateRoles: saveChanges,
    isUpdatingRoles,
    changeRowRole,
    changeRowOrganizations,
    addRow,
    removeRow,
    isLoading: isLoadingGrants || isLoadingOrgs,
    errors,
    closeDeleteModal,
  }
}

function roleOrgsReducer(state: RoleOrg[], action: Action): RoleOrg[] {
  switch (action.type) {
    case 'ADD_ROW': {
      return [...state, { role: action.payload.role, orgIds: [] }]
    }
    case 'REMOVE_ROW': {
      if (state.length <= 1) {
        return [{ role: '', orgIds: [] }]
      }

      return [...state.slice(0, action.payload.index), ...state.slice(action.payload.index + 1)]
    }
    case 'SET_ROW_ROLE': {
      const prev = state[action.payload.index]

      if (state.length === 1) {
        return [{ ...prev, role: action.payload.role }]
      }
      return [
        ...state.slice(0, action.payload.index),
        { ...prev, role: action.payload.role },
        ...state.slice(action.payload.index + 1),
      ]
    }
    case 'SET_ROW_ORGANIZATIONS': {
      const prev = state[action.payload.index]

      if (state.length === 1) {
        return [{ ...prev, orgIds: action.payload.orgIds }]
      }

      return [
        ...state.slice(0, action.payload.index),
        { ...prev, orgIds: action.payload.orgIds },
        ...state.slice(action.payload.index + 1),
      ] as RoleOrg[]
    }
    case 'RESET': {
      return action.payload
    }
    default:
      return state
  }
}

/**
 * Exported only for testing
 */
export function getRoleOrgsErrors(state: RoleOrg[]): string | undefined {
  return state.every((ro) => ro.orgIds.length === 0)
    ? 'User must be assigned to at least one organization'
    : undefined
}

/**
 * Exported only for testing
 */
export function generateInitialRoleOrgs(roleOptions: RoleOption[], grants: Grant[]): RoleOrg[] {
  if (grants.length === 0) {
    return [{ role: roleOptions[0].value, orgIds: [] }]
  }

  const mappedInitialState = [] as RoleOrg[]
  const initializedRoles = [] as string[]
  const validRoles = roleOptions.map((ro) => ro.value)

  grants.forEach((_grant, index) => {
    const currentRole = grants[index].role as MerchantsEnterpriseUserRole
    const isR3Grant = grants[index].ern.includes('ORG:')

    if (validRoles.includes(currentRole) && isR3Grant) {
      const currentOrgId = grants[index].ern.split('ORG:')[1]
      const mappedIndex = initializedRoles.findIndex((r) => r === currentRole)

      if (mappedIndex !== -1) {
        mappedInitialState[mappedIndex].orgIds.push(currentOrgId)
      } else {
        initializedRoles.push(currentRole)
        mappedInitialState.push({ role: currentRole, orgIds: [currentOrgId] })
      }
    }
  })

  return mappedInitialState
}
