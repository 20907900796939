import type { FC } from 'react'
import React from 'react'
import { ErrorIcon, BrokenLinkIcon } from '@helloextend/merchants-ui'
import { COLOR } from '@extend/zen'
import styles from './error-status-page.module.css'

const Error500: FC = () => {
  return (
    <>
      <div className={styles['icon-container']}>
        <ErrorIcon sizePx={47} fill={COLOR.NEUTRAL[800]} />
        <BrokenLinkIcon sizePx={46} fill={COLOR.NEUTRAL[800]} />
      </div>
      <div className={styles.title} data-cy="errorTitle">
        Something went wrong
      </div>
      <div className={styles.text} data-cy="errorText">
        The content for this page failed to load. Please contact support if this issue persists.
      </div>
    </>
  )
}

export { Error500 }
