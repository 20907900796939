import { atom } from 'jotai/vanilla'
import { atomWithReset } from 'jotai/vanilla/utils'

export const supportPopoverContactFormDefaultsAtom = atomWithReset<{
  requestType: string | null
  message: string | null
}>({
  requestType: null,
  message: null,
})

export const shouldShowSupportPopoverContactFormAtom = atom(false)

export const shouldShowSupportPopoverAtom = atom(false)
