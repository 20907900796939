// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AsuBTrYbSqR_vL76lzS-1 {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n._2hQr96YvnUEOSS6wKHVVpG {\n    flex: 1 0 100%;\n}\n\n.vuNear60abWImZjIG17Lo {\n    font-size: 18px;\n    margin-bottom: 8px;\n}\n\n._3a2yWY3gOj6uBYaQ39fL9Y {\n    flex-grow: 1;\n}\n\n._2GbtQs45ZVjl1-b47pLnD {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n._2GbtQs45ZVjl1-b47pLnD > * + * {\n    margin-top: 16px;\n}\n\n@media (min-width: 1025px) {\n    ._2hQr96YvnUEOSS6wKHVVpG {\n        flex: 0 1 348px;\n        margin-right: 46px;\n    }\n\n    ._2GbtQs45ZVjl1-b47pLnD {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "AsuBTrYbSqR_vL76lzS-1",
	"header": "_2hQr96YvnUEOSS6wKHVVpG",
	"header-text": "vuNear60abWImZjIG17Lo",
	"details": "_3a2yWY3gOj6uBYaQ39fL9Y",
	"details-column": "_2GbtQs45ZVjl1-b47pLnD"
};
module.exports = exports;
