// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._23BVfHfwZIwCcKP1o4jTsK {\n    display: grid;\n    grid-auto-flow: row;\n    grid-template-columns: repeat(4, 1fr);\n    gap: 8px;\n}\n\n._3KgiTDf_UvQd-ocOhFxMzC {\n    display: grid;\n    gap: 0.5rem;\n    grid-column: 1/3;\n}\n\n._3KgiTDf_UvQd-ocOhFxMzC label {\n    color: var(--neutral-1000);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_23BVfHfwZIwCcKP1o4jTsK",
	"input": "_3KgiTDf_UvQd-ocOhFxMzC"
};
module.exports = exports;
