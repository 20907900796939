import type { FC } from 'react'
import React from 'react'
import Images from '../../images'
import styles from './support-help-category-button.module.css'

type HelpButtonType = 'billing' | 'leads' | 'basics'

type HelpButtonProps = {
  helpType: HelpButtonType
  text: string
  url: string
}

const helpButtonImageMap: { [Key in HelpButtonType]: string } = {
  basics: Images.helpBasicsImage,
  billing: Images.helpBillingImage,
  leads: Images.helpLeadsImage,
}

export const SupportHelpCategoryButton: FC<HelpButtonProps> = ({ helpType, text, url }) => {
  return (
    <a className={styles.button} title={text} href={url} target="_blank" rel="noreferrer">
      <img
        className={styles.help}
        src={helpButtonImageMap[helpType]}
        alt={`${helpType.toUpperCase()} Help`}
      />
      <span>{text}</span>
    </a>
  )
}
