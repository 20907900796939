import React, { FC } from 'react'
import lodash from 'lodash'
import { DataPropertyGrid } from '../../../../components/data-property-grid'
import { getCustomerInfoFieldsFromOrder } from '../../../../utils/customer-info-fields'
import { Checkbox } from '@extend/zen'
import { Order } from '@helloextend/extend-api-client'
import styles from './customer-information-grid.module.css'
import { NewOrder } from '../../../../atoms/create-contracts-modal'

export const CustomerInformationGrid: FC<{
  order?: Order | NewOrder
}> = ({ order }) => {
  const fields = getCustomerInfoFieldsFromOrder(order)

  // determine if the existing, persisted shipping and billing addresses are identical.
  // this is only used when there is already an existing order.
  const isShippingAndBillingAddressesIdentical =
    order &&
    lodash.isEqual(order?.customer.billingAddress, order?.customer.shippingAddress)

  return (
    <>
      <DataPropertyGrid values={fields.customerInformation} className={styles.grid} />
      <h4 className={styles['h4-styles']}>SHIPPING INFORMATION</h4>
      <DataPropertyGrid values={fields.shippingInformation} className={styles.addressGrid} />
      <h4 className={styles['h4-styles']}>BILLING INFORMATION</h4>
      {isShippingAndBillingAddressesIdentical ? (
        <Checkbox checked disabled label="Shipping Information is the same as Billing Address" />
      ) : (
        <DataPropertyGrid values={fields.billingInformation} className={styles.addressGrid} />
      )}
    </>
  )
}
