import type { FC } from 'react'
import React, { useState } from 'react'
import { Button, Help, Modal, ModalController } from '@extend/zen'
import { BadgeDescriptionItem, Descriptions } from './badge-descriptions'
import styles from './badge-descriptions-modal.module.css'

export interface BadgeDescriptionsProps {
  descriptions: BadgeDescriptionItem[]
  'data-cy'?: string
}

export const BadgeDescriptionsModal: FC<BadgeDescriptionsProps> = ({
  descriptions,
  'data-cy': dataCy,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        data-cy={dataCy}
        onClick={() => setIsOpen(true)}
        size="xsmall"
        icon={Help}
        emphasis="low"
      />
      <ModalController isOpen={isOpen}>
        <Modal heading="Claim Status" onDismissRequest={() => setIsOpen(false)}>
          <Descriptions
            data-cy={dataCy}
            descriptions={descriptions}
            badgeCellStyle={styles['badge-cell']}
          />
        </Modal>
      </ModalController>
    </>
  )
}
