import { ContractStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '../components/badge-descriptions'

const getContractStatusSynonyms = (contractStatus: ContractStatus): ContractStatus[] => {
  switch (contractStatus) {
    case ContractStatus.LIVE:
    case ContractStatus.CREATED:
      return [ContractStatus.LIVE, ContractStatus.CREATED]
    case ContractStatus.CANCELLED:
    case ContractStatus.REFUND_REQUESTED:
    case ContractStatus.REFUND_ACCEPTED:
      return [
        ContractStatus.CANCELLED,
        ContractStatus.REFUND_REQUESTED,
        ContractStatus.REFUND_ACCEPTED,
      ]
    case ContractStatus.DELIVERED:
      return [ContractStatus.DELIVERED]
    case ContractStatus.EXPIRED:
      return [ContractStatus.EXPIRED]
    case ContractStatus.FULFILLED:
      return [ContractStatus.FULFILLED]
    case ContractStatus.REFUND_DENIED:
      return [ContractStatus.REFUND_DENIED]
    case ContractStatus.REFUND_PAID:
      return [ContractStatus.REFUND_PAID]
    case ContractStatus.VOIDED:
      return [ContractStatus.VOIDED]
    case ContractStatus.TERMINATED:
      return [ContractStatus.TERMINATED]
    default:
      return []
  }
}

const getContractStatusCopy = (contractStatus: ContractStatus): BadgeDescriptionItem => {
  switch (contractStatus) {
    case ContractStatus.LIVE:
    case ContractStatus.CREATED:
      return {
        text: 'Active',
        description: 'Coverage has started and claims can be filed against the protection plan.',
        color: 'green',
      }
    case ContractStatus.CANCELLED:
    case ContractStatus.REFUND_REQUESTED:
    case ContractStatus.REFUND_ACCEPTED:
      return {
        text: 'Canceled',
        description: 'Canceled by the request of the customer. Future claims will be rejected.',
        color: 'neutral',
      }

    case ContractStatus.DELIVERED:
      return {
        text: 'Delivered',
        description:
          'All shipments covered by the shipping protection plan have been delivered. Future claims will be reviewed manually.',
        color: 'neutral',
      }
    case ContractStatus.EXPIRED:
      return {
        text: 'Expired',
        description:
          'Product protection coverage has ended. Future claims against will be rejected.',
        color: 'red',
      }
    case ContractStatus.FULFILLED:
      return {
        text: 'Fulfilled',
        description:
          'All available entitlements have been consumed. Future claims will be rejected.',
        color: 'neutral',
      }
    case ContractStatus.REFUND_DENIED:
      return {
        text: 'Refund Denied',
        description: '',
        color: 'neutral',
      }
    case ContractStatus.REFUND_PAID:
      return {
        text: 'Refund Paid',
        description: '',
        color: 'neutral',
      }
    case ContractStatus.VOIDED:
      return {
        text: 'Voided',
        description:
          'Voided by your organization due to fraud. Future claims against will be rejected.',
        color: 'red',
      }
    case ContractStatus.TERMINATED:
      return {
        text: 'Terminated',
        description: 'Terminated by Extend due to fraud. Future claims against will be rejected.',
        color: 'red',
      }
    default:
      return {
        text: 'Unknown',
        description: '',
        color: 'neutral',
      }
  }
}

const statusDescriptions = [
  getContractStatusCopy(ContractStatus.LIVE),
  getContractStatusCopy(ContractStatus.CANCELLED),
  getContractStatusCopy(ContractStatus.FULFILLED),
  getContractStatusCopy(ContractStatus.DELIVERED),
  getContractStatusCopy(ContractStatus.EXPIRED),
  getContractStatusCopy(ContractStatus.VOIDED),
  getContractStatusCopy(ContractStatus.TERMINATED),
]

export { getContractStatusSynonyms, getContractStatusCopy, statusDescriptions }
