import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Modal, ModalController } from '@extend/zen'
import { Trash } from '@extend/zen'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { Theme } from '@helloextend/extend-api-rtk-query'
import { usePrevious } from '@helloextend/client-hooks'
import { useAtom, useSetAtom } from 'jotai/react'
import {
  themeToDeleteAtom,
  currentlySelectedThemeIdAtom,
  createThemeNameAtom,
} from '../../../atoms/customize-theme'

export type DeleteModalProps = {
  isVisible: boolean
  handleCloseModal: () => void
  handleDelete: () => void
  isProcessing: boolean
  isSuccess: boolean
  error?: FetchBaseQueryError | SerializedError | null | string
  reset: () => void
  currentlySelectedTheme: Theme | null
}

export const DeleteModal: FC<DeleteModalProps> = ({
  isVisible,
  handleCloseModal,
  handleDelete,
  isProcessing,
  isSuccess,
  error,
  reset,
  currentlySelectedTheme,
}) => {
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const [themeToDelete, setThemeToDelete] = useAtom(themeToDeleteAtom)
  const [newThemeName] = useAtom(createThemeNameAtom)

  const prevProcessing = usePrevious<boolean>(isProcessing)
  const { toastCompleted, toastError } = useStandardToast()

  const deletingSelectedTheme = useMemo(
    () => themeToDelete?.themeId === currentlySelectedTheme?.themeId,
    [themeToDelete, currentlySelectedTheme],
  )

  useEffect(() => {
    if (prevProcessing && !error && isSuccess) {
      toastCompleted('Theme Successfully Removed')
      reset()
      setThemeToDelete(null)
      handleCloseModal()

      if (deletingSelectedTheme) {
        setCurrentThemeId('')
      }
    }
  }, [
    setCurrentThemeId,
    setThemeToDelete,
    prevProcessing,
    error,
    isSuccess,
    toastCompleted,
    reset,
    handleCloseModal,
    deletingSelectedTheme,
  ])

  useEffect(() => {
    if (error) {
      toastError('Uh-Oh, something went wrong. Please try again.')
      reset()
    }
  }, [error, toastError, reset])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Delete this theme?"
        secondaryButtonProps={{
          'data-cy': 'delete-modal-cancel',
          text: 'Cancel',
          onClick: handleCloseModal,
        }}
        primaryButtonProps={{
          'data-cy': 'delete-modal-delete',
          text: 'Delete Theme',
          color: 'red',
          onClick: handleDelete,
          icon: Trash,
          isProcessing,
        }}
      >
        <p data-cy="delete-theme-label">{`${
          newThemeName || themeToDelete?.name || 'The selected theme'
        } will be deleted—this can't be undone.`}</p>
      </Modal>
    </ModalController>
  )
}
