// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2I-mEsV866H9XWX708Chz_ {\n    text-align: right;\n    padding-right: 10px;\n}", ""]);
// Exports
exports.locals = {
	"cell": "_2I-mEsV866H9XWX708Chz_"
};
module.exports = exports;
