import type { FC, FormEvent } from 'react'
import React, { useState } from 'react'
import { DatePicker } from '@helloextend/merchants-ui'
import type { Reply, Slot } from '@helloextend/extend-api-client'
import { Footer } from './footer'
import type { MerchantContract } from '../../../../types/merchant-contract'
import styles from './answer-date-picker.module.css'
import { getFormattedIncredibotMessage } from '../../../../utils/get-formatted-incredibot-message'

interface AnswerDatePickerProps {
  onSubmit: (slot: Slot, slotValue: string | number) => void
  isLoading: boolean
  reply: Reply
  contractId: MerchantContract['id']
}

export const AnswerDatePicker: FC<AnswerDatePickerProps> = ({
  isLoading,
  onSubmit,
  reply,
  contractId,
}) => {
  const [value, setValue] = useState<Date | null>(new Date())

  const handleChange = (date: Date | null): void => {
    if (date) {
      setValue(date)
    }
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()
    if (reply.prompt?.slot && value) {
      onSubmit(reply.prompt.slot, value.getTime())
    }
  }

  return (
    <form className={styles.picker} onSubmit={handleSubmit}>
      <div className={styles['datepicker-form-wrapper']}>
        <h2>{getFormattedIncredibotMessage(reply.messages)}</h2>
        <DatePicker
          data-cy="date-stopped-working"
          selected={value}
          onChange={handleChange}
          calendarPosition="bottom"
        />
      </div>
      <Footer disabled={!value} isLoading={isLoading} contractId={contractId} />
    </form>
  )
}
