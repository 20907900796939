import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { customLogger } from '@extend/client-helpers'
import * as pkceOauth from '../lib/pkce-oauth'
import { AuthLayout } from '../components/auth-layout'
import { HeadTag } from '../components/head-tag'
import { OktaLoginButton } from '../features/login/okta-login-button'
import { UserNoGrantsError, useGetOktaAccessTokenQuery } from '../queries/okta'

const OktaLoginCallback: FC = () => {
  const { toast } = useToaster()
  const history = useHistory()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const oktaCallbackError = params.get('error')
  const state = params.get('state')

  const { mutateAsync: getOktaAccessToken, isLoading: isGettingOktaToken } =
    useGetOktaAccessTokenQuery({
      onSuccess: () => {
        history.push('/account-selector')
      },
    })

  const handleLoginCallback = useCallback(async (): Promise<void> => {
    try {
      const verifier = pkceOauth.getCodeVerifier()
      const pkceState = pkceOauth.getPkceState()

      if (oktaCallbackError || !code || !verifier || pkceState !== state) {
        throw new Error('Error verifying PKCE state')
      }
      await getOktaAccessToken({ code, verifier })
    } catch (err: unknown) {
      if (err instanceof UserNoGrantsError) {
        toast({
          message: 'You do not have access to this application',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        history.push('/login')
        return
      }

      if (err instanceof Error) {
        customLogger.error(err.message, { stack: err.stack })
      }
      toast({
        message: 'Something went wrong during login',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      history.push('/login')
    }
  }, [oktaCallbackError, code, state, getOktaAccessToken, history, toast])

  useEffect(() => {
    handleLoginCallback()
  }, [handleLoginCallback])

  return (
    <AuthLayout showFooter>
      <HeadTag siteTitle="Extend | Login" />
      <OktaLoginButton isLoading={isGettingOktaToken} />
    </AuthLayout>
  )
}

export { OktaLoginCallback }
