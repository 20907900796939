import { Information } from '@extend/zen'
import React from 'react'
import styles from './leads-section-info.module.css'

export const LeadsEmailTypeInfo = (): JSX.Element => {
  return (
    <>
      <Information>
        <div className={styles.info}>Marketing</div>
        <ul className={styles.list}>
          <li className={styles.first}>4 emails sent on Extend’s domain</li>
          <li className={styles.plain}>(e.g. extendedprotection@extend.com)</li>
          <li className={styles.item}>Extend/Merchant co-branded</li>
          <li className={styles.item}>More upsell language</li>
          <li className={styles.item}>Ability to unsubscribe</li>
        </ul>
        <div className={styles.info}>Transactional</div>
        <ul className={styles.list}>
          <li className={styles.first}>2 emails sent on merchant&apos;s domain</li>
          <li className={styles.plain}>(e.g. extendedprotection@merchant.com)</li>
          <li className={styles.item}>Merchant branded</li>
          <li className={styles.item}>No unsubscribe</li>
        </ul>
      </Information>
    </>
  )
}
