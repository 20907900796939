import type { FC } from 'react'
import React from 'react'
import type { MerchantContract } from '../../../types/merchant-contract'
import { ContractCardListItem } from './contract-card-list-item'
import styles from './contracts-list.module.css'

interface ContractListProps {
  contracts: MerchantContract[]
}

export const ContractsList: FC<ContractListProps> = ({ contracts }) => {
  return (
    <div className={styles.cards}>
      {contracts.map((contract) => {
        return <ContractCardListItem key={contract.id} contract={contract} />
      })}
    </div>
  )
}
