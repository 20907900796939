import type { FC, SyntheticEvent, ChangeEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import {
  COLOR,
  ColorInput,
  IconSize,
  ImageInput,
  isFileValidImage,
  isFileValidSize,
  Select,
  Spinner,
  useToaster,
  ToastColor,
  ToastDuration,
  HeadingSmall,
  Stack,
  Switch,
  Label,
  LinkButton,
  DataProperty,
} from '@extend/zen'
import type { ValidImageExtension } from '@extend/zen'
import { Loader, RangeSlider } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import type { OfferVersion, SDKConfig } from '@extend/extend-sdk-react'
import { ExtendProvider, generateDefaultOfferConfig } from '@extend/extend-sdk-react'
import type { Environment } from '@helloextend/extend-sdk-client'
import { Extend as ExtendSDK } from '@helloextend/extend-sdk-client'
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search'
import { EditorState, Compartment } from '@codemirror/state'
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  lineNumbers,
  rectangularSelection,
} from '@codemirror/view'
import { defaultKeymap } from '@codemirror/commands'
import { css } from '@codemirror/lang-css'
import {
  bracketMatching,
  defaultHighlightStyle,
  foldGutter,
  foldKeymap,
  syntaxHighlighting,
} from '@codemirror/language'
import { debounce } from 'lodash'
import { useAtom, useAtomValue } from 'jotai/react'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { CustomizeSaveBanner } from './customize-save-banner'
import {
  customizeCSSSchema,
  mapSchemaToValues,
  mapValuesToSchema,
} from '../../../schemas/customize-schema'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import { ThemesDropdown } from './themes-dropdown'
import { SubHeader } from '../../../components/sub-header'
import { createThemeNameAtom, draftThemeGlobalPropertiesAtom } from '../../../atoms/customize-theme'
import { CustomizeActionMenu } from './customize-action-menu'
import { ModalsContainer } from './modals-container'
import { PreviewContainer } from './preview-container'
import { useSelectedTheme } from '../../../hooks/use-get-selected-theme'
import { useCreateTheme } from '../../../hooks/use-create-theme'
import { useUpdateTheme } from '../../../hooks/use-update-theme'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import { FONT_FAMILIES } from '../../../constants/customize-font-families'
import { StandardHeader } from '../../../components/standard-header'
import { getBase64 } from '../../../utils/get-base64'
import type { Theme } from '../../../queries/themes'
import { ThemePublishedStatus, useUploadThemeLogoMutation } from '../../../queries/themes'
import { getActiveStoreAtom, getActiveStoreIdAtom } from '../../../atoms/stores'
import styles from './customize.module.css'

export function getSubheaderLabelText(
  createThemeName: string | null,
  currentlySelectedTheme: Theme | null,
): string {
  return createThemeName ?? currentlySelectedTheme?.name ?? ''
}

const MAX_FILE_SIZE_MB = 6
const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpg', 'png']
const localeStringOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const Customize: FC = () => {
  const history = useHistory()
  const { createTheme, isCreateProcessing } = useCreateTheme()
  const { updateTheme, isThemeUpdating } = useUpdateTheme()
  const { theme: currentlySelectedTheme, isLoading: isSelectedThemeLoading } = useSelectedTheme()
  const { toast } = useToaster()
  const {
    mutateAsync: uploadThemeLogo,
    data: themeLogoUrl,
    reset: resetUploadThemeHook,
  } = useUploadThemeLogoMutation()
  const { hasPermission } = usePermissions()
  const hasEditPermissions = hasPermission(Permission.CustomizeFullAccess)

  const storeId = useAtomValue(getActiveStoreIdAtom)
  const [draftThemeGlobalProperties, setDraftThemeGlobalProperties] = useAtom(
    draftThemeGlobalPropertiesAtom,
  )
  const [, setResetLogoUpload] = useState(false)
  const [loadingFrame, setLoadingFrame] = useState(false)
  const codeMirrorEditor = useRef<HTMLInputElement>(null)

  const activeStore = useAtomValue(getActiveStoreAtom)

  const isLoading = useMemo(
    () => isSelectedThemeLoading || !currentlySelectedTheme,
    [currentlySelectedTheme, isSelectedThemeLoading],
  )
  const isProcessing = isThemeUpdating || isCreateProcessing
  const [createThemeName, setCreateThemeName] = useAtom(createThemeNameAtom)

  const handleRangeChange = (rangeValue: number): void => {
    formik.setFieldValue('buttonCornerRadius', rangeValue)
  }

  const handleChangeDropdown = (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>): void => {
    const target = e.currentTarget
    const fontOptionValue = FONT_FAMILIES.find((font) => font.value === target.value)
    formik.setFieldValue('fontFamily', fontOptionValue?.value)
  }

  const resetImageUploader = useCallback(() => {
    resetUploadThemeHook()
    setResetLogoUpload(true)
  }, [resetUploadThemeHook])

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const imageFile = event.target.files && event.target.files[0]

    if (!imageFile) {
      return
    }

    const isValidFile =
      isFileValidImage(imageFile, SUPPORTED_FILE_EXTENSIONS) &&
      isFileValidSize(imageFile, MAX_FILE_SIZE_MB)

    if (!isValidFile) {
      return
    }

    try {
      setResetLogoUpload(false)
      const base64image = await getBase64(imageFile)
      if (base64image) {
        await uploadThemeLogo({ base64image })
      }
    } catch {
      toast({
        message: 'Uh-Oh, something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      resetImageUploader()
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: customizeCSSSchema,
    initialValues:
      currentlySelectedTheme && !isSelectedThemeLoading
        ? mapSchemaToValues(currentlySelectedTheme)
        : customizeCSSSchema.default(),
    onSubmit: (): void => {},
  })

  if (themeLogoUrl && themeLogoUrl !== formik.values.storeLogo) {
    formik.setFieldValue('storeLogo', themeLogoUrl)
  }

  const isEditMode = formik.dirty || !!createThemeName

  // subscribers to update draftGlobalProperties in Redux
  useEffect(() => {
    if (currentlySelectedTheme) {
      setDraftThemeGlobalProperties(currentlySelectedTheme.contents.global)
    }
  }, [setDraftThemeGlobalProperties, currentlySelectedTheme])

  useEffect(() => {
    // originally we had a check here to see if the theme existed and whether the editor was in
    // edit mode similar to the check below in the JSX. This is unnecessary because if that's the
    // case then the ref will be null because of the check down there and we can rely on it here.
    if (codeMirrorEditor.current) {
      const view = EditorView.findFromDOM(codeMirrorEditor.current)

      if (view) return undefined
    }

    const editorTheme = EditorView.theme({
      '&.cm-editor': {
        height: '400px',
        backgroundColor: 'rgba(239, 239, 239, 0.3)',
        color: 'rgb(84, 84, 84)',
        borderColor: 'rgba(118, 118, 118, 0.3)',
        margin: '5px 0px 10px 0px',
      },
    })

    const editableCompartment = new Compartment()

    const startState = EditorState.create({
      extensions: [
        lineNumbers(),
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        foldGutter(),
        drawSelection(),
        dropCursor(),
        EditorState.allowMultipleSelections.of(true),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        bracketMatching(),
        rectangularSelection(),
        crosshairCursor(),
        highlightActiveLine(),
        highlightSelectionMatches(),
        keymap.of([...defaultKeymap, ...searchKeymap, ...foldKeymap]),
        css(),
        editorTheme,
        editableCompartment.of(EditorView.editable.of(false)),
      ],
    })

    const view = new EditorView({
      state: startState,
      parent: codeMirrorEditor.current ?? undefined,
    })

    view.dispatch({
      changes: {
        from: 0,
        to: view.state.doc.length,
        insert: currentlySelectedTheme?.cssConfig?.css ?? '',
      },
    })

    return () => {
      view.destroy()
    }
  }, [currentlySelectedTheme, isEditMode])

  useEffect(() => {
    const values = mapValuesToSchema(formik.values)
    setDraftThemeGlobalProperties(values)
  }, [setDraftThemeGlobalProperties, formik.values])

  // Separate handlers per color to avoid arrow function that would
  // be re-created on each render. This is because ColorInput doesn't
  // bubble up full event.

  const handleBackgroundColorChange = useMemo(
    () =>
      debounce((color: string) => formik.setFieldValue('backgroundColor', color), 300, {
        leading: true,
      }),
    [formik],
  )

  const handlePrimaryFontColorChange = useMemo(
    () =>
      debounce((color: string) => formik.setFieldValue('primaryFontColor', color), 300, {
        leading: true,
      }),
    [formik],
  )

  const handleButtonColorChange = useMemo(
    () =>
      debounce((color: string) => formik.setFieldValue('buttonColor', color), 300, {
        leading: true,
      }),
    [formik],
  )

  const handleButtonTextColorChange = useMemo(
    () =>
      debounce((color: string) => formik.setFieldValue('buttonTextColor', color), 300, {
        leading: true,
      }),
    [formik],
  )

  // This is used to force the iframe to rerender when there are changes that update the providerConfig
  // this will guaruntee that the iframe will always be in sync with the current theme / draft settings
  const loadFrameFn = useMemo(
    () =>
      debounce(() => setLoadingFrame(false), 1000),
    [],
  )

  const providerConfig = useMemo<SDKConfig>(() => {
    setLoadingFrame(true)
    const modalVersion = activeStore?.offerModalVersion ?? 'V1'
    const { environment, referenceId } = generateDefaultOfferConfig({
      storeId,
      version: modalVersion as OfferVersion,
      env: EXTEND_ENV as Environment,
    })

    const sdkThemeOverrides = !draftThemeGlobalProperties ? undefined : { global: draftThemeGlobalProperties }
    const sdkCssOverrides = isEditMode && currentlySelectedTheme?.status === ThemePublishedStatus.published ? {} : currentlySelectedTheme?.cssConfig ?? {}

    const offerConfig = {
      sdkThemeOverrides,
      sdkCssOverrides,
      environment,
      referenceId,
      storeId,
    }

    ExtendSDK.config(offerConfig)

    loadFrameFn()

    return {
      sdk: ExtendSDK,
      ...offerConfig,
    }
  }, [activeStore, currentlySelectedTheme, draftThemeGlobalProperties, isEditMode])

  const handleLeavePage = (path: string): void => {
    setCreateThemeName(null)
    history.push(path)
  }

  const handleResetForm = useCallback(() => {
    if (createThemeName) {
      setCreateThemeName('')
    }
    resetImageUploader()
    formik.resetForm()
  }, [setCreateThemeName, createThemeName, formik, resetImageUploader])

  const handleSaveTheme = (): void => {
    if (!createThemeName && currentlySelectedTheme) {
      updateTheme()
    } else if (createThemeName) {
      createTheme()
    }
  }

  return (
    <>
      <ModalsContainer resetForm={handleResetForm} />
      <LeavePageGuard isNavBlocked={formik.dirty || isEditMode} handleLeavePage={handleLeavePage} />
      <CustomizeSaveBanner
        isVisible={isEditMode}
        onDiscardChanges={handleResetForm}
        themeStatus={
          createThemeName ? ThemePublishedStatus.published : currentlySelectedTheme?.status
        }
        onSaveChanges={handleSaveTheme}
        isProcessing={isProcessing}
      />
      <StandardHeader data-cy="customize-home-page-header" pageTitle="Customize" />
      <div className={styles.dropdown}>
        <ThemesDropdown isFormDirty={formik.dirty} isDisabled={!hasEditPermissions} />
        {!isSelectedThemeLoading && !hasEditPermissions && <CustomizeActionMenu />}
      </div>
      <hr className={styles.divider} />
      <div className="flex">
        {hasEditPermissions ? (
          <>
            <div className={styles.fields} data-cy="customize-form">
              {isLoading ? (
                <div className={styles.subheader}>
                  <Loader width="85%" height="28px" />
                </div>
              ) : (
                <SubHeader
                  color={COLOR.BLUE[1000]}
                  labelText={getSubheaderLabelText(createThemeName, currentlySelectedTheme)}
                />
              )}
              <p>
                Edit this theme to customize how Extend offers look on your site. Use the preview to
                see how the theme applies to different offers.
              </p>
              {isLoading ? (
                <div className={styles.spinner}>
                  <Spinner size={IconSize.xlarge} color={COLOR.NEUTRAL[300]} />
                </div>
              ) : (
                <>
                  <div className={styles['image-input']}>
                    <ImageInput
                      id="image-uploader"
                      label="Store logo"
                      helperText="For best results, use a transparent PNG that's 175x60px or larger with no padding around the logo"
                      currentImage={formik.values.storeLogo}
                      imageExtensions={['jpg', 'png']}
                      onChange={handleImageChange}
                      maxSizeMb={MAX_FILE_SIZE_MB}
                      data-cy="image-uploader-input"
                    />
                  </div>
                  <div className={styles.color}>
                    <ColorInput
                      id="background-color"
                      label="Background Color"
                      onChange={handleBackgroundColorChange}
                      value={formik.values.backgroundColor}
                      data-cy="background-color-picker"
                    />
                  </div>
                  <div className={styles.input}>
                    <Select
                      data-cy="font-family-select"
                      onChange={handleChangeDropdown}
                      label="Font Family"
                      id="font-family"
                      value={formik.values.fontFamily}
                    >
                      {FONT_FAMILIES.map((fontFamily) => (
                        <option value={fontFamily.value} key={fontFamily.value}>
                          {fontFamily.label}
                        </option>
                      ))}
                    </Select>

                    <ColorInput
                      id="primary-font-color"
                      label="Primary Font Color"
                      onChange={handlePrimaryFontColorChange}
                      value={formik.values.primaryFontColor}
                      data-cy="primary-font-color-picker"
                    />
                  </div>
                  <div className={styles.input}>
                    <ColorInput
                      id="button-color"
                      label="Button Color"
                      onChange={handleButtonColorChange}
                      value={formik.values.buttonColor}
                      data-cy="button-color-picker"
                    />
                    <ColorInput
                      id="button-font-color"
                      label="Button Font Color"
                      onChange={handleButtonTextColorChange}
                      value={formik.values.buttonTextColor}
                      data-cy="button-font-color-picker"
                    />
                  </div>
                  <RangeSlider
                    min={0}
                    max={30}
                    data-cy="button-corner-radius-slider"
                    label="Button Corner Radius"
                    labelHelper="Control how round the corners are on your call-to-action buttons"
                    onChange={handleRangeChange}
                    initialValue={formik.values.buttonCornerRadius}
                    suffix="px"
                  />
                  {currentlySelectedTheme?.cssConfig &&
                    !(
                      isEditMode && currentlySelectedTheme.status === ThemePublishedStatus.published
                    ) && (
                      <div data-cy="css-override-section">
                        <hr className={styles.divider} />
                        <HeadingSmall css={{ marginTop: '24px' }}>
                          Custom CSS Override by Extend
                        </HeadingSmall>
                        <p>
                          This theme has a custom CSS Override applied. It will override any
                          conflicting settings you made above. Contact Extend to make changes.
                        </p>
                        <div className={styles['css-override-spacer']}>
                          <DataProperty
                            isHorizontal
                            data-cy="css-last-updated"
                            label="Last Updated"
                            value={new Date(currentlySelectedTheme?.updatedAt).toLocaleString(
                              'en-US',
                              localeStringOptions,
                            )}
                          />
                        </div>
                        <Stack isRow>
                          <Switch
                            id="cssOverrideDeploymentStatus"
                            data-cy="css-override-deployment-status"
                            label="Deployment Enabled"
                            isOn={currentlySelectedTheme?.cssConfig?.enabled ?? false}
                            labelPosition="before"
                            isDisabled
                          />
                        </Stack>
                        <div className={styles['css-override-spacer']}>
                          <Label>Custom CSS Override</Label>
                        </div>
                        <div data-cy="css-override-codemirror" ref={codeMirrorEditor} />
                        <LinkButton
                          data-cy="contact-extend-button"
                          emphasis="medium"
                          text="Contact Extend"
                          color="blue"
                          to="https://docs.extend.com/docs/request-support-from-extend"
                        />
                      </div>
                    )}
                </>
              )}
            </div>
            <div className="flex flex-col flex-grow">
              <p className={styles['preview-label']}>Preview</p>
              {!isSelectedThemeLoading && (
                <ExtendProvider config={providerConfig}>
                  <PreviewContainer isLoading={isSelectedThemeLoading || loadingFrame} />
                </ExtendProvider>
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col flex-grow">
            <p className={styles['preview-label']}>Preview</p>
            {!isSelectedThemeLoading && (
              <ExtendProvider config={providerConfig}>
                <PreviewContainer isLoading={isSelectedThemeLoading || loadingFrame} />
              </ExtendProvider>
            )}
          </div>
        )}
      </div>
    </>
  )
}
