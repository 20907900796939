import type { FC } from 'react'
import React from 'react'
import type { MerchantProduct } from '../../../types/merchant-product'
import { WARRANTY_STATUS_MAP_DISPLAY } from '../../../constants/product-warranty-status'
import { SubHeader } from '../../../components/sub-header'
import styles from './product-details-offer-info.module.css'

type ProductDetailsOfferInfoProps = {
  product: MerchantProduct
}

export const ProductDetailsOfferInfo: FC<ProductDetailsOfferInfoProps> = ({ product }) => {
  return (
    <>
      <div className={styles.details}>
        <SubHeader labelText="Offer Info" />
        <div className={styles.row}>
          <div className={styles.detail}>
            <span className={styles.label}>Status</span>
            <span data-cy="product-details-status">
              {WARRANTY_STATUS_MAP_DISPLAY[product.warrantyStatus]}
            </span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>Display Offer</span>
            <span data-cy="product-details-enabled">{product.enabled ? 'True' : 'False'}</span>
          </div>
        </div>
      </div>
    </>
  )
}
