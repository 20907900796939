import type { FC } from 'react'
import React from 'react'
import { Badge } from '@extend/zen'
import { CellMarkup } from '@helloextend/merchants-ui'
import type { BadgeDescriptionItem } from './badge-descriptions'

type CellStatusBadgeProps = {
  badgeData: BadgeDescriptionItem
}

const CellStatusBadge: FC<CellStatusBadgeProps> = ({ badgeData }) => {
  return (
    <CellMarkup data-cy="badge-status">
      <Badge
        text={badgeData.text}
        emphasis="medium"
        color={badgeData.color}
        size="regular"
        details={badgeData.details}
      />
    </CellMarkup>
  )
}

export { CellStatusBadge }
