// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WkVuQ7Nr5GgoYfRvKJ_wu {\n  margin-bottom: 34px;\n  flex: 1;\n}\n\n._2EkIjLMRMcWQYI-Eu86PGT {\n  font-size: 14px;\n  font-weight: 700;\n  margin-bottom: 12px;\n}\n\n.fCcDZf18EPrD7Lg654KVP {\n  cursor: not-allowed\n}\n\n.fCcDZf18EPrD7Lg654KVP > div {\n  pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"slider-wrapper": "WkVuQ7Nr5GgoYfRvKJ_wu",
	"slider-text": "_2EkIjLMRMcWQYI-Eu86PGT",
	"not-allowed": "fCcDZf18EPrD7Lg654KVP"
};
module.exports = exports;
