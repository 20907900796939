import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useResendInviteMutation } from '../queries/users-v3'

export const useResendInvite = (): {
  resendInvite: (email: string) => Promise<void>
  isLoading: boolean
} => {
  const { mutateAsync, isLoading } = useResendInviteMutation()

  const { toast } = useToaster()
  const resendInvite = async (email: string): Promise<void> => {
    try {
      await mutateAsync(email)
      toast({
        message: `Invite sent to ${email}`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    } catch (err) {
      toast({
        message: 'The invite couldn’t be sent. Try again later',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return { resendInvite, isLoading }
}
