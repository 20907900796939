import { useCallback } from 'react'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useAtom } from 'jotai/react'
import { useSelectedTheme } from './use-get-selected-theme'
import { mapGlobalOverrideToTheme } from '../schemas/customize-schema'
import { draftThemeGlobalPropertiesAtom } from '../atoms/customize-theme'
import { useUpdateThemeMutation } from '../queries/themes'

type UseUpdateTheme = {
  updateTheme: () => void
  isThemeUpdating: boolean
  isThemeUpdateSuccess: boolean
}

const useUpdateTheme = (): UseUpdateTheme => {
  const [draftGlobalProperties] = useAtom(draftThemeGlobalPropertiesAtom)

  const { theme: currentlySelectedTheme } = useSelectedTheme()
  const { toastCompleted, toastError } = useStandardToast()
  const {
    mutateAsync: updateThemeMutation,
    isLoading: isThemeUpdating,
    isSuccess: isThemeUpdateSuccess,
    reset: resetUpdateThemeMutation,
  } = useUpdateThemeMutation()

  const updateTheme = useCallback(async () => {
    if (currentlySelectedTheme && draftGlobalProperties) {
      const payload = mapGlobalOverrideToTheme(currentlySelectedTheme, draftGlobalProperties)
      try {
        await updateThemeMutation(payload)
        toastCompleted('Theme successfully updated')
        resetUpdateThemeMutation()
      } catch (err) {
        toastError('Uh-Oh, something went wrong. Please try again.')
        resetUpdateThemeMutation()
      }
    }
  }, [
    currentlySelectedTheme,
    draftGlobalProperties,
    resetUpdateThemeMutation,
    toastCompleted,
    toastError,
    updateThemeMutation,
  ])

  return { updateTheme, isThemeUpdating, isThemeUpdateSuccess }
}

export { useUpdateTheme }
