// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ZJc97JDZjVJqtz0cnrfL_ {\n    background: var(--neutral-100);\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-family: \"Nunito Sans\", sans-serif;\n}\n\n._1uPDLyiDV40xLy1mzSuJKG {\n    width: 480px;\n    min-height: 292px;\n    max-height: 50%;\n    background: var(--white);\n    border-radius: 12px;\n    box-shadow: 0 0 40px rgba(163, 163, 163, 0.0813688);\n    padding: 40px;\n    box-sizing: border-box;\n    overflow: auto;\n}\n\n.fbWWlvNe17LUN4jRwUq6u {\n    background: var(--white);\n    width: 400px;\n    height: 80px;\n    margin: 16px 0 16px 0;\n    text-align: left;\n    border: 1px solid var(--neutral-300);\n    border-radius: 4px;\n    padding-left: 32px;\n    font-size: 14px;\n    color: var(--neutral-1000);\n    cursor: pointer;\n}\n\n.fbWWlvNe17LUN4jRwUq6u:hover {\n    border: 1px solid var(--blue-800);\n    background: var(--neutral-100);\n}\n\n.EydGV3Wp3AmvtEnHnoEJ {\n    font-size: 12px;\n    letter-spacing: 1px;\n    font-style: normal;\n    font-weight: 700;\n    text-transform: uppercase;\n    margin-bottom: 16px;\n}", ""]);
// Exports
exports.locals = {
	"account-selector-container": "_3ZJc97JDZjVJqtz0cnrfL_",
	"account-selectors": "_1uPDLyiDV40xLy1mzSuJKG",
	"account-selector-item": "fbWWlvNe17LUN4jRwUq6u",
	"account-selector-header": "EydGV3Wp3AmvtEnHnoEJ"
};
module.exports = exports;
