// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1h0836z0FBN5hMTDG54fN3 {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.TGZH8iazP63wtlmD2AZ9r {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._3fOj4_aIzJBs0gBfXhW-z6 {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n.DeZRIKW1SVcdO8zCUqrsP {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_1h0836z0FBN5hMTDG54fN3",
	"row": "TGZH8iazP63wtlmD2AZ9r",
	"details": "_3fOj4_aIzJBs0gBfXhW-z6",
	"label": "DeZRIKW1SVcdO8zCUqrsP"
};
module.exports = exports;
