import jwt, { decode } from '@helloextend/jwt'
import type { AccessToken } from '@helloextend/extend-api-client'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { Grant } from '../types/okta'
import type { EnterpriseUserRole } from './permissions'

type DecodedUserJwtToken = null | {
  firstName: string
  lastName: string
  admin: boolean
  exp: number
  role: EnterpriseUserRole
  accountId: string
  email: string
  sub: string
}

type V3AccessToken = {
  firstName: string
  lastName: string
  ern: string
  sub: string
  scope: string
  email: string
}

export function getUserRoleFromToken(token?: string | null): EnterpriseUserRole | null {
  if (!token) {
    return null
  }

  try {
    const decoded = jwt.decode(token) as DecodedUserJwtToken
    if (!decoded || isExpired(decoded.exp)) {
      return null
    }

    return decoded.role
  } catch (e) {
    return null
  }
}

export function getAccountIdFromV3Token(token: string): string | null {
  try {
    const decoded = decodeToken(token)

    return decoded.ern.split('ACC:')[1].split(':ORG:')[0]
  } catch (e) {
    return null
  }
}

export function getRoleFromCachedRoles(
  cachedRoles: Record<string, Grant>,
  accountId: string,
): EnterpriseUserRole | null {
  if (!cachedRoles || !cachedRoles[accountId]) {
    return null
  }

  return cachedRoles[accountId].role as EnterpriseUserRole
}

function isExpired(expiration: number): boolean {
  return Date.now() / 1000 > expiration
}

export function decodeToken(token: AccessToken): V3AccessToken {
  return jwt.decode(token) as V3AccessToken
}

export function decodeV2Token(token: AccessToken): DecodedUserJwtToken {
  return jwt.decode(token) as DecodedUserJwtToken
}

export function isValidToken(
  token?: string | null,
  checkAdmin = false,
  isOktaLogin = false,
): boolean {
  if (!token) {
    return false
  }

  try {
    const decoded = decode(token) as { admin: boolean; exp: number; iss: string }
    if (isExpired(decoded.exp)) return false
    if (checkAdmin && !decoded.admin) return false

    return !(isOktaLogin && decoded.iss !== EXTEND_API_HOST)
  } catch (e) {
    return false
  }
}
