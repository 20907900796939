import { atom } from 'jotai/vanilla'
import type { Grant } from '../types/okta'

export const deactivateUserModalEmailAtom = atom<string>('')
export const deactivateUserModalGrantsAtom = atom<Grant[]>([])

export const isDeactivateUserModalVisible = atom((get) =>
  Boolean(get(deactivateUserModalEmailAtom)),
)

export const openDeactivateUserModal = atom(null, (_get, set, email: string, grants: Grant[]) => {
  set(deactivateUserModalEmailAtom, email)
  set(deactivateUserModalGrantsAtom, grants)
})

export const closeDeactivateUserModal = atom(null, (_get, set) => {
  set(deactivateUserModalEmailAtom, '')
  set(deactivateUserModalGrantsAtom, [])
})
