import { date } from '@extend/client-helpers'
import { startCase } from 'lodash/fp'
import { ClaimType } from '@helloextend/extend-api-client'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { formatAddress } from './form-utils'
import { mapPlanIdToDisplayValue } from '../schemas/claim-info-schema'
import { claimServiceTypeMap } from './get-service-order-status-copy'
import type { MerchantClaim } from '../types/claims'
import type { MerchantContract } from '../types/merchant-contract'

enum SectionKey {
  customer = 'customer',
  claimInformation = 'claimInformation',
}

export const getProductClaimFields = (
  contract: MerchantContract,
  claim: MerchantClaim,
): Record<SectionKey, DataPropertyDetails[]> => {
  const contractProduct =
    claim.type === ClaimType.CATEGORY
      ? contract.categoryProductsList?.find(
          (p) => p.referenceId === claim.products?.[0].referenceId,
        )
      : contract.product

  const isKaley = `${claim.filedBy?.firstName} ${claim.filedBy?.lastName}` === 'Kaley Chatbot'

  return {
    [SectionKey.customer]: [
      {
        key: 'name',
        label: 'Name',
        dataCy: 'name',
        value: claim.customer.name,
      },
      { key: 'email', label: 'Email', value: claim.customer.email, dataCy: 'email' },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
        value: claim.customer?.phone,
        dataCy: 'phoneNumber',
      },
      {
        key: 'shippingAddress',
        label: 'Customer Shipping Address',
        value: claim.customer?.shippingAddress
          ? formatAddress(claim.customer?.shippingAddress)
          : '',
        dataCy: 'shippingAddress',
      },
    ],
    [SectionKey.claimInformation]: [
      {
        key: 'claimType',
        value: 'Product Protection',
        label: 'Type',
        dataCy: 'claimType',
      },
      {
        key: 'reportedAt',
        value: date.format(claim.reportedAt),
        label: 'Reported At Date',
        dataCy: 'reportedAt',
      },
      {
        key: 'incidentDate',
        value: date.format(claim.incident.occurredAt),
        label: 'Incident Date',
        dataCy: 'incidentDate',
      },
      {
        key: 'claimStatus',
        value: claim.status,
        label: 'Claim Status',
        dataCy: 'claimStatus',
      },
      {
        key: 'incidentDescription',
        value: claim.incident.description,
        label: 'Incident Description',
        dataCy: 'description',
      },
      {
        key: 'contractId',
        value: contract.id,
        label: 'Contract ID',
        dataCy: 'contractId',
        includeCopyAction: true,
      },
      {
        key: 'transactionDate',
        value: contractProduct?.transactionDate
          ? date.format(contractProduct?.transactionDate)
          : '',
        label: 'Transaction Date',
        dataCy: 'transactionDate',
      },
      {
        key: 'transactionId',
        value: contractProduct?.transactionId,
        label: 'Transaction ID',
        dataCy: 'transactionId',
      },
      {
        key: 'fraudulentActivity',
        value: claim.fraudulentActivity?.description,
        label: 'Fraudulent Activity',
        dataCy: 'fraudulentActivity',
      },
      {
        key: 'purchaseOrderNumber',
        value: contract.poNumber,
        label: 'PO Number',
        dataCy: 'purchaseOrderNumber',
      },
      {
        key: 'storeName',
        value: contract.sellerName,
        label: 'Store Name',
        dataCy: 'storeName',
      },
      {
        key: 'storeId',
        value: contract.sellerId,
        label: 'Store ID',
        dataCy: 'storeId',
        includeCopyAction: true,
      },
      {
        key: 'failureType',
        value: startCase(claim.incident.failureType),
        label: 'Failure Type',
        dataCy: 'failureType',
      },
      {
        key: 'failureCause',
        value: startCase(claim.incident.failureCause ?? ''),
        label: 'Failure Cause',
        dataCy: 'failureCause',
      },
      {
        key: 'serviceType',
        value: claimServiceTypeMap[claim.serviceType],
        label: 'Service Type',
        dataCy: 'serviceType',
      },
      {
        key: 'coverage',
        value: mapPlanIdToDisplayValue(contract.plan.planId),
        label: 'Coverage',
        dataCy: 'coverage',
      },
      {
        key: 'coverageTerm',
        value: date.formatDateRange(contract.coverage?.starts, contract.coverage?.ends),
        label: 'Coverage Term',
        dataCy: 'coverageTerm',
      },
      {
        key: 'createdBy',
        value: isKaley ? claim.customer.email : claim.filedBy?.email,
        label: 'Created By',
        dataCy: 'createdBy',
      },
    ],
  }
}
