import { atomWithReset } from 'jotai/vanilla/utils'

type ConfirmProtectionDisableState = {
  label: string
  protectionType: 'shipping' | 'product'
  isVisible: boolean
}

export const confirmProtectionDisableModalAtom = atomWithReset<ConfirmProtectionDisableState>({
  label: '',
  protectionType: 'product',
  isVisible: false,
})
