import type { FC } from 'react'
import React, { useState } from 'react'
import { useResetAtom } from 'jotai/react/utils'
import { Button, Close } from '@extend/zen'
import { supportPopoverContactFormDefaultsAtom } from '../../atoms/support'
import { useUser } from '../../hooks/use-user'
import { SupportContactForm } from './support-contact-form'
import styles from './support-contact-us-step.module.css'

export const SupportContactUsStep: FC<{ closePopover: () => void }> = ({ closePopover }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const resetForm = useResetAtom(supportPopoverContactFormDefaultsAtom)
  const { firstName, lastName, email } = useUser()
  const name = `${firstName} ${lastName}`
  const handleDone = (): void => {
    closePopover()
    resetForm()
  }

  return isSuccess ? (
    <div className={styles.thanks}>
      <div className={styles['thanks-header']}>Thanks for reaching out!</div>
      <div className={styles['thanks-text']}>We will be in touch soon.</div>
      <div className={styles['thanks-button']}>
        <Button fillContainer text="Done" onClick={handleDone} />
      </div>
    </div>
  ) : (
    <>
      <div className="flex flex-row justify-content-between">
        <span className={styles.header}>Contact Us</span>
        <Button icon={Close} color="neutral" emphasis="low" onClick={closePopover} />
      </div>
      <div className={styles.text}>
        Questions, bug reports, feedback, feature requests &mdash; we&apos;re here to help
      </div>
      <SupportContactForm name={name} email={email ?? ''} onSubmit={setIsSuccess} />
    </>
  )
}
