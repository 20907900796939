import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { RouterError } from '@helloextend/component-commons'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { useAtomValue } from 'jotai/react'
import { ShopifyInstall } from './pages/shopify-install'
import { Logout } from './pages/logout'
import { ProtectedRoute } from './components/protected-route'
import { Store } from './pages/store'
import { Permission } from './lib/permissions'
import { useUser } from './hooks/use-user'
import { OktaLogin } from './pages/okta-login'
import { OktaLoginCallback } from './pages/okta-login-callback'
import { AccountSelector } from './pages/account-selector'
import { getActiveStoreAtom, getActiveStoreIdAtom } from './atoms/stores'
import { UserProfile } from './pages/user-profile'
import { useSetRumUser } from './hooks/use-set-rum-user'
import { cachedAccountNameAtom } from './atoms/auth'
import { Account } from './pages/account'
import { useTokenRefresh } from './hooks/use-token-refresh'

const Router: FC = () => {
  const store = useAtomValue(getActiveStoreAtom)
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const ldClient = useLDClient()
  const { initialize } = useUserAnalytics()
  const { isLoggedIn, tokenRole, accountId, userId, email } = useUser()
  const accountName = useAtomValue(cachedAccountNameAtom)

  useTokenRefresh()
  useSetRumUser()

  useEffect(() => {
    const ldUser = !userId
      ? {
          anonymous: true,
        }
      : {
          key: userId,
          email: email as string,
          custom: {
            accountId: accountId as string,
            storeId,
          },
          privateAttributeNames: ['email'],
        }

    ldClient?.identify(ldUser)
  }, [ldClient, userId, email, accountId, storeId])

  useEffect(() => {
    if (userId && email && accountId && storeId && tokenRole && store) {
      const additionalAccountProperties = {
        platform: store.platform,
        product_protection_approved: store.approved,
        claims_management_enabled: store.claimsManagementEnabled,
        shipping_protection_approved: store.shippingProtection?.approved,
        offers_by_category_enabled: store.offersByCategoryEnabled,
        product_upsell_emails_enabled: store.enableMerchantPortalLeadEmailsConfiguration,
        configure_lead_url_enabled: store.leadUrlConfigurabilityEnabled,
        offers_custom_content_enabled: store.offersCustomContentEnabled,
        offers_theme_configurability_enabled: store.themeConfigurabilityEnabled,
        extend_version: store.platformMetaData?.extendVersion,
      }
      initialize({
        userId,
        email,
        accountId,
        accountName,
        storeId,
        role: tokenRole,
        additionalAccountProperties,
      })
    }
  }, [initialize, userId, email, accountId, storeId, tokenRole, store, accountName])

  const rootRedirectTo = useMemo(() => {
    if (!isLoggedIn) {
      return '/login'
    }
    return '/store'
  }, [isLoggedIn])

  return (
    <Switch>
      <Redirect from="/" to={rootRedirectTo} exact />
      <Route path="/login/callback" exact>
        <OktaLoginCallback />
      </Route>
      <Route path="/login">
        <OktaLogin />
      </Route>
      <Route path="/account-selector">
        <AccountSelector />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/magento">
        <OktaLogin />
      </Route>
      <ProtectedRoute path="/shp/install" component={ShopifyInstall} loginRedirectPath="/login" />
      <ProtectedRoute path="/store/:submitSupportTicket?" component={Store} />
      <ProtectedRoute path="/account/:submitSupportTicket?" component={Account} />
      {/* Routes and Redirects for backwards compatbility */}
      <ProtectedRoute path="/dashboard" component={Store} />
      <Redirect from="/credentials" to="/store/credentials" />
      <ProtectedRoute
        requiredPermissions={[Permission.ManageProfile]}
        path="/profile"
        component={UserProfile}
      />
      <Route>
        <RouterError />
      </Route>
    </Switch>
  )
}

export { Router }
