import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  COLOR,
  Input,
  Spinner,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useSanitizedBlur } from '../../../hooks/use-sanitized-blur'
import { useGetStoreQuery, useUpdateStoreMutation } from '../../../queries/stores'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import styles from './lead-url-config-form.module.css'

const schema = Yup.object()
  .shape({
    customLeadUrl: Yup.string().required(),
  })
  .defined()

type LeadConfigFormProps = {
  storeId: string
  setHasChangesMade: (value: boolean) => void
}

export const LeadUrlConfigForm: FC<LeadConfigFormProps> = ({ storeId, setHasChangesMade }) => {
  const [restoreDefault, setRestoreDefault] = useState<boolean>(false)
  const { toast } = useToaster()
  const { mutateAsync: updateStore, isLoading: isUpdateLoading } = useUpdateStoreMutation()
  const { data: store, isLoading: isQueryLoading } = useGetStoreQuery({
    storeId,
    version: 'latest',
  })
  const isLoading = isQueryLoading || isUpdateLoading
  const { hasPermission } = usePermissions()
  const shouldViewOnly = !hasPermission(Permission.StoreSettingsFullAccess)

  const {
    dirty,
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      customLeadUrl: store?.customLeadUrl || store?.defaultLeadUrl || store?.domain || '',
    },
    onSubmit: async ({ customLeadUrl }): Promise<void> => {
      if (!store) return

      try {
        await updateStore({
          storeId,
          store: {
            customLeadUrl,
          },
          version: 'latest',
        })
        const message = restoreDefault
          ? 'Lead URL Format restored to default'
          : 'Lead URL Format updated'

        toast({
          message,
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.short,
        })
        setRestoreDefault(false)
      } catch (err) {
        const message = restoreDefault
          ? 'Lead URL Format couldn’t be restored to default. Please try again later'
          : 'Lead URL Format couldn’t be updated. Please try again later'

        toast({
          message,
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        setRestoreDefault(false)
      }
      setHasChangesMade(false)
    },
  })

  useEffect(() => {
    return () => {
      setHasChangesMade(false)
    }
  }, [setHasChangesMade])

  useEffect(() => {
    setHasChangesMade(Boolean(Object.values(touched).find(Boolean) && dirty))
  }, [touched, dirty, setHasChangesMade])

  const hasNotChanged = !dirty

  const areButtonsDisabled =
    isLoading || hasNotChanged || Boolean(Object.values(errors).find(Boolean))

  const handleRestoreDefault = (): void => {
    setFieldValue('customLeadUrl', store?.defaultLeadUrl || store?.domain)
    setRestoreDefault(true)
  }

  const onReset = (): void => {
    handleReset(null)
    setHasChangesMade(false)
    setRestoreDefault(false)
  }

  const { onHandleBlur } = useSanitizedBlur(handleBlur, setFieldValue)

  if (isLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!store) return null

  const isRestoreDefaultDisabled = store.defaultLeadUrl
    ? store.customLeadUrl === store.defaultLeadUrl
    : store.customLeadUrl === store.domain

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <div className={styles['input-wrapper']}>
        <Input
          label="Lead Config Url"
          id="customLeadUrl"
          onChange={handleChange}
          onBlur={onHandleBlur}
          value={values.customLeadUrl}
          isError={Boolean(errors.customLeadUrl && touched.customLeadUrl)}
          errorFeedback={errors.customLeadUrl}
          isDisabled={isLoading || shouldViewOnly}
        />
      </div>
      {!shouldViewOnly && (
        <div className={styles['button-group']}>
          <div className={styles['button-wrapper']}>
            <Button
              type="submit"
              color="blue"
              isProcessing={isLoading || isSubmitting}
              isDisabled={areButtonsDisabled || hasNotChanged}
              text="Save"
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              type="button"
              emphasis="medium"
              text="Restore Default"
              isDisabled={isRestoreDefaultDisabled}
              onClick={handleRestoreDefault}
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              type="button"
              color="blue"
              emphasis="medium"
              isDisabled={areButtonsDisabled || hasNotChanged}
              onClick={onReset}
              text="Cancel"
            />
          </div>
        </div>
      )}
    </form>
  )
}
