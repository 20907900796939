export const FONT_FAMILIES = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Bookman', value: 'Bookman' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Copperplate Gothic', value: 'Copperplate Gothic' },
  { label: 'Courier', value: 'Courier' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Garamond', value: 'Garamond' },
  { label: 'Helvetica', value: 'Helvetica' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Nunito Sans', value: 'Nunito Sans' },
  { label: 'Palatino Font', value: 'Palatino Font' },
  { label: 'Sans-serif', value: 'Sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  { label: 'Trebuchet MS', value: 'Trebuchet MS' },
  { label: 'Verdana', value: 'Verdana' },
]
