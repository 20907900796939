import { faker } from '@faker-js/faker/locale/en'
import type {
  Order,
  OrderProduct,
  LineItem,
  LineItemBase,
  LineItemType,
  LineItemContract,
  LineItemLead,
  LineItemNonWarrantable,
  LineItemShipments,
  ShippingAddress,
} from '@helloextend/extend-api-client'
import { OrdersSearchResponse, OrderSearchItem } from '@helloextend/extend-api-rtk-query'

export const generateOrderSearchResult = (): OrdersSearchResponse => {
  return {
    orders: [generateOrderSearchItem(), generateOrderSearchItem()],
  }
}

export function generateOrderSearchItem(
  overrides: Partial<OrderSearchItem> = {},
  itemCount = 2,
): OrderSearchItem {
  const lineItems = overrides.lineItems?.length
    ? overrides.lineItems
    : Array(itemCount)
        .fill(0)
        .map(() => faker.datatype.uuid())
  return {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
    currency: 'USD',
    customer: {
      name: faker.name.firstName(),
      email: faker.internet.exampleEmail(),
      billingAddress: {
        address1: faker.address.streetAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        province: faker.address.state(),
      },
      ...overrides.customer,
    },
    poNumber: faker.datatype.number(1000).toString(),
    shippingCostTotal: faker.datatype.number(100000),
    status: 'closed',
    storeId: faker.datatype.uuid(),
    storeName: faker.commerce.department(),
    taxCostTotal: faker.datatype.number(10000),
    total: faker.datatype.number(10000),
    transactionId: faker.datatype.number(10000).toString(),
    ...overrides,
    lineItems,
  }
}

export function generateOrder(
  overrides: Partial<Order> = {},
  lineItemType: LineItemType = 'lead',
  itemCount = 2,
): Order {
  const lineItems = overrides.lineItems?.length
    ? (overrides.lineItems as LineItem[])
    : generateLineItems(itemCount, lineItemType)
  return {
    id: faker.datatype.uuid(),
    accountId: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
    currency: 'USD',
    customer: {
      name: faker.name.firstName(),
      email: faker.internet.exampleEmail(),
      billingAddress: {
        address1: faker.address.streetAddress(),
        city: faker.address.city(),
        country: faker.address.country(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        province: faker.address.state(),
      },
      ...overrides.customer,
    },
    metadata: {
      analytics: {
        customerStatus: 'existing',
        loyaltyStatus: 'elite',
        marketingOptIn: 'true',
        paymentInstrument: 'visa',
        paymentMethod: 'credit',
      },
      saleOrigin: {
        agentId: '1234',
        channel: 'web',
        integratorId: '9876',
        platform: 'shopify',
      },
    },
    poNumber: faker.datatype.number(1000).toString(),
    productTotalCost: faker.datatype.number(1000000),
    shippingCostTotal: faker.datatype.number(100000),
    status: 'closed',
    storeId: faker.datatype.uuid(),
    storeName: faker.commerce.department(),
    taxCostTotal: faker.datatype.number(10000),
    total: faker.datatype.number(10000),
    transactionId: faker.datatype.number(10000).toString(),
    ...overrides,
    lineItems,
    saleOrigin: {
      agentId: '1234',
      channel: 'web',
      integratorId: '9876',
      platform: 'shopify',
      storeAddress: {
        address1: faker.address.streetAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        province: faker.address.state(),
      },
    },
    analytics: {
      customerStatus: 'existing',
      loyaltyStatus: 'elite',
      marketingOptIn: 'true',
      paymentInstrument: 'visa',
      paymentMethod: 'credit',
    },
  }
}

function generateLineItemBase<T extends LineItemType>(type: T): LineItemBase<T> {
  return {
    type,
    id: faker.datatype.uuid(),
    accountId: faker.datatype.uuid(),
    storeId: faker.datatype.uuid(),
    orderId: faker.datatype.uuid(),
    transactionId: faker.datatype.uuid(),
    lineItemTransactionId: faker.datatype.uuid(),
    status: 'fulfilled',
    fulfilledAt: faker.date.past().getTime(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
  }
}

export function generateLineItemProduct(overrides: Partial<OrderProduct> = {}): OrderProduct {
  return {
    id: faker.hacker.noun(),
    name: faker.hacker.noun(),
    listPrice: faker.datatype.number({ min: 10000, max: 100000 }),
    purchasePrice: faker.datatype.number({ min: 10000, max: 100000 }),
    quantity: 1,
    purchaseDate: faker.date.past().getTime(),
    ...overrides,
  }
}

export function generateLineItemContract(overrides: Partial<LineItemContract> = {}): LineItemContract {
  return {
    plan: {
      id: '10001-auto-replace-1y',
      purchasePrice: faker.datatype.number({ min: 10000, max: 100000 }),
      listPrice: faker.datatype.number({ min: 10000, max: 100000 }),
    },
    product: generateLineItemProduct(overrides.product),
    contractId: faker.datatype.uuid(),
    ...generateLineItemBase('contract'),
    ...overrides,
  }
}

export function generateLineItemLead(overrides: Partial<LineItemLead> = {}): LineItemLead {
  return {
    product: generateLineItemProduct(overrides.product),
    leadToken: faker.datatype.uuid(),
    ...generateLineItemBase('lead'),
    ...overrides,
  }
}

export function generateLineItemNonWarrantable(
  overrides: Partial<LineItemNonWarrantable> = {},
): LineItemNonWarrantable {
  return {
    product: generateLineItemProduct(overrides.product),
    ...generateLineItemBase('non_warrantable'),
    ...overrides,
  }
}

function generateShippingAddress(): ShippingAddress {
  return {
    address1: faker.address.streetAddress(),
    city: faker.address.city(),
    countryCode: faker.address.countryCode(),
    postalCode: faker.address.zipCode(),
  }
}

export function generateLineItemShipment(overrides: Partial<LineItemShipments> = {}): LineItemShipments {
  return {
    ...generateLineItemBase('shipments'),
    status: 'fulfilled',
    quoteId: faker.datatype.uuid(),
    contractId: faker.datatype.uuid(),
    lineItemIds: [faker.datatype.uuid()],
    plan: {
      id: 'shipping-protection-plan-1',
      purchasePrice: faker.datatype.number({ min: 10000, max: 100000 }),
      listPrice: faker.datatype.number({ min: 10000, max: 100000 }),
    },
    shipmentInfo: [
      {
        productIds: [faker.hacker.noun()],
        shipmentDate: faker.date.past().getTime(),
        shipmentId: faker.datatype.uuid(),
        shippingProvider: 'fedex',
        trackingId: faker.datatype.uuid(),
        trackingUrl: 'test.trackingurl.com',
        destination: generateShippingAddress(),
        source: generateShippingAddress(),
      },
    ],
    ...overrides,
  }
}

export function generateLineItem(
  lineItemType: LineItemType,
  product: Partial<OrderProduct> = {},
): LineItem {
  switch (lineItemType) {
    case 'contract': {
      return generateLineItemContract(product)
    }
    case 'lead': {
      return generateLineItemLead(product)
    }
    case 'non_warrantable': {
      return generateLineItemNonWarrantable(product)
    }
    default: {
      return generateLineItemLead(product)
    }
  }
}

export function generateLineItems(
  itemCount: number,
  lineItemType: LineItemType = 'contract',
): LineItem[] {
  const lineItems: LineItem[] = []

  for (let i = 0; i < itemCount; i += 1) {
    lineItems.push(generateLineItem(lineItemType))
  }
  const lineItemIds = lineItems.map((lineItem) => lineItem.id)
  const shipmentLineItem = generateLineItemShipment({ lineItemIds })
  lineItems.push(shipmentLineItem)
  return lineItems
}
