// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UdWWNeUdm-WkMOg2OZYZ6 {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n._3UVfdtvJyzRe34zVVgipM2 {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n\n._3UVfdtvJyzRe34zVVgipM2 div {\n    margin: 0;\n}\n\n._3km2FHrwSnYvmEiMrHqvtB {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "UdWWNeUdm-WkMOg2OZYZ6",
	"grid": "_3UVfdtvJyzRe34zVVgipM2",
	"date-container": "_3km2FHrwSnYvmEiMrHqvtB"
};
module.exports = exports;
