import { useEffect, useState } from 'react'

export const useTableauAnalyticsScript = (): boolean => {
  const [isScriptLoaded, setScriptLoaded] = useState(false)

  const handleTableauLoaded = (): void => {
    setScriptLoaded(true)
  }
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'tableau-script'
    script.type = 'module'
    script.src =
      'https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'
    document.body.appendChild(script)
    script.addEventListener('load', handleTableauLoaded)
    return () => {
      script.removeEventListener('load', handleTableauLoaded)
    }
  }, [])

  return isScriptLoaded
}
