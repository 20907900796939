import { useMemo } from 'react'
import { capitalize } from 'lodash/fp'
import { useAtomValue } from 'jotai/react'
import type { ProductShipmentTableItem } from '../types/contracts'
import type { DataPropertyDetails } from '../components/data-property-grid'
import { contractDetailsAtom } from '../atoms/contract-details'

type ShipmentInfo = {
  label: string
  trackingId: string
  sectionValues: DataPropertyDetails[]
  products: ProductShipmentTableItem[]
}

const useProductsShipment = (): {
  shipmentInfos: ShipmentInfo[]
  unshipped: ProductShipmentTableItem[]
} => {
  const contract = useAtomValue(contractDetailsAtom)
  return useMemo(() => {
    const shippedProducts: string[] = []
    const shipmentInfos =
      contract.trackingInfo?.map((trackingInfo, index) => {
        return {
          label: `Shipment ${index + 1}`,
          trackingId: trackingInfo.trackingId,
          sectionValues: [
            {
              key: 'shippingProvider',
              value: trackingInfo.shippingProvider,
              label: 'Shipping Provider',
              dataCy: 'shippingProvider',
            },
            {
              key: 'trackingId',
              value: trackingInfo.trackingId,
              label: 'Tracking ID',
              dataCy: 'trackingId',
            },
            {
              key: 'shippingStatus',
              value: capitalize(trackingInfo.shippingState),
              label: 'Shipping Status',
              dataCy: 'shippingStatus',
              width: '50%',
            },
            {
              key: 'shipmentId',
              value: trackingInfo.shipmentId,
              label: 'Shipment ID',
              dataCy: 'shipmentId',
              width: '100%',
            },
          ],
          products: trackingInfo.productsShipped?.reduce(
            (acc: ProductShipmentTableItem[], refId: string) => {
              shippedProducts.push(refId)
              const foundProduct = contract.productsList?.find((p) => p.referenceId === refId)
              return foundProduct
                ? [...acc, { ...foundProduct, currencyCode: contract.currency }]
                : acc
            },
            [],
          ),
        }
      }) || []

    const unshipped =
      contract.productsList?.filter((product) => !shippedProducts.includes(product.referenceId)) ||
      []
    return { shipmentInfos, unshipped }
  }, [contract])
}

export { useProductsShipment }
