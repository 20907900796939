import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import { currency } from '@extend/client-helpers'
import { Link } from 'react-router-dom'
import { CopyToClipboardButton } from '@helloextend/merchants-ui'
import { getContractCoverageIncludesText } from '../../../utils/contract-mappers'
import type { MerchantProduct } from '../../../types/merchant-product'
import { SubHeader } from '../../../components/sub-header'
import { getProductVariantsUrl } from '../../../utils/route-helper'
import { useGetCountsQuery } from '../../../queries/products'
import { useGetOffersQuery } from '../../../queries/offers'
import styles from './plans-rdb.module.css'

type PlansRdbProps = {
  product: MerchantProduct
}

export const PlansRdb: FC<PlansRdbProps> = ({ product }) => {
  const { storeId, referenceId, parentReferenceId } = product

  const { data: variantCounts } = useGetCountsQuery({
    storeId,
    referenceId,
  })

  const childCount = variantCounts?.childCount || 0
  const isVariant = !!parentReferenceId
  const isBase = childCount > 0
  const isEnabled = product.enabled || false
  const allowPlansToShow = !isBase && isEnabled

  const { data: plans, isFetching } = useGetOffersQuery({
    storeId,
    productId: referenceId,
    enabled: allowPlansToShow,
  })

  const hasPlans = plans && plans.length > 0

  const showPlans = allowPlansToShow && hasPlans

  if (isFetching) {
    return (
      <div className={styles.details}>
        <SubHeader labelText="Plans" />
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  return (
    <div className={styles.details}>
      <SubHeader labelText="Plans" />
      {showPlans ? (
        <>
          <div className={styles.plans}>Plans that are currently live in your webstore</div>
          <div className={styles.row} data-cy="plans">
            <div className="flex flex-col" style={{ width: '30%' }}>
              <span className={styles.label}> PLAN NAME </span>
            </div>
            <div className="flex flex-col" style={{ width: '20%' }}>
              <span className={styles.label}> PLAN TYPE </span>
            </div>
            <div className="flex flex-col" style={{ width: '20%' }}>
              <span className={styles.label}> PLAN TERM </span>
            </div>
            <div className="flex flex-col" style={{ width: '20%' }}>
              <span className={styles.label}> PLAN ID </span>
            </div>
            <div className="flex flex-col" style={{ width: '15%' }}>
              <span className={styles.label}> PLAN PRICE </span>
            </div>
          </div>
          {plans?.map((plan) => (
            <div className={styles.row} key={plan.id}>
              <div className="flex flex-col" style={{ width: '30%' }}>
                <span className={styles.plan}>{plan.title}</span>
              </div>
              <div className="flex flex-col" style={{ width: '20%' }}>
                <span className={styles.plan}>{getContractCoverageIncludesText(plan)}</span>
              </div>
              <div className="flex flex-col" style={{ width: '20%' }}>
                <span className={styles.plan}>
                  {plan.contract.termLength?.toString().concat(' months')}
                </span>
              </div>
              <div className="flex flex-col" style={{ width: '20%' }}>
                <span className={styles.plan}>
                  {plan.id}
                  <CopyToClipboardButton label="Plan ID" value={plan.id} data-cy="plan-id" />
                </span>
              </div>
              <div className="flex flex-col" style={{ width: '15%' }}>
                <span className={styles.plan}>
                  {currency.formatWithoutCurrencySymbol(plan.price, product.price?.currencyCode || '')}
                  <CopyToClipboardButton
                    label="Plan Price"
                    value={currency.formatWithoutCurrencySymbol(
                      plan.price,
                      product.price?.currencyCode || '',
                    )}
                    data-cy="plan-price"
                  />
                </span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="flex flex-col" data-cy="noplans">
          {isBase && !isVariant ? (
            <span>
              Plans are mapped directly to the variants of this product.{' '}
              <Link className={styles.link} to={getProductVariantsUrl(referenceId)}>
                View list of product variants
              </Link>
            </span>
          ) : (
            <span>
              After you have enabled Display Offer for this product, the plans currently available
              to customers will appear here
            </span>
          )}
        </div>
      )}
    </div>
  )
}
