import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { DatePicker } from '@helloextend/merchants-ui'
import { Modal, TextArea, Label, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import type { ServiceType } from '@helloextend/extend-api-client'
import type { V2 } from '@extend-services/service-orders'
import { useFulfillRepairDepotServiceOrder } from '../../../queries/service-orders'
import styles from './resolve-service-order-product-repair-content.module.css'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'

type FormState = {
  repairCompleteDate: Date | null
  repairExplanation: string
}

type FormErrors = Partial<Record<keyof FormState, string>>

type Props = {
  serviceOrderId: string
  fulfillmentMethod: V2.Models.RepairFulfillmentMethod
  serviceType: ServiceType
}
export const ResolveServiceOrderProductRepairContent: FC<Props> = ({
  serviceOrderId,
  fulfillmentMethod,
}) => {
  const { toast } = useToaster()
  const { mutateAsync: fulfillServiceOrder } = useFulfillRepairDepotServiceOrder(fulfillmentMethod)

  const [formState, setFormState] = useState<FormState>({
    repairCompleteDate: null,
    repairExplanation: '',
  })
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState<FormErrors>({})
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)
  const handleSubmit = useCallback(async (): Promise<void> => {
    const newErrors = validateFormState(formState)
    setErrors(newErrors)

    if (Object.keys(newErrors).length) {
      return
    }

    try {
      await fulfillServiceOrder({
        serviceOrderId,
        repairCompleteDate: formState.repairCompleteDate as Date,
        repairExplanation: formState.repairExplanation,
      })
      toast({
        message: 'Service order resolved.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (err) {
      toast({
        message: 'Unable to resolve service order.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [fulfillServiceOrder, formState, setErrors, serviceOrderId, toast])

  const handleDateChange = (d: Date | null): void => {
    setFormState({ ...formState, repairCompleteDate: d })
    setHasChanges(true)
  }

  return (
    <Modal
      primaryButtonProps={{
        formId: 'repairModalForm',
        onClick: handleSubmit,
        isDisabled: !hasChanges,
        text: 'Confirm',
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: () => setIsResolveServiceOrderModalVisible(false),
      }}
      onDismissRequest={() => setIsResolveServiceOrderModalVisible(false)}
      heading="Resolve Service Order - Repair"
      size="md"
    >
      <form className={styles.form} onChange={() => setHasChanges(true)}>
        <section className={styles.section}>
          <div style={{ flex: 1, width: '100%' }}>
            <Label htmlFor="repair-completed-date">Repair Complete Date</Label>
            <DatePicker
              id="repair-completed-date"
              errorFeedback={errors?.repairCompleteDate}
              onChange={handleDateChange}
              selected={formState.repairCompleteDate}
              placeHolder=""
            />
          </div>
        </section>
        <section className={styles.section}>
          <TextArea
            id="repair-explanation"
            label="Repair Explanation (optional)"
            maxLength={3000}
            value={formState.repairExplanation}
            onChange={(e) => setFormState({ ...formState, repairExplanation: e.target.value })}
          />
        </section>
      </form>
    </Modal>
  )
}

export function validateFormState({ repairCompleteDate }: FormState): FormErrors {
  const newErrors: FormErrors = {}

  if (!repairCompleteDate) {
    newErrors.repairCompleteDate = 'Repair complete date is required'
  }

  return newErrors
}
