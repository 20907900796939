// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvfsA8fxu9mmC7HO7ge1R {\n    font-size: 20px;\n    line-height: 28px;\n}", ""]);
// Exports
exports.locals = {
	"subheading": "kvfsA8fxu9mmC7HO7ge1R"
};
module.exports = exports;
