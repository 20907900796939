// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3fUfx5JVF5w9v_NKTMeoaa {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 40px;\n    align-items: center;\n    background-color: var(--blue-100);\n    border: 1px solid var(--blue-300);\n    border-radius: 4px;\n    padding: 12px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n._3fUfx5JVF5w9v_NKTMeoaa label {\n    color: var(--black);\n}\n\n._3fUfx5JVF5w9v_NKTMeoaa div {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"expense-info-banner": "_3fUfx5JVF5w9v_NKTMeoaa"
};
module.exports = exports;
