import type { FC, SyntheticEvent } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, COLOR, Icon } from '@extend/zen'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { More } from '@extend/zen'
import { Menu } from '../menu'
import type { ContextMenuItem } from './types'
import { ListItemButton } from '../list'

type Alignment = 'left' | 'right' | 'center'

interface ContextMenuProps {
  position?: Alignment
  items: ContextMenuItem[]
}

/**
 * @deprecated Use Zen Menu component instead: `import { Menu } from '@extend/zen'`
 */
const ContextMenu: FC<ContextMenuProps> = ({ items, position = 'left' }) => {
  const [isMenuOpen, { toggle, off }] = useToggle()

  const handleInternalClick = useCallback(
    (e: SyntheticEvent, onClick: ContextMenuItem['onClick']) => {
      e.stopPropagation()
      off()

      if (onClick) {
        onClick()
      }
    },
    [off],
  )

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const handleOnClick = (e: SyntheticEvent): void => {
    e.stopPropagation()
    toggle()
  }

  return (
    <FilterWrapper>
      <ButtonContainer ref={ref}>
        <Button
          emphasis="low"
          isToggled={isMenuOpen}
          data-cy="context-menu-trigger"
          onClick={handleOnClick}
          icon={More}
          color="neutral"
        />
        <MenuWrapper>
          <Menu isOpen={isMenuOpen} position={position} width={216}>
            {items.map(({ key, text, icon, onClick }) => (
              <ItemWrapper key={key}>
                <MenuButton
                  data-cy={key}
                  id={key}
                  hoverBackground={COLOR.NEUTRAL[100]}
                  onClick={(e) => handleInternalClick(e, onClick)}
                  className="menu-button"
                >
                  {icon && <Icon icon={{
                    d: icon.d,
                    name: icon.name,
                    stroke: icon.stroke
                  }} />}
                  {text}
                </MenuButton>
              </ItemWrapper>
            ))}
          </Menu>
        </MenuWrapper>
      </ButtonContainer>
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
}))

const MenuWrapper = styled.div({
  width: '100%',
  position: 'absolute',
  top: 38,
})

const MenuButton = styled(ListItemButton)({
  color: COLOR.BLACK,
  borderRadius: 0,
  fontSize: 16,
  lineHeight: '24px',
})

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
})

const ItemWrapper = styled.div({
  position: 'relative',
  '&:first-of-type > .menu-button': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  '&:last-of-type > .menu-button': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

export type { ContextMenuProps }
export { ContextMenu }
