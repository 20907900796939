import type { Claim } from '@helloextend/extend-api-client'
import type { InsuranceClaimRecord } from '@helloextend/extend-api-rtk-query'
import type { MerchantClaim } from '../types/claims'

/**
 * @mapUnhandledClaimsServiceItemsForClaimsTable
 * The fields failureType and occurredAt are expected by the front end in
 * apps/merchants/src/features/store/claims/claims-data-table.tsx. The claims
 * service was recently updated to return these values as FailureType and IncidentDate.
 * This utility is a Band-Aid fix temporarily until the claims service is updated to return
 * unified values.
 * */

export const mapUnhandledClaimsServiceItemsForClaimsTable = (
  items: InsuranceClaimRecord[],
): InsuranceClaimRecord[] => items.map(mapCommonClaimAttributes)

/**
 * A Claim has several attributes which changed names between API versions, but have the same meaning.
 * Because these values are not guaranteed to be present on all claims, we need to map them to a common
 * attribute name which the front end can rely on.
 *
 * @see https://github.com/helloextend/claims-management-service/blob/5a5f43e1e00b49eb55904afd2fb0a3a7d2ba54d2/src/lib/map-claims-array.ts#L57
 *
 * @param claim Claim
 * @returns Claim with preferred attributes mapped to older attributes which are commonly referenced.
 */
export const mapCommonClaimAttributes = <T extends Claim | MerchantClaim | InsuranceClaimRecord>(
  claim: T,
): T => {
  return {
    ...claim,
    incident: {
      ...claim.incident,
      description: claim.incident.FailureDescription || claim.incident.description,
      failureLocation: claim.incident.FailureLocation || claim.incident.failureLocation,
      failureCause: claim.incident.FailureCause || claim.incident.failureCause,
      failureType: claim.incident.FailureType || claim.incident.failureType,
      occurredAt: claim.incident.IncidentDate || claim.incident.occurredAt,
      productSection: claim.incident.ProductSection || claim.incident.productSection,
    },
  }
}
