import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@extend/zen'
import { ChevronLeft, ChevronRight } from '@extend/zen'
import type { PaginationDirection, PaginationType } from './types'

type BasicPaginationProps = {
  paginationType: PaginationType.BASIC
  count: number
  type: string
  currPage: number
  pageSize: number
  hasNext?: boolean
  hasPrev?: boolean
  handlePagination: (type: PaginationDirection) => void
}

const BasicPagination: FC<BasicPaginationProps> = ({
  count,
  pageSize = 25,
  type,
  currPage,
  hasNext,
  hasPrev,
  handlePagination,
}) => {
  const totalOnPage = count < pageSize ? count : pageSize

  const handlePrev = (): void => {
    if (hasPrev) {
      handlePagination('previous')
    }
  }

  const handleNext = (): void => {
    if (hasNext) {
      handlePagination('next')
    }
  }

  return (
    <Wrapper>
      {(count > 1 || currPage > 1) && (
        <>
          <Container>
            <span>
              Showing <DarkText>{totalOnPage}</DarkText> {type}
            </span>
          </Container>
          <Container>
            {hasPrev && (
              <Button
                icon={ChevronLeft}
                onClick={handlePrev}
                isDisabled={!hasPrev}
                color="neutral"
                emphasis="low"
                size="small"
                data-cy="prev"
              />
            )}
            {currPage >= 0 && (hasNext || hasPrev) && (
              <span>
                Page <DarkText>{currPage + 1}</DarkText>
              </span>
            )}
            {hasNext && (
              <Button
                icon={ChevronRight}
                onClick={handleNext}
                isDisabled={!hasNext}
                color="neutral"
                emphasis="low"
                size="small"
                data-cy="next"
              />
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const Container = styled.p({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  fontSize: 14,
  color: COLOR.NEUTRAL[600],
})

const DarkText = styled.span({
  color: COLOR.NEUTRAL[1000],
})
export type { BasicPaginationProps }
export { BasicPagination }
