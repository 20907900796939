import type { FC } from 'react'
import React from 'react'
import { Header, HeaderLogo, HeaderMenuItem, Stack } from '@extend/zen'
import { GridAddFilled, UserManagement } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { usePermissions } from '../hooks/use-permissions'
import { Permission } from '../lib/permissions'
import { Support } from '../features/support/support'
import { UserProfileDropdown } from './user-profile-dropdown'
import { LDFlag } from '../constants/ld-flags'
import styles from './dashboard-header.module.css'

const DashboardHeader: FC = () => {
  const history = useHistory()
  const { hasPermission } = usePermissions()
  const { [LDFlag.MpEER4Integrations]: FF_R4_INTEGRATIONS } = useFlags()

  const shouldShowManageUsers =
    hasPermission(Permission.ManageUsers) || hasPermission(Permission.ManageUsersV3)

  const handleSettingsClick = (): void => {
    history.push('/account/users')
  }

  const handleIntegrationsClick = (): void => {
    history.push('/account/integrations')
  }

  const handleLogoClick = (): void => {
    history.push('/')
  }

  return (
    <Header
      start={
        <HeaderMenuItem onClick={handleLogoClick} data-cy="dashboard-header-menu-item-logo">
          <HeaderLogo data-cy="dashboard-header-merchants-logo" />
        </HeaderMenuItem>
      }
      end={
        <Stack isRow spacing={1}>
          {shouldShowManageUsers && (
            <HeaderMenuItem
              data-cy="user-management-button"
              icon={UserManagement}
              onClick={handleSettingsClick}
              tooltip="User Management"
            >
              <p className={styles.label}>Users</p>
            </HeaderMenuItem>
          )}
          {FF_R4_INTEGRATIONS && hasPermission(Permission.IntegrationsView) && (
            <HeaderMenuItem
              icon={GridAddFilled}
              onClick={handleIntegrationsClick}
              tooltip="Integrations"
            >
              <p className={styles.label}>Integrations</p>
            </HeaderMenuItem>
          )}
          <Support />
          <UserProfileDropdown />
        </Stack>
      }
    />
  )
}

export { DashboardHeader }
