// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2tkguG4w5PPm_J-et4CZKD {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "_2tkguG4w5PPm_J-et4CZKD"
};
module.exports = exports;
