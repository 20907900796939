import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { useDeleteServiceOrderExpenseMutation } from '../../../queries/service-orders'

export type DeleteExpenseModalProps = {
  isVisible: boolean
  closeModal: () => void
  serviceOrderId: string
  expenseId: string
}

export const DeleteExpenseModal: FC<DeleteExpenseModalProps> = ({
  closeModal,
  isVisible = false,
  serviceOrderId,
  expenseId,
}) => {
  const { mutateAsync: deleteServiceOrderExpenseMutation, isLoading } =
    useDeleteServiceOrderExpenseMutation()
  const { toast } = useToaster()

  const handleOnClickDelete = async (): Promise<void> => {
    try {
      await deleteServiceOrderExpenseMutation({ serviceOrderId, expenseId })
      toast({
        message: 'This expense was successfully deleted',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      closeModal()
    } catch {
      toast({
        message: 'There was an error deleting this expense. Please try again',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Deleting an Expense"
        secondaryButtonProps={{ text: 'Cancel', onClick: closeModal }}
        primaryButtonProps={{
          text: 'Delete',
          color: 'red',
          onClick: handleOnClickDelete,
          isProcessing: isLoading,
        }}
      >
        <p>Are you sure you want to delete this expense?</p>
      </Modal>
    </ModalController>
  )
}
