// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._15CKfnXsEEjzfnw41qakIa {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n._2RBYhuiZF1gStOneWWpNaG {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_15CKfnXsEEjzfnw41qakIa",
	"buttons": "_2RBYhuiZF1gStOneWWpNaG"
};
module.exports = exports;
