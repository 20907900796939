// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1sgn-F7Ty8-CB1C8WTkG0n {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n._3Cl1VOWx0BN1Y--wTSSTnG {\n    flex: 1 0 100%;\n}\n\n._1cp5m0zm2cds8xJm7r5cG9 {\n    width: 140px;\n    height: 140px;\n}\n\n._1H-xOQuSE0fqc_Gey7JLL7 {\n    height: 100%;\n    object-fit: cover;\n    object-position: center center;\n    width: 100%;\n}\n\n._150SoqVvtx9f3zhcqOsTWh {\n    flex-grow: 1;\n}\n\n._3iZu66rHQT3MzE5RBOVh3 {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n._3iZu66rHQT3MzE5RBOVh3 > * + * {\n    margin-top: 16px;\n}\n\n._19zll4SxmEPCs32JS5Yl9z {\n    display: flex;\n    flex-flow: column wrap;\n    margin-top: 32px;\n    align-items: baseline;\n}\n\n._19zll4SxmEPCs32JS5Yl9z > * + * {\n    margin: 16px 0 0 0;\n}\n\n@media (min-width: 1025px) {\n    ._3Cl1VOWx0BN1Y--wTSSTnG {\n        flex: 0 1;\n        margin-right: 46px;\n    }\n\n    ._3iZu66rHQT3MzE5RBOVh3 {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n\n    ._19zll4SxmEPCs32JS5Yl9z {\n        flex-flow: row wrap;\n    }\n\n    ._19zll4SxmEPCs32JS5Yl9z > * + * {\n        margin: 0 0 0 16px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "_1sgn-F7Ty8-CB1C8WTkG0n",
	"figure": "_3Cl1VOWx0BN1Y--wTSSTnG",
	"image-wrapper": "_1cp5m0zm2cds8xJm7r5cG9",
	"image": "_1H-xOQuSE0fqc_Gey7JLL7",
	"details": "_150SoqVvtx9f3zhcqOsTWh",
	"details-column": "_3iZu66rHQT3MzE5RBOVh3",
	"buttons": "_19zll4SxmEPCs32JS5Yl9z"
};
module.exports = exports;
