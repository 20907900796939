import type { FC, FormEvent, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import { InputWithSelect } from '@extend/zen'
import { useHistory } from 'react-router'
import { querystring } from '@extend/client-helpers'
import styles from './claims-managment.module.css'

export type ContractSearchKey =
  | 'customerEmail'
  | 'contractId'
  | 'productSerialNumber'
  | 'transactionId'

export const ClaimsManagement: FC = () => {
  const history = useHistory()
  const { key, value } = querystring.parse(window.location.search)
  const qsSearchKey = String(key || '') as ContractSearchKey
  const qsSearchValue = String(value || '')

  const [searchKey, setSearchKey] = useState<ContractSearchKey>(qsSearchKey || 'customerEmail')
  const [searchValue, setSearchValue] = useState<string>(qsSearchValue || '')

  const isFormDisabled = !searchValue

  const handleChangeSelect = (event: SyntheticEvent<HTMLSelectElement>): void => {
    const selectedKeyValue = event.currentTarget.value as ContractSearchKey
    setSearchKey(selectedKeyValue)
  }

  const handleChangeInput = (event: SyntheticEvent<HTMLInputElement>): void => {
    setSearchValue(event.currentTarget.value)
  }

  // redirects the user to the claims management search results page
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    if (isFormDisabled) return
    history.push(
      `/store/claims/new/search?key=${searchKey}&value=${encodeURIComponent(searchValue.trim())}`,
    )
  }

  return (
    <div className={styles.page}>
      <h1 className={styles.header}>Find an Extend customer’s contract to file a claim</h1>
      <p className={styles.description}>
        To get started on a claim, find the customer’s protection plan by using one of the options
        below.
      </p>
      <form className={styles.search} onSubmit={handleSubmit}>
        <InputWithSelect
          id="contractSearchValueAndKey"
          value={searchValue}
          selectValue={searchKey}
          onChange={handleChangeInput}
          onSelectChange={handleChangeSelect}
          placeholder="Search by"
          actionButtonProps={{
            isDisabled: isFormDisabled,
            'data-cy': 'searchSubmit',
            text: 'Search',
            type: 'submit',
          }}
        >
          <option value="customerEmail">Customer Email</option>
          <option value="contractId">Contract ID</option>
          <option value="productSerialNumber">Product Serial #</option>
          <option value="transactionId">Transaction ID</option>
        </InputWithSelect>
      </form>
    </div>
  )
}
