import type { Contract, OfferPlan } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'

/**
 * Translates the GET contract response body of contract.planDetails.coverage_type into readable text
 * @param planCoverageType
 */
function getCoverageTypeText(planCoverageType: string): string {
  return planCoverageType === 'adh' ? 'Accidental' : 'Base'
}

/**
 * Uses the contract.product.purchasePrice as the "coverage amount"
 * @param contractProduct
 */
export function setContractCoverageAmount(contractProduct: Contract['product']): number {
  return contractProduct.purchasePrice
}

/**
 * Translates months to <X>Year(s), <Y>Month(s)
 * @param months
 */
export function getContractTermLengthDisplayText(termLength: number): string {
  const YEARS_IN_MONTHS = 12
  const years = Math.floor(termLength / YEARS_IN_MONTHS)
  const yearsToText = years ? `${years} Year${years > 1 ? 's' : ''}` : ''
  const months = termLength % YEARS_IN_MONTHS
  const monthsToText = months ? `${months} Month${months > 1 ? 's' : ''}` : ''

  return `${yearsToText} ${monthsToText}`.trim()
}

/**
 * Summarizes the plans information to a readable coverage type
 * i.e. Base Jewelry - 3 Years
 * @param plan
 */
export function getContractPlanCoverageType({
  coverageIncludes,
  pcmiSubcategory,
  termLength,
}: {
  coverageIncludes: string
  pcmiSubcategory: string
  termLength: number
}): string {
  return `${getCoverageTypeText(
    coverageIncludes,
  )} ${pcmiSubcategory} - ${getContractTermLengthDisplayText(termLength)}`
}

/**
 * Translates the plan service type values to readable text
 * @param serviceType
 */
export function getContractServiceType(serviceType: string): string {
  switch (serviceType) {
    case 'replace':
      return 'Replacement'
    case 'repair_depot':
    case 'repair_home':
    case 'repair_irobot':
    case 'repair_onsite':
    default:
      return 'Repair'
  }
}

export function getContractCoverageIncludesText(plan: OfferPlan): string {
  switch (plan.contract.coverageIncludes) {
    case 'adh':
      return 'Accidents'
    case 'roadhazard':
      return 'Road Hazards'
    case 'base':
      return 'Base'
    default:
      return 'Mechanical/Electrical Defects'
  }
}

type MerchantPortalContractTypes = Exclude<ContractType, ContractType.SUBSCRIPTION>

const contractTypes: Record<MerchantPortalContractTypes, string> = {
  [ContractType.PCRS]: 'Product',
  [ContractType.SHIPPING_PROTECTION]: 'Shipping',
  [ContractType.CATEGORY]: 'Category',
  [ContractType.PRODUCT_PROTECTION_BUNDLE]: 'Bundle',
}

export const getContractType = (type: ContractType): string => {
  return (
    (contractTypes[type as keyof typeof contractTypes] as ContractType) || ('Unknown' as string)
  )
}
