import type { FC, ReactElement } from 'react'
import React, { useMemo } from 'react'
import { SidebarMenuLink } from '@extend/zen'
import type { NavIconProps } from '@extend/zen'
import { Products } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAtomValue } from 'jotai/react'
import type { Permission } from '../lib/permissions'
import { usePermissions } from '../hooks/use-permissions'
import { LDFlag } from '../constants/ld-flags'
import { getActiveStoreAtom } from '../atoms/stores'

interface DashboardSidebarMenuItemProp {
  text: string
  icon: React.ElementType<NavIconProps>
  to: string
  permission: Permission
}

interface DashboardMenuItemsProps {
  links: DashboardSidebarMenuItemProp[]
}

const DashboardSidebarMenuItems: FC<DashboardMenuItemsProps> = ({ links }) => {
  const { hasPermission } = usePermissions()
  const { [LDFlag.EnterpriseInitiative]: FF_ENTERPRISE_INITIATIVE } = useFlags()
  const activeStore = useAtomValue(getActiveStoreAtom)

  const filteredLinks = useMemo(() => {
    const isStoreActiveApproved = activeStore?.approved || activeStore?.shippingProtection?.approved
    if (FF_ENTERPRISE_INITIATIVE && activeStore && !isStoreActiveApproved) {
      return [
        <SidebarMenuLink
          to="/store/account/welcome"
          key={`nav-link-${'/store/account/welcome'}`}
          icon={Products}
          data-cy="navigation-tab"
        >
          Welcome
        </SidebarMenuLink>,
      ]
    }
    return links.reduce<ReactElement[]>((acc, { permission, icon, text, to }) => {
      if (!permission || hasPermission(permission)) {
        return [
          ...acc,
          <SidebarMenuLink to={to} key={`nav-link-${to}`} icon={icon} data-cy="navigation-tab">
            {text}
          </SidebarMenuLink>,
        ]
      }
      return acc
    }, [])
  }, [links, hasPermission, activeStore, FF_ENTERPRISE_INITIATIVE])

  return <>{filteredLinks}</>
}

export type { DashboardSidebarMenuItemProp }
export { DashboardSidebarMenuItems }
