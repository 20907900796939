import { FC } from 'react'
import React from 'react'
import { Box, DataProperty, Divider } from '@extend/zen'
import type { Integration, ShopifyIntegration } from '../../queries/integrations'
import styles from './integration-details.module.css'
import { ActiveBadge } from '../../components/active-badge'
import { isMagento, isShopify } from '../../utils/integration-helpers'

const IntegrationSettingsSchema: Array<{
  key: keyof Integration
  label: string
}> = [
  { key: 'autoRefunds', label: 'Auto Refunds' },
  { key: 'createLeads', label: 'Auto Lead Creation' },
  { key: 'claimsFulfillment', label: 'Claims Fulfillment' },
  { key: 'productsUpdate', label: 'Auto Sync on product update' },
  { key: 'productsCreate', label: 'Auto Sync on new product creation' },
  { key: 'productsDelete', label: 'Auto Sync on product deletion' },
]

const ShopifySettingsSchema: Array<{
  key: keyof ShopifyIntegration
  label: string
}> = [
  { key: 'barcodes', label: 'Auto plan variant barcodes' },
  { key: 'autoFulfillPlans', label: 'Auto plan fulfill' },
]

export const PlatformIntegrationDetails: FC<{ integration: Integration }> = ({ integration }) => {
  return (
    <div className={styles.wrapper}>
      <Box padding={5}>
        <div className={styles.container}>
          <h2>Basic Info</h2>
          <h3>Platform</h3>
          <div className={styles.grid}>
            <DataProperty label="Platform Store ID" value={integration.platformStoreId} />
            <DataProperty label="Platform" value={integration.platform} />
          </div>

          <h3>Account</h3>
          <div className={styles.grid}>
            <DataProperty label="Extend Org ID" value={integration.organizationId} />
            <DataProperty label="Extend Account ID" value={integration.accountId} />
            <DataProperty label="Extend Store ID" value={integration.storeId} />
          </div>
        </div>
      </Box>
      <Box padding={5}>
        <div className={styles.container}>
          <h2>{integration.platform} Integration</h2>
          <div className={styles.grid}>
            {isShopify(integration) && (
              <DataProperty
                label="Offer Sync Type"
                value={offerSyncTypeMap[integration.offerSyncType || 'off']}
              />
            )}
            {integration.contractEvent && (
              <DataProperty
                label="Contract Creation"
                value={`Create contract when order is ${
                  contractEventMap[integration.contractEvent]
                }`}
              />
            )}
            {isMagento(integration) && integration.productPriceField && (
              <DataProperty label="Product Price Field" value={integration.productPriceField} />
            )}
          </div>
          <Divider />
          <h3>Integration Settings</h3>
          <ul>
            {IntegrationSettingsSchema.map(({ key, label }) => {
              const active = Boolean(integration[key])
              return (
                <li key={key} data-cy={`${key}-${active}`}>
                  <ActiveBadge active={active} />
                  {label}
                </li>
              )
            })}
            {isShopify(integration) &&
              ShopifySettingsSchema.map(({ key, label }) => {
                const active = Boolean(integration[key])
                return (
                  <li key={key} data-cy={`${key}-${active}`}>
                    <ActiveBadge active={active} />
                    {label}
                  </li>
                )
              })}
          </ul>
        </div>
      </Box>
    </div>
  )
}

const contractEventMap = {
  orderCreate: 'created',
  orderFulfill: 'fulfilled',
}

const offerSyncTypeMap = {
  off: 'No offer sync',
  directMap: 'Direct map sync',
  pricePoints: 'Price points sync',
}
