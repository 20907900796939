import type { ClaimsSearchResponseV2 } from '@helloextend/extend-api-rtk-query'
import { useListInsuranceClaimsQuery } from '@helloextend/extend-api-rtk-query'
import type { ClaimSelectStatus, ClaimsSearchResponse } from '@helloextend/extend-api-client'
import { mapUnhandledClaimsServiceItemsForClaimsTable } from '../utils/map-unhandled-claims-service-items-for-claims-table'

interface QueryParams {
  sellerId?: string
  searchVersion?: string
  containsContractId: string
  matchesClaimStatus?: ClaimSelectStatus[]
}

const useClaimsSearch = ({
  queryParams,
  skip = false,
  refetchOnMountOrArgChange = false,
}: {
  queryParams: QueryParams
  skip?: boolean
  refetchOnMountOrArgChange?: boolean
  useReactQuery?: boolean
}): {
  data?: ClaimsSearchResponseV2
  isLoading: boolean
  isError: boolean
  tableData?: ClaimsSearchResponse[]
} => {
  const { data, isLoading, isError } = useListInsuranceClaimsQuery(queryParams, {
    skip,
    refetchOnMountOrArgChange,
  })

  const tableData = mapUnhandledClaimsServiceItemsForClaimsTable(data?.items || [])

  return {
    data,
    isLoading,
    isError,
    tableData,
  }
}

export { useClaimsSearch }
