import type { ThemeMinimal } from '@helloextend/extend-api-rtk-query'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import type { TableTheme } from '../types/merchant-theme'

export const toTableThemeItem = (theme: ThemeMinimal): TableTheme => {
  return {
    name: theme.name,
    storeId: theme.storeId,
    createdAt: theme.createdAt,
    updatedAt: theme.updatedAt,
    status: theme.status,
    themeId: theme.themeId,
  }
}

// Sort by status if there is a published theme, otherwise sort by updatedAt
export const formatThemeToTableThemeItems = (themes: ThemeMinimal[]): TableTheme[] => {
  const hasPublishedTheme = themes.find((theme) => theme.status === ThemePublishedStatus.published)
  const sortedMethod = hasPublishedTheme ? compareByStatus : compareByUpdatedAt
  const sortedThemes = [...themes].sort(sortedMethod)
  return sortedThemes.map(toTableThemeItem)
}

const compareByStatus = (a: ThemeMinimal, b: ThemeMinimal): number => {
  return a.status.localeCompare(b.status)
}

const compareByUpdatedAt = (a: ThemeMinimal, b: ThemeMinimal): number => {
  return b.updatedAt - a.updatedAt
}
