import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { ClaimPhotosGetResponse } from '@helloextend/extend-api-client'
import type { MerchantClaim } from '../../../../../../types/claims'
import { ConfirmPhotoDeleteModal } from '../../../confirm-photo-delete'
import { ClaimPhotoCard } from '../../../claim-photo-card'
import { AttachmentsUpload } from '../../../attachments-upload'

interface AttachmentsProps {
  claim: MerchantClaim
  photoData: ClaimPhotosGetResponse | undefined
}

const photoIdRegex = /photo\/([A-Za-z0-9-]*)/

const Attachments: FC<AttachmentsProps> = ({ claim, photoData }) => {
  const [deletePhoto, setDeletePhoto] = useState<{ claimId: string; photoId: string } | null>(null)

  const handleDownloadClick = (url?: string): void => {
    if (!url) return
    window.open(url, '_blank')
  }

  const handleDeleteClick = (url?: string): void => {
    if (!url) return
    const regexResult = photoIdRegex.exec(url)
    if (!regexResult || regexResult.length < 2) return
    const photoId = regexResult[1]
    setDeletePhoto({ claimId: claim.id, photoId })
  }

  const handleModalClose = (): void => {
    setDeletePhoto(null)
  }

  return (
    <Container>
      <Title data-cy="attachments-tab-title">Attachments</Title>
      {photoData?.photoDetails && (
        <SectionContainer>
          <ImagesContainer data-cy="photos-section-image-container">
            {photoData.photoDetails.map((detail) => {
              return (
                <ClaimPhotoCard
                  photoDetail={detail}
                  category={claim.adjudicationCategory}
                  onDeleteClick={() => handleDeleteClick(detail.url)}
                  onDownloadClick={() => handleDownloadClick(detail.url)}
                />
              )
            })}
          </ImagesContainer>
        </SectionContainer>
      )}
      <AttachmentsUpload claimId={claim.id} />
      <ConfirmPhotoDeleteModal
        isVisible={Boolean(deletePhoto)}
        onClose={handleModalClose}
        claimId={deletePhoto?.claimId}
        photoId={deletePhoto?.photoId}
        contractId={claim.contractId}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Title = styled.h2({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '28px',
})

const SectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 8,
  flex: '1 0 0',
  paddingBottom: 16,
})

const ImagesContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  padding: 0,
  gap: 16,
})

export { Attachments }
