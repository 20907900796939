import { COLOR, Spinner } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { ClaimType } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { ShippingProtectionClaimView } from '../shipping-protection-claim-view'
import { ErrorPage } from '../../../components/error-page'
import { ClaimHeader } from './claim-header'
import { ProductProtectionClaimView } from '../product-protection-claim-view'
import { useGetInsuranceClaimQuery } from '../../../queries/claims'
import { useGetServiceOrdersByClaimQuery } from '../../../queries/service-orders'
import { useGetContractQuery } from '../../../queries/contract'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import { contractDetailsAtom } from '../../../atoms/contract-details'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { serviceOrdersAtom } from '../../../atoms/service-orders-atom'
import { mapCommonClaimAttributes } from '../../../utils/map-unhandled-claims-service-items-for-claims-table'

export const Claim: FC = () => {
  const { url } = useRouteMatch()
  const { claimId } = useParams<{ claimId: string }>()

  const { data: claim, isInitialLoading, isError } = useGetInsuranceClaimQuery({ claimId })

  const contractId = claim?.contractId || ''

  const isShippingProtectionClaim = Boolean(claim?.type === ClaimType.SHIPPING_PROTECTION)
  const { data: serviceOrders } = useGetServiceOrdersByClaimQuery({ claimId })

  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractError,
    error: contractError,
  } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
    enabled: Boolean(contractId),
  })
  const setContractAtom = useSetAtom(contractDetailsAtom)
  const setClaimAtom = useSetAtom(claimDetailsAtom)
  const setServiceOrdersAtom = useSetAtom(serviceOrdersAtom)

  if (isInitialLoading || isContractLoading || !contract || !claim) {
    return (
      <div className="width-100 height-100 flex justify-content-center align-items-center">
        <Spinner color={COLOR.BLUE[800]} data-cy="spinner" />
      </div>
    )
  }

  if (isError || isContractError || !contract || !claim) {
    return <ErrorPage error={contractError} data-cy="error-page" />
  }
  setContractAtom(contract)
  setClaimAtom(mapCommonClaimAttributes(claim))
  setServiceOrdersAtom(serviceOrders || [])

  return (
    <>
      <ClaimHeader contract={contract} claim={claim} />
      <Switch>
        <Route exact path="/store/claims/:claimId">
          {isShippingProtectionClaim ? (
            <ShippingProtectionClaimView data-cy="shipping-protection-claim-view" />
          ) : (
            <ProductProtectionClaimView data-cy="product-protection-claim-view" />
          )}
        </Route>
        <Route exact path={`${url}/history`}>
          <div>Coming soon!</div>
        </Route>
        <Redirect to="/404" />
      </Switch>
    </>
  )
}
