import React from 'react'
import type { FC } from 'react'
import { Modal } from '@extend/zen'

export interface ConfirmationModalProps {
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  return (
    <Modal
      heading="Hang on! You have some unsaved changes on this page."
      primaryButtonProps={{
        text: 'Leave Page',
        onClick: props.onConfirm,
        color: 'red',
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: props.onCancel,
      }}
      onDismissRequest={props.onCancel}
      data-cy="confirmation-modal"
      size="sm"
    >
      <p>
        If you leave, we will discard your unsaved changes, or you can click cancel and save your
        changes.
      </p>
      <p>Are you sure you want to leave?</p>
    </Modal>
  )
}
