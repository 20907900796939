import { useCallback } from 'react'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import type { Grant } from '../types/okta'
import { getAccountIdFromV3Token } from '../lib/jwt'
import { isSettledPromiseRejected } from '../lib/promises'
import type { RoleOrg } from '../utils/user-roles'
import { diffRolesR3, diffRoles } from '../utils/user-roles'
import { formatUserGrantsForDeactivation } from '../utils/format-user-grants-for-deactivation'
import { buildQueryStringFromObject } from '../utils/build-query-string-from-object'
import type { V3User } from '../types/users'
import { mapUsersWithGrants } from '../utils/map-users-with-grants'

const AUTH_V3_BASE_URL = `https://${EXTEND_API_HOST}/auth/v3`
const GRANTS_BASE_URL = `https://${EXTEND_API_HOST}/auth/grants`

export const USERS_CACHE_KEY = 'Users_V3'
export const USER_GRANTS_CACHE_KEY = 'User_Grants'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
}

const fetchUsers = async (
  v3AccessToken: string,
  cursor?: string,
  pageSize = 10,
  queryParams: Record<string, string | number> = {},
): Promise<PaginatedUsers> => {
  const accountId = getAccountIdFromV3Token(v3AccessToken)
  const params = buildQueryStringFromObject(queryParams)
  const headers = {
    ...COMMON_HEADERS,
    'x-extend-access-token': v3AccessToken,
  }
  const response = await fetch(
    `${AUTH_V3_BASE_URL}/users?limit=${pageSize}${cursor ? `&cursor=${cursor}` : ''}${
      params ? `&${params}` : ''
    }`,
    {
      headers,
    },
  )
  if (!response.ok) {
    throw new Error('Unable to fetch users')
  }
  const data = await response.json()

  const usersGrantsRes = await fetch(`${GRANTS_BASE_URL}/filter-grants-for-ern`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ern: `ERN:ACC:${accountId}`,
      partialErn: false,
      userIds: data.users.map((user: V3User) => user.email),
    }),
  })

  const userGrantsData = await usersGrantsRes.json()

  data.users = mapUsersWithGrants(data.users, userGrantsData.grants)

  return data
}

export function useGetUsersV3Query(
  { page = 0, pageSize = 10 },
  cursor = '',
  params = {},
): QueryObserverResult<PaginatedUsers, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const data: PaginatedUsers = await fetchUsers(accessToken, cursor, pageSize, params)

    return data
  }, [accessToken, cursor, pageSize, params])

  return useQuery({
    queryKey: [USERS_CACHE_KEY, params, page, pageSize],
    queryFn,
  })
}

export function useGetUserV3Query(uuid: string): QueryObserverResult<V3User, Error> {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${AUTH_V3_BASE_URL}/users/${uuid}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': v3AccessToken,
      },
    })
    if (!response.ok) {
      throw new Error('Unable to fetch user')
    }

    const user = await response.json()

    const grantsResponse = await fetch(`${GRANTS_BASE_URL}/users/${user.email}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': v3AccessToken,
      },
    })

    if (grantsResponse.ok) {
      const grantsData = await grantsResponse.json()
      user.grants = grantsData.grants.map((grant: Grant) => ({
        userId: user.email,
        ...grant,
      }))
    }

    return user
  }, [v3AccessToken, uuid])

  return useQuery({
    queryKey: [USERS_CACHE_KEY, { uuid }],
    queryFn,
    enabled: Boolean(uuid),
  })
}

type UpdateUserGrantsRequest = {
  uuid: string
  userId: string
  newRoles: string[]
  previousRoles: string[]
}
export function useUpdateUserGrantsMutation(): UseBaseMutationResult<
  void,
  Error,
  UpdateUserGrantsRequest,
  { previousData: V3User | undefined }
> {
  const client = useQueryClient()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(v3AccessToken)

  return useMutation({
    mutationFn: async ({ userId, newRoles, previousRoles }) => {
      const { toAdd, toRemove } = diffRoles(newRoles, previousRoles)
      const grantResponses: Array<Promise<Response>> = []
      for (const role of toAdd) {
        grantResponses.push(
          fetch(GRANTS_BASE_URL, {
            method: 'POST',
            body: JSON.stringify({ userId, ern: `ERN:ACC:${accountId}`, role }),
            headers: {
              ...COMMON_HEADERS,
              'x-extend-access-token': v3AccessToken,
            },
          }),
        )
      }
      if (toRemove.length) {
        const grants: Grant[] = toRemove.map((role) => ({
          userId,
          ern: `ERN:ACC:${accountId}`,
          role,
        }))
        grantResponses.push(
          fetch(`${GRANTS_BASE_URL}/multiple`, {
            method: 'DELETE',
            body: JSON.stringify(grants),
            headers: {
              ...COMMON_HEADERS,
              'x-extend-access-token': v3AccessToken,
            },
          }),
        )
      }

      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (isSettledPromiseRejected(r)) {
            throw new Error('Unable to update all grants')
          }
        }
      })
    },
    onMutate: async ({ uuid, newRoles, userId }) => {
      await client.cancelQueries({ queryKey: [USERS_CACHE_KEY, { uuid }] })
      const previousData: V3User | undefined = client.getQueryData([USERS_CACHE_KEY, { uuid }])

      client.setQueryData<V3User>([USERS_CACHE_KEY, { uuid }], (prev) => {
        if (!prev) {
          return undefined
        }
        const newGrants = newRoles.map((role) => ({ userId, ern: `ERN:ACC:${accountId}`, role }))
        return { ...prev, grants: newGrants }
      })

      return { previousData }
    },
    onError: async (_data, { uuid }, context) => {
      client.setQueryData([USERS_CACHE_KEY, { uuid }], context?.previousData)
    },
  })
}

type UpdateUserGrantsRequestR3 = {
  uuid: string
  userId: string
  newRoleOrgs: RoleOrg[]
  previousRoleOrgs: RoleOrg[]
}
export function useUpdateUserGrantsMutationR3(): UseBaseMutationResult<
  void,
  Error,
  UpdateUserGrantsRequestR3,
  { previousData: V3User | undefined }
> {
  const client = useQueryClient()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(v3AccessToken)

  return useMutation({
    mutationFn: async ({ userId, newRoleOrgs, previousRoleOrgs }) => {
      const { toAdd, toRemove } = diffRolesR3(newRoleOrgs, previousRoleOrgs)
      const grantResponses: Array<Promise<Response>> = []

      for (const roleOrg of toAdd) {
        for (const id of roleOrg.orgIds) {
          grantResponses.push(
            fetch(GRANTS_BASE_URL, {
              method: 'POST',
              body: JSON.stringify({
                userId,
                ern: `ERN:ACC:${accountId}:ORG:${id}`,
                role: roleOrg.role,
              }),
              headers: {
                ...COMMON_HEADERS,
                'x-extend-access-token': v3AccessToken,
              },
            }),
          )
        }
      }

      for (const roleOrg of toRemove) {
        for (const id of roleOrg.orgIds) {
          grantResponses.push(
            fetch(GRANTS_BASE_URL, {
              method: 'DELETE',
              body: JSON.stringify({
                userId,
                ern: `ERN:ACC:${accountId}:ORG:${id}`,
                role: roleOrg.role,
              }),
              headers: {
                ...COMMON_HEADERS,
                'x-extend-access-token': v3AccessToken,
              },
            }),
          )
        }
      }

      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (isSettledPromiseRejected(r)) {
            throw new Error('Unable to update all grants')
          }
        }
      })

      await client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}

export function useGetUserGrantsQuery(email: string): QueryObserverResult<Grant[], Error> {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const grantsResponse = await fetch(`${GRANTS_BASE_URL}/users/${email}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': v3AccessToken,
      },
    })

    if (!grantsResponse.ok) {
      throw new Error('Unable to fetch grants')
    }

    const grantsData = await grantsResponse.json()

    return grantsData.grants
  }, [v3AccessToken, email])

  return useQuery({
    queryKey: [USER_GRANTS_CACHE_KEY, { email }],
    queryFn,
    enabled: Boolean(email),
  })
}

export function useResendInviteMutation(): UseBaseMutationResult<void, Error, string, void> {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  return useMutation({
    mutationFn: async (email: string) => {
      const response = await fetch(`${AUTH_V3_BASE_URL}/users/${email}/regenerate-invite`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': v3AccessToken,
        },
      })

      if (!response.ok) {
        throw new Error('Unable to invite user')
      }
    },
  })
}

export function useDeactivateUserMutation(): UseBaseMutationResult<
  void,
  Error,
  DeactivateUserRequest,
  void
> {
  const client = useQueryClient()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ grants }) => {
      const response = await fetch(`${GRANTS_BASE_URL}/multiple`, {
        method: 'DELETE',
        body: JSON.stringify(formatUserGrantsForDeactivation(grants)),
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': v3AccessToken,
        },
      })

      if (!response.ok) {
        throw new Error('Unable to deactivate user')
      }
    },
    onSuccess: async () => {
      await client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}

export function useUpdateUserNameMutation(): UseBaseMutationResult<
  void,
  Error,
  UpdateUserNameRequest,
  void
> {
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ email, firstName, lastName }) => {
      const response = await fetch(`${AUTH_V3_BASE_URL}/users/${email}`, {
        method: 'PUT',
        body: JSON.stringify({ firstName, lastName }),
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': v3AccessToken,
        },
      })

      if (!response.ok) {
        throw new Error('Unable to update user name')
      }
    },
  })
}

export function useActivateUserMutation(): UseBaseMutationResult<
  void,
  Error,
  ActivateUserRequest,
  void
> {
  const client = useQueryClient()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(v3AccessToken)

  return useMutation({
    mutationFn: async ({ email, roles }) => {
      const grantResponses: Array<Promise<Response>> = []
      for (const role of roles) {
        grantResponses.push(
          fetch(GRANTS_BASE_URL, {
            method: 'POST',
            body: JSON.stringify({
              userId: email,
              ern: `ERN:ACC:${accountId}`,
              role,
            }),
            headers: {
              ...COMMON_HEADERS,
              'x-extend-access-token': v3AccessToken,
            },
          }),
        )
      }
      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (isSettledPromiseRejected(r)) {
            throw new Error('Unable to add all grants')
          }
        }
      })
    },
    onSuccess: async () => {
      await client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}

export function useActivateUserMutationR3(): UseBaseMutationResult<
  void,
  Error,
  ActivateUserRequestV3,
  void
> {
  const client = useQueryClient()
  const v3AccessToken = useAtomValue(v3AccessTokenAtom) || ''
  const accountId = getAccountIdFromV3Token(v3AccessToken)

  return useMutation({
    mutationFn: async ({ email, roleOrgs }) => {
      const grantResponses: Array<Promise<Response>> = []
      for (const roleOrg of roleOrgs) {
        for (const orgId of roleOrg.orgIds) {
          grantResponses.push(
            fetch(GRANTS_BASE_URL, {
              method: 'POST',
              body: JSON.stringify({
                userId: email,
                ern: `ERN:ACC:${accountId}:ORG:${orgId}`,
                role: roleOrg.role,
              }),
              headers: {
                ...COMMON_HEADERS,
                'x-extend-access-token': v3AccessToken,
              },
            }),
          )
        }
      }
      await Promise.allSettled(grantResponses).then((rs) => {
        for (const r of rs) {
          if (isSettledPromiseRejected(r)) {
            throw new Error('Unable to add all grants')
          }
        }
      })
    },
    onSuccess: async () => {
      await client.invalidateQueries([USERS_CACHE_KEY])
    },
  })
}
type ActivateUserRequest = {
  email: string
  roles: string[]
}

type ActivateUserRequestV3 = {
  email: string
  roleOrgs: RoleOrg[]
}

export type DeactivateUserRequest = { grants: Grant[] }

export type UpdateUserNameRequest = { email: string; firstName: string; lastName: string }

export type PaginatedUsers = {
  users: V3User[]
  cursor?: string
}
