import type { FC } from 'react'
import React from 'react'
import Helmet from 'react-helmet'
import { Header } from './header/header'

export const PublicSiteHeader: FC = () => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,Regular,SemiBold"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,500,500i,600,600i,800,800i,Regular,SemiBold,ExtraBold"
        rel="stylesheet"
      />
      <html lang="en" />
    </Helmet>
    <Header />
  </>
)
