import { COLOR, Error, Icon } from '@extend/zen'
import { useSetAtom } from 'jotai'
import type { FC } from 'react'
import React from 'react'
import { v3AccessTokenAtom } from '../atoms/auth'
import styles from './user-profile-logout-reminder.module.css'

export const UserProfileLogoutReminder: FC = () => {
  const setAccessToken = useSetAtom(v3AccessTokenAtom)
  const handleClick = (): void => {
    setAccessToken('')
  }
  return (
    <div className={styles.container}>
      <div className={styles['icon-wrapper']}>
        <Icon icon={Error} color={COLOR.YELLOW[700]} />
      </div>
      <span className={styles.text}>
        To see your name change reflected throughout the portal, you&apos;ll have to
        <button className={styles['logout-button']} type="button" onClick={handleClick}>
          log out and log back in.
        </button>
      </span>
    </div>
  )
}
