import { combineReducers } from 'redux'
import {
  servicersApi,
  claimsApi,
  contractsApi,
  productCategoriesRdbApi,
  productsApi,
  productsRdbApi,
  storesApi,
  serviceOrdersApi,
  adjudicationManagementApi,
  emsApi,
  ordersApi,
  conversationsApi,
} from '@helloextend/extend-api-rtk-query'

export interface RootState {
  [productCategoriesRdbApi.reducerPath]: ReturnType<typeof productCategoriesRdbApi.reducer>
  [productsApi.reducerPath]: ReturnType<typeof productsApi.reducer>
  [productsRdbApi.reducerPath]: ReturnType<typeof productsRdbApi.reducer>
  [contractsApi.reducerPath]: ReturnType<typeof contractsApi.reducer>
  [claimsApi.reducerPath]: ReturnType<typeof claimsApi.reducer>
  [storesApi.reducerPath]: ReturnType<typeof storesApi.reducer>
  [serviceOrdersApi.reducerPath]: ReturnType<typeof serviceOrdersApi.reducer>
  [servicersApi.reducerPath]: ReturnType<typeof servicersApi.reducer>
  [adjudicationManagementApi.reducerPath]: ReturnType<typeof adjudicationManagementApi.reducer>
  [emsApi.reducerPath]: ReturnType<typeof emsApi.reducer>
  [ordersApi.reducerPath]: ReturnType<typeof ordersApi.reducer>
  [conversationsApi.reducerPath]: ReturnType<typeof conversationsApi.reducer>
}
export default combineReducers<RootState>({
  [productCategoriesRdbApi.reducerPath]: productCategoriesRdbApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [productsRdbApi.reducerPath]: productsRdbApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [storesApi.reducerPath]: storesApi.reducer,
  [serviceOrdersApi.reducerPath]: serviceOrdersApi.reducer,
  [servicersApi.reducerPath]: servicersApi.reducer,
  [adjudicationManagementApi.reducerPath]: adjudicationManagementApi.reducer,
  [emsApi.reducerPath]: emsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [conversationsApi.reducerPath]: conversationsApi.reducer,
})
