// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rhQHAfc9ybSuq7BOLhirB {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n._1jnQ3piGbno6_zQFGpKlV6 {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n\n._2xroVWpRGOI0xGBDxvik8T {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n}\n\n._2xroVWpRGOI0xGBDxvik8T label {\n    color: var(--neutral-1000);\n}", ""]);
// Exports
exports.locals = {
	"container": "rhQHAfc9ybSuq7BOLhirB",
	"grid": "_1jnQ3piGbno6_zQFGpKlV6",
	"date-container": "_2xroVWpRGOI0xGBDxvik8T"
};
module.exports = exports;
