// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3pqWbn4-1zAvmmlBylItBe {\n    color: var(--neutral-1000);\n    font-size: 16px;\n    font-weight: 500;\n    margin: 0 0 24px 0;\n}", ""]);
// Exports
exports.locals = {
	"detail": "_3pqWbn4-1zAvmmlBylItBe"
};
module.exports = exports;
