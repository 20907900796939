import { atom } from 'jotai/vanilla'
import { CreateContractsModalStep } from '../constants/create-contracts-modal-step'
import { Order, Product, OfferPlan } from '@helloextend/extend-api-client'

export type NewOrder = {
  currency: Order['currency']
  customer: Pick<Order['customer'], 'name' | 'email'> &
    Partial<Pick<Order['customer'], 'phone'>> & {
      billingAddress?: Partial<Order['customer']['billingAddress']>
      shippingAddress?: Partial<Order['customer']['shippingAddress']>
    }
}

export type ProductDetails = {
  purchaseDate?: string
  purchasePrice?: string
}

export type ContractCreatedInfo = {
  customerName: string
  contractIds: string[]
  transactionId: string
  contractsCreated?: boolean
  pendingDismissed?: boolean
  successDismissed?: boolean
}

export type PlanDetails = { plan?: OfferPlan; numContracts?: number }

export const isModalVisibleAtom = atom<boolean>(false)

export const isConfirmationModalVisibleAtom = atom<boolean>(false)

// default to OrderSearch as it is the first step in contract creation
export const modalStepAtom = atom<CreateContractsModalStep>(CreateContractsModalStep.OrderSearch)

export const transactionIdAtom = atom<string>('')

export const orderAtom = atom<Order | NewOrder | undefined>(undefined)

export const productAtom = atom<Product | undefined>(undefined)

export const productDetailsAtom = atom<ProductDetails | undefined>(undefined)

export const offersAtom = atom<OfferPlan[]>([])

export const planDetailsAtom = atom<PlanDetails | undefined>(undefined)

export const contractCreatedAtom = atom<ContractCreatedInfo | undefined>(undefined)
