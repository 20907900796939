import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { Add, AdvancedSelect, Button, Label, Modal, ModalController, Trash } from '@extend/zen'
import {
  closeReactivateUserModal,
  isReactivateUserModalVisible,
  reactivateUserModalEmailAtom,
} from '../../atoms/reactivate-user-modal'
import { useRoleOptions } from '../../hooks/use-role-options'
import { useV3ReactivateUserForm } from './use-v3-reactivate-user-form-r3'
import styles from './reactivate-user-modal-r3.module.css'

export const ReactivateUserModalR3: FC = () => {
  const isVisible = useAtomValue(isReactivateUserModalVisible)
  const close = useSetAtom(closeReactivateUserModal)
  const email = useAtomValue(reactivateUserModalEmailAtom)

  const {
    organizations,
    roleOrgMappings,
    hasChanges,
    reactivateUser,
    addRow,
    removeRow,
    changeRowRole,
    changeRowOrganizations,
    isUserReactivating,
    errors,
  } = useV3ReactivateUserForm({
    email,
    onCancel: close,
  })

  const numRows = roleOrgMappings.length

  const options = useRoleOptions()

  const canAddRow = numRows < options.length

  const mappedOrgs = useMemo(() => {
    return organizations.map((organization) => ({
      display: organization.name,
      value: organization.id,
    }))
  }, [organizations])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Activate user"
        primaryButtonProps={{
          onClick: reactivateUser,
          text: 'Activate User',
          isDisabled: !hasChanges,
          isProcessing: isUserReactivating,
        }}
        secondaryButtonProps={{
          onClick: close,
          text: 'Cancel',
        }}
      >
        <section className={styles['roles-section']}>
          <div className={styles['label-row']}>
            <div>
              <Label
                helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
                htmlFor="roles"
              >
                Role
              </Label>
            </div>
            <div>
              <Label
                helperText="The roles that this user has been assigned. An admin can edit these in the Users tab."
                htmlFor="roles"
              >
                Organization
              </Label>
            </div>
          </div>
          {roleOrgMappings.map((roleOrg, index) => {
            return (
              <div className={styles['data-row']} key={`Row-${roleOrg.role}`}>
                <div className={styles['role-selector']}>
                  <AdvancedSelect
                    id="roles"
                    placeholder="Role"
                    data-cy={`role-selector-${index + 1}`}
                    options={options}
                    value={roleOrg.role}
                    onChange={(e) => changeRowRole(e.target.value, index)}
                    multiple={false}
                  />
                </div>
                <div className={styles['org-selector']}>
                  <AdvancedSelect
                    id="organizations"
                    placeholder="Organizations"
                    data-cy={`org-selector-${index + 1}`}
                    options={mappedOrgs}
                    value={roleOrg.orgIds}
                    errorFeedback={errors?.role}
                    isError={Boolean(errors?.role)}
                    onChange={(e) => {
                      const newOrgs = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value]
                      changeRowOrganizations(newOrgs, index)
                    }}
                    multiple
                  />
                  {numRows !== 1 ? (
                    <Button
                      emphasis="low"
                      size="small"
                      color="neutral"
                      icon={Trash}
                      onClick={() => removeRow(index)}
                    />
                  ) : null}
                </div>
              </div>
            )
          })}
        </section>
        {canAddRow ? (
          <section className={styles['add-button-row']}>
            <Button
              text="Add row"
              icon={Add}
              iconPosition="right"
              onClick={addRow}
              emphasis="medium"
              size="small"
              fillContainer
            />
          </section>
        ) : null}
      </Modal>
    </ModalController>
  )
}
