// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2A12sMwdsl2fH2et9UjJD1 {\n    font-weight: 700;\n}\n\n._2cqzeKIQSFbcjwj9Uu1csJ {\n    display: flex;\n    gap: 10px;\n    align-items: end;\n}\n\n._2cqzeKIQSFbcjwj9Uu1csJ > div:first-child {\n    width: 100%;\n}\n\n._2cqzeKIQSFbcjwj9Uu1csJ > button {\n    align-self: unset;\n}\n\np {\n    font-weight: 100;\n    line-height: 20px;\n}\n\n._3OVUVfEC8ErdvamLvXO5Sx {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}", ""]);
// Exports
exports.locals = {
	"strong": "_2A12sMwdsl2fH2et9UjJD1",
	"input-wrapper": "_2cqzeKIQSFbcjwj9Uu1csJ",
	"wrapper": "_3OVUVfEC8ErdvamLvXO5Sx"
};
module.exports = exports;
