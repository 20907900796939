// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._26nL5KnrTzyCC0NrO9Vlhg {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n._24xHe8zmHIAJ7392-LaV8j {\n    flex: 1 0 100%;\n}\n\n._3fZNplR4QsXNPRy7Ja2Ob4 {\n    font-size: 18px;\n    margin-bottom: 8px;\n}\n\n.XeQECdg9eDEP8oUiUoaT2 {\n    flex-grow: 1;\n}\n\n._2NHzU351VXUtabB3MR4cgT {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n._2NHzU351VXUtabB3MR4cgT > * + * {\n    margin-top: 16px;\n}\n\n@media (min-width: 1025px) {\n    ._24xHe8zmHIAJ7392-LaV8j {\n        flex: 0 1 348px;\n        margin-right: 46px;\n    }\n\n    ._3fZNplR4QsXNPRy7Ja2Ob4 {\n        max-width: 178px;\n    }\n\n    ._2NHzU351VXUtabB3MR4cgT {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n}", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "_26nL5KnrTzyCC0NrO9Vlhg",
	"header": "_24xHe8zmHIAJ7392-LaV8j",
	"header-text": "_3fZNplR4QsXNPRy7Ja2Ob4",
	"details": "XeQECdg9eDEP8oUiUoaT2",
	"details-column": "_2NHzU351VXUtabB3MR4cgT"
};
module.exports = exports;
