import { useCallback } from 'react'
import { useAtomValue } from 'jotai'
import { getActiveStoreIdAtom } from '../atoms/stores'
import { orderAtom, transactionIdAtom } from '../atoms/create-contracts-modal'
import { productAtom, productDetailsAtom, planDetailsAtom } from '../atoms/create-contracts-modal'
import { useCreateOrderLineItemMutation, useUpsertOrderMutation } from '../queries/orders'
import { LineItem } from '@helloextend/extend-api-client'

const getNewlyCreatedLineItems = (
  lineItems: LineItem[],
  lineItemTransactionId: string,
  numContracts: number,
) => {
  // Reverse the line items so the most recent is first
  const lineItemsByRecent = lineItems.reverse() || []
  const newlyCreatedLineItems = lineItemsByRecent.filter(
    (li) => li.lineItemTransactionId === lineItemTransactionId && li.status === 'contract_pending',
  )

  return newlyCreatedLineItems.slice(0, numContracts || 1)
}

export const useCreateContracts = () => {
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const transactionId = useAtomValue(transactionIdAtom)
  const order = useAtomValue(orderAtom)
  const product = useAtomValue(productAtom)
  const productDetails = useAtomValue(productDetailsAtom)
  const planDetails = useAtomValue(planDetailsAtom)
  const { mutateAsync: createLineItemMutation } = useCreateOrderLineItemMutation()
  const { mutateAsync: upsertOrderMutation } = useUpsertOrderMutation()

  const createContracts: () => Promise<LineItem[]> = useCallback(async () => {
    // NOTE: these should be set during the contract creation flow
    // The checks are more for easily processing the types below
    if (
      !order ||
      !product ||
      !product.price ||
      !productDetails ||
      !planDetails?.plan?.id ||
      !productDetails.purchasePrice ||
      !productDetails.purchaseDate
    ) {
      console.error('Missing required data')
      return []
    }

    const lineItemTransactionId = `STORE::${storeId}::ORDER::${transactionId}::PRODUCT::${product.referenceId}::PLAN::${planDetails.plan.id}`

    if (order && 'id' in order && order.id) {
      // Order already exists, add new line items
      try {
        const res = await createLineItemMutation({
          orderId: order.id,
          lineItemTransactionId,
          plan: {
            id: planDetails.plan.id,
            purchasePrice: planDetails.plan.price,
          },
          product: {
            id: product.referenceId,
            title: product.title,
            category: product.category || '',
            listPrice: product?.price.amount,
            purchasePrice: parseInt(productDetails.purchasePrice, 10),
            purchaseDate: Date.parse(productDetails.purchaseDate),
          },
          fulfilledQuantity: planDetails.numContracts || 1,
          quantity: planDetails.numContracts || 1,
        })

        return getNewlyCreatedLineItems(
          res.lineItems,
          lineItemTransactionId,
          planDetails.numContracts || 1,
        )
      } catch (e) {
        console.error('Error creating line items', e)
        throw e
      }
    } else {
      // Order does not exist, upsert new order and line items
      try {
        const res = await upsertOrderMutation({
          storeId,
          transactionId,
          customer: order.customer,
          currency: order.currency,
          lineItems: [
            {
              lineItemTransactionId,
              plan: {
                id: planDetails.plan.id,
                purchasePrice: planDetails.plan.price,
              },
              product: {
                id: product.referenceId,
                title: product.title,
                category: product.category || '',
                listPrice: product?.price.amount,
                purchasePrice: parseInt(productDetails.purchasePrice, 10),
                purchaseDate: Date.parse(productDetails.purchaseDate),
              },
              fulfilledQuantity: planDetails.numContracts || 1,
              quantity: planDetails.numContracts || 1,
              status: 'fulfilled',
            },
          ],
        })

        return getNewlyCreatedLineItems(
          res.lineItems,
          lineItemTransactionId,
          planDetails.numContracts || 1,
        )
      } catch (e) {
        console.error('Error upserting new order', e)
        throw e
      }
    }
  }, [order, product, productDetails, planDetails, storeId, transactionId, createLineItemMutation])

  return { createContracts }
}
