import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { Input, Modal, ModalController } from '@extend/zen'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useFormik } from 'formik'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { usePrevious } from '@helloextend/client-hooks'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { themeNameValidations } from '../../../schemas/name-theme-modal-schema'
import {
  currentlySelectedThemeIdAtom,
  hasChangesToPublishedThemeAtom,
  renameThemeDisplayAtom,
} from '../../../atoms/customize-theme'
import { useFetchAllThemesQuery } from '../../../queries/themes'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

export type NameThemeModalProps = {
  isVisible: boolean
  title?: string
  themeName?: string
  onConfirm: (themeName: string) => void
  onCancel: () => void
  resetForm: () => void
  isProcessing?: boolean
  error?: FetchBaseQueryError | SerializedError | null | string
  isSuccess?: boolean
  createdThemeId?: string
}

export const NameThemeModal: FC<NameThemeModalProps> = ({
  isVisible,
  title = 'New Theme Name',
  themeName,
  onConfirm,
  onCancel,
  resetForm,
  isProcessing = false,
  error,
  isSuccess = false,
  createdThemeId,
}) => {
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const [hasChangesToPublishedTheme, setHasChangesToPublishedTheme] = useAtom(
    hasChangesToPublishedThemeAtom,
  )
  const setCurrentThemeId = useSetAtom(currentlySelectedThemeIdAtom)
  const setRenameThemeDisplay = useSetAtom(renameThemeDisplayAtom)

  const confirmButtonText = useMemo(() => {
    if (hasChangesToPublishedTheme) {
      return 'Save'
    }
    return themeName ? 'Update' : 'Next'
  }, [hasChangesToPublishedTheme, themeName])

  const { data } = useFetchAllThemesQuery({ storeId })

  const formik = useFormik({
    validationSchema: themeNameValidations(data?.themes ?? []),
    initialValues: { name: themeName ?? '' },
    onSubmit: () => {},
  })

  const [isInitialValue, setIsInitialValue] = useState(true)
  const hasErrors = Object.entries(formik.errors).length >= 1
  const isConfirmButtonDisabled = hasErrors || isInitialValue || isProcessing
  const { toastCompleted, toastError } = useStandardToast()

  const handleThemeNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue('name', e.target.value)
    setIsInitialValue(false)
  }

  const handleOnConfirm = (): void => {
    onConfirm(formik.values.name)
  }

  const prevProcessing = usePrevious<boolean>(isProcessing)

  useEffect(() => {
    if (prevProcessing && !error && isSuccess) {
      onCancel()
      resetForm()
      toastCompleted(`Theme successfully ${hasChangesToPublishedTheme ? 'created' : 'updated'}`)
      setRenameThemeDisplay(null)
      setHasChangesToPublishedTheme(false)
    }
  }, [
    prevProcessing,
    error,
    isSuccess,
    onCancel,
    toastCompleted,
    resetForm,
    setRenameThemeDisplay,
    setHasChangesToPublishedTheme,
    hasChangesToPublishedTheme,
  ])

  useEffect(() => {
    if (error) {
      toastError('Uh-Oh, something went wrong. Please try again.')
      setRenameThemeDisplay(null)
    }
  }, [error, toastError, setRenameThemeDisplay])

  useEffect(() => {
    if (createdThemeId) {
      setCurrentThemeId(createdThemeId)
    }
  }, [setCurrentThemeId, createdThemeId])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading={title}
        secondaryButtonProps={{
          'data-cy': 'name-theme-modal-cancel-button',
          text: 'Cancel',
          onClick: onCancel,
        }}
        primaryButtonProps={{
          'data-cy': 'name-theme-modal-confirm-button',
          text: confirmButtonText,
          onClick: handleOnConfirm,
          isDisabled: isConfirmButtonDisabled,
          isProcessing,
        }}
      >
        <Input
          data-cy="name-theme"
          id="name-theme"
          label="Theme Name"
          value={formik.values.name}
          onChange={handleThemeNameChange}
          autoFocus
          isError={hasErrors}
          errorFeedback={formik.errors.name}
        />
      </Modal>
    </ModalController>
  )
}
