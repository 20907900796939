import React, { useEffect, useCallback, useState } from 'react'
import type { FC } from 'react'
import { useHistory } from 'react-router'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import {
  MenuButtonItem,
  COLOR,
  Icon,
  ToastColor,
  ToastDuration,
  useToaster,
  Spinner,
} from '@extend/zen'
import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { Check } from '@extend/zen'
import { useGetUserGrantsQuery } from '../queries/users-v3'
import { useUser } from '../hooks/use-user'
import { v3AccessTokenAtom, cachedRolesAtom } from '../atoms/auth'
import type { UserRoleV3 } from '../utils/get-user-roles'
import { UserRoleMappingV3 } from '../utils/get-user-roles'
import { useExchangeTokenMutation } from '../queries/okta'
import styles from './role-switcher.module.css'
import { useAccountFilteredGrants } from '../hooks/use-account-filtered-grants'

export const RoleSwitcher: FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const accessToken = useAtomValue(v3AccessTokenAtom)
  const { email, accountId } = useUser()
  const { data: grants, isError, isInitialLoading } = useGetUserGrantsQuery(email ?? '')

  const { mutateAsync: exchangeToken, isLoading: isLoadingExchangeToken } =
    useExchangeTokenMutation()
  const setAuthToken = useSetAtom(v3AccessTokenAtom)
  const [cachedRoles, setCachedRoles] = useAtom(cachedRolesAtom)
  const selectedGrant = cachedRoles && cachedRoles[accountId || '']

  const [roleClicked, isRoleClicked] = useState<UserGrant | null>(null)

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Something went wrong when getting a list of roles',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isError, toast])

  const handleOnClick = useCallback(
    async (grant: UserGrant) => {
      try {
        isRoleClicked(grant)
        const newAccessToken = await exchangeToken({ accessToken, grant })

        if (newAccessToken) {
          setAuthToken(newAccessToken)
          setCachedRoles({
            ...cachedRoles,
            [accountId || '']: grant,
          })
        }
        history.push('/')
      } catch (err) {
        isRoleClicked(null)
        toast({
          message: 'Something went wrong when switching roles.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    },
    [
      cachedRoles,
      setCachedRoles,
      exchangeToken,
      setAuthToken,
      history,
      toast,
      accessToken,
      accountId,
    ],
  )

  const validFilteredGrants = useAccountFilteredGrants(grants)

  const renderAdornment = useCallback(
    (isSelectedRole: boolean, grant: UserGrant): React.ReactElement | undefined => {
      const shouldDisplayRoleSpinner = isLoadingExchangeToken && grant === roleClicked
      if (shouldDisplayRoleSpinner) {
        return <Spinner data-cy="role-switcher-spinner" />
      }

      return isSelectedRole ? (
        <Icon icon={Check} color={COLOR.BLUE[800]} data-cy="role-switcher-selected-role" />
      ) : undefined
    },
    [isLoadingExchangeToken, roleClicked],
  )

  if (isInitialLoading) {
    return (
      <div className={styles['spinner-container']}>
        <Spinner data-cy="role-switcher-spinner" />
      </div>
    )
  }

  if (!validFilteredGrants || validFilteredGrants?.length <= 1) return null

  return (
    <>
      <h3 className={styles['role-switcher-title']}>Switch Roles</h3>

      {validFilteredGrants?.map((grant) => {
        const selectedRole = selectedGrant?.role === grant.role && selectedGrant.ern === grant.ern
        const roleName = UserRoleMappingV3[grant.role as UserRoleV3]

        return (
          <MenuButtonItem
            rightAlignAdornment
            data-cy="role-switcher-menu-item"
            endAdornment={renderAdornment(selectedRole, grant)}
            onClick={() => handleOnClick(grant)}
            key={`${grant.role}-${grant.ern}`}
            isToggled={Boolean(selectedRole)}
          >
            {roleName}
          </MenuButtonItem>
        )
      })}
      <div className={styles['menu-item-divider']} />
    </>
  )
}
