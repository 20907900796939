// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3giHYCa6uv98n_9O4pzM0g {\n\tdisplay: grid;\n\tgrid-auto-flow: row;\n\tgap: 1rem;\n}\n\n._1fwzHmT4I7HlEZOGl4rswv {\n\tmax-width: 6rem;\n}\n\n._1541W3sIGWv4NOEob_yVvB {\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_3giHYCa6uv98n_9O4pzM0g",
	"product-image": "_1fwzHmT4I7HlEZOGl4rswv",
	"truncatedId": "_1541W3sIGWv4NOEob_yVvB"
};
module.exports = exports;
