import type { FC } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './link-tab.module.css'

type LinkTabProps = {
  to: string
  isAnalytics?: boolean
}

export const LinkTab: FC<LinkTabProps> = ({ to, isAnalytics, children }) => {
  const className = isAnalytics ? `${styles.link} ${styles['is-analytics']}` : styles.link

  return (
    <NavLink className={className} to={to} exact activeClassName={styles.active}>
      {children}
    </NavLink>
  )
}
