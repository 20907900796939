// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Fc1bGqCmNLLQEmVh6nwoC {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 16px;\n}\n\n._1Fc1bGqCmNLLQEmVh6nwoC > * {\n    flex: 1;\n}\n\n._1Fc1bGqCmNLLQEmVh6nwoC > *:nth-of-type(2) {\n    margin-left: 16px;\n}\n\n._40WSOM0WEuyZzX-GssGz_ {\n    margin-bottom: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"name-input": "_1Fc1bGqCmNLLQEmVh6nwoC",
	"input": "_40WSOM0WEuyZzX-GssGz_"
};
module.exports = exports;
