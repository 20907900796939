import type { FC } from 'react'
import React from 'react'
import { Card } from '@helloextend/merchants-ui'
import { Button } from '@extend/zen'
import { Small } from '@helloextend/merchants-ui/src/components/typography'
import { date } from '@extend/client-helpers'
import { Link } from 'react-router-dom'
import type { MerchantContract } from '../../../types/merchant-contract'
import { useGetPlanByIdQuery } from '../../../queries/plans'
import { getContractPlanCoverageType } from '../../../utils/contract-mappers'
import styles from './contract-card-list-item.module.css'

export const ContractCardListItem: FC<{ contract: MerchantContract }> = ({ contract }) => {
  const { data: plan } = useGetPlanByIdQuery({ id: contract.plan.planId })
  const planCoverageType = plan
    ? getContractPlanCoverageType({
        coverageIncludes: plan.contract.coverage_includes,
        pcmiSubcategory: plan.contract.pcmi_subcategory,
        termLength: plan.contract.term_length,
      })
    : null
  const purchaseDate = contract.transactionDate
    ? date.format(contract.transactionDate, date.standardDateFormat)
    : '--'

  return (
    <Card dataQa={`contract-search-item-${contract.id}`}>
      <div className={styles['flex-wrapper']}>
        <div className={styles.figure}>
          <div className={styles['image-wrapper']}>
            <img
              className={styles.image}
              src={contract.product?.imageUrl}
              alt={contract.product.title}
            />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles['flex-wrapper']}>
            {/*
             * Product Details and Contract Details should
             * be grouped to separate columns in large screens
             */}
            <div className={styles['details-column']}>
              {[
                ['Product Name', 'productTitle', contract.product.title],
                ['Product Serial #', 'productSerialNumber', contract.product.serialNumber],
                ['Transaction ID', 'transactionId', contract.product.transactionId],
                ['Store Name', 'storeName', contract.sellerName],
              ].map(([label, dataQa, value]) => {
                if (!value) return null
                return (
                  <div data-qa={`${contract.id}.${dataQa}`} key={value}>
                    <Small>{label}</Small>
                    <div>{value}</div>
                  </div>
                )
              })}
            </div>
            <div className={styles['details-column']}>
              {[
                ['Contract ID', 'id', contract.id],
                ['Plan Coverage Type', 'planCoverage', planCoverageType],
                ['Purchase Date', 'purchaseDate', purchaseDate],
              ].map(([label, dataQa, value]) => {
                if (!value) return null
                return (
                  <div data-qa={`${contract.id}.${dataQa}`} key={value}>
                    <Small>{label}</Small>
                    <div>{value}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.buttons}>
            <Link to={`/store/claims/new/contracts/${contract.id}/create-claim`}>
              <Button text="Start a Claim" />
            </Link>
            <Link to={`/store/claims/new/contracts/${contract.id}`}>
              <Button emphasis="medium" text="Contract Details" />
            </Link>
          </div>
        </div>
      </div>
    </Card>
  )
}
