import type { LDFlag } from '../constants/ld-flags'
import type { MerchantsEnterpriseUserRole } from '../types/users'

export type RuleDefinition = {
  allowedRoles: MerchantsEnterpriseUserRole[]
  requiredFeatureFlags?: LDFlag[]
  storeRequirements?: StoreRequirement[]
}

export enum StoreRequirement {
  ActiveStore,
  Analytics,
  ClaimsAgent,
  InStoreApp,
}

export enum EnterpriseUserRole {
  MERCHANT_PORTAL_ADMIN = 'MerchantPortalAdmin',
  MERCHANT_API_MANAGER = 'MerchantApiManager',
  MERCHANT_TRANSACTION_MANAGER = 'MerchantTransactionManager',
  MERCHANT_CLAIMS_AGENT = 'MerchantClaimsAgent',
}

export enum Permission {
  ManageStores = 'MANAGE_STORES',
  ViewStores = 'VIEW_STORES',
  ManageStore = 'MANAGE_STORE',
  StoreSettingsView = 'STORE_SETTINGS_VIEW_ONLY',
  StoreSettingsFullAccess = 'STORE_SETTINGS_FULL_ACCESS',
  ProductsView = 'PRODUCTS_VIEW',
  ProductsFullAccess = 'PRODUCTS_FULL_ACCESS',
  StoreClaimsView = 'STORE_CLAIMS_VIEW',
  StoreClaimsFullAccess = 'STORE_CLAIMS_FULL_ACCESS',
  StoreContractsView = 'STORE_CONTRACTS_VIEW',
  StoreContractsViewAndFileClaim = 'STORE_CONTRACTS_VIEW_AND_FILE_CLAIM',
  StoreContractsFullAccess = 'STORE_CONTRACTS_FULL_ACCESS',
  LeadsView = 'LEADS_VIEW',
  LeadsFullAccess = 'LEADS_FULL_ACCESS',
  ViewStoreAnalytics = 'VIEW_STORE_ANALYTICS',
  CustomizeFullAccess = 'CUSTOMIZE_FULL_ACCESS',
  CustomizeView = 'CUSTOMIZE_VIEW',
  ServiceOrderFullAccess = 'SERVICE_ORDER_FULL_ACCESS',
  ManageUsers = 'MANAGE_USERS',
  ManageUsersV3 = 'MANAGE_USERS_V3',
  ManageProfile = 'MANAGE_PROFILE',
  ManageInStore = 'MANAGE_INSTORE',
  IntegrationsView = 'INTEGRATIONS_VIEW',
  ManageMid = 'MANAGE_MID',
}

// ------------------------------------------------------------------------------------------------
// New permission rules should be added as follows:
//  1. Define a permission in the Permission enum
//  2. Add a rule for the new permission in the rules below.
//    - allowedRoles: User roles that a user must be one of for the permission to be granted
//    - requiredFeatureFlags: Feature flags that must be enabled in LaunchDarkly for the permission
//      to be granted
//    - storeRequirements: Store settings that need to be enabled for the permission to be granted
// ------------------------------------------------------------------------------------------------
export const permissions: Record<Permission, RuleDefinition> = {
  // ACTIVE STORE PERMISSIONS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageStore]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.StoreSettingsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.StoreSettingsFullAccess]: {
    allowedRoles: [EnterpriseUserRole.MERCHANT_PORTAL_ADMIN],
    storeRequirements: [StoreRequirement.ActiveStore],
  },
  [Permission.IntegrationsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },
  [Permission.CustomizeView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.CustomizeFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.ViewStoreAnalytics]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.ProductsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.ProductsFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.ServiceOrderFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.StoreClaimsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore, StoreRequirement.ClaimsAgent],
  },

  [Permission.StoreClaimsFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore, StoreRequirement.ClaimsAgent],
  },

  [Permission.StoreContractsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.StoreContractsViewAndFileClaim]: {
    allowedRoles: [EnterpriseUserRole.MERCHANT_CLAIMS_AGENT],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  [Permission.StoreContractsFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },
  [Permission.LeadsView]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },
  [Permission.LeadsFullAccess]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.ActiveStore],
  },

  // ACCOUNT
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageStores]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
  },

  [Permission.ViewStores]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
    ],
  },

  [Permission.ManageUsers]: {
    allowedRoles: [EnterpriseUserRole.MERCHANT_PORTAL_ADMIN],
  },

  [Permission.ManageProfile]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
  },
  // Instore
  [Permission.ManageInStore]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_API_MANAGER,
      EnterpriseUserRole.MERCHANT_CLAIMS_AGENT,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
    storeRequirements: [StoreRequirement.InStoreApp],
  },

  [Permission.ManageMid]: {
    allowedRoles: [
      EnterpriseUserRole.MERCHANT_PORTAL_ADMIN,
      EnterpriseUserRole.MERCHANT_TRANSACTION_MANAGER,
    ],
  },

  [Permission.ManageUsersV3]: {
    allowedRoles: [EnterpriseUserRole.MERCHANT_PORTAL_ADMIN],
  },
}
