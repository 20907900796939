import type { FC } from 'react'
import React from 'react'
import { StandardHeader } from '../../../components/standard-header'
import { TableauAnalytics } from './tableau-analytics'
import styles from './analytics.module.css'

export const Analytics: FC = () => {
  return (
    <div className={styles.container}>
      <StandardHeader pageTitle="Analytics" />
      <TableauAnalytics />
    </div>
  )
}
