import { ServiceOrder } from '@helloextend/extend-api-client'

export const isDepotRepair = (serviceOrder: ServiceOrder): boolean => {
  return (
    serviceOrder.serviceType === 'repair_depot' ||
    serviceOrder.configurations?.repairFulfillmentMethod === 'repair_depot'
  )
}

export const isRepair = (serviceOrder: ServiceOrder): boolean => {
  return (
    serviceOrder.serviceType === 'repair_depot' ||
    serviceOrder.serviceType === 'repair_onsite' ||
    serviceOrder.serviceType === 'repair'
  )
}
