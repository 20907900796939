// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AAzdOaksQjAVcXDLxB6FA {\n    max-width: 1107px;\n}\n\n._1AAzdOaksQjAVcXDLxB6FA > * {\n    margin-bottom: 32px;\n}", ""]);
// Exports
exports.locals = {
	"content": "_1AAzdOaksQjAVcXDLxB6FA"
};
module.exports = exports;
