import type { FC } from 'react'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import { COLOR, Spinner } from '@extend/zen'
import { usePrevious } from '@helloextend/client-hooks'
import { useListInsuranceClaimsQuery } from '@helloextend/extend-api-rtk-query'
import { useAtomValue } from 'jotai/react'
import { useGetContractQuery } from '../../../queries/contract'
import { ClaimDetailsResult } from './claim-details-result'
import { ClaimDetailsError } from './claim-details-error'
import { getClaimDetailUrl, getContractDetailUrl } from '../../../utils/route-helper'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import { getActiveStoreAtom } from '../../../atoms/stores'

export const ClaimDetails: FC = () => {
  const { contractId, claimId } = useParams<{ contractId: string; claimId: string }>()
  const history = useHistory()

  const contractDetailsUrl = getContractDetailUrl(contractId)
  const store = useAtomValue(getActiveStoreAtom)

  const { data, isLoading, error } = useListInsuranceClaimsQuery({
    sellerId: store?.id || '',
    containsClaimId: claimId,
    searchVersion: '2',
  })

  const { data: contractData, isLoading: isContractLoading } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })
  const [claim] = data?.items || []
  const prevIsClaimsLoading = usePrevious<boolean>(isLoading)

  const handleOnClickClaimDetails = (): void => {
    history.push(claim?.id ? getClaimDetailUrl(claimId) : contractDetailsUrl)
  }

  const handleOnClickContractDetails = (): void => {
    history.push(contractDetailsUrl)
  }

  if (isLoading || isContractLoading) {
    return (
      <div className="flex justify-content-center align-self-center height-100 width-100">
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (prevIsClaimsLoading && !isLoading && !claim) {
    return (
      <ClaimDetailsError
        handleOnClickContractDetails={handleOnClickContractDetails}
        contractId={contractId}
        claimId={claimId}
        error={JSON.stringify(error)}
      />
    )
  }

  return (
    <ClaimDetailsResult
      handleOnClickClaimDetails={handleOnClickClaimDetails}
      handleOnClickContractDetails={handleOnClickContractDetails}
      claim={claim}
      contract={contractData}
    />
  )
}
