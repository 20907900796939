import type { Claim, Contract } from '@helloextend/extend-api-client'
import { celebrate } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { StandardHeader } from '../../../components/standard-header'
import { BulletList, DoneMail, GetHelpInverse } from '../../../components/icons'
import { getContractDetailUrl, getContractsUrl } from '../../../utils/route-helper'
import { useAtomValue } from 'jotai/react'
import { getClaimResultMessaging } from '../../../utils/get-claim-result-messaging'
import styles from './claim-details-result.module.css'
import { getActiveStoreAtom } from '../../../atoms/stores'

export interface ClaimDetailsResultProps {
  handleOnClickContractDetails: () => void
  handleOnClickClaimDetails: () => void
  claim: Claim
  contract: Contract | undefined
}

export const ClaimDetailsResult: FC<ClaimDetailsResultProps> = ({
  handleOnClickContractDetails,
  handleOnClickClaimDetails,
  claim,
  contract,
}) => {
  const activeStore = useAtomValue(getActiveStoreAtom)
  const { headerText, titleText, firstListItemText, secondListItemText, thirdListItemText } =
    useMemo(() => getClaimResultMessaging(claim, activeStore?.name ?? ''), [claim])

  const { contractId } = useParams<{ contractId: string; claimId: string }>()
  const crumbs = useMemo(() => {
    return [
      { to: getContractsUrl(), text: 'Contracts' },
      { to: getContractDetailUrl(contractId), text: `Contract ID: ${contractId}` },
      {
        text: `File a Claim`,
      },
    ]
  }, [contractId])

  return (
    <section className={styles.section}>
      {claim.status === 'approved' && celebrate()}
      <header>
        <StandardHeader
          pageTitle={headerText}
          crumbs={crumbs}
          buttons={[
            {
              text: 'Claim Details',
              emphasis: 'medium',
              onClick: handleOnClickClaimDetails,
            },
            {
              text: 'Back to Contract Details',
              onClick: handleOnClickContractDetails,
            },
          ]}
        />
        <p data-cy="claim-id" data-qa="create-claim-header-description">
          <strong>Claim ID:</strong> {claim.id}
        </p>
        {contract && contract.transactionId && (
          <p data-cy="contract-transactionId">
            <strong>Transaction ID:</strong> {contract.transactionId}
          </p>
        )}
      </header>

      <div className={styles['message-container']}>
        <h2>{titleText}</h2>
        <ul>
          <li>
            <DoneMail />
            {firstListItemText}
          </li>
          <li>
            <BulletList />
            {secondListItemText}
          </li>
          <li>
            <GetHelpInverse />
            {thirdListItemText}
          </li>
        </ul>
      </div>
    </section>
  )
}
