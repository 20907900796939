import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { TableauEmbed } from './tableau-embed'
import { useMerchantReport } from '../../../queries/reports'

export type MerchantReportProps = {
  storeId: string
  reportId: string
}

const MerchantReport: FC<MerchantReportProps> = ({ storeId, reportId }) => {
  const [shouldFetchReport, setShouldFetchReport] = useState(false)
  const {
    isLoading,
    error,
    data: report,
  } = useMerchantReport({
    storeId,
    reportId,
    enabled: shouldFetchReport,
  })
  useEffect(() => {
    setShouldFetchReport(true)
  }, [setShouldFetchReport])

  return (
    <TableauEmbed
      reportUrl={report?.url}
      token={report?.token}
      storeId={storeId}
      isLoading={isLoading}
      isError={Boolean(error)}
      isSuccess={Boolean(report)}
    />
  )
}

export { MerchantReport }
