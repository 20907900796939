import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { currency } from '@extend/client-helpers'
import {
  Modal,
  ModalController,
  Checkbox,
  useToaster,
  ToastDuration,
  ToastColor,
} from '@extend/zen'
import { useReportRefundByIdMutation } from '../queries/refunds'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../constants/const'
import styles from './refund-modal.module.css'

export type RefundModalProps = {
  isVisible: boolean
  contractId: string
  amount: number | undefined
  closeModal: () => void
}

export const RefundModal: FC<RefundModalProps> = ({
  isVisible,
  contractId,
  amount,
  closeModal,
}) => {
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false)
  const { mutateAsync: reportRefundById, isLoading } = useReportRefundByIdMutation()
  const { toast } = useToaster()

  const submitUserRefund = async (): Promise<void> => {
    try {
      await reportRefundById({ contractId, contractsApiVersion: CONTRACTS_API_VERSION_FEB_2022 })
      toast({
        message: 'Refund reported',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      closeModal()
    } catch {
      toast({
        message: `The refund couldn’t be reported. Please try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const toggleCheckBox = (): void => {
    setIsCheckBoxSelected(!isCheckBoxSelected)
  }

  const handleCloseModal = (): void => {
    closeModal()
    setIsCheckBoxSelected(false)
  }

  const refundAmount = useMemo(() => {
    if (typeof amount !== 'number') {
      return '–'
    }

    return currency.format(amount)
  }, [amount])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Refund Instructions"
        primaryButtonProps={{
          onClick: submitUserRefund,
          text: 'Report Refund',
          isDisabled: !isCheckBoxSelected,
          isProcessing: isLoading,
          type: 'button',
        }}
        secondaryButtonProps={{
          onClick: handleCloseModal,
          text: 'Cancel',
        }}
      >
        <p className={styles['refund-amount-text']}>
          <strong>Amount to Refund Customer:</strong> {refundAmount}
        </p>
        <p className={styles['detail-text']}>
          Refund your customer the amount listed above via your own payment system, then click
          Report Refund to notify Extend. Extend will credit you the same amount at the end of the
          current invoice cycle.
        </p>
        <Checkbox
          checked={isCheckBoxSelected}
          name="submitContractRefund"
          disabled={isLoading}
          onChange={toggleCheckBox}
          label="I refunded my end customer. I’m ready to cancel the customer’s contract and prevent
            any further claims from being filed against it."
        />
      </Modal>
    </ModalController>
  )
}
