// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3i5Ivl9qkkTQJLDWcG4rsO {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._2kafP6WB_tRJXcZiLzJzT- {\n    max-width: 816px;\n    margin: auto;\n}\n\n._2kafP6WB_tRJXcZiLzJzT- h2 {\n    text-align: center;\n    margin: 0 auto 32px auto;\n    width: 493px;\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "_3i5Ivl9qkkTQJLDWcG4rsO",
	"wrapper": "_2kafP6WB_tRJXcZiLzJzT-"
};
module.exports = exports;
