import type { FC } from 'react'
import React from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useDeactivateUserMutation } from '../../queries/users-v3'
import {
  isDeactivateUserModalVisible,
  closeDeactivateUserModal,
  deactivateUserModalGrantsAtom,
} from '../../atoms/deactivate-user-modal'
import styles from './deactivate-user-modal.module.css'

export const DeactivateUserModal: FC = () => {
  const { toast } = useToaster()
  const isVisible = useAtomValue(isDeactivateUserModalVisible)
  const close = useSetAtom(closeDeactivateUserModal)

  const grants = useAtomValue(deactivateUserModalGrantsAtom)
  const { mutateAsync: deactivateUser, isLoading: isDeactivating } = useDeactivateUserMutation()

  const handleClickDeactivateUser = async (): Promise<void> => {
    try {
      await deactivateUser({ grants })
      toast({
        message: 'User deactivated',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      close()
    } catch (err) {
      toast({
        message: `The user couldn’t be deactivated. Try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="deactivate-user-modal"
        heading="Deactivate User"
        primaryButtonProps={{
          onClick: handleClickDeactivateUser,
          color: 'red',
          text: 'Deactivate User',
          isProcessing: isDeactivating,
        }}
        secondaryButtonProps={{
          onClick: close,
          text: 'Cancel',
        }}
      >
        <p className={styles.detail}>
          This will remove the user&apos;s access to the Merchant Portal and assigned role(s).
        </p>
        <p className={styles.detail}> Are you sure you want to deactivate this user?</p>
      </Modal>
    </ModalController>
  )
}
