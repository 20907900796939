import { useMemo } from 'react'
import type { Contract } from '@helloextend/extend-api-client'
import { ContractStatus, ContractType } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useGetEntitlementsCategoryQuery } from '../queries/entitlements'

type LimitOfLiabilityType = {
  remainingAmount: string
  isLoading: boolean
}

const INVALID_CONTRACT_STATUSES = [
  ContractStatus.CANCELLED,
  ContractStatus.EXPIRED,
  ContractStatus.FULFILLED,
]

export const useCategoryLOLRemaining = (
  contract: Contract,
  lineItemId: string,
): LimitOfLiabilityType => {
  const { data: entitlements, isLoading } = useGetEntitlementsCategoryQuery({
    contractId: contract.id,
    lineItemId,
    enabled: Boolean(lineItemId) && contract.type === ContractType.CATEGORY,
  })

  const remainingAmount = useMemo(() => {
    const amount = entitlements?.coverageAmountRemaining?.amount
    const currencyCode = entitlements?.coverageAmountRemaining?.currencyCode

    if (!amount || INVALID_CONTRACT_STATUSES.includes(contract.status)) {
      return `0.00 ${currencyCode}`
    }
    return `${currency.formatWithoutCurrencySymbol(amount, currencyCode)}`
  }, [entitlements, contract])

  return {
    remainingAmount,
    isLoading,
  }
}
