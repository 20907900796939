// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1g8JvqMDLCLbaDwgAEOqjv {\n    background-color: var(--neutral-100);\n    min-height: 650px;\n    min-width: 450px;\n    padding: 40px 12%;\n    display: flex;\n    justify-content: center;\n}\n\n._9YDMBvqoboAJQH1MZRfX3 {\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n}\n\n._3H5AfInmpgqzFKw-3efguR {\n    margin-top: 24px;\n    margin-bottom: 16px;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 28px;\n}\n\n._1ak62_hKFOnFbzy-AxzLqB {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: center;\n    margin-top: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"background": "_1g8JvqMDLCLbaDwgAEOqjv",
	"offer": "_9YDMBvqoboAJQH1MZRfX3",
	"title": "_3H5AfInmpgqzFKw-3efguR",
	"description": "_1ak62_hKFOnFbzy-AxzLqB"
};
module.exports = exports;
