// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ygsDoUVtPmWehk2FnDZYp {\n    text-align: center;\n    margin-top: 105px;\n}\n\n._2xPejizV5c_L1INVfLlc_m {\n    color: var(--blue-1000);\n    font-size: 32px;\n    margin-bottom: 8px;\n}\n\n._34cCaCTl_rh1SA7g8KhnE_ {\n    width: 448px;\n    margin: 0 auto;\n}\n\n._2qlf7F9ZNlZZxKIomtN-EC {\n    margin-top: 48px;\n    display: flex;\n    justify-content: center;\n}\n\n._2qlf7F9ZNlZZxKIomtN-EC input[type='text'] {\n    width: 300px;\n}\n\n@media (min-width: 1025px) {\n    ._2qlf7F9ZNlZZxKIomtN-EC {\n        width: 432px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"page": "_1ygsDoUVtPmWehk2FnDZYp",
	"header": "_2xPejizV5c_L1INVfLlc_m",
	"description": "_34cCaCTl_rh1SA7g8KhnE_",
	"search": "_2qlf7F9ZNlZZxKIomtN-EC"
};
module.exports = exports;
