import type { FC } from 'react'
import React, { useMemo, useCallback } from 'react'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import { useAtom, useSetAtom } from 'jotai/react'
import { SaveBanner } from '../../../components/save-banner'
import {
  isRenameThemeModalVisibleAtom,
  hasChangesToPublishedThemeAtom,
  createThemeNameAtom,
} from '../../../atoms/customize-theme'

export type CustomizeSaveBannerProps = {
  onDiscardChanges: () => void
  onSaveChanges: () => void
  isVisible: boolean
  themeStatus?: ThemePublishedStatus
  isProcessing: boolean
}

export const CustomizeSaveBanner: FC<CustomizeSaveBannerProps> = ({
  onDiscardChanges,
  onSaveChanges,
  isVisible,
  themeStatus = ThemePublishedStatus.unpublished,
  isProcessing,
}) => {
  const [isRenameThemeModalVisible, setIsRenameThemeModalVisible] = useAtom(
    isRenameThemeModalVisibleAtom,
  )
  const [createThemeName] = useAtom(createThemeNameAtom)
  const setHasChangesToPublishedTheme = useSetAtom(hasChangesToPublishedThemeAtom)

  const handleSaveChanges = useCallback(() => {
    if (themeStatus === ThemePublishedStatus.published && !createThemeName) {
      setIsRenameThemeModalVisible(!isRenameThemeModalVisible)
      setHasChangesToPublishedTheme(true)
    } else {
      onSaveChanges()
    }
  }, [
    themeStatus,
    onSaveChanges,
    setIsRenameThemeModalVisible,
    isRenameThemeModalVisible,
    setHasChangesToPublishedTheme,
    createThemeName,
  ])

  const bannerText = useMemo(
    () =>
      themeStatus === ThemePublishedStatus.published
        ? {
            saveButtonText: 'Save As New',
            message: 'Save to create a new theme',
          }
        : {},
    [themeStatus],
  )
  return (
    <SaveBanner
      onSaveChanges={handleSaveChanges}
      onDiscardChanges={onDiscardChanges}
      isProcessing={isProcessing}
      isVisible={isVisible}
      {...bannerText}
    />
  )
}
