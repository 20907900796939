import * as Yup from 'yup'
import type { ThemeMinimal } from '@helloextend/extend-api-rtk-query'

const normalizeThemeName = (themeName: string): string => {
  return themeName.replace(/ /g, '').toUpperCase().trim()
}

const getThemeNames = (merchantThemes: ThemeMinimal[]): string[] => {
  return merchantThemes.map((theme) => {
    return normalizeThemeName(theme.name)
  })
}

const themeNameValidations = (merchantThemes: ThemeMinimal[]): Yup.ObjectSchema => {
  return Yup.object().shape({
    name: Yup.string()
      .max(255, 'Maximum allowed characters is 255')
      .test('unique-name', 'Please choose a unique theme name', (value): boolean => {
        if (merchantThemes.length !== 0 && value) {
          const themeNamesList = getThemeNames(merchantThemes)
          const normalizedName = normalizeThemeName(value)
          return !themeNamesList.includes(normalizedName)
        }
        return true
      })
      .required('Please enter a new theme name'),
  })
}

export { normalizeThemeName, themeNameValidations, getThemeNames }
