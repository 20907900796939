import type {
  PhotosetRequest,
  NumericInvokableFunction,
  RulesetCreate,
} from '@extend-conversations/types'
import type { Operation } from 'fast-json-patch'

export interface ConversationModel {
  id: string
  createdAt: string
  updatedAt: string
  editedBy: string
  version: number
  isPublished: boolean
  title: string
  description: string
  threads: string[]
}

export interface ThreadModel {
  id: string
  type: ThreadTypes
  version: number
  title: string
  editedBy: string
  isPublished: boolean
  createdAt: string
  updatedAt: string
}

export type Source = 'merch_admin' | 'ops_admin' | 'chatbot' | 'admin'
export type ThreadTypes = 'outro' | 'intro' | 'adjudication' | 'troubleshooting' | 'single_use'
export type Action = 'next' | 'repeat' | 'replace' | 'execute' | 'stop'
export type PromptType =
  | 'input'
  | 'addressInput'
  | 'buttons'
  | 'carousel'
  | 'orderCarousel'
  | 'datepicker'
  | 'imageUpload'
  | 'multiselect'
  | 'addressVerification'
  | 'shipmentSelect'

export interface ScriptItem {
  reply?: Reply
  collect: Collect
}

export type Collect = {
  needs?: Need[]
  options: Option[]
}

export interface Option {
  default?: boolean
  patterns?: Pattern[]
  action: Exclude<Action, 'inject' | 'replace'>
  execute?: Execute
}

export interface Pattern {
  input?: string | number
  params?: {
    [key: string]: string | number | boolean
  }
}

export interface DialogPosition {
  scriptIndex: number
}

export interface Execute extends DialogPosition {
  forward?: Forward[]
}

export type Forward =
  | {
      key: string
      path: string
    }
  | {
      key: string
      value: string | boolean | number
    }

// a robot's reply when the user sends claim information
export type Reply = ReplacementReply | DefaultReply

export interface ReplacementReply extends Replacement {}

export interface DefaultReply {
  needs?: Need[]
  messages: Message[]
  prompt?: Prompt
  poll?: Poll
  claimId?: string
}

export type Prompt = Replacement | DefaultPrompt

export interface DefaultPrompt {
  slot: string
  type: PromptType
  placeholder?: string
  validationType?: 'email' | 'phone' | 'number'
  options?: PromptOption[]
  card?: Replacement
  presignedPost?: Replacement
}

export interface PromptOption {
  title: string
  value: string
  outputText?: string
  redirectUrl?: string
}

export interface Poll {
  url: string
  accessToken?: string
}

export type Message = ReplacementMessage | DefaultMessage

export interface ReplacementMessage extends Replacement {}

export interface DefaultMessage {
  type: MessageType
  content: string
  imageUrl?: string
}

export type Expects = {
  type: 'string' | 'number' | 'boolean' | 'object'
  schema?: string
}

export interface LookupNeed {
  lookup: string
  expects: Expects
}

export interface InvokeNeed {
  invoke: string
  expects: Expects
}

export type Need = LookupNeed | InvokeNeed

export interface Replacement {
  action: 'replace'
  replace: {
    invoke: string
    expects: Expects
    arguments?: Record<string, string | boolean | number>
  }
}

export enum MessageType {
  text = 'text',
  textSelect = 'textSelect',
  image = 'image',
}

export interface ConversationCreateRequest {
  title: string
  description: string
  threads: string[]
}

export interface ConversationUpdateRequest {
  conversation: Omit<ConversationCreateRequest, 'title'>
}

export interface ConversationUpdateOptimisticRequest {
  conversationId: string
  conversation: ConversationResponse
}

export type ConversationStatus = 'published' | 'draft' | 'archived'

export interface ConversationResponse extends Omit<ConversationModel, 'isPublished'> {
  status: ConversationStatus
}

export interface ThreadCreateRequest {
  type: ThreadTypes
  title: string
  ownedBy?: string
  script: ScriptItem[]
}

export type ThreadStatus = 'pending_changes' | 'published' | 'draft' | 'archived' | 'override'

export interface ThreadResponseBase extends Omit<ThreadModel, 'isPublished'> {
  status: ThreadStatus
}

export interface ThreadListItem extends Omit<ThreadResponseBase, 'version'> {}

export interface ThreadResponse extends ThreadResponseBase {
  script: ScriptItem[]
}

export interface ThreadUpdateBody extends ThreadCreateRequest {
  status: ThreadStatus
  version: number
}

export interface ThreadUpdateRequest {
  threadId: string
  thread: ThreadUpdateBody
}

export interface UsedByResponse {
  title: string
  status: ConversationStatus
}

export interface ThreadPublishRequest {
  threadId: string
  version: number
}

export interface ConversationPublishRequest {
  conversationId: string
  version: number
}

export enum AdjudicationCategory {
  adjustable_bed = 'adjustable_bed',
  area_rug = 'area_rug',
  audio_equipment = 'audio_equipment',
  auto_parts = 'auto_parts',
  bikes_ebikes = 'bikes_ebikes',
  camping = 'camping',
  consumer_electronics = 'consumer_electronics',
  drones = 'drones',
  eyewear = 'eyewear',
  general_sporting_goods = 'general_sporting_goods',
  grills = 'grills',
  gym_equipment = 'gym_equipment',
  indoor_furniture = 'indoor_furniture',
  iRobot = 'iRobot',
  jewelry = 'jewelry',
  laptops_tablets_desktops = 'laptops_tablets_desktops',
  luggage_bags = 'luggage_bags',
  mattress = 'mattress',
  mattress_protector = 'mattress_protector',
  musical_instrument = 'musical_instrument',
  outdoor_furniture = 'outdoor_furniture',
  shipping_protection = 'shipping_protection',
  tire = 'tire',
  vehicle_accessories = 'vehicle_accessories',
  watch = 'watch',
  wheel = 'wheel',
  wheel_tire = 'wheel_tire',
}

export interface ConversationConfigurationResponse {
  category: AdjudicationCategory
  conversationId: string
  createdAt: string
  editedBy: string
  updatedAt: string
  id: string
  storeId?: string
  planId?: string
}

export interface ConversationConfigCreateRequest {
  category: AdjudicationCategory
  conversationId: string
  storeId?: string
  planId?: string
}

export type ConversationConfigUpdateRequest = Pick<
  ConversationConfigCreateRequest,
  'conversationId'
> & {
  id: string
}

export interface PhotoCreateResponse {
  id: string
}

export interface PhotoCreateRequest {
  photo: string
}

export interface RulesetBase {
  id: string
  approveRules: RuleCreate[]
  denyRules: RuleCreate[]
  reviewRules: RuleCreate[]
}

export interface RulesetResponse extends RulesetBase {
  type: RulesetType
  version: number
  editedBy: string
  createdAt: string
  updatedAt: string
  isDeleted: boolean
}

export interface RuleResponse {
  id: string
  status: RuleStatus
  index: number
  conditions: Condition[]
  version: number
  createdAt: string
  updatedAt: string
}

export enum RulesetType {
  conversation = 'CONVERSATION',
  thread = 'THREAD',
}

export interface RuleCreate {
  conditions: Condition[]
}

export interface AdjudicationRuleRequest {
  rulesetId: string
  ruleId: string
  version: number
  expand?: boolean
}

export type AdjudicationRuleResponseExpanded = RuleResponse & {
  conditions: ScriptItem[]
}

export type AdjudicationRuleResponse = RuleResponse | AdjudicationRuleResponseExpanded

export type RuleStatus = 'approved' | 'denied' | 'review'

export type ConditionTypes =
  | 'nonNumericComparand'
  | 'nonNumericScript'
  | 'numericScript'
  | 'numericValue'

export type NumericCondition = NumericConditionWithScript | NumericConditionWithValue

export interface NumericConditionWithScript {
  script: number | ScriptItem // added ScriptItem to keep type consistent. In UI will always be a number
  value?: never
  comparand: NumericInvokableFunction
  operator: Operator
  offset?: number
}

export interface NumericConditionWithValue {
  script?: never
  value: number
  comparand: NumericInvokableFunction
  operator: Operator
  offset?: number
}

export type NonNumericCondition = NonNumericConditionWithComparand | NonNumericConditionWithScript

export interface NonNumericConditionWithComparand {
  script?: never
  value: string[] | boolean
  comparand: NonNumericInvokableFunction
}

export interface NonNumericConditionWithScript {
  script: number | ScriptItem // added ScriptItem to keep type consistent. In UI will always be a number
  value: string[]
  comparand?: never
}

export type Condition = NumericCondition | NonNumericCondition

export type NonNumericInvokableFunction = 'planHasCoverageType'

export type { NumericInvokableFunction }

export type Operator = '>' | '>=' | '=' | '<=' | '<'

export type ThreadRulesetCreateRequest = RulesetCreate

export interface CreateThreadRulesetRequest {
  threadId: string
  data: ThreadRulesetCreateRequest
}

export interface CreatePhotosetRequest {
  threadId: string
  data: PhotosetRequest
}

export interface UpdatePhotosetRequest extends CreatePhotosetRequest {}

export interface UpdateThreadRulesetRequest extends CreateThreadRulesetRequest {}

export interface ConversationPatchRequest {
  conversationId: string
  patches: Operation[]
}

export interface ThreadPatchRequest {
  threadId: string
  patches: Operation[]
}
