import type { FC } from 'react'
import React, { useCallback } from 'react'
import {  useAtomValue, useSetAtom } from 'jotai/react'
import { ButtonProps, Add } from '@extend/zen'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import { LDFlag } from '../../../constants/ld-flags'
import { StandardHeader } from '../../../components/standard-header'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { useExportContractsMutation } from '../../../queries/contract'
import { CreateContractsModalController } from './create/create-contracts-modal-controller'
import { contractCreatedAtom, isModalVisibleAtom } from '../../../atoms/create-contracts-modal'
import { ContractCreatedAlert } from './contract-created-alert'

export const ContractsHeader: FC = () => {
  const { [LDFlag.CreateContracts]: FF_CREATE_CONTRACTS } = useFlags()
  const { hasPermission } = usePermissions()
  const setIsCreateContractsModalOpen = useSetAtom(isModalVisibleAtom)

  const { toast } = useToaster()
  const store = useAtomValue(getActiveStoreAtom)
  const storeId = store?.id || ''
  const contractCreatedInfo = useAtomValue(contractCreatedAtom)

  const { mutateAsync: exportContracts, isLoading } = useExportContractsMutation()

  const handleClick = useCallback(async () => {
    try {
      const response = await exportContracts({ storeId })
      if ('message' in response && response.message === 'Job Started') {
        toast({
          message:
            'Your data is being prepared. You will receive an email with a download link shortly.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.blue,
        })
      } else {
        toast({
          message:
            'You can only download a new export once every 24 hours. Check your email to download your recent export.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.yellow,
        })
      }
    } catch (err) {
      toast({
        message: 'There was an error exporting contracts, please try again later.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [exportContracts, storeId, toast])

  const buttons: ButtonProps[] = [
    {
      onClick: handleClick,
      text: 'Export Contracts',
      emphasis: 'medium',
      isProcessing: isLoading,
    },
  ]

  if (!(FF_CREATE_CONTRACTS && hasPermission(Permission.StoreContractsFullAccess))) {
    return <StandardHeader pageTitle="Contracts" buttons={buttons} />
  }

  buttons.unshift({
    onClick: () => setIsCreateContractsModalOpen(true),
    text: 'Create Contracts',
    emphasis: 'medium',
    isProcessing: false,
    icon: Add,
    iconPosition: 'left',
  })

  return (
    <>
      <StandardHeader pageTitle="Contracts" buttons={buttons} />
      {contractCreatedInfo && (<ContractCreatedAlert />)}
      <CreateContractsModalController />
    </>
  )
}
