import type { FC } from 'react'
import React from 'react'
import { Button } from '@extend/zen'
import styles from './save-banner.module.css'

interface SaveBannerProps {
  onSaveChanges: () => void
  onDiscardChanges: () => void
  isProcessing: boolean
  saveButtonText?: string
  message?: string
  isVisible: boolean
}

export const SaveBanner: FC<SaveBannerProps> = ({
  onSaveChanges,
  onDiscardChanges,
  isProcessing,
  saveButtonText = 'Save Changes',
  message = `Don't forget to save your changes`,
  isVisible,
}) => {
  const bannerClass = `${styles['save-banner']} ${isVisible ? '' : styles.invisible}`.trim()

  return (
    <div className={bannerClass}>
      <div className={styles.section}>
        <div className={styles.end}>
          <Button
            onClick={onDiscardChanges}
            text="Discard Changes"
            emphasis="low"
            isInverted
            color="neutral"
            isDisabled={isProcessing}
          />
          <Button
            onClick={onSaveChanges}
            text={saveButtonText}
            emphasis="medium"
            isInverted
            color="neutral"
            isProcessing={isProcessing}
          />
        </div>
      </div>
      <div className={styles.section}>
        <span className={styles.header}>{message}</span>
      </div>
      <div className={styles.section}>
        <div className={styles.start} />
      </div>
    </div>
  )
}
