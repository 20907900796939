// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1sT9Nxd-YWHAqXsFNI4Iob {\n    width: 440px;\n}\n\n.Hwli-A4vpxOV0jwxMFECA {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-1000);\n    margin: 24px 0 4px;\n}\n\n._18d9ISQRAyhLgGNpf8cxc3 {\n    border: 1px solid var(--neutral-300);\n    border-radius: 4px;\n    height: 22px;\n    display: flex;\n    align-items: center;\n    padding: 8px 12px;\n    cursor: pointer;\n}\n\n._18d9ISQRAyhLgGNpf8cxc3 > span {\n    width: 100%;\n}\n\n._25SzXG2aEnlhkv4_I1HN8 {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n._3KGb3IQFZvPZfEQS7_x8Ge {\n    display: inline-block;\n    transition: transform .2s ease-in;\n    transform: scale(0.8);\n    margin-left: 16px;\n}\n\n._3KGb3IQFZvPZfEQS7_x8Ge._16eEv-zyD1fZqxNLNU6GN8 {\n    transform: scale(0.8) rotate(180deg);\n}\n\n._2mN28Zq6hE4ugSpEinANJo {\n    display: flex;\n    gap: 8px;\n    width: 400px;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1sT9Nxd-YWHAqXsFNI4Iob",
	"title": "Hwli-A4vpxOV0jwxMFECA",
	"theme-selector": "_18d9ISQRAyhLgGNpf8cxc3",
	"item": "_25SzXG2aEnlhkv4_I1HN8",
	"caret": "_3KGb3IQFZvPZfEQS7_x8Ge",
	"is-open": "_16eEv-zyD1fZqxNLNU6GN8",
	"menu-item": "_2mN28Zq6hE4ugSpEinANJo"
};
module.exports = exports;
