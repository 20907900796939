import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { ClaimSelectStatus, ContractStatus, ContractType } from '@helloextend/extend-api-client'
import { CONTRACT_TYPE_NAMES } from '@helloextend/extend-api-client/src/models/contract'
import { useHistory, useParams } from 'react-router-dom'
import { Information, LoadingText } from '@extend/zen'
import { useAtom, useAtomValue } from 'jotai/react'
import type { PrecheckResponse } from '@helloextend/extend-api-rtk-query'
import { usePrecheckQuery } from '@helloextend/extend-api-rtk-query'
import styles from './contract-header.module.css'
import { getContractStatusCopy, statusDescriptions } from '../../../utils/contract-status'
import { RefundModal } from '../../../components/refund-modal'
import { HeaderLabel } from '../../../components/header-label'
import { StandardHeader } from '../../../components/standard-header'
import type { TabBarLinkDefinition } from '../../../components/tab-bar'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import { contractBreadcrumbsAtom } from '../../../atoms/contract-breadcrumb'
import { useLimitOfLiability } from '../../../hooks/use-limit-of-liability'
import { useRefundModal } from '../../../hooks/use-refund-modal'
import { useGetContractHeaderButtons } from '../../../hooks/use-get-contract-header-buttons'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import {
  contractDetailsAtom,
  isUserEditingContractDetailFormAtom,
} from '../../../atoms/contract-details'
import { useClaimsSearch } from '../../../hooks/use-claims-search'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

export const ContractHeader: FC<{ isClaimsManagementEnabled: boolean }> = ({
  isClaimsManagementEnabled,
}) => {
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const contract = useAtomValue(contractDetailsAtom)
  const isUserEditingContractDetailForm = useAtomValue(isUserEditingContractDetailFormAtom)
  const { data, isFetching, isOpen, closeModal, openModal } = useRefundModal({
    contractId: contract.id,
  })
  const history = useHistory()

  const statusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getContractStatusCopy(contract.status)
  }, [contract])

  const handleFileClaim = useCallback((): void => {
    history.push(`/store/contracts/${contract.id}/claims/new`)
  }, [history, contract.id])

  const isContractActive =
    contract.status === ContractStatus.LIVE ||
    contract.status === ContractStatus.CREATED ||
    contract.status === ContractStatus.DELIVERED
  const isProductProtectionContract = contract.type === ContractType.PCRS

  const [contractsUrl] = useAtom(contractBreadcrumbsAtom)
  const { contractId } = useParams<{ contractId: string }>()

  const {
    remainingAmount,
    coverageAmount,
    isLoading: isLoadingEntitlements,
    productPriceType,
  } = useLimitOfLiability(contract)
  const { hasPermission } = usePermissions()
  const userHasContractClaimFilingPermission = hasPermission(
    Permission.StoreContractsViewAndFileClaim,
  )
  const userHasFullContractPermission = hasPermission(Permission.StoreContractsFullAccess)

  const { data: precheckResponse } = usePrecheckQuery({
    contractId,
  })

  const shouldClaimBeDisabled =
    isUserEditingContractDetailForm || !precheckResponse || precheckResponse?.status === 'failure'

  const pageTitle = `${contract.customer.name}'s Contract`

  const crumbs = useMemo(() => {
    return [
      { to: contractsUrl || '/store/contracts', text: 'Contracts' },
      {
        text: pageTitle,
      },
    ]
  }, [contractsUrl, pageTitle])

  const tabBarLinks: TabBarLinkDefinition[] = [
    { to: `/store/contracts/${contract.id}`, text: 'Contract Details' },
  ]

  if (isClaimsManagementEnabled) {
    tabBarLinks.push({ to: `/store/contracts/${contract.id}/claims`, text: 'Claims' })
  }

  const getTooltip = (precheck: PrecheckResponse | undefined): string => {
    if (!precheck || precheck.status === 'success') return ''
    switch (precheck.validationError) {
      case 'active_claim_found':
        return 'A claim is already in progress for this contract.'
      case 'no_coverage_remaining':
        return 'There is no remaining coverage available'
      case 'expired':
        return 'This contract has expired.'
      case 'not_yet_active':
        return 'This contract is not yet active.'
      case 'service_type_invalid':
        return 'This contract does not support claims.'
      case 'mfr_warranty_active':
        return 'The manufacturer warranty is still active.'
      default:
        return ''
    }
  }

  const { tableData: claims, isLoading: isClaimsSearchLoading } = useClaimsSearch({
    queryParams: { containsContractId: contractId, sellerId: storeId },
  })

  const hasActiveClaim = useMemo(() => {
    const validClaimStatuses = new Set([
      ClaimSelectStatus.review,
      ClaimSelectStatus.approved,
      ClaimSelectStatus.pending_adjudication,
    ])
    return Boolean(
      claims?.some((claim) => validClaimStatuses.has(claim.status as ClaimSelectStatus)),
    )
  }, [claims])

  const shouldRefundBeDisabled = useMemo(() => {
    return Boolean(!isContractActive || isClaimsSearchLoading || hasActiveClaim)
  }, [contract, isClaimsSearchLoading, hasActiveClaim])

  const getContractHeaderButtons = useGetContractHeaderButtons({
    contractType: contract.type,
    isClaimsManagementEnabled,
    isContractActive,
    handleRefundModalRequest: openModal,
    isEditing: isUserEditingContractDetailForm,
    isLoading: isFetching,
    handleFileClaim,
    shouldClaimBeDisabled,
    userHasContractClaimFilingPermission,
    userHasFullContractPermission,
    tooltip: getTooltip(precheckResponse),
    shouldRefundBeDisabled,
  })

  return (
    <StandardHeader
      data-cy="contract-header"
      pageTitle={pageTitle}
      pageTitleBadge={{
        badgeText: statusBadgeDescription?.text || '',
        badgeColor: statusBadgeDescription?.color || 'neutral',
        badgeDescriptions: statusDescriptions,
      }}
      crumbs={crumbs}
      buttons={getContractHeaderButtons}
      tabBarLinks={tabBarLinks}
    >
      <RefundModal
        isVisible={isOpen}
        contractId={contract.id}
        amount={data?.refundAmounts?.customer}
        closeModal={closeModal}
      />
      <div className={styles['contract-line']}>
        <HeaderLabel labelText="ID:" />
        <span data-cy="contract-id-line">{contract.id}</span>
      </div>
      {isProductProtectionContract && contract.limitOfLiabilityAmount && (
        <div className={styles['product-line']} data-cy="remaining-coverage-line">
          <div className={styles['info-label']}>Remaining Coverage</div>
          <Information data-cy="coverage-info">
            <p>The amount of coverage remaining out of the coverage limit</p>
            <p>This contract’s coverage limit is based on Product {productPriceType} Price</p>
          </Information>
          {isLoadingEntitlements ? (
            <LoadingText />
          ) : (
            <>
              {remainingAmount}
              &nbsp;
              <span className={styles['remaining-amount']} data-cy="remaining-coverage">
                {coverageAmount}
              </span>
            </>
          )}
        </div>
      )}
      <div className={styles['type-line']}>
        <HeaderLabel labelText="Type:" />
        <span data-cy="contract-type">{CONTRACT_TYPE_NAMES[contract.type].long}</span>
      </div>
    </StandardHeader>
  )
}
