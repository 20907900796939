import type { FC } from 'react'
import React, { useState } from 'react'
import { useAtom } from 'jotai/react'
import {
  Modal,
  ModalController,
  ToastColor,
  ToastDuration,
  useToaster,
  Checkbox,
} from '@extend/zen'
import styles from './delete-integration-modal.module.css'
import { useDeleteOAuthClientMutation } from '../../queries/oauth-client'
import { deleteApiIntegrationAtom } from '../../atoms/delete-api-integration'

export const DeleteIntegrationModal: FC = () => {
  const { toast } = useToaster()
  const { mutateAsync: deleteOAuthClient, isLoading: isDeleting } = useDeleteOAuthClientMutation()
  const [isChecked, setIsChecked] = useState(false)

  const [deleteApiIntegration, setDeleteApiIntegration] = useAtom(deleteApiIntegrationAtom)
  const isModalVisible = Boolean(deleteApiIntegration)

  if (!deleteApiIntegration) return null

  const { clientId, name } = deleteApiIntegration

  const handleCloseModal = (): void => {
    setIsChecked(false)
    setDeleteApiIntegration(null)
  }

  const handleClickDeleteIntegration = async (): Promise<void> => {
    try {
      await deleteOAuthClient({ id: clientId })
      toast({
        message: 'Integration deleted successfully',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      handleCloseModal()
    } catch (err) {
      toast({
        message: `We encountered an error while deleting this integration. Please try again.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isModalVisible}>
      <Modal
        data-cy="delete-integration-modal"
        heading="Delete this integration?"
        primaryButtonProps={{
          onClick: handleClickDeleteIntegration,
          color: 'red',
          text: 'Delete Integration',
          isProcessing: isDeleting,
          isDisabled: !isChecked,
        }}
        secondaryButtonProps={{
          onClick: handleCloseModal,
          text: 'Cancel',
        }}
        onDismissRequest={handleCloseModal}
      >
        <div className={styles.container}>
          <p>
            You are about to permanently delete <b>{name}</b>.
          </p>

          <ul>
            <li>
              <p>
                This OAuth Client can no longer be used to access the Extend platform. Any systems
                using these credentials will lose access to Extend APIs.
              </p>
            </li>
            <li>
              <p>
                This action cannot be undone. This Client ID will be permanently deleted and cannot
                be recovered.
              </p>
            </li>
          </ul>

          <Checkbox
            label="I acknowledge all of the consequences of this action"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </div>
      </Modal>
    </ModalController>
  )
}
