// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1iUGUwdTAHl7bvQvNvO0HW {\n    margin-bottom: 32px;\n}\n\n._1iUGUwdTAHl7bvQvNvO0HW svg {\n    margin: 0 12px;\n}\n\n.QPHnmqjZCVKYvJGqcy1g5 {\n    margin: 0 auto;\n    max-width: 826px;\n    text-align: center;\n}\n\n._8HT_a7Oi7kpIClhVVtj4d {\n    margin: 40px 0 56px;\n    font-size: 20px;\n}\n\n._8HT_a7Oi7kpIClhVVtj4d a {\n    color: var(--bright-blue);\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"icons": "_1iUGUwdTAHl7bvQvNvO0HW",
	"wrapper": "QPHnmqjZCVKYvJGqcy1g5",
	"body": "_8HT_a7Oi7kpIClhVVtj4d"
};
module.exports = exports;
