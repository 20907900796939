import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useMemo } from 'react'
import { useAtomValue } from 'jotai/react'
import { generateSchema } from '../schemas/service-order-schema'
import { contractDetailsAtom } from '../atoms/contract-details'
import { claimDetailsAtom } from '../atoms/claim-details'

export const useGetAcceptServiceOrderPackingSlipDetails = (
  serviceOrder: ServiceOrder,
): {
  packingSlipDataProperties: Array<{
    key: string
    label: string
    value: string
  }>
} => {
  const contract = useAtomValue(contractDetailsAtom)
  const claim = useAtomValue(claimDetailsAtom)

  const packingSlipDataProperties = useMemo(
    () =>
      generateSchema(serviceOrder, claim, contract).filter(({ key }) => {
        if (key === 'instructions') return true
        const packingSlipConfiguration = serviceOrder?.configurations?.packingSlipConfiguration
        return packingSlipConfiguration && packingSlipConfiguration[key]
      }),
    [claim, contract, serviceOrder],
  )

  return { packingSlipDataProperties }
}
