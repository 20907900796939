import type { FC } from 'react'
import React from 'react'
import { ClipboardSearchIcon } from '@helloextend/merchants-ui'
import { COLOR } from '@extend/zen'
import styles from './error-status-page.module.css'

const Error404: FC = () => {
  return (
    <>
      <div className={styles['icon-container']}>
        <ClipboardSearchIcon sizePx={54} fill={COLOR.NEUTRAL[800]} />
      </div>
      <div className={styles.title} data-cy="errorTitle">
        We couldn’t find what you’re looking for
      </div>
      <div className={styles.text} data-cy="errorText">
        The URL may be mistyped, or the record you’re looking for might belong to a different store.
      </div>
    </>
  )
}

export { Error404 }
