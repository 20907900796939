import type { ServiceOrder, ServiceType } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '../components/badge-descriptions'

export const claimServiceTypeMap: Record<ServiceType, string> = {
  repair: 'Repair',
  replace: 'Replacement',
  repair_depot: 'Depot Repair',
  repair_home: 'Home Repair',
  repair_irobot: 'iRobot Repair',
  repair_onsite: 'Onsite Repair',
}

export type ServiceOrderStatusDescriptions = 'replace' | 'repair_onsite' | 'repair_depot'

export type ServiceOrderBadgeDescriptions = Record<string, BadgeDescriptionItem>

const created: BadgeDescriptionItem = {
  text: 'Created',
  description: 'Service Order has been created.',
  color: 'green',
}

const paymentRequested: BadgeDescriptionItem = {
  text: 'Payment Requested',
  description: 'Payment has been requested; expenses are under review by Extend.',
  color: 'yellow',
}

const paymentApproved: BadgeDescriptionItem = {
  text: 'Payment Approved',
  description: 'Extend has reviewed and approved expenses for payment.',
  color: 'green',
}

const paid: BadgeDescriptionItem = {
  text: 'Paid',
  description: 'Service Order expenses have been paid / reimbursed by Extend.',
  color: 'neutral',
}

const closed: BadgeDescriptionItem = {
  text: 'Closed',
  description: 'Service Order has been closed.  No further action is required.',
  color: 'neutral',
}

export const SERVICE_ORDER_REPLACE_STATUS_DESCRIPTIONS: ServiceOrderBadgeDescriptions = {
  created,
  accepted: {
    text: 'Accepted',
    description: 'Service Order has been accepted by the Servicer and is under review.',
    color: 'green',
  },
  replacement_approved: {
    text: 'Replacement Approved',
    description: 'Replacement has been approved; fulfillment is in progress.',
    color: 'green',
  },
  action_required: {
    text: 'Pending',
    description: 'Awaiting product shipment or additional information from customer.',
    color: 'yellow',
  },
  fulfilled: {
    text: 'Replacement Shipped',
    description: 'Replacement product has been shipped to the customer.',
    color: 'green',
  },
  payment_requested: paymentRequested,
  payment_approved: paymentApproved,
  paid,
  closed,
}

export const SERVICE_ORDER_REPAIR_ONSITE_STATUS_DESCRIPTIONS: ServiceOrderBadgeDescriptions = {
  created,
  assigned: {
    text: 'Assigned',
    description:
      'Service Order has been assigned to a Servicer and is awaiting acceptance into the scheduling queue.',
    color: 'green',
  },
  accepted: {
    text: 'Accepted',
    description:
      'The Service Order has been accepted by the Servicer and repair scheduling is now in progress.',
    color: 'green',
  },
  fulfilled: {
    text: 'Repair Complete',
    description: 'Repair is complete.',
    color: 'green',
  },
  payment_requested: paymentRequested,
  payment_approved: paymentApproved,
  paid,
  closed,
}

export const SERVICE_ORDER_REPAIR_DEPOT_STATUS_DESCRIPTIONS: ServiceOrderBadgeDescriptions = {
  created,
  assigned: {
    text: 'Assigned',
    description:
      'Service Order has been assigned to a Servicer and is awaiting acceptance into the repair backlog.',
    color: 'green',
  },
  accepted: {
    text: 'Accepted',
    description:
      'Service Order has been accepted into the repair backlog and receipt of defective product is pending.',
    color: 'green',
  },
  repair_shipped: {
    text: 'Defective Product Shipped',
    description: 'Customer has shipped product to Servicer for repair.',
    color: 'green',
  },
  repair_started: {
    text: 'Repair Started',
    description: 'Servicer has received product and repair is in process. ',
    color: 'green',
  },
  fulfilled: {
    text: 'Repaired Product Shipped',
    description: 'Repair is complete. Repaired product has been shipped back to customer.',
    color: 'green',
  },
  payment_requested: paymentRequested,
  payment_approved: paymentApproved,
  paid,
  closed,
}

export const SERVICE_ORDER_BADGE_DESCRIPTIONS: Record<string, ServiceOrderBadgeDescriptions> = {
  replace: SERVICE_ORDER_REPLACE_STATUS_DESCRIPTIONS,
  repair_onsite: SERVICE_ORDER_REPAIR_ONSITE_STATUS_DESCRIPTIONS,
  repair_depot: SERVICE_ORDER_REPAIR_DEPOT_STATUS_DESCRIPTIONS,
  repair: SERVICE_ORDER_REPAIR_DEPOT_STATUS_DESCRIPTIONS,
}

export const defaultBadgeProps: BadgeDescriptionItem = {
  color: 'neutral',
  text: 'Unknown',
  description: '',
}

export const getServiceOrderBadgeStatus = (serviceOrder: ServiceOrder): BadgeDescriptionItem => {
  const { status, serviceType } = serviceOrder
  const statuses = SERVICE_ORDER_BADGE_DESCRIPTIONS[serviceType]

  if (!statuses) return defaultBadgeProps

  return statuses[status] ? statuses[status] : defaultBadgeProps
}

export const getServiceOrderBadgeStatusDescriptions = (
  serviceOrder: ServiceOrder,
): BadgeDescriptionItem[] => {
  const { serviceType } = serviceOrder
  const statuses = SERVICE_ORDER_BADGE_DESCRIPTIONS[serviceType]

  if (!statuses) return [] as BadgeDescriptionItem[]

  return Object.values(statuses)
}
