// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1BViThqbryxCfxy5kp__26 {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._1BViThqbryxCfxy5kp__26 > div {\n    max-width: 100%;\n}\n\n._3BqZlfK0bPiVvn7dEHNS0l {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._1pJPRsPysrYhrONkqW-v3F {\n    display: flex;\n    flex-direction: column;\n}\n\n._1pJPRsPysrYhrONkqW-v3F > div {\n    max-width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_1BViThqbryxCfxy5kp__26",
	"lead": "_3BqZlfK0bPiVvn7dEHNS0l",
	"column": "_1pJPRsPysrYhrONkqW-v3F"
};
module.exports = exports;
