import * as Yup from 'yup'
import type {
  FormikMapperConfig,
  CurrencyConfig,
} from '../components/form-text-group/formik-mapper'
import { PlanIdDisplayValue } from '../types/plans'

type MapperConfig = FormikMapperConfig | CurrencyConfig

const schema = Yup.object()
  .shape({
    productName: Yup.string().default(''),
    incidentDescription: Yup.string().default(''),
    contractId: Yup.string().default(''),
    claimStatus: Yup.string().default(''),
    fraudulentActivity: Yup.string().default(''),
    productSerialNumber: Yup.string().default(''),
    productModelNumber: Yup.string().default(''),
    productManufacturer: Yup.string().default(''),
    customerName: Yup.string().default(''),
    customerEmail: Yup.string().email().default(''),
    coverage: Yup.string().default(''),
    coverageTerm: Yup.string().default(''),
    customerPhone: Yup.string().default(''),
    storeName: Yup.string().default(''),
    purchaseOrderNumber: Yup.string().default(''),
    storeId: Yup.string().default(''),
    customerShippingAddress: Yup.string().default(''),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const fields: MapperConfig[] = [
  { key: 'productName', label: 'Product Name', columnCount: 2 },
  { key: 'incidentDescription', label: 'Incident Description', columnCount: 2 },
  { key: 'contractId', label: 'Contract ID', columnCount: 1, includeCopyAction: true },
  { key: 'claimStatus', label: 'Claim Status', columnCount: 1 },
  { key: 'fraudulentActivity', label: 'Fraudulent Activity', columnCount: 1 },
  { key: 'productSerialNumber', label: 'Product Serial Number', columnCount: 1 },
  { key: 'productModelNumber', label: 'Product Model Number', columnCount: 1 },
  { key: 'productManufacturer', label: 'Product Manufacturer', columnCount: 1 },
  { key: 'customerName', label: 'Customer Name', columnCount: 1 },
  { key: 'customerEmail', label: 'Customer Email', columnCount: 1 },
  { key: 'coverage', label: 'Coverage', columnCount: 1 },
  { key: 'coverageTerm', label: 'Coverage Term', columnCount: 1 },
  { key: 'customerPhone', label: 'Customer Phone', columnCount: 1 },
  { key: 'storeName', label: 'Store Name', columnCount: 1 },
  { key: 'purchaseOrderNumber', label: 'Purchase Order Number', columnCount: 1 },
  { key: 'storeId', label: 'Store ID', columnCount: 1, includeCopyAction: true },
  { key: 'customerShippingAddress', label: 'Customer Shipping Address', columnCount: 4 },
]

const mapPlanIdToDisplayValue = (planId?: string): string => {
  if (!planId) return ''

  if (/-adh-/.test(planId)) {
    return PlanIdDisplayValue.adh_plan
  }

  if (/-base-/.test(planId)) {
    return PlanIdDisplayValue.base_plan
  }

  return planId
}

export type { Values }
export { schema, fields, mapPlanIdToDisplayValue }
