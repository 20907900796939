// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".F3APTqYe0aMyj1wgEKYcF {\n    display: flex;\n    gap: 16px;\n    padding: 0.8em 0;\n    width: 100%;\n}\n\n.F3APTqYe0aMyj1wgEKYcF > div {\n    width: 100%;\n}\n\n._2kGxVv-Yckct-S1gg8-bAr {\n    padding: 0 0 1.2em;\n}", ""]);
// Exports
exports.locals = {
	"section": "F3APTqYe0aMyj1wgEKYcF",
	"form": "_2kGxVv-Yckct-S1gg8-bAr"
};
module.exports = exports;
