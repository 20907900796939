import type { Store } from '@helloextend/extend-api-client'
import { atom } from 'jotai/vanilla'
import { atomWithReset, atomWithStorage } from 'jotai/vanilla/utils'
import { EXTEND_ENV } from '@helloextend/client-constants'
import {
  createThemeNameAtom,
  currentlySelectedThemeAtom,
  currentlySelectedThemeIdAtom,
} from './customize-theme'

export const ACTIVE_STORE_ID = `${EXTEND_ENV}_ACTIVE_STORE_ID`

/**
 * Do not set directly!
 * Exported only for initialValues in tests
 */
export const activeStoreAtom = atom<Store | undefined>(undefined)

export const getActiveStoreAtom = atom<Store | undefined>((get) => get(activeStoreAtom))
export const setActiveStoreAtom = atom(null, (_get, set, store: Store | undefined) => {
  set(activeStoreAtom, store)
  set(activeStoreIdAtom, store?.id || '')
  set(createThemeNameAtom, null)
  set(currentlySelectedThemeIdAtom, '')
  set(currentlySelectedThemeAtom, null)
})

/**
 * Do not set directly!
 * Exported only for initialValues in tests
 */
export const activeStoreIdAtom = atomWithStorage<string>(ACTIVE_STORE_ID, '')

export const getActiveStoreIdAtom = atom((get) => get(activeStoreIdAtom))

export const stagedChangesDataAtom = atomWithReset<Partial<Store> | null>(null)

export const stagedChangesAtom = atom(
  (get) => get(stagedChangesDataAtom),
  (_get, set, store: Partial<Store>) => {
    set(stagedChangesDataAtom, store)
    set(changesMadeAtom, true)
  },
)

export const clearStagedChangesDataKeyAtom = atom(undefined, (get, set, key: string) => {
  const previous = get(stagedChangesDataAtom)
  set(stagedChangesDataAtom, { ...previous, [key]: undefined })
  set(changesMadeAtom, false)
})

export const changesMadeAtom = atom(false)

export const isStoreUpdatingAtom = atom(false)

export const storeUpdateErrorAtom = atom<string>('')
