import type { FC } from 'react'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Redirect, Switch } from 'react-router-dom'
import { DashboardLayout } from '../components/dashboard-layout'
import { DashboardSection } from '../constants/dashboard-sections'
import { ProtectedRoute } from '../components/protected-route'
import { Permission } from '../lib/permissions'
import { V3Users } from '../features/account/users/v3-users'
import { V3User } from '../features/account/users/v3-user'
import { LDFlag } from '../constants/ld-flags'
import { Integrations } from '../features/integrations/integrations'
import { Integration } from '../features/integration/integration'
import { PlatformIntegration } from '../features/integration/platform-integration'

const ACCOUNT_PATH = '/account'

export const Account: FC = () => {
  const { [LDFlag.MpEER4Integrations]: FF_R4_INTEGRATIONS } = useFlags()
  return (
    <DashboardLayout section={DashboardSection.Stores}>
      <Switch>
        {FF_R4_INTEGRATIONS && (
          <ProtectedRoute
            exact
            requiredPermissions={[Permission.IntegrationsView]}
            path="/account/integrations/api/:clientId"
            component={Integration}
          />
        )}
        {FF_R4_INTEGRATIONS && (
          <ProtectedRoute
            exact
            requiredPermissions={[Permission.IntegrationsView]}
            path="/account/integrations/platform/:integrationId"
            component={PlatformIntegration}
          />
        )}
        {FF_R4_INTEGRATIONS && (
          // TODO: move the integrations feature folder into account/integrations
          <ProtectedRoute
            requiredPermissions={[Permission.IntegrationsView]}
            path="/account/integrations"
            component={Integrations}
          />
        )}
        <ProtectedRoute
          requiredPermissions={[Permission.ManageUsersV3]}
          path={`${ACCOUNT_PATH}/users`}
          exact
          component={V3Users}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.ManageUsersV3]}
          path={`${ACCOUNT_PATH}/users/:uuid`}
          component={V3User}
        />

        <Redirect to="/404" />
      </Switch>
    </DashboardLayout>
  )
}
