import type { FC } from 'react'
import React from 'react'
import { useAtom } from 'jotai/react'
import { StandardHeader } from '../../../components/standard-header'
import { isCreateThemeModalVisibleAtom } from '../../../atoms/customize-theme'
import { ThemesDataTable } from './themes-data-table'
import { ModalsContainer } from './modals-container'

const CustomizeManageThemes: FC = () => {
  const [isCreateThemeModalVisible, setIsCreateThemeModalVisible] = useAtom(
    isCreateThemeModalVisibleAtom,
  )

  const handleToggleModal = (): void => {
    setIsCreateThemeModalVisible(!isCreateThemeModalVisible)
  }

  return (
    <>
      <ModalsContainer />
      <StandardHeader
        data-cy="customize-manage-themes-header"
        pageTitle="Manage Themes"
        crumbs={[
          { text: 'Customize', to: '/store/customize' },
          { text: 'Manage Themes', to: `/store/customize/themes` },
        ]}
        buttons={[
          {
            text: 'New Theme',
            'data-cy': 'new-theme-button',
            emphasis: 'medium',
            onClick: handleToggleModal,
          },
        ]}
      />

      <ThemesDataTable />
    </>
  )
}

export { CustomizeManageThemes }
