import React, { useState } from 'react'
import type { FC } from 'react'
import { Button, Input, useToaster, ToastColor, ToastDuration, DataProperty } from '@extend/zen'
import { currency, date } from '@extend/client-helpers'
import { useAtomValue } from 'jotai/react'
import { useHistory } from 'react-router-dom'
import styles from './product-information-form-grid.module.css'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import { useUpdateContractMutation } from '../../../../queries/contract'
import { Permission } from '../../../../lib/permissions'
import { usePermissions } from '../../../../hooks/use-permissions'
import { contractDetailsAtom } from '../../../../atoms/contract-details'
import { LeavePageGuard } from '../../../../components/leave-page-guard'
import { DatePickerInput } from '../../../../components/date-picker-input'
import { useGetProductQuery } from '../../../../queries/products'

export const ProductInformationFormGrid: FC = () => {
  const { mutateAsync: updateContract, isLoading } = useUpdateContractMutation()
  const { toast } = useToaster()
  const { hasPermission } = usePermissions()
  const contract = useAtomValue(contractDetailsAtom)
  const history = useHistory()
  const { data: product, isLoading: isLoadingProduct } = useGetProductQuery({
    storeId: contract.sellerId,
    referenceId: contract.product.referenceId,
  })

  const serialNumberFromServer = contract?.product?.serialNumber
  const purchaseDateFromServer = contract?.product?.purchaseDate

  const [isEditing, setIsEditing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [serialNumber, setSerialNumber] = useState(serialNumberFromServer || '')
  const [purchaseDate, setPurchaseDate] = useState(purchaseDateFromServer)

  const hasUpdatedSerialNumber = serialNumber !== serialNumberFromServer
  const hasUpdatedPurchaseDate = purchaseDate !== purchaseDateFromServer
  const isDirty = hasUpdatedSerialNumber || hasUpdatedPurchaseDate

  if (!contract) return null

  const handleSubmit = async (): Promise<void> => {
    try {
      await updateContract({
        contract: {
          id: contract.id,
          product: {
            serialNumber,
            purchaseDate,
          },
        },
      })
      setIsEditing(false)
      toast({
        message: 'Contract updated',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: 'Contract update failed, please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const handleToggle = (): void => {
    if (isExpanded && isEditing) {
      setIsExpanded(false)
      setIsEditing(false)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const handleEdit = (): void => {
    setIsEditing(true)
    setIsExpanded(true)
  }

  const handleCancel = (): void => {
    setIsEditing(false)
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  const handlePurchaseDateChange = (dateString: string): void => {
    setPurchaseDate(date.formatToMilliseconds(dateString))
  }

  // Note: The box-overflow style is required to allow the Date Picker to expand the container.
  return (
    <div className={styles['box-overflow']}>
      <CollapsibleSection
        heading="Product Information"
        isExpanded={isExpanded}
        onToggleRequest={handleToggle}
        toolBar={
          hasPermission(Permission.StoreContractsFullAccess) &&
          (isEditing ? (
            <Button
              color="red"
              emphasis="low"
              type="button"
              size="small"
              onClick={handleCancel}
              text="Cancel"
            />
          ) : (
            <Button
              color="blue"
              emphasis="low"
              size="small"
              type="button"
              onClick={handleEdit}
              text="Edit"
              data-cy="edit-product-information"
            />
          ))
        }
        data-cy="productInformation"
      >
        <LeavePageGuard isNavBlocked={isEditing && isDirty} handleLeavePage={handleLeavePage} />
        <div className={styles['grid-33-33-33']}>
          <DataProperty
            value={contract.product?.referenceId}
            label="Reference ID"
            includeCopyAction
            data-cy="referenceId"
          />
          {isEditing ? (
            <Input
              id="serialNumber"
              value={serialNumber}
              placeholder={serialNumber}
              label="Serial Number"
              onChange={(e) => setSerialNumber(e.target.value)}
              data-cy="serialNumber"
            />
          ) : (
            <DataProperty
              value={contract.product?.serialNumber}
              label="Serial Number"
              data-cy="serialNumber"
            />
          )}
          <DataProperty
            value={currency.formatWithoutCurrencySymbol(
              Number(contract.product?.purchasePrice),
              contract.currency,
            )}
            label="Purchase Price"
            data-cy="productPurchasePrice"
          />
          <DataProperty value={contract.product?.title} label="Product Name" />
          <DataProperty
            value={currency.formatWithoutCurrencySymbol(
              Number(contract.product?.listPrice),
              contract.currency,
            )}
            label="List Price"
            data-cy="productListPrice"
          />
          {isEditing ? (
            <DatePickerInput
              label="Purchase Date"
              helperText="Date of the transaction."
              value={date.format(Number(purchaseDate), date.standardDateFormat)}
              onChange={handlePurchaseDateChange}
              data-cy="productPurchaseDate"
            />
          ) : (
            <DataProperty
              value={date.format(Number(contract.product?.purchaseDate), date.standardDateFormat)}
              label="Purchase Date"
              data-cy="productPurchaseDate"
            />
          )}
          <DataProperty
            value={date.format(Number(contract.product?.fulfillmentDate), date.standardDateFormat)}
            label="Fulfillment Date"
            data-cy="fulfillmentData"
          />
          <DataProperty
            value={String(contract.product?.manufacturerWarrantyLengthLabor)}
            label="Mfr Warranty Length - Parts"
            data-cy="mfrWarrantyLengthParts"
          />
          <DataProperty
            value={String(contract.product?.manufacturerWarrantyLengthParts)}
            label="Mfr Warranty Length - Labor"
            data-cy="mfrWarrantyLengthLabor"
          />
          <DataProperty
            value={product?.category}
            label="Product Category"
            isLoading={isLoadingProduct}
            data-cy="productCategory"
          />
        </div>
        {isEditing && (
          <span className={styles['button-group']}>
            <Button emphasis="medium" text="Cancel" onClick={handleCancel} />
            <Button
              type="button"
              text="Save Changes"
              isDisabled={!isDirty}
              isProcessing={isLoading}
              onClick={handleSubmit}
            />
          </span>
        )}
      </CollapsibleSection>
    </div>
  )
}
