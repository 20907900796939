import React, { useMemo } from 'react'
import type { Column, Cell } from 'react-table'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import { date, currency } from '@extend/client-helpers'
import type { ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { ContractStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import { BadgeDescriptions } from '../../../components/badge-descriptions'
import { getContractStatusCopy, statusDescriptions } from '../../../utils/contract-status'
import { CellStatusBadge } from '../../../components/cell-status-badge'

const columns: Array<Column<ContractsV2GetResponse>> = [
  {
    Header: 'Transaction ID',
    headerTooltip: 'Transaction ID in your system (typically order ID)',
    accessor: 'transactionId',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-transactionId">{value}</CellText>
    ),
  },
  {
    Header: 'Trans Date',
    headerTooltip: 'Order creation date',
    accessor: 'purchaseDate',
    disableSortBy: false,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-transactionDate">
        {date.format(value, date.standardDateFormat)}
      </CellText>
    ),
  },
  {
    Header: 'Customer Name',
    headerTooltip: 'Customer’s name',
    accessor: 'customer.name',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-customerName">{value}</CellText>
    ),
  },
  {
    Header: 'Customer Email',
    headerTooltip: 'Customer’s email address',
    accessor: 'customer.email',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-customerEmail">{value}</CellText>
    ),
  },
  {
    Header: 'Prod Name',
    headerTooltip: 'Product name displayed to customers',
    accessor: 'product.name',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-productName">{value}</CellText>
    ),
  },
  {
    Header: 'Prod Ref Id',
    headerTooltip: 'Product’s ID in your system (often same as SKU)',
    accessor: 'product.referenceId',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="contract-productRefId">{value}</CellText>
    ),
  },
  {
    Header: 'Contract Id',
    headerTooltip: 'Contract’s ID in Extend’s system',
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="contract-id">{value}</CellText>,
  },
  {
    Header: 'Plan Price',
    headerTooltip: 'Price customer paid for plan after discounts applied',
    accessor: 'purchasePrice',
    disableSortBy: true,
    textAlign: 'right',
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText align="right" data-cy="contract-planPurchasePrice">
        {currency.format(value)}
      </CellText>
    ),
  },
  {
    Header: 'Status',
    HeaderAdornment: <BadgeDescriptions descriptions={statusDescriptions} buttonSize="xsmall" />,
    headerTooltip: 'Current status of the contract',
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ value }: Cell): JSX.Element => {
      const statusBadgeDescription = useMemo((): BadgeDescriptionItem => {
        return getContractStatusCopy(value)
      }, [value])
      return <CellStatusBadge badgeData={statusBadgeDescription} />
    },
  },
]

const filterOptions: Record<string, FilterOptions> = {
  status: {
    label: 'Contract Status',
    type: 'checkbox',
    options: {
      [ContractStatus.LIVE]: getContractStatusCopy(ContractStatus.LIVE).text,
      [ContractStatus.CANCELLED]: getContractStatusCopy(ContractStatus.CANCELLED).text,
      [ContractStatus.FULFILLED]: getContractStatusCopy(ContractStatus.FULFILLED).text,
      [ContractStatus.DELIVERED]: getContractStatusCopy(ContractStatus.DELIVERED).text,
      [ContractStatus.EXPIRED]: getContractStatusCopy(ContractStatus.EXPIRED).text,
      [ContractStatus.VOIDED]: getContractStatusCopy(ContractStatus.VOIDED).text,
      [ContractStatus.TERMINATED]: getContractStatusCopy(ContractStatus.TERMINATED).text,
    },
  },
  transactionDate: { label: 'Contract Transaction Date', type: 'dateRange' },
  canceledDate: { label: 'Contract Canceled Date', type: 'dateRange' },
  refundedDate: { label: 'Contract Refunded Date', type: 'dateRange' },
  updatedDate: { label: 'Contract Updated Date', type: 'dateRange' },
}

const searchOptions = [
  { label: 'Transaction ID', value: 'containsTransactionId' },
  { label: 'Contract ID', value: 'containsId' },
  { label: 'Reference ID', value: 'containsProductReferenceId' },
  { label: 'Serial Number', value: 'containsProductSerialNumber' },
  { label: 'Customer Email', value: 'containsCustomerEmail' },
  { label: 'Customer Phone', value: 'containsCustomerPhone' },
  { label: 'Customer ID', value: 'containsMerchantCustomerId' },
]

export { columns, filterOptions, searchOptions }
