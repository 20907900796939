import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  usePopover,
  Popover,
  PopoverAlignment,
  HeaderMenuItem,
  HeaderUserInfo,
  Stack,
  MenuButtonItem,
} from '@extend/zen'
import { getUserRoleLabel } from '../utils/user-roles'
import { useUser } from '../hooks/use-user'
import { RoleSwitcher } from './role-switcher'
import styles from './user-profile-dropdown.module.css'

const UserProfileDropdown: FC = () => {
  const history = useHistory()
  const { tokenRole: role, initials } = useUser()

  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, toggle } =
    usePopover<HTMLDivElement>()

  return (
    <>
      <HeaderMenuItem ref={triggerRef} onClick={toggle} tooltip="Profile">
        <HeaderUserInfo initials={initials} label={getUserRoleLabel(role)} />
      </HeaderMenuItem>
      <Popover
        ref={popoverRef}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        alignment={PopoverAlignment.end}
      >
        <Stack padding={1}>
          <div className={styles.wrapper}>
            <MenuButtonItem onClick={() => history.push('/profile')}>Manage Profile</MenuButtonItem>
            <div className={styles['menu-item-divider']} />
            <RoleSwitcher />
            <MenuButtonItem onClick={() => history.push('/logout')}>Logout</MenuButtonItem>
          </div>
        </Stack>
      </Popover>
    </>
  )
}

export { UserProfileDropdown }
