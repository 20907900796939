// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HLxAK2dVN9N1dsUSFm0b {\n    max-width: 816px;\n    margin: auto;\n}\n\n.HLxAK2dVN9N1dsUSFm0b h2 {\n    text-align: center;\n}\n\n.HLxAK2dVN9N1dsUSFm0b ._235vSZOgURaBKfC94hbDVL {\n    margin: 0 auto 56px auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n\n.HLxAK2dVN9N1dsUSFm0b div[class*=\"StyledTextArea\"] {\n    align-self: center;\n    max-width: 816px;\n    width: 650px;\n}\n\n.HLxAK2dVN9N1dsUSFm0b textarea {\n    height: 169px;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "HLxAK2dVN9N1dsUSFm0b",
	"text-form-wrapper": "_235vSZOgURaBKfC94hbDVL"
};
module.exports = exports;
