import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import { Input, InputType } from '@extend/zen'
import { OrdersSearchKey, useSearchOrdersMutation } from '../../../../queries/orders'
import {
  transactionIdAtom,
  modalStepAtom,
  orderAtom,
} from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import styles from './create-contracts-modal-order-search.module.css'
import { CreateContractsModal } from './create-contracts-modal'

export const CreateContractsModalOrderSearch: FC = () => {
  const setModalStep = useSetAtom(modalStepAtom)
  const setOrder = useSetAtom(orderAtom)
  const [transactionId, setTransactionId] = useAtom(transactionIdAtom)
  const { mutateAsync: searchForOrder, isLoading } = useSearchOrdersMutation({
    searchKey: OrdersSearchKey.transactionId,
    searchValue: transactionId,
  })
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  // simple handler to update the transaction ID atom on user input
  const handleTransactionIdInput = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setTransactionId(e.target.value)
  }, [])

  return (
    <CreateContractsModal
      subHeading={
        'Use the transaction ID to find an existing order, or a new order will be created upon saving.'
      }
      primaryButton={{
        text: 'Next',
        onClick: async () => {
          setErrorMsg(null)

          // initiate the search for the order based on the transaction ID
          try {
            const result = await searchForOrder()
            if (result?.orders?.[0]) setOrder(result.orders[0])
            // once the search is completed, move to the next step
            setModalStep(CreateContractsModalStep.OrderDetails)
          } catch (e: unknown) {
            setErrorMsg('An unexpected error occurred. Please try again later.')
          }
        },
        // disable the primary button if the transaction ID is not set (i.e., user has not inputted any value yet)
        isDisabled: !transactionId,
        // display the loading indicator on the primary button if the query is enabled and currently loading
        isProcessing: isLoading,
      }}
      errorMsg={errorMsg}
      // secondary button/onDismiss default behavior is fine, no need to override it here
    >
      <div className={styles.input}>
        <Input
          type={InputType.text}
          label="Transaction ID"
          id="transaction-id"
          data-cy="transaction-id-input"
          onChange={handleTransactionIdInput}
          value={transactionId}
          autoFocus
        />
      </div>
    </CreateContractsModal>
  )
}
