import type { FC } from 'react'
import React, { useMemo } from 'react'
import { MerchantContract } from '../../../../types/merchant-contract'
import { DataTable} from '@extend/zen'
import { TABLE_COLUMNS, ContractsTableMapping } from './contract-products-data-table-mapping'
import { getProductDataTableData } from './utils'
import { ContractType } from '@helloextend/extend-api-client'
import { BundleProducts } from './bundle-products'

type ContractProductsTableProps = {
  contract: MerchantContract
}

export const ContractProductsTable: FC<ContractProductsTableProps> = ({
  contract,
}) => {
  const data = useMemo(() => getProductDataTableData(contract), [contract])

  return (
    <DataTable
      columnVisibility={{
        components: false,
      }}
      columns={TABLE_COLUMNS}
      isLoading={false}
      data={data}
      data-cy='contract-products-table'
      {...(contract.type === ContractType.PRODUCT_PROTECTION_BUNDLE && {
        rowDetailRenderer: (row: ContractsTableMapping) => {
          if (!row.components) return null
          const maxLength = data?.reduceRight((acc, product) => {
            return product?.title?.length > acc ? product?.title?.length : acc
          }, 0)
          return (
            <BundleProducts
              bundleProducts={row.components}
              maxLength={maxLength}
            />
          )
        },
      })}
      stateViews={{
        noData: {
          description: 'No associated products found.',
        },
      }}
    />
  )
}
