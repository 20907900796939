import type { Contract } from '@helloextend/extend-api-client'

export function getContractProductName(contract: Contract): string {
  switch (contract.type) {
    case 'pcrs': {
      return contract.product.name ?? ''
    }
    case 'shipping_protection': {
      const productsList = contract?.productsList || []
      const numberOfProducts = productsList.length || 0
      return numberOfProducts > 1 ? `${numberOfProducts} products` : productsList[0]?.title ?? ''
    }
    default: {
      return ''
    }
  }
}
