import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import { ClipboardSearchIcon } from '@helloextend/merchants-ui'
import { COLOR } from '@extend/zen'
import type { Image } from '../../../../../apps/merchants/src/images'
import Images from '../../../../../apps/merchants/src/images'

interface ZeroStateProps {
  title: string
  text: string
  'data-cy'?: string
  altIconName?: Image
}

const ZeroState: FC<ZeroStateProps> = ({
  title,
  text,
  'data-cy': dataCy,
  altIconName,
  children,
}) => {
  return (
    <Container data-cy={dataCy}>
      {' '}
      <IconContainer>
        {altIconName ? (
          <AlternateIcons src={Images[altIconName]} alt={`${altIconName} icon`} width={54} />
        ) : (
          <ClipboardSearchIcon sizePx={54} fill={COLOR.NEUTRAL[800]} />
        )}
      </IconContainer>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {children}
    </Container>
  )
}

const Container = styled.div({
  margin: '155px auto',
  textAlign: 'center',
  maxWidth: 550,
  padding: 8,
  color: COLOR.NEUTRAL[800],
})

const IconContainer = styled.div({
  marginBottom: 32,
})

export const AlternateIcons = styled.img(({ width }) => ({
  width: width ? `${width}` : '100%',
}))

const Title = styled.h2({
  marginBottom: 24,
  fontSize: 24,
  fontWeight: 700,
})

const Text = styled.p({
  fontSize: 20,
  whiteSpace: 'pre-wrap',
})

export type { ZeroStateProps }
export { ZeroState }
