import { useCallback } from 'react'
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { Contract, ContractSearchKey, Store } from '@helloextend/extend-api-client'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { isStoreUpdatingAtom, storeUpdateErrorAtom } from '../atoms/stores'

const BASE_URL = `https://${EXTEND_API_HOST}/stores`
export const STORES_CACHE_KEY = 'Stores'
export const STORES_CONTRACT_SEARCH_CACHE_KEY = 'StoresContractSearch'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=default',
}

export function useFetchAllStoresQuery(): UseQueryResult<Store[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(BASE_URL, {
      headers: {
        'Content-Type': 'application/json',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch all stores')
    }

    return response.json()
  }, [accessToken])

  return useQuery({
    queryKey: [STORES_CACHE_KEY],
    queryFn,
  })
}

export function useGetStoreQuery({
  storeId,
  version = 'default',
  enabled = true,
}: {
  storeId: string
  version?: string
  enabled?: boolean
}): UseQueryResult<Store, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${storeId}`, {
      headers: {
        ...COMMON_HEADERS,
        accept: `application/json; version=${version};`,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get store')
    }

    return response.json()
  }, [accessToken, storeId, version])

  return useQuery({
    queryKey: [STORES_CACHE_KEY, { id: storeId }],
    queryFn,
    enabled,
  })
}

export function useUpdateStoreMutation(): UseBaseMutationResult<
  void,
  Error,
  { storeId: string; store: Partial<Store>; version?: string },
  { previousStores: Store[]; storeId: string }
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const setIsStoreUpdating = useSetAtom(isStoreUpdatingAtom)
  const setStoreUpdateError = useSetAtom(storeUpdateErrorAtom)

  return useMutation({
    mutationFn: async ({ storeId, store, version = 'default' }) => {
      setIsStoreUpdating(true)
      setStoreUpdateError('')
      const response = await fetch(`${BASE_URL}/${storeId}`, {
        headers: {
          ...COMMON_HEADERS,
          accept: `application/json; version=${version};`,
          'x-extend-access-token': accessToken,
        },
        method: 'PUT',
        body: JSON.stringify(store),
      })

      if (!response.ok) {
        const message = 'Unable to update store'
        setStoreUpdateError(message)
        throw new Error(message)
      }
    },
    onMutate: async ({ storeId, store }) => {
      await client.cancelQueries({ queryKey: [STORES_CACHE_KEY] })
      const previousStores = client.getQueryData([STORES_CACHE_KEY]) as Store[]

      client.setQueryData<Store[]>([STORES_CACHE_KEY], (oldStores) => {
        if (!oldStores) {
          return []
        }
        return oldStores.map((oldStore) => {
          if (oldStore.id === storeId) {
            return {
              ...oldStore,
              ...store,
              shippingProtection: {
                ...oldStore.shippingProtection,
                ...store.shippingProtection,
              },
            }
          }
          return oldStore
        })
      })

      return { previousStores, storeId }
    },
    onError: (_, __, context) => {
      if (context?.storeId && context.previousStores) {
        client.setQueryData([STORES_CACHE_KEY], context.previousStores)
      }
    },
    onSettled: (_, __) => {
      setIsStoreUpdating(false)
      client.invalidateQueries([STORES_CACHE_KEY])
    },
  })
}

export function useSearchContractsByStore({
  storeId,
  searchKey,
  value,
}: {
  storeId: string
  searchKey: ContractSearchKey
  value: string
}): UseQueryResult<Contract[], Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ [searchKey]: value })
    const response = await fetch(`${BASE_URL}/${storeId}/contracts/search?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        accept: `application/json; version=2021-01-01`,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to search store contracts')
    }

    return response.json()
  }, [accessToken, searchKey, storeId, value])

  return useQuery({
    queryKey: [STORES_CONTRACT_SEARCH_CACHE_KEY],
    queryFn,
  })
}

export function useUpdateStoreLogoMutation(): UseBaseMutationResult<
  string,
  Error,
  { storeId: string; base64Image: string },
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, base64Image }) => {
      const response = await fetch(`${BASE_URL}/${storeId}/logo`, {
        headers: {
          'Content-Type': 'text',
          'x-extend-access-token': accessToken,
        },
        method: 'PUT',
        body: base64Image,
      })

      if (!response.ok) {
        throw new Error('Unable to update store logo')
      }

      return response.json()
    },
  })
}

export function useUpdateLeadsEmailLogoMutation(): UseBaseMutationResult<
  string,
  Error,
  { storeId: string; base64Image: string },
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId, base64Image }) => {
      const response = await fetch(`${BASE_URL}/${storeId}/logo?logoType=messaging`, {
        headers: {
          'Content-Type': 'text',
          'x-extend-access-token': accessToken,
        },
        method: 'PUT',
        body: base64Image,
      })

      if (!response.ok) {
        throw new Error('Unable to update leads email logo')
      }

      return response.json()
    },
  })
}

export function useDeleteStoreLogoMutation(): UseBaseMutationResult<
  void,
  Error,
  { storeId: string },
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ storeId }) => {
      const response = await fetch(`${BASE_URL}/${storeId}/logo`, {
        headers: {
          'x-extend-access-token': accessToken,
        },
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Unable to delete store logo')
      }
    },
  })
}
