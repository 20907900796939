import type { FC } from 'react'
import React from 'react'
import type { ButtonEmphasis, ButtonSize, ButtonIconPosition } from '@extend/zen'
import { Button, ToastColor, ContentCopy } from '@extend/zen'
import { useCopyToClipboard } from '@helloextend/merchants-ui/src/hooks'

interface CopyToClipboardButtonProps {
  label: string
  value: string
  'data-cy'?: string
  size?: ButtonSize
  text?: string
  emphasis?: ButtonEmphasis
  iconPosition?: ButtonIconPosition
  customMessage?: string
  toastColor?: ToastColor
}

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
  label,
  value,
  'data-cy': dataCy,
  size = 'xsmall',
  text,
  emphasis = 'low',
  iconPosition = 'left',
  customMessage = null,
  toastColor = 'neutral',
}) => {
  const copyToClipboard = useCopyToClipboard()
  const customToastColor = ToastColor[toastColor as ToastColor]

  const copyValue = async (): Promise<void> => {
    await copyToClipboard(label, value, customMessage, customToastColor)
  }

  return (
    <Button
      icon={ContentCopy}
      iconPosition={iconPosition as ButtonIconPosition}
      emphasis={emphasis}
      size={size}
      tooltip="Copy to Clipboard"
      data-cy={dataCy && `${dataCy}:copy-to-clipboard`}
      onClick={copyValue}
      text={text}
    />
  )
}

export type { CopyToClipboardButtonProps }
export { CopyToClipboardButton }
