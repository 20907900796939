import React, { useCallback, useMemo, useState } from 'react'
import type { ChangeEvent, ReactElement } from 'react'
import { useAtom } from 'jotai/react'
import {
  DataProperty,
  DataPropertyType,
  Input,
  Modal,
  ModalController,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import type { CategoryContract, Contract } from '@helloextend/extend-api-client'
import { categoryProductModalAtom } from '../../../../atoms/category-product-modal'
import images from '../../../../images'
import { useUpdateContractMutation } from '../../../../queries/contract'
import styles from './category-product-modal.module.css'

type Props = {
  contract: CategoryContract
}
export function CategoryProductModal({ contract }: Props): ReactElement | null {
  const [{ isVisible, productId }, setModalState] = useAtom(categoryProductModalAtom)

  const product = useMemo(() => {
    return contract?.productsList?.find((p) => p.referenceId === productId)
  }, [contract, productId])

  const [serialNumber, setSerialNumber] = useState(product?.serialNumber || '')
  const { mutateAsync: updateContract, isLoading: isUpdating } = useUpdateContractMutation()
  const { toast } = useToaster()

  const closeModal = useCallback(() => {
    setModalState({ isVisible: false, productId: '' })
    setSerialNumber('')
  }, [setModalState, setSerialNumber])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSerialNumber(event?.target?.value || '')
    },
    [setSerialNumber],
  )

  const handleSave = useCallback(async () => {
    if (!productId || !product) {
      return
    }

    try {
      const currentProductIndex =
        contract?.productsList?.findIndex((p) => p.referenceId === productId) || 0
      await updateContract({
        contract: {
          ...contract,
          productsList: [
            ...(contract?.productsList?.slice(0, currentProductIndex) || []),
            { ...product, serialNumber },
            ...(contract?.productsList?.slice(currentProductIndex + 1) || []),
          ],
        } as unknown as Partial<Contract>,
      })
      toast({
        message: 'Successfully updated category-product',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      closeModal()
    } catch (err) {
      console.error(err)
      toast({
        message: 'Unable to update category-product',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [contract, productId, product, updateContract, toast, closeModal, serialNumber])

  const canSubmit = useMemo(
    () => serialNumber && product?.serialNumber !== serialNumber,
    [serialNumber, product],
  )

  if (!product) {
    return null
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading={`${product.title} Details`}
        onDismissRequest={closeModal}
        primaryButtonProps={{
          onClick: () => closeModal(),
          text: 'Close',
        }}
        secondaryButtonProps={{
          onClick: handleSave,
          text: 'Save',
          isProcessing: isUpdating,
          isDisabled: !canSubmit,
        }}
        size="md"
      >
        <form className={styles.form}>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <img
              className={styles['product-image']}
              alt="product-logo"
              src={product?.imageUrl || images.placeholderImage}
            />
            <article>
              <DataProperty label="Product Title" value={product.title} />
            </article>
          </section>
          <article>
            <DataProperty label="Product Description" value={product?.description || '_'} />
          </article>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <article className="flex-1">
              <DataProperty
                label="Manufacturer Warranty Length Labor"
                value={product.manufacturerWarrantyLengthLabor}
                type={DataPropertyType.currency}
                currencyCode={contract.purchaseCurrency}
              />
            </article>
            <article className="flex-1">
              <DataProperty
                label="Manufacturer Warranty Length Parts"
                value={product.manufacturerWarrantyLengthParts}
                type={DataPropertyType.currency}
                currencyCode={contract.purchaseCurrency}
              />
            </article>
          </section>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <article className="flex-1">
              <DataProperty
                label="List Price"
                value={product.listPrice}
                type={DataPropertyType.currency}
                currencyCode={contract.purchaseCurrency}
              />
            </article>
            <article className="flex-1">
              <DataProperty
                label="Purchase Price"
                value={product.purchasePrice}
                type={DataPropertyType.currency}
                currencyCode={contract.purchaseCurrency}
              />
            </article>
          </section>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <article className="flex-1">
              <DataProperty label="Reference Id" value={product.referenceId} />
            </article>

            <article className="flex-1">
              <Input
                data-cy="serial-number"
                id="serialNumber"
                label="Serial Number"
                onChange={handleChange}
                placeholder={product.serialNumber}
                value={serialNumber}
              />
            </article>
          </section>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <article className="flex-1">
              <DataProperty
                label="Transaction Date"
                value={product.transactionDate}
                type={DataPropertyType.date}
              />
            </article>
            <article className={`${styles.truncatedId} flex-1`}>
              <DataProperty label="Transaction Id" value={product.transactionId} />
            </article>
          </section>
          <section className="flex flex-row align-items-center gap-16 w-full">
            <article className={`${styles.truncatedId} flex-1`}>
              <DataProperty label="Line Item Id" value={product.lineItemId} />
            </article>
            <article className="flex-1">
              <DataProperty
                label="Fulfillment Date"
                value={product.fulfillmentDate}
                type={DataPropertyType.date}
              />
            </article>
          </section>
          <br />
        </form>
      </Modal>
    </ModalController>
  )
}
