import { useCallback } from 'react'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

export function useMerchantReport({
  storeId,
  reportId,
  shouldUseTableau = true,
  enabled = true,
}: {
  storeId: string
  reportId: string
  shouldUseTableau?: boolean
  enabled?: boolean
}): UseQueryResult<Report, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ shouldUseTableau: shouldUseTableau.toString() })
    const response = await fetch(
      `https://${EXTEND_API_HOST}/stores/${storeId}/reports/${reportId}?${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-extend-access-token': accessToken,
        },
      },
    )
    if (!response.ok) {
      throw new Error('Unable to fetch report')
    }
    return response.json()
  }, [accessToken, reportId, shouldUseTableau, storeId])

  return useQuery({
    queryFn,
    queryKey: [reportId],
    enabled,
    cacheTime: 0,
  })
}

export type Report = {
  url: string
  token?: string
}
