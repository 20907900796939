import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import { isEnv } from '@extend/client-helpers'
import { OKTA_BASE_URL } from '@helloextend/client-constants'
import { datadogRum } from '@datadog/browser-rum'
import { HeadTag } from '../components/head-tag'
import images from '../images'
import { v3AccessTokenAtom } from '../atoms/auth'
import { idTokenAtom } from '../atoms/okta'
import styles from './logout.module.css'

const LOGOUT_TIMEOUT_MS = 3500

export const Logout: FC = () => {
  const setV3AccessToken = useSetAtom(v3AccessTokenAtom)
  const [idToken, setIdToken] = useAtom(idTokenAtom)

  useEffect(() => {
    setV3AccessToken('')

    if (idToken) {
      window.location.replace(getOktaLogoutUrl(idToken))
      setIdToken('')
    } else {
      navigateToLogin()
    }

    if (isEnv(['production', 'demo'])) {
      datadogRum.clearUser()
    }
  }, [idToken, setIdToken, setV3AccessToken])

  return (
    <main className={styles.wrapper}>
      <HeadTag />
      <article>
        <img className={styles.logo} src={images.extendIcon} alt="Extend" />
        <h2>See you later!</h2>
        <div>
          You have been logged out of <strong>Extend Merchants</strong> and will be redirected to
          the login page in a moment.
        </div>
        <a href="/login">Send me back to login</a>
      </article>
    </main>
  )
}

/**
 * forces page refresh to reroute to login
 */
function navigateToLogin(path = '/login'): void {
  setTimeout(() => {
    window.location.replace(path)
  }, LOGOUT_TIMEOUT_MS)
}

function getOktaLogoutUrl(idToken: string): string {
  return `${OKTA_BASE_URL}/oauth2/default/v1/logout?id_token_hint=${idToken}`
}
