// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TX5NP7vJ1YWmDpd0RVgp_ {\n    margin-right: 16px;\n    margin-bottom: 40px;\n}\n\n._2V6hpW7ewKiC_o4obtIPpp {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n._3T9S22E5Y7sHJ9iclKT0hS {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n\n.qCff9nLpXDum1iM5WAAIH {\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n    width: 100%;\n    margin: 0 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"property": "_3TX5NP7vJ1YWmDpd0RVgp_",
	"title": "_2V6hpW7ewKiC_o4obtIPpp",
	"title-row": "_3T9S22E5Y7sHJ9iclKT0hS",
	"button-row": "qCff9nLpXDum1iM5WAAIH"
};
module.exports = exports;
