import type { FC } from 'react'
import React from 'react'
import { Button, Input, useToaster, ToastColor, ToastDuration, ContentCopy } from '@extend/zen'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { useGetApiKeyMutation } from '../../../queries/auth'
import styles from './credentials.module.css'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { CreateNewAPIKeyModal } from './create-new-api-key-modal'
import { isCreateNewAPIKeyModalVisibleAtom } from '../../../atoms/create-new-api-key-modal'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard'

import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

export const Credentials: FC = () => {
  const isProdEnv = EXTEND_ENV === 'production'
  const store = useAtomValue(getActiveStoreAtom)

  const { mutateAsync: fetchApiKey, data: APIKey, isLoading } = useGetApiKeyMutation()
  const setIsCreateNewAPIKeyModalVisible = useSetAtom(isCreateNewAPIKeyModalVisibleAtom)
  const storeIdLabel = isProdEnv ? 'Store ID' : 'Sandbox Store ID'
  const apiKeyLabel = isProdEnv ? 'API Key' : 'Sandbox API Key'
  const { toast } = useToaster()
  const copyToClipboard = useCopyToClipboard()
  const { hasPermission } = usePermissions()

  const hasStoreSettingsFullAccess = hasPermission(Permission.StoreSettingsFullAccess)

  const handleCreateNewKeyClick = async (): Promise<void> => {
    try {
      await fetchApiKey()
      setIsCreateNewAPIKeyModalVisible(true)
    } catch {
      toast({
        message: 'There was an error creating a new API Key. Please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles['input-wrapper']}>
        <Input id="settingsStoreId" label={storeIdLabel} isDisabled value={store?.id || ''} />
        <div className={styles['copy-button-wrapper']}>
          <Button
            text="Copy"
            emphasis="low"
            size="small"
            icon={ContentCopy}
            onClick={() => copyToClipboard('Store ID', store?.id || '')}
          />
        </div>
      </div>

      {hasStoreSettingsFullAccess && (
        <Button
          emphasis="medium"
          onClick={handleCreateNewKeyClick}
          text={`Create New ${apiKeyLabel}`}
          isProcessing={isLoading}
        />
      )}
      <CreateNewAPIKeyModal APIKey={APIKey || ''} />
    </div>
  )
}
