import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { DataProperty, Information, Label, LoadingText } from '@extend/zen'
import { useGetOrganizations } from '../../queries/organizations'
import { LDFlag } from '../../constants/ld-flags'
import { useUser } from '../../hooks/use-user'
import { useGetUserGrantsQuery } from '../../queries/users-v3'
import { getAllUserRoles, UserRoleMappingV3 } from '../../utils/get-user-roles'
import type { UserRoleV3 } from '../../utils/get-user-roles'
import styles from './roles-display.module.css'
import { useAccountFilteredGrants } from '../../hooks/use-account-filtered-grants'

export const RolesDisplay: FC = () => {
  const { [LDFlag.EnterpriseTenancyR3]: FF_R3_ORGANIZATIONS } = useFlags()

  const { email } = useUser()
  const { data: organizations, isLoading: isLoadingOrgs } = useGetOrganizations()
  const { data: grants, isLoading } = useGetUserGrantsQuery(email || '')

  const roleAssignments = useMemo(() => {
    if (!organizations?.length || !grants?.length) {
      return {}
    }

    const roleOrgs: Record<string, string[]> = {}
    for (const grant of grants) {
      const { role } = grant
      const orgId = grant.ern.split('ORG:')[1]

      if (role && orgId) {
        const orgName = organizations.find((org) => org.id === orgId)?.name || ''

        if (role in roleOrgs) {
          roleOrgs[role].push(orgName)
        } else {
          roleOrgs[role] = [orgName]
        }
      }
    }

    return roleOrgs
  }, [organizations, grants])

  const accountFilteredGrants = useAccountFilteredGrants(grants)
  const roles = getAllUserRoles(accountFilteredGrants || []).join(', ')

  return FF_R3_ORGANIZATIONS ? (
    <section>
      <h4 className={styles.subtitle}>Role Assignments</h4>
      <div className={styles.container}>
        <div className={`${styles['quarter-width']} ${styles['margin-bottom-4']}`}>
          <Label isMuted helperText="The role this user is assigned in one or more organizations">
            Role
          </Label>
        </div>
        <div className={`${styles['three-quarter-width']} ${styles['margin-bottom-4']}`}>
          <Label isMuted helperText="The organization(s) in which this user performs their role">
            Organization(s)
          </Label>
        </div>
        {Object.entries(roleAssignments).length ? (
          Object.entries(roleAssignments).map(([role, orgIds]) => (
            <R3RoleMapping key={role} role={role} orgIds={orgIds} />
          ))
        ) : (
          <BlankR3Roles />
        )}
      </div>
    </section>
  ) : (
    <div className={styles.row}>
      <span>
        <div className={`${styles['user-detail-label']} flex align-items-center`}>
          Role(s)
          <Information buttonSize="xsmall">
            The roles that this user has been assigned. An admin can edit these in the Users tab.
          </Information>
        </div>
        {isLoading || isLoadingOrgs ? (
          <LoadingText data-cy="roles:loading-text" />
        ) : (
          <p className="no-margin">{roles}</p>
        )}
      </span>
    </div>
  )
}

const R3RoleMapping: FC<{ role: string; orgIds: string[] }> = ({ role, orgIds }) => (
  <div className={styles['org-row']}>
    <div className={`${styles['quarter-width']} ${styles['no-bottom-margin']}`}>
      <span>{UserRoleMappingV3[role as UserRoleV3] || '—'}</span>
    </div>
    <div className={`${styles['three-quarter-width']} ${styles['no-bottom-margin']}`}>
      <span>{orgIds.join(', ') || '—'}</span>
    </div>
  </div>
)

const BlankR3Roles: FC = () => (
  <div className={styles.row}>
    <div className={`${styles['quarter-width']} ${styles['no-bottom-margin']}`}>
      <DataProperty value="" />
    </div>
    <div className={`${styles['three-quarter-width']} ${styles['no-bottom-margin']}`}>
      <DataProperty value="" />
    </div>
  </div>
)
