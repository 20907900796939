import type { BadgeColor, Crumb, ButtonOrLinkButtonProps } from '@extend/zen'
import { Badge, Breadcrumbs, ButtonGroup, ButtonOrLinkButton } from '@extend/zen'
import React from 'react'
import { v4 } from 'uuid'
import type { BadgeDescriptionItem } from './badge-descriptions'
import { BadgeDescriptions } from './badge-descriptions'
import type { TabBarLinkDefinition } from './tab-bar'
import { TabBar } from './tab-bar'
import styles from './standard-header.module.css'
import { SplitButton } from './split-button'
import type { SplitButtonProps } from './split-button'

export interface StandardHeaderProps {
  children?: React.ReactNode
  pageTitle: string
  pageTitleAside?: React.ReactNode
  buttons?: ButtonOrLinkButtonProps[] | SplitButtonProps[]
  crumbs?: Crumb[]
  pageTitleBadge?: {
    badgeText: string
    badgeColor: BadgeColor
    badgeDescriptions?: BadgeDescriptionItem[]
    badgeDetails?: string
  }
  tabBarLinks?: TabBarLinkDefinition[]
  'data-cy'?: string
  marginBottom?: number
  marginTop?: number
}

export const StandardHeader = React.forwardRef<HTMLDivElement, StandardHeaderProps>(
  (
    {
      children,
      pageTitle,
      pageTitleAside,
      buttons,
      crumbs,
      pageTitleBadge,
      tabBarLinks,
      'data-cy': dataCy,
      marginBottom = 16,
      marginTop = 0,
    },
    ref,
  ) => {
    return (
      <>
        {crumbs && crumbs.length > 0 && (
          <Breadcrumbs data-cy={`${dataCy}:breadcrumbs`} crumbs={crumbs} />
        )}
        <div className={styles.row} ref={ref}>
          {pageTitleAside && (
            <div className={`${styles['title-aside']} ${styles['flex-no-grow']}`}>
              {pageTitleAside}
            </div>
          )}
          <div className={styles['flex-grow']} data-cy={`${dataCy}:header-section`}>
            <h1
              className={styles.heading}
              style={{ marginBottom: `${marginBottom}px`, marginTop: `${marginTop}px` }}
              data-cy={`${dataCy}:title`}
            >
              {pageTitle}
              {!!pageTitleBadge?.badgeText && (
                <div className={styles['badge-container']} data-cy={`${dataCy}:badge-container`}>
                  <Badge
                    text={pageTitleBadge.badgeText}
                    color={pageTitleBadge.badgeColor}
                    details={pageTitleBadge.badgeDetails}
                  />
                  {pageTitleBadge.badgeDescriptions && pageTitleBadge.badgeDescriptions.length > 0 && (
                    <div className={styles['descriptions-container']}>
                      <BadgeDescriptions
                        data-cy={`${dataCy}:badge-descriptions`}
                        descriptions={pageTitleBadge.badgeDescriptions}
                        buttonSize="small"
                      />
                    </div>
                  )}
                </div>
              )}
            </h1>
            {children}
          </div>
          {buttons && buttons.length > 0 && (
            <div className={styles['buttons-container']}>
              <ButtonGroup data-cy={`${dataCy}:buttons`}>
                {buttons.map((b) =>
                  'mainButton' in b ? (
                    <SplitButton key={`header-button-${v4()}`} {...b} />
                  ) : (
                    <ButtonOrLinkButton key={`header-button-${v4()}`} {...b} />
                  ),
                )}
              </ButtonGroup>
            </div>
          )}
        </div>
        {tabBarLinks && tabBarLinks.length > 0 && (
          <TabBar data-cy="tab-bar" tabBarLinks={tabBarLinks} />
        )}
      </>
    )
  },
)
