// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2aKPIitdDwIG9_Apq1SU5q {\n    max-width: 816px;\n    margin: auto;\n}\n\n._2aKPIitdDwIG9_Apq1SU5q .G3IfUOVFC85bqNXPKCISt {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 56px;\n}\n\n._2aKPIitdDwIG9_Apq1SU5q div[class*=\"DateWrapper\"] {\n    justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"picker": "_2aKPIitdDwIG9_Apq1SU5q",
	"datepicker-form-wrapper": "G3IfUOVFC85bqNXPKCISt"
};
module.exports = exports;
