// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wVBzmyx0b22Ynm-mmOL1P {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    min-width: 440px;\n}\n\n._3EwSq1k74j_UQHo7B89tLL {\n    border: 1px solid var(--neutral-200);\n    margin-top: 32px;\n    padding: 20px 40px 40px 40px;\n    min-width: 988px;\n}\n\n._20AEbDQmFlR867ibG4641H {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--neutral-200);\n    margin-top: 32px;\n    padding: 20px 40px 40px 40px;\n    min-width: 988px;\n}\n\n._3LKwa1YlpF5oEZXBb2OmLK {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    width: 100%;\n}\n\n.PHHxHY4aeKPTeBGljGjv1 {\n    font-weight: 700;\n    padding-bottom: 10px;\n    padding-top: 32px;\n}\n\n._3DD5eXMTbFRpWWWUiBIyil {\n    font-weight: 800;\n    padding-top: 28px;\n    font-size: 14px;\n    line-height: 22px;\n    align-items: center;\n    display: flex;\n}\n\n._2KFcUDbjlwo5WVZB2b3qHr {\n    width: 262px;\n    margin-top: 24px;\n}\n\n._24TeFJN70HOara2XA_1oN {\n    width: 262px;\n    margin-top: 24px;\n}\n\n._1OKWK99yShJmb1e3Xujuvq {\n    display: flex;\n    margin-top: 24px;\n}\n\n._3lHMvIYSFRq0q0OiXq_Bjo {\n    width: 262px;\n    margin-right: 16px;\n}\n\n._3V1PuOj3DPzFiP8gd6wlw5 {\n    margin-top: 32px;\n}\n", ""]);
// Exports
exports.locals = {
	"email-fields": "_2wVBzmyx0b22Ynm-mmOL1P",
	"leads-emails": "_3EwSq1k74j_UQHo7B89tLL",
	"customization": "_20AEbDQmFlR867ibG4641H",
	"description": "_3LKwa1YlpF5oEZXBb2OmLK",
	"input-header": "PHHxHY4aeKPTeBGljGjv1",
	"preview": "_3DD5eXMTbFRpWWWUiBIyil",
	"info": "_2KFcUDbjlwo5WVZB2b3qHr",
	"input": "_24TeFJN70HOara2XA_1oN",
	"row": "_1OKWK99yShJmb1e3Xujuvq",
	"text": "_3lHMvIYSFRq0q0OiXq_Bjo",
	"top-text": "_3V1PuOj3DPzFiP8gd6wlw5"
};
module.exports = exports;
