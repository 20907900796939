import type { ContractSearchOptionsWithTypeFilter } from '@helloextend/extend-api-client'
import { useAtomValue } from 'jotai/react'
import { useMemo } from 'react'
import type { ContractsTableMapping } from '../features/store/contracts/contract-data-table-config'
import { useSearchContractsQuery } from '../queries/contract'
import { getActiveStoreAtom } from '../atoms/stores'
import { toContractsTableMapping } from '../features/store/contracts/utils'

export const useContractsTableSearch = (
  params?: ContractSearchOptionsWithTypeFilter,
): {
  isLoading: boolean
  tableItems: ContractsTableMapping[]
} => {
  const store = useAtomValue(getActiveStoreAtom)

  const { data, isLoading } = useSearchContractsQuery({
    params,
    storeId: store?.id,
    apiVersion: '2022-02-01',
  })

  const tableItems: ContractsTableMapping[] = useMemo(() => {
    if (!data?.length) return []

    return data.map(toContractsTableMapping)
  }, [data])

  return {
    isLoading,
    tableItems,
  }
}
