// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._11UgQMKu1Sl6GUaChqK8Iq {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"status": "_11UgQMKu1Sl6GUaChqK8Iq"
};
module.exports = exports;
