import * as Yup from 'yup'

export const userProfileEditFormSchema = Yup.object()
  .shape({
    firstName: Yup.string().required('First name is required').max(30, 'Please enter a valid name'),
    lastName: Yup.string().required('Last name is required').max(30, 'Please enter a valid name'),
  })
  .defined()

export type Values = Yup.InferType<typeof userProfileEditFormSchema>
