import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ContractType } from '@helloextend/extend-api-client'
import type { ContractCoveredProduct } from '@helloextend/extend-api-client'
import { getContractDetailUrl } from '../../../../utils/route-helper'
import type { MerchantContract } from '../../../../types/merchant-contract'
import { StandardHeader } from '../../../../components/standard-header'
import styles from './header.module.css'

interface HeaderProps {
  contract: MerchantContract
}

export const Header: FC<HeaderProps> = ({ contract }) => {
  const { contractId, lineItemId } = useParams<{
    contractId: string
    lineItemId?: string
  }>()

  const isShippingProtection = contract?.type === ContractType.SHIPPING_PROTECTION

  return (
    <header className={styles.wrapper}>
      <StandardHeader pageTitle="Filing a Claim" />

      {contract && isShippingProtection ? (
        <p>
          Order Number: <span>{contract.orderId}</span>
        </p>
      ) : (
        <p>
          Claim in progress for:{' '}
          <span>
            {lineItemId
              ? getCategoryProductByLineItemId(contract, lineItemId)?.title
              : contract.product?.name}
          </span>
        </p>
      )}

      {lineItemId && (
        <p>
          Product ID:{' '}
          <span>
            {lineItemId ? getCategoryProductByLineItemId(contract, lineItemId)?.referenceId : ''}
          </span>
        </p>
      )}

      <p>
        Contract ID: <Link to={getContractDetailUrl(contractId)}>{contractId}</Link>
      </p>
    </header>
  )
}

/* exporting for testing purposes */
export function getCategoryProductByLineItemId(
  contract: MerchantContract,
  productLineItemId: string,
): ContractCoveredProduct | undefined {
  return contract?.productsList?.find((product) => product?.lineItemId === productLineItemId)
}
