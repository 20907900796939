import type {
  CarouselPromptOption,
  MultiSelectPromptOption,
  SelectPromptOption,
} from '@helloextend/extend-api-client'
import type { ClaimSessionLog } from '@helloextend/extend-api-rtk-query'
import { formatDate } from './format-date'

export interface TransformedLog {
  question: string
  answer: string | number
  id: string
}
export const transformSessionLogs = (sessionLogs: ClaimSessionLog[]): TransformedLog[] => {
  return sessionLogs.reduce((acc: TransformedLog[], log, index, arr) => {
    if (!log.slot) return acc

    let answer = log.slotValue

    const previousLog = arr[index - 1] || {}
    const previousReply = previousLog?.reply
    const previousPrompt = previousReply?.prompt
    const previousTextMessages = previousReply?.messages.filter((msg) => msg.type === 'text')
    const previousMessages = previousTextMessages.map((msg) => msg.content).join(' ')

    const promptType = previousPrompt?.type ?? 'input'
    switch (promptType) {
      case 'datepicker':
        answer = formatDate(log.slotValue as number)
        break
      case 'addressInput':
        if (typeof log?.slotValue !== 'object') {
          throw new Error('address inputs can only be of type `Object`')
        }

        answer = Object.values(log.slotValue).reduce(
          (str: string, address) => str.concat(`${address} `),
          '',
        )
        break
      case 'input':
        answer = log.slotValue
        break
      case 'imageUpload':
        return acc
      default: {
        if (previousPrompt && 'options' in previousPrompt) {
          const previousPromptOptions: Array<
            MultiSelectPromptOption | SelectPromptOption | CarouselPromptOption
          > = previousPrompt?.options
          answer = previousPromptOptions.find(({ value }) => value === log.slotValue)
            ?.outputText as string
        }
      }
    }

    if (answer) {
      acc.push({ answer, question: previousMessages, id: log.id })
    }

    return acc
  }, [])
}
