import { atom } from 'jotai/vanilla'

type SplashScreenState = {
  keyMap: Record<string, boolean>
  loadingCount: number
}

export const splashScreenAtom = atom<SplashScreenState>({
  keyMap: {},
  loadingCount: 0,
})

export const onLoadingAtom = atom(null, (get, set, key: string) => {
  const state = get(splashScreenAtom)
  if (!state.keyMap[key]) {
    set(splashScreenAtom, {
      loadingCount: state.loadingCount + 1,
      keyMap: { ...state.keyMap, [key]: true },
    })
  }
})

export const afterLoadingAtom = atom(null, (get, set, key: string) => {
  const state = get(splashScreenAtom)
  if (state.keyMap[key]) {
    set(splashScreenAtom, {
      loadingCount: state.loadingCount - 1,
      keyMap: { ...state.keyMap, [key]: false },
    })
  }
})
