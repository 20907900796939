import React, { useCallback } from 'react'
import type { FC } from 'react'
import { ReactNode } from 'react'
import { IconProps, InlineAlert, InlineAlertColor, Modal, Error as ErrorIcon } from '@extend/zen'
import { useAtom, useSetAtom } from 'jotai'
import {
  isModalVisibleAtom,
  isConfirmationModalVisibleAtom,
} from '../../../../atoms/create-contracts-modal'
import { ConfirmationModal } from './create-contracts-confirmation-modal'
import { useCreateContractsModalClose } from '../../../../hooks/use-create-contracts-modal-close'
import styles from './create-contracts-modal.module.css'

/**
 * Props for the Create Contracts Modal component.
 *
 * @param heading headline for the modal (title)
 * @param subHeading subheadline for the modal
 * @param primaryButton primary button props for the modal
 * @param primaryButton.text text to display on the primary button
 * @param primaryButton.onClick click handler for the primary button
 * @param primaryButton.isDisabled whether the primary button is disabled
 * @param primaryButton.isProcessing whether the primary button is processing
 * @param secondaryButton secondary button props for the modal
 * @param secondaryButton.text text to display on the secondary button; defaults to 'cancel' if not provided
 * @param secondaryButton.onClick click handler for the secondary button; defaults to a handler that closes the modal and cleans up modal state
 * @param onDismiss dismiss handler for the modal; defaults to a handler that closes the modal and cleans up modal state
 * @param children child components to render in the modal
 */
export interface CreateContractsModalProps {
  heading?: string
  subHeading?: string
  primaryButton: {
    text: string
    onClick: () => unknown
    isDisabled: boolean
    isProcessing: boolean
  }
  secondaryButton?: { text?: string; onClick?: () => unknown }
  altButton?: { text?: string; onClick?: () => unknown; icon?: IconProps }
  onDismiss?: () => unknown
  children?: ReactNode
  errorMsg?: string | null
}

/**
 * Modal component for use in the Create Contracts flow. Standardizes some of the modal props that
 * are common across the different steps of the Create Contracts flow.
 * @param props {@link CreateContractsModalProps}
 * @returns {@link Modal}
 */
export const CreateContractsModal: FC<CreateContractsModalProps> = (
  props: CreateContractsModalProps,
) => {
  const setIsModalVisible = useSetAtom(isModalVisibleAtom)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useAtom(
    isConfirmationModalVisibleAtom,
  )

  const { handleModalClose } = useCreateContractsModalClose()

  const confirmModalCloseHandler = useCallback(() => {
    setIsConfirmationModalVisible(true)
  }, [])

  const handleCancelClose = () => {
    setIsModalVisible(true)
    setIsConfirmationModalVisible(false)
  }

  // if a subheadline is provided, render it before the children
  const children = props.subHeading ? (
    <>
      <p>{props.subHeading}</p>
      {props.children}
    </>
  ) : (
    props.children
  )

  return (
    <>
      <Modal
        heading={props.heading ?? 'Create a new contract'}
        primaryButtonProps={{
          text: props.primaryButton.text,
          onClick: props.primaryButton.onClick,
          isDisabled: props.primaryButton.isDisabled,
          isProcessing: props.primaryButton.isProcessing,
        }}
        secondaryButtonProps={{
          // default to 'Cancel' text if no secondary button text is provided
          text: props.secondaryButton?.text ?? 'Cancel',
          // default to the modal close handler if no secondary button handler is provided
          onClick: props.secondaryButton?.onClick ?? confirmModalCloseHandler,
        }}
        altButtonProps={props.altButton}
        onDismissRequest={props.onDismiss ?? confirmModalCloseHandler}
        data-cy="create-contracts-modal"
        size="lg"
        children={
          <>
            {props.errorMsg && (
              <div className={styles.alert}>
                <InlineAlert
                  color={InlineAlertColor.red}
                  data-cy="create-contracts-modal-errorMsg"
                  icon={ErrorIcon}
                >
                  {props.errorMsg}
                </InlineAlert>
              </div>
            )}
            {children}
          </>
        }
      />
      {isConfirmationModalVisible && (
        <ConfirmationModal onConfirm={handleModalClose} onCancel={handleCancelClose} />
      )}
    </>
  )
}
