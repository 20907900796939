import type { FC, MouseEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { useHistory, useLocation } from 'react-router-dom'
import type {
  CheckboxFilterOptions,
  CheckboxFilterValues,
  FilterOptions,
  FilterValues,
} from '@helloextend/merchants-ui'
import { Filters, PaginationType, TableSkeleton } from '@helloextend/merchants-ui'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { ProductsSearchParams } from '@helloextend/extend-api-client'
import { useStandardToast } from '@helloextend/merchants-ui/src/hooks'
import {
  ArrowDownward,
  ArrowUpward,
  COLOR,
  Icon,
  IconSize,
  useShellContext,
} from '@extend/zen'
import {
  ColumnHead,
  Table,
  TableBody,
  TableHead,
} from '@helloextend/merchants-ui/src/components/table'
import { ReactTableRow } from '@helloextend/merchants-ui/src/components/data-react-table/react-table-row'
import { SearchBar } from '@helloextend/merchants-ui/src/components/search-bar'
import { EmptyMessage } from '@helloextend/merchants-ui/src/components/empty-message'
import type { PaginationDirection } from '@helloextend/merchants-ui/src/components/pagination'
import { Pagination } from '@helloextend/merchants-ui/src/components/pagination'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { ErrorMessage } from '../../../components/error-message'
import type { ProductRowData } from './product-data-table-rdb-config'
import { productColumns, searchOptions } from './product-data-table-rdb-config'
import { productsUrlAtom, variantsUrlAtom } from '../../../atoms/product-breadcrumbs'
import {
  getProductsUrl,
  getProductUrl,
  getProductVariantsUrl,
  getProductVariantUrl,
} from '../../../utils/route-helper'
import { useDataDelayedToast } from '../../../hooks/use-data-delay-toast'
import type { ProductsSearchQueryArguments } from '../../../queries/products'
import { useGetSearchCountQuery, useSearchProductsPaginatedQuery } from '../../../queries/products'
import { useGetStoreCategoriesQuery } from '../../../queries/store-categories'
import { getActiveStoreAtom } from '../../../atoms/stores'
import styles from './product-data-table-rdb.module.css'

interface ProductDataTableProps {
  parentReferenceId?: string
}

export const ProductDataTableRdb: FC<ProductDataTableProps> = ({ parentReferenceId }) => {
  const { getScrollableRegionRef } = useShellContext()
  const { toastError } = useStandardToast()
  const { push } = useHistory()
  const { search: queryParams } = useLocation()
  const { trackEvent } = useUserAnalytics()
  const store = useAtomValue(getActiveStoreAtom)
  const storeId = store?.id || ''
  const setProductsUrl = useSetAtom(productsUrlAtom)
  const setVariantsUrl = useSetAtom(variantsUrlAtom)
  const columns = useMemo(() => {
    if (parentReferenceId) {
      return productColumns.filter((c) => c.accessor !== 'variantCount')
    }

    return productColumns
  }, [parentReferenceId])

  useEffect(() => {
    // Sync the breadcrumb whenever the query params change
    if (!parentReferenceId) {
      const url = `${getProductsUrl()}${queryParams}`
      setProductsUrl(url)
    } else {
      const url = `${getProductVariantsUrl(parentReferenceId)}${queryParams}`
      setVariantsUrl(url)
    }
  }, [setProductsUrl, setVariantsUrl, parentReferenceId, queryParams])

  const [searchKey, setSearchKey] = useQueryStringState('search', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')

  const [sortDirection, setSortDirection] = useQueryStringState<
    ProductsSearchParams['sortDirection']
  >('sortDirection', 'asc')
  const [sortField, setSortField] = useQueryStringState<ProductsSearchParams['sortField']>(
    'sortField',
    'name',
  )
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 50)
  const [queryPageIndex, setQueryPageIndex] = useQueryStringState('page', 1)
  const [serverPageIndex, setServerPageIndex] = useState(queryPageIndex - 1)
  const [productFilters, setProductFilters] = useQueryStringState<
    Record<string, FilterValues | null>
  >('filters', {}, { encode: true, transformDates: true })
  const [autoResetPage, setAutoResetPage] = useState(true)

  useEffect(() => {
    setQueryPageIndex(serverPageIndex + 1)
  }, [setQueryPageIndex, serverPageIndex])

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: sortField || '', desc: sortDirection === 'desc' }],
      pageSize,
      pageIndex: serverPageIndex,
      filters: Object.keys(productFilters).map((key) => ({
        id: key,
        value: productFilters[key],
      })),
    }
  }, [productFilters, pageSize, sortDirection, sortField, serverPageIndex])

  const [hasSearched, setHasSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)

  const argsForApi: ProductsSearchQueryArguments = useMemo(() => {
    let args: ProductsSearchQueryArguments = {
      parentReferenceId,
      storeId,
      sortDirection,
      sortField,
      offset: serverPageIndex * pageSize,
      limit: pageSize,
      page: serverPageIndex,
    }

    if (searchKey && searchValue) {
      args = { ...args, [searchKey]: searchValue }
    }

    const hasProductFilters =
      productFilters.category && (productFilters.category as CheckboxFilterValues).values.length

    if (hasProductFilters) {
      args.category = (productFilters.category as CheckboxFilterValues).values
    }

    const hasActiveCheckboxFilters =
      productFilters.enabled && (productFilters.enabled as CheckboxFilterValues).values.length === 1

    if (hasActiveCheckboxFilters) {
      args.active = (productFilters.enabled as CheckboxFilterValues).values[0] === 'displayed'
    }

    return args
  }, [
    productFilters.category,
    productFilters.enabled,
    parentReferenceId,
    searchKey,
    searchValue,
    serverPageIndex,
    sortDirection,
    sortField,
    storeId,
    pageSize,
  ])

  const {
    data: productsResponse,
    isSuccess,
    isFetching,
    isLoading,
    isError,
    dataUpdatedAt,
  } = useSearchProductsPaginatedQuery(argsForApi)
  const { data: searchCount } = useGetSearchCountQuery(argsForApi)

  useEffect(() => {
    if (!isFetching && hasSearched && productsResponse?.products) {
      trackEvent('product_search_result_count', {
        count: productsResponse.products.length,
        search_parameter: searchKey && searchKey,
        search_value: searchValue && searchValue,
      })
    }
  }, [hasSearched, productsResponse, isFetching, trackEvent, searchKey, searchValue])

  useDataDelayedToast(isFetching)

  const products = useMemo(() => {
    return (productsResponse?.products || []) as ProductRowData[]
  }, [productsResponse?.products])

  useEffect(() => {
    if (isError) {
      toastError('Products for this store could not be retrieved')
    }
  }, [isError, toastError])

  const { data: categoriesResponse, isError: isCategoriesFetchErrored } =
    useGetStoreCategoriesQuery({ storeId })

  useEffect(() => {
    if (isCategoriesFetchErrored) {
      toastError('Product categories for this store could not be retrieved')
    }
  }, [isCategoriesFetchErrored, toastError])

  const handleRowClick = useCallback(
    (_: MouseEvent, product: ProductRowData): void => {
      if (!parentReferenceId) {
        push(getProductUrl(product.referenceId))
      } else {
        push(getProductVariantUrl(parentReferenceId, product.referenceId))
      }
    },
    [parentReferenceId, push],
  )

  const filterOptions: Record<string, FilterOptions> | undefined = useMemo(() => {
    const newFilterOptions: Record<string, FilterOptions> = {}

    newFilterOptions.category = {
      type: 'checkbox',
      label: 'Category',
      includeSearchBar: true,
      options: (categoriesResponse?.categories || []).reduce<Record<string, string>>(
        (acc, item) => {
          if (item) {
            acc[item] = item
          }
          return acc
        },
        {},
      ),
    } as CheckboxFilterOptions

    newFilterOptions.enabled = {
      type: 'checkbox',
      label: 'Display Offer',
      options: { displayed: 'Displayed', notDisplayed: 'Not Displayed' },
    }

    return Object.keys(newFilterOptions).length ? newFilterOptions : undefined
  }, [categoriesResponse?.categories])

  const {
    headerGroups,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { pageIndex: tablePageIndex, pageSize: tablePageSize, sortBy },
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
  } = useTable(
    {
      columns,
      data: products,
      initialState,
      manualSortBy: true,
      disableMultiSort: true,
      manualFilters: true,
      disableSortRemove: true,
      autoResetPage,
      manualPagination: true,
      pageCount: searchCount && Math.ceil(searchCount.totalProducts / pageSize),
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            ...(resetFilters && { ...initialState, filters: [] }),
          }),
          [state],
        )
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const resetPagination = useCallback(() => {
    setServerPageIndex(0)
    setAutoResetPage(true)
  }, [setServerPageIndex])

  const handleServerPagination = useCallback(
    (tempPageIndex: number) => {
      setServerPageIndex(tempPageIndex)
      setAutoResetPage(false)
    },
    [setServerPageIndex],
  )

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const handleLocalPagination = useCallback(
    (pageAmount: number): void => {
      if (setPagination) {
        setPagination(pageAmount)
      }
    },
    [setPagination],
  )

  useEffect(() => {
    handleServerPagination(tablePageIndex)
  }, [tablePageIndex, handleServerPagination])

  const handlePagination = useCallback(
    (paginationDirection: PaginationDirection): void => {
      if (paginationDirection === 'next') {
        nextPage()
      } else if (paginationDirection === 'previous') {
        previousPage()
      } else if (paginationDirection === 'first') {
        gotoPage(0)
      } else if (paginationDirection === 'last') {
        gotoPage(pageOptions.length - 1)
      }
    },
    [gotoPage, nextPage, pageOptions.length, previousPage],
  )

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setProductFilters(filtersRecord)
      resetPagination()
      handlePagination('first')
    },
    [handlePagination, resetPagination, setProductFilters],
  )

  const handleSearch = useCallback(
    (key?: string, value?: string): void => {
      if (key && value) {
        setSearchKey(key)
        setSearchValue(value)
        setHasSearched(true)
        setIsSearching(true)
      } else {
        setSearchKey('')
        setSearchValue('')
        setHasSearched(false)
        setIsSearching(true)
      }

      resetPagination()
      handlePagination('first')
    },
    [handlePagination, resetPagination, setSearchKey, setSearchValue],
  )

  useEffect(() => {
    let timeoutId: number
    const scrollToRef = getScrollableRegionRef()
    if (scrollToRef && scrollToRef.current) {
      // use setTimeout to prevent  scroll getting cancelled if button rerenders
      timeoutId = setTimeout(() => scrollToRef.current?.scrollTo({ top: 0, behavior: 'smooth' }))
    }
    handleLocalPagination(tablePageSize)

    return (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [tablePageIndex, tablePageSize, handleLocalPagination, getScrollableRegionRef])

  const handleServerSort = useCallback(
    (newSortKey: string, newSortAsc: boolean) => {
      let isChanged = false

      if (newSortKey) {
        const newSortDirection = newSortAsc ? 'asc' : 'desc'
        isChanged = newSortKey !== sortField || newSortDirection !== sortDirection
        setSortField(newSortKey)
        setSortDirection(newSortDirection)
      } else {
        isChanged = !!sortField || !!sortDirection
        setSortField(undefined)
        setSortDirection(undefined)
      }

      if (isChanged) {
        resetPagination()
      }
    },
    [resetPagination, setSortDirection, setSortField, sortDirection, sortField],
  )

  useEffect(() => {
    if (sortBy.length) {
      handleServerSort(sortBy[0].id, !sortBy[0].desc)
    } else {
      handleServerSort('', true)
    }
  }, [handleServerSort, sortBy])

  const mappedFilters: Record<string, FilterValues | null> = Object.keys(productFilters)
    .map((key) => ({ id: key, value: productFilters[key] }))
    .reduce(
      (filterMap, filter) => ({
        ...filterMap,
        [filter.id]: filter.value,
      }),
      {},
    )

  const handleResetFilters = useCallback(() => {
    setHasSearched(false)
    setResetFilters(true)
    handleServerFilter?.({})
    handleSearch?.()
    handleServerPagination?.(0)
    resetPagination()
    handlePagination('first')
  }, [handleServerFilter, handleServerPagination, handleSearch, resetPagination, handlePagination])

  useEffect(() => {
    if (!isLoading && isSearching) {
      setIsSearching(false)
    }
  }, [isLoading, isSearching])

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false)
    }
  }, [resetFilters])

  const hasProducts = products?.length
  const hasProductFilters = Object.keys(productFilters).length > 0
  const hasSearch = !!searchKey && !!searchValue
  const shouldDisplayZeroState =
    isError || (isSuccess && !hasProducts && !hasProductFilters && !hasSearched && !hasSearch)
  const isSearchDisabled = shouldDisplayZeroState || !dataUpdatedAt
  const displayTableHeader = Boolean((hasSearched && hasProducts) || hasProducts)
  const displayTable = !isLoading && !shouldDisplayZeroState && dataUpdatedAt

  return (
    <>
      <div className={styles['search-wrapper']}>
        <SearchBar
          data-cy="products-data-table:search"
          id="table-search"
          resetSearch={resetFilters}
          options={searchOptions}
          onSubmit={handleSearch}
          isLoading={isSearching}
          isDisabled={isSearchDisabled}
          initialKey={searchKey}
          initialValue={searchValue}
        />
      </div>
      {filterOptions && Object.keys(filterOptions).length > 0 && (
        <Filters
          onFilter={handleServerFilter}
          values={mappedFilters}
          filters={filterOptions}
          isDisabled={isLoading || !dataUpdatedAt || shouldDisplayZeroState}
          onClear={handleResetFilters}
        />
      )}

      {(isLoading || (!dataUpdatedAt && !isError)) && (
        <TableSkeleton columns={columns.length} rows={25} />
      )}

      {shouldDisplayZeroState && <ErrorMessage platform={store?.platform || ''} />}

      {displayTable && (
        <>
          <Table {...getTableProps()} data-cy="product-data:table">
            {displayTableHeader &&
              headerGroups.map((headerGroup) => (
                <TableHead {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ColumnHead
                      textAlign={column.textAlign}
                      columnWidth={column.width}
                      active={false}
                      isSelectable={false}
                      onClick={() => {}}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className={styles.header} style={{ textAlign: column.textAlign }}>
                        <span className={getHeaderTextWrapperClass(column.isSorted)}>
                          {column.textAlign === 'right' && column.canSort && (
                            <div className={getIconClass(column.isSorted)}>
                              <Icon
                                icon={column.isSortedDesc ? ArrowDownward : ArrowUpward}
                                size={IconSize.xsmall}
                                color={column.isSorted ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600]}
                              />
                            </div>
                          )}
                          <span
                            className={getHeaderTextClass(column.isSorted)}
                            data-tooltip={column.headerTooltip}
                          >
                            {column.render('Header')}
                          </span>
                          {column.textAlign !== 'right' && column.canSort && (
                            <div className={getIconClass(column.isSorted)}>
                              <Icon
                                icon={column.isSortedDesc ? ArrowDownward : ArrowUpward}
                                size={IconSize.xsmall}
                                color={column.isSorted ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600]}
                              />
                            </div>
                          )}
                        </span>
                        {column.HeaderAdornment && column.render('HeaderAdornment')}
                      </div>
                    </ColumnHead>
                  ))}
                </TableHead>
              ))}
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return <ReactTableRow key={row.id} row={row} onRowClick={handleRowClick} />
              })}
            </TableBody>
          </Table>

          {rows.length === 0 && !isLoading && (
            <EmptyMessage
              header="We can't find a match for that..."
              message="Double check that the search term is correct, or try applying different filters to your search."
              onClearFilters={handleResetFilters}
            />
          )}

          <Pagination
            paginationType={PaginationType.DETERMINATE_SERVER_SIDE}
            count={searchCount?.totalProducts ?? rows.length}
            type="products"
            currPage={serverPageIndex || tablePageIndex}
            pageSize={tablePageSize}
            handlePagination={handlePagination}
            onPageSizeChange={setTablePageSize}
            displayLastPageButton
            shouldHidePageNumber
          />
        </>
      )}
    </>
  )
}

/**
 * Exported only for testing
 */
export function getHeaderTextWrapperClass(canSort: boolean): string {
  return `${styles['header-text-wrapper']} ${canSort ? styles['is-sortable'] : ''}`.trim()
}

/**
 * Exported only for testing
 */
export function getHeaderTextClass(isSorted: boolean): string {
  return `${styles['header-text']} ${isSorted ? styles.sorted : ''}`.trim()
}

/**
 * Exported only for testing
 */
export function getIconClass(isSorted: boolean): string {
  return `${styles.icon} ${isSorted ? styles.visible : ''}`.trim()
}
