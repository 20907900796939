import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { useAtom } from 'jotai/react'
import { useHistory } from 'react-router-dom'
import { StandardHeader } from '../../../components/standard-header'
import { getClaimsUrl } from '../../../utils/route-helper'
import { claimBreadcrumbsAtom } from '../../../atoms/claim-breadcrumb'

const FileClaimHeader: FC = () => {
  const { push } = useHistory()
  const [claimsUrl] = useAtom(claimBreadcrumbsAtom)

  const crumbs = useMemo(
    () => [{ text: 'Claims', to: claimsUrl || getClaimsUrl() }, { text: 'File a Claim' }],
    [claimsUrl],
  )

  const handleCancel = useCallback(() => {
    push(claimsUrl || getClaimsUrl())
  }, [claimsUrl, push])

  return (
    <StandardHeader
      data-cy="claim-find-contract"
      pageTitle="Find a contract to file a claim"
      crumbs={crumbs}
      buttons={[{ text: 'Cancel', emphasis: 'medium', onClick: handleCancel }]}
    />
  )
}

export { FileClaimHeader }
