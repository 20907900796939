import type { Theme, ThemeMinimal } from '@helloextend/extend-api-rtk-query'
import type { PrimitiveAtom } from 'jotai/vanilla'
import { atom } from 'jotai/vanilla'
import type { ThemeGlobalProperties } from '../types/customize'

export type SelectedBreakpoint = 'mobile' | 'desktop'

export const createThemeNameAtom = atom<string | null>(null) as PrimitiveAtom<string | null>
export const currentlySelectedThemeIdAtom = atom('')
export const currentlySelectedThemeAtom = atom<Theme | null>(null) as PrimitiveAtom<Theme | null>
export const themesAtom = atom<ThemeMinimal[]>([])
export const isCreateThemeModalVisibleAtom = atom(false)
export const isRenameThemeModalVisibleAtom = atom(false)
export const isWarningModalVisibleAtom = atom(false)
export const isDeleteThemeModalVisibleAtom = atom(false)
export const isPublishThemeModalVisibleAtom = atom(false)
export const draftThemeGlobalPropertiesAtom = atom<ThemeGlobalProperties | null>(
  null,
) as PrimitiveAtom<ThemeGlobalProperties | null>
export const themeToDeleteAtom = atom<ThemeMinimal | null>(
  null,
) as PrimitiveAtom<ThemeMinimal | null>
export const requestedSelectedThemeAtom = atom<ThemeMinimal | null>(
  null,
) as PrimitiveAtom<ThemeMinimal | null>
export const renameThemeDisplayAtom = atom<string | null>(null) as PrimitiveAtom<string | null>
export const hasChangesToPublishedThemeAtom = atom(false)
