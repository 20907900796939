// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".J7EDHF8BmagYmWzM-YQ3h {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"container": "J7EDHF8BmagYmWzM-YQ3h"
};
module.exports = exports;
