import type { Store } from '@helloextend/extend-api-client'

type CopyLeadUrlsType = {
  leadUrl: string | null
  shouldDisplayCopyLeadUrlButton: boolean
}

export const useGetLeadUrls = (store: Store | null): CopyLeadUrlsType => {
  const isLeadUrlConfigurabilityEnabled =
    store?.leadUrlConfigurabilityEnabled ||
    store?.platform === 'shopify' ||
    store?.platform === 'big-commerce'

  return {
    leadUrl:
      store?.customLeadUrl ||
      (store?.platform === 'shopify' || store?.platform === 'big-commerce' ? store?.domain : null),
    shouldDisplayCopyLeadUrlButton:
      isLeadUrlConfigurabilityEnabled && Boolean(store?.customLeadUrl || store?.domain),
  }
}
