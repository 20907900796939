import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import type { Store } from '@helloextend/extend-api-client'
import { Button, COLOR, DataProperty, Spinner } from '@extend/zen'
import { SettingsContainer } from '../../../components/settings-container'
import { useGetNetsuiteACHQuery } from '../../../queries/support'

import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

type RecurringPaymentProps = {
  store: Store
}

export const RecurringPayment: FC<RecurringPaymentProps> = ({ store }) => {
  const history = useHistory()
  const { hasPermission } = usePermissions()
  const { data, isLoading } = useGetNetsuiteACHQuery(store.id)
  const showBankDetails = useMemo(() => {
    return data === null
  }, [data])

  const isApproved = store.approved || store.shippingProtection?.approved
  const approvedToolTipText = isApproved
    ? ''
    : 'Authorizing recurring payments requires approval by your Extend representative'

  const redirectToPaymentAuthorization = (): void => {
    history.push('/store/settings/payment-authorization')
  }

  const shouldDisableBtn = !hasPermission(Permission.StoreSettingsFullAccess) || !isApproved

  const viewOnly = !hasPermission(Permission.StoreSettingsFullAccess)

  const btnTextPaymentDetails = viewOnly ? 'View payment details' : 'Edit payment details'

  const subHeaderText = showBankDetails
    ? 'Provide your bank details and authorize monthly auto-debit payments from your bank account to Extend'
    : 'Update your bank details for your monthly auto-debit payments to Extend'

  return (
    <SettingsContainer headerText="Recurring Payment ACH Authorization" subHeader={subHeaderText}>
      {isLoading ? (
        <Spinner color={COLOR.BLUE[800]} />
      ) : (
        <>
          {showBankDetails ? (
            <div className="align-self-center">
              <Button
                data-cy="authorize-recurring-payments"
                emphasis="medium"
                onClick={redirectToPaymentAuthorization}
                text="Authorize recurring auto-debit payments"
                isDisabled={shouldDisableBtn}
                tooltip={approvedToolTipText}
              />
            </div>
          ) : (
            <div>
              <div style={{ marginBottom: 20 }}>
                <DataProperty data-cy="bankName" label="Bank Name" value={data?.bankName} />
              </div>
              <div style={{ marginBottom: 20 }}>
                <DataProperty
                  data-cy="accountNumber"
                  label="Account Number"
                  value={`...${data?.accountNumber || ''}`}
                />
              </div>
              <div style={{ marginBottom: 20 }}>
                <Button
                  emphasis="medium"
                  onClick={redirectToPaymentAuthorization}
                  text={btnTextPaymentDetails}
                />
              </div>
            </div>
          )}
        </>
      )}
    </SettingsContainer>
  )
}
