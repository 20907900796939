import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { SplashScreen } from '@helloextend/component-commons'
import { useAtom } from 'jotai/react'
import { splashScreenAtom } from '../../atoms/splash-screen'

const DEBOUNCE_DELAY_MS = 10

export const StoreControlledSplashScreen: FC = () => {
  const [{ loadingCount }] = useAtom(splashScreenAtom)
  const [isShown, setIsShown] = useState(loadingCount > 0)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timeoutId.current) {
      // Clear any waiting timeout
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }

    if (loadingCount) {
      // Immediately show the splash screen if some component is loading
      setIsShown(true)
    } else {
      // Debounce hiding the splash screen since some screens load
      // successively and that causes render flashes in the UI
      timeoutId.current = setTimeout(() => setIsShown(false), DEBOUNCE_DELAY_MS)
    }

    return () => {
      if (timeoutId.current) {
        // Clear any waiting timeout
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }, [loadingCount])

  if (!isShown && !loadingCount) {
    return null
  }

  return <SplashScreen data-cy="splash-screen" zIndex={100} />
}
