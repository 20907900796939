import { useCallback } from 'react'
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import type { V2 } from '@extend-services/service-orders'
import { v3AccessTokenAtom } from '../atoms/auth'

const MERCHANT_SERVICING_SETTINGS_CACHE_KEY = 'MerchantServicingSettings'
const SERVICE_ORDER_SERVICER_SETTINGS_CACHE_KEY = 'ServiceOrderServicerSettings'
const BASE_URL = `https://${EXTEND_API_HOST}/servicers/merchant-servicing-settings`
const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export function useGetMerchantServicingSettingsQuery({
  sellerId,
}: {
  sellerId: string
}): UseQueryResult<MerchantServicingSettings, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${sellerId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch merchants service settings')
    }

    return response.json()
  }, [accessToken, sellerId])

  return useQuery({
    queryKey: [MERCHANT_SERVICING_SETTINGS_CACHE_KEY],
    queryFn,
    refetchOnMount: true,
  })
}

export function useGetServiceOrderServicerSettingsQuery({
  servicerId,
}: {
  servicerId: string
}): UseQueryResult<ServicingOrderServicerSettings, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`https://${EXTEND_API_HOST}/servicers/${servicerId}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch service order servicer settings')
    }

    return response.json()
  }, [accessToken, servicerId])

  return useQuery({
    queryKey: [SERVICE_ORDER_SERVICER_SETTINGS_CACHE_KEY],
    queryFn,
    refetchOnMount: true,
  })
}

export function useUpsertMerchantServicingSettingsMutation(): UseMutationResult<
  void,
  Error,
  MerchantServicingSettings,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (merchantServicingSettings) => {
      const response = await fetch(BASE_URL, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify(merchantServicingSettings),
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to upsert merchant servicing settings')
      }

      await client.invalidateQueries([MERCHANT_SERVICING_SETTINGS_CACHE_KEY])
    },
  })
}

export interface MerchantServicingSettings {
  sellerId: string
  autoAssignOnsiteServicer?: boolean
  primaryOnsiteServicerId?: string
  autoAssignDepotRepairServicer?: boolean
  primaryDepotRepairServicerId?: string
  mids?: string[]
  fulfillmentEnabled?: boolean
  fieldDestroyEnabled?: boolean
  replacementFulfillmentMethod?: V2.Models.ReplacementFulfillmentMethod
}

export interface ServicingOrderServicerSettings {
  id: string
  type: string
  updatedAt: number
  authOrganizationId: string
  createdAt: number
  businessName: string
  contact: {
    name: string
    phone: string
    email: string
  }
  settings: {
    depotRepairEnabled: boolean
    onsiteRepairEnabled: boolean
    preventAutoAccept: boolean
    shipments: {
      rmaNumberRequired: boolean
      hasCustomReturnLabel: boolean
      isSignatureTwoDayReturnShippingEnabled: boolean
      hasCustomDefectiveLabel: boolean
    }
    paymentPreference: string
    webhookConfigurations: {
      sendAssignedWebhook: boolean
      url: string
    }
  }
  sellerId: string
  locations: Array<{
    id: string
    address: {
      city: string
      address2: string
      countryCode: string
      provinceCode: string
      address1: string
      postalCode: string
    }
    contact: {
      name: string
      phone: string
      email: string
    }
    businessName: string
    createdAt: number
    updatedAt: number
  }>
}

export interface MerchantServicingSettingsUpsertResponse {
  sellerId: string
}
