// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._40VtI8Ss79W8XypEItbsx {\n    div > div {\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n    }\n}", ""]);
// Exports
exports.locals = {
	"badge-cell": "_40VtI8Ss79W8XypEItbsx"
};
module.exports = exports;
