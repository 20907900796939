import React, { useCallback, useState } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import { Input, InputType, OpenInNew } from '@extend/zen'
import { CreateContractsModal } from './create-contracts-modal'
import styles from './create-contracts-modal-order-search.module.css'
import { useLazyGetProductQuery } from '../../../../queries/products'
import {
  modalStepAtom,
  planDetailsAtom,
  productAtom,
} from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'

export const CreateContractsModalProductsSearch = () => {
  const [product, setProduct] = useAtom(productAtom)
  const setModalStep = useSetAtom(modalStepAtom)
  const setPlanDetails = useSetAtom(planDetailsAtom)
  const [referenceId, setReferenceId] = useState(product?.referenceId || '')
  const [productNotFound, setProductNotFound] = useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  const { mutateAsync: getProduct, isLoading } = useLazyGetProductQuery()

  const handleOnClick = useCallback(async () => {
    if (referenceId) {
      setErrorMsg(null)
      try {
        const foundProduct = await getProduct(referenceId)
        if (foundProduct) {
          setProductNotFound(false)
          setProduct(foundProduct)
          setModalStep(CreateContractsModalStep.ProductDetails)
        } else {
          setProductNotFound(true)
        }
      } catch (e) {
        setErrorMsg('An unexpected error occurred. Please try again later.')
      }
    }
  }, [referenceId])

  const handleOnChange = useCallback((event) => {
    setReferenceId(event.target.value)
    setProductNotFound(false)
    setErrorMsg(null)
    // In case the user navigated back to this step and changed the product,
    // clear the plan details since those might not be valid anymore
    setPlanDetails(undefined)
  }, [])

  return (
    <CreateContractsModal
      subHeading="Find the product reference ID in your category."
      primaryButton={{
        text: 'Next',
        onClick: handleOnClick,
        isDisabled: !referenceId || productNotFound || isLoading,
        isProcessing: isLoading,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.OrderDetails)
          // Clear the error message if the user navigates back
          setErrorMsg(null)
        },
      }}
      altButton={{
        text: 'View Products',
        icon: OpenInNew,
        onClick: () => {
          window.open('/store/products', '_blank')
        },
      }}
      errorMsg={errorMsg}
    >
      <div className={styles.input}>
        <Input
          type={InputType.text}
          label="Reference ID"
          errorFeedback="Invalid ID"
          isError={productNotFound}
          id="reference-id"
          data-cy="reference-id-input"
          onChange={handleOnChange}
          value={referenceId}
          autoFocus
        />
      </div>
    </CreateContractsModal>
  )
}
