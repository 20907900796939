import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { DashboardSidebarStoreBadge } from './dashboard-sidebar-store-badge'
import styles from './dashboard-sidebar-store-picker.module.css'

export const getIsStoreBadgeApproved = (activeStore: Store): boolean =>
  activeStore.approved || Boolean(activeStore.shippingProtection?.approved)

export const getIsStoreBadgeEnabled = (activeStore: Store): boolean =>
  activeStore.enabled || Boolean(activeStore.shippingProtection?.enabled)

export const DashboardSidebarActiveStoreMenuItem: FC<{ activeStore: Store }> = ({
  activeStore,
}) => {
  return (
    <>
      <div className={styles['store-name-wrapper']} data-tooltip={activeStore.name}>
        {activeStore.name}
      </div>
      <DashboardSidebarStoreBadge
        approved={getIsStoreBadgeApproved(activeStore)}
        enabled={getIsStoreBadgeEnabled(activeStore)}
      />
    </>
  )
}
