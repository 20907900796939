import { useEffect, useMemo, useRef } from 'react'
import { useGetUsersV3Query } from '../queries/users-v3'
import type { UserTableMapping } from '../features/account/users/v3-users-table-config'
import { mapOktaToExtendStatus } from '../utils/okta-to-extend-status-utils'

export const useAllTableUsers = (
  { pageIndex = 0, pageSize = 10 },
  params = {},
): { isLoading: boolean; tableItems: UserTableMapping[]; hasNextPage: boolean } => {
  const cursors = useRef<Record<number, string>>({})

  const { data, isLoading } = useGetUsersV3Query(
    { page: pageIndex, pageSize },
    cursors.current[pageIndex] || '',
    params,
  )

  useEffect(() => {
    cursors.current[pageIndex + 1] = data?.cursor || ''
  }, [data?.cursor, pageIndex, cursors])

  useEffect(() => {
    cursors.current = {}
  }, [params])

  const tableItems: UserTableMapping[] = useMemo(() => {
    return (
      data?.users?.map((user) => ({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        status: mapOktaToExtendStatus(user.status, user.grants),
        grants: user.grants,
        uuid: user.uuid,
      })) || []
    )
  }, [data])

  return {
    isLoading,
    tableItems,
    hasNextPage: Boolean(data?.cursor),
  }
}
