import type { FC } from 'react'
import React, { useMemo } from 'react'
import { DataProperty } from '@extend/zen'

export type DataPropertyDetails = {
  key: string
  value: string | undefined
  label: string
  dataCy?: string
  includeCopyAction?: boolean
  isLoading?: boolean
  helperText?: string
  href?: string
  openInNew?: boolean
  hide?: boolean
}

export const DataPropertyGrid: FC<{ values: DataPropertyDetails[]; className?: string }> = ({
  values,
  className,
}) => {
  const properties = useMemo(() => {
    return values.map(
      ({
        label,
        value,
        dataCy,
        includeCopyAction,
        isLoading,
        helperText,
        key,
        href,
        openInNew,
        hide = false,
      }) => {
        if ((!label && !value) || hide) return undefined

        return (
          <DataProperty
            to={href}
            openInNew={openInNew}
            key={key || dataCy}
            value={String(value || '')}
            label={label}
            data-cy={dataCy}
            includeCopyAction={includeCopyAction}
            isLoading={isLoading}
            helperText={helperText}
          />
        )
      },
    )
  }, [values])

  return <div className={className}>{properties}</div>
}
