import { useCallback } from 'react'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { CONTRACT_CACHE_KEY } from './contract'

const REFUNDS_CACHE_KEY = 'Refunds'

const BASE_URL = `https://${EXTEND_API_HOST}/refunds`

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=2021-07-01;',
}

export function useGetRefundsByContractId({
  contractId,
  enabled = true,
  onSuccess,
  onError,
  onSettled,
}: {
  contractId: string
  enabled?: boolean
  onSuccess?: (data: Refund) => void
  onError?: (e: Error) => void
  onSettled?: (data: Refund | undefined, error: Error | null) => void
}): QueryObserverResult<Refund, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const params = new URLSearchParams({ contractId })
    const response = await fetch(`${BASE_URL}?${params}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get refund by contract id')
    }

    return response.json()
  }, [accessToken, contractId])

  return useQuery({
    queryKey: [REFUNDS_CACHE_KEY],
    queryFn,
    enabled,
    onError,
    onSuccess,
    onSettled,
  })
}

export function useReportRefundByIdMutation(): UseBaseMutationResult<
  void,
  Error,
  { contractId: string; contractsApiVersion?: string },
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const client = useQueryClient()

  return useMutation({
    mutationFn: async ({ contractId }) => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
        body: JSON.stringify({ contractId }),
      })

      if (!response.ok) {
        throw new Error('Unable to report refund by id')
      }
      await client.invalidateQueries([CONTRACT_CACHE_KEY, contractId])
    },
  })
}

export interface Refund {
  createdAt: number
  contractId: string
  id: string
  expiresAt: number
  lineItemId: string
  sellerId: string
  status: string
  reason: string
  refundAmounts: {
    customer: number
    merchant?: number
    obligor?: number
  }
}
