import type { Reply, Slot } from '@helloextend/extend-api-client'
import { TextArea } from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import type * as Yup from 'yup'
import type { MerchantContract } from '../../../../types/merchant-contract'
import { Footer } from './footer'
import { answerTextAreaSchema, DESCRIPTION_MAX } from './schema'
import styles from './answer-text-area.module.css'
import { getFormattedIncredibotMessage } from '../../../../utils/get-formatted-incredibot-message'

interface AnswerTextAreaProps {
  isLoading: boolean
  onSubmit: (slot: Slot, slotValue: string | number) => void
  reply: Reply
  contractId: MerchantContract['id']
}
type Values = Yup.InferType<typeof answerTextAreaSchema>

export const AnswerTextArea: FC<AnswerTextAreaProps> = ({
  isLoading,
  onSubmit,
  reply,
  contractId,
}) => {
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: { description: '' },
    validationSchema: answerTextAreaSchema,
    onSubmit: (vals: Values): void => {
      if (reply.prompt?.slot) {
        onSubmit(reply.prompt.slot, vals.description)
      }
    },
  })
  useEffect(() => {
    resetForm({ values: { description: '' } })
  }, [reply, resetForm])

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles['text-form-wrapper']}>
        <h2>{getFormattedIncredibotMessage(reply.messages)}</h2>
        <TextArea
          data-cy="description-claim-new"
          id="description"
          value={values.description}
          placeholder="Write a brief description of what happened or the state of the product. 1,000 character max"
          maxLength={DESCRIPTION_MAX}
          onChange={handleChange}
          isDisabled={isLoading}
          errorFeedback={errors.description}
        />
      </div>
      <Footer disabled={!values.description} isLoading={isLoading} contractId={contractId} />
    </form>
  )
}
