import type { FC } from 'react'
import React from 'react'
import { Box, DataProperty } from '@extend/zen'
import styles from './integration-details.module.css'
import type { OAuthClient } from '../../queries/oauth-client'
import { useGetOrganizations } from '../../queries/organizations'
import type { Organization } from '../../queries/organizations'
import { formatDate } from '../../utils/format-date'
import { getOrgIdFromErn } from '../../utils/get-org-id-from-ern'

export type IntegrationDetailsType = { integration: OAuthClient }

export const IntegrationDetails: FC<IntegrationDetailsType> = ({ integration }) => {
  const { data: organizations } = useGetOrganizations()
  const { ownerErn } = integration
  const orgId = getOrgIdFromErn(ownerErn)

  return (
    <Box>
      <div className={styles.container}>
        <h2>Client Details</h2>

        <div className={styles.grid}>
          <DataProperty label="Client Name" value={integration?.config.name} />
          <DataProperty label="Expiration Date" value={formatDate(integration?.validUntil)} />
          <DataProperty
            label="Organization"
            value={getIntegrationOrganizationValue(orgId, organizations || [])}
          />
        </div>
        <DataProperty label="Client ID" value={integration?.clientId} includeCopyAction />
      </div>
    </Box>
  )
}

// exporting for testing purposes
export function getIntegrationOrganizationValue(
  orgId: string,
  organizations: Organization[],
): string {
  if (!orgId) return 'Account Level'
  const orgName = organizations?.find((org) => org.id === orgId)?.name || ''
  return orgName
}
