import { useCallback, useState } from 'react'
import type { Product } from '@helloextend/extend-api-client'
import { useLazyGetProductWithCountQuery } from '../queries/products'

// exporting for testing purposes
export const errorMsg = 'Please enter a Product Reference ID that exists in the store catalog'

export const useGetProductForLead = (): {
  getProductForLead: (productReferenceId: string) => Promise<void>
  product: Product | null
  error: string
  isLoading: boolean
  clearError: () => void
} => {
  const { mutateAsync: getProductWithCount, isLoading } = useLazyGetProductWithCountQuery()
  const [product, setProduct] = useState<Product | null>(null)
  const [error, setError] = useState('')

  const clearError = useCallback(() => {
    setError('')
    setProduct(null)
  }, [setError, setProduct])

  const getProductForLead = useCallback(
    async (productReferenceId: string): Promise<void> => {
      setError('')
      setProduct(null)

      if (!productReferenceId) {
        setError(errorMsg)
        return
      }

      try {
        const { product: foundProduct, counts } = await getProductWithCount(productReferenceId)

        if (counts?.childCount && counts.childCount > 0) {
          setError(
            `${foundProduct.title} is a generic base product. Please enter the ID of a warrantable variant`,
          )
        } else {
          setProduct(foundProduct)
        }
      } catch (err) {
        setError(errorMsg)
      }
    },
    [getProductWithCount],
  )

  return { getProductForLead, product, error, isLoading, clearError }
}
