import { useCallback, useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAtomValue } from 'jotai/react'
import type { Permission } from '../lib/permissions'
import { getPermissions } from '../utils/get-permissions'
import { cachedRolesAtom, v3AccessTokenAtom } from '../atoms/auth'
import { getAccountIdFromV3Token, getRoleFromCachedRoles } from '../lib/jwt'
import { getActiveStoreAtom } from '../atoms/stores'

const usePermissions = (): {
  permissions: Permission[]
  hasPermission: (permission: Permission) => boolean
} => {
  const flags = useFlags()
  const v3accessToken = useAtomValue(v3AccessTokenAtom)
  const v3AccountId = getAccountIdFromV3Token(v3accessToken)
  const store = useAtomValue(getActiveStoreAtom) || null
  const cachedRoles = useAtomValue(cachedRolesAtom)
  const role = v3AccountId ? getRoleFromCachedRoles(cachedRoles, v3AccountId) : null

  const permissions = useMemo(() => getPermissions(role, flags, store), [role, flags, store])

  const hasPermission = useCallback(
    (permission: Permission): boolean => {
      return permissions.includes(permission)
    },
    [permissions],
  )

  return { permissions, hasPermission }
}

export { usePermissions }
