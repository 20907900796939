import { EXTEND_API_HOST, SHOPINT2_HOST } from '@helloextend/client-constants'
import type { UseBaseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import { sanitizeUrl } from '../utils/shopify-login-url-parser'
import { useUser } from '../hooks/use-user'
import { IntegrationStatus } from '../utils/integration-status-utils'

const INTEGRATIONS_CACHE_KEY = 'Integrations'

const BASE_URL = `https://${EXTEND_API_HOST}/integrations`

export const SHOPIFY_INTEGRATION_URL_CACHE_KEY = 'ShopifyIntegrationUrl'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export function useGetIntegrationListQuery(): UseQueryResult<GetIntegrationResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { accountId } = useUser()
  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/account/${accountId}/integrations`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
        accept: 'application/json; version=latest;',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch integrations')
    }

    return response.json()
  }, [accessToken, accountId])

  return useQuery({
    queryKey: [INTEGRATIONS_CACHE_KEY],
    queryFn,
  })
}

export function useGetIntegrationQuery(id: string): UseQueryResult<Integration, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const { accountId } = useUser()
  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/account/${accountId}/integration/${id}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
        accept: 'application/json; version=latest;',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch integrations')
    }

    return response.json()
  }, [accessToken, accountId])

  return useQuery({
    queryKey: [INTEGRATIONS_CACHE_KEY],
    queryFn,
  })
}

export function useDeleteIntegrationMutation(): UseBaseMutationResult<
  void,
  Error,
  DeleteIntegrationRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async ({ id, version = 'latest', accountId, organizationId }) => {
      const response = await fetch(
        `${BASE_URL}/accounts/${accountId}/organizations/${organizationId}/integrations/${id}`,
        {
          headers: {
            ...COMMON_HEADERS,
            'x-extend-access-token': accessToken,
            accept: `application/json; version=${version};`,
          },
          method: 'Delete',
        },
      )

      if (!response.ok) {
        throw new Error('Unable to delete integration')
      }

      await client.invalidateQueries([INTEGRATIONS_CACHE_KEY])
    },
  })
}

export function useFetchShopifyIntegrationUrl({
  shopUrl,
  enabled = true,
}: {
  shopUrl: string
  enabled?: boolean
}): UseQueryResult<string, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const url = sanitizeUrl(shopUrl)
    const response = await fetch(`https://${SHOPINT2_HOST}/auth/start?shop=${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch shopify integration url')
    }

    const shopifyUrl = await response.json()

    return shopifyUrl.url
  }, [accessToken, shopUrl])

  return useQuery({
    queryKey: [SHOPIFY_INTEGRATION_URL_CACHE_KEY],
    queryFn,
    enabled,
  })
}

export type PlatformIntegration = {
  id: string
  type: 'platform'
  organizationId: string
  storeId: string
  accountId: string
  status: IntegrationStatus
  updatedAt: number
  platform: 'shopify' | 'big-commerce' | 'magento'
  platformStoreId: string
  createContracts?: boolean
  contractEvent?: 'orderCreate' | 'orderFulfill'
  autoRefunds?: boolean
  createLeads?: boolean
  claimsFulfillment?: boolean
  productsCreate?: boolean
  productsUpdate?: boolean
  productsDelete?: boolean
}

export type ShopifyIntegration = PlatformIntegration & {
  platform: 'shopify'
  barcodes?: boolean
  autoFulfillPlans?: boolean
  offerSyncType?: 'directMap' | 'pricePoints' | 'off'
}

export type MagentoIntegration = PlatformIntegration & {
  platform: 'magento'
  productPriceField?: 'default' | 'msrp' | 'sale'
}

export type Integration = PlatformIntegration | ShopifyIntegration | MagentoIntegration

type GetIntegrationResponse = {
  limit: number
  items: Integration[]
}

type DeleteIntegrationRequest = {
  id: string
  accountId: string
  organizationId: string
  version?: string
}
