import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Select, Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useUpdateClaimMutation } from '../queries/claims'
import type { MerchantClaim } from '../types/claims'
import { ClaimSelectStatus, ClaimClosedResolution } from '@helloextend/extend-api-client'

export const CloseClaimModal: FC<{
  isOpen: boolean
  handleConfirm: () => void
  handleLeave: () => void
  claim: MerchantClaim
}> = ({ isOpen, handleConfirm, handleLeave, claim }) => {
  const { toast } = useToaster()
  const [claimClosedResolution, setClaimClosedResolution] = useState<ClaimClosedResolution | null>(
    null,
  )
  const { mutateAsync: updateClaim, isLoading: isUpdatingClaim } = useUpdateClaimMutation()

  const onConfirmClick = async (): Promise<void> => {
    if (!claimClosedResolution) return
    try {
      await updateClaim({
        claimId: claim.id,
        body: {
          status: ClaimSelectStatus.closed,
          resolution: claimClosedResolution,
        },
      })
      handleConfirm()
    } catch (error) {
      toast({
        message: 'Failed to close claim!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isOpen}>
      <Modal
        heading="Close Claim"
        size="lg"
        primaryButtonProps={{
          'data-cy': 'close-claim-modal-confirm',
          text: 'Confirm',
          color: 'blue',
          isProcessing: isUpdatingClaim,
          isDisabled: !claimClosedResolution,
          onClick: onConfirmClick,
        }}
        secondaryButtonProps={{
          'data-cy': 'close-claim-modal-cancel',
          text: 'Cancel',
          isProcessing: isUpdatingClaim,
          onClick: handleLeave,
        }}
      >
        <DataRow>
          <ItemWrapper>
            <Select
              label="Reason"
              id="close-denialReason-select"
              onChange={(e: SyntheticEvent<Element>): void => {
                const { value } = e.currentTarget as HTMLInputElement
                setClaimClosedResolution(value as ClaimClosedResolution)
              }}
              value={claimClosedResolution ?? ''}
              placeholder="—SELECT—"
            >
              <option
                key="no_service"
                value="no_service"
                label="No service required"
                data-cy="select-item-no-service"
              />
            </Select>
          </ItemWrapper>
        </DataRow>
      </Modal>
    </ModalController>
  )
}

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})
