import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useAtomValue } from 'jotai/react'
import { AccordionSection, InformationSize } from '@extend/zen'
import { DataPropertyGrid } from '../../components/data-property-grid'
import { Expenses, showExpenses } from './claim/expenses'
import { ServiceOrderInformation } from './claim/service-order-information'
import {
  getProductClaimFields,
} from '../../utils/get-product-claim-fields'
import { FailedProductShipmentSection } from './claim/failed-product-shipment'
import { FulfilledProductShipmentSection } from './claim/fulfilled-product-shipment'

import styles from './product-protection-claim-view.module.css'
import { CollapsibleSection } from '../../components/collapsible-section'
import { AcceptServiceOrderButton } from './claim/accept-service-order-button'
import { AcceptServiceOrderModal } from './claim/accept-service-order-modal'
import { CompleteRepairOnsiteButton } from './claim/complete-repair-onsite-button'
import { CompleteRepairOnsiteModal } from './claim/complete-repair-onsite-modal'

import { BadgeDescriptions } from '../../components/badge-descriptions'
import {
  getServiceOrderBadgeStatus,
  getServiceOrderBadgeStatusDescriptions,
} from '../../utils/get-service-order-status-copy'
import { contractDetailsAtom } from '../../atoms/contract-details'
import { claimDetailsAtom } from '../../atoms/claim-details'
import { serviceOrdersAtom } from '../../atoms/service-orders-atom'
import { StartRepairButton } from './claim/start-repair-button'
import { StartRepairModal } from './claim/start-repair-modal'
import { CompleteRepairDepotButton } from './claim/complete-repair-depot-button'
import { CompleteRepairDepotModal } from './claim/complete-repair-depot-modal'
import { ProductProtectionShipmentTable } from './claim/product-protection-shipment-table'
import { ServicerSection } from './claim/servicer-section'
import { ClaimTabSection } from './claim/claim-tab-section'
import { ContractProductsTable } from './claim/contract-products-table'

export const ProductProtectionClaimView: FC = () => {
  const contract = useAtomValue(contractDetailsAtom)
  const claim = useAtomValue(claimDetailsAtom)
  const serviceOrders = useAtomValue(serviceOrdersAtom)

  const fields = useMemo(() => getProductClaimFields(contract, claim), [contract, claim])

  return (
    <div className={styles['claims-wrapper']}>
      <CollapsibleSection heading="Customer Information" data-cy="customer-information">
        <DataPropertyGrid values={fields.customer} className={styles.grid} />
      </CollapsibleSection>
      <CollapsibleSection heading="Claim Information" data-cy="claim-information" isExpanded>
        <DataPropertyGrid values={fields.claimInformation} className={styles.grid} />
      </CollapsibleSection>
      <CollapsibleSection heading="Product Information" data-cy="product-information" isExpanded>
        <ContractProductsTable contract={contract} />
      </CollapsibleSection>
      <CollapsibleSection heading="Items Requiring Fulfillment" data-cy="ProductShipmentTable">
        <ProductProtectionShipmentTable claim={claim} contract={contract} />
      </CollapsibleSection>
      {serviceOrders &&
        serviceOrders.map((serviceOrder) => (
          <CollapsibleSection
            data-cy="servicer-order-information"
            isExpanded
            heading="Service Order Information"
            key={serviceOrder.id}
            badgeProps={getServiceOrderBadgeStatus(serviceOrder)}
            information={
              <BadgeDescriptions
                data-cy="service-order-infotip:badge-descriptions"
                descriptions={getServiceOrderBadgeStatusDescriptions(serviceOrder)}
                buttonSize="small"
                shouldWrapInfoIcon={false}
              />
            }
            informationPopoverSize={InformationSize.large}
          >
            <ServiceOrderInformation serviceOrder={serviceOrder} />
            {serviceOrder.assignedServicer && (
              <>
                <hr />
                <ServicerSection serviceOrder={serviceOrder} data-cy="servicer-section" />
              </>
            )}
            <hr />
            <FailedProductShipmentSection
              serviceOrder={serviceOrder}
              packingSlipURL={serviceOrder.attachments?.packingSlip?.packingSlipUrl || ''}
            />
            <hr />
            <FulfilledProductShipmentSection serviceOrder={serviceOrder} />
            {showExpenses(serviceOrder) && (
              <>
                <hr />
                <AccordionSection heading="Expenses" headingSize="sub" data-cy="expenses">
                  <Expenses serviceOrder={serviceOrder} />
                </AccordionSection>
              </>
            )}
            <AcceptServiceOrderButton serviceOrder={serviceOrder} />
            <AcceptServiceOrderModal serviceOrder={serviceOrder} />
            <StartRepairButton serviceOrder={serviceOrder} />
            <StartRepairModal serviceOrder={serviceOrder} />
            <CompleteRepairDepotButton serviceOrder={serviceOrder} />
            <CompleteRepairDepotModal serviceOrder={serviceOrder} />
            <CompleteRepairOnsiteButton serviceOrder={serviceOrder} />
            <CompleteRepairOnsiteModal serviceOrder={serviceOrder} />
          </CollapsibleSection>
        ))}
      <ClaimTabSection claim={claim}></ClaimTabSection>
    </div>
  )
}
