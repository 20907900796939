import type { AuditItem } from '@helloextend/extend-api-client'

export const generateAuditItem = <T>(
  item: T,
  resourceType: AuditItem<T>['resourceType'],
  overrides?: Partial<AuditItem<T>>,
): AuditItem<T> => ({
  resourceType,
  item: item,
  actor: 'actor',
  reason: 'some reason',
  method: 'some method',
  eventTime: new Date().getTime(),
  changes: [{ kind: 'E', lhs: 'Jon', rhs: 'Jonathan', path: ['customer', 'name'] }],
  ...overrides,
})
