// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._12gGsbp8CeZh2WTYqis0Gq {\n    display: flex;\n    padding: 0 40px;\n    box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5%);\n    height: 80px;\n    align-items: center;\n    justify-content: space-between;\n    background: linear-gradient(to left, var(--bright-blue), var(--blue-1000));\n}\n\n._1posEZk_kLNfzXPHNgpn3X {\n    width: 104px;\n    height: 24px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_12gGsbp8CeZh2WTYqis0Gq",
	"img": "_1posEZk_kLNfzXPHNgpn3X"
};
module.exports = exports;
