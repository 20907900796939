import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@extend/zen'
// Uncomment when photo fetching no longer uses presigned URLs
// import { Download, Trash } from '@extend/zen'
import { Trash } from '@extend/zen'
import type { ClaimPhotoDetail } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'

type ClaimPhotoCardProps = {
  onDownloadClick: () => void
  onDeleteClick: () => void
  photoDetail: ClaimPhotoDetail
  category?: string
}

export const formatDateTime = (value?: number, omitTime?: boolean): string => {
  const format = omitTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY [at] hh:mm:ss A'
  return typeof value === 'number' ? date.format(value, format) : ''
}

const ClaimPhotoCard: FC<ClaimPhotoCardProps> = ({
  // Uncomment when photo fetching no longer uses presigned URLs
  // onDownloadClick,
  onDeleteClick,
  photoDetail,
  category,
}) => {
  const { source, description, name, url, createdBy, createdAt } = photoDetail

  const uploadedBy = source === 'chatbot' ? 'Customer' : createdBy

  const photoDescription = description || `${category} - ${name}`

  return (
    <CardWrapper>
      <PhotoImage src={url} data-cy={`card-image-${name}`} />
      <PhotoDetails>
        <PhotoName data-tooltip={photoDescription} data-cy={`card-name-${name}`}>
          {photoDescription}
        </PhotoName>
        <Footer>
          <UploadedBy data-cy={`card-uploaded-by-${name}`}>
            Uploaded {formatDateTime(createdAt)} by {uploadedBy}
          </UploadedBy>
          <ActionButtons>
            {
              // Uncomment when photo fetching no longer uses presigned URLs
              /* <Button
              icon={Download}
              size="xsmall"
              emphasis="low"
              onClick={onDownloadClick}
              color="neutral"
              data-cy={`card-download-image-button-${name}`}
            /> */
            }
            <Button
              icon={Trash}
              size="xsmall"
              emphasis="low"
              onClick={onDeleteClick}
              color="neutral"
              data-cy={`card-delete-image-button-${name}`}
            />
          </ActionButtons>
        </Footer>
      </PhotoDetails>
    </CardWrapper>
  )
}

const CardWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 340,
  'border-radius': '8px',
  overflow: 'hidden',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  alignSelf: 'flex-start',
})

const PhotoImage = styled.img({
  width: '100%',
  height: 226,
})

const PhotoDetails = styled.div({
  display: 'flex',
  width: `calc(100% - 2 * 16px - 2px)`, // width - padding - border
  padding: '16px',
  gap: '6px',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const PhotoName = styled.div({
  alignSelf: 'stretch',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
})

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '6px',
  alignSelf: 'stretch',
  width: '100%',
})

const UploadedBy = styled.div({
  flex: '1 0 0',
  alignSelf: 'stretch',
  fontColor: COLOR.NEUTRAL[800],
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '24px',
})

const ActionButtons = styled.div({
  display: 'flex',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
})

export { ClaimPhotoCard }
