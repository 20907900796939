import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Popover, PopoverDirection, SidebarMenuItem, Stack, usePopover } from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { applySorting } from '@helloextend/client-hooks/src/use-sort-by'
import { Permission } from '../lib/permissions'
import { usePermissions } from '../hooks/use-permissions'
import { DashboardSidebarStoreMenuItem } from './dashboard-sidebar-store-menu-item'
import { getActiveStoreAtom } from '../atoms/stores'
import { useFetchAllStoresQuery } from '../queries/stores'
import styles from './dashboard-sidebar-store-picker.module.css'

import { DashboardSidebarActiveStoreMenuItem } from './dashboard-sidebar-active-store-menu-item'

export const DashboardSidebarStorePicker: FC = () => {
  const { hasPermission } = usePermissions()

  const activeStore = useAtomValue(getActiveStoreAtom)
  const { data: stores = [] } = useFetchAllStoresQuery()

  const canViewStores = hasPermission(Permission.ViewStores)

  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLDivElement>()

  const getMenuItemProps = useMemo(() => {
    return canViewStores
      ? {
          showArrow: true,
          ref: triggerRef,
          isToggled: isPresent,
          onClick: toggle,
        }
      : {}
  }, [canViewStores, triggerRef, isPresent, toggle])

  if (!activeStore) {
    return null
  }

  const tempSortedStores = applySorting(stores, 'name', 'asc').filter(
    (store) => store.id !== activeStore.id,
  )
  const sortedStores = activeStore ? [activeStore, ...tempSortedStores] : []

  const shouldDisplayStorePopOver = sortedStores?.length > 1

  return (
    <>
      {shouldDisplayStorePopOver ? (
        <>
          <SidebarMenuItem {...getMenuItemProps} data-cy="store-dropdown">
            <DashboardSidebarActiveStoreMenuItem activeStore={activeStore} />
          </SidebarMenuItem>
          <Popover
            ref={popoverRef}
            direction={PopoverDirection.right}
            isPresent={isPresent}
            maxHeight={480}
            triggerBoundingBox={triggerBoundingBox}
          >
            <Stack padding={1}>
              <ul className={styles['store-dropdown-list']}>
                {sortedStores.map((filteredStore) => {
                  const isSelectedStore = filteredStore.id === activeStore.id
                  return (
                    <li key={filteredStore.id}>
                      <DashboardSidebarStoreMenuItem
                        store={filteredStore}
                        isSelectedStore={isSelectedStore}
                        toggle={toggle}
                      />
                    </li>
                  )
                })}
              </ul>
            </Stack>
          </Popover>
        </>
      ) : (
        <div className={styles['active-store-wrapper']}>
          <DashboardSidebarActiveStoreMenuItem activeStore={activeStore} />
        </div>
      )}
    </>
  )
}
