import type { FC } from 'react'
import React from 'react'
import type { MerchantProduct } from '../../../types/merchant-product'
import { SubHeader } from '../../../components/sub-header'
import styles from './product-details-manufacturer-info.module.css'

type ProductDetailsManufacturingInfoProps = {
  product: MerchantProduct
}

type ValueOf<T> = T[keyof T]

export const ProductDetailsManufacturingInfo: FC<ProductDetailsManufacturingInfoProps> = ({
  product,
}) => {
  const getValue = (value: ValueOf<MerchantProduct>): string => {
    return `${value ?? 'N/A'}`
  }
  return (
    <>
      <div className={styles.details}>
        <SubHeader labelText="Manufacturing Info" />
        <div className={styles.row}>
          <div className={styles.detail}>
            <span className={styles.label}>MFR Length Labor</span>
            <span className={styles.value} data-cy="product-details-labor">
              {getValue(product.mfrWarranty?.labor)}
            </span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>MFR Length Parts</span>
            <span className={styles.value} data-cy="product-details-parts">
              {getValue(product.mfrWarranty?.parts)}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
