// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1dDDtCncZ22fWHm9Oya4cX {\n    display: flex;\n    align-items: center;\n    margin-top: 4px;\n    gap: 10px;\n}\n\n._2LdPaDKMgNSg59WEH9S-_o {\n    width: 24px;\n    height: 24px;\n    border-radius: 4px;\n    box-shadow: 0 0 0 1px var(--neutral-opacity-50);\n}\n\n._3FV3NgXMhSW589acVFP-ju {\n    margin: 0;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1dDDtCncZ22fWHm9Oya4cX",
	"preview": "_2LdPaDKMgNSg59WEH9S-_o",
	"text": "_3FV3NgXMhSW589acVFP-ju"
};
module.exports = exports;
