import type { FC } from 'react'
import React from 'react'
import { Button, ButtonGroup, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { BasicModal } from '@helloextend/merchants-ui'
import { useAtom, useAtomValue } from 'jotai/react'
import { useResetAtom } from 'jotai/react/utils'
import { confirmProtectionDisableModalAtom } from '../../../atoms/confirm-protection-disable-modal'
import { useUpdateStoreMutation } from '../../../queries/stores'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { getProtectionToggleDetails } from '../../../utils/get-protection-toggle-details'

export const ProtectionWarningModal: FC = () => {
  const { mutateAsync: updateStore, isLoading } = useUpdateStoreMutation()
  const store = useAtomValue(getActiveStoreAtom)
  const [{ isVisible, label, protectionType }] = useAtom(confirmProtectionDisableModalAtom)
  const { toast } = useToaster()
  const closeModal = useResetAtom(confirmProtectionDisableModalAtom)
  const { capitalizedProtectionType, updateStoreDataShape } = getProtectionToggleDetails({
    protectionType,
    store,
    isEnabling: false,
  })
  const onConfirm = async (): Promise<void> => {
    closeModal()
    try {
      await updateStore({
        storeId: store?.id || '',
        store: updateStoreDataShape,
        version: 'latest',
      })
      toast({
        message: `${capitalizedProtectionType} protection is now paused in your store`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch {
      toast({
        message: `There was a ${protectionType} protection status update error. Please try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
  }

  return (
    <BasicModal data-cy="confirm-modal" isVisible={isVisible} onClickClose={closeModal}>
      <div className="flex flex-col">
        <div style={{ fontSize: 20, fontWeight: 700 }} data-cy="confirm-modal-header">
          You are disabling {label} in your store
        </div>
        <div className="flex flex-col justify-content-between" data-cy="confirm-modal-context">
          <p>
            Your customers will be unable to see or buy {label} plans in your store until you set
            Extend to live again.
          </p>
          <p>Are you sure you want to disable {label} for now?</p>
        </div>
        <div className="align-self-end">
          <ButtonGroup>
            <Button onClick={closeModal} emphasis="medium" data-cy="modal-cancel" text="Cancel" />
            <Button
              onClick={onConfirm}
              color="red"
              type="submit"
              data-cy="modal-submit"
              isProcessing={isLoading}
              text="Disable"
            />
          </ButtonGroup>
        </div>
      </div>
    </BasicModal>
  )
}
