import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAtom } from 'jotai/react'
import { loginRedirectPathAtom } from '../atoms/login-redirect'
import { useUser } from './use-user'

/*
 * IF MAKING CHANGES TO THIS FILE, BE SURE TO TEST THE ENTIRE FLOW.
 * THIS INCLUDES LOGGING IN, LOGGING OUT, APP REDIRECTS TO CONTRACTS PAGE IF YOU ARE LOGGED IN AND TRY TO GO TO LOGIN PAGE, WHEN TOKEN EXPIRES THE APP REDIRECTS TO LOGIN PAGE
 */
const useLoginRedirect = (defaultRedirectPath = '/'): void => {
  const { replace } = useHistory()
  const { search } = useLocation()

  const isShopify = Boolean(new URLSearchParams(search).get('shopifyUrl'))

  const { isLoggedIn } = useUser()
  const [loginRedirectPath, setLoginRedirectPath] = useAtom(loginRedirectPathAtom)

  useEffect(() => {
    if (isLoggedIn && !isShopify) {
      setLoginRedirectPath('')
      replace(loginRedirectPath || defaultRedirectPath)
    }
    return () => {}
  }, [defaultRedirectPath, loginRedirectPath, isLoggedIn, replace, setLoginRedirectPath, isShopify])
}

export { useLoginRedirect }
