import type { FC, ReactElement } from 'react'
import React from 'react'
import { Button } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import images from '../images'
import { ExternalLink } from './icons'
import { shouldShowSupportPopoverAtom } from '../atoms/support'
import styles from './error-message.module.css'

export const MAGENTO_USER_GUIDE_LINK =
  'https://marketplace.magento.com/media/catalog/product/extend-module-warranty-100-1-0-ce/installation_guides.pdf'
export const API_DOCUMENTATION_LINK = 'https://developers.extend.com/'
export const BIG_COMMERCE_ERROR_MESSAGE =
  'To import your catalog, please reach out to your Merchant Success Manager.'
export const SALESFORCE_ERROR_MESSAGE = 'To import your catalog, please refer to the setup guide'

const CustomErrorMessage = (): ReactElement => {
  return (
    <p className="no-margin no-padding">
      To import your catalog, check out our&nbsp;
      <a href={API_DOCUMENTATION_LINK} target="_blank" rel="noopener noreferrer">
        API documentation <ExternalLink />
      </a>
    </p>
  )
}

const platformErrorMessageMap: Record<string, ReactElement> = {
  'big-commerce': <p className="no-margin no-padding">{BIG_COMMERCE_ERROR_MESSAGE}</p>,
  magento: (
    <p className="no-margin no-padding">
      To import your catalog, please refer to the&nbsp;
      <a href={MAGENTO_USER_GUIDE_LINK} target="_blank" rel="noopener noreferrer">
        user guides in Magento <ExternalLink />
      </a>
    </p>
  ),
  salesforce: <p className="no-margin no-padding">{SALESFORCE_ERROR_MESSAGE}</p>,
  custom: <CustomErrorMessage />,
}

export const ErrorMessage: FC<{
  platform: string
}> = ({ platform }) => {
  const setShowSupportPopover = useSetAtom(shouldShowSupportPopoverAtom)
  const showSupportPopover = (): void => {
    setShowSupportPopover(true)
  }

  if (platform === 'shopify') {
    return (
      <div
        className={`${styles.wrapper} text-center`}
        data-cy="products-zero-state:message-container"
      >
        <h2 className={styles.header}>
          Sorry, we didn&apos;t find any products in your Shopify store.
        </h2>
        <div className={styles.caption}>
          {'This could be because your store has no products, ' +
            'or we ran into an error when we tried to import them.\n' +
            "If you believe it's the latter case, please contact Support " +
            "and we'll help you out right away!"}
        </div>

        <div className={styles['button-wrapper']}>
          <Button text="Get help" emphasis="medium" onClick={showSupportPopover} />
        </div>
      </div>
    )
  }
  return (
    <div
      className={`${styles.wrapper} text-center`}
      data-cy="products-zero-state:message-container"
    >
      <img
        className={styles['product-icons']}
        src={images.products}
        alt="error state icon"
        width="400px"
      />
      <h2 className={styles.header}>Import your catalog to see your products</h2>
      <div className={styles.caption}>
        {platformErrorMessageMap[platform] || <CustomErrorMessage />}
        <p className="no-margin no-padding">
          You can get your API keys in <a href="/store/settings">settings</a>
        </p>
      </div>
      <div className={styles['button-wrapper']}>
        <Button text="Get help" emphasis="medium" onClick={showSupportPopover} />
      </div>
    </div>
  )
}
