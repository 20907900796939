import { Input, InputType, Visibility, VisibilityOff } from '@extend/zen'
import type { FC, SyntheticEvent, FocusEvent } from 'react'
import React, { useState } from 'react'

interface MaskedAchInputProps {
  handleChange: (e: SyntheticEvent) => void
  handleOnBlur: (e: FocusEvent<HTMLInputElement>) => void
  handleOnFocus: (e: FocusEvent<HTMLInputElement>) => void
  label: string
  id: string
  value: string
  isValid: boolean
  focusedInput?: string
  touched?: boolean
  error?: string
  invalidErrorMessage?: string
  isDisabled?: boolean
}

export const MaskedAchInput: FC<MaskedAchInputProps> = ({
  handleChange,
  handleOnBlur,
  handleOnFocus,
  label,
  id,
  focusedInput,
  touched,
  error,
  value,
  invalidErrorMessage,
  isValid,
  isDisabled,
}) => {
  const [isMasked, setIsMasked] = useState(false)
  const shouldMaskNumber = focusedInput === id || touched
  const inputType = shouldMaskNumber && !isMasked ? InputType.password : InputType.text
  const displayValue = shouldMaskNumber ? value : `${value && '...'}${value}`

  return (
    <Input
      data-cy={id}
      label={label}
      id={id}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      type={inputType}
      value={displayValue}
      isError={(touched && Boolean(error)) || !isValid}
      errorFeedback={error || invalidErrorMessage}
      isDisabled={isDisabled}
      actionButtonProps={
        shouldMaskNumber
          ? {
              emphasis: 'low',
              color: 'neutral',
              icon: isMasked ? VisibilityOff : Visibility,
              onClick: () => setIsMasked(!isMasked),
            }
          : undefined
      }
    />
  )
}
