import type { FC } from 'react'
import React, { useRef, useCallback } from 'react'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { ProductDataTableRdb } from '../product/product-data-table-rdb/product-data-table-rdb'
import { StandardHeader } from '../../components/standard-header'
import { useExportProductsMutation } from '../../queries/products'

export const Products: FC = () => {
  const { toast } = useToaster()
  const topRef = useRef<HTMLDivElement>(null)
  const { mutateAsync: exportProducts, isLoading } = useExportProductsMutation()

  const handleClickExportProducts = useCallback(async () => {
    try {
      const response = await exportProducts()
      if ('message' in response && response.message === 'Job Started') {
        toast({
          message:
            'Your data is being prepared. You will receive an email with a download link shortly.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.blue,
        })
      } else {
        toast({
          message:
            'You can only download a new export once every 24 hours. Check your email to download your recent export.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.yellow,
        })
      }
    } catch (err) {
      toast({
        message: 'There was an error exporting products, please try again later.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [exportProducts, toast])

  return (
    <>
      <StandardHeader
        ref={topRef}
        pageTitle="Products"
        buttons={[
          {
            onClick: handleClickExportProducts,
            text: 'Export Products',
            emphasis: 'medium',
            isProcessing: isLoading,
          },
        ]}
      />
      <ProductDataTableRdb />
    </>
  )
}
