import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import { customLogger } from '@extend/client-helpers'

export const logRtkQueryErrors: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    customLogger.warn('Error from RTK query', { error: action.error })
  }

  return next(action)
}
