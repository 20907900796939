import type { FC } from 'react'
import React from 'react'
import { Loader } from '@helloextend/merchants-ui'
import { Badge } from '@extend/zen'
import type { Theme } from '@helloextend/extend-api-rtk-query'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'
import styles from './theme-name-display.module.css'

export type ThemeNameDisplayProps = {
  isLoading: boolean
  isDraftMode: boolean
  draftThemeName: string | null
  currentlySelectedTheme: Theme | null
}

export const ThemeNameDisplay: FC<ThemeNameDisplayProps> = ({
  isLoading,
  isDraftMode,
  draftThemeName,
  currentlySelectedTheme,
}) => {
  const transformStatus = (apiStatus: string): string => {
    return apiStatus === ThemePublishedStatus.published ? 'Published' : 'Unpublished'
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Loader width="380px" height="100%" />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {isDraftMode ? (
        <>
          {draftThemeName}
          <Badge
            color="neutral"
            emphasis="medium"
            size="regular"
            text="Draft"
            data-cy="published-theme"
          />
        </>
      ) : (
        <>
          {currentlySelectedTheme?.name}
          <Badge
            color={
              currentlySelectedTheme?.status?.toUpperCase() ===
              ThemePublishedStatus.published.toUpperCase()
                ? 'green'
                : 'neutral'
            }
            emphasis="medium"
            size="regular"
            text={transformStatus(currentlySelectedTheme?.status ?? '')}
            data-cy="published-theme"
          />
        </>
      )}
    </div>
  )
}
