import { atom } from 'jotai/vanilla'

export const reactivateUserModalEmailAtom = atom<string>('')

export const isReactivateUserModalVisible = atom((get) =>
  Boolean(get(reactivateUserModalEmailAtom)),
)

export const openReactivateUserModal = atom(null, (_get, set, email: string) => {
  set(reactivateUserModalEmailAtom, email)
})

export const closeReactivateUserModal = atom(null, (_get, set) => {
  set(reactivateUserModalEmailAtom, '')
})
