import { ClaimType } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import type { PackingSlipConfiguration } from '@helloextend/extend-api-client/src/models/servicer'
import type { MerchantContract } from '../types/merchant-contract'
import type { MerchantClaim } from '../types/claims'

const generateSchema = (
  serviceOrder?: ServiceOrder,
  claim?: MerchantClaim,
  contract?: MerchantContract,
): Array<{
  key: keyof PackingSlipConfiguration | 'instructions'
  label: string
  value: string
}> => {
  const contractProduct =
    claim?.type === ClaimType.CATEGORY
      ? contract?.categoryProductsList?.find(
          (p) => p.referenceId === claim?.products?.[0]?.referenceId,
        )
      : contract?.product

  const schema: Array<{
    key: keyof PackingSlipConfiguration | 'instructions'
    label: string
    value: string
  }> = [
    { key: 'contractId', label: 'Contract ID', value: serviceOrder?.contractId || '' },
    { key: 'claimId', label: 'Claim ID', value: serviceOrder?.claimId || '' },
    { key: 'serviceOrderId', label: 'Service Order ID', value: serviceOrder?.id || '' },
    { key: 'orderNumber', label: 'Order Number', value: contract?.transactionId || '' },
    { key: 'customerName', label: 'Customer Name', value: claim?.customer.name || '' },
    { key: 'productName', label: 'Product Name', value: contractProduct?.title || '' },
    {
      key: 'productReferenceId',
      label: 'Product Reference ID',
      value: contractProduct?.referenceId ?? '',
    },
    {
      key: 'productListPrice',
      label: 'Product List Price',
      value: contractProduct?.listPrice ? currency.format(contractProduct?.listPrice) : '',
    },
    {
      key: 'productPurchasePrice',
      label: 'Product Purchase Price',
      value: contractProduct?.purchasePrice ? currency.format(contractProduct?.purchasePrice) : '',
    },
    {
      key: 'failureType',
      label: 'Failure Type',
      value: claim?.incident.failureType ?? '',
    },
    {
      key: 'failureDescription',
      label: 'Failure Description',
      value: claim?.incident.description ?? '',
    },
  ]

  if (serviceOrder?.attachments?.packingSlip?.instructions) {
    schema.push({
      key: 'instructions',
      label: 'Instructions',
      value: serviceOrder?.attachments?.packingSlip.instructions,
    })
  }

  return schema
}

export { generateSchema }
