import type { FC } from 'react'
import React, { useState } from 'react'
import {
  DataProperty,
  Info,
  InlineAlert,
  InlineAlertColor,
  Input,
  Modal,
  ModalController,
  TextArea,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useAtom } from 'jotai/react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { isAcceptServiceOrderModalVisibleAtom } from '../../../atoms/accept-service-order-modal'
import styles from './accept-service-order-modal.module.css'
import { useAcceptServiceOrderMutation } from '../../../queries/service-orders'
import { useGetAcceptServiceOrderPackingSlipDetails } from '../../../hooks/use-get-accept-service-order-packing-slip-details'

export const PACKING_SLIP_TEXT =
  'Please review and complete the packing slip information before accepting the service order. Once the service order has been accepted, the packing slip cannot be modified.'
export const RMA_REQUIRED_TEXT = 'RMA Number required to accept this service order.'
export const AcceptServiceOrderModal: FC<{
  serviceOrder: ServiceOrder
}> = ({ serviceOrder }) => {
  const [isVisible, setIsVisible] = useAtom(isAcceptServiceOrderModalVisibleAtom)
  const [rmaInputValue, setRMAInputValue] = useState(serviceOrder.rmaNumber || '')
  const [hasRMAInputBeenBlurred, setHasRMAInputBeenBlurred] = useState(false)
  const [instructionsInputValue, setInstructionsInputValue] = useState(
    serviceOrder.attachments?.packingSlip?.instructions || '',
  )
  const { toast } = useToaster()
  const { mutateAsync, isLoading } = useAcceptServiceOrderMutation()
  const closeModal = (): void => setIsVisible(false)
  const isPackingSlipRequired = serviceOrder.configurations?.packingSlipRequired
  const { packingSlipDataProperties } = useGetAcceptServiceOrderPackingSlipDetails(serviceOrder)
  const handleSubmit = async (): Promise<void> => {
    try {
      await mutateAsync({
        serviceOrderId: serviceOrder.id,
        body: { rmaNumber: rmaInputValue, instructions: instructionsInputValue },
      })
      closeModal()
      toast({
        message: 'The service order has successfully been accepted',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    } catch {
      toast({
        message: 'There was an error accepting the service order. Please try again',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Accept Service Order"
        onDismissRequest={closeModal}
        primaryButtonProps={{
          text: 'Accept',
          isDisabled: !rmaInputValue,
          onClick: handleSubmit,
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: closeModal }}
      >
        <div className={styles.container}>
          <InlineAlert color={InlineAlertColor.blue} icon={Info} isDismissable={false}>
            {isPackingSlipRequired ? PACKING_SLIP_TEXT : RMA_REQUIRED_TEXT}
          </InlineAlert>
          {serviceOrder.configurations?.packingSlipRequired && (
            <>
              {packingSlipDataProperties.map(({ key, label, value }) => (
                <DataProperty label={label} value={value} key={key} />
              ))}
            </>
          )}
          {serviceOrder.configurations?.packingSlipRequired && (
            <TextArea
              label="Additional Information"
              maxLength={3000}
              onChange={(e) => setInstructionsInputValue(e.target.value)}
              value={instructionsInputValue}
              id="instructions"
              data-cy="instructions"
            />
          )}
          <Input
            value={rmaInputValue}
            label="RMA Number"
            onBlur={() => setHasRMAInputBeenBlurred(true)}
            isError={!rmaInputValue && hasRMAInputBeenBlurred}
            errorFeedback="Please enter an RMA number"
            placeholder={
              isPackingSlipRequired ? 'An RMA number is required to accept this service order.' : ''
            }
            onChange={(e) => setRMAInputValue(e.target.value)}
            id="rmaNumber"
          />
        </div>
      </Modal>
    </ModalController>
  )
}
