import { Button } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useSetAtom, useAtomValue } from 'jotai/react'
import { Permission } from '../../../lib/permissions'
import { usePermissions } from '../../../hooks/use-permissions'
import { isCompleteRepairOnsiteModalVisibleAtom } from '../../../atoms/complete-repair-onsite-modal'
import { claimDetailsAtom } from '../../../atoms/claim-details'

export const CompleteRepairOnsiteButton: FC<{ serviceOrder: ServiceOrder }> = ({
  serviceOrder,
}) => {
  const { hasPermission } = usePermissions()
  const claim = useAtomValue(claimDetailsAtom)
  const setIsCompleteRepairModalVisible = useSetAtom(isCompleteRepairOnsiteModalVisibleAtom)
  const shouldShowCompleteOnsiteRepairButton = useMemo(() => {
    return (
      hasPermission(Permission.ServiceOrderFullAccess) &&
      claim.status === 'approved' &&
      serviceOrder?.status === 'accepted' &&
      claim.serviceType === 'repair_onsite'
    )
  }, [claim.serviceType, claim.status, hasPermission, serviceOrder?.status])

  const handleOnClick = async (): Promise<void> => {
    return setIsCompleteRepairModalVisible(true)
  }

  if (shouldShowCompleteOnsiteRepairButton) {
    return (
      <>
        <hr />
        <Button text="Complete Repair" onClick={handleOnClick} />
      </>
    )
  }

  return null
}
