import React from 'react'
import type { CellContext, ColumnDefs, FilterDef } from '@extend/zen'
import { Badge } from '@extend/zen'
import { Descriptions } from '../../../components/badge-descriptions'
import type { Grant } from '../../../types/okta'
import type { ExtendUserAccountStatus } from '../../../utils/okta-to-extend-status-utils'
import {
  getExtendUserStatusBadgeItem,
  STATUS_MAP,
  FILTER_OPTIONS,
} from '../../../utils/okta-to-extend-status-utils'

export type UserTableMapping = {
  firstName: string
  lastName: string
  email: string
  status: ExtendUserAccountStatus
  grants: Grant[]
  uuid: string
}

export const TABLE_COLUMNS: ColumnDefs<UserTableMapping> = [
  {
    id: 'firstName',
    label: 'First Name',
    renderCell: (cellData: CellContext<UserTableMapping, string>) => cellData.getValue(),
    search: 'explicit',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    renderCell: (cellData: CellContext<UserTableMapping, string>) => cellData.getValue(),
    search: 'explicit',
  },
  {
    id: 'email',
    label: 'Email',
    renderCell: (cellData: CellContext<UserTableMapping, string>) => cellData.getValue(),
    search: 'explicit',
  },
  {
    id: 'status',
    label: 'Status',
    information: <Descriptions descriptions={STATUS_MAP} />,
    renderCell: (cellData: CellContext<UserTableMapping, ExtendUserAccountStatus>) => {
      const { text, color } = getExtendUserStatusBadgeItem(cellData.getValue())
      return <Badge text={text} color={color} />
    },
  },
]

export const FILTER_DEFS: FilterDef[] = [
  {
    type: 'group',
    filterDefs: [
      {
        id: 'status',
        type: 'select',
        label: 'Status',
        options: FILTER_OPTIONS,
      },
    ],
  },
]
