import { StorePlatform } from './store-options'

const { BigCommerce, CommerceCloud, Custom, Magento, Shopify } = StorePlatform

const ZENDESK_PLATFORM_OPTIONS = {
  [BigCommerce]: 'merchant_platform_bigcommerce',
  [CommerceCloud]: 'merchant_platform_sfcc',
  [Custom]: 'merchant_platform_other',
  [Magento]: 'merchant_platform_magento',
  [Shopify]: 'merchant_platform_shopify',
}

export enum SupportRequestType {
  BUSINESS_SUCCESS = 'merchant_request_type_business',
  BILLING_INVOICING = 'billing_request',
  PRODUCT_MAPPING = 'product_mapping_request',
  CONTRACT_QUESTIONS = 'contract_questions',
  TECHNICAL_SUPPORT = 'merchant_request_type_technical',
  REINTEGRATION_REQUESRT = 'reintegration',
  REPAIRS_SUPPORT = 'repairs_support',
  VIRTUAL_GIFT_CARD = 'virtual_gift_card',
  GENERAL_OTHER = 'general_request',
  APPROVAL_REQUEST = 'approval_request',
}

export const SUPPORT_REQUEST_OPTIONS_APPROVED_MERCHANT = [
  { display: 'Business Success', value: SupportRequestType.BUSINESS_SUCCESS },
  { display: 'Billing/Invoicing', value: SupportRequestType.BILLING_INVOICING },
  { display: 'Product Mapping', value: SupportRequestType.PRODUCT_MAPPING },
  { display: 'Contract Questions', value: SupportRequestType.CONTRACT_QUESTIONS },
  { display: 'Technical Support', value: SupportRequestType.TECHNICAL_SUPPORT },
  { display: 'Reintegration Request', value: SupportRequestType.REINTEGRATION_REQUESRT },
  { display: 'Repairs Support', value: SupportRequestType.REPAIRS_SUPPORT },
  { display: 'Virtual Gift Card', value: SupportRequestType.VIRTUAL_GIFT_CARD },
  { display: 'General/Other', value: SupportRequestType.GENERAL_OTHER },
]

export const SUPPORT_REQUEST_OPTIONS_UNAPPROVED_MERCHANT = [
  { display: 'Approval Request', value: SupportRequestType.APPROVAL_REQUEST },
]

export const MERCHANT_CONTACT_METHOD = 'merchant_contact_method_form'

export const getZendeskStoreValue = (storePlatform: StorePlatform): string =>
  ZENDESK_PLATFORM_OPTIONS[storePlatform] || ''
