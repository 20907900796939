import React from 'react'

const MockProductImage = (): JSX.Element => (
  <svg width="73" height="84" viewBox="0 0 73 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.3151 41.9994L36.3151 83.7409L0 62.8702V21.1287L36.3151 41.9994Z" fill="#A9B1BB" />
    <path
      d="M36.3153 41.9994L36.3153 83.7409L72.6304 62.8702V21.1287L36.3153 41.9994Z"
      fill="#C9D1DC"
    />
    <path
      d="M36.6494 0.257812L72.7986 21.1286L36.5664 42.143L0.417236 21.2722L36.6494 0.257812Z"
      fill="#E3EAF0"
    />
  </svg>
)

export { MockProductImage }
