import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { CurrencyCode } from '@helloextend/extend-api-client'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const ENTITLEMENTS_CACHE_KEY = 'Entitlements'

export function useGetEntitlementsQuery({
  contractId,
  enabled = true,
}: {
  contractId: string
  enabled?: boolean
}): UseQueryResult<EntitlementsResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`https://${EXTEND_API_HOST}/entitlements/contract/${contractId}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json; version=latest',
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get entitlements')
    }

    return response.json()
  }, [accessToken, contractId])

  return useQuery({
    queryKey: [ENTITLEMENTS_CACHE_KEY, { id: contractId }],
    queryFn,
    enabled,
  })
}

export function useGetEntitlementsCategoryQuery({
  contractId,
  lineItemId,
  enabled = true,
}: {
  contractId: string
  lineItemId: string
  enabled?: boolean
}): UseQueryResult<EntitlementsResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(
      `https://${EXTEND_API_HOST}/entitlements/contract/${contractId}/lineItem/${lineItemId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json; version=latest',
          'x-extend-access-token': accessToken,
        },
        method: 'GET',
      },
    )

    if (!response.ok) {
      throw new Error('Unable to get entitlements')
    }

    return response.json()
  }, [accessToken, contractId, lineItemId])

  return useQuery({
    queryKey: [ENTITLEMENTS_CACHE_KEY, { id: contractId }],
    queryFn,
    enabled,
  })
}

type EntitlementsResponse = {
  coverageAmountRemaining: {
    amount: number
    currencyCode: CurrencyCode
  }
}
