import { Help, HeaderMenuItem, Popover, PopoverAlignment, usePopover } from '@extend/zen'
import { useAtom, useSetAtom } from 'jotai/react'
import { useResetAtom } from 'jotai/react/utils'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import {
  shouldShowSupportPopoverContactFormAtom,
  shouldShowSupportPopoverAtom,
  supportPopoverContactFormDefaultsAtom,
} from '../../atoms/support'
import { SupportPopover } from './support-popover'
import styles from './support.module.css'

export const Support: FC = () => {
  const setShouldShowContactForm = useSetAtom(shouldShowSupportPopoverContactFormAtom)
  const resetPopover = useResetAtom(supportPopoverContactFormDefaultsAtom)
  const [shouldShowSupportPopover, setShouldShowSupportPopover] = useAtom(
    shouldShowSupportPopoverAtom,
  )

  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, show, hide } =
    usePopover<HTMLDivElement>({
      onHide: () => {
        resetPopover()
        // Don't allow flicker back to previous screen *while* close animation
        // is still running due to Popover useEffect's setTimeout
        setTimeout(() => setShouldShowContactForm(false), 100)
      },
    })
  useEffect(() => {
    if (shouldShowSupportPopover) {
      show()
      setShouldShowSupportPopover(false)
    }
  })
  return (
    <>
      <HeaderMenuItem ref={triggerRef} icon={Help} onClick={show} tooltip="Help">
        <p className={styles.label}>Support</p>
      </HeaderMenuItem>
      <Popover
        ref={popoverRef}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        alignment={PopoverAlignment.end}
        maxWidth={360}
      >
        <SupportPopover closePopover={hide} />
      </Popover>
    </>
  )
}
