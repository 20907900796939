import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'
import {
  Button,
  COLOR,
  Input,
  Spinner,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { useSanitizedBlur } from '../../../hooks/use-sanitized-blur'
import { useGetStoreQuery, useUpdateStoreMutation } from '../../../queries/stores'
import { getActiveStoreIdAtom } from '../../../atoms/stores'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import styles from './store-details-form.module.css'

const PHONE_NUMBER_MIN_LENGTH_USA = 10

const schema = Yup.object()
  .shape({
    name: Yup.string().required('Please enter your store name'),
    domain: Yup.string()
      .required('Please enter your store URL')
      .matches(validate.urlRegExp, 'Please enter a valid URL'),
    phoneNumber: Yup.string()
      .test('phone-number-validation', 'Please enter a valid phone number', (value): boolean => {
        return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      })
      .label('Phone'),
  })
  .defined()

export type Values = Yup.InferType<typeof schema>

type StoreDetailsFormProps = {
  setHasChangesMade: (value: boolean) => void
}

export const StoreDetailsForm: FC<StoreDetailsFormProps> = ({ setHasChangesMade }) => {
  const { toast } = useToaster()
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const { data: store, isLoading: isQueryLoading } = useGetStoreQuery({ storeId })
  const { mutateAsync: updateStore, isLoading: isUpdateLoading } = useUpdateStoreMutation()
  const isLoading = isQueryLoading || isUpdateLoading
  const { hasPermission } = usePermissions()

  const shouldViewOnly = !hasPermission(Permission.StoreSettingsFullAccess)

  const phoneNumber = useMemo(
    () =>
      (store?.phoneNumber ||
        (store?.phoneNumbers && store?.phoneNumbers[0]?.number) ||
        '') as string,
    [store?.phoneNumber, store?.phoneNumbers],
  )

  const {
    dirty,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: store?.name || '',
      domain: store?.domain || '',
      phoneNumber: phoneNumber ? validate.formatPhoneNumber(phoneNumber) : '',
    },
    onSubmit: async (vals: Values): Promise<void> => {
      if (!store) return
      try {
        await updateStore({
          storeId,
          store: {
            name: vals.name,
            domain: vals.domain,
            phoneNumbers: vals.phoneNumber
              ? [
                  {
                    number: validate.removeNonNumsFromPhone(vals.phoneNumber),
                  },
                ]
              : [],
          },
          version: 'latest',
        })
        toast({
          message: 'Store details updated',
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.short,
        })
        setHasChangesMade(false)
      } catch (err) {
        toast({
          message: 'Store details could not be updated. Please try again later',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    },
  })

  useEffect(() => {
    return () => {
      setHasChangesMade(false)
    }
  }, [setHasChangesMade])

  useEffect(() => {
    setHasChangesMade(Boolean(Object.values(touched).find(Boolean) && dirty))
  }, [touched, dirty, setHasChangesMade])

  const hasNotChanged = !dirty

  const areButtonsDisabled =
    isLoading || hasNotChanged || Boolean(Object.values(errors).find(Boolean))

  const onReset = (): void => {
    handleReset(null)
    setHasChangesMade(false)
  }

  const { onHandleBlur } = useSanitizedBlur(handleBlur, setFieldValue)

  const formattedNumber =
    touched.phoneNumber && errors.phoneNumber
      ? validate.removeNonNumsFromPhone(values?.phoneNumber ?? '')
      : validate.formatPhoneNumber(values?.phoneNumber ?? '')

  if (isLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!store) return null

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <div className={styles.input}>
        <Input
          label="Store Name"
          id="name"
          onChange={handleChange}
          onBlur={onHandleBlur}
          value={values.name}
          isError={Boolean(errors.name && touched.name)}
          errorFeedback={errors.name}
          isDisabled={isLoading || shouldViewOnly}
        />
      </div>
      <div className={styles.input}>
        <Input
          label="Website"
          id="domain"
          onChange={handleChange}
          onBlur={onHandleBlur}
          value={values.domain}
          isError={Boolean(errors.domain && touched.domain)}
          errorFeedback={errors.domain}
          isDisabled={isLoading || shouldViewOnly}
        />
      </div>
      <div className={styles.input}>
        <Input
          label="Phone"
          id="phoneNumber"
          onChange={handleChange}
          onBlur={onHandleBlur}
          value={formattedNumber}
          isError={Boolean(errors.phoneNumber && touched.phoneNumber)}
          errorFeedback={errors.phoneNumber}
          isDisabled={isLoading || shouldViewOnly}
        />
      </div>
      <div className={styles.input}>
        <Input
          label="E-Commerce Platform"
          id="settingsStorePlatform"
          value={store.platform}
          isDisabled
        />
      </div>
      {!shouldViewOnly && (
        <div className="break-before-left flex">
          <div className={styles.button}>
            <Button
              type="submit"
              color="blue"
              isProcessing={isLoading}
              isDisabled={areButtonsDisabled || hasNotChanged}
              text="Save"
            />
          </div>
          <div className={styles.button}>
            <Button
              type="button"
              color="blue"
              onClick={onReset}
              isDisabled={areButtonsDisabled || hasNotChanged}
              text="Cancel"
            />
          </div>
        </div>
      )}
    </form>
  )
}
