import type { CategoryProduct } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import type { MerchantContract } from '../../../../types/merchant-contract'
import { ContractsTableMapping } from './contract-products-data-table-mapping'
import { date } from '@extend/client-helpers'

export function getProductCoverage(coverage: CategoryProduct['coverage']): string {
  if (coverage && coverage?.starts && coverage?.ends) {
    const { starts, ends } = coverage

    const START_DATE = date.format(Number(starts), 'MMM DD YYYY')
    const END_DATE = date.format(Number(ends), 'MMM DD YYYY')

    return `${START_DATE} - ${END_DATE}`
  }

  return ''
}

export const getProductDataTableData = (contract: MerchantContract): ContractsTableMapping[] => {
  if (contract.type === ContractType.PRODUCT_PROTECTION_BUNDLE) {
    if (!contract.bundlesList) return []
    return contract.bundlesList?.map((bundle) => {
      return {
        ...bundle,
        components: contract.bundleComponents
          ?.filter((component) => component.bundleId === bundle.referenceId)
          .map((component) => {
            return {
              ...component,
              category: contract?.planDetails?.category,
              coverage: getProductCoverage(component.coverage),
            }
          }),
        category: contract?.planDetails?.category,
        coverage: getProductCoverage(bundle.coverage),
      }
    })
  }

  if (contract.type === ContractType.CATEGORY) {
    if (!contract.categoryProductsList) return []

    return contract.categoryProductsList.map((categoryProduct) => {
      return {
        ...categoryProduct,
        coverage: getProductCoverage(categoryProduct.coverage),
      }
    })
  }

  return [
    {
      ...contract.product,
      category: contract?.planDetails?.category,
      coverage: getProductCoverage(contract.coverage),
      limitOfLiability: contract.limitOfLiabilityAmount,
    },
  ]
}
