import { Button, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useSetAtom, useAtomValue } from 'jotai/react'
import { Permission } from '../../../lib/permissions'
import { usePermissions } from '../../../hooks/use-permissions'
import { useGetServiceOrderServicerSettingsQuery } from '../../../queries/servicers'
import { isAcceptServiceOrderModalVisibleAtom } from '../../../atoms/accept-service-order-modal'
import { useAcceptServiceOrderMutation } from '../../../queries/service-orders'
import { claimDetailsAtom } from '../../../atoms/claim-details'

export const AcceptServiceOrderButton: FC<{ serviceOrder: ServiceOrder }> = ({ serviceOrder }) => {
  const { hasPermission } = usePermissions()
  const claim = useAtomValue(claimDetailsAtom)
  const setIsAcceptServiceOrderModalVisible = useSetAtom(isAcceptServiceOrderModalVisibleAtom)
  const { data: servicerSettings } = useGetServiceOrderServicerSettingsQuery({
    servicerId: serviceOrder?.assignedServicer?.servicerId || '',
  })
  const { mutateAsync: acceptServiceOrderMutation, isLoading } = useAcceptServiceOrderMutation()
  const { toast } = useToaster()
  const shouldShowAcceptServiceOrderButton = useMemo(() => {
    return (
      hasPermission(Permission.ServiceOrderFullAccess) &&
      claim.status === 'approved' &&
      serviceOrder?.status === 'assigned' &&
      servicerSettings?.settings?.depotRepairEnabled &&
      servicerSettings?.settings?.onsiteRepairEnabled &&
      servicerSettings?.settings?.preventAutoAccept
    )
  }, [
    claim.status,
    hasPermission,
    serviceOrder?.status,
    servicerSettings?.settings?.depotRepairEnabled,
    servicerSettings?.settings?.onsiteRepairEnabled,
    servicerSettings?.settings?.preventAutoAccept,
  ])

  // eslint-disable-next-line consistent-return
  const handleOnClick = async (): Promise<void> => {
    if (serviceOrder.configurations?.rmaNumberRequired) {
      return setIsAcceptServiceOrderModalVisible(true)
    }
    try {
      await acceptServiceOrderMutation({ serviceOrderId: serviceOrder.id, body: {} })
      toast({
        message: 'The service order has successfully been accepted',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    } catch {
      toast({
        message: 'There was an error accepting the service order. Please try again',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  if (shouldShowAcceptServiceOrderButton) {
    return (
      <>
        <hr />
        <Button text="Accept Service Order" onClick={handleOnClick} isProcessing={isLoading} />
      </>
    )
  }

  return null
}
