import type { FC } from 'react'
import React from 'react'
import styles from './header-label.module.css'

type LabelProps = {
  labelText: string
  'data-cy'?: string
}

export const HeaderLabel: FC<LabelProps> = ({ labelText, 'data-cy': dataCy }) => (
  <span className={styles['label-heading']} data-cy={dataCy}>
    {labelText}
  </span>
)
