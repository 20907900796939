import type { FC } from 'react'
import React from 'react'
import type { Money } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'

import {
  CurrencyInput,
  Select,
  Input,
  InputType,
  Button,
  ButtonGroup,
  Checkbox,
  GenericField,
} from '@extend/zen'
import styles from './add-expense-form.module.css'
import {
  useAddExpenseForm,
  PARTS,
  REPLACEMENT,
  REFURBISHED_REPLACEMENT,
} from '../../../hooks/use-add-expense-form'

export const AddExpenseForm: FC<{
  closeForm: () => void
  currencyCode?: Money['currencyCode']
  serviceOrderId: string
}> = ({ closeForm, currencyCode = 'USD', serviceOrderId }) => {
  const {
    expenseType,
    description,
    setDescription,
    partsNumber,
    replacementProduct,
    isGoodwill,
    setIsGoodwill,
    rate,
    setRate,
    wholesaleCost,
    setWholesaleCost,
    quantity,
    setQuantity,
    shouldShowReplacementProductInput,
    shouldShowPartsNumberInput,
    totalCost,
    isLoading,
    handleCreateExpenseSubmit,
    handleOnClickCancel,
    handleSelectExpenseType,
    updatePartsNumberAndMetaData,
    updateReplacementProductAndMetaData,
    isSubmitDisabled,
    descriptionHasError,
    partsNumberHasError,
    replacementProductHasError,
    rateHasError,
    quantityHasError,
    setDescriptionHasBeenBlurred,
    setPartsNumberHasBeenBlurred,
    setReplacementProductHasBeenBlurred,
    setRateHasBeenBlurred,
    setQuantityHasBeenBlurred,
  } = useAddExpenseForm({ closeForm, serviceOrderId, currencyCode })

  return (
    <section className={styles.container}>
      <h2>Add Expense</h2>
      <form id="addExpenseForm" onSubmit={handleCreateExpenseSubmit}>
        <div className={styles.grid}>
          <Select
            label="Expense Type"
            id="expenseType"
            placeholder="Select"
            value={expenseType}
            onChange={handleSelectExpenseType}
          >
            <option value="diagnosis_fee">Diagnostic Fee</option>
            <option value="labor">Labor - Hourly Rate</option>
            <option value={PARTS}>Parts</option>
            <option value={REFURBISHED_REPLACEMENT}>Refurbished Replacement</option>
            <option value={REPLACEMENT}>Replacement</option>
            <option value="taxes">Taxes</option>
            <option value="trip_charges">Trip Charges</option>
            <option value="cleaning_kit">Cleaning Kit</option>
            <option value="shipping">Shipping & Handling</option>
          </Select>
          <div className={styles['two-column']}>
            <Input
              label="Description"
              id="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              isError={descriptionHasError}
              errorFeedback="Required"
              onBlur={() => setDescriptionHasBeenBlurred(true)}
            />
          </div>
          <GenericField hasLabelSpacing>
            <Checkbox
              checked={isGoodwill}
              label="Goodwill"
              onChange={() => setIsGoodwill(!isGoodwill)}
            />
          </GenericField>
          {shouldShowPartsNumberInput && (
            <Input
              id="partsNumber"
              label="Parts Number"
              value={partsNumber}
              onChange={updatePartsNumberAndMetaData}
              isError={partsNumberHasError}
              errorFeedback="Required"
              onBlur={() => setPartsNumberHasBeenBlurred(true)}
            />
          )}
          {shouldShowReplacementProductInput && (
            <Input
              id="replacementProduct"
              label="Replacement Product"
              value={replacementProduct}
              onChange={updateReplacementProductAndMetaData}
              isError={replacementProductHasError}
              errorFeedback="Required"
              onBlur={() => setReplacementProductHasBeenBlurred(true)}
            />
          )}
        </div>
        <div className={styles.grid}>
          <Select label="Incurred By" id="incurredBy" value="servicer" isDisabled>
            <option value="servicer">Servicer</option>
          </Select>
          <CurrencyInput
            currency={currencyCode}
            id="rate"
            label="Rate"
            currencyDisplay="code"
            onChange={(event) => setRate(event.target.value)}
            value={rate}
            isError={rateHasError}
            errorFeedback="Required"
            onBlur={() => setRateHasBeenBlurred(true)}
          />
          <CurrencyInput
            currency={currencyCode}
            id="wholesaleCost"
            label="Wholesale Cost (Optional)"
            currencyDisplay="code"
            onChange={(event) => setWholesaleCost(event.target.value)}
            value={wholesaleCost}
            errorFeedback="Required"
            onBlur={() => setRateHasBeenBlurred(true)}
          />
          <Input
            type={InputType.number}
            id="quantity"
            label="Quantity"
            step={0.25}
            onChange={(event) => setQuantity(event.currentTarget.value)}
            value={quantity}
            min={0}
            placeholder="0"
            isError={quantityHasError}
            errorFeedback="Required"
            onBlur={() => setQuantityHasBeenBlurred(true)}
          />
          <GenericField label="Total">
            {currency.formatWithoutCurrencySymbol(totalCost, currencyCode, true)}
          </GenericField>
        </div>
        <ButtonGroup justify="end">
          <Button
            emphasis="medium"
            ariaLabel="Cancel"
            text="Cancel"
            onClick={handleOnClickCancel}
          />
          <Button
            type="submit"
            ariaLabel="Add Expense"
            text="Add Expense"
            isProcessing={isLoading}
            isDisabled={isSubmitDisabled}
          />
        </ButtonGroup>
      </form>
    </section>
  )
}
