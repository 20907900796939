import type { FC } from 'react'
import React from 'react'
import { ClaimsDataTable } from './claims-data-table'
import { ClaimsHeader } from './claims-header'

const Claims: FC = () => {
  return (
    <>
      <ClaimsHeader />
      <ClaimsDataTable />
    </>
  )
}

export { Claims }
