import type { ServiceOrderShipment } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'

export function getShipmentDeliveredDate(shipment?: ServiceOrderShipment): string {
  const nonFormattedDate =
    shipment?.status === 'delivered' ? shipment?.deliveredAt : shipment?.estimatedDeliveryAt

  const formattedDate = date.format(Number(nonFormattedDate), date.standardDateFormat)

  if (formattedDate !== 'Invalid date') {
    return formattedDate
  }

  return ''
}
