// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Ky2VVOlaSYdnHNpwLtQbP {\n  margin-bottom: 20px;\n}", ""]);
// Exports
exports.locals = {
	"data-property-wrapper": "_2Ky2VVOlaSYdnHNpwLtQbP"
};
module.exports = exports;
