import type { FC, MouseEvent } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import type {
  CheckboxFilterValues,
  DateRangeFilterValues,
  FilterValues,
} from '@helloextend/merchants-ui'
import { DataReactTable, PaginationType, useStandardToast } from '@helloextend/merchants-ui'
import type {
  ContractSearchQueryStringOptions,
  ContractSearchSortKey,
} from '@helloextend/extend-api-rtk-query'
import { useSearchContractsQuery } from '@helloextend/extend-api-rtk-query'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { ContractStatus } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'
import { useAtomValue } from 'jotai/react'
import { useShellContext } from '@extend/zen'
import { columns, filterOptions, searchOptions } from './table-config'
import { getContractStatusSynonyms } from '../../../utils/contract-status'
import { getNewContractClaimUrl } from '../../../utils/route-helper'
import { useDataDelayedToast } from '../../../hooks/use-data-delay-toast'
import { getActiveStoreAtom } from '../../../atoms/stores'

type Filters = Pick<
  ContractSearchQueryStringOptions,
  | 'filterMatchStatuses'
  | 'transactionDateBegin'
  | 'transactionDateEnd'
  | 'productTransactionDateBegin'
  | 'productTransactionDateEnd'
  | 'cancelledAtBegin'
  | 'cancelledAtEnd'
  | 'refundedAtBegin'
  | 'refundedAtEnd'
  | 'updatedAtBegin'
  | 'updatedAtEnd'
>
const EMPTY_FILTER_MESSAGE = 'Double check that the search term is correct.'

const ContractDataTable: FC = () => {
  const { toastError } = useStandardToast()
  const { push } = useHistory()
  const { getScrollableRegionRef } = useShellContext()

  const store = useAtomValue(getActiveStoreAtom)

  const [filters, setFilters] = useQueryStringState<Record<string, FilterValues | null>>(
    'filters',
    {},
    { encode: true, transformDates: true },
  )
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 50)
  const [sortAsc, setSortAsc] = useQueryStringState('sortAsc', false)
  const [sortKey, setSortKey] = useQueryStringState<ContractSearchSortKey>(
    'sortKey',
    'transactionDate',
  )

  const [searchKey, setSearchKey] = useQueryStringState('search', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')

  const [nextPageCursor, setNextPageCursor] = useState('')
  const [autoResetPage, setAutoResetPage] = useState(true)

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: sortKey === 'transactionDate' ? 'purchaseDate' : sortKey, desc: !sortAsc }],
      pageSize,
      filters: Object.keys(filters).map((key) => ({ id: key, value: filters[key] })),
    }
  }, [filters, pageSize, sortAsc, sortKey])

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: ContractsV2GetResponse) => {
      const allowedStatuses = [ContractStatus.LIVE, ContractStatus.CREATED]
      const isAllowed = allowedStatuses.indexOf(rowData.status) !== -1

      if (!isAllowed) {
        toastError('Claims can only be filed against active contracts')
        return
      }

      push(getNewContractClaimUrl(rowData.id))
    },
    [push, toastError],
  )

  const filtersForApi = useMemo(() => {
    const newFilters: Filters = {}

    if (filters.status && (filters.status as CheckboxFilterValues).values.length) {
      newFilters.filterMatchStatuses = (filters.status as CheckboxFilterValues).values
        .map((v) => getContractStatusSynonyms(v as ContractStatus))
        .flat()
    }

    if (filters.transactionDate) {
      const { start, end } = filters.transactionDate as DateRangeFilterValues
      if (start) newFilters.transactionDateBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.transactionDateEnd = date.getEndOfDay(end).getTime()
    }

    if (filters.productTransactionDate) {
      const { start, end } = filters.productTransactionDate as DateRangeFilterValues
      if (start) newFilters.productTransactionDateBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.productTransactionDateEnd = date.getEndOfDay(end).getTime()
    }

    if (filters.canceledDate) {
      const { start, end } = filters.canceledDate as DateRangeFilterValues
      if (start) newFilters.cancelledAtBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.cancelledAtEnd = date.getEndOfDay(end).getTime()
    }

    if (filters.refundedDate) {
      const { start, end } = filters.refundedDate as DateRangeFilterValues
      if (start) newFilters.refundedAtBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.refundedAtEnd = date.getEndOfDay(end).getTime()
    }

    if (filters.updatedDate) {
      const { start, end } = filters.updatedDate as DateRangeFilterValues
      if (start) newFilters.updatedAtBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.updatedAtEnd = date.getEndOfDay(end).getTime()
    }

    if (searchKey && searchValue) {
      return { ...newFilters, [searchKey]: searchValue }
    }

    if (searchKey && searchValue) {
      return { ...newFilters, [searchKey]: searchValue }
    }

    return newFilters
  }, [
    filters.canceledDate,
    filters.productTransactionDate,
    filters.refundedDate,
    filters.status,
    filters.transactionDate,
    filters.updatedDate,
    searchKey,
    searchValue,
  ])

  const { data, isLoading, isFetching, isSuccess, fulfilledTimeStamp } = useSearchContractsQuery(
    {
      sellerId: store?.id ?? '',
      searchVersion: '2',
      limit: 100,
      cursor: nextPageCursor || undefined,
      sortKey,
      sortAsc,
      ...filtersForApi,
    },
    { refetchOnMountOrArgChange: true },
  )

  useDataDelayedToast(isFetching)

  const resetPagination = useCallback(() => {
    setNextPageCursor('')
    setAutoResetPage(true)
  }, [])

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setFilters(filtersRecord)
      resetPagination()
    },
    [resetPagination, setFilters],
  )

  const handleServerSort = useCallback(
    (newSortKey: string, newSortAsc: boolean) => {
      const shouldSortAsc = Boolean(newSortKey && newSortAsc)
      const key = !newSortKey || newSortKey === 'purchaseDate' ? 'transactionDate' : newSortKey

      setSortKey(key as ContractSearchSortKey)
      setSortAsc(shouldSortAsc)

      resetPagination()
    },
    [resetPagination, setSortAsc, setSortKey],
  )

  const handleSearch = useCallback(
    (key?: string, value?: string): void => {
      if (key && value) {
        setSearchKey(key)
        setSearchValue(value)
      } else {
        setSearchKey('')
        setSearchValue('')
      }
    },
    [setSearchKey, setSearchValue],
  )

  const handleServerPagination = useCallback((cursor: string) => {
    setNextPageCursor(cursor ?? '')
    setAutoResetPage(false)
  }, [])

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const hasSearch = Boolean(searchKey && searchValue)

  return (
    <DataReactTable
      data-cy="contracts"
      enableSearch
      hasSearchBar
      data={hasSearch && isSuccess && data ? data.items : []}
      isLoading={isLoading || isFetching}
      columns={columns}
      initialState={initialState}
      initialSearchKey={searchKey}
      initialSearchValue={searchValue}
      filterOptions={hasSearch ? filterOptions : {}}
      onServerFilter={handleServerFilter}
      onServerSort={handleServerSort}
      searchOptions={searchOptions}
      onServerSearch={handleSearch}
      onRowClick={handleRowClick}
      onServerPagination={handleServerPagination}
      emptyMessage={EMPTY_FILTER_MESSAGE}
      type="contracts"
      autoResetPage={autoResetPage}
      nextPageCursor={data?.nextPageCursor}
      paginationType={PaginationType.ENHANCED_SERVER_SIDE}
      setPagination={setPagination}
      getScrollToRef={getScrollableRegionRef}
      disableSortRemove
      isInitialReqFullfilled={Boolean(fulfilledTimeStamp)}
      searchOnly
      searchOnlyMessage="Search above to find the contract you’d like to file a claim against"
    />
  )
}

export { ContractDataTable }
