import type { FC, MouseEvent } from 'react'
import React, { useCallback } from 'react'
import { DataReactTable, ZeroState } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router-dom'
import type { ClaimsSearchResponse } from '@helloextend/extend-api-client'
import { useAtomValue } from 'jotai/react'
import { columns } from './contract-claims-table-config'
import { getClaimDetailUrl } from '../../../utils/route-helper'
import { useClaimsSearch } from '../../../hooks/use-claims-search'
import { getActiveStoreIdAtom } from '../../../atoms/stores'

export const ContractClaims: FC<{ contractId: string }> = ({ contractId }) => {
  const { push } = useHistory()
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const { tableData, isLoading } = useClaimsSearch({
    queryParams: { containsContractId: contractId, sellerId: storeId },
    useReactQuery: true,
  })

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: ClaimsSearchResponse): void => {
      push(getClaimDetailUrl(rowData.id))
    },
    [push],
  )

  if (!isLoading && !tableData?.length) {
    return (
      <ZeroState
        title="No claims yet"
        text="When a customers files a claim against this contract, you can view it here."
      />
    )
  }

  return (
    <DataReactTable
      initialState={{ sortBy: [{ id: 'reportedAt', desc: true }] }}
      data={tableData ?? []}
      isLoading={isLoading}
      columns={columns}
      type="claims"
      onRowClick={handleRowClick}
    />
  )
}
