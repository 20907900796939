export interface TablePlansSearch {
  id: string
  planId: string
  version: number
}

export enum PlanIdDisplayValue {
  adh_plan = 'Accidental Damage Handling (ADH) Plan',
  base_plan = 'Base Plan',
}
