import type { FC } from 'react'
import React from 'react'
import { COLOR, Spinner } from '@extend/zen'
import { Switch, Redirect, useRouteMatch, useParams, Route } from 'react-router-dom'
import { useSetAtom } from 'jotai/react'
import styles from './contract.module.css'
import { useGetContractQuery } from '../../../queries/contract'
import { ContractClaims } from './contract-claims'
import { ContractDetails } from './contract-details/contract-details'
import { ContractHeader } from './contract-header'
import { ErrorPage } from '../../../components/error-page'
import { useQueryStore } from '../../../hooks/use-query-store'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import { contractDetailsAtom } from '../../../atoms/contract-details'

export const Contract: FC = () => {
  const { url } = useRouteMatch()
  const { store } = useQueryStore()
  const { contractId } = useParams<{ contractId: string }>()
  const setContractAtom = useSetAtom(contractDetailsAtom)
  const {
    data: contract,
    isLoading,
    isError,
    error,
  } = useGetContractQuery({ contractId, apiVersion: CONTRACTS_API_VERSION_FEB_2022 })
  const isClaimsManagementEnabled = store?.claimsManagementEnabled || false

  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError || !contract) {
    return <ErrorPage error={error} />
  }

  setContractAtom(contract)
  return (
    <>
      <ContractHeader isClaimsManagementEnabled={isClaimsManagementEnabled} />
      <Switch>
        <Route exact path={`${url}`}>
          <ContractDetails />
        </Route>
        {isClaimsManagementEnabled && (
          <Route exact path={`${url}/claims`}>
            <ContractClaims contractId={contractId} />
          </Route>
        )}
        <Redirect to="/404" />
      </Switch>
    </>
  )
}
