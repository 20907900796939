// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1H0MKllmnijlBaWxSFpK_a {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n._1Pliyb0i8MZxRuCgck-Pp {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n.A9qC1rNooKNdk1TivRv0z {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._1h4ncg69ubv1qit-IJ4Un9 {\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n}\n\n.ZWyq5duYIpxUiaAQ6C0FQ {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_1H0MKllmnijlBaWxSFpK_a",
	"row": "_1Pliyb0i8MZxRuCgck-Pp",
	"details": "A9qC1rNooKNdk1TivRv0z",
	"detail": "_1h4ncg69ubv1qit-IJ4Un9",
	"label": "ZWyq5duYIpxUiaAQ6C0FQ"
};
module.exports = exports;
