import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { Button, Input } from '@extend/zen'
import { InputType } from '@extend/zen'
import { validate } from '@extend/client-helpers'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useSendTestEmailMutation } from '../../../queries/messaging'
import { generateTestEmailBody } from '../../../utils/send-test-email-helper'
import { SubHeader } from '../../../components/sub-header'
import styles from './send-test-emails.module.css'

interface SendTestEmailProps {
  storeId: string
}

const sendTestEmailsDescription =
  'Send test emails to yourself to preview them. All the emails you have configured in the Emails Sent section will be sent to the address you provide.'

export const SendTestEmails: FC<SendTestEmailProps> = ({ storeId }) => {
  const [sendTestEmailAddress, setSendTestEmailAddress] = useState('')
  const [isSendTestEmailTouched, setIsSendTestEmailTouched] = useState(false)
  const {
    mutate: sendTestEmail,
    isLoading: isSending,
    isSuccess: isSendSuccess,
    isError: isSendError,
  } = useSendTestEmailMutation()

  const handleSendTestEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSendTestEmailAddress(e.target.value.trim())
    setIsSendTestEmailTouched(true)
  }

  const handleSendTestEmailAddressBlur = (): void => {
    setIsSendTestEmailTouched(true)
  }

  const handleSendTestEmail = (): void => {
    sendTestEmail(generateTestEmailBody(sendTestEmailAddress, storeId))
  }

  const { toastError, toastCompleted } = useStandardToast()

  useEffect(() => {
    if (isSendSuccess) {
      toastCompleted(
        'Email successfully sent! Please allow for up to one minute for the email to arrive',
      )
    }
  }, [isSendSuccess, toastCompleted])

  useEffect(() => {
    if (isSendError) {
      toastError('Error Sending Email. Please try again later.')
    }
  }, [isSendError, toastError])

  return (
    <div className="block">
      <SubHeader labelText="Send Test Emails" />
      <div className={styles.description} style={{ width: '50%' }}>
        {' '}
        {sendTestEmailsDescription}{' '}
      </div>
      <div className={styles.combo}>
        <div className={styles.input}>
          <Input
            onChange={handleSendTestEmailAddressChange}
            onBlur={handleSendTestEmailAddressBlur}
            type={InputType.text}
            label="Test Email Address"
            id="sendToEmail"
            value={sendTestEmailAddress}
            errorFeedback={
              validate.isValidEmail(sendTestEmailAddress) ? '' : 'Please enter a valid email address.'
            }
            isError={isSendTestEmailTouched && !validate.isValidEmail(sendTestEmailAddress)}
            isDisabled={isSending}
            data-cy="send-test-email-address"
          />
        </div>
        <div className={styles.button}>
          <Button
            text="Send Test Email(s)"
            onClick={handleSendTestEmail}
            emphasis="medium"
            size="regular"
            isDisabled={isSending || !validate.isValidEmail(sendTestEmailAddress)}
            isProcessing={isSending}
            data-cy="send-test-emails-button"
          />
        </div>
      </div>
    </div>
  )
}
