import type { FC } from 'react'
import React from 'react'
import { Button } from '@extend/zen'
import type { ServiceOrder, ServiceType } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { ResolveServiceOrderModal } from './resolve-service-order-modal'
import styles from './service-order-information.module.css'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import { useGetServiceOrderInformation } from '../../../hooks/use-get-service-order-information'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'

export const ServiceOrderInformation: FC<{
  serviceOrder: ServiceOrder | null
  isReadonly?: boolean
}> = ({ serviceOrder, isReadonly = false }) => {
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)
  const { shouldShowResolveServiceOrder, dataPropertyValues } = useGetServiceOrderInformation(
    serviceOrder,
    isReadonly,
  )

  return (
    <>
      <DataPropertyGrid values={dataPropertyValues} className={styles.grid} />
      {shouldShowResolveServiceOrder && (
        <>
          <Button
            text="Resolve Service Order"
            isDisabled={!serviceOrder?.contractId}
            onClick={() => setIsResolveServiceOrderModalVisible(true)}
          />
          <ResolveServiceOrderModal
            serviceOrderType={serviceOrder?.serviceType as ServiceType}
            serviceOrderId={serviceOrder?.id || ''}
            contractId={serviceOrder?.contractId || ''}
            fulfillmentMethod={serviceOrder?.configurations?.repairFulfillmentMethod}
          />
        </>
      )}
    </>
  )
}
