import type { FC } from 'react'
import React, { useMemo } from 'react'
import { customLogger } from '@extend/client-helpers'
import { NavLink } from 'react-router-dom'
import { currency } from '@extend/client-helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useGetOrderQuery } from '@helloextend/extend-api-rtk-query'
import type { ClaimItem, LineItem, LineItemContract } from '@helloextend/extend-api-client'
import { LDFlag } from '../../../constants/ld-flags'
import { getProductUrl } from '../../../utils/route-helper'
import styles from './product-protection-shipment-table.module.css'
import type { MerchantClaim } from '../../../types/claims'
import type { MerchantContract } from '../../../types/merchant-contract'

type ProductShipmentTableProps = {
  claim: MerchantClaim
  contract: MerchantContract
}

export const ProductProtectionShipmentTable: FC<ProductShipmentTableProps> = ({
  claim,
  contract,
}) => {
  const { [LDFlag.UseClaimProducts]: FF_USE_CLAIM_PRODUCTS } = useFlags()
  const { id } = claim
  const { data: order } = useGetOrderQuery(contract.orderId || '', {
    skip: !FF_USE_CLAIM_PRODUCTS || !contract.orderId,
  })

  const currencyCode = FF_USE_CLAIM_PRODUCTS
    ? claim.products?.[0].limitOfLiability.currencyCode || 'USD'
    : claim.items?.[0]?.currencyCode || 'USD'

  const miscFees = useMemo(() => getMiscFeesAmount(claim), [claim])

  let items: ClaimItem[] | LineItem[] = claim.items || []

  // If we are supposed to use claim products instead of claim items we need the product info from the line items
  if (FF_USE_CLAIM_PRODUCTS) {
    const lineItemsIds = claim.products?.map((product) => product.lineItemId) || []
    items = order?.lineItems?.filter((item) => lineItemsIds.includes(item.id)) || []
  }

  const subtotal = useMemo(
    () => claim.items?.reduce((acc, item) => acc + (item?.purchasePrice || 0), 0) || 0,
    [claim],
  )
  const total = FF_USE_CLAIM_PRODUCTS
    ? currency.formatWithoutCurrencySymbol(claim?.totalValue?.amount, currencyCode, true)
    : currency.formatWithoutCurrencySymbol(subtotal + miscFees, currencyCode, true)

  return (
    <table className={styles.table} data-cy={`${id}:product-shipment-table`}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
          <th style={{ textAlign: 'right' }}>Purchase Price</th>
        </tr>
      </thead>
      <tbody>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {items.map((item: ClaimItem | LineItem) => {
          const productId = FF_USE_CLAIM_PRODUCTS
            ? (item as LineItemContract)?.product?.id || '-'
            : (item as ClaimItem).referenceItemId
          const productName = FF_USE_CLAIM_PRODUCTS
            ? (item as LineItemContract)?.product?.name || '-'
            : (item as ClaimItem).title
          const productPurchasePrice = FF_USE_CLAIM_PRODUCTS
            ? (item as LineItemContract)?.product?.purchasePrice
            : (item as ClaimItem).purchasePrice
          return (
            <tr key={productId}>
              <td>
                <NavLink to={getProductUrl(productId)}>{productName}</NavLink>
              </td>
              <td>
                <p data-cy="product-reference-id">{productId}</p>
              </td>
              <td className="text-right">
                <p>
                  {currency.formatWithoutCurrencySymbol(productPurchasePrice, currencyCode, true)}
                </p>
              </td>
            </tr>
          )
        })}
        <tr>
          <td>Misc Fee</td>
          <td>Taxes & Shipping</td>
          <td className="text-right">
            {currency.formatWithoutCurrencySymbol(miscFees, currencyCode, true)}
          </td>
        </tr>
        <tr>
          <td>Total</td>
          <td>-</td>
          <td className="text-right">{total}</td>
        </tr>
      </tbody>
    </table>
  )
}

function getMiscFeesAmount(claim: MerchantClaim): number {
  try {
    const tax = claim.tax?.amount || 0
    const shippingCost = claim.shippingCost?.amount || 0

    return tax + shippingCost
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error(error.message, { stack: error.stack })
    }
    return 0
  }
}
