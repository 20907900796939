import type { FC } from 'react'
import React from 'react'
import type { DataTableMenuItem } from '@extend/zen'
import { useSetAtom } from 'jotai/react'
import { Add, Button, LinkButton, DataTable, Stack } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { TABLE_COLUMNS } from './api-integrations-table-config'
import type { APIIntegrationMapping } from '../../hooks/use-all-api-clients'
import { useAllAPIClients } from '../../hooks/use-all-api-clients'
import { deleteApiIntegrationAtom } from '../../atoms/delete-api-integration'
import { DeleteIntegrationModal } from './delete-integration-modal'
import styles from './api-integrations-table.module.css'
import { CreateApiIntegrationModal } from './create-api-integration-modal'
import { isCreateIntegrationModalVisibleAtom } from '../../atoms/create-api-integration-modal'
import { CreateApiIntegrationDetailsModal } from './create-api-integration-details-modal'

export const ApiIntegrationsTable: FC = () => {
  const history = useHistory()
  const { isLoading, tableItems, error } = useAllAPIClients()
  const hasAPIClients = Boolean(tableItems?.length) // API Clients are non-platform integrations
  const openDeleteAPIIntegrationModal = useSetAtom(deleteApiIntegrationAtom)
  const openCreateIntegrationModal = useSetAtom(isCreateIntegrationModalVisibleAtom)

  const getRowMenuItems = (row: APIIntegrationMapping): DataTableMenuItem[] => {
    return [
      {
        children: 'Delete',
        onClick: () => {
          openDeleteAPIIntegrationModal(row)
        },
        'data-cy': 'delete-api-integration',
      },
    ]
  }

  const openCreateModal = (): void => {
    openCreateIntegrationModal(true)
  }

  return (
    <>
      {hasAPIClients && (
        <div className={styles['add-integration-button-wrapper']}>
          <LinkButton
            text="View API Documentation"
            to="https://docs.extend.com/reference/introduction"
            emphasis="low"
            data-cy="view-api-documentation-button"
          />
          <Button
            text="New API Integration"
            icon={Add}
            emphasis="medium"
            data-cy="add-new-api-integration-button"
            onClick={openCreateModal}
          />
        </div>
      )}
      <DataTable
        data-cy="api-integrations-table"
        isError={Boolean(error)}
        isLoading={isLoading}
        data={tableItems}
        stateViews={{
          skeleton: {
            message: 'Please wait while we retrieve your API integrations',
          },
          error: {
            heading: 'Unable to retrieve API integrations',
            description: 'Please try again later.',
          },
          noData: {
            heading: 'No integrations yet',
            description: 'The API integrations that have been created will be shown on this page.',
            button: (
              <Stack align="center" spacingY={0.5}>
                <Button
                  text="New API Integration"
                  icon={Add}
                  emphasis="medium"
                  data-cy="add-new-api-integration-button-empty-state"
                  onClick={openCreateModal}
                />
                <LinkButton
                  text="View API Documentation"
                  to="https://docs.extend.com/reference/introduction"
                  emphasis="low"
                  data-cy="view-api-documentation-button-empty-state"
                />
              </Stack>
            ),
          },
        }}
        columns={TABLE_COLUMNS}
        getRowActions={(row) => [
          {
            onClick: () => history.push(`/account/integrations/api/${row.clientId}`),
            text: 'View',
            emphasis: 'low',
          },
        ]}
        getRowMenuItems={getRowMenuItems}
      />
      <DeleteIntegrationModal />
      <CreateApiIntegrationModal />
      <CreateApiIntegrationDetailsModal />
    </>
  )
}
