import type { ComponentClass, FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import type { RouteComponentProps } from 'react-router'
import { Redirect, Route, useHistory, useLocation } from 'react-router'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { usePermissions } from '../hooks/use-permissions'
import type { Permission } from '../lib/permissions'
import { useUser } from '../hooks/use-user'
import { loginRedirectPathAtom } from '../atoms/login-redirect'
import { SplashScreen } from '@helloextend/component-commons'
import { useRefreshAndExchangeTokens } from '../queries/okta'
import { isRefreshingTokensAtom, v3AccessTokenAtom } from '../atoms/auth'
import { oktaRefreshTokenAtom } from '../atoms/okta'
import { isTokenExpired } from '../hooks/use-token-refresh'

interface ProtectedRouteProps {
  path: string | string[]
  exact?: boolean
  requiredPermissions?: Permission[]
  loginRedirectPath?: string
  errorRedirectPath?: string
  component: React.FC<RouteComponentProps> | ComponentClass
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  path,
  exact,
  requiredPermissions = [],
  loginRedirectPath = '/',
  errorRedirectPath = '/404',
  component: PropsComponent,
}) => {
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { isLoggedIn, hasLoadedToken } = useUser()
  const isAllowed =
    isLoggedIn && requiredPermissions.every((permission) => hasPermission(permission))
  const setLoginRedirectPath = useSetAtom(loginRedirectPathAtom)

  const v3AccessToken = useAtomValue(v3AccessTokenAtom)
  const oktaRefreshToken = useAtomValue(oktaRefreshTokenAtom)
  const { mutateAsync: refreshAndExchangeTokens } = useRefreshAndExchangeTokens()
  const isRefreshingTokens = useAtomValue(isRefreshingTokensAtom)
  const setIsRefreshingTokens = useSetAtom(isRefreshingTokensAtom)
  const tokenExpired = isTokenExpired(v3AccessToken)

  const redirectPath = useMemo(
    () => (hasLoadedToken && isLoggedIn ? errorRedirectPath : loginRedirectPath),
    [errorRedirectPath, isLoggedIn, loginRedirectPath, hasLoadedToken],
  )

  useEffect(() => {
    if (!isLoggedIn) {
      setLoginRedirectPath(`${pathname}${search}`)
    }
  })

  if (!hasLoadedToken) {
    return null
  }

  useEffect(() => {
    async function refreshTokens() {
      if (!isRefreshingTokens) {
        setIsRefreshingTokens(true)
        try {
          await refreshAndExchangeTokens({ v3AccessToken, oktaRefreshToken })
        } catch (e) {
          history.push('/logout')
          return
        }

        setIsRefreshingTokens(false)
      }
    }

    if (tokenExpired) {
      if (v3AccessToken && oktaRefreshToken) {
        refreshTokens()
      } else {
        history.push('/logout')
      }
    }
  }, [
    tokenExpired,
    isRefreshingTokens,
    setIsRefreshingTokens,
    refreshAndExchangeTokens,
    v3AccessToken,
    oktaRefreshToken,
  ])

  if (tokenExpired) {
    return <SplashScreen data-cy="splash-screen" />
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) =>
        isAllowed ? (
          <PropsComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  )
}
