import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Link, NavLink } from 'react-router-dom'
import type { Lead } from '@helloextend/extend-api-client'
import { LeadStatus } from '@helloextend/extend-api-client'
import type { ButtonProps } from '@extend/zen'
import { ContentCopy } from '@extend/zen'
import { useAtom, useAtomValue } from 'jotai/react'
import { getLeadStatusCopy, statusDescriptions } from '../../../utils/get-lead-status-copy'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard'
import { HeaderLabel } from '../../../components/header-label'
import { StandardHeader } from '../../../components/standard-header'
import { getLeadsSearchUrl, getProductUrl } from '../../../utils/route-helper'
import { leadsUrlAtom } from '../../../atoms/leads-breadcrumb'
import { useGetLeadUrls } from '../../../hooks/use-get-lead-urls'
import { getActiveStoreAtom } from '../../../atoms/stores'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import styles from './lead-header.module.css'

interface LeadHeaderProps {
  lead: Lead
}

export const LeadHeader: FC<LeadHeaderProps> = ({ lead }) => {
  const copyToClipboard = useCopyToClipboard()

  const store = useAtomValue(getActiveStoreAtom)
  const { leadUrl, shouldDisplayCopyLeadUrlButton } = useGetLeadUrls(store || null)
  const statusBadgeDescription = useMemo((): BadgeDescriptionItem => {
    return getLeadStatusCopy(lead.status)
  }, [lead])

  const productLink = useMemo(() => {
    return getProductUrl(lead.product.referenceId)
  }, [lead])

  const productName = useMemo(() => {
    return lead.product.title || ''
  }, [lead])

  const copyLeadToken = async (): Promise<void> => {
    await copyToClipboard('Lead token', lead.leadToken)
  }

  const [leadsUrl] = useAtom(leadsUrlAtom)

  const copyLeadUrl = async (): Promise<void> => {
    const customMessage = (
      <div>
        Lead URL copied to clipboard. You can configure this in{' '}
        <Link className="text-underline" to="/store/settings">
          settings
        </Link>
        .
      </div>
    )
    if (leadUrl)
      await copyToClipboard('Lead URL', `${leadUrl}?leadToken=${lead.leadToken}`, customMessage)
  }

  const getButtons = (): ButtonProps[] => {
    let buttons: ButtonProps[] = [
      {
        icon: ContentCopy,
        text: 'Copy Lead Token',
        emphasis: 'medium',
        onClick: copyLeadToken,
        'data-cy': 'lead-info:buttons:copy-lead-token',
      },
    ]

    if (shouldDisplayCopyLeadUrlButton)
      buttons.push({
        icon: ContentCopy,
        text: 'Copy Lead URL',
        emphasis: 'medium',
        onClick: copyLeadUrl,
        'data-cy': 'lead-info:buttons:copy-lead-url',
      })

    if (lead.status !== LeadStatus.LIVE) {
      buttons = buttons.map((button) => {
        return {
          ...button,
          isDisabled: true,
          tooltip: 'Offers are only available for live leads',
        }
      })
    }

    return buttons
  }

  const crumbs = useMemo(() => {
    return [
      { to: leadsUrl || getLeadsSearchUrl(), text: 'Leads' },
      { text: `Lead Token: ${lead.leadToken}` },
    ]
  }, [lead, leadsUrl])

  return (
    <>
      <StandardHeader
        data-cy="lead-info"
        pageTitle="Lead"
        pageTitleBadge={{
          badgeText: statusBadgeDescription.text,
          badgeColor: statusBadgeDescription.color || 'neutral',
          badgeDescriptions: statusDescriptions,
        }}
        crumbs={crumbs}
        buttons={getButtons()}
      >
        <div className={styles.status} data-cy="lead-info:token-line">
          <HeaderLabel labelText="Lead Token:" />
          <span>{lead.leadToken}</span>
        </div>
        <div className="flex align-items-center" data-cy="lead-info:product-line">
          <HeaderLabel labelText="Product:" />
          <NavLink to={productLink}>{productName}</NavLink>
        </div>
      </StandardHeader>
    </>
  )
}
