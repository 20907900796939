import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { Prompt } from 'react-router'
import { useAtomValue } from 'jotai/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as Icon from '../../../components/icons'
import { LDFlag } from '../../../constants/ld-flags'
import { Credentials } from './credentials'
import { SettingsContainer } from '../../../components/settings-container'
import { StoreDetails } from './store-details'
import { StoreDetailsForm } from './store-details-form'
import { StoreStatus } from './store-status'
import { useSplashScreen } from '../../../hooks/use-splash-screen'
import { LeadUrlConfigForm } from './lead-url-config-form'
import { StandardHeader } from '../../../components/standard-header'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import { MidSetting } from './mid-setting'
import { RecurringPayment } from './recurring-payment'
import { v3AccessTokenAtom } from '../../../atoms/auth'
import { getActiveStoreAtom } from '../../../atoms/stores'
import { useGetSettingsCredentialsSubheaderText } from '../../../hooks/use-get-settings-credentials-subheader-text'
import styles from './settings.module.css'

export const Settings: FC = () => {
  const { [LDFlag.MpEER4Integrations]: FF_R4_INTEGRATIONS } = useFlags()
  const accessToken = useAtomValue(v3AccessTokenAtom)
  const store = useAtomValue(getActiveStoreAtom)
  const { onLoading, afterLoading } = useSplashScreen('store:settings')
  const [hasChangesMade, setHasChangesMade] = useState(false)
  const { hasPermission } = usePermissions()
  const isProdEnv = EXTEND_ENV === 'production'
  const credentialsHeaderText = isProdEnv ? 'Production credentials' : 'Sandbox credentials'
  const credentialsSubheaderText = useGetSettingsCredentialsSubheaderText()

  useEffect(() => {
    if (!store || !accessToken) {
      onLoading()
    } else {
      afterLoading()
    }
  }, [store, accessToken, onLoading, afterLoading])

  if (!store || !accessToken) {
    return null
  }

  const isShopify = store.platform === 'shopify'

  const shopifySubHeader = (
    <>
      You can edit these in&nbsp;
      <a
        className={styles.shopify}
        href="https://accounts.shopify.com/store-login"
        target="_blank"
        rel="noopener noreferrer"
      >
        Shopify <Icon.ExternalLink />
      </a>
    </>
  )
  const detailsSubHeader = isShopify ? shopifySubHeader : 'Edit basic information about your store'

  const isLeadUrlConfigurabilityEnabled =
    store.leadUrlConfigurabilityEnabled ||
    store.platform === 'shopify' ||
    store.platform === 'big-commerce'

  return (
    <div className={styles.settings} data-cy="setting-wrapper">
      <Prompt when={hasChangesMade} message="Leave page? Your changes will not be saved" />
      <StandardHeader pageTitle="Settings" data-cy="setting-header" />
      <StoreStatus />
      <SettingsContainer headerText="Store details" subHeader={detailsSubHeader}>
        {isShopify ? (
          <StoreDetails storeId={store.id} />
        ) : (
          <StoreDetailsForm setHasChangesMade={setHasChangesMade} />
        )}
      </SettingsContainer>
      {isLeadUrlConfigurabilityEnabled && (
        <SettingsContainer
          headerText="Lead URL Configuration"
          subHeader="Change how Lead URLs are formatted when you copy them from a Lead page"
        >
          <LeadUrlConfigForm setHasChangesMade={setHasChangesMade} storeId={store.id} />
        </SettingsContainer>
      )}
      {!FF_R4_INTEGRATIONS && (
        <SettingsContainer headerText={credentialsHeaderText} subHeader={credentialsSubheaderText}>
          <Credentials />
        </SettingsContainer>
      )}
      {hasPermission(Permission.ManageMid) && (
        <SettingsContainer
          headerText="Merchant Identification Number (MID)"
          subHeader="Enter your MID to restrict customers’ virtual gift cards to your store"
        >
          <MidSetting sellerId={store.id} setHasChangesMade={setHasChangesMade} />
        </SettingsContainer>
      )}
      <RecurringPayment store={store} />
    </div>
  )
}
