import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner, COLOR } from '@extend/zen'
import { StandardHeader } from '../../components/standard-header'
import styles from './integration-details.module.css'
import { ErrorPage } from '../../components/error-page'
import { useGetIntegrationQuery } from '../../queries/integrations'
import { getIntegrationStatusBadgeItem } from '../../utils/integration-status-utils'
import { PlatformIntegrationDetails } from './platform-integration-details'

export const PlatformIntegration: FC = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const {
    data: platformIntegration,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetIntegrationQuery(integrationId)

  const pageTitle = platformIntegration?.platformStoreId || ''

  const crumbs = useMemo(() => {
    return [
      { to: '/account/integrations', text: 'Integrations' },
      { to: '/account/integrations?integrations=platform', text: 'Platform Integrations' },
      {
        text: pageTitle,
      },
    ]
  }, [pageTitle])

  if (isFetching || isLoading) {
    return (
      <div className={styles['loading-container']}>
        <Spinner color={COLOR.BLUE[800]} />
      </div>
    )
  }

  if (isError || !platformIntegration) {
    return <ErrorPage error={error} />
  }

  const integrationStatus = getIntegrationStatusBadgeItem(platformIntegration.status)

  return (
    <>
      <StandardHeader
        marginTop={10}
        marginBottom={40}
        pageTitle={pageTitle}
        crumbs={crumbs}
        pageTitleBadge={{
          badgeText: integrationStatus.text,
          badgeColor: integrationStatus.color,
        }}
      />
      <PlatformIntegrationDetails integration={platformIntegration} />
    </>
  )
}
