import { useCallback, useMemo, useState } from 'react'
import type {
  Contract,
  DecodedAccessToken,
  Reply,
} from '@helloextend/extend-api-client'
import type { ConnectResponse } from '@extend-incredibot/types'
import { ClaimSource, Slot } from '@helloextend/extend-api-client'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import jwt from '@helloextend/jwt'
import { useAtomValue } from 'jotai/react'
import { useConnectIncredibotMutation, useUpdateIncredibotMutation } from '../queries/incredibot'
import { useGetContractQuery } from '../queries/contract'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../constants/const'
import { v3AccessTokenAtom } from '../atoms/auth'

type UseIncredibotFlowStateValue = {
  contract: Contract | undefined
  currentReply: ConnectResponse | Reply
  hasError: boolean
  isLoading: boolean
  isIncredibotUpdating: boolean
  updateSession: (slot: Slot, slotValue: string | number | string[]) => Promise<void>
  connect: () => Promise<void>
}

export function useIncredibotFlowState(
  contractId: string,
  lineItemId?: string,
): UseIncredibotFlowStateValue {
  const { toast } = useToaster()
  const accessToken = useAtomValue(v3AccessTokenAtom)

  const { data: contract, isLoading: isGetContractLoading } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })

  const [currentReply, setCurrentReply] = useState<ConnectResponse | Reply>({ messages: [] })

  const {
    mutateAsync: connectIncredibot,
    isLoading: isIncredibotConnectLoading,
    error: incredibotConnectError,
    data: incredibotConnectReply,
  } = useConnectIncredibotMutation()

  const {
    mutateAsync: updateIncredibot,
    isLoading: isIncredibotUpdating,
    error: incredibotUpdateError,
  } = useUpdateIncredibotMutation()

  const handleError = useCallback(() => {
    toast({
      message: 'Error occurred. Please try again.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.long,
    })
  }, [toast])

  const connect = useCallback(
    async (): Promise<void> => {
      try {
        if (accessToken) {
          const { firstName, lastName, email, accountId } = jwt.decode(
            accessToken,
          ) as DecodedAccessToken

          const reply = await connectIncredibot({
            filedBy: {
              firstName,
              lastName,
              email,
              accountId,
            },
            contractId,
            ...(lineItemId && { lineItemIds: [lineItemId] }),
            source: ClaimSource.merch_admin,
          })
          setCurrentReply(reply)
        }
      } catch (error) {
        handleError()
      }
    },
    [accessToken, connectIncredibot, contractId, handleError, lineItemId],
  )

  const updateSession = useCallback(
    async (slot: Slot, slotValue: string | number | string[]): Promise<void> => {
      try {
        if (incredibotConnectReply?.accessToken) {
          const reply = await updateIncredibot({
            accessToken: incredibotConnectReply?.accessToken,
            data: { slot, slotValue: slotValue as string },
          })
          setCurrentReply(reply)
        }
      } catch (error) {
        handleError()
      }
    },
    [incredibotConnectReply, handleError, updateIncredibot],
  )

  const isLoading = useMemo(
    () =>
      Boolean(
          isIncredibotConnectLoading ||
          isIncredibotUpdating ||
          isGetContractLoading,
      ),
    [
      isGetContractLoading,
      isIncredibotConnectLoading,
      isIncredibotUpdating,
    ],
  )
  const hasError = useMemo(
    () => Boolean(incredibotConnectError || incredibotUpdateError),
    [incredibotConnectError, incredibotUpdateError],
  )

  return {
    contract,
    currentReply,
    hasError,
    isLoading,
    isIncredibotUpdating,
    updateSession,
    connect,
  }
}
