import type { FC, SyntheticEvent } from 'react'
import React, { useState, useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import {
  Checkbox,
  Input,
  Select,
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  Info as InfoIcon,
} from '@extend/zen'
import { CurrencyInputGroup } from '@helloextend/merchants-ui'
import type { ProductReplacementServiceOrdersFulfillRequest } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { useGetContractQuery } from '../../../queries/contract'
import type { Values } from './reserve-service-order-modal-schema'
import { schema } from './reserve-service-order-modal-schema'
import { useFulfillServiceOrderMutation } from '../../../queries/service-orders'
import { ShippingCarrierSelectOptions } from '../../../components/shipping-carrier-select-options'
import styles from './resolve-service-order-product-replacement-form.module.css'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'

export type ReplacementModalProps = {
  contractId: string
  serviceOrderId: string
}

export const ResolveServiceOrderProductReplacementForm: FC<ReplacementModalProps> = ({
  contractId,
  serviceOrderId,
}) => {
  const { data: contract } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })

  const { mutateAsync: fulfillReplacement, isLoading } = useFulfillServiceOrderMutation()
  const [isProductDifferent, setIsProductDifferent] = useState(false)
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)
  const initialValues: Values = useMemo(() => {
    return {
      method: 'product_replacement',
      product: {
        referenceId: contract?.product?.referenceId ?? '',
        title: contract?.product?.title ?? '',
        value: {
          amount: contract?.product?.listPrice,
          currencyCode: contract?.currency ?? 'USD',
        },
        reimbursementAmount: {
          amount: contract?.product?.reimbursementAmount,
          currencyCode: contract?.currency ?? 'USD',
        },
      },
      orderNumber: '',
      expenseDescription: '',
      carrier: '',
      trackingNumber: '',
    }
  }, [contract])

  const {
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
    setValues,
    setFieldValue,
    setErrors,
    setTouched,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues,
    onSubmit: async (v: Values) => {
      const formValues = { ...v }
      if (!v.wholesaleCost?.amount) {
        delete formValues.wholesaleCost
      } else if (formValues.wholesaleCost) {
        formValues.wholesaleCost.currencyCode = contract?.currency ?? 'USD'
      }
      await fulfillReplacement({
        serviceOrderId,
        fulfillRequest: {
          ...formValues,
          method: 'product_replacement',
          product: { ...formValues.product, title: undefined, name: formValues.product.title },
        } as ProductReplacementServiceOrdersFulfillRequest,
      })
      setIsResolveServiceOrderModalVisible(false)
    },
  })

  const handleCarrierChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>,
  ): void => {
    const { value } = event.currentTarget
    setFieldValue('carrier', value)
  }

  const handleReplacementTypeChange = useCallback((): void => {
    // if previously product was different but now it's what's in the contract, set all fields to INITIAL values
    // otherwise set all fields to DEFAULT values (empty strings) to let merchant user fill them in
    const resetValues = isProductDifferent ? initialValues : schema.default()
    setValues({
      ...resetValues,
    })
    setErrors({})
    setTouched({})
    setIsProductDifferent(!isProductDifferent)
  }, [initialValues, isProductDifferent, setValues, setErrors, setTouched, setIsProductDifferent])

  const saveButtonText = 'Resolve'

  return (
    <form
      className={styles.form}
      name="product-replacement-form"
      aria-label="product-replacement-form"
      onSubmit={handleSubmit}
    >
      <div className={styles['checkbox-wrapper']}>
        <Checkbox
          checked={isProductDifferent}
          name="isProductDifferent"
          onChange={handleReplacementTypeChange}
          label="Replacement product is different from covered product."
        />
      </div>
      <div className={styles.grid}>
        <div className={styles['grid-full-row']}>
          <Input
            id="product.title"
            label="Replacement Product"
            isDisabled={!isProductDifferent}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.product?.title}
            isError={touched.product?.title && Boolean(errors.product?.title)}
            errorFeedback={
              (isProductDifferent && touched.product?.title && errors.product?.title) || ''
            }
          />
        </div>

        <Input
          id="product.referenceId"
          label="Replacement Product Ref ID"
          isDisabled={!isProductDifferent}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.product?.referenceId}
          isError={touched.product?.referenceId && Boolean(errors.product?.referenceId)}
          errorFeedback={
            (isProductDifferent && touched.product?.referenceId && errors.product?.referenceId) ||
            ''
          }
        />
        <CurrencyInputGroup
          data-cy="replacement-value"
          name="product.value.amount"
          label="Replacement Value"
          isCurrencyCodeDisabled
          isDisabled={!isProductDifferent}
          currencyProps={{
            codeFieldName: 'replacementValue',
            codeValue: contract?.currency ?? 'USD',
          }}
          showSymbol={false}
          onChange={setFieldValue}
          onBlur={handleBlur}
          value={values.product?.value?.amount}
          invalid={touched.product?.value?.amount && Boolean(errors.product?.value?.amount)}
          errorMessage={
            (isProductDifferent &&
              touched.product?.value?.amount &&
              errors.product?.value?.amount) ||
            ''
          }
        />
        <CurrencyInputGroup
          data-cy="reimbursement-amount"
          name="product.reimbursementAmount.amount"
          label="Reimbursement Amount"
          isCurrencyCodeDisabled
          isDisabled={!isProductDifferent}
          currencyProps={{
            codeFieldName: 'reimbursementAmount',
            codeValue: contract?.currency ?? 'USD',
          }}
          onChange={setFieldValue}
          showSymbol={false}
          onBlur={handleBlur}
          value={values.product?.reimbursementAmount?.amount}
          invalid={
            touched.product?.reimbursementAmount?.amount &&
            Boolean(errors.product?.reimbursementAmount?.amount)
          }
          errorMessage={
            (isProductDifferent &&
              touched.product?.reimbursementAmount?.amount &&
              errors.product?.reimbursementAmount?.amount) ||
            ''
          }
        />

        <Input
          id="orderNumber"
          label="Order Number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.orderNumber}
          isError={touched.orderNumber && Boolean(errors.orderNumber)}
          errorFeedback={(touched.orderNumber && errors.orderNumber) || ''}
        />
        <Input
          id="expenseDescription"
          label="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.expenseDescription}
          isError={touched.expenseDescription && Boolean(errors.expenseDescription)}
          errorFeedback={(touched.expenseDescription && errors.expenseDescription) || ''}
        />

        <CurrencyInputGroup
          justifyStart
          data-cy="wholesale-cost"
          name="wholesaleCost.amount"
          label="Wholesale Cost (Optional)"
          isCurrencyCodeDisabled
          currencyProps={{
            codeFieldName: 'wholesaleCost',
            codeValue: contract?.currency ?? 'USD',
          }}
          showSymbol={false}
          onChange={setFieldValue}
          onBlur={handleBlur}
          value={values.wholesaleCost?.amount}
          invalid={touched.wholesaleCost && Boolean(errors.wholesaleCost)}
          errorMessage={touched.wholesaleCost && errors.wholesaleCost}
        />
        <>
          <Select
            id="carrier"
            onChange={handleCarrierChange}
            value={values.carrier || ''}
            placeholder="—SELECT—"
            isError={touched.carrier && Boolean(errors.carrier)}
            errorFeedback={(touched.carrier && errors.carrier) || ''}
            label="Carrier"
          >
            <ShippingCarrierSelectOptions />
          </Select>
          <div className={styles['grid-two-columns']}>
            <Input
              id="trackingNumber"
              label="Tracking Number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trackingNumber || ''}
              isError={touched.trackingNumber && Boolean(errors.trackingNumber)}
              errorFeedback={(touched.trackingNumber && errors.trackingNumber) || ''}
            />
          </div>
        </>
      </div>
      <div className={styles['checkbox-wrapper']} />

      <InlineAlert
        color={InlineAlertColor.blue}
        icon={InfoIcon}
        data-cy="service-order-resolve-gift-card-alert"
        isDismissable={false}
      >
        {`Resolution cannot be edited once submitted. Please review before clicking
            "${saveButtonText}"`}
      </InlineAlert>

      <div className={styles['button-group-wrapper']}>
        <ButtonGroup isReversed>
          <Button
            type="submit"
            text={saveButtonText}
            data-cy="resolve-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => setIsResolveServiceOrderModalVisible(false)}
            isDisabled={isLoading}
          />
        </ButtonGroup>
      </div>
    </form>
  )
}
