import type { FC } from 'react'
import React from 'react'
import { Modal, ModalController } from '@extend/zen'

export type LeavePageModalProps = {
  handleLeavePage: () => void
  handleCloseModal: () => void
  isVisible: boolean
}

export const LeavePageModal: FC<LeavePageModalProps> = ({
  handleLeavePage,
  handleCloseModal,
  isVisible,
}) => (
  <ModalController isOpen={isVisible}>
    <Modal
      heading="Hang on! You have some unsaved changes on this page"
      data-testid="leave-page-modal"
      primaryButtonProps={{
        'data-cy': 'leavePageModalConfirm',
        text: 'Leave Without Saving',
        color: 'red',
        onClick: handleLeavePage,
      }}
      secondaryButtonProps={{
        'data-cy': 'leavePageModalCancel',
        text: 'Cancel',
        onClick: handleCloseModal,
      }}
    >
      <p>Are you sure you want to leave?</p>
    </Modal>
  </ModalController>
)
