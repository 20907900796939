import type {
  ContractSearchOptionsWithTypeFilter,
  ContractStatus,
} from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { ColumnFiltersState } from '@extend/zen'
import { getColumnFiltersFromQueryParams } from '../features/store/contracts/utils'
import { contractBreadcrumbsAtom } from '../atoms/contract-breadcrumb'

export const useContractsColumnFiltersWithQueryString = (): {
  columnFilters: ColumnFiltersState
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>
  searchParams: ContractSearchOptionsWithTypeFilter
} => {
  const { search: queryParams } = useLocation()

  const setContractsUrl = useSetAtom(contractBreadcrumbsAtom)

  useEffect(() => {
    // Sync the breadcrumb whenever the query params change
    setContractsUrl(`/store/contracts${queryParams}`)
  }, [setContractsUrl, queryParams])

  const [searchKey, setSearchKey] = useQueryStringState('searchKey', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')
  const [searchFilters, setSearchFilters] = useQueryStringState('filters', {}, { encode: true })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    getColumnFiltersFromQueryParams({
      searchFilters,
      searchKey,
      searchValue,
    }),
  )

  const setQueryStringParams = useCallback(() => {
    // Limited options are "filters", while all others would be search params
    const searchParam = columnFilters.find(({ id }) => !['status', 'typeFilter'].includes(id))

    const filters = columnFilters.reduce((acc, curr) => {
      if (curr.id === 'status') {
        return { ...acc, status: curr }
      }
      if (curr.id === 'typeFilter') {
        return { ...acc, typeFilter: curr }
      }

      return acc
    }, {})

    setSearchKey(searchParam?.id || '')
    setSearchValue((searchParam?.value as string) || '')
    setSearchFilters(filters)
  }, [setSearchKey, setSearchValue, setSearchFilters, columnFilters])

  const searchParams: ContractSearchOptionsWithTypeFilter = useMemo(() => {
    setQueryStringParams()

    if (!columnFilters.length) return {}

    return columnFilters.reduce<ContractSearchOptionsWithTypeFilter>((acc, filter) => {
      let currentFilter: ContractSearchOptionsWithTypeFilter = {}

      if (typeof filter.value === 'string') {
        currentFilter = {
          [filter.id]: filter.value.trim(),
        }
      } else if (filter.id === 'status' && Array.isArray(filter.value)) {
        // 'status' is derived from a select filter, so the value is in an array.
        // There is only ever one value since it's not a multiselect.
        currentFilter = {
          status: filter.value[0] as ContractStatus,
        }
      } else if (filter.id === 'typeFilter' && Array.isArray(filter.value)) {
        currentFilter = {
          typeFilter: filter.value,
        }
      }

      return {
        ...acc,
        ...currentFilter,
      }
    }, {})
  }, [columnFilters, setQueryStringParams])

  return {
    columnFilters,
    setColumnFilters,
    searchParams,
  }
}
