// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1us-SBunpO_qjMuYnywthO {\n    font-size: 12px;\n    line-height: 16px;\n    color: var(--neutral-1000);\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    text-align: left;\n    margin: 0;\n}\n\n._2e0A61pGpKVHlCrcWE9nex {\n    padding-right: 12px;\n}\n", ""]);
// Exports
exports.locals = {
	"double-cell-text": "_1us-SBunpO_qjMuYnywthO",
	"amount": "_2e0A61pGpKVHlCrcWE9nex"
};
module.exports = exports;
