import type { FC } from 'react'
import React, { useEffect } from 'react'
import { ImageUploader } from '@helloextend/merchants-ui'
import { useUpdateLeadsEmailLogoMutation } from '../../../queries/stores'
import { getBase64 } from '../../../utils/get-base64'

type StoreLogoUploadProps = {
  storeId: string
  onChange: (url: string) => void
  currentImage?: string
}

const StoreLogoUpload: FC<StoreLogoUploadProps> = ({ storeId, onChange, currentImage }) => {
  const { mutate: uploadStoreLogo, data, isLoading } = useUpdateLeadsEmailLogoMutation()

  useEffect(() => {
    if (data) {
      onChange(data)
    }
  }, [data, onChange])

  const handleImageOnChange = async (file: File): Promise<void> => {
    const base64Image = await getBase64(file)
    if (base64Image) {
      uploadStoreLogo({ storeId, base64Image })
    }
  }

  return (
    <ImageUploader
      isProcessing={isLoading}
      title="Store Logo"
      onChange={handleImageOnChange}
      currentImage={currentImage}
    />
  )
}

export { StoreLogoUpload }
