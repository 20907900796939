import { NumericInvokableFunction } from '@extend-conversations/types'
import type { AdvancedSelectOption, AdvancedSelectOptionGroup } from '@extend/zen'

export const getKeySelectorOptionsBase = (): AdvancedSelectOptionGroup[] => {
  return [
    {
      label: 'Plan Attributes',
      options: [
        {
          display: 'Coverage Type',
          value: 'planHasCoverageType',
        },
      ],
    },
    {
      label: 'Contract Attributes',
      options: [
        {
          display: 'Total of the purchase prices',
          value: 'totalOfPurchasePrices',
        },
        {
          display: 'Number of shipments',
          value: 'numberOfShipments',
        },
      ],
    },
    {
      label: 'Claim Attributes',
      options: [
        {
          display: 'Number of fulfilled claims',
          value: 'numberOfFulfilledClaims',
        },
        {
          display: 'Number of days between claim creation and contract start date',
          value: 'daysBetweenClaimCreateAndContractStart',
        },
        {
          display: 'Number of days between claim creation and incident date',
          value: 'daysBetweenClaimCreateAndIncident',
        },
      ],
    },
    {
      label: 'Message Blocks',
      options: [],
    },
  ]
}

export const keySelectorOptions: AdvancedSelectOption[] = getKeySelectorOptionsBase().flatMap(
  (group) => group.options,
)

export const numericValueConditionComparandOptions: (AdvancedSelectOption & {
  value: NumericInvokableFunction
})[] = [
  { id: '0', display: 'Contract Transaction Date', value: 'contractTransactionDate' },
  { id: '1', display: 'Contract coverage start date', value: 'contractStartDate' },
  { id: '2', display: 'Contract coverage end date', value: 'contractEndDate' },
  { id: '3', display: 'Number of shipments', value: 'numberOfShipments' },
  { id: '4', display: 'Product delivery date', value: 'productDeliveryDate' },
  { id: '5', display: 'Total of the purchase price', value: 'totalOfPurchasePrices' },
  { id: '6', display: 'Number of fulfilledClaims', value: 'numberOfFulfilledClaims' },
  {
    id: '7',
    display: 'Number of days between claim creation and contract start date',
    value: 'daysBetweenClaimCreateAndContractStart',
  },
  {
    id: '8',
    display: 'Number of days between claim creation and incident date',
    value: 'daysBetweenClaimCreateAndIncident',
  },
]
